import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { Link, withRouter } from 'react-router-dom'
import Carousel from 'react-bootstrap/Carousel'
import Button from '@material-ui/core/Button'
import FitnessIcon from '@material-ui/icons/FitnessCenter'
import trainerPhoto1 from '../../images/trainers/1.jpg'
import trainerPhoto2 from '../../images/trainers/2.jpg'
import trainerPhoto3 from '../../images/trainers/3.jpg'
import trainerPhoto4 from '../../images/trainers/4.jpg'
import trainerPhoto5 from '../../images/trainers/5.jpg'
import trainerPhoto6 from '../../images/trainers/6.jpg'
import trainerPhoto7 from '../../images/trainers/7.jpg'
import trainerPhoto8 from '../../images/trainers/8.jpg'
import trainerPhoto9 from '../../images/trainers/9.png'
import trainerPhoto10 from '../../images/trainers/10.jpg'
import trainerPhoto11 from '../../images/trainers/11.jpeg'
import trainerPhoto12 from '../../images/trainers/12.jpg'
import trainerPhoto13 from '../../images/trainers/13.jpg'
import trainerPhoto14 from '../../images/trainers/14.jpg'
import trainerPhoto15 from '../../images/trainers/15.jpg'
import trainerPhoto16 from '../../images/trainers/16.jpg'
import trainerPhoto17 from '../../images/trainers/17.png'
import trainerPhoto18 from '../../images/trainers/18.jpg'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import { mediaQuery, isScrolledIntoView, photoUrlPrefix } from '../../services/global'
import { getString, multilineHtmlText } from '../../services/presetStringsHelper'
import { invokeAPIcall } from '../../services/rest'
import ProductPriceTag from '../ProductPriceTag'
import FullScreenDisplay from '../FullScreenDisplay'

// Style
const FirstMainPhotosList = styled.div`
  background-color: ${colorPalette.white.main};
  background-size: 50% 140%;
  background-position: bottom left;
  background-repeat: no-repeat;
  height: 300px;
  border-bottom-left-radius: ${globalStyles.mainBoxRadius};
  border-bottom-right-radius: ${globalStyles.mainBoxRadius};
  box-shadow: ${globalStyles.mainBoxShadow};
  position: relative;
  z-index: 30;
  top: -50px;
  display: flex;
  ${mediaQuery.phone`
    display: none;
  `}
`
const SecondMainPhotosList = styled(FirstMainPhotosList)`
  z-index: 15;
  margin-top: 30px;
  ${mediaQuery.phone`
    height: 260px;
  `}
`
const TrainerPhotoItem = styled.div`
  flex: 1;
  height: 200px;
  background-image: url('${props => props.photoUrl}');
  background-size: cover;
  background-position: ${props => props.position || 'center'};
  background-repeat: no-repeat;
  margin: 5px;
  margin-top: 50px;
  border-radius: 10px;
  border: 3px solid ${colorPalette.primary1.main};
  ${mediaQuery.phone`
    border-radius: 0;
    border: none;
    margin: 0;
    height: 300px;
  `}
`
const TrainersCarouselItemWrapper = styled.div`
  background-color: ${colorPalette.white.main};
  border-bottom-left-radius: ${globalStyles.mainBoxRadius};
  border-bottom-right-radius: ${globalStyles.mainBoxRadius};
  box-shadow: ${globalStyles.mainBoxShadow};
  color: ${colorPalette.white.main};
  background-color: ${colorPalette.primary1.main};
  position: relative;
  z-index: 20;
  text-align: center;
  margin-top: -72px;
  padding-top: 60px;
  padding-bottom: 30px;
  ${mediaQuery.phone`
    height: auto;
  `}
`
const SecondTrainersCarouselItemWrapper = styled(TrainersCarouselItemWrapper)`
  z-index: 14;
  margin-top: -45px;
  color: ${colorPalette.primary1.main};
  background-color: ${colorPalette.white.main};
  padding-top: 20px;
  padding-bottom: 0;
`
const CarouselItemInner = styled.div`
  width: 100%;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mediaQuery.phone`
    padding: 10px;
  `}
`
const ContentBox = styled.div`
  max-width: 900px;
  margin: 0 auto;
  ${mediaQuery.phone`
    width: auto;
    padding: 0 20px;
    flex-direction: column;
  `}
`
const PromotionTitle = styled.h2`
  font-size: 37px;
  font-weight: 500;
  margin: 0;
  ${mediaQuery.tablet`
    font-size: 30px;
  `}
  ${mediaQuery.phone`
    font-size: 25px;
  `}
`
const PromotionTitleFirst = styled(PromotionTitle)`
  text-align: center;
`
const PromotionSubTitle = styled.h4`
  font-size: 26px;
  font-weight: 500;
  margin-top: 10px;
  max-width: 100%;
  ${mediaQuery.phone`
    font-size: 1.2rem;
    text-shadow: none;
  `}
`
const PromotionSubTitleSecond = styled(PromotionSubTitle)`
  margin: 0;
`
const TopContentText = styled.div`
  font-size: 20px;
  padding: 20px;
  text-align: right;
  width: 100%;
`
const PromotionItemsList = styled.ul`
  margin: 20px 15px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 20px auto;
  flex: 1;
  text-align: center;
  max-width: 670px;
  ${mediaQuery.phone`
    flex-direction: column;
    flex-wrap: nowrap;
    max-width: none;
  `}
`
const PromotionItem = styled.li`
  flex: 0 0 48%;
  margin: 10px 0;
  span {
    display: none;
    font-size: 14px;
  }
  &:hover span {
    display block;
  }
  ${mediaQuery.phone`
    ${props => (props.hideInMobile ? 'display: none;' : '')}
  `}
  /*transition: transform 0.5s, opacity 2s;
  transition-delay: ${props => props.index * 0.1}s;
  transform: ${props => (props.isActive ? 'translateX(0%)' : 'translateX(100%)')};
  opacity: ${props => (props.isActive ? '1' : '0')};*/
`
const ItemTitle = styled.div`
  display: flex;
  font-size: 16px;
  ${mediaQuery.phone`
  `}
`
const StyledDoneOutlineIcon = styled.div`
  && {
    background: ${colorPalette.primary1.main};
    width: 14px;
    height: 14px;
    flex: 0 0 14px;
    margin-top: 5px;
    margin-left: 10px;
  }
`
const StyledFitnessIcon = styled(FitnessIcon)`
  margin-left: 20px;
  margin-top: 4px;
  width: 30px !important;
  height: 30px !important;
  transform: rotate(-45deg);
`
const TrainingProducts = styled.ul`
  flex: 3;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 10px;
  ${mediaQuery.tablet`
    flex: 1;
  `}
`
const ProductItem = styled.li`
  background-color: ${colorPalette.white.main};
  flex: 0 0 22%;
  margin-bottom: 2px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  color: ${colorPalette.primary1.main};
  &:hover {
    opacity: 0.8;
  }
  ${mediaQuery.tablet`
    flex: 0 0 48%;
  `}
  ${mediaQuery.phone`
    flex: 0 0 48%;
  `}
`
const ProductNameAndPrice = styled.div`
  padding: 0 5px;
  padding-bottom: 12px;
  color: ${colorPalette.black.main};
  & > div {
    font-weight: 500;
    font-size: 14px;
  }
`
const ProductName = styled.div`
  margin-top: 2px;
  font-size: 12px !important;
  font-weight: 300 !important;
`
const ProductPhoto = styled.div`
  width: 100%;
  height: 125px;
  background-color: #eee;
  background-image: url('${props => props.photoUrl}');
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  margin: auto;
`
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 14px 0;
`
const StyledButton = styled(Button)`
  && {
    padding: 6px 35px;
    font-size: 20px;
  }
`
const StyledCarousel = styled(Carousel)`
  && {
    display: none;
    position: relative;
    z-index: 30;
    top: -50px;
    border-bottom-left-radius: ${globalStyles.mainBoxRadius};
    border-bottom-right-radius: ${globalStyles.mainBoxRadius};
    height: 300px;
    overflow: hidden;

    .carousel-control-prev,
    .carousel-control-next {
      margin-top: 140px;
    }
  }
  ${mediaQuery.phone`
    && {
      display: block;
    }
  `}
`
const SecondStyledCarousel = styled(StyledCarousel)`
  && {
    z-index: 15;
  }
`
const StyledFullScreenCarousel = styled(StyledCarousel)`
  && {
    display: block;
    height: 500px;
    width: 500px;
    top: 0;
    border-radius: 0;
    .carousel-control-prev,
    .carousel-control-next {
      margin-top: 240px;
    }
    ${TrainerPhotoItem} {
      width: 500px;
      height: 500px;
      margin-top: 0;
      border-radius: 0;
    }
  }
`

// Component
class OurTrainersCarouselItem extends Component {
  state = {
    showPromotionItems: true,
    isVisible: true,
    products: [],
    globalSettingsData: {},
    carouselIndex: 0,
    showFullScreenPhotosGroup: null,
  }

  // Lifecycle methods
  componentDidMount() {
    window.addEventListener('scroll', () => {
      const isInView = isScrolledIntoView(document.getElementById('TrainersCarouselItemWrapper'))
      const { isVisible } = this.state
      if (this.props.isActive && !isVisible) {
        //this.setState({ isVisible: isInView })
      }
    })

    if (this.props.isActive) {
      window.setTimeout(() => {
        //this.setState({ showPromotionItems: true })
      }, 800)
    }

    this.loadProductList()
    this.loadGlobalSettingsData()
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.isActive && nextProps.isActive) {
      window.setTimeout(() => {
        //this.setState({ showPromotionItems: true })
      }, 800)
    }
  }

  // Helper methods
  loadProductList = async () => {
    let productList = await invokeAPIcall({ uri: `api/products/getByCategory?categoryId=1` })
    productList = productList.sort((a, b) => (a.DisplayPricePerItem || a.Price) - (b.DisplayPricePerItem || b.Price))
    this.setState({ products: productList })
  }

  loadGlobalSettingsData = async () => {
    let globalSettingsData = await invokeAPIcall({ uri: `api/globalSettings/1` })
    this.setState({
      globalSettingsData: globalSettingsData,
    })
  }

  // UI event methods
  handleProductClick = urlCode => {
    const { history } = this.props
    history.push(`/productDetails/${urlCode}`)
  }

  handleSelectCarouselItem = (selectedIndex, e) => {
    this.setState({ carouselIndex: selectedIndex })
  }

  handlePhotoClick = (photoIndex, photosGroup) => () => {
    this.setState({ carouselIndex: photoIndex, showFullScreenPhotosGroup: photosGroup })
  }

  handlePhotoFullScreenClose = () => {
    this.setState({ showFullScreenPhotosGroup: null })
  }

  // Render methods
  renderProductItem = productItem => {
    const { globalSettingsData } = this.state
    let photoUrl = productItem.ProductPhotos[0] && productItem.ProductPhotos[0].PhotoURL
    if (photoUrl) {
      if (!photoUrl.includes('http')) {
        photoUrl = `${photoUrlPrefix}/productPhotos/${photoUrl}`
      }
    }
    return (
      <ProductItem onClick={this.handleProductClick.bind(this, productItem.UrlCode)}>
        <ProductPhoto photoUrl={photoUrl} />
        <ProductNameAndPrice>
          <ProductName>{productItem.Name}</ProductName>
          <ProductPriceTag productItem={productItem} globalSettingsData={globalSettingsData} showPricePerItem />
        </ProductNameAndPrice>
      </ProductItem>
    )
  }

  renderCarouselItem = item => (
    <Carousel.Item>
      <TrainerPhotoItem photoUrl={item.photoUrl} position={item.position} />
    </Carousel.Item>
  )

  render() {
    const { showPromotionItems, isVisible, products, carouselIndex, showFullScreenPhotosGroup } = this.state
    const trainerPhotos = [
      { photoUrl: trainerPhoto1, position: 'top center' },
      { photoUrl: trainerPhoto2, position: 'top center' },
      { photoUrl: trainerPhoto3, position: 'top center' },
      { photoUrl: trainerPhoto4, position: 'top center' },
      { photoUrl: trainerPhoto5 },
      { photoUrl: trainerPhoto6 },
      { photoUrl: trainerPhoto7, position: 'top center' },
      { photoUrl: trainerPhoto8, position: 'top center' },
      { photoUrl: trainerPhoto9, position: 'top center' },
    ]
    const trainerPhotos2 = [
      { photoUrl: trainerPhoto10, position: 'top center' },
      { photoUrl: trainerPhoto11, position: 'top center' },
      { photoUrl: trainerPhoto12, position: 'top center' },
      { photoUrl: trainerPhoto13, position: 'top center' },
      { photoUrl: trainerPhoto14 },
      { photoUrl: trainerPhoto15, position: 'top center' },
      { photoUrl: trainerPhoto16, position: 'top center' },
      { photoUrl: trainerPhoto17, position: 'top center' },
      { photoUrl: trainerPhoto18, position: 'top center' },
    ]
    const carouselProps = {
      activeIndex: carouselIndex,
      onSelect: this.handleSelectCarouselItem,
      fade: true,
      interval: 4000,
      indicators: false,
    }
    return (
      <>
        <FirstMainPhotosList>
          {trainerPhotos.map((item, index) => (
            <TrainerPhotoItem photoUrl={item.photoUrl} onClick={this.handlePhotoClick(index, 'photosGroup1')} position={item.position} />
          ))}
        </FirstMainPhotosList>
        <StyledCarousel {...carouselProps}>{trainerPhotos.map(this.renderCarouselItem)}</StyledCarousel>
        <TrainersCarouselItemWrapper id="TrainersCarouselItemWrapper">
          <CarouselItemInner isVisible={isVisible}>
            <ContentBox>
              <PromotionTitleFirst>{getString('OurTrainersCarouselItemTitle')}</PromotionTitleFirst>
              <TopContentText>{multilineHtmlText(getString('OurTrainersCarouselItemSubTitle'))}</TopContentText>
              {/*<TrainingProducts>{productsToDisplay.map(this.renderProductItem)}</TrainingProducts>*/}
              <ButtonsWrapper>
                <Link to="/store/1">
                  <StyledButton variant="contained" color="secondary">
                    {getString('OurTrainersCarouselItemButton')}
                  </StyledButton>
                </Link>
              </ButtonsWrapper>
            </ContentBox>
          </CarouselItemInner>
        </TrainersCarouselItemWrapper>
        <SecondMainPhotosList>
          {trainerPhotos2.map((item, index) => (
            <TrainerPhotoItem photoUrl={item.photoUrl} onClick={this.handlePhotoClick(index, 'photosGroup2')} position={item.position} />
          ))}
        </SecondMainPhotosList>
        <SecondStyledCarousel {...carouselProps}>{trainerPhotos2.map(this.renderCarouselItem)}</SecondStyledCarousel>
        <SecondTrainersCarouselItemWrapper>
          <CarouselItemInner>
            <ContentBox>
              <PromotionSubTitle>{getString('TrainersCarouselItemSubTitle')}</PromotionSubTitle>
              <PromotionSubTitleSecond>{getString('TrainersCarouselItemSubTitle2')}</PromotionSubTitleSecond>
              <PromotionItemsList>
                <PromotionItem index={0} isActive={showPromotionItems}>
                  <ItemTitle>
                    <StyledDoneOutlineIcon />
                    {getString('TrainersCarouselItemListItem1')}
                  </ItemTitle>
                </PromotionItem>
                <PromotionItem index={1} isActive={showPromotionItems}>
                  <ItemTitle>
                    <StyledDoneOutlineIcon />
                    {getString('TrainersCarouselItemListItem2')}
                  </ItemTitle>
                </PromotionItem>
                <PromotionItem index={2} isActive={showPromotionItems}>
                  <ItemTitle>
                    <StyledDoneOutlineIcon />
                    {getString('TrainersCarouselItemListItem3')}
                  </ItemTitle>
                </PromotionItem>
                <PromotionItem index={3} isActive={showPromotionItems}>
                  <ItemTitle>
                    <StyledDoneOutlineIcon />
                    {getString('TrainersCarouselItemListItem4')}
                  </ItemTitle>
                </PromotionItem>
                <PromotionItem index={4} isActive={showPromotionItems}>
                  <ItemTitle>
                    <StyledDoneOutlineIcon />
                    {getString('TrainersCarouselItemListItem5')}
                  </ItemTitle>
                </PromotionItem>
                <PromotionItem index={5} isActive={showPromotionItems}>
                  <ItemTitle>
                    <StyledDoneOutlineIcon />
                    {getString('TrainersCarouselItemListItem6')}
                  </ItemTitle>
                </PromotionItem>
              </PromotionItemsList>
              <ButtonsWrapper>
                <Link to="/store/1">
                  <StyledButton variant="contained" color="secondary">
                    {getString('OurTrainersCarouselItemButton')}
                  </StyledButton>
                </Link>
              </ButtonsWrapper>
            </ContentBox>
          </CarouselItemInner>
        </SecondTrainersCarouselItemWrapper>
        {showFullScreenPhotosGroup && (
          <FullScreenDisplay handleClose={this.handlePhotoFullScreenClose}>
            <StyledFullScreenCarousel {...carouselProps}>
              {showFullScreenPhotosGroup === 'photosGroup1' && trainerPhotos.map(this.renderCarouselItem)}
              {showFullScreenPhotosGroup === 'photosGroup2' && trainerPhotos2.map(this.renderCarouselItem)}
            </StyledFullScreenCarousel>
          </FullScreenDisplay>
        )}
      </>
    )
  }
}

export default withRouter(OurTrainersCarouselItem)
