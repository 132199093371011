/* global FB */
import React, { Component } from 'react'
import styled from 'styled-components'
import { Formik, Form, Field } from 'formik'
import moment from 'moment'
import { Link, withRouter } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Checkbox from '@material-ui/core/Checkbox'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import FacebookIcon from '@material-ui/icons/Facebook'
import formBackgroundLeft from '../../images/formBackground_left.jpg'
import formBackgroundRight from '../../images/formBackground_right.jpg'
import appleIconImg from '../../images/appleIcon.png'
import { mediaQuery, keysToLowerCase } from '../../services/global'
import { countryList } from '../../services/countries'
import {
  requiredValidation,
  emailValidation,
  phoneNumValidation,
  israelPhoneNumValidation,
  dateValidation,
} from '../../services/formValidation'
import { invokeAPIcall, invokeAPIcallWithFormData } from '../../services/rest'
import { TRIAL_DAYS } from '../../services/challengeProgressHelper'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import StyledTextField from '../StyledTextField'
import StyledSelect from '../StyledSelect'
import CountryPhoneCode from '../CountryPhoneCode'
import Breadcrumbs from '../Breadcrumbs'
import BirthDateField from './BirthDateField'

const FACEBOOK_ME_URL = '/me?fields=id,first_name,last_name,email'

// Style
const PageWrapper = styled.div`
  background-color: #f5fcfd;
  background: url(${formBackgroundLeft}) left bottom no-repeat, url(${formBackgroundRight}) right bottom no-repeat, #f7fdfd;
  background-size: 40% auto, 35% auto;
  padding: 20px;
  flex: 1;
  ${mediaQuery.phone`
    background: none;
  `}
`
const FormWrapper = styled.div`
  background-color: ${colorPalette.white.main};
  max-width: 680px;
  margin: 0 auto;
  padding: 20px;
  color: ${colorPalette.primary1.main};
  ${globalStyles.boxShadow}
  ${globalStyles.boxRadius}
`
const PageTitle = styled.h1`
  text-align: center;
  color: ${colorPalette.primary1.main};
  margin-bottom: 20px;
  ${mediaQuery.phone`
    font-size: 30px;
  `}
`
const InnerTitle = styled.h4`
  text-align: center;
`
const FieldsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
`
const FieldCell = styled.div`
  flex: 0 0 49%;
  display: flex;
  justify-content: space-between;
  .MuiFormControl-fullWidth {
    margin-top: 16px;
    margin-bottom: 8px;
  }
  ${mediaQuery.phone`
    flex: 0 0 100%;
  `}
`
const CityCountryFieldCell = styled(FieldCell)`
  & > .MuiFormControl-root {
    flex: 0 0 49%;
  }
`
const PhoneFieldCell = styled(FieldCell)`
  & > .MuiFormControl-root:first-child {
    flex: 0 0 69%;
  }
  & > .MuiFormControl-root:last-child {
    flex: 0 0 29%;
    .MuiInput-input {
      font-size: 12px;
    }
  }
`
const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: auto;
  margin-top: 20px;
  ${mediaQuery.phone`
    width: 100%;
    flex-direction: column;
  `}
`
const StyledButton = styled(Button)`
  && {
    font-size: 22px;
    line-height: 1.4;
    max-height: 62px;
    flex: 0 0 ${props => (props.singleButton ? '100%' : '49%')};
    text-transform: none;
    ${mediaQuery.phone`
      font-size: 16px;
      margin-bottom: 10px;
      width: 100%;
    `}
    & > span {
      display: block;
    }
  }
`
const StyledSecondaryButton = styled(StyledButton)`
  && {
    padding: 16px 22px;
  }
`
const StyledFBButton = styled(StyledButton)`
  && {
    & > span {
      display: inline-flex;
    }
    svg {
      font-size: 30px;
    }
  }
`
const ButtonSmallLabel = styled.div`
  font-size: 12px;
`
const TermsOfService = styled.div`
  & > a {
    text-decoration: underline !important;
  }
`
const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    margin-top: 20px;
    margin-bottom: 0;
  }
`
const StyledFormHelperText = styled(FormHelperText)`
  && {
    margin-top: 0;
  }
`
const CaptchaWrapper = styled.div`
  margin-top: 10px;
`
const LoadingIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`
const OrLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 40px -20px;
  font-weight: 500;
  font-size: 25px;
`
const OrLabelLine = styled.div`
  flex: 1;
  background: ${colorPalette.primary1.main};
  height: 5px;
  margin: 0 10px;
`
const LoginWithEmailLabel = styled.div`
  font-size: 20px;
  font-weight: 500;
`
const Phase1FormWrapper = styled.div`
  margin: 0 80px;
  ${mediaQuery.phone`
    margin: 0 10px;
  `}
`
const Phase1Buttons = styled.div`
  width: 50%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mediaQuery.phone`
    width: 100%;
  `}
`
const Phase1TitleWrapper = styled.div`
  text-align: center;
`
const StyledLink = styled(Link)`
  && {
    font-size: 13px;
    margin-bottom: 28px;
    color: ${colorPalette.primary1.light};
    &:hover {
      color: ${colorPalette.primary1.main};
    }
  }
`
const SuccessEmailLabel = styled.span`
  font-weight: 500;
  text-decoration: underline;
`
const NotRegisteredYetButton = styled.div`
  margin-top: 20px;
  color: ${colorPalette.black.main};
`
const NotRegisteredYetButtonLink = styled(Link)`
  cursor: pointer;
  color: ${colorPalette.primary1.main};
`
const FacebookBtnWrapper = styled.div`
  position: relative;
  margin-top: 20px;
`
const FacebookBtnCordovaOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 6;
`
const AppleBtnWrapper = styled(FacebookBtnWrapper)``
const AppleIcon = styled.div`
  width: 30px;
  height: 30px;
  background-image: url('${appleIconImg}');
  background-size: contain;
  background-repeat: no-repeat;
`

// Component
class RegisterScreen extends Component {
  state = {
    globalSettingsData: {},
    initialValues: {
      fullName: '',
      phone: '',
      email: '',
      birthDate: null,
      street: '',
      city: '',
      country: 'IL',
      countryPhoneCode: 'IL',
      gender: '',
      password: '',
      confirmPassword: '',
      agreeTerms: false,
    },
    registeredSuccess: false,
    registeredSuccessWithEmail: '',
    formPhase: 1, // can be 1 (when choosing Facebook vs Email) OR 1.5 (when it's a facebook existing email) OR 2 (when the user fill up all other fields)
    methodOfRegister: '', // can be 'viaEmail' or 'viaSocial'
    isFacebookLoading: false,
    showForgotPass: false,
    appliedCoupon: null,
    showPasswordText: false,
  }

  // Lifecycle methods
  componentDidMount() {
    const { currentUser, history, match } = this.props
    this.loadGlobalSettingsData()

    const isEditMode = this.isEditMode()
    // if user already logged in - redirect to the Program screen
    if (currentUser && !isEditMode) {
      history.push('/program')
    }

    if (isEditMode) {
      this.loadAndPopulateUserData()
    } else {
      if (typeof FB !== 'undefined') {
        if (FB && FB.XFBML) {
          FB.XFBML.parse()
        }

        FB.Event.subscribe('auth.statusChange', response => {
          const facebookAccessToken = response && response.authResponse && response.authResponse.accessToken
          if (response.status === 'connected') {
            // `connected` means that the user is logged in and that your app is authorized to do requests on the behalf of the user

            this.setState({ isFacebookLoading: true })

            FB.api(FACEBOOK_ME_URL, this.handleFacebookMeCallSuccess(facebookAccessToken))
          } else if (response.status === 'not_authorized') {
            // The user is logged in on Facebook, but has not authorized your app
          } else {
            // The user is not logged in on Facebook
          }
        })
      }

      // pre-display the page in FormPhase2 (for new user via email)
      if (match.params.mode === 'newUserViaEmail') {
        this.setState({ formPhase: 2, methodOfRegister: 'viaEmail' })
      }
    }

    // load 'couponCode' from the sessionStorage (if there is one)
    const couponCode = sessionStorage.getItem('couponCode')
    if (couponCode) {
      this.loadCouponData(couponCode)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.mode !== nextProps.match.params.mode) {
      // pre-display the page in FormPhase2 (for new user via email)
      if (nextProps.match.params.mode === 'newUserViaEmail') {
        this.setState({ formPhase: 2, methodOfRegister: 'viaEmail' })
      } else {
        window.setTimeout(() => {
          if (typeof FB !== 'undefined') {
            if (FB && FB.XFBML) {
              FB.XFBML.parse()
            }
          }
        }, 500)
        this.setState({ formPhase: 1, methodOfRegister: '' })
      }
    }
  }

  // Helper methods
  validate = values => {
    const { formPhase, methodOfRegister } = this.state
    const isEditMode = this.isEditMode()
    let errors = {}

    // validations for Phase1
    if ((formPhase === 1 || formPhase === 1.5) && !isEditMode) {
      if (requiredValidation(values.email)) {
        errors.email = requiredValidation(values.email)
      } else if (emailValidation(values.email)) {
        errors.email = emailValidation(values.email)
      }
      if (requiredValidation(values.password)) {
        errors.password = requiredValidation(values.password)
      }
      return errors
    }

    // validations for Phase2 AND editMode

    /*if (requiredValidation(values.firstName)) {
      errors.firstName = requiredValidation(values.firstName)
    }
    if (requiredValidation(values.lastName)) {
      errors.lastName = requiredValidation(values.lastName)
    }*/
    /*if (requiredValidation(values.city)) {
      errors.city = requiredValidation(values.city)
    }
    if (requiredValidation(values.street)) {
      errors.street = requiredValidation(values.street)
    }*/
    if (requiredValidation(values.phone)) {
      errors.phone = requiredValidation(values.phone)
    }
    if (values.countryPhoneCode !== 'IL') {
      if (values.phone && phoneNumValidation(values.phone)) {
        errors.phone = phoneNumValidation(values.phone)
      }
    } else {
      if (values.phone && israelPhoneNumValidation(values.phone)) {
        errors.phone = israelPhoneNumValidation(values.phone)
      }
    }
    if (requiredValidation(values.email)) {
      errors.email = requiredValidation(values.email)
    } else if (emailValidation(values.email)) {
      errors.email = emailValidation(values.email)
    }
    if (requiredValidation(values.fullName)) {
      errors.fullName = requiredValidation(values.fullName)
    }

    // validation only for ADD MODE
    if (!isEditMode) {
      if (requiredValidation(values.password)) {
        errors.password = requiredValidation(values.password)
      }
      if (values.agreeTerms === false) {
        errors.agreeTerms = 'יש להסכים לתנאי השימוש'
      }
      if (values.captchaPassed !== true && !window.cordova) {
        errors.captchaPassed = 'שדה חובה'
      }
      if (formPhase === 2) {
        /*if (requiredValidation(values.confirmPassword)) {
          errors.confirmPassword = requiredValidation(values.confirmPassword)
        } else if (values.password !== values.confirmPassword) {
          errors.confirmPassword = 'סיסמאות לא תואמות'
        }*/
      }
    }

    // validation only for EDIT MODE
    if (isEditMode) {
      if (requiredValidation(values.birthDate)) {
        errors.birthDate = requiredValidation(values.birthDate)
      } else if (dateValidation(values.birthDate)) {
        errors.birthDate = dateValidation(values.birthDate)
      }
      if (requiredValidation(values.gender)) {
        errors.gender = requiredValidation(values.gender)
      }
      if (requiredValidation(values.fullName)) {
        errors.fullName = requiredValidation(values.fullName)
      }
    }

    return errors
  }

  isEditMode = () => {
    const { editMode } = this.props
    return editMode === true
  }

  loadAndPopulateUserData = async () => {
    const { initialValues } = this.state
    let currentUserData = await invokeAPIcall({ uri: 'api/users/getCurrentUser' })
    currentUserData = keysToLowerCase(currentUserData)

    const updatedValues = {
      ...initialValues,
      ...currentUserData,
      birthDate: currentUserData.birthDate ? moment(currentUserData.birthDate) : null,
      fullName: currentUserData.firstName ? currentUserData.firstName + ' ' + currentUserData.lastName : '',
      countryPhoneCode: currentUserData.countryPhoneCode || 'IL',
    }
    this.setState({ initialValues: updatedValues })
  }

  loadGlobalSettingsData = async id => {
    let globalSettingsData = await invokeAPIcall({ uri: `api/globalSettings/1` })
    this.setState({
      globalSettingsData,
    })
  }

  loginUser = async values => {
    const { setAccessToken, setCurrentUser, history, openSnackbar } = this.props
    try {
      // invoke API call to login
      const responseData = await invokeAPIcallWithFormData({
        uri: 'token',
        body: `grant_type=password&username=${values.email}&password=${values.password}`,
      })

      // if success
      if (!responseData.error) {
        setAccessToken({ accessToken: responseData.access_token })

        const currentUserData = await invokeAPIcall({ uri: 'api/users/getCurrentUser' })
        let setCurrentUserParams = { user: currentUserData }
        if (values.email.includes('@')) {
          setCurrentUserParams = { ...setCurrentUserParams, loginValues: values }
        }
        setCurrentUser(setCurrentUserParams)

        this.setState({ isFacebookLoading: false })
        history.push('/program')
      }
      // if failed
      else {
        this.setState({ showForgotPass: true })
        openSnackbar({ message: responseData.error_description, severity: 'failure' })
      }
    } catch (error) {
      console.log('error in login user', error)
    }
  }

  handleFacebookMeCallSuccess = facebookAccessToken => async response => {
    if (response && response.email) {
      this.socialLoginSuccess(facebookAccessToken, 'facebookToken', response)
    }
  }

  handleAppleCallSuccess = (appleAccessToken, userInfo) => {
    if (userInfo) {
      this.socialLoginSuccess(appleAccessToken, 'appleToken', userInfo)
    }
  }

  socialLoginSuccess = async (accessToken, socialNetworkTokenType, userInfo) => {
    const { initialValues } = this.state
    const updatedValues = {
      ...initialValues,
      fullName: userInfo.first_name + ' ' + userInfo.last_name,
      email: userInfo.email,
    }

    const responseData = await invokeAPIcall({ uri: `api/users/getIsEmailExist?email=${updatedValues.email}` })
    // this email exists - can't register
    if (responseData.Key === true) {
      // try to login the user (it's a special login, with socialNetwork access token)
      const loginValues = { email: accessToken, password: socialNetworkTokenType } // send the facebookToken/appleToken as the 'email' param
      this.loginUser(loginValues)
    }
    // email doesn't exist, progress to next phase
    else {
      this.setState({ initialValues: updatedValues, formPhase: 2, methodOfRegister: 'viaSocial' })
    }
  }

  loadCouponData = async couponCode => {
    let couponData = await invokeAPIcall({ uri: `api/coupons/getByCouponCode?code=${couponCode}` })
    this.setState({ appliedCoupon: couponData })
  }

  // UI event methods
  handleSubmit = async (values, { setSubmitting, setTouched, touched }) => {
    const { history, openSnackbar, setCurrentUser } = this.props
    const { formPhase } = this.state
    const isEditMode = this.isEditMode()
    const fullNameSpaceIndex = values.fullName.indexOf(' ')
    const data = {
      ...values,
      userTypeID: values.paidProgram === true ? 1 : 2,
      firstName: fullNameSpaceIndex > 0 ? values.fullName.substring(0, fullNameSpaceIndex) : values.fullName,
      lastName: fullNameSpaceIndex > 0 ? values.fullName.substring(fullNameSpaceIndex + 1) : '',
    }

    // process for FormPhase1
    if ((formPhase === 1 || formPhase === 1.5) && !isEditMode) {
      this.loginUser(values)

      /*const responseData = await invokeAPIcall({ uri: `api/users/getIsEmailExist?email=${values.email}` })

      // this email exists - can't register
      if (responseData.Key === true) {
        // try to login the user
        this.loginUser(values)
      }
      // email doesn't exist, progress to next phase
      else {
        this.setState({ formPhase: 2, methodOfRegister: 'viaEmail' })
        setTouched({ ...touched, email: false, password: false })
      }*/

      setSubmitting(false)
      return
    }

    // EDIT MODE
    if (isEditMode) {
      // invoke API call
      try {
        const responseData = await invokeAPIcall({ uri: 'api/users/UpdateUserInfo', data, method: 'PUT' })

        // if registration success
        if (responseData.Key === true) {
          openSnackbar({ message: responseData.Value, severity: 'success' })
          history.push('/')
        }
        // if failed
        else {
          openSnackbar({ message: responseData.Value, severity: 'failure' })
        }
      } catch (error) {
        console.log('error in updating user data', error)
      }
    }
    // ADD MODE
    else {
      // invoke API call
      try {
        const responseData = await invokeAPIcall({ uri: 'api/users', data, method: 'POST' })

        // if registration success
        if (responseData.Key === true) {
          setCurrentUser({ loginValues: values })
          this.setState({ registeredSuccess: true, registeredSuccessWithEmail: values.email })

          /*this.fetchTokenAndLoginUser(values)

          if (values.paidProgram === false) {
            history.push('/personalStats')
          } else {
            // go to PayPal, to pay for the program
            history.push('/challengePayment')
          }*/
        }
        // if failed
        else {
          openSnackbar({ message: responseData.Value, severity: 'failure' })
        }
      } catch (error) {
        console.log('error in adding new user', error)
      }
    }
    setSubmitting(false)
  }

  handleFacebookBtnClick = () => {
    if (window.cordova && window.facebookConnectPlugin) {
      // display the Cordova dialog of Facebook login
      window.facebookConnectPlugin.login(['email'], response => {
        const facebookAccessToken = response && response.authResponse && response.authResponse.accessToken
        this.setState({ isFacebookLoading: true })
        window.facebookConnectPlugin.api(FACEBOOK_ME_URL, ['email'], this.handleFacebookMeCallSuccess(facebookAccessToken))
      })
    }
  }

  handleAppleBtnClick = () => {
    if (window.cordova && window.cordova.plugins.SignInWithApple) {
      // display the Cordova dialog of Apple login
      window.cordova.plugins.SignInWithApple.signin(
        { requestedScopes: [0, 1] },
        async data => {
          const appleAccessToken = data && data.authorizationCode
          let email = data && data.email
          const first_name = data && data.fullName && data.fullName.givenName
          const last_name = data && data.fullName && data.fullName.familyName
          this.setState({ isFacebookLoading: true })

          // we fetch the user's email by the Apple authCode
          const responseData = await invokeAPIcall({
            uri: `api/users/LinkUserToAppleAuthCode?appleAuthCode=${appleAccessToken}`,
            data: {},
            method: 'PUT',
          })
          if (responseData.Key === true) {
            email = responseData.Value
            this.handleAppleCallSuccess(appleAccessToken, { email, first_name, last_name })
          }
        },
        err => {
          console.log('Apple sign-in error')
        },
      )
    }
  }

  handleClickShowPassword = () => {
    this.setState({ showPasswordText: !this.state.showPasswordText })
  }

  // Render methods
  render() {
    const {
      initialValues,
      globalSettingsData,
      registeredSuccess,
      registeredSuccessWithEmail,
      formPhase,
      methodOfRegister,
      isFacebookLoading,
      showForgotPass,
      appliedCoupon,
      showPasswordText,
    } = this.state
    const challengePrice = globalSettingsData ? globalSettingsData.ChallengePrice : 0
    const isEditMode = this.isEditMode()

    const years = []
    const maxYear = moment().year()
    const minYear = moment()
      .subtract(100, 'years')
      .year()
    for (var i = minYear; i <= maxYear; i++) {
      years.push(i)
    }
    const months = []
    const maxMonth = 12
    const minMonth = 1
    for (var i = minMonth; i <= maxMonth; i++) {
      months.push(i)
    }
    const days = []
    const maxDay = 31
    const minDay = 1
    for (var i = minDay; i <= maxDay; i++) {
      days.push(i)
    }

    let pageTitle = 'החשבון שלי'
    if (!isEditMode && formPhase === 1) {
      pageTitle = 'כניסה לחוויית החיטוב'
    }
    if (!isEditMode && formPhase === 1.5) {
      pageTitle = 'חשבון קיים, הזן את הסיסמה'
    } else if (!isEditMode && formPhase === 2) {
      pageTitle = 'יצירת חשבון חדש'
    }

    return (
      <PageWrapper>
        <Breadcrumbs previousItems={[]} currentItem={pageTitle} />
        {!registeredSuccess && <PageTitle>{pageTitle}</PageTitle>}
        <FormWrapper>
          {registeredSuccess && (
            <>
              <PageTitle>נרשמת בהצלחה ל-FitThyBody</PageTitle>
              <div>
                ברגעים אלו נשלח למייל שלך <SuccessEmailLabel>{registeredSuccessWithEmail}</SuccessEmailLabel> אישור להפעלת חשבונך באתר.
                <br />
                להשלמת התהליך, יש ללחוץ על הקישור שקיבלת במייל כדי להתחבר.
              </div>
            </>
          )}
          {!registeredSuccess && (
            <>
              {/*formPhase === 1 && !isEditMode && <InnerTitle>לקוח חוזר</InnerTitle>*/}
              <Formik validate={this.validate} initialValues={initialValues} onSubmit={this.handleSubmit} enableReinitialize={true}>
                {formikBag => {
                  const {
                    isSubmitting,
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    setFieldTouched,
                    isValid,
                  } = formikBag

                  const emailFieldComponent = (
                    <StyledTextField
                      label={'כתובת דוא"ל'}
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={errors.email && touched.email && errors.email}
                      error={errors.email && touched.email}
                      margin="normal"
                      fullWidth
                      type="email"
                      autoComplete="email"
                      required
                    />
                  )
                  const passwordFieldComponent = (
                    <StyledTextField
                      label={'סיסמה'}
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={errors.password && touched.password && errors.password}
                      error={errors.password && touched.password}
                      margin="normal"
                      fullWidth
                      type={showPasswordText ? 'text' : 'password'}
                      autoComplete="new-password"
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={this.handleClickShowPassword}>
                              {showPasswordText ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )
                  const fullNameFieldComponent = (
                    <StyledTextField
                      label={'שם מלא'}
                      name="fullName"
                      value={values.fullName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={errors.fullName && touched.fullName && errors.fullName}
                      error={errors.fullName && touched.fullName}
                      margin="normal"
                      fullWidth
                      required
                    />
                  )
                  const genderBirthDateRowComponent = (
                    <FieldsRow>
                      <FieldCell>
                        <StyledSelect
                          selectParams={{
                            name: 'gender',
                            value: values.gender,
                            onChange: handleChange,
                            onBlur: handleBlur,
                            margin: 'normal',
                            fullWidth: true,
                            inputProps: {
                              name: 'gender',
                              id: 'gender-simple',
                            },
                          }}
                          label="מין"
                          htmlFor="gender-simple"
                          helperText={errors.gender && touched.gender && errors.gender}
                          error={errors.gender && touched.gender}
                          required
                        >
                          <MenuItem value={'male'}>זכר</MenuItem>
                          <MenuItem value={'female'}>נקבה</MenuItem>
                        </StyledSelect>
                      </FieldCell>
                      <FieldCell>
                        <BirthDateField
                          values={values}
                          errors={errors}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                        />
                      </FieldCell>
                    </FieldsRow>
                  )
                  const addressRowComponent = (
                    <FieldsRow>
                      <FieldCell>
                        <StyledTextField
                          label={'רחוב (מספר, קומה, דירה)'}
                          name="street"
                          value={values.street}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={errors.street && touched.street && errors.street}
                          error={errors.street && touched.street}
                          margin="normal"
                          fullWidth
                        />
                      </FieldCell>
                      <CityCountryFieldCell>
                        <StyledTextField
                          label={'עיר'}
                          name="city"
                          value={values.city}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={errors.city && touched.city && errors.city}
                          error={errors.city && touched.city}
                          margin="normal"
                          fullWidth
                        />
                        <StyledSelect
                          selectParams={{
                            name: 'country',
                            value: values.country,
                            onChange: handleChange,
                            onBlur: handleBlur,
                            margin: 'normal',
                            fullWidth: true,
                            inputProps: {
                              name: 'country',
                              id: 'country-simple',
                            },
                          }}
                          label="מדינה"
                          htmlFor="country-simple"
                          helperText={errors.country && touched.country && errors.country}
                          error={errors.country && touched.country}
                        >
                          {countryList.map(item => (
                            <MenuItem value={item.value}>{item.label}</MenuItem>
                          ))}
                        </StyledSelect>
                      </CityCountryFieldCell>
                    </FieldsRow>
                  )
                  const phoneFieldComponent = (
                    <>
                      <StyledTextField
                        label={'טלפון'}
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.phone && touched.phone && errors.phone}
                        error={errors.phone && touched.phone}
                        margin="normal"
                        fullWidth
                        required
                      />
                      <CountryPhoneCode
                        value={values.countryPhoneCode}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        errors={errors}
                        touched={touched}
                      />
                    </>
                  )
                  const confirmPasswordFieldComponent = (
                    <StyledTextField
                      label={'אימות סיסמה'}
                      name="confirmPassword"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={errors.confirmPassword && touched.confirmPassword && errors.confirmPassword}
                      error={errors.confirmPassword && touched.confirmPassword}
                      margin="normal"
                      fullWidth
                      type="password"
                      autoComplete="new-password"
                      required
                    />
                  )

                  return (
                    <Form>
                      {formPhase === 1 && !isEditMode && (
                        <Phase1FormWrapper>
                          <FieldsRow>
                            <FieldCell>{emailFieldComponent}</FieldCell>
                            <FieldCell>{passwordFieldComponent}</FieldCell>
                          </FieldsRow>
                          <Phase1Buttons>
                            <StyledLink to="/forgotPassword">שכחת סיסמה?</StyledLink>
                            <StyledButton
                              variant="contained"
                              color="primary"
                              fullWidth
                              size="large"
                              onClick={e => {
                                handleSubmit(e)
                              }}
                            >
                              כניסה
                            </StyledButton>
                          </Phase1Buttons>
                        </Phase1FormWrapper>
                      )}
                      {formPhase === 1.5 && !isEditMode && (
                        <Phase1FormWrapper>
                          <FieldsRow>
                            <FieldCell>{emailFieldComponent}</FieldCell>
                            <FieldCell>{passwordFieldComponent}</FieldCell>
                          </FieldsRow>
                          <Phase1Buttons>
                            <StyledButton
                              variant="contained"
                              color="primary"
                              fullWidth
                              size="large"
                              onClick={e => {
                                handleSubmit(e)
                              }}
                            >
                              כניסה לחוויית החיטוב
                            </StyledButton>
                          </Phase1Buttons>
                        </Phase1FormWrapper>
                      )}
                      {formPhase === 2 && !isEditMode && (
                        <>
                          <FieldsRow>
                            <FieldCell>{fullNameFieldComponent}</FieldCell>
                            <FieldCell>{emailFieldComponent}</FieldCell>
                          </FieldsRow>
                          <FieldsRow>
                            <FieldCell>{passwordFieldComponent}</FieldCell>
                            <PhoneFieldCell>{phoneFieldComponent}</PhoneFieldCell>
                            {/*<FieldCell>{confirmPasswordFieldComponent}</FieldCell>*/}
                          </FieldsRow>
                          <FormControl required error={errors.agreeTerms && touched.agreeTerms}>
                            <StyledFormControlLabel
                              control={
                                <Checkbox
                                  name="agreeTerms"
                                  checked={values.agreeTerms}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.agreeTerms}
                                  color="primary"
                                />
                              }
                              label={
                                <TermsOfService>
                                  אני מסכים לתנאי השימוש (
                                  <a
                                    onClick={() => {
                                      window.open('https://www.fitthybody.com/contentPage/termsOfService', '_blank')
                                    }}
                                  >
                                    תקנון ותנאי שימוש
                                  </a>
                                  )
                                </TermsOfService>
                              }
                            />
                            <StyledFormHelperText>{errors.agreeTerms && touched.agreeTerms && errors.agreeTerms}</StyledFormHelperText>
                          </FormControl>
                          {!window.cordova && (
                            <CaptchaWrapper>
                              <ReCAPTCHA
                                sitekey="6LeKrsAUAAAAAMHcKa_l60MwgXE9tHl0_lb0eGk7"
                                onChange={value => {
                                  if (value) {
                                    setFieldValue('captchaPassed', true, false)
                                  }
                                }}
                              />
                              <FormControl required error={errors.captchaPassed}>
                                <StyledFormHelperText>{errors.captchaPassed}</StyledFormHelperText>
                              </FormControl>
                            </CaptchaWrapper>
                          )}
                          <ButtonsWrapper>
                            <StyledButton
                              variant="contained"
                              color="primary"
                              fullWidth
                              size="large"
                              disabled={isSubmitting}
                              onClick={e => {
                                setFieldValue('paidProgram', true, false)
                                handleSubmit(e)
                              }}
                              singleButton
                            >
                              להתחלת התוכנית
                              {/*<ButtonSmallLabel>{TRIAL_DAYS} ימים במתנה</ButtonSmallLabel>*/}
                            </StyledButton>
                            {/*<StyledSecondaryButton
                                variant="contained"
                                color="secondary"
                                fullWidth
                                size="large"
                                disabled={isSubmitting}
                                onClick={e => {
                                  setFieldValue('paidProgram', true, false)
                                  handleSubmit(e)
                                }}
                              >
                                לרכישת חוויית הפרימיום
                                {appliedCoupon && appliedCoupon.SpecificProductID === null ? (
                                  <ButtonSmallLabel>
                                    הנחה של{' '}
                                    {appliedCoupon.FixedDiscount
                                      ? `₪${appliedCoupon.FixedDiscount}`
                                      : appliedCoupon.PercentageDiscount
                                      ? `${appliedCoupon.PercentageDiscount}%`
                                      : null}
                                  </ButtonSmallLabel>
                                ) : null}
                                    </StyledSecondaryButton>*/}
                          </ButtonsWrapper>
                        </>
                      )}
                      {isEditMode && (
                        <>
                          {fullNameFieldComponent}
                          {genderBirthDateRowComponent}
                          <FieldsRow>
                            <PhoneFieldCell>{phoneFieldComponent}</PhoneFieldCell>
                            <FieldCell>{emailFieldComponent}</FieldCell>
                          </FieldsRow>
                          <ButtonsWrapper>
                            <StyledButton
                              variant="contained"
                              color="primary"
                              fullWidth
                              size="large"
                              disabled={isSubmitting}
                              onClick={e => {
                                handleSubmit(e)
                              }}
                              singleButton
                            >
                              עדכון פרטים
                            </StyledButton>
                          </ButtonsWrapper>
                        </>
                      )}
                      {isSubmitting && (
                        <LoadingIndicatorWrapper>
                          <CircularProgress />
                        </LoadingIndicatorWrapper>
                      )}
                    </Form>
                  )
                }}
              </Formik>
              {formPhase === 1 && !isEditMode && (
                <Phase1TitleWrapper>
                  <OrLabelWrapper>
                    <OrLabelLine />
                    או
                    <OrLabelLine />
                  </OrLabelWrapper>
                  <NotRegisteredYetButton>
                    <NotRegisteredYetButtonLink to="/registerToChallenge/newUserViaEmail">
                      <StyledButton variant="contained" color="primary" size="large">
                        יצירת חשבון חדש
                      </StyledButton>
                    </NotRegisteredYetButtonLink>
                  </NotRegisteredYetButton>
                  <OrLabelWrapper>
                    <OrLabelLine />
                    או
                    <OrLabelLine />
                  </OrLabelWrapper>
                  <InnerTitle>כניסה באמצעות מדיה חברתית</InnerTitle>
                  {!isFacebookLoading && (
                    <>
                      <FacebookBtnWrapper>
                        {window.cordova && (
                          <FacebookBtnCordovaOverlay onClick={this.handleFacebookBtnClick}>
                            <StyledFBButton variant="contained" color="primary" size="large" startIcon={<FacebookIcon />}>
                              כניסה עם Facebook
                            </StyledFBButton>
                          </FacebookBtnCordovaOverlay>
                        )}
                        <div
                          id="fb-login-button"
                          class="fb-login-button"
                          data-width=""
                          data-size="large"
                          data-button-type="continue_with"
                          data-auto-logout-link="false"
                          data-use-continue-as="true"
                          data-scope="email"
                        ></div>
                      </FacebookBtnWrapper>
                      {window.cordova && window.device && window.device.platform.toLowerCase() === 'ios' && (
                        <AppleBtnWrapper>
                          <StyledFBButton
                            variant="contained"
                            color="primary"
                            size="large"
                            startIcon={<AppleIcon />}
                            onClick={this.handleAppleBtnClick}
                          >
                            כניסה עם Apple
                          </StyledFBButton>
                        </AppleBtnWrapper>
                      )}
                    </>
                  )}
                  {isFacebookLoading && (
                    <LoadingIndicatorWrapper>
                      <CircularProgress />
                    </LoadingIndicatorWrapper>
                  )}
                </Phase1TitleWrapper>
              )}
            </>
          )}
        </FormWrapper>
      </PageWrapper>
    )
  }
}

export default withRouter(RegisterScreen)
