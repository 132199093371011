import { connect } from 'react-redux'
import MyPersonalStatsProfileScreen from '../components/MyPersonalStatsProfileScreen'

export default connect(
  state => ({
    currentUser: state.currentUser.user,
    personalStats: state.currentUser.personalStats,
  }),
  {},
)(MyPersonalStatsProfileScreen)
