import React, { Component } from 'react'
import styled from 'styled-components'
import EcoIcon from '@material-ui/icons/Eco'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import { mediaQuery, isScrolledIntoView, photoUrlPrefix } from '../../services/global'
import { getString } from '../../services/presetStringsHelper'
import { invokeAPIcall } from '../../services/rest'
import BeforeAfterBox from './BeforeAfterBox'

// Style
const MotivationCarouselItemWrapper = styled.div`
  background-color: ${colorPalette.primary1.main};
  border-bottom-left-radius: ${globalStyles.mainBoxRadius};
  border-bottom-right-radius: ${globalStyles.mainBoxRadius};
  color: ${colorPalette.white.main};
  box-shadow: ${globalStyles.mainBoxShadow};
  position: relative;
  top: -80px;
  z-index: 16;
  padding: 30px 0;
  padding-top: 75px;
  display: none;
  ${mediaQuery.phone`
    height: auto;
  `}
`
const BeforeAfterPhotosCarouselItemWrapper = styled(MotivationCarouselItemWrapper)`
  background-color: ${colorPalette.white.main};
  color: ${colorPalette.black.main};
  padding: 10px 0;
  z-index: 15;
  top: -28px;
  text-align: right;
  display: block;
  ${mediaQuery.phone`
  `}
`
const ContentBox = styled.div`
  font-size: 22px;
  display: flex;
  ${mediaQuery.phone`
    position: static;
    width: auto;
    padding: 0 10px;
    flex-direction: column;
  `}
`
const PromotionItemsList = styled.ul`
  flex: 3;
  ${mediaQuery.phone`
    padding: 20px;
    font-size: 16px;
  `}
`
const PromotionItem = styled.li`
  span {
    display: none;
    font-size: 14px;
  }
  &:hover span {
    display block;
  }
  ${mediaQuery.phone`
    ${props => (props.hideInMobile ? 'display: none;' : '')}
  `}
  transition: transform 1s, opacity 3s;
  transition-delay: ${props => 0 + props.index * 0.1}s;
  transform: ${props => (props.isActive ? 'translateX(0%)' : 'translateX(100%)')};
  opacity: ${props => (props.isActive ? '1' : '0')};
`
const ItemTitle = styled.div`
  display: flex;
  align-items: center;
  ${mediaQuery.phone`
    align-items: flex-start;
  `}
`
const StyledDoneOutlineIcon = styled(EcoIcon)`
  && {
    width: 22px;
    margin-left: 10px;
  }
`
const BeforeAfterBoxWrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colorPalette.white.main};
  font-size: 24px;
  ${mediaQuery.phone`
    margin: 10px auto;
  `}
  transition: transform 0.5s, opacity 2s;
  transition-delay: 1s;
  transform: ${props => (props.isActive ? 'translateX(0%)' : 'translateX(100%)')};
  opacity: ${props => (props.isActive ? '1' : '0')};
`
const BeforeAfterPhotosBottomBar = styled.ul`
  display: flex;
  justify-content: center;
  position: relative;
  top: -45px;
  background: ${colorPalette.tertiary1.light};
  border-bottom-left-radius: ${globalStyles.mainBoxRadius};
  border-bottom-right-radius: ${globalStyles.mainBoxRadius};
  box-shadow: ${globalStyles.mainBoxShadow};
  overflow: hidden;
  flex-wrap: wrap;
  max-height: 80px;
  z-index: 13;
`
const BeforeAfterPhoto = styled.li`
  width: 96px;
  height: 80px;
  background-image: url('${props => props.photoUrl}');
  background-size: cover;
  background-position: center 15px;
`
const PromotionTitle = styled.h2`
  font-size: 37px;
  font-weight: 500;
  margin: 20px 0;
  ${mediaQuery.tablet`
    font-size: 30px;
  `}
  ${mediaQuery.phone`
    font-size: 25px;
    text-align: center;
    margin: 0 20px;
    margin-top: 20px;
  `}
`

// Component
class MotivationCarouselItem extends Component {
  state = { showPromotionItems: true, isVisible: true, beforeAfterPhotos: [] }

  // Lifecycle methods
  componentDidMount() {
    this.loadBeforeAfterPhotos()

    window.setTimeout(() => {
      //this.setState({ showPromotionItems: true })
    }, 0)

    window.addEventListener('scroll', () => {
      const isInView = isScrolledIntoView(document.getElementById('MotivationCarouselItemWrapper'))
      const { isVisible } = this.state
      if (this.props.isActive && !isVisible) {
        //this.setState({ isVisible: isInView })
      }
    })
  }

  // Helper methods
  loadBeforeAfterPhotos = async () => {
    const beforeAfterPhotos = await invokeAPIcall({ uri: 'api/beforeAfterPhotos' })
    this.setState({ beforeAfterPhotos })
  }

  // Render methods
  renderPhotoItem = photo => {
    let photoUrl = photo.PhotoURL
    if (photoUrl) {
      if (!photoUrl.includes('http')) {
        photoUrl = `${photoUrlPrefix}/beforeAfterPhotos/${photoUrl}`
      }
    }
    return <BeforeAfterPhoto photoUrl={photoUrl}></BeforeAfterPhoto>
  }

  render() {
    const { showPromotionItems, isVisible, beforeAfterPhotos } = this.state
    return (
      <>
        <BeforeAfterPhotosCarouselItemWrapper>
          <BeforeAfterBoxWrapper isActive={showPromotionItems && isVisible}>
            <BeforeAfterBox beforeAfterPhotos={beforeAfterPhotos.filter(item => item.IsHighlighted)} />
          </BeforeAfterBoxWrapper>
        </BeforeAfterPhotosCarouselItemWrapper>
        <BeforeAfterPhotosBottomBar>
          {beforeAfterPhotos.map(this.renderPhotoItem)}
          {beforeAfterPhotos.map(this.renderPhotoItem)}
        </BeforeAfterPhotosBottomBar>
      </>
    )
  }
}

export default MotivationCarouselItem
