import { connect } from 'react-redux'
import SetNewPasswordFromExistingScreen from '../components/SetNewPasswordFromExistingScreen'
import { openSnackbar } from '../bundles/snackbar'

export default connect(
  state => ({}),
  {
    openSnackbar,
  },
)(SetNewPasswordFromExistingScreen)
