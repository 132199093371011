import React, { Component } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import { mediaQuery } from '../services/global'

// Style
const StyledDialogTitle = styled(DialogTitle)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
  }
`
const StyledButton = styled(Button)`
  && {
    margin-left: 10px;
  }
  ${mediaQuery.phone`
    && {
      margin-left: 0;
      margin-bottom: 10px;
      flex: 1;
    }
  `}
`
const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  ${mediaQuery.phone`
    flex-direction: column;
    margin-bottom: 0;
  `}
`

// Consts

// Component
class ConfirmDialog extends Component {
  state = {}

  // Lifecycle methods
  componentDidMount() {}

  // Helper methods

  // UI event methods

  // Render methods
  render() {
    const { title, text, handleClose, handleSubmit } = this.props
    return (
      <Dialog onClose={handleClose} open>
        <StyledDialogTitle id="simple-dialog-title" disableTypography>
          <Typography variant="h6">{title}</Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <DialogContent>
          {text}
          <ButtonsWrapper>
            <StyledButton variant="contained" color="primary" onClick={handleSubmit}>
              כן
            </StyledButton>
            <StyledButton variant="contained" color="primary" onClick={handleClose}>
              לא
            </StyledButton>
          </ButtonsWrapper>
        </DialogContent>
      </Dialog>
    )
  }
}

export default withRouter(ConfirmDialog)
