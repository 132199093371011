import React, { Component } from 'react'
import styled from 'styled-components'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import { mediaQuery } from '../services/global'
import { invokeAPIcall } from '../services/rest'
import { colorPalette, globalStyles } from '../styles/styleVariables'
import heImage from '../images/languages/he.png'
import enImage from '../images/languages/en.png'
import googleTranslateLogo from '../images/googleTranslate.png'

// Consts
const langOptions = { he: { icon: heImage }, en: { icon: enImage } }

// Style
const LangSelectorWrapper = styled.div`
  height: 100%;
  position: relative;
  ${mediaQuery.phone`
    display: none;
  `}
`
const LangButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 100%;
  padding: 15px 13px;
  background-color: ${props => (props.isActive ? colorPalette.white.main : 'transparent')};
  color: ${props => (props.isActive ? colorPalette.primary1.main : colorPalette.white.main)};
  ${globalStyles.customButton}
`
const SelectedFlag = styled.div`
  background: url(${props => langOptions[props.selectedLang].icon});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 24px;
  height: 14px;
`
const LangSelectionOptionsMenu = styled.div`
  position: absolute;
  top: 44px;
  left: 0;
  background-color: ${colorPalette.white.main};
  ${globalStyles.boxRadius}
  ${globalStyles.boxShadowPopup}
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  overflow: hidden;
  min-width: 80px;
  transition: all 0.3s;
  height: ${props => (props.isActive ? '110px' : '0px')};
`
const StyledMenuItem = styled(MenuItem)`
  && {
    color: ${colorPalette.primary1.main};
  }
`
const StyledListItemIcon = styled(ListItemIcon)`
  && {
    min-width: 32px;
  }
`
const HebrewIcon = styled.div`
  background: url(${heImage});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 24px;
  height: 28px;
`
const EnglishIcon = styled.div`
  background: url(${enImage});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 24px;
  height: 28px;
`
const GoogleTranslateButton = styled.div`
  background-image: url(${googleTranslateLogo});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 24px;
  height: 24px;
`
const StyledDialog = styled(Dialog)`
  && {
    direction: ltr;
    text-align: left;
  }
`
const StyledDialogTitle = styled(DialogTitle)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
  }
`
const ButtonsWrapper = styled.div`
  margin: 20px 0;
`

// Component
class LangSelector extends Component {
  state = {
    langButtonClicked: false,
    selectedLang: 'he',
    showNonIsraeliUserDialog: false,
  }

  // Lifecycle methods
  componentDidMount() {
    this.showAlertForNonIsraeliUsers()
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  // Helper methods
  showAlertForNonIsraeliUsers = async () => {
    const userCountryInfo = await invokeAPIcall({ uri: 'api/users/getUserCountry' })
    if (userCountryInfo && userCountryInfo.country_name.toLowerCase() !== 'israel') {
      this.setState({ showNonIsraeliUserDialog: true })
    }
  }

  // UI event methods
  handleLangButtonClick = () => {
    const { langButtonClicked } = this.state
    this.setState({ langButtonClicked: !langButtonClicked })
  }

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.buttonRef && !this.buttonRef.contains(event.target)) {
      this.setState({ langButtonClicked: false })
    }
  }

  handleHebrewClick = () => {
    this.setState({ selectedLang: 'he' })
    this.setState({ langButtonClicked: false })
  }

  handleEnglishClick = () => {
    this.setState({ selectedLang: 'en' })
    this.setState({ langButtonClicked: false })
  }

  handleGoogleTranslateClick = () => {
    const SHARED_URL = window.location.href
    window.location = `https://translate.google.com/translate?hl=&sl=iw&tl=en&u=${SHARED_URL}`
  }

  handleNonIsraeliDialogClose = () => {
    this.setState({ showNonIsraeliUserDialog: false })
  }

  setWrapperRef = node => {
    this.wrapperRef = node
  }

  setButtonRef = node => {
    this.buttonRef = node
  }

  render() {
    const { langButtonClicked, selectedLang, showNonIsraeliUserDialog } = this.state
    return (
      <LangSelectorWrapper>
        {/*<LangButton
          isActive={langButtonClicked}
          onClick={this.handleGoogleTranslateClick //this.handleLangButtonClick}
          ref={this.setButtonRef}
          title="החלף שפה"
          type="button"
        >
          <GoogleTranslateButton title="Google Translate" />
          //<SelectedFlag selectedLang={selectedLang} />
        </LangButton>*/}
        <LangSelectionOptionsMenu isActive={langButtonClicked} ref={this.setWrapperRef}>
          <MenuList>
            <StyledMenuItem onClick={this.handleHebrewClick}>
              <StyledListItemIcon>
                <HebrewIcon />
              </StyledListItemIcon>
              <span>עברית</span>
            </StyledMenuItem>
            <StyledMenuItem onClick={this.handleEnglishClick}>
              <StyledListItemIcon>
                <EnglishIcon />
              </StyledListItemIcon>
              <span>English</span>
            </StyledMenuItem>
          </MenuList>
        </LangSelectionOptionsMenu>
        <StyledDialog onClose={this.handleNonIsraeliDialogClose} open={showNonIsraeliUserDialog}>
          <StyledDialogTitle id="simple-dialog-title" disableTypography>
            <Typography variant="h6">Choose your language</Typography>
            <IconButton aria-label="close" onClick={this.handleNonIsraeliDialogClose}>
              <CloseIcon />
            </IconButton>
          </StyledDialogTitle>
          <DialogContent>
            In order to change the website's language, please click here to choose another language via Google Translate:
            <ButtonsWrapper>
              <Button variant="contained" color="primary" onClick={this.handleGoogleTranslateClick}>
                Change Language
              </Button>
            </ButtonsWrapper>
          </DialogContent>
        </StyledDialog>
      </LangSelectorWrapper>
    )
  }
}

export default LangSelector
