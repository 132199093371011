import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { withRouter } from 'react-router-dom'
import MicIcon from '@material-ui/icons/Mic'
import VolumeIcon from '@material-ui/icons/VolumeUp'
import successIcon from '../../images/successIcon.png'
import { mediaQuery } from '../../services/global'
import { invokeAPIcall } from '../../services/rest'
import { colorPalette } from '../../styles/styleVariables'
import Breadcrumbs from '../Breadcrumbs'

// Consts

// Style
const PageWrapper = styled.div`
  padding: 20px;
  padding-top: 0;
  flex: 1;
  ${mediaQuery.phone`
    background: none;
    padding: 20px;
  `}
`
const SuccessScreenContent = styled.div`
  display: flex;
  ${mediaQuery.phone`
    width: auto;
    padding: 0 10px;
    flex-direction: column;
  `}
`
const SuccessTitleSection = styled.div`
  flex: 1.5;
  margin-left: 30px;
  ${mediaQuery.phone`
    margin-bottom: 30px;
    margin-left: 0;
  `}
`
const SuccessTitle = styled.div`
  background-color: ${colorPalette.primary1.main};
  color: ${colorPalette.white.main};
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 4px 10px;
  display: flex;
  align-items: center;
`
const SuccessTitleLabel = styled.div`
  color: ${colorPalette.white.main};
  font-size: 36px;
  font-weight: 500;
  margin-right: 20px;
  ${mediaQuery.phone`
    font-size: 30px;
  `}
`
const iconCSS = css`
  && {
    width: 50px;
    height: 50px;
    color: ${colorPalette.white.main};
  }
`
const SuccessStepsWrapper = styled.div`
  flex: 3;
  ${mediaQuery.tablet`
    flex: 1;
  `}
`
const SuccessIcon = styled.div`
  width: 40px;
  height: 40px;
  background-image: url(${successIcon});
  background-size: contain;
  background-repeat: no-repeat;
`
const SuccessSteps = styled.ul`
  display: flex;
`
const StepButton = styled.li`
  background-color: ${props => (props.selected ? colorPalette.primary1.main : colorPalette.primary1.dark)};
  color: ${colorPalette.white.main};
  font-weight: 500;
  padding: 10px 30px;
  margin-left: 2px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  cursor: pointer;
  ${mediaQuery.phone`
    font-size: 13px;
  `}
`
const SuccessTextWrapper = styled.div`
  background-color: ${colorPalette.primary1.main};
  color: ${colorPalette.white.main};
  padding: 30px;
  min-height: 80px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`
const StepTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 28px;
  font-weight: 500;
  svg {
    margin-left: 10px;
    width: 50px;
    height: 50px;
  }
`
const StepContentItems = styled.ul`
  margin-right: 60px;
`
const StepItem = styled.li`
  margin-top: 20px;
`
const StepItemTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
`
const StepItemDesc = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  svg {
    margin-right: 5px;
    cursor: pointer;
  }
`

// Consts
const stepsArr = [
  {
    title: 'הכנות נפשיות',
    items: [
      { title: 'התחדשות שרירים: הפעלת הרפיה המאפשרת מנוחה שתורמת להגברת האנרגיה', desc: 'להקשיב כרצונך', file: '' },
      { title: 'גאווה ובטחון עצמי', desc: 'להאזנה לתחילת היום ה-16 בחודש השני', file: '' },
      { title: 'עתידנות רגשית', desc: 'להאזין פעם אחת או יותר במהלך השבוע האחרון', file: '' },
    ],
  },
  {
    title: 'כותרת שלב 2',
    items: [
      { title: 'אייטם ראשון', desc: 'להקשיב כרצונך', file: '' },
      { title: 'אייטם שני', desc: 'להאזנה לתחילת היום ה-16 בחודש השני', file: '' },
      { title: 'עתידנות רגשית', desc: 'להאזין פעם אחת או יותר במהלך השבוע האחרון', file: '' },
    ],
  },
  {
    title: 'כותרת שלב שלישי',
    items: [
      { title: 'אייטם ראשון בשלב 3', desc: 'להקשיב כרצונך', file: '' },
      { title: 'אייטם שני בשלב 3', desc: 'להאזנה לתחילת היום ה-16 בחודש השני', file: '' },
      { title: 'עתידנות רגשית', desc: 'להאזין פעם אחת או יותר במהלך השבוע האחרון', file: '' },
    ],
  },
]

// Component
class MySuccessWayScreen extends Component {
  state = {
    selectedStep: 1,
  }

  // Lifecycle methods
  componentDidMount() {}

  // Helper methods

  // UI event methods
  handleStepButtonClick = step => {
    this.setState({ selectedStep: step })
  }

  // Render methods
  render() {
    const { selectedStep } = this.state
    const selectedStepData = stepsArr[selectedStep - 1]
    return (
      <PageWrapper>
        <Breadcrumbs
          previousItems={[
            {
              label: 'חוויית החיטוב ב-90 יום',
              url: `/program`,
            },
          ]}
          currentItem="הדרך להצלחה"
        />
        <SuccessScreenContent>
          <SuccessTitleSection>
            <SuccessTitle>
              <SuccessIcon />
              <SuccessTitleLabel>הדרך להצלחה</SuccessTitleLabel>
            </SuccessTitle>
            <audio controls>
              <source src="https://www.w3schools.com/html/horse.ogg" type="audio/ogg" />
              <source src="https://www.w3schools.com/html/horse.mp3" type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </SuccessTitleSection>
          <SuccessStepsWrapper>
            <SuccessSteps>
              <StepButton selected={selectedStep === 1} onClick={this.handleStepButtonClick.bind(this, 1)}>
                שלב 1
              </StepButton>
              <StepButton selected={selectedStep === 2} onClick={this.handleStepButtonClick.bind(this, 2)}>
                שלב 2
              </StepButton>
              <StepButton selected={selectedStep === 3} onClick={this.handleStepButtonClick.bind(this, 3)}>
                שלב 3
              </StepButton>
            </SuccessSteps>
            <SuccessTextWrapper>
              <StepTitle>
                <MicIcon />
                {selectedStepData.title}
              </StepTitle>
              <StepContentItems>
                {selectedStepData.items.map(item => (
                  <StepItem>
                    <StepItemTitle>{item.title}</StepItemTitle>
                    <StepItemDesc>
                      {item.desc}
                      <VolumeIcon />
                    </StepItemDesc>
                  </StepItem>
                ))}
              </StepContentItems>
            </SuccessTextWrapper>
          </SuccessStepsWrapper>
        </SuccessScreenContent>
      </PageWrapper>
    )
  }
}

export default withRouter(MySuccessWayScreen)
