import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import Carousel from 'react-bootstrap/Carousel'
import FitnessIcon from '@material-ui/icons/FitnessCenter'
import ChatIcon from '@material-ui/icons/Chat'
import ChallengeIcon from '@material-ui/icons/EmojiEvents'
import StandingIcon from '@material-ui/icons/EmojiPeople'
import { mediaQuery, isAdminUser } from '../../services/global'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import naturalExtractIcon from '../../images/naturalExtractIconColor.png'
import ChallengeCarouselItem from './ChallengeCarouselItem'
import ArticlesCarouselItem from './ArticlesCarouselItem'
import TrainersCarouselItem from './TrainersCarouselItem'
import OurTrainersCarouselItem from './OurTrainersCarouselItem'
import HighlightProductsCarouselItem from './HighlightProductsCarouselItem'
import OurHighlightProductsCarouselItem from './OurHighlightProductsCarouselItem'
import FeedbackCarouselItem from './FeedbackCarouselItem'
import CordovaLoginButtons from './CordovaLoginButtons'
import AppleGoogleButtons from './AppleGoogleButtons'

// Style
const StyledCarousel = styled(Carousel)`
  && {
  }
  ${mediaQuery.phone`
    && {
      margin-top: -25px;
    }
  `}
`
const VideoContainer = styled.div`
  padding: 60px;
  padding-top: 0px;
  bottom: -30px;
  position: relative;
  display: none;
  ${mediaQuery.phone`
    padding-left: 5px;
    padding-right: 5px;
  `}
`
const VideoWrapper = styled.div`
  margin: auto;
  width: 55%;
  background: ${colorPalette.primary1.main};
  padding: 30px 0;
  border-radius: 30px;
  video {
    width: 100%;
    height: 500px;
    background: ${colorPalette.black.main};
    display: none;
  }
  ${mediaQuery.phone`
    width: 100%;
  `}
`
const StyledIframe = styled.iframe`
  width: 100%;
  ${mediaQuery.phone`
    height: 200px;
  `}
`
const Separator = styled.div`
  background: ${colorPalette.primary1.main};
  border-bottom-left-radius: ${globalStyles.mainBoxRadius};
  border-bottom-right-radius: ${globalStyles.mainBoxRadius};
  box-shadow: ${globalStyles.mainBoxShadow};
  height: 70px;
  position: relative;
  top: -30px;
`
const Spacer = styled.div`
  height: 45px;
`
const MobileButtons = styled.div`
  display: none;
  background: ${colorPalette.primary1.main};
  border-bottom-left-radius: ${globalStyles.mainBoxRadius};
  border-bottom-right-radius: ${globalStyles.mainBoxRadius};
  height: ${window.innerHeight - 215}px;
  position: relative;
  z-index: 2;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 20px;
  padding-top: 40px;
  ${mediaQuery.phone`
  `}
`
const MobileButtonWrapper = styled.div`
  flex: 0 0 48%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const MobileButton = styled(Link)`
  background: ${props => (props.challengeButton ? colorPalette.primary2.main : colorPalette.white.main)};
  color: ${props => (props.challengeButton ? colorPalette.black.main : colorPalette.primary1.main)};
  box-shadow: ${globalStyles.mainBoxShadow};
  border-radius: 10px;
  flex: 0 0 82%;
  max-height: 160px;
  margin-bottom: 20px;
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    color: ${props => (props.challengeButton ? colorPalette.black.main : colorPalette.primary1.main)};
  }
`
const MobileButtonText = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
`
const NaturalProductsIcon = styled.div`
  width: 50px;
  height: 50px;
  background-image: url(${naturalExtractIcon});
  background-size: contain;
  background-repeat: no-repeat;
`
const iconCSS = css`
  && {
    width: 50px;
    height: 50px;
  }
`
const StyledFitnessIcon = styled(FitnessIcon)`
  ${iconCSS}
  transform: rotate(-45deg);
`

const StyledChatIcon = styled(ChatIcon)`
  ${iconCSS}
`

const StyledStandingIcon = styled(StandingIcon)`
  ${iconCSS}
`

const StyledChallengeIcon = styled(ChallengeIcon)`
  ${iconCSS}
`
const AppleGoogleButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -58px;
  margin-bottom: -20px;
`

// Component
class HomeScreen extends Component {
  state = {
    index: 0,
    direction: null,
  }

  componentDidMount() {
    //document.getElementById('fitthybodyVideo').play()
  }

  handleSelect = (selectedIndex, e) => {
    this.setState({
      index: selectedIndex,
      direction: e.direction,
    })
  }

  render() {
    const { index, direction } = this.state
    const { currentUser, openSnackbar } = this.props
    const isAdmin = isAdminUser(currentUser)

    return (
      <div>
        <MobileButtons>
          <MobileButtonWrapper>
            <MobileButton to="/myTrainers">
              <StyledFitnessIcon />
              <MobileButtonText>מאמנים אישיים</MobileButtonText>
            </MobileButton>
          </MobileButtonWrapper>
          <MobileButtonWrapper>
            <MobileButton to="/store/2">
              <NaturalProductsIcon />
              <MobileButtonText>מוצרים בשבילך</MobileButtonText>
            </MobileButton>
          </MobileButtonWrapper>
          <MobileButtonWrapper>
            <MobileButton to={'/challengeIntro'} challengeButton>
              <StyledChallengeIcon />
              <MobileButtonText>חוויית החיטוב ב-90 יום</MobileButtonText>
            </MobileButton>
          </MobileButtonWrapper>
          <MobileButtonWrapper>
            <MobileButton to="/contactUs">
              <StyledChatIcon />
              <MobileButtonText>כתבו לנו</MobileButtonText>
            </MobileButton>
          </MobileButtonWrapper>
        </MobileButtons>
        {window.cordova && <CordovaLoginButtons currentUser={currentUser} />}
        <StyledCarousel
          activeIndex={index}
          direction={direction}
          onSelect={this.handleSelect}
          fade
          interval={500000}
          touch={false}
          indicators={false}
          controls={!window.cordova}
        >
          <Carousel.Item>
            <ChallengeCarouselItem openSnackbar={openSnackbar} />
            {!window.cordova && (
              <>
                <ArticlesCarouselItem />
                <AppleGoogleButtonsWrapper>
                  <AppleGoogleButtons />
                </AppleGoogleButtonsWrapper>
              </>
            )}
          </Carousel.Item>
          <Carousel.Item>
            <TrainersCarouselItem isActive={index === 1} />
            <OurTrainersCarouselItem isActive={index === 1} />
            <Spacer />
            <FeedbackCarouselItem customTop={68} />
          </Carousel.Item>
          {isAdmin && ( // TEMP - don't show this item to users. Only to admins
            <Carousel.Item>
              <HighlightProductsCarouselItem isActive={isAdmin ? index === 2 : index === 1} />
              <OurHighlightProductsCarouselItem isActive={isAdmin ? index === 2 : index === 1} />
              <Spacer />
              <FeedbackCarouselItem customTop={68} />
              {/*<Separator />*/}
            </Carousel.Item>
          )}
        </StyledCarousel>
        {/*<VideoContainer>
          <VideoWrapper>
            <StyledIframe
              height="485"
              src="https://www.youtube.com/embed/QZllB3sZ6L4?autoplay=1&mute=1&rel=0"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></StyledIframe>
            <video id="fitthybodyVideo" width="320" height="240" autoplay muted controls>
            <source src={fitthybodyVideo} type="video/mp4" />
            Your browser does not support the video tag.
      </video>
          </VideoWrapper>
        </VideoContainer>*/}
      </div>
    )
  }
}

export default HomeScreen
