import React, { Component } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import HelpIcon from '@material-ui/icons/HelpOutline'
import LeftArrowIcon from '@material-ui/icons/KeyboardArrowLeft'
import RightArrowIcon from '@material-ui/icons/KeyboardArrowRight'
import { mediaQuery, mobileCheck, isScrolledIntoView } from '../services/global'
import { getString } from '../services/presetStringsHelper'
import { colorPalette } from '../styles/styleVariables'

// Consts

// Style
const StyledDialog = styled(Dialog)`
  && {
    .MuiDialog-scrollPaper {
      align-items: flex-end;
      justify-content: flex-end;
    }
    .MuiDialog-paper {
      margin: 0;
      border-radius: 0;
      min-width: 600px;
      ${mediaQuery.phone`
        min-width: 100%;
      `}
    }
  }
`
const StyledDialogTitle = styled(DialogTitle)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
  }
`
const StyledDialogContent = styled(DialogContent)`
  && {
    ${mediaQuery.phone`
      padding: 0px 24px;
    `}
  }
`
const StyledDialogActions = styled(DialogActions)`
  && {
    justify-content: center;
  }
`
const TitleWithIcon = styled.div`
  display: flex;
  align-items: center;
  svg {
    color: ${colorPalette.primary1.main};
    margin-left: 5px;
    width: 40px;
    height: 40px;
  }
`
const OpenWizardBtn = styled.div`
  position: fixed;
  z-index: 999;
  border-radius: 100%;
  height: 36px;
  width: 36px;
  background: ${colorPalette.primary1.main};
  bottom: 10px;
  right: 10px;
  border: 2px solid ${colorPalette.white.main};
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${colorPalette.white.main};
  font-weight: 600;
`

// Component
class WizardDialog extends Component {
  state = {
    isVisible: false,
    stepsList: [],
    currentStep: 0,
    wizardType: '',
    isBottomBarInView: false,
    isMobile: mobileCheck(),
  }

  // Lifecycle methods
  componentWillMount() {
    const { isMobile } = this.state
    this.initWizardSettings(this.props)

    window.addEventListener('scroll', () => {
      const isBottomBarInView = isScrolledIntoView(document.getElementById('BottomBar'))
      if (this.state.isBottomBarInView !== isBottomBarInView && isMobile) {
        this.setState({ isBottomBarInView })
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.initWizardSettings(nextProps)
    }
  }

  // Helper methods
  initWizardSettings = props => {
    const { location } = props
    let wizardType = ''
    if (location.pathname.includes('myDailySchedule') && location.pathname.includes('myMeals') === false) {
      wizardType = 'myDailySchedule'
    } /*else if (location.pathname.includes('program')) {
      wizardType = 'program'
    }*/ else if (
      location.pathname.includes('myWorkoutVideos')
    ) {
      wizardType = 'myWorkoutVideos'
    } else if (location.pathname.includes('myPersonalStatsProfile')) {
      wizardType = 'myPersonalStatsProfile'
    } else if (location.pathname === '/myMeals') {
      wizardType = 'myMeals'
    } else if (location.pathname.includes('myMeals/byDayTime')) {
      wizardType = 'myMealsByDayTime'
    } else if (location.pathname.includes('myMeals/')) {
      wizardType = 'mealDetails'
    }

    // based on wizard type, define the list of wizard steps to render
    let stepsList = []
    if (wizardType === 'program') {
      stepsList = [
        {
          content: <div>{getString('WizardProgram_Item1')}</div>,
        },
        {
          content: <div>{getString('WizardProgram_Item2')}</div>,
          highlightElementSelector: '.wizard_progressBar',
          animationClass: 'wizardSelfHighlightAnimation',
        },
        {
          content: <div>{getString('WizardProgram_Item3')}</div>,
          highlightElementSelector: '.wizard_updateInitialPersonalStats',
          animationClass: 'wizardBorderHighlightAnimation',
        },
      ]
    } else if (wizardType === 'myDailySchedule') {
      stepsList = [
        {
          content: <div>{getString('WizardDailySchedule_Item1')}</div>,
        },
        {
          content: <div>{getString('WizardDailySchedule_Item2')}</div>,
          highlightElementSelector: '.wizard_currentDayInSchedule',
          animationClass: 'wizardBorderHighlightAnimation',
        },
        {
          content: <div>{getString('WizardDailySchedule_Item3')}</div>,
          highlightElementSelector: '.wizard_dailyScheduleSwapIcon',
          animationClass: 'wizardBorderHighlightAnimation',
        },
        {
          content: <div>{getString('WizardDailySchedule_Item4')}</div>,
          highlightElementSelector: '.wizard_downloadCalendar',
          animationClass: 'wizardBorderHighlightAnimation',
        },
      ]
    } else if (wizardType === 'myWorkoutVideos') {
      stepsList = [
        {
          content: <div>{getString('WizardMyWorkouts_Item1')}</div>,
        },
        {
          content: <div>{getString('WizardMyWorkouts_Item2')}</div>,
        },
        {
          content: <div>{getString('WizardMyWorkouts_Item3')}</div>,
          highlightElementSelector: '.wizard_challengePhaseSelector',
          animationClass: 'wizardBorderHighlightAnimation',
        },
        {
          content: <div>{getString('WizardMyWorkouts_Item4')}</div>,
        },
      ]
    } else if (wizardType === 'myPersonalStatsProfile') {
      stepsList = [
        {
          content: <div>{getString('WizardMyPersonalStats_Item1')}</div>,
        },
        {
          content: <div>{getString('WizardMyPersonalStats_Item2')}</div>,
          highlightElementSelector: '.wizard_foodTypeChangeBtn',
          animationClass: 'wizardBorderHighlightAnimation',
        },
      ]
    } else if (wizardType === 'myMeals') {
      stepsList = [
        {
          content: <div>{getString('WizardMyMeals_Item1')}</div>,
        },
        {
          content: <div>{getString('WizardMyMeals_Item2')}</div>,
        },
      ]
    } else if (wizardType === 'myMealsByDayTime') {
      stepsList = [
        {
          content: <div>{getString('WizardMyMealsByDayTime_Item1')}</div>,
        },
        {
          content: <div>{getString('WizardMyMealsByDayTime_Item2')}</div>,
          highlightElementSelector: '.wizard_challengePhaseSelector',
          animationClass: 'wizardBorderHighlightAnimation',
        },
      ]
    } else if (wizardType === 'mealDetails') {
      stepsList = [
        {
          content: <div>{getString('WizardMealDetails_Item1')}</div>,
        },
        {
          content: <div>{getString('WizardMealDetails_Item2')}</div>,
          highlightElementSelector: '.wizard_sendIngredientsBtn',
          animationClass: 'wizardBorderHighlightAnimation',
        },
        {
          content: <div>{getString('WizardMealDetails_Item3')}</div>,
          highlightElementSelector: '.wizard_sendRecipeBtn',
          animationClass: 'wizardBorderHighlightAnimation',
        },
      ]
    }

    const wasWizardCompleted = localStorage.getItem(`wizardCompleted_${wizardType}`)
    const isVisible = wasWizardCompleted !== 'true' && stepsList.length > 0
    this.setState({ stepsList, wizardType, isVisible, currentStep: 0 })
  }

  highlightElementBasedOnNewStep = newStep => {
    const { stepsList, isMobile } = this.state
    this.removeHighlighted()

    // apply highlight animation
    const selector = stepsList[newStep].highlightElementSelector
    const animationClass = stepsList[newStep].animationClass
    if (selector) {
      const element = document.querySelector(selector)
      if (element) {
        element.classList.add(animationClass)
        element.scrollIntoView({ behavior: 'smooth', block: isMobile ? 'start' : 'end' })
        if (isMobile) {
          window.setTimeout(() => {
            window.scrollBy(0, -10)
          }, 1)
        }
      }
    }
  }

  removeHighlighted = () => {
    // remove highlight animation from all current elements with animation
    document.querySelectorAll('.wizardBorderHighlightAnimation').forEach(item => item.classList.remove('wizardBorderHighlightAnimation'))
    document.querySelectorAll('.wizardSelfHighlightAnimation').forEach(item => item.classList.remove('wizardSelfHighlightAnimation'))
  }

  // UI event methods
  handlePrevClick = () => {
    const { currentStep } = this.state

    const newStep = currentStep - 1
    this.setState({ currentStep: newStep })
    this.highlightElementBasedOnNewStep(newStep)
  }

  handleNextClick = () => {
    const { currentStep, stepsList, wizardType } = this.state

    const newStep = currentStep + 1
    if (newStep < stepsList.length) {
      this.setState({ currentStep: newStep })
      this.highlightElementBasedOnNewStep(newStep)
    } else {
      localStorage.setItem(`wizardCompleted_${wizardType}`, 'true')
      this.removeHighlighted()
      this.setState({ isVisible: false })
    }
  }

  handleClose = () => {
    localStorage.setItem(`wizardCompleted_myDailySchedule`, 'true')
    localStorage.setItem(`wizardCompleted_program`, 'true')
    localStorage.setItem(`wizardCompleted_myWorkoutVideos`, 'true')
    localStorage.setItem(`wizardCompleted_myPersonalStatsProfile`, 'true')
    localStorage.setItem(`wizardCompleted_myMeals`, 'true')
    localStorage.setItem(`wizardCompleted_myMealsByDayTime`, 'true')
    localStorage.setItem(`wizardCompleted_mealDetails`, 'true')
    this.removeHighlighted()
    this.setState({ isVisible: false })
  }

  handleOpenWizardClick = () => {
    const { wizardType, stepsList } = this.state
    if (stepsList.length > 0) {
      localStorage.removeItem(`wizardCompleted_${wizardType}`)
      this.setState({ isVisible: true, currentStep: 0 })
    }
  }

  // Render methods
  render() {
    const { currentStep, stepsList, isVisible, isBottomBarInView } = this.state
    const isFinalStep = currentStep === stepsList.length - 1

    if (!isVisible)
      return (
        <>
          {!isBottomBarInView && (
            <OpenWizardBtn title={getString('WizardDialogTitle')} onClick={this.handleOpenWizardClick}>
              ?
            </OpenWizardBtn>
          )}
        </>
      )

    return (
      <StyledDialog
        onClose={this.handleClose}
        open
        disableBackdropClick
        disableEscapeKeyDown
        BackdropProps={{ style: { backgroundColor: 'rgba(0,0,0,0.2)' } }}
      >
        <StyledDialogTitle id="simple-dialog-title" disableTypography>
          <TitleWithIcon>
            <HelpIcon />
            <Typography variant="h6">{getString('WizardDialogTitle')}</Typography>
          </TitleWithIcon>
          <IconButton aria-label="close" onClick={this.handleClose}>
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <StyledDialogContent>{stepsList && stepsList[currentStep] && stepsList[currentStep].content}</StyledDialogContent>
        <StyledDialogActions>
          <Button onClick={this.handlePrevClick} color="primary" startIcon={<RightArrowIcon />} disabled={currentStep === 0}>
            הקודם
          </Button>
          <Button onClick={this.handleNextClick} color="primary" endIcon={isFinalStep ? null : <LeftArrowIcon />}>
            {isFinalStep ? 'סיום' : 'הבא'}
          </Button>
        </StyledDialogActions>
      </StyledDialog>
    )
  }
}

export default withRouter(WizardDialog)
