import React, { Component } from 'react'
import styled from 'styled-components'
import { Link, withRouter } from 'react-router-dom'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import backgroundImage from '../../images/dumbblesBg.jpg'
import { mediaQuery, siteName, photoUrlPrefix } from '../../services/global'
import { invokeAPIcall } from '../../services/rest'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import Breadcrumbs from '../Breadcrumbs'

// Consts

// Style
const PageWrapper = styled.div`
  background-color: ${colorPalette.white.main};
  background-image: url(${backgroundImage});
  background-size: 60% auto;
  background-position: top right;
  background-repeat: no-repeat;
  padding: 20px;
  flex: 1;
  ${mediaQuery.phone`
    background: none;
  `}
`
const ArticleList = styled.ul`
  width: 72%;
  margin-right: auto;
  margin-left: 9%;
  ${mediaQuery.phone`
    width: 95%;
    margin: 0;
  `}
`
const ArticleItem = styled.li`
  margin-bottom: 20px;
  background-color: ${colorPalette.white.main};
  color: ${colorPalette.black.main};
  display: flex;
  overflow: hidden;
  a {
    color: ${colorPalette.black.main};
  }
  ${globalStyles.boxShadow}
  ${globalStyles.boxRadius}
  ${mediaQuery.phone`
    flex-direction: column;
  `}
`
const ArticleItemContent = styled.div`
  padding: 20px;
  margin-right: 10px;
`
const ReadMoreButton = styled(Button)`
  && {
    margin-top: 20px;
  }
`
const Title = styled.div`
  font-size: 34px;
  font-weight: 500;
  ${mediaQuery.phone`
    font-size: 28px;
  `}
`
const Date = styled.div``
const Desc = styled.div``
const LoadingIndicatorWrapper = styled.div`
  margin: 50px auto;
  display: flex;
  justify-content: center;
`
const ArticlePhoto = styled.div`
  width: 180px;
  flex: 0 0 180px;
  height: 185px;
  background-color: #eee;
  background-image: url('${props => props.photoUrl}');
  background-position: center;
  background-size: cover;
  border-left: 1px solid ${colorPalette.primary1.main};
  ${mediaQuery.phone`
    width: 100%;
    border-left-width: 0;
    border-bottom: 1px solid ${colorPalette.primary1.main};
  `}
`

// Component
class ArticlesScreen extends Component {
  state = {
    articles: [],
  }

  // Lifecycle methods
  componentDidMount() {
    this.loadArticleList(this.props)
  }

  // Helper methods
  loadArticleList = async props => {
    const articleList = await invokeAPIcall({ uri: `api/articles` })
    this.setState({ articles: articleList })
  }

  // UI event methods

  // Render methods
  renderArticleItem = item => {
    let photoUrl = item.PhotoURL
    if (photoUrl) {
      if (!photoUrl.includes('http')) {
        photoUrl = `${photoUrlPrefix}/articlePhotos/${photoUrl}`
      }
    }
    return (
      <ArticleItem>
        <ArticlePhoto photoUrl={photoUrl} />
        <ArticleItemContent>
          <Link to={`/article/${item.ArticleID}`}>
            <Title>{item.Title}</Title>
            <Date>פורסם ב: {moment(item.Date).format('DD/MM/YYYY')}</Date>
            <Desc>{item.Description}</Desc>
          </Link>
        </ArticleItemContent>
        {/*<Link to={`/article/${item.ArticleID}`}>
          <ReadMoreButton variant="contained" color="primary">
            המשך לקרוא {'>'}
          </ReadMoreButton>
        </Link>*/}
      </ArticleItem>
    )
  }

  render() {
    const { articles } = this.state
    return (
      <PageWrapper>
        <Helmet>
          <title>{siteName} - מאמרים</title>
        </Helmet>
        <Breadcrumbs previousItems={[]} currentItem="מאמרים" />
        {articles.length === 0 && (
          <LoadingIndicatorWrapper>
            <CircularProgress />
          </LoadingIndicatorWrapper>
        )}
        <ArticleList>{articles.map(this.renderArticleItem)}</ArticleList>
      </PageWrapper>
    )
  }
}

export default withRouter(ArticlesScreen)
