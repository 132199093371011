import React, { Component } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import { withRouter } from 'react-router-dom'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import { mediaQuery } from '../../services/global'
import { requiredValidation } from '../../services/formValidation'
import { invokeAPIcall } from '../../services/rest'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import StyledTextField from '../StyledTextField'
import Breadcrumbs from '../Breadcrumbs'

// Style
const PageWrapper = styled.div`
  background-color: ${colorPalette.white.main};
  padding: 20px;
  flex: 1;
  ${mediaQuery.phone`
    background: none;
  `}
`
const PageContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  max-width: 900px;
  margin: 0 auto;
  ${mediaQuery.phone`
    flex-direction: column;
    align-items: stretch;
  `}
`
const FormWrapper = styled.div`
  background-color: ${colorPalette.white.main};
  padding: 20px;
  color: ${colorPalette.primary1.main};
  ${globalStyles.boxShadow}
  ${globalStyles.boxRadius}
  flex: 1;
`
const PageTitle = styled.h1`
  margin-bottom: 20px;
`
const FieldsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
`
const FieldCell = styled.div`
  flex: 0 0 100%;
`
const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  margin-top: 20px;
  ${mediaQuery.phone`
    width: 100%;
  `}
`
const StyledButton = styled(Button)`
  && {
  }
`
const SliderWrapper = styled.div`
  margin: 0 30px;
  margin-top: 40px;
`
const SliderLabel = styled.div`
  margin-top: 30px;
`
const OptionalSection = styled.div`
  display: ${props => (props.visible ? 'block' : 'none')};
`

// Component
class PhoneCallFeedbackScreen extends Component {
  state = {
    initialValues: {
      step: 1,
      isFinished: false,
      satisfactionRating: 5,
      challengeRating: 5,
      professionalRating: 5,
      text: '',
    },
  }

  // Lifecycle methods

  // Helper methods
  validate = values => {
    let errors = {}
    return errors
  }

  // UI event methods
  handleSubmit = async (values, { setSubmitting }) => {
    const { history, openSnackbar } = this.props
    const data = {
      ...values,
    }

    // invoke API call
    try {
      const responseData = await invokeAPIcall({
        uri: 'api/contact/phoneCallFeedback',
        data,
        method: 'POST',
      })

      // if API success
      if (responseData.Key === true) {
        openSnackbar({
          message: 'תודה רבה על המשוב!',
          severity: 'success',
        })
        history.push('/')
      }
      // if failed
      else {
        openSnackbar({ message: responseData.Value, severity: 'failure' })
      }
    } catch (error) {
      console.log('error in sending email', error)
    }
    setSubmitting(false)
  }

  // Render methods
  render() {
    const { initialValues } = this.state
    const sliderProps = {
      min: 1,
      max: 10,
      step: 1,
      valueLabelDisplay: 'on',
    }
    return (
      <PageWrapper>
        <Breadcrumbs previousItems={[]} currentItem="שביעות רצון מהשירות" />
        <PageContentWrapper>
          <FormWrapper>
            <PageTitle>שביעות רצון מהשירות</PageTitle>
            <Formik validate={this.validate} initialValues={initialValues} onSubmit={this.handleSubmit} enableReinitialize={true}>
              {formikBag => {
                const { isSubmitting, values, errors, touched, handleChange, handleBlur, isValid, setFieldValue } = formikBag
                return (
                  <Form>
                    <OptionalSection visible={values.step === 1}>
                      <FieldsRow>
                        <FieldCell>
                          <SliderLabel>מהי שביעות הרצון שלך מהשירות שקיבלת מהפנייה האחרונה מ-FitThyBody?</SliderLabel>
                          <SliderWrapper>
                            <Slider
                              value={values.satisfactionRating}
                              onChange={(e, value) => {
                                setFieldValue('satisfactionRating', value, false)
                              }}
                              {...sliderProps}
                              marks={[
                                {
                                  value: 1,
                                  label: '1 - לא מרוצה',
                                },
                                {
                                  value: 10,
                                  label: '10 - מרוצה מאוד',
                                },
                              ]}
                            />
                          </SliderWrapper>
                        </FieldCell>
                      </FieldsRow>
                      <ButtonsWrapper>
                        <StyledButton
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={() => {
                            setFieldValue('step', 2)
                          }}
                        >
                          המשך
                        </StyledButton>
                      </ButtonsWrapper>
                    </OptionalSection>
                    <OptionalSection visible={values.step === 2}>
                      <FieldsRow>
                        <FieldCell>
                          <SliderLabel>
                            {values.satisfactionRating <= 7 && <div>חבל, מצטערים לשמוע.</div>}
                            {values.satisfactionRating > 7 && <div>תודה, נעים לשמוע.</div>}
                            באיזו מידה הרגשת שהשירות אתגר אותך?
                          </SliderLabel>
                          <SliderWrapper>
                            <Slider
                              value={values.challengeRating}
                              onChange={(e, value) => {
                                setFieldValue('challengeRating', value, false)
                              }}
                              {...sliderProps}
                              marks={[
                                {
                                  value: 1,
                                  label: '1 - לא אתגר',
                                },
                                {
                                  value: 10,
                                  label: '10 - אתגר מאוד',
                                },
                              ]}
                            />
                          </SliderWrapper>
                        </FieldCell>
                      </FieldsRow>
                      <ButtonsWrapper>
                        <StyledButton
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={() => {
                            if (values.challengeRating <= 7) {
                              setFieldValue('step', 3)
                            } else {
                              setFieldValue('professionalRating', null)
                              setFieldValue('step', 0)
                              setFieldValue('isFinished', true)
                            }
                          }}
                        >
                          המשך
                        </StyledButton>
                      </ButtonsWrapper>
                    </OptionalSection>
                    <OptionalSection visible={values.step === 3}>
                      <FieldsRow>
                        <FieldCell>
                          <SliderLabel>עד כמה היית מרוצה מהמקצועיות של השירות?</SliderLabel>
                          <SliderWrapper>
                            <Slider
                              value={values.professionalRating}
                              onChange={(e, value) => {
                                setFieldValue('professionalRating', value, false)
                              }}
                              {...sliderProps}
                              marks={[
                                {
                                  value: 1,
                                  label: '1 - לא מרוצה',
                                },
                                {
                                  value: 10,
                                  label: '10 - מרוצה מאוד',
                                },
                              ]}
                            />
                          </SliderWrapper>
                        </FieldCell>
                      </FieldsRow>
                      <ButtonsWrapper>
                        <StyledButton
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={() => {
                            if (values.professionalRating <= 7) {
                              setFieldValue('step', 4)
                            } else {
                              setFieldValue('step', 0)
                            }
                            setFieldValue('isFinished', true)
                          }}
                        >
                          המשך
                        </StyledButton>
                      </ButtonsWrapper>
                    </OptionalSection>
                    <OptionalSection visible={values.step === 4}>
                      <FieldsRow>
                        <div>לפני סיום, איך לדעתך נוכל לשפר את השירות שלנו? (נא לציין פרטים אישיים בתשובתך)</div>
                        <FieldCell>
                          <StyledTextField
                            label={'פירוט מלא'}
                            name="text"
                            value={values.text}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={errors.text && touched.text && errors.text}
                            error={errors.text && touched.text}
                            margin="normal"
                            fullWidth
                            multiline
                            rows="3"
                          />
                        </FieldCell>
                      </FieldsRow>
                    </OptionalSection>
                    <OptionalSection visible={values.isFinished}>
                      <OptionalSection visible={values.step === 0}>תודה רבה שהקדשת מזמנך!</OptionalSection>
                      <ButtonsWrapper>
                        <StyledButton variant="contained" color="primary" fullWidth disabled={isSubmitting} type="submit">
                          שליחת משוב
                        </StyledButton>
                      </ButtonsWrapper>
                    </OptionalSection>
                  </Form>
                )
              }}
            </Formik>
          </FormWrapper>
        </PageContentWrapper>
      </PageWrapper>
    )
  }
}

export default withRouter(PhoneCallFeedbackScreen)
