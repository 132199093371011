import React, { Component } from 'react'
import styled from 'styled-components'
import { Link, withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import ExpandIcon from '@material-ui/icons/ExpandMore'
import CheckIcon from '@material-ui/icons/DoneOutline'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import { mediaQuery } from '../../services/global'
import { getString } from '../../services/presetStringsHelper'
import challengeIntro1 from '../../images/challengeIntro1.png'
import challengeIntro2 from '../../images/challengeIntro2.png'
import challengeIntro3 from '../../images/challengeIntro3.png'
import challengeIntro4 from '../../images/challengeIntro4.png'

// Style
const ChallengeIntroScreenWrapper = styled.div`
  background-color: ${colorPalette.primary1.main};
  border-bottom-left-radius: ${globalStyles.mainBoxRadius};
  border-bottom-right-radius: ${globalStyles.mainBoxRadius};
  color: ${colorPalette.white.main};
  box-shadow: ${globalStyles.mainBoxShadow};
  position: relative;
  z-index: 1;
  padding: 30px 0;
  ${mediaQuery.phone`
    height: auto;
  `}
`
const PromotionTitle = styled.h2`
  font-size: 36px;
  font-weight: 500;
  margin: 20px 10px;
  margin-top: 35px;
  text-align: center;
  ${mediaQuery.tablet`
    font-size: 34px;
  `}
  ${mediaQuery.phone`
    font-size: 28px;
  `}
`
const PromotionSubTitle = styled.h4`
  font-size: 22px;
  text-align: center;
  margin: 0 10px;
  margin-bottom: 20px;
`
const PromotionListItem = styled.div`
  display: flex;
  background-color: ${props => (false ? colorPalette.white.main : colorPalette.primary1.main)};
  color: ${props => (false ? colorPalette.primary1.main : colorPalette.white.main)};
`
const PromotionListItemInner = styled.div`
  display: flex;
  align-items: center;
  margin: 30px auto;
  max-width: 1300px;
  text-align: center;
  padding: 0 20px;
  ${mediaQuery.phone`
    flex-direction: column;
  `}
`
const PromotionListItemContent = styled.div`
  line-height: 1.9
  word-spacing: 2.6px;
  font-weight: 500;
`
const PromotionListItemContentTitle = styled.h4`
  font-size: 24px;
  font-weight: 500;
`
const PromotionPhotoWrapper = styled.div`
  background-color: ${colorPalette.white.main};
  display: flex;
  align-items: center;
  justify-content: center;
`
const PromotionPhoto = styled.div`
  width: 900px;
  height: 290px;
  flex: 0 0 900px;
  background: gray;
  border-radius: 10px;
  margin: 15px;
  background-image: url('${props => props.photoUrl}');
  background-size: cover;
  background-position: center;
  ${mediaQuery.phone`
    order: 3;
    margin: 15px 0;
    width: 320px;
  height: 102px;
  flex: 0 0 320px;
  `}
`
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 25px 0;
  ${props => (props.bottom ? 'margin-top: 0;' : '')}
`
const StyledButton = styled(Button)`
  && {
    padding: 10px 35px;
    font-size: 20px;
    line-height: 1.4;
    & > span {
      display: block;
    }
  }
`
const ExpandIconWrapper = styled.div`
  svg {
    cursor: pointer;
  }
`
const ObjectiveList = styled.ul`
  margin: 9px 0;
`
const ObjectiveItem = styled.li`
  margin: 20px 0;
`
const StyledCheckIcon = styled(CheckIcon)`
  && {
    width: 18px;
    height: 18px;
    margin-top: -3px;
    margin-left: 8px;
  }
`

// Component
class ChallengeIntroScreen extends Component {
  state = {
    challengeIntroItem1Expand: false,
    challengeIntroItem4Expand: false,
  }

  // Lifecycle methods
  componentDidMount() {
    const { currentUser, history } = this.props
    // if user already logged in - redirect to the Program screen
    if (currentUser) {
      history.push('/program')
    }
  }

  // Helper methods

  // UI event methods
  handleChallengeIntroItem1Expand = () => {
    this.setState({ challengeIntroItem1Expand: true })
  }
  handleChallengeIntroItem4Expand = () => {
    this.setState({ challengeIntroItem4Expand: true })
  }

  // Render methods
  render() {
    const { challengeIntroItem1Expand, challengeIntroItem4Expand } = this.state
    return (
      <>
        <ChallengeIntroScreenWrapper>
          <PromotionTitle>{getString('ChallengeIntroTitle')}</PromotionTitle>
          <PromotionSubTitle>{getString('ChallengeIntroSubTitle')}</PromotionSubTitle>
          <ButtonsWrapper>
            <Link to="/registerToChallenge">
              <StyledButton variant="contained" color="secondary">
                כניסה לחוויית החיטוב
              </StyledButton>
            </Link>
          </ButtonsWrapper>
          <PromotionPhotoWrapper>
            <PromotionPhoto
              photoUrl={challengeIntro1}
              onClick={() => {
                window.open(challengeIntro1, '_blank')
              }}
            />
          </PromotionPhotoWrapper>
          <PromotionListItem white>
            <PromotionListItemInner>
              <PromotionListItemContent>
                {getString('ChallengeIntroItem1Text')}
                {challengeIntroItem1Expand && <div>{getString('ChallengeIntroItem1Text_ReadMore')}</div>}
                {!challengeIntroItem1Expand && (
                  <ExpandIconWrapper onClick={this.handleChallengeIntroItem1Expand}>
                    <ExpandIcon />
                  </ExpandIconWrapper>
                )}
              </PromotionListItemContent>
            </PromotionListItemInner>
          </PromotionListItem>
          <PromotionPhotoWrapper>
            <PromotionPhoto
              photoUrl={challengeIntro2}
              onClick={() => {
                window.open(challengeIntro2, '_blank')
              }}
            />
          </PromotionPhotoWrapper>
          <PromotionListItem>
            <PromotionListItemInner>
              <PromotionListItemContent>
                <PromotionListItemContentTitle>{getString('ChallengeIntroItem2Title')}</PromotionListItemContentTitle>
                {getString('ChallengeIntroItem2Text')}
                <ObjectiveList>
                  <ObjectiveItem>
                    <StyledCheckIcon />
                    {getString('ChallengeIntroItem2Text_Item1')}
                  </ObjectiveItem>
                  <ObjectiveItem>
                    <StyledCheckIcon />
                    {getString('ChallengeIntroItem2Text_Item2')}
                  </ObjectiveItem>
                  <ObjectiveItem>
                    <StyledCheckIcon />
                    {getString('ChallengeIntroItem2Text_Item3')}
                  </ObjectiveItem>
                  <ObjectiveItem>
                    <StyledCheckIcon />
                    {getString('ChallengeIntroItem2Text_Item4')}
                  </ObjectiveItem>
                </ObjectiveList>
              </PromotionListItemContent>
            </PromotionListItemInner>
          </PromotionListItem>
          <PromotionPhotoWrapper>
            <PromotionPhoto
              photoUrl={challengeIntro3}
              onClick={() => {
                window.open(challengeIntro3, '_blank')
              }}
            />
          </PromotionPhotoWrapper>
          <PromotionListItem white>
            <PromotionListItemInner>
              <PromotionListItemContent>
                <PromotionListItemContentTitle>{getString('ChallengeIntroItem3Title')}</PromotionListItemContentTitle>
                {getString('ChallengeIntroItem3Text')}
                <ObjectiveList>
                  <ObjectiveItem>
                    <StyledCheckIcon />
                    {getString('ChallengeIntroItem3Text_Item1')}
                  </ObjectiveItem>
                  <ObjectiveItem>
                    <StyledCheckIcon />
                    {getString('ChallengeIntroItem3Text_Item2')}
                  </ObjectiveItem>
                  <ObjectiveItem>
                    <StyledCheckIcon />
                    {getString('ChallengeIntroItem3Text_Item3')}
                  </ObjectiveItem>
                </ObjectiveList>
              </PromotionListItemContent>
            </PromotionListItemInner>
          </PromotionListItem>
          <PromotionPhotoWrapper>
            <PromotionPhoto
              photoUrl={challengeIntro4}
              onClick={() => {
                window.open(challengeIntro4, '_blank')
              }}
            />
          </PromotionPhotoWrapper>
          <PromotionListItem>
            <PromotionListItemInner>
              <PromotionListItemContent>
                <PromotionListItemContentTitle>{getString('ChallengeIntroItem4Title')}</PromotionListItemContentTitle>
                {getString('ChallengeIntroItem4Text')}
                {challengeIntroItem4Expand && <div>{getString('ChallengeIntroItem4Text_ReadMore')}</div>}
                {!challengeIntroItem4Expand && (
                  <ExpandIconWrapper onClick={this.handleChallengeIntroItem4Expand}>
                    <ExpandIcon />
                  </ExpandIconWrapper>
                )}
              </PromotionListItemContent>
            </PromotionListItemInner>
          </PromotionListItem>
          <ButtonsWrapper bottom>
            <Link to="/registerToChallenge">
              <StyledButton variant="contained" color="secondary">
                כניסה לחוויית החיטוב
              </StyledButton>
            </Link>
          </ButtonsWrapper>
        </ChallengeIntroScreenWrapper>
      </>
    )
  }
}

export default withRouter(ChallengeIntroScreen)
