import React, { Component } from 'react'
import styled from 'styled-components'
import { Link, NavLink } from 'react-router-dom'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import { mediaQuery } from '../services/global'
import { colorPalette, globalStyles } from '../styles/styleVariables'

// Style
const MenuOptions = styled.ul`
  display: flex;
  margin-bottom: 0;
  margin-right: 15px;
  ${mediaQuery.phone`
    padding: 30px;
    flex-direction: column;
    margin-top: env(safe-area-inset-top);
  `}
`
const MenuOption = styled.li`
  font-size: 19px;
  white-space: nowrap;
  padding: 0 15px;
  cursor: pointer;
  position: relative;
  height: 44px;
  display: flex;
  align-items: center;
  ${props => (props.hideOnDesktop ? `display: none;` : '')}
  ${props => (props.isActive ? `background ${colorPalette.white.main};` : '')}
  color: ${props => (props.isActive ? colorPalette.primary1.main : colorPalette.white.main)};
  &:hover {
    color: ${props => (props.isActive ? colorPalette.primary1.light : colorPalette.tertiary1.light)};
  }
  a {
    color: ${props => (props.isActive ? colorPalette.primary1.main : colorPalette.white.main)};
    &:hover {
      color: ${props => (props.isActive ? colorPalette.primary1.light : colorPalette.tertiary1.light)};
    }
  }
  ${mediaQuery.tablet`
    font-size: 17px;
    margin-right: 14px;
  `}
  ${mediaQuery.phone`
    display: block;
    margin-right: 0;
    margin-bottom: 20px;
    ${props => (props.hideOnMobile ? `display: none;` : '')}
    a {
      color: ${colorPalette.primary1.main};
    }
  `}
`
const SubMenuOptionsPopup = styled.div`
  position: absolute;
  top: 44px;
  right: 0;
  background-color: ${colorPalette.white.main};
  ${globalStyles.boxRadius}
  ${globalStyles.boxShadowPopup}
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  overflow: hidden;
  min-width: 200px;
  transition: all 0.3s;
  height: ${props => (props.isActive ? '90px' : '0px')};
`
const StyledMenuList = styled(MenuList)`
  && {
    padding: 10px 0;
  }
`
const StyledMenuItem = styled(MenuItem)`
  && {
    a {
      color: ${colorPalette.primary1.main};
      &:hover {
        color: ${colorPalette.primary1.light};
      }
    }
  }
`

class MainMenu extends Component {
  state = {
    storeMenuOpen: false,
  }

  // Lifecycle methods
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  // UI event methods
  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.buttonRef && !this.buttonRef.contains(event.target)) {
      this.setState({ storeMenuOpen: false })
    }
  }

  handleStoreBtnClick = () => {
    const { storeMenuOpen } = this.state
    this.setState({ storeMenuOpen: !storeMenuOpen })
  }

  handleStoreMenuItemClick = () => {
    this.setState({ storeMenuOpen: false })
  }

  setWrapperRef = node => {
    this.wrapperRef = node
  }

  setButtonRef = node => {
    this.buttonRef = node
  }

  // Render methods
  render() {
    const { storeMenuOpen } = this.state
    const { handleMenuItemClick } = this.props
    const activeStyle = {
      color: colorPalette.tertiary1.light,
    }
    return (
      <MenuOptions>
        <MenuOption hideOnDesktop>
          <NavLink to="/" exact activeStyle={activeStyle} onClick={handleMenuItemClick}>
            בית
          </NavLink>
        </MenuOption>
        <MenuOption>
          <NavLink to="/myTrainers" exact activeStyle={activeStyle} onClick={handleMenuItemClick}>
            מאמנים אישיים
          </NavLink>
        </MenuOption>
        <MenuOption hideOnDesktop>
          <NavLink to="/store/1" exact activeStyle={activeStyle} onClick={handleMenuItemClick}>
            חנות: מאמנים בשבילך
          </NavLink>
        </MenuOption>
        <MenuOption hideOnDesktop>
          <NavLink to="/store/2" exact activeStyle={activeStyle} onClick={handleMenuItemClick}>
            חנות: מוצרים בשבילך
          </NavLink>
        </MenuOption>
        <MenuOption hideOnMobile isActive={storeMenuOpen} ref={this.setButtonRef} onClick={this.handleStoreBtnClick}>
          <div>חנות</div>
          <SubMenuOptionsPopup isActive={storeMenuOpen} ref={this.setWrapperRef}>
            <StyledMenuList>
              <StyledMenuItem>
                <Link to="/store/1" onClick={this.handleStoreMenuItemClick}>
                  מאמנים בשבילך
                </Link>
              </StyledMenuItem>
              <StyledMenuItem>
                <Link to="/store/2" onClick={this.handleStoreMenuItemClick}>
                  מוצרים בשבילך
                </Link>
              </StyledMenuItem>
            </StyledMenuList>
          </SubMenuOptionsPopup>
        </MenuOption>
        <MenuOption>
          <NavLink to="/articles" exact activeStyle={activeStyle} onClick={handleMenuItemClick}>
            מאמרים
          </NavLink>
        </MenuOption>
        <MenuOption>
          <NavLink to="/contactUs" exact activeStyle={activeStyle} onClick={handleMenuItemClick}>
            כתבו לנו
          </NavLink>
        </MenuOption>
      </MenuOptions>
    )
  }
}

export default MainMenu
