import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import fitthybodyLogo from '../../images/fitthybodyLogo.png'
import { colorPalette, convertHexToRGB, globalStyles } from '../../styles/styleVariables'
import { mediaQuery } from '../../services/global'
import MotiviationLines from './MotivationLines'

// Consts

// Style
const CordovaLoginButtonsWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  padding: 40px 25px;
  background: ${colorPalette.primary1.main};
`
const NotRegisteredYetButtonLink = styled(Link)`
  cursor: pointer;
  color: ${colorPalette.primary1.main};
`
const StyledButton = styled(Button)`
  && {
    font-size: 22px;
    line-height: 1.4;
    max-height: 62px;
    flex: 0 0 100%;
    text-transform: none;
    ${mediaQuery.phone`
      font-size: 16px;
      margin-bottom: 10px;
      width: 100%;
    `}
    & > span {
      display: block;
    }
  }
`
const CordovaOverlayLayer = styled.div`
  position: fixed;
  background: ${colorPalette.white.main};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const LoadingIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;
`
const Logo = styled.div`
  background-image: url(${fitthybodyLogo});
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 0;
  margin-left: 12px;
  margin-top: 5px;
  text-align: center;
  width: 201px;
  height: 61px;
`

// Component
class CordovaLoginButtons extends Component {
  state = {
    //showOverlay: true,
  }

  // Lifecycle methods
  /*componentDidMount() {
    if (sessionStorage.getItem('showLoadingOverlayCordovaYoutube') !== 'true') {
      window.setTimeout(() => {
        this.setState({ showOverlay: false })
      }, 4000)
      sessionStorage.setItem('showLoadingOverlayCordovaYoutube', 'true')
    } else {
      this.setState({ showOverlay: false })
    }
  }*/

  // Render methods
  render() {
    //const { showOverlay } = this.state
    const { currentUser } = this.props
    //const isAndroid = window.device.platform.toLowerCase() === 'android'

    return (
      <>
        {/*showOverlay && isAndroid && (
          <CordovaOverlayLayer>
            <Logo />
            <LoadingIndicatorWrapper>
              <CircularProgress />
            </LoadingIndicatorWrapper>
          </CordovaOverlayLayer>
        )*/}
        <CordovaLoginButtonsWrapper>
          <MotiviationLines />
          {currentUser && (
            <NotRegisteredYetButtonLink to="/program">
              <StyledButton variant="contained" color="secondary" size="large">
                לחוויית החיטוב ב-90 יום
              </StyledButton>
            </NotRegisteredYetButtonLink>
          )}
          {!currentUser && (
            <NotRegisteredYetButtonLink to="/registerToChallenge/newUserViaEmail">
              <StyledButton variant="contained" color="secondary" size="large">
                הרשמה לתוכנית
              </StyledButton>
            </NotRegisteredYetButtonLink>
          )}
          {!currentUser && (
            <NotRegisteredYetButtonLink to="/registerToChallenge">
              <StyledButton variant="contained" color="secondary" size="large">
                כניסה לרשומים
              </StyledButton>
            </NotRegisteredYetButtonLink>
          )}
        </CordovaLoginButtonsWrapper>
      </>
    )
  }
}

export default CordovaLoginButtons
