import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import LeftArrowIcon from '@material-ui/icons/KeyboardArrowLeft'
import RightArrowIcon from '@material-ui/icons/KeyboardArrowRight'
import { colorPalette } from '../styles/styleVariables'
import { mediaQuery } from '../services/global'

// Style
const FullVideoWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1001;
`
const FullVideoBox = styled.div`
  position: relative;
  padding: 20px;
  padding-top: 55px;
  border-radius: 10px;
  background-color: ${colorPalette.primary1.main};
  ${mediaQuery.phone`
    height: 100%;
    width: 100%;
    margin: 0;
    overflow: auto;
    iframe {
      width: 100%;
    }
  `}
`
const VideoAndDescWrapper = styled.div`
  display: flex;
  margin: 0 34px;
  ${mediaQuery.phone`
    display: block;
    padding-top: env(safe-area-inset-top);
    ${props =>
      props.showOnlyVideo
        ? `
      position: fixed;
      top: 50px;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      iframe {
        height: 100%;
      }
    `
        : ''}
  `}
`
const CloseBtnWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 15px;
  svg {
    color: ${colorPalette.white.main};
  }
  ${mediaQuery.phone`
    margin-top: env(safe-area-inset-top);
  `}
`
const VideoDesc = styled.div`
  margin-right: 20px;
  color: ${colorPalette.white.main};
  ${mediaQuery.phone`
    margin-top: 20px;
    margin-right: 0;
  `}
`
const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  ${mediaQuery.phone`
    flex-direction: column;
  `}
`
const StyledButton = styled(Button)`
  && {
    background: ${colorPalette.white.main};
    color: ${colorPalette.primary1.main};
    margin-left: 20px;
    &:hover {
      background: ${colorPalette.tertiary1.main};
    }
  }
  ${mediaQuery.phone`
    && {
      margin-left: 0;
      margin-bottom: 20px;
    }
  `}
`
const NextVideoBtn = styled.div`
  position: absolute;
  left: 5px;
  top: 170px;
  svg {
    color: ${colorPalette.white.main};
    width: 45px;
    height: 45px;
    cursor: pointer;
  }
  &:hover svg {
    color: ${colorPalette.tertiary1.main};
  }
`
const PrevVideoBtn = styled(NextVideoBtn)`
  right: 5px;
  left: auto;
`

// Component
class WorkoutVideoDisplay extends Component {
  state = {
    activeVideo: null,
    startTimeMS: 1,
  }

  // Lifecycle methods
  async componentDidMount() {}

  // Helper methods

  // UI event methods
  handleMoveTimeToStart = e => {
    e.stopPropagation()
    this.setState({ startTimeMS: 1 })
  }

  handleMoveTimeToWorkoutStartTime = e => {
    const { activeVideo } = this.props
    e.stopPropagation()
    this.setState({ startTimeMS: activeVideo.WorkoutStartTimeSecs })
  }

  // Render methods
  render() {
    const { startTimeMS } = this.state
    const { activeVideo, handleClose, handleNextVideoClick, handlePrevVideoClick } = this.props
    return (
      <FullVideoWrapper>
        <FullVideoBox>
          <CloseBtnWrapper onClick={handleClose}>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </CloseBtnWrapper>
          <VideoAndDescWrapper showOnlyVideo={activeVideo.WorkoutStartTimeSecs == null}>
            <iframe
              width="560"
              height={activeVideo.WorkoutStartTimeSecs ? '315' : '450'}
              src={`https://www.youtube.com/embed/${activeVideo.YoutubeCode}?autoplay=1&rel=0&start=${startTimeMS}`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <VideoDesc>
              {activeVideo.Description &&
                activeVideo.Description.split('\n').map(function(item, idx) {
                  return (
                    <span key={idx}>
                      {item}
                      <br />
                    </span>
                  )
                })}
              {activeVideo.WorkoutStartTimeSecs && (
                <ButtonsWrapper>
                  <StyledButton variant="outlined" color="primary" onClick={this.handleMoveTimeToStart}>
                    עבור לשלב ההסברים
                  </StyledButton>
                  <StyledButton variant="outlined" color="primary" onClick={this.handleMoveTimeToWorkoutStartTime}>
                    עבור לשלב האימון
                  </StyledButton>
                </ButtonsWrapper>
              )}
            </VideoDesc>
          </VideoAndDescWrapper>
          {handleNextVideoClick && (
            <NextVideoBtn onClick={handleNextVideoClick}>
              <LeftArrowIcon />
            </NextVideoBtn>
          )}
          {handlePrevVideoClick && (
            <PrevVideoBtn onClick={handlePrevVideoClick}>
              <RightArrowIcon />
            </PrevVideoBtn>
          )}
        </FullVideoBox>
      </FullVideoWrapper>
    )
  }
}

export default WorkoutVideoDisplay
