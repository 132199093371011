import React, { Component } from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { mediaQuery, isAdminUser } from '../services/global'
import { showInChallengePhaseOptions } from '../services/workoutVideos'
import { calcDaysSinceStartedChallenge } from '../services/challengeProgressHelper'
import { colorPalette } from '../styles/styleVariables'

// Consts

// Style
const StyledButtonGroup = styled(ButtonGroup)`
  && {
    background-color: ${colorPalette.white.main};
    margin-top: 20px;
  }
  ${mediaQuery.phone`
    && {
      width: 100%;
    }
  `}
`
const StyledButton = styled(Button)`
  && {
    ${props =>
      props.active
        ? `
      background-color: ${colorPalette.primary1.main};
      color: ${colorPalette.white.main};
      &:hover {
        background-color: ${colorPalette.primary1.main};
      }
    `
        : ''}
  }
`

// Component
class ChallengePhaseSelector extends Component {
  state = {}

  // Lifecycle methods
  componentDidMount() {}

  // Helper methods

  // UI event methods

  // Render methods
  render() {
    const { personalStats, currentUser, selectedChallengePhase, handleChallengePhaseClick } = this.props

    const daysSinceStartedChallenge = calcDaysSinceStartedChallenge(personalStats, currentUser)
    const isAdmin = isAdminUser(currentUser)

    return (
      <StyledButtonGroup color="primary" orientation="vertical" className="wizard_challengePhaseSelector">
        {showInChallengePhaseOptions.map(item => (
          <StyledButton
            active={selectedChallengePhase === item.value}
            disabled={
              !isAdmin && ((item.value === 2 && daysSinceStartedChallenge < 30) || (item.value === 3 && daysSinceStartedChallenge < 60))
            }
            onClick={handleChallengePhaseClick.bind(this, item.value)}
          >
            {item.label}
          </StyledButton>
        ))}
      </StyledButtonGroup>
    )
  }
}

export default ChallengePhaseSelector
