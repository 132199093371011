import { connect } from 'react-redux'
import PhoneCallFeedbackScreen from '../components/PhoneCallFeedbackScreen'
import { openSnackbar } from '../bundles/snackbar'

export default connect(
  state => ({}),
  {
    openSnackbar,
  },
)(PhoneCallFeedbackScreen)
