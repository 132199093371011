import moment from 'moment'

export const green = '#0cff0c'
export const yellow = '#ffff00'
export const red = '#ff7878'

export function calcBodyFatColor(bodyFatVal, user) {
  var userAge = moment().diff(user.BirthDate, 'years')

  // for Male
  if (user.Gender === 'male') {
    if (userAge < 30) {
      // Male below age 30
      if (bodyFatVal <= 13) {
        return green
      } else if (bodyFatVal <= 23) {
        return yellow
      }
      return red
    } else if (userAge < 40) {
      // Male between 30-40
      if (bodyFatVal <= 14) {
        return green
      } else if (bodyFatVal <= 24) {
        return yellow
      }
      return red
    } else if (userAge < 50) {
      // Male between 40-50
      if (bodyFatVal <= 16) {
        return green
      } else if (bodyFatVal <= 26) {
        return yellow
      }
      return red
    } else if (userAge < 60) {
      // Male between 50-60
      if (bodyFatVal <= 17) {
        return green
      } else if (bodyFatVal <= 27) {
        return yellow
      }
      return red
    } else {
      // Male over 60
      if (bodyFatVal <= 18) {
        return green
      } else if (bodyFatVal <= 28) {
        return yellow
      }
      return red
    }
  }
  // for Female
  else {
    if (userAge < 30) {
      // Female below age 30
      if (bodyFatVal <= 19) {
        return green
      } else if (bodyFatVal <= 31) {
        return yellow
      }
      return red
    } else if (userAge < 40) {
      // Female between 30-40
      if (bodyFatVal <= 20) {
        return green
      } else if (bodyFatVal <= 32) {
        return yellow
      }
      return red
    } else if (userAge < 50) {
      // Female between 40-50
      if (bodyFatVal <= 21) {
        return green
      } else if (bodyFatVal <= 33) {
        return yellow
      }
      return red
    } else if (userAge < 60) {
      // Female between 50-60
      if (bodyFatVal <= 22) {
        return green
      } else if (bodyFatVal <= 34) {
        return yellow
      }
      return red
    } else {
      // Female over 60
      if (bodyFatVal <= 23) {
        return green
      } else if (bodyFatVal <= 35) {
        return yellow
      }
      return red
    }
  }
}

export function calcWeightColor(weightVal, heightVal, user) {
  //var userAge = moment().diff(user.BirthDate, 'years')
  heightVal = heightVal / 100
  const bmi = weightVal / heightVal ** 2 // calculate the BMI

  if (bmi < 25) {
    return green
  } else if (bmi < 35) {
    return yellow
  } else {
    return red
  }
}
