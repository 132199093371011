import React, { Component } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import { withRouter } from 'react-router-dom'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloseIcon from '@material-ui/icons/Close'
import { invokeAPIcall } from '../../services/rest'
import { mediaQuery, keysToLowerCase } from '../../services/global'
import { requiredValidation, dateValidation } from '../../services/formValidation'
import StyledSelect from '../StyledSelect'
import BirthDateField from '../RegisterScreen/BirthDateField'

// Consts

// Style
const StyledDialogTitle = styled(DialogTitle)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
  }
`
const StyledButton = styled(Button)`
  && {
    margin-left: 10px;
  }
  ${mediaQuery.phone`
    && {
      margin-left: 0;
      margin-bottom: 10px;
      flex: 1;
    }
  `}
`
const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 40px;
  ${mediaQuery.phone`
    flex-direction: column;
    margin-top: 10px;
  `}
`

const FormWrapper = styled.div`
  padding: 20px 40px;
  padding-top: 0;
`
const FieldsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin: 10px 0;
  min-width: 460px;
  ${mediaQuery.phone`
    min-width: 0;
  `}
`
const FieldCell = styled.div`
  flex: 0 0 48%;
  display: flex;
  justify-content: space-between;
  .MuiFormControl-fullWidth {
    margin-top: 16px;
    margin-bottom: 8px;
  }
  ${mediaQuery.phone`
    flex: 0 0 100%;
  `}
`
const LoadingIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5px;
`

// Component
class PersonalStatsOfPhaseDialog extends Component {
  state = {
    initialValues: {},
    personalStatsList: [],
    isLoading: true,
  }

  // Lifecycle methods
  async componentWillMount() {
    const { personalStatsFieldsToDisplay, phase } = this.props
    const personalStatsList = await invokeAPIcall({ uri: 'api/personalStats' })
    const personalStatsOfSpecificPhase = personalStatsList.filter(item => item.Phase === phase)

    const initialValues = {}

    // init the initial values of the fields (based on values in DB, 'existingPersonalStatsRecord')
    personalStatsFieldsToDisplay.forEach(fieldInfo => {
      initialValues[fieldInfo.fieldName] = null

      // if there is a value in DB
      if (personalStatsOfSpecificPhase.length > 0) {
        const existingPersonalStatsRecord = keysToLowerCase({ ...personalStatsOfSpecificPhase[0] })
        if (existingPersonalStatsRecord[fieldInfo.fieldName]) {
          initialValues[fieldInfo.fieldName] = existingPersonalStatsRecord[fieldInfo.fieldName]
        }
      }
    })

    // in phase 'start' we have 2 extra fields: gender+birthDate
    if (phase === 'start') {
      initialValues.gender = ''
      initialValues.birthDate = null
    }

    this.setState({ initialValues, personalStatsList, isLoading: false })
  }

  // Helper methods
  validate = values => {
    const { personalStatsFieldsToDisplay, phase, currentUser } = this.props

    let errors = {}

    personalStatsFieldsToDisplay.forEach(fieldInfo => {
      if (requiredValidation(values[fieldInfo.fieldName])) {
        //errors[fieldInfo.fieldName] = requiredValidation(values[fieldInfo.fieldName])
      }
    })

    // in phase 'start' we have 2 extra fields: gender+birthDate
    if (phase === 'start' && (!currentUser.Gender || !currentUser.BirthDate)) {
      if (requiredValidation(values.birthDate)) {
        errors.birthDate = requiredValidation(values.birthDate)
      } else if (dateValidation(values.birthDate)) {
        errors.birthDate = dateValidation(values.birthDate)
      }
      if (requiredValidation(values.gender)) {
        errors.gender = requiredValidation(values.gender)
      }
    }

    return errors
  }

  convertToFeet = n => {
    var realFeet = (n * 0.3937) / 12
    var feet = Math.floor(realFeet)
    var inches = Math.round((realFeet - feet) * 12)
    return feet + ' פיט, ' + inches + ' אינ׳'
  }

  // UI event methods
  handleSubmit = async (values, { setSubmitting }) => {
    const { phase, handleClose } = this.props
    const { personalStatsList } = this.state

    const personalStatsOfSpecificPhase = personalStatsList.filter(item => item.Phase === phase)
    if (personalStatsOfSpecificPhase.length > 0) {
      const existingPersonalStatsRecord = personalStatsOfSpecificPhase[0]
      const data = {
        ...keysToLowerCase(existingPersonalStatsRecord),
        ...values,
      }

      // invoke API call
      try {
        const responseData = await invokeAPIcall({ uri: 'api/personalStats', data, method: 'POST' })

        // if API success
        if (responseData.Key === true) {
          // in phase 'start' we have 2 extra fields: gender+birthDate
          if (phase === 'start' && values.birthDate && values.gender) {
            // extra call to update these user info fields
            const userData = {
              birthDate: values.birthDate,
              gender: values.gender,
            }
            await invokeAPIcall({ uri: 'api/users/UpdateUserInfoExtra', data: userData, method: 'PUT' })
          }

          handleClose()
          window.location.reload()
        }
        // if failed
        else {
          alert(responseData.Value || 'התרחשה שגיאה')
        }
      } catch (error) {
        console.log('error in updating PersonalStats values', error)
      }
    }
    setSubmitting(false)
  }

  // Render methods
  render() {
    const { initialValues, isLoading } = this.state
    const { personalStatsFieldsToDisplay, handleClose, phase, currentUser } = this.props
    if (isLoading) {
      return null
    }

    return (
      <Dialog onClose={handleClose} open>
        <StyledDialogTitle id="simple-dialog-title" disableTypography>
          <Typography variant="h6">עדכון המדדים שלי</Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <DialogContent>
          <Formik validate={this.validate} initialValues={initialValues} onSubmit={this.handleSubmit}>
            {formikBag => {
              const { isSubmitting, values, errors, touched, handleChange, handleBlur, isValid, setFieldValue, setFieldTouched } = formikBag
              return (
                <FormWrapper>
                  <Form>
                    {phase === 'start' && (!currentUser.Gender || !currentUser.BirthDate) && (
                      <FieldsRow>
                        <FieldCell>
                          <StyledSelect
                            selectParams={{
                              name: 'gender',
                              value: values.gender,
                              onChange: handleChange,
                              onBlur: handleBlur,
                              margin: 'normal',
                              fullWidth: true,
                              inputProps: {
                                name: 'gender',
                                id: 'gender-simple',
                              },
                            }}
                            label="מין"
                            htmlFor="gender-simple"
                            helperText={errors.gender && touched.gender && errors.gender}
                            error={errors.gender && touched.gender}
                            required
                          >
                            <MenuItem value={'male'}>זכר</MenuItem>
                            <MenuItem value={'female'}>נקבה</MenuItem>
                          </StyledSelect>
                        </FieldCell>
                        <FieldCell>
                          <BirthDateField
                            values={values}
                            errors={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                          />
                        </FieldCell>
                      </FieldsRow>
                    )}
                    <FieldsRow>
                      {personalStatsFieldsToDisplay.map(fieldInfo => {
                        const dropdownOptions = []
                        if (fieldInfo.dropdownUnknownVal) {
                          dropdownOptions.push({ value: fieldInfo.dropdownUnknownVal, label: fieldInfo.dropdownUnknownVal })
                        }
                        for (let i = fieldInfo.min; i <= fieldInfo.max; i++) {
                          let label = fieldInfo.labelFormat
                          label = label.replace('{item}', i)
                          label = label.replace('{libItem}', Math.round(i * 2.204))
                          label = label.replace('{feetItem}', this.convertToFeet(i))
                          dropdownOptions.push({ value: i, label })
                        }

                        return (
                          <FieldCell>
                            {fieldInfo.type === 'dropdown' && (
                              <>
                                <StyledSelect
                                  selectParams={{
                                    name: fieldInfo.fieldName,
                                    value: values[fieldInfo.fieldName],
                                    onChange: handleChange,
                                    margin: 'normal',
                                    fullWidth: true,
                                    inputProps: {
                                      name: fieldInfo.fieldName,
                                      id: `${fieldInfo.fieldName}-simple`,
                                    },
                                  }}
                                  htmlFor={`${fieldInfo.fieldName}-simple`}
                                  label={fieldInfo.fieldLabel}
                                  helperText={errors[fieldInfo.fieldName] && touched[fieldInfo.fieldName] && errors[fieldInfo.fieldName]}
                                  error={errors[fieldInfo.fieldName] && touched[fieldInfo.fieldName]}
                                >
                                  {dropdownOptions.map((item, index) => {
                                    const autoFocusItem = fieldInfo.initialFocusOnVal
                                      ? item.value == fieldInfo.initialFocusOnVal
                                      : index === Math.floor(dropdownOptions.length / 2)
                                    return (
                                      <MenuItem value={item.value} autoFocus={autoFocusItem}>
                                        {item.label}
                                      </MenuItem>
                                    )
                                  })}
                                </StyledSelect>
                              </>
                            )}
                          </FieldCell>
                        )
                      })}
                    </FieldsRow>
                    <ButtonsWrapper>
                      <StyledButton variant="contained" color="primary" disabled={isSubmitting} type="submit">
                        אישור
                      </StyledButton>
                    </ButtonsWrapper>
                    {isSubmitting && (
                      <LoadingIndicatorWrapper>
                        <CircularProgress />
                      </LoadingIndicatorWrapper>
                    )}
                  </Form>
                </FormWrapper>
              )
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    )
  }
}

export default withRouter(PersonalStatsOfPhaseDialog)
