import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import FitnessIcon from '@material-ui/icons/FitnessCenter'
import FoodIcon from '@material-ui/icons/LocalDining'
import ChatIcon from '@material-ui/icons/Chat'
import StandingIcon from '@material-ui/icons/EmojiPeople'
import VolumeUpIcon from '@material-ui/icons/VolumeUp'
import VolumeOffIcon from '@material-ui/icons/VolumeOff'
import EcoIcon from '@material-ui/icons/Eco'
import DownArrowIcon from '@material-ui/icons/KeyboardArrowDown'
import { colorPalette, convertHexToRGB, globalStyles } from '../../styles/styleVariables'
import { mediaQuery, mobileCheck } from '../../services/global'
import { getString, multilineHtmlText } from '../../services/presetStringsHelper'
import carouselBg from '../../images/runningBgMainPhoto.jpg'
import AboutUsCarouselItem from './AboutUsCarouselItem'
import MotivationCarouselItem from './MotivationCarouselItem'
import ContactCarouselItem from './ContactCarouselItem'
import FeedbackCarouselItem from './FeedbackCarouselItem'
import ChallengeCountdownCarouselItem from './ChallengeCountdownCarouselItem'
import SmartphoneScreenImageBg1 from '../../images/SmartphoneScreenImageBg1.png'
import SmartphoneScreenImageBg2 from '../../images/SmartphoneScreenImageBg2.png'
import SmartphoneScreenImageBg3 from '../../images/SmartphoneScreenImageBg3.png'
import SecondaryContentBoxLeftImageBg from '../../images/SecondaryContentBoxLeftImage.jpg'
import SecondaryContentBoxRightImageBg from '../../images/SecondaryContentBoxRightImage.jpg'
import PhoneOnTable from '../../images/PhoneOnTable.png'
import pushUpGuyBg from '../../images/pushUpGuy3.jpeg'
import healthyFoodBg from '../../images/healthyFoodBg.jpg'

// Consts
const youtubeVideoId = window.cordova ? 'm_GrS51QtQc' : 'EhurwAWkpYM' // 'K5klog2BqAQ'
const getVideoHeight = width => (mobileCheck() ? (window.cordova ? document.body.clientHeight - 40 : width / 1.3) : width / 1.77 - 4)
const getVideoWidth = () => (mobileCheck() ? document.body.clientWidth * 1.2 : document.body.clientWidth)

// Style
const ChallengeCarouselItemWrapper = styled.div`
  color: ${colorPalette.white.main};
  position: relative;
  display: flex;
  flex-direction: column;
  ${mediaQuery.phone`
    height: auto;
  `}
`
const MainPhoto = styled.div`
  background: url(${carouselBg});
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  flex: 0 0 580px;
  height: 580px;
  width: 100%;
  border-bottom-left-radius: ${globalStyles.mainBoxRadius};
  border-bottom-right-radius: ${globalStyles.mainBoxRadius};
  position: relative;
  z-index: 18;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  padding: 0 20px;
  ${mediaQuery.homeScreenChallengeCarouselItemLevel1`
    height: 500px;
    flex: 0 0 500px;
  `}
  ${mediaQuery.homeScreenChallengeCarouselItemLevel2`
  height: 400px;
  flex: 0 0 400px;
  `}
  ${mediaQuery.phone`
    height: 200px;
    flex: 0 0 200px;
    background-size: 130% auto;
  `}
`
const MainVideoWrapper = styled.div`
  position: relative;
  top: -24px;
  border-bottom-left-radius: ${globalStyles.mainBoxRadius};
  border-bottom-right-radius: ${globalStyles.mainBoxRadius};
  overflow: hidden;
  z-index: 18;
  height: ${props => props.height}px;
  width: ${props => props.width}px;
  svg {
    width: 40px;
    height: 40px;
    color: rgba(0, 0, 0, 0.7);
  }
  ${mediaQuery.phone`
    top: -10px;
    width: 100%;
    height: ${props => (window.cordova ? props.height : props.height - 50)}px;
    svg {
      width: 20px;
      height: 20px;
    }
    iframe {
      position: relative;
      left: ${props => props.width * (window.cordova ? 0.095 : 0.09)}px;
      ${props => (window.cordova ? `top: -${props.height * 0.09}px` : '')}
    }
  `}
`
const MuteIconWrapper = styled.div`
  position: absolute;
  top: 65px;
  left: 20px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  padding: 10px;
  ${mediaQuery.phone`
    display: ${window.cordova ? 'none' : 'flex'};
    padding: 4px;
  `}
`
const MainVideoWrapperOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`
const MainVideoImageThumbnailOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #eee;
  background-image: url('http://img.youtube.com/vi/${youtubeVideoId}/maxresdefault.jpg');
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  margin: auto;
`
const ContentBox = styled.div`
  background-color: ${colorPalette.primary1.main};
  border-bottom-left-radius: ${globalStyles.mainBoxRadius};
  border-bottom-right-radius: ${globalStyles.mainBoxRadius};
  box-shadow: ${globalStyles.mainBoxShadow};
  position: relative;
  top: -48px;
  z-index: 8;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  ${mediaQuery.phone`
    flex-direction: column;
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: -15px;
    box-shadow: none;
    z-index: 10;
  `}
`
const BottomContentBox = styled(ContentBox)`
  position: relative;
  background-color: ${colorPalette.white.main};
  color: ${colorPalette.primary1.main};
  z-index: 4;
  top: -145px;
  flex-direction: row;
  padding-top: 0;
  ${mediaQuery.phone`
    padding-top: 45px;
    z-index: 4;
    overflow: hidden;
  `}
`
const ContentBoxInner = styled.div`
  max-width: 1240px;
  margin: 0 auto;
`
const BottomContentBoxInner = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  ${mediaQuery.phone`
    flex-direction: column;
  `}
`
const SecondaryContentBox = styled(ContentBox)`
  top: -70px;
  padding-top: 50px;
  overflow: hidden;
`
const SecondaryContentBoxInner = styled.div`
  margin: 0;
`
const PromotionTitle = styled.h2`
  font-size: 37px;
  font-weight: 500;
  margin: 0;
  ${mediaQuery.tablet`
    font-size: 30px;
  `}
  ${mediaQuery.phone`
    font-size: 25px;
    margin-top: 10px;
  `}
`
const PromotionTitleFirst = styled(PromotionTitle)`
  color: ${colorPalette.white.main};
  margin: 0;
  text-align: center;
`
const PromotionTitleSecond = styled(PromotionTitle)``
const PromotionSubTitle = styled.h4`
  font-size: 24px;
  font-weight: 400;
  margin-top: 10px;
  max-width: 90%;
  ${mediaQuery.phone`
    font-size: 18px;
    text-shadow: none;
    max-width: 100%;
  `}
`
const PromotionSubTitleSecond = styled(PromotionSubTitle)`
  margin-top: 30px;
  margin-bottom: 30px;
  ${mediaQuery.phone`
    margin-top: 0;
    margin-bottom: 0;
  `}
`
const TitlesSection = styled.div`
  flex: 3;
  padding-right: 50px;
  padding-top: 50px;
  ${mediaQuery.phone`
    padding: 0 20px;
  `}
`
const PromotionItemsList = styled.ul`
  flex: 2;
  margin: 0 15px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${mediaQuery.phone`
    margin: 20px 0;
  `}
`
const PromotionItem = styled.li`
  font-size: 22px;
  margin: 12px 0;
  span {
    display: none;
    font-size: 14px;
  }
  &:hover span {
    display block;
  }
  /*transition: transform 0.5s, opacity 2s;
  transition-delay: ${props => props.index * 0.1}s;
  transform: ${props => (props.isActive ? 'translateX(0%)' : 'translateX(100%)')};
  opacity: ${props => (props.isActive ? '1' : '0')};*/
`
const ItemDesc = styled.div`
  font-size: 13px;
  font-weight: 500;
  text-shadow: none;
  margin-right: 35px;
  color: ${colorPalette.black.main};
  display: none;
  ${mediaQuery.phone`
    font-size: 12px;
  `}
`
const ItemTitle = styled.div`
  ${props => (props.isHover ? 'cursor: pointer; display: inline;' : '')}
  &:hover {
    ${ItemDesc} {
      display: block !important;
    }
  }
  ${mediaQuery.phone`
    font-size: 16px;
    text-shadow: none;
  `}
`
const iconCSS = css`
  && {
    width: 25px;
    margin-left: 10px;
  }
`
const StyledFoodIcon = styled(FoodIcon)`
  ${iconCSS}
`
const StyledFitnessIcon = styled(FitnessIcon)`
  ${iconCSS}
  transform: rotate(-45deg);
`
const StyledChatIcon = styled(ChatIcon)`
  ${iconCSS}
`
const StyledStandingIcon = styled(StandingIcon)`
  ${iconCSS}
`

const TopContentText = styled.div`
  font-size: 20px;
  padding: 20px 10px;
  padding-top: 50px;
  width: 100%;
  display: flex;
  ${mediaQuery.phone`
    padding: 8px 30px;
    flex-direction: column;
    font-size: 16px;
  `}
`
const TopContentTextRightSide = styled.div`
  flex: 2;
  font-size: 30px;
  ${mediaQuery.phone`
    font-size: 16px;
  `}
`
const TopContentTextLeftSide = styled(TopContentTextRightSide)`
  margin-right: 35px;
  flex: 1;
  ${mediaQuery.phone`
    margin-right: 0;
    margin-top: 20px;
  `}
`
const ButtonsWrapper = styled.div`
  display: flex;
  margin: 14px 0;
  margin-bottom: 34px;
  justify-content: center;
  ${mediaQuery.phone`
    justify-content: center;
  `}
`
const StyledButton = styled(Button)`
  && {
    padding: 6px 35px;
    font-size: 20px;
  }
`
const VideoOverlayTitleWrapper = styled.div`
  position: absolute;
  background: rgba(255, 255, 255, 0.7);
  top: 28px;
  right: 0;
  padding: 20px;
  max-width: 420px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-bottom-left-radius: ${globalStyles.mainBoxRadius};
  border-bottom-right-radius: ${globalStyles.mainBoxRadius};
  ${mediaQuery.phone`
    display: none;
  `}
`
const MobileVideoOverlayTitleWrapper = styled(VideoOverlayTitleWrapper)`
  display: none;
  ${mediaQuery.phone`
    display: flex;
    position: static;
    background: ${colorPalette.white.main};
    top: 20px;
    max-width: none;
    padding: 8px;
    padding-top: 35px;
    padding-bottom: 20px;
    margin: 0 -10px;
    margin-top: -2px;
  `}
`
const MobileVideoOverlayTitleSeparator = styled.div`
  background: ${colorPalette.primary1.main};
  height: 40px;
  border-bottom-left-radius: ${globalStyles.mainBoxRadius};
  border-bottom-right-radius: ${globalStyles.mainBoxRadius};
`
const VideoOverlayTitle = styled.div`
  font-size: 30px;
  font-weight: 600;
  color: ${colorPalette.primary1.main};
  margin-bottom: 20px;
  ${mediaQuery.phone`
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 15px;
  `}
`
const VideoOverlayTitleMain = styled(VideoOverlayTitle)`
  display: none;
  ${mediaQuery.phone`
    display: block;
    font-size: 25px;
    font-weight: 600;
  `}
`
const StyledDoneOutlineIcon = styled(EcoIcon)`
  && {
    width: 22px;
    margin-left: 10px;
  }
`
const SmartphoneScreenImages = styled.div`
  display: flex;
`
const SmartphoneScreenImage = styled.div`
  width: 170px;
  height: 350px;
  background: url(${props => {
    if (props.index === '1') return SmartphoneScreenImageBg1
    if (props.index === '2') return SmartphoneScreenImageBg2
    if (props.index === '3') return SmartphoneScreenImageBg3
  }});
  transform: rotate(
    ${props => {
      if (props.index === '1') return '12deg'
      if (props.index === '2') return '0deg'
      if (props.index === '3') return '-12deg'
    }}
  );
  z-index: ${props => {
    if (props.index === '1') return '1'
    if (props.index === '2') return '2'
    if (props.index === '3') return '1'
  }};
  background-size: contain;
  background-repeat: no-repeat;
  ${mediaQuery.tablet`
    width: 120px;
    height: 238px;
  `}
  ${mediaQuery.phone`
    width: 90px;
    height: 138px;
  `}
`
const SecondaryContentBoxItemsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  ${mediaQuery.phone`
    flex-direction: column;
    align-items: center;
  `}
`
const SecondaryContentBoxMiddle = styled.div`
  flex: 1;
`
const SecondaryContentBoxLeftImage = styled.div`
  background-image: url(${SecondaryContentBoxLeftImageBg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom left;
  width: 282px;
  height: 368px;
  ${mediaQuery.phone`
    width: 106px;
    height: 150px;
  `}
`
const SecondaryContentBoxRightImage = styled(SecondaryContentBoxLeftImage)`
  background-image: url(${SecondaryContentBoxRightImageBg});
  background-position: bottom right;
  width: 215px;
`
const MotivationCarouselItemWrapper = styled.div`
  background-color: ${colorPalette.primary1.main};
  border-bottom-left-radius: ${globalStyles.mainBoxRadius};
  border-bottom-right-radius: ${globalStyles.mainBoxRadius};
  color: ${colorPalette.white.main};
  box-shadow: ${globalStyles.mainBoxShadow};
  position: relative;
  top: -122px;
  z-index: 5;
  padding: 0;
  overflow: hidden;
  ${mediaQuery.phone`
    height: auto;
  `}
`
const DietCarouselItemWrapper = styled(MotivationCarouselItemWrapper)`
  top: -166px;
  z-index: 3;
`
const MotivationContentBoxInnerWrapper = styled.div`
  display: flex;
  ${mediaQuery.phone`
    flex-direction: column;
  `}
`
const PhoneOnTableImage = styled.div`
  background-image: url(${PhoneOnTable});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 570px;
  height: auto;
  ${mediaQuery.phone`
    height: 280px;
    width: 100%;
  `}
`
const HealthyFoodBgImage = styled(PhoneOnTableImage)`
  background-image: url(${healthyFoodBg});
`
const MotivationContentBoxInner = styled.div`
  flex: 1;
  padding-top: 62px;
  padding-right: 50px;
  ${mediaQuery.phone`
    padding-top: 32px;
    padding-right: 10px;
    padding-left: 10px;
  `}
`
const StyledDownArrowIcon = styled(DownArrowIcon)`
  margin-right: 10px;
`
const PhotoPlaceholder = styled.div`
  flex: 0 0 570px;
  background-image: url(${pushUpGuyBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left top;
  ${mediaQuery.phone`
    margin-left: -10px;
    margin-right: -10px;
  `}
`

// Component
class ChallengeCarouselItem extends Component {
  state = {
    showPromotionItems: false,
    windowWidth: getVideoWidth(),
    videoMute: true,
    videoPlaying: false,
    videoReadyToPlay: false,
  }

  // Lifecycle methods
  componentDidMount() {
    window.setTimeout(() => {
      this.setState({ showPromotionItems: true, windowWidth: getVideoWidth() })
    }, 800)

    if (!window.YT) {
      // If not, load the script asynchronously
      var tag = document.createElement('script')
      tag.src = 'https://www.youtube.com/iframe_api'
      var firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

      window.onYouTubeIframeAPIReady = this.loadYoutubeVideo
    } else {
      // If script is already there, load the video directly
      this.loadYoutubeVideo()
    }

    window.addEventListener('resize', this.updateDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  // UI event methods
  handleMuteBtnClick = () => {
    if (!this.state.videoMute) {
      this.youtubePlayer.mute()
    } else {
      this.youtubePlayer.unMute()
    }

    this.setState({ videoMute: !this.state.videoMute })
  }

  handleVideoPlayBtnClick = () => {
    if (!this.state.videoPlaying) {
      this.youtubePlayer.playVideo()
    } else {
      //this.youtubePlayer.pauseVideo()
    }

    //this.setState({ videoPlaying: !this.state.videoPlaying })
  }

  // Helper methods
  loadYoutubeVideo = () => {
    if (window.YT) {
      this.youtubePlayer = new window.YT.Player('videoPlayer', {
        height: getVideoHeight(this.state.windowWidth),
        width: this.state.windowWidth,
        videoId: youtubeVideoId,
        playerVars: {
          autoplay: 1,
          loop: 1,
          controls: 0,
          modestbranding: 1,
          mute: 0,
          muted: 0,
          rel: 0,
          playsinline: 1,
          enablejsapi: 1,
          //origin: 'https://www.fitthybody.com',
          playlist: youtubeVideoId,
        },
        events: {
          onReady: e => {
            //if (window.cordova) {
            e.target.mute()
            e.target.playVideo()
            //}
            //this.setState({ videoReadyToPlay: true })
          },
        },
      })
    }
  }

  updateDimensions = () => {
    const windowWidth = getVideoWidth()
    this.youtubePlayer.setSize(windowWidth, getVideoHeight(windowWidth))
    this.setState({ windowWidth: getVideoWidth() })
  }

  // Render methods
  render() {
    const { showPromotionItems, windowWidth, videoMute, videoReadyToPlay } = this.state
    const { openSnackbar } = this.props
    const height = getVideoHeight(windowWidth)
    const videoOverlayTitleContent = (
      <>
        <VideoOverlayTitleMain>{getString('90DaysChallengeTitle')}</VideoOverlayTitleMain>
        <VideoOverlayTitle>{getString('ChallengeCarouselItemVideoOverlayText')}</VideoOverlayTitle>
        <Link to="/registerToChallenge">
          <StyledButton variant="contained" color="secondary">
            {getString('ChallengeCarouselItemVideoOverlayButton')}
          </StyledButton>
        </Link>
      </>
    )
    return (
      <ChallengeCarouselItemWrapper>
        <MainVideoWrapper width={windowWidth} height={height}>
          <div id="videoPlayer"></div>
          <MainVideoWrapperOverlay onClick={this.handleVideoPlayBtnClick} />
          {/*!videoReadyToPlay && <MainVideoImageThumbnailOverlay />*/}
          <MuteIconWrapper>
            {videoMute ? <VolumeOffIcon onClick={this.handleMuteBtnClick} /> : <VolumeUpIcon onClick={this.handleMuteBtnClick} />}
          </MuteIconWrapper>
          <VideoOverlayTitleWrapper>{videoOverlayTitleContent}</VideoOverlayTitleWrapper>
        </MainVideoWrapper>
        <ContentBox>
          {!window.cordova && <MobileVideoOverlayTitleWrapper>{videoOverlayTitleContent}</MobileVideoOverlayTitleWrapper>}
          {/*window.cordova && <MobileVideoOverlayTitleSeparator />*/}
          {!window.cordova && (
            <ContentBoxInner>
              <PromotionTitleFirst>{getString('ChallengeCarouselItemTopTitle1')}</PromotionTitleFirst>
              <TopContentText>
                <TopContentTextRightSide>
                  {multilineHtmlText(getString('ChallengeCarouselItemTopTextRight'))}
                  <ul>
                    <PromotionItem>
                      <ItemTitle>
                        <StyledDoneOutlineIcon />
                        {getString('ChallengeCarouselItemTopTextRightItem1')}
                      </ItemTitle>
                    </PromotionItem>
                    <PromotionItem>
                      <ItemTitle>
                        <StyledDoneOutlineIcon />
                        {getString('ChallengeCarouselItemTopTextRightItem2')}
                      </ItemTitle>
                    </PromotionItem>
                    <PromotionItem>
                      <ItemTitle>
                        <StyledDoneOutlineIcon />
                        {getString('ChallengeCarouselItemTopTextRightItem3')}
                      </ItemTitle>
                    </PromotionItem>
                  </ul>
                </TopContentTextRightSide>
                <TopContentTextLeftSide>
                  <SmartphoneScreenImages>
                    <SmartphoneScreenImage index="1" />
                    <SmartphoneScreenImage index="2" />
                    <SmartphoneScreenImage index="3" />
                  </SmartphoneScreenImages>
                </TopContentTextLeftSide>
              </TopContentText>
              <ButtonsWrapper>
                <Link to="/registerToChallenge">
                  <StyledButton variant="contained" color="secondary">
                    {getString('ChallengeCarouselItemTopButton')}
                  </StyledButton>
                </Link>
              </ButtonsWrapper>
            </ContentBoxInner>
          )}
        </ContentBox>
        {!window.cordova && (
          <>
            <MotivationCarouselItem isActive />
            <FeedbackCarouselItem />
            <ContactCarouselItem openSnackbar={openSnackbar} />
            <SecondaryContentBox>
              {!window.cordova && (
                <SecondaryContentBoxInner>
                  <PromotionTitleFirst>{multilineHtmlText(getString('ChallengeCarouselItemTopTextLeft'))}</PromotionTitleFirst>
                  <SecondaryContentBoxItemsWrapper>
                    <SecondaryContentBoxRightImage />
                    <SecondaryContentBoxMiddle>
                      <TopContentText>
                        <TopContentTextRightSide>
                          <ul>
                            <PromotionItem>
                              <ItemTitle>
                                <StyledDoneOutlineIcon />
                                {getString('ChallengeCarouselItemTopTextLeftItem1')}
                              </ItemTitle>
                            </PromotionItem>
                            <PromotionItem>
                              <ItemTitle>
                                <StyledDoneOutlineIcon />
                                {getString('ChallengeCarouselItemTopTextLeftItem2')}
                              </ItemTitle>
                            </PromotionItem>
                            <PromotionItem>
                              <ItemTitle>
                                <StyledDoneOutlineIcon />
                                {getString('ChallengeCarouselItemTopTextLeftItem3')}
                              </ItemTitle>
                            </PromotionItem>
                          </ul>
                          {multilineHtmlText(getString('ChallengeCarouselItemTopTextLeftBottom'))}
                        </TopContentTextRightSide>
                      </TopContentText>
                    </SecondaryContentBoxMiddle>
                    <SecondaryContentBoxLeftImage />
                  </SecondaryContentBoxItemsWrapper>
                </SecondaryContentBoxInner>
              )}
            </SecondaryContentBox>
            <ChallengeCountdownCarouselItem />
            <MotivationCarouselItemWrapper id="MotivationCarouselItemWrapper">
              <MotivationContentBoxInnerWrapper>
                <MotivationContentBoxInner>
                  <PromotionTitle>{getString('MotivationCarouselItemTopTitle')}</PromotionTitle>
                  <PromotionItemsList>
                    <PromotionItem index={0} isActive={showPromotionItems}>
                      <ItemTitle>
                        <StyledDoneOutlineIcon />
                        {getString('MotivationCarouselItemListItem1')}
                      </ItemTitle>
                    </PromotionItem>
                    <PromotionItem index={1} isActive={showPromotionItems}>
                      <ItemTitle>
                        <StyledDoneOutlineIcon />
                        {getString('MotivationCarouselItemListItem2')}
                      </ItemTitle>
                    </PromotionItem>
                    <PromotionItem index={2} isActive={showPromotionItems}>
                      <ItemTitle>
                        <StyledDoneOutlineIcon />
                        {getString('MotivationCarouselItemListItem3')}
                      </ItemTitle>
                    </PromotionItem>
                    <PromotionItem index={3} isActive={showPromotionItems}>
                      <ItemTitle>
                        <StyledDoneOutlineIcon />
                        {getString('MotivationCarouselItemListItem4')}
                      </ItemTitle>
                    </PromotionItem>
                    <PromotionItem hideInMobile index={4} isActive={showPromotionItems}>
                      <ItemTitle>
                        <StyledDoneOutlineIcon />
                        {getString('MotivationCarouselItemListItem5')}
                      </ItemTitle>
                    </PromotionItem>
                    <PromotionItem hideInMobile index={5} isActive={showPromotionItems}>
                      <ItemTitle>
                        <StyledDoneOutlineIcon />
                        {getString('MotivationCarouselItemListItem6')}
                      </ItemTitle>
                    </PromotionItem>
                  </PromotionItemsList>
                </MotivationContentBoxInner>
                <PhoneOnTableImage />
              </MotivationContentBoxInnerWrapper>
            </MotivationCarouselItemWrapper>
            <BottomContentBox>
              <BottomContentBoxInner>
                <TitlesSection>
                  <PromotionTitle>{multilineHtmlText(getString('ChallengeCarouselItemTitle1'))}</PromotionTitle>
                  <PromotionSubTitle>{getString('ChallengeCarouselItemSubTitle1')}</PromotionSubTitle>
                  <PromotionSubTitleSecond>{getString('ChallengeCarouselItemSubTitle2')}</PromotionSubTitleSecond>
                  <PromotionItemsList>
                    <PromotionItem index={0} isActive={showPromotionItems}>
                      <ItemTitle isHover>
                        <StyledFitnessIcon />
                        {getString('ChallengeCarouselItemListItem1')}
                        <StyledDownArrowIcon />
                        <ItemDesc>{getString('ChallengeCarouselItemListItem1Desc')}</ItemDesc>
                      </ItemTitle>
                    </PromotionItem>
                    <PromotionItem index={1} isActive={showPromotionItems}>
                      <ItemTitle isHover>
                        <StyledFoodIcon />
                        {getString('ChallengeCarouselItemListItem2')}
                        <StyledDownArrowIcon />
                        <ItemDesc>{getString('ChallengeCarouselItemListItem2Desc')}</ItemDesc>
                      </ItemTitle>
                    </PromotionItem>
                    <PromotionItem index={2} isActive={showPromotionItems}>
                      <ItemTitle isHover>
                        <StyledStandingIcon />
                        {getString('ChallengeCarouselItemListItem3')}
                        <StyledDownArrowIcon />
                        <ItemDesc>{getString('ChallengeCarouselItemListItem3Desc')}</ItemDesc>
                      </ItemTitle>
                    </PromotionItem>
                    <PromotionItem index={3} isActive={showPromotionItems}>
                      <ItemTitle isHover>
                        <StyledChatIcon />
                        {getString('ChallengeCarouselItemListItem4')}
                        <StyledDownArrowIcon />
                        <ItemDesc>{getString('ChallengeCarouselItemListItem4Desc')}</ItemDesc>
                      </ItemTitle>
                    </PromotionItem>
                  </PromotionItemsList>
                </TitlesSection>
                <PhotoPlaceholder />
              </BottomContentBoxInner>
            </BottomContentBox>
            <DietCarouselItemWrapper>
              <MotivationContentBoxInnerWrapper>
                <MotivationContentBoxInner>
                  <PromotionTitle>{getString('DietCarouselItemTopTitle')}</PromotionTitle>
                  <PromotionSubTitle>{getString('DietCarouselItemSubTitle1')}</PromotionSubTitle>
                  <PromotionItemsList>
                    <PromotionItem index={0} isActive={showPromotionItems}>
                      <ItemTitle>
                        <StyledDoneOutlineIcon />
                        {getString('DietCarouselItemListItem1')}
                      </ItemTitle>
                    </PromotionItem>
                    <PromotionItem index={1} isActive={showPromotionItems}>
                      <ItemTitle>
                        <StyledDoneOutlineIcon />
                        {getString('DietCarouselItemListItem2')}
                      </ItemTitle>
                    </PromotionItem>
                    <PromotionItem index={2} isActive={showPromotionItems}>
                      <ItemTitle>
                        <StyledDoneOutlineIcon />
                        {getString('DietCarouselItemListItem3')}
                      </ItemTitle>
                    </PromotionItem>
                    <PromotionItem index={3} isActive={showPromotionItems}>
                      <ItemTitle>
                        <StyledDoneOutlineIcon />
                        {getString('DietCarouselItemListItem4')}
                      </ItemTitle>
                    </PromotionItem>
                  </PromotionItemsList>
                </MotivationContentBoxInner>
                <HealthyFoodBgImage />
              </MotivationContentBoxInnerWrapper>
            </DietCarouselItemWrapper>
            <AboutUsCarouselItem />
          </>
        )}
        {/*<ChallengeRegisterBox />*/}
      </ChallengeCarouselItemWrapper>
    )
  }
}

export default ChallengeCarouselItem
