import { connect } from 'react-redux'
import AppRouter from '../components/AppRouter'
import { setPresetStrings } from '../bundles/presetStrings'

export default connect(
  state => ({}),
  {
    setPresetStrings,
  },
)(AppRouter)
