import React, { Component } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import formBackgroundLeft from '../../images/formBackground_left.jpg'
import appleIconImg from '../../images/appleIcon.png'
import { mediaQuery, InApp_ProductId } from '../../services/global'
import { invokeAPIcall } from '../../services/rest'
import { getString } from '../../services/presetStringsHelper'
import { calcMaxNumOfMonthlyInstallmentsToAllow } from '../../services/pricesHelper'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import {
  MonthlyInstallmentsSelector,
  PaymentOverlayButton,
  PayPalButtonWrapper,
  CreditCardButtonWrapper,
  OrderTotalDetails,
  OrderTotalDetailsItem,
  OrderTotalDetailsItemValue,
  OrderTotalDetailsItemLabel,
  PelecardIframeWrapper,
  PelecardLoadingIndicator,
} from '../ShoppingCartScreen'
import StyledSelect from '../StyledSelect'
import StyledTextField from '../StyledTextField'
import ChallengePriceStar from '../ChallengePriceStar'
import Breadcrumbs from '../Breadcrumbs'
import PackageItemList from './PackageItemList'

// Consts

// Style
const PageWrapper = styled.div`
  background-color: #f5fcfd;
  background: url(${formBackgroundLeft}) left bottom no-repeat, #f7fdfd;
  background-size: 55% auto;
  padding: 20px;
  flex: 1;
  ${mediaQuery.phone`
    background: none;
  `}
`
const PackageSelectionWrapper = styled.div`
  margin: 5px auto 0px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${props => (props.show === false ? 'display: none;' : '')}
`
const PackageSelectionTitle = styled.h1`
  ${mediaQuery.phone`
    font-size: 26px;
  `}
`
const ButtonsIframeWrapper = styled.div`
  width: 620px;
  margin: 5px auto 0px;
  ${props =>
    props.showPelecardBox
      ? `
      display: flex;
      align-items: flex-start;
      width: 90%;
  `
      : ''}
  ${mediaQuery.tablet`
    flex-direction: column;
    width: 100%;
  `}
  ${props => (props.show === false ? 'display: none;' : '')}
`
const RightSideWrapper = styled.div`
  flex: 7;
  ${mediaQuery.tablet`
    width: 100%;
  `}
`
const ButtonsBox = styled.div`
  flex: 3;
  padding: 30px;
  margin-right: 30px;
  background-color: ${colorPalette.white.main};
  color: ${colorPalette.primary1.main};
  ${globalStyles.boxShadow}
  ${globalStyles.boxRadius}
  ${mediaQuery.tablet`
    width: 100%;
    margin-right: 0;
    margin-top: 20px;
  `}
`
const Title = styled.h1`
  margin-bottom: 5px;
  font-size: 36px;
  text-align: center;
`
const SubTitle = styled.h4`
  font-size: 16px;
  text-align: center;
`
const CouponFieldCell = styled.div`
  flex: 4;
`
const CouponRow = styled.div`
  display: flex;
  align-items: center;
  display: ${props => (props.hidden ? 'none' : 'block')};
`
const ApplyCouponButton = styled(Button)`
  && {
    font-size: 13px;
    line-height: 1;
    flex: 1;
    margin-right: 5px;
  }
`
const InAppPurchaseButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const AppleIcon = styled.div`
  width: 30px;
  height: 30px;
  background-image: url('${appleIconImg}');
  background-size: contain;
  background-repeat: no-repeat;
`
const CreditCardExplainMsg = styled.div`
  margin-top: 10px;
  font-size: 18px;
  color: ${colorPalette.custom.warning};
`
const CreditCardExplainMsgBox = styled.div`
  position: relative;
  margin-top: 20px;
  padding: 10px;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  background-color: ${colorPalette.white.main};
  color: ${colorPalette.custom.warning};
  ${globalStyles.boxShadow}
  ${globalStyles.boxRadius}
`

// Component
class ChallengePaymentScreen extends Component {
  state = {
    selectedPackage: null,
    globalSettingsData: {},
    monthlyInstallmentsLevels: [],
    monthlyInstallmentsValue: 1,
    pelecardPaymentIframeUrl: null,
    couponVal: '',
    appliedCoupon: null,
  }

  // Lifecycle methods
  componentDidMount() {
    this.loadGlobalSettingsData()
    this.loadMonthlyInstallmentsLevels()

    // load 'couponCode' from the sessionStorage (if there is one)
    const couponCode = sessionStorage.getItem('couponCode')
    if (couponCode) {
      this.loadCouponData(couponCode, false)
    }

    this.loadSelectedPackage(this.props)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.packageNum !== nextProps.match.params.packageNum) {
      this.loadSelectedPackage(nextProps)
    }
  }

  // Helper methods
  loadMonthlyInstallmentsLevels = async id => {
    const monthlyInstallmentsLevels = await invokeAPIcall({ uri: 'api/monthlyInstallmentsLevels' })
    this.setState({
      monthlyInstallmentsLevels,
    })
  }

  loadGlobalSettingsData = async id => {
    let globalSettingsData = await invokeAPIcall({ uri: `api/globalSettings/1` })
    this.setState({
      globalSettingsData,
    })
  }

  loadCouponData = async (couponCode, showMessage) => {
    const { openSnackbar } = this.props

    let couponData = await invokeAPIcall({ uri: `api/coupons/getByCouponCode?code=${couponCode}` })
    this.setState({ appliedCoupon: couponData, monthlyInstallmentsValue: 1 /* also reset the 'monthlyInstallmentsValue' */ })

    if (showMessage) {
      if (couponData !== null) {
        const { SpecificProductID } = couponData
        if (SpecificProductID !== null) {
          openSnackbar({ message: 'קוד קופון תקין אך לא רלוונטי למוצר זה', severity: 'failure' })
        } else {
          openSnackbar({ message: 'קוד קופון תקין. ההנחה בוצעה!', severity: 'success' })
        }
      } else {
        openSnackbar({ message: 'קוד קופון לא קיים', severity: 'failure' })
      }
    }
  }

  calcChallengePrice = () => {
    const { appliedCoupon } = this.state
    let challengePrice = this.getSelectedPackagePrice()

    // apply coupon
    if (appliedCoupon) {
      const { FixedDiscount, PercentageDiscount, SpecificProductID } = appliedCoupon

      // for specific product
      if (SpecificProductID !== null) {
      }
      // global discount
      else {
        if (FixedDiscount) {
          challengePrice -= FixedDiscount
        } else if (PercentageDiscount) {
          challengePrice = Math.floor(challengePrice * ((100 - PercentageDiscount) / 100))
        }
      }

      // total can't be below 0
      if (challengePrice < 0) {
        challengePrice = 0
      }
    }

    return challengePrice
  }

  loadSelectedPackage = props => {
    const { match, history } = props
    const { params } = match
    this.setState({ selectedPackage: params.packageNum, pelecardPaymentIframeUrl: null })

    // for ios cordova - don't allow package selection - just pick one by default
    const isCordovaIos = window.cordova && window.device && window.device.platform.toLowerCase() === 'ios'
    if (isCordovaIos && !params.packageNum) {
      history.push(`/challengePayment/1`)
    }
  }

  getSelectedPackagePrice = () => {
    const { globalSettingsData, selectedPackage } = this.state
    if (selectedPackage == 1) {
      return globalSettingsData ? globalSettingsData.ChallengePricePack1 : 0
    } else if (selectedPackage == 2) {
      return globalSettingsData ? globalSettingsData.ChallengePricePack2 : 0
    } else if (selectedPackage == 3) {
      return globalSettingsData ? globalSettingsData.ChallengePricePack3 : 0
    }
    return globalSettingsData ? globalSettingsData.ChallengePricePack1 : 0
  }

  getSelectedPackageName = () => {
    const { selectedPackage } = this.state
    if (selectedPackage == 1) {
      return 'Basic'
    } else if (selectedPackage == 2) {
      return 'Premium'
    } else if (selectedPackage == 3) {
      return 'VIP'
    }
    return '-'
  }

  // UI event methods
  handleMonthlyInstallmentsChange = e => {
    this.setState({ monthlyInstallmentsValue: e.target.value })
  }

  handleCreditCardPaymentClick = async () => {
    const { monthlyInstallmentsValue, appliedCoupon } = this.state
    const challengePrice = this.calcChallengePrice()
    const couponCode = appliedCoupon && appliedCoupon.CouponCode ? appliedCoupon.CouponCode : ''

    const pelecardPaymentData = await invokeAPIcall({
      uri: `api/pelecardPayment/getChallengePaymentUrl?orderTotal=${challengePrice}&monthlyInstallmentsNum=${monthlyInstallmentsValue}&couponCode=${couponCode}`,
    })
    this.setState({ pelecardPaymentIframeUrl: pelecardPaymentData.URL })
  }

  handleCouponChange = e => {
    this.setState({ couponVal: e.target.value })
  }

  handleApplyCouponClick = couponCode => {
    this.loadCouponData(couponCode, true)
  }

  handleCordovaInAppPurchaseClick = () => {
    if (!window.store) {
      return
    }

    // invoke a purchase in the In-App-Purchase
    window.store.order(InApp_ProductId)

    const { showFullScreenLoading } = this.props
    window.setTimeout(() => {
      showFullScreenLoading()
    }, 800)
  }

  handleCordovaInAppPurchaseRestoreClick = () => {
    if (!window.store) {
      return
    }

    const { showFullScreenLoading, hideFullScreenLoading } = this.props

    // restore the purchase in the In-App-Purchase
    showFullScreenLoading()
    window.store.refresh().finished(() => {
      hideFullScreenLoading()
    })
  }

  handlePackageItemClick = packageNum => () => {
    const { history } = this.props
    history.push(`/challengePayment/${packageNum}`)
  }

  // Render methods
  render() {
    const {
      couponVal,
      monthlyInstallmentsValue,
      monthlyInstallmentsLevels,
      pelecardPaymentIframeUrl,
      appliedCoupon,
      selectedPackage,
      globalSettingsData,
    } = this.state
    const { currentUser } = this.props
    let challengeFullPrice = this.getSelectedPackagePrice()
    let challengePrice = this.calcChallengePrice()
    let currency = '₪'
    const isCordovaIos = window.cordova && window.device && window.device.platform.toLowerCase() === 'ios'

    // In-App-Purchase - get price from the Apple/Google remote store data
    if (isCordovaIos) {
      var inAppPurchaseData = null
      var inAppPurchaseDataString = localStorage.getItem('challenge90Days_InAppPurchaseData')
      if (inAppPurchaseDataString != null) {
        inAppPurchaseData = JSON.parse(inAppPurchaseDataString)
        challengeFullPrice = inAppPurchaseData.priceMicros / 1000000
        challengePrice = challengeFullPrice
        currency = inAppPurchaseData.currency
      }
    }

    const couponCalculatedDiscount = challengeFullPrice - challengePrice
    const monthlyInstallmentsNum = Number(monthlyInstallmentsValue)
    const payInMonthlyInstallments = monthlyInstallmentsNum > 1
    const maxNumOfMonthlyInstallmentsToAllow = calcMaxNumOfMonthlyInstallmentsToAllow(challengePrice, monthlyInstallmentsLevels)
    const monthlyInstallmentsToAllow = []
    for (var i = 1; i <= maxNumOfMonthlyInstallmentsToAllow; i++) {
      monthlyInstallmentsToAllow.push(i)
    }
    const couponCode = appliedCoupon && appliedCoupon.CouponCode ? appliedCoupon.CouponCode : ''

    return (
      <PageWrapper>
        <Breadcrumbs
          previousItems={[
            {
              label: 'חוויית החיטוב ב-90 יום',
              url: `/program`,
            },
            ...(selectedPackage != null
              ? [
                  {
                    label: 'בחירת חבילה',
                    url: `/challengePayment`,
                  },
                ]
              : []),
          ]}
          currentItem={selectedPackage == null ? `בחירת חבילה` : `רכישת חבילת ${this.getSelectedPackageName(selectedPackage)}`}
        />
        <PackageSelectionWrapper show={selectedPackage == null}>
          <PackageSelectionTitle>בחירת החבילה שלך</PackageSelectionTitle>
          <PackageItemList globalSettingsData={globalSettingsData} />
        </PackageSelectionWrapper>
        <ButtonsIframeWrapper show={selectedPackage != null} showPelecardBox={pelecardPaymentIframeUrl !== null}>
          {pelecardPaymentIframeUrl !== null && (
            <RightSideWrapper>
              <PelecardIframeWrapper>
                <PelecardLoadingIndicator>
                  <CircularProgress />
                </PelecardLoadingIndicator>
                <iframe width="960" height="70" src={pelecardPaymentIframeUrl} frameBorder="0" allowFullScreen allowTransparency></iframe>
              </PelecardIframeWrapper>
              <CreditCardExplainMsgBox>{getString('ChallengeCarouselCreditCardExplainMsgBox')}</CreditCardExplainMsgBox>
            </RightSideWrapper>
          )}
          <ButtonsBox>
            <Title>רכישת ״חוויית החיטוב ב-90 יום״</Title>
            <SubTitle>
              {getString('ChallengeCarouselItemSubTitle1')}
              <br />
              {`(חבילת ${this.getSelectedPackageName(selectedPackage)})`}
              <br />
              <CreditCardExplainMsg>{getString('ChallengeCarouselCreditCardExplainMsg')}</CreditCardExplainMsg>
              <br />
              <ChallengePriceStar challengeFullPrice={challengeFullPrice} currency={currency} />
            </SubTitle>
            <OrderTotalDetails>
              {couponCalculatedDiscount > 0 && (
                <>
                  <OrderTotalDetails>
                    <OrderTotalDetailsItem>
                      <OrderTotalDetailsItemLabel>סכום רכישות:</OrderTotalDetailsItemLabel>
                      <OrderTotalDetailsItemValue>
                        {currency}
                        {challengeFullPrice}
                      </OrderTotalDetailsItemValue>
                    </OrderTotalDetailsItem>
                  </OrderTotalDetails>
                  <OrderTotalDetails>
                    <OrderTotalDetailsItem>
                      <OrderTotalDetailsItemLabel>הנחת קופון:</OrderTotalDetailsItemLabel>
                      <OrderTotalDetailsItemValue>
                        {currency}
                        {couponCalculatedDiscount}-
                      </OrderTotalDetailsItemValue>
                    </OrderTotalDetailsItem>
                  </OrderTotalDetails>
                </>
              )}
              <OrderTotalDetailsItem>
                <OrderTotalDetailsItemLabel>סך כל ההזמנה:</OrderTotalDetailsItemLabel>
                <OrderTotalDetailsItemValue bold>
                  {currency}
                  {challengePrice}
                </OrderTotalDetailsItemValue>
              </OrderTotalDetailsItem>
            </OrderTotalDetails>
            {pelecardPaymentIframeUrl == null && !isCordovaIos && (
              <>
                {maxNumOfMonthlyInstallmentsToAllow > 1 && (
                  <MonthlyInstallmentsSelector>
                    כמות תשלומים:
                    <StyledSelect
                      selectParams={{
                        name: `monthlyInstallments`,
                        value: monthlyInstallmentsValue,
                        onChange: this.handleMonthlyInstallmentsChange,
                        margin: 'normal',
                        inputProps: {
                          name: `monthlyInstallments`,
                          id: `monthlyInstallments-simple`,
                        },
                      }}
                      htmlFor={`monthlyInstallments-simple`}
                      variant="outlined"
                      fullWidth={false}
                    >
                      {monthlyInstallmentsToAllow.map(item => {
                        if (item === 1) {
                          return <MenuItem value="1">תשלום אחד</MenuItem>
                        }
                        return <MenuItem value={item}>{item} תשלומים</MenuItem>
                      })}
                    </StyledSelect>
                  </MonthlyInstallmentsSelector>
                )}
                <CreditCardButtonWrapper>
                  <PaymentOverlayButton
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    onClick={this.handleCreditCardPaymentClick}
                  >
                    תשלום בכרטיס אשראי
                  </PaymentOverlayButton>
                </CreditCardButtonWrapper>
                <PayPalButtonWrapper style={{ display: 'none' }}>
                  <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                    <input type="hidden" name="cmd" value={payInMonthlyInstallments ? '_xclick-subscriptions' : '_xclick'} />
                    <input type="hidden" name="business" value="fitthybody@gmail.com" />
                    <input type="hidden" name="lc" value="IL" />
                    <input type="hidden" name="item_name" value={`FitThyBody Store (90-days challenge)`} />
                    <input type="hidden" name="item_number" value={`ChallengeUser_${currentUser.UserID}|${couponCode}`} />
                    <input type="hidden" name="amount" value={challengePrice} />
                    <input type="hidden" name="currency_code" value="ILS" />
                    <input type="hidden" name="button_subtype" value="services" />
                    <input type="hidden" name="no_note" value="1" />
                    <input type="hidden" name="shipping" value="0.00" />
                    {payInMonthlyInstallments && (
                      // a3=Regular subscription price (amount of money every month)
                      <input type="hidden" name="a3" value={challengePrice / monthlyInstallmentsNum} />
                    )}
                    {payInMonthlyInstallments && (
                      // p3=Subscription duration (X in months, days or weeks)
                      <input type="hidden" name="p3" value="1" />
                    )}
                    {payInMonthlyInstallments && (
                      // t3=Regular subscription units of duration (M=month)
                      <input type="hidden" name="t3" value="M" />
                    )}
                    {payInMonthlyInstallments && (
                      // srt=Recurring times. Number of times that subscription payments recur
                      <input type="hidden" name="srt" value={monthlyInstallmentsNum} />
                    )}
                    {payInMonthlyInstallments && (
                      // sra=Reattempt on failure (1=reattemp)
                      <input type="hidden" name="sra" value="1" />
                    )}
                    {payInMonthlyInstallments && (
                      // src=Recurring payments. Subscription payments recur unless subscribers cancel (1=recur)
                      <input type="hidden" name="src" value="1" />
                    )}
                    <input TYPE="hidden" NAME="return" value={`https://www.fitthybody.com/fitthybodyAPI/api/paypalPayment`} />
                    <input TYPE="hidden" NAME="notify_url" value={`https://www.fitthybody.com/fitthybodyAPI/api/paypalPayment`} />
                    <input TYPE="hidden" NAME="rm" value="2" />
                    <input type="hidden" name="bn" value="PP-BuyNowBF:btn_buynowCC_LG.gif:NonHostedGuest" />
                    <input
                      type="image"
                      src="https://www.paypalobjects.com/he_IL/IL/i/btn/btn_buynowCC_LG.gif"
                      border="0"
                      name="submit"
                      alt="PayPal - הדרך הקלה והבטוחה יותר לשלם באינטרנט!"
                    />
                    <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                  </form>
                  <PaymentOverlayButton variant="contained" color="primary" fullWidth size="large">
                    תשלום דרך PayPal
                  </PaymentOverlayButton>
                </PayPalButtonWrapper>
              </>
            )}
            {isCordovaIos && (
              <InAppPurchaseButtons>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                  onClick={this.handleCordovaInAppPurchaseClick}
                  startIcon={<AppleIcon />}
                >
                  תשלום דרך האפליקציה
                </Button>
                <Button variant="text" color="primary" onClick={this.handleCordovaInAppPurchaseRestoreClick}>
                  שחזור רכישה (אם כבר רכשת בעבר)
                </Button>
              </InAppPurchaseButtons>
            )}
            <CouponRow hidden={pelecardPaymentIframeUrl != null || isCordovaIos}>
              <CouponFieldCell>
                <StyledTextField
                  label={'קוד קופון'}
                  name="coupon"
                  value={couponVal}
                  onChange={this.handleCouponChange}
                  margin="normal"
                  fullWidth
                />
                {/*helperText="יש לך קופון הנחה? הקלד כאן"*/}
              </CouponFieldCell>
              <ApplyCouponButton
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                onClick={this.handleApplyCouponClick.bind(this, couponVal)}
              >
                שימוש בקופון
              </ApplyCouponButton>
            </CouponRow>
          </ButtonsBox>
        </ButtonsIframeWrapper>
      </PageWrapper>
    )
  }
}

export default withRouter(ChallengePaymentScreen)
