import React from 'react'
import styled from 'styled-components'
import { Link, withRouter } from 'react-router-dom'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import YouTubeIcon from '@material-ui/icons/YouTube'
import sslLogoImage from '../images/sslLogo.png'
import { mediaQuery } from '../services/global'
import { colorPalette, globalStyles } from '../styles/styleVariables'

// Style
const BottomBarWrapper = styled.div``
const UpperSeparator = styled.div`
  background: ${colorPalette.white.main};
  border-bottom-left-radius: ${globalStyles.mainBoxRadius};
  border-bottom-right-radius: ${globalStyles.mainBoxRadius};
  box-shadow: ${globalStyles.mainBoxShadow};
  height: 30px;
  position: relative;
  top: 28px;
`
const MenuOptions = styled.ul`
  display: flex;
  align-items: center;
  height: 84px;
  padding: 0 40px;
  padding-top: 35px;
  background-color: ${colorPalette.primary1.main};
  color: #fff;
  a {
    color: ${colorPalette.white.main};
    margin: 0 20px;
  }
  ${mediaQuery.phone`
    padding: 0 10px;
    padding-top: 35px;
    height: auto;
    min-height: 84px;
    padding-bottom: env(safe-area-inset-bottom);
    a {
      margin: 0 6px;
    }
  `}
`
const MenuOption = styled.li`
  font-size: 15px;
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    color: ${colorPalette.primary1.main};
  }
  a {
    color: ${colorPalette.white.main};
    &:hover {
      color: ${colorPalette.tertiary1.main};
    }
  }
`
const FacebookMenuOption = styled(MenuOption)`
  margin-right: auto;
`
const HttpsLogo = styled.div`
  background-image: url('${sslLogoImage}');
  width: 130px;
  height: 32px;
  background-position: center;
  background-size: cover;
  margin-right: 20px;
  ${mediaQuery.phone`
    display: none;
  `}
`

function BottomBar({ location }) {
  if (window.cordova && location.pathname === '/' && (location.hash === '' || location.hash === '#/')) {
    // don't render this component for cordova homepage
    return null
  }

  return (
    <BottomBarWrapper id="BottomBar">
      <UpperSeparator />
      <MenuOptions>
        <MenuOption>
          <Link to="/contentPage/termsOfService">תקנון</Link>
        </MenuOption>
        <MenuOption>
          <Link to="/contentPage/aboutUs">אודות</Link>
        </MenuOption>
        <MenuOption>
          <Link to="/contactUs">כתבו לנו</Link>
        </MenuOption>
        <HttpsLogo />
        <FacebookMenuOption>
          <a
            href="#"
            onClick={() => {
              window.open('https://www.facebook.com/crosstraining4you', '_blank')
            }}
          >
            <FacebookIcon />
          </a>
        </FacebookMenuOption>
        <MenuOption>
          <a
            href="#"
            onClick={() => {
              window.open('https://www.instagram.com/eladaiash', '_blank')
            }}
          >
            <InstagramIcon />
          </a>
        </MenuOption>
        <MenuOption>
          <a
            href="#"
            onClick={() => {
              window.open('https://www.youtube.com/channel/UC-kLGUQxGypdHhgr9-MJ8rw', '_blank')
            }}
          >
            <YouTubeIcon />
          </a>
        </MenuOption>
      </MenuOptions>
    </BottomBarWrapper>
  )
}

export default withRouter(BottomBar)
