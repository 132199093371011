import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import LeftArrowIcon from '@material-ui/icons/KeyboardArrowLeft'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import { mediaQuery } from '../../services/global'
import { getString } from '../../services/presetStringsHelper'
import oshriProfile from '../../images/oshriProfile.JPG'
import eladProfile from '../../images/eladProfile.jpg'

// Style
const AboutUsCarouselItemWrapper = styled.div`
  color: ${colorPalette.black.main};
  background-color: ${colorPalette.white.main};
  border-bottom-left-radius: ${globalStyles.mainBoxRadius};
  border-bottom-right-radius: ${globalStyles.mainBoxRadius};
  box-shadow: ${globalStyles.mainBoxShadow};
  position: relative;
  top: -138px;
  z-index: 7;
  padding: 2px 0;
  ${mediaQuery.phone`
    height: auto;
  `}
`
const CarouselItemInner = styled.div`
  width: 100%;
  padding: 10px 20px;
  flex-direction: column;
  align-items: center;
  ${mediaQuery.phone`
    background-size: auto 100%;
    background-position: center;
    height: auto;
    padding-top: 20px;
  `}
`
const ContentBox = styled.div`
  font-size: 22px;
  ${mediaQuery.phone`
    position: static;
    text-align: center;
    width: auto;
    padding: 0 10px;
    flex-direction: column;
  `}
`
const PromotionTitle = styled.h2`
  font-size: 36px;
  font-weight: 500;
  margin: 20px 0;
  text-align: center;
  ${mediaQuery.tablet`
    font-size: 34px;
  `}
  ${mediaQuery.phone`
    font-size: 28px;
  `}
`
const BottomTitle = styled(PromotionTitle)`
  font-size: 24px;
  color: ${colorPalette.primary1.main};
  ${mediaQuery.tablet`
    font-size: 22px;
  `}
  ${mediaQuery.phone`
    font-size: 18px;
  `}
`
const AboutUsList = styled.ul`
  display: flex;
  max-width: 1240px;
  margin: 0 auto;
  ${mediaQuery.phone`
    max-width: none;
    flex-direction: column;
  `}
`
const AboutUsItem = styled.li`
  flex: 1;
  display: flex;
  margin: 0;
  &:first-child {
    margin-left: 70px;
  }
  ${mediaQuery.phone`
    margin: 10px 0;
    &:first-child {
      margin-left: 0;
    }
  `}
`
const AboutUsItemPhoto = styled.div`
  background-color: gray;
  ${props => (props.photoUrl ? `background-image: url(${props.photoUrl});` : '')}
  background-size: cover;
  background-position: center;
  width: 140px;
  height: 140px;
  flex: 0 0 140px;
  border-radius: 5px;
  border: 1px solid ${colorPalette.primary1.main};
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 70px;
    height: 70px;
    color: #fff;
  }
  ${mediaQuery.phone`
        margin-left: 10px;
  `}
`
const AboutUsItemContent = styled.div`
  font-size: 14px;
  text-align: right;
`
const AboutUsItemName = styled.div`
  font-size: 18px;
  font-weight: 500;
`
const BottomLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  color: ${colorPalette.primary1.main};
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    ${mediaQuery.phone`
      font-size: 16px;
    `}
  }
  svg {
    margin-right: 5px;
  }
`

// Component
class AboutUsCarouselItem extends Component {
  state = {}

  // Lifecycle methods
  componentDidMount() {}

  // Helper methods

  // Render methods
  render() {
    return (
      <>
        <AboutUsCarouselItemWrapper>
          <CarouselItemInner>
            <ContentBox>
              <PromotionTitle>{getString('AboutUsCarouselItemTitle')}</PromotionTitle>
              <AboutUsList>
                <AboutUsItem>
                  <AboutUsItemPhoto photoUrl={oshriProfile}></AboutUsItemPhoto>
                  <AboutUsItemContent>
                    <AboutUsItemName>{getString('AboutUsCarouselItemRightSideTitle')}</AboutUsItemName>
                    {getString('AboutUsCarouselItemRightSideText')}
                  </AboutUsItemContent>
                </AboutUsItem>
                <AboutUsItem>
                  <AboutUsItemPhoto photoUrl={eladProfile}></AboutUsItemPhoto>
                  <AboutUsItemContent>
                    <AboutUsItemName>{getString('AboutUsCarouselItemLeftSideTitle')}</AboutUsItemName>
                    {getString('AboutUsCarouselItemLeftSideText')}
                  </AboutUsItemContent>
                </AboutUsItem>
              </AboutUsList>
              <BottomTitle>{getString('AboutUsCarouselItemBottomLabel')}</BottomTitle>
              <BottomLinkWrapper>
                <Link to="/contentPage/aboutUs">
                  {getString('AboutUsCarouselItemBottomLink')}
                  <LeftArrowIcon />
                </Link>
              </BottomLinkWrapper>
            </ContentBox>
          </CarouselItemInner>
        </AboutUsCarouselItemWrapper>
      </>
    )
  }
}

export default AboutUsCarouselItem
