import { connect } from 'react-redux'
import InAppPurchaseStore from '../components/InAppPurchaseStore'
import { showFullScreenLoading, hideFullScreenLoading } from '../bundles/fullScreenLoading'
import { setCurrentUser } from '../bundles/currentUser'

export default connect(
  state => ({
    currentUser: state.currentUser.user,
    isLoading: state.fullScreenLoading.isLoading,
  }),
  {
    showFullScreenLoading,
    hideFullScreenLoading,
    setCurrentUser,
  },
)(InAppPurchaseStore)
