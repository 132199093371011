import { connect } from 'react-redux'
import StoreScreen from '../components/StoreScreen'
import { addProductToCart } from '../bundles/shoppingCart'

export default connect(
  state => ({}),
  {
    addProductToCart,
  },
)(StoreScreen)
