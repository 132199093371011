import moment from 'moment'

export const TRIAL_DAYS = 7

export function calcDaysSinceStartedChallenge(personalStatsList, currentUser) {
  let daysSinceStartedChallenge = 0

  if (personalStatsList && personalStatsList.length > 0) {
    const now = moment().startOf('day')
    const recentPersonalStatsItem = personalStatsList[personalStatsList.length - 1]
    const phaseStartingDate = moment(recentPersonalStatsItem.PhaseStartingDate).startOf('day')

    if (recentPersonalStatsItem.Phase === 'start') {
      daysSinceStartedChallenge = now.diff(phaseStartingDate, 'days')
      if (daysSinceStartedChallenge > 45) {
        daysSinceStartedChallenge = 45
      }
    } else if (recentPersonalStatsItem.Phase === 'afterPhase1') {
      daysSinceStartedChallenge = now.diff(phaseStartingDate, 'days') + 45
      if (daysSinceStartedChallenge > 75) {
        daysSinceStartedChallenge = 75
      }
    } else if (recentPersonalStatsItem.Phase === 'afterPhase2') {
      daysSinceStartedChallenge = now.diff(phaseStartingDate, 'days') + 75
      if (daysSinceStartedChallenge > 90) {
        daysSinceStartedChallenge = 90
      }
    } else if (recentPersonalStatsItem.Phase === 'afterPhase3') {
      daysSinceStartedChallenge = 90
    }
  }

  // if more than X days since the challenge started
  if (daysSinceStartedChallenge >= TRIAL_DAYS) {
    // and the user has not paid yet
    if (currentUser && currentUser.ChallengePaymentDateOfBilling == null) {
      daysSinceStartedChallenge = TRIAL_DAYS
    }
  }

  return daysSinceStartedChallenge
}

export function isTrialUserExpired(daysSinceStartedChallenge, currentUser) {
  // if more than X days since the challenge started
  if (daysSinceStartedChallenge >= TRIAL_DAYS) {
    // and the user has not paid yet
    if (currentUser.ChallengePaymentDateOfBilling == null) {
      return true
    }
  }
  return false
}

export function isTrialUser(currentUser) {
  // the user has not paid yet for the challenge
  if (currentUser.ChallengePaymentDateOfBilling == null) {
    return true
  }
  return false
}

export function isUserDidntEnterCreditCardInfo(currentUser) {
  // the user has not entered yet his creditCard info (via pelecard page) for the challenge
  if (currentUser.ChallengePaymentDate == null) {
    return true
  }
  return false
}
