import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { Link, withRouter } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import FitnessIcon from '@material-ui/icons/FitnessCenter'
import FoodIcon from '@material-ui/icons/Restaurant'
import CalendarIcon from '@material-ui/icons/InsertInvitation'
import ProgressIcon from '@material-ui/icons/ShowChart'
import TuneIcon from '@material-ui/icons/Tune'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import TrendingDownIcon from '@material-ui/icons/TrendingDown'
import EditIcon from '@material-ui/icons/Edit'
import PersonIcon from '@material-ui/icons/Person'
import CheckIcon from '@material-ui/icons/Check'
import formBackgroundLeft from '../../images/formBackground_left.jpg'
import crownIcon from '../../images/crownIcon.png'
import { mediaQuery, mobileCheck, capitalizeFirstLetter, photoUrlPrefix } from '../../services/global'
import { invokeAPIcall } from '../../services/rest'
import { getString } from '../../services/presetStringsHelper'
import { calcBodyFatColor, calcWeightColor, red, green } from '../../services/personalStatsHelper'
import { isTrialUser, calcDaysSinceStartedChallenge } from '../../services/challengeProgressHelper'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import Breadcrumbs from '../Breadcrumbs'
import WorkoutVideoDisplay from '../WorkoutVideoDisplay'
import { questionFields } from '../PersonalStatsScreen'
import PersonalStatsOfPhaseDialog from '../../containers/PersonalStatsOfPhaseDialogContainer'
import PersonalStatsPhotoDialog from './PersonalStatsPhotoDialog'

// Consts

// Style
const PageWrapper = styled.div`
  background-color: #f5fcfd;
  background: url(${formBackgroundLeft}) left bottom no-repeat, #f7fdfd;
  background-size: 55% auto;
  padding: 20px;
  padding-top: 0;
  flex: 1;
  ${mediaQuery.phone`
    background: none;
    padding: 20px;
  `}
`
const MainSectionWrapper = styled.div`
  margin: 0 50px;
  margin-top: 60px;
  ${mediaQuery.phone`
    margin: 0;
  `}
`
const MainSection = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
  ${mediaQuery.phone`
    flex-direction: column;
  `}
`
const ActionButtons = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 400px;
  margin-right: 40px;
  ${mediaQuery.phone`
    margin-right: 0;
    margin-top: 30px;
  `}
`
const StyledLink = styled(Link)`
  flex: 1;
`
const ActionButton = styled.li`
  flex: 1;
  background-color: ${props => (props.paymentButton ? colorPalette.primary2.main : colorPalette.primary1.main)};
  color: ${props => (props.paymentButton ? colorPalette.black.main : colorPalette.white.main)};
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  ${ActionButtons} &:hover {
    cursor: pointer;
    background-color: ${props => (props.paymentButton ? colorPalette.primary2.light : colorPalette.primary1.light)};
  }
`
const ActionButtonLabel = styled.div`
  color: ${props => (props.paymentButton ? colorPalette.black.main : colorPalette.white.main)};
  font-size: ${props => (props.paymentButton ? '28px' : '40px')};
  font-weight: 500;
  margin-right: 20px;
  ${mediaQuery.phone`
    font-size: ${props => (props.paymentButton ? '22px' : '30px')};
  `}
`
const iconCSS = css`
  && {
    width: 50px;
    height: 50px;
    color: ${colorPalette.white.main};
  }
`
const StyledFoodIcon = styled(FoodIcon)`
  ${iconCSS}
`
const StyledCalendarIcon = styled(CalendarIcon)`
  ${iconCSS}
`
const StyledProgressIcon = styled(ProgressIcon)`
  ${iconCSS}
`
const StyledTuneIcon = styled(TuneIcon)`
  ${iconCSS}
`
const StyledPersonIcon = styled(PersonIcon)`
  ${iconCSS}
`
const StyledFitnessIcon = styled(FitnessIcon)`
  ${iconCSS}
  transform: rotate(-45deg);
`
const StyledCrownIcon = styled.div`
  background-image: url(${crownIcon});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
`
const ExistingPersonalStatsTableWrapper = styled.div`
  min-width: 440px;
  ${mediaQuery.phone`
    min-width: 0;
    width: 100%;
  `}
`
const ExistingPersonalStatsTable = styled.div`
  color: ${colorPalette.white.main};
  background-color: ${colorPalette.primary1.main};
  padding: 20px;
  ${globalStyles.boxRadius};
  ${mediaQuery.phone`
    padding: 5px;
    padding-right: 8px;
  `}
`
const PersonalStatHeader = styled.ul`
  display: flex;
  align-items: center;
`
const PersonalStatItem = styled(PersonalStatHeader)``
const PersonalStatItemCell = styled.li`
  flex: ${props => (props.wider ? '4' : '3')};
  font-size: ${props => (props.bold ? '16px' : '14px')};
  font-weight: ${props => (props.bold ? '600' : '500')};
  padding: 4px 0;
  display: flex;
  justify-content: ${props => (props.wider ? 'flex-start' : 'center')};
  ${mediaQuery.phone`
    font-size: 12px;
  `}
`
const UserBodyPhoto = styled.div`
  height: 40px;
  width: 40px;
  background-image: url('${props => props.photoUrl}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: 2px solid #fff;
  border-radius: 8px;
`
const ChangeInStatsLabel = styled.div`
  direction: ltr;
  font-size: 16px;
  text-align: center;
  & > div {
    font-size: 11px;
    margin-top: 10px;
  }
`
const ChangeInStatsIconButton = styled(IconButton)`
  && {
    padding: 4px;
    svg {
      color: ${colorPalette.white.main};
      transform: rotate(-180deg);
    }
  }
`
const WelcomeUserLabelWrapper = styled.div`
  display: flex;
`
const WelcomeUserLabel = styled.div`
  font-size: 20px;
  min-width: 480px;
  ${mediaQuery.phone`
    min-width: 0;
  `}
`
const DailyTipLabel = styled.div`
  font-size: 18px;
  background-color: #066106;
  color: ${colorPalette.white.main};
  padding: 5px 10px;
  border-radius: 10px;
  span {
    font-size: 20px;
    font-weight: 500;
    margin-right: 4px;
  }
  ${mediaQuery.phone`
    display:none;
  `}
`
const DailyTipLabelMobile = styled(DailyTipLabel)`
  display: none;
  ${mediaQuery.phone`
    display:block;
    margin-top: 20px;
    width: 100%;
  `}
`
const EmptyValueClickableLabel = styled.div`
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
  }
`
const LoadingIndicatorWrapper = styled.div`
  margin: 50px auto;
  display: flex;
  justify-content: center;
`

// Component
class ProgramScreen extends Component {
  state = {
    personalStatsList: null,
    showPersonalStatsOfPhaseDialog: null,
    showPhotoOfPhaseDialog: null,
    daysSinceStartedChallenge: 0,
    isLoading: true,
    activeVideo: null,
    isMobile: mobileCheck(),
  }

  // Lifecycle methods
  componentDidMount() {
    this.loadPersonalStats()
  }

  // Helper methods
  loadPersonalStats = async () => {
    const { currentUser } = this.props
    const personalStatsList = await invokeAPIcall({ uri: 'api/personalStats' })
    const daysSinceStartedChallenge = calcDaysSinceStartedChallenge(personalStatsList, currentUser)
    this.setState({ personalStatsList, isLoading: false, daysSinceStartedChallenge })

    // after 1 second, immediately pop up the intro video (if user is in the first days of the 90dayschallenge AND has not watched yet)
    window.setTimeout(() => {
      if (daysSinceStartedChallenge < 3 && localStorage.getItem(`watchedDay1IntroVideo`) != 'true') {
        const { isMobile } = this.state
        this.setState({
          activeVideo: {
            YoutubeCode: isMobile ? 'lbPdXFWU67M' : '-px_Wipxuvk',
          },
        })
      }
    }, 1000)
  }

  getPhotoUrl = photoUrl => {
    if (photoUrl) {
      if (!photoUrl.includes('http')) {
        photoUrl = `${photoUrlPrefix}/personalStatsPhotos/${photoUrl}`
      }
    }
    return photoUrl
  }

  getFieldValue = (statsObject, fieldInfo) => {
    if (statsObject && statsObject[0]) {
      const fieldName = capitalizeFirstLetter(fieldInfo.fieldName)
      const val = statsObject[0][fieldName]
      return val
    }
    return null
  }

  getRecentPhase = () => {
    const { personalStatsList } = this.state
    const recentPersonalStatsItem = personalStatsList[personalStatsList.length - 1]
    const recentPersonalStatsItemPhase = recentPersonalStatsItem && recentPersonalStatsItem.Phase
    return recentPersonalStatsItemPhase
  }

  // UI event methods
  handleShowPersonalStatsOfPhaseDialog = phase => () => {
    this.setState({ showPersonalStatsOfPhaseDialog: phase })
  }

  handlePersonalStatsOfPhaseDialogClose = () => {
    this.setState({ showPersonalStatsOfPhaseDialog: null })
  }

  handleShowPersonalStatsPhotoDialog = phase => () => {
    this.setState({ showPhotoOfPhaseDialog: phase })
  }

  handlePersonalStatsPhotoDialogClose = () => {
    this.setState({ showPhotoOfPhaseDialog: null })
  }

  handleVideoCloseClick = () => {
    this.setState({ activeVideo: null })
    localStorage.setItem(`watchedDay1IntroVideo`, 'true')
  }

  // Render methods
  renderPersonalStatsItemValue = (statsObject, fieldInfo) => {
    const { currentUser } = this.props

    if (statsObject && statsObject[0]) {
      const val = this.getFieldValue(statsObject, fieldInfo)

      if (val) {
        let style = {}

        // specific logic for field 'BodyFat'
        if (fieldInfo.fieldName === 'bodyFatPerc') {
          if (currentUser.BirthDate && currentUser.Gender) {
            style.color = calcBodyFatColor(val, currentUser)
          }
        }
        // specific logic for field 'Weight'
        if (fieldInfo.fieldName === 'weight') {
          const { personalStatsList } = this.state
          const startStats = personalStatsList && personalStatsList.filter(item => item.Phase === 'start')
          const userHeight = startStats && startStats[0] && startStats[0].Height
          style.color = calcWeightColor(val, userHeight, currentUser)
        }

        if (fieldInfo.labelShortFormat) {
          return <span style={style}>{fieldInfo.labelShortFormat.replace('{item}', val)}</span>
        }
        if (fieldInfo.labelFormat) {
          return <span style={style}>{fieldInfo.labelFormat.replace('{item}', val)}</span>
        }
        return <span style={style}>{val}</span>
      } else if (val == null) {
        // if the Phase of this value is the recent phase the user reached to
        if (statsObject[0].Phase === this.getRecentPhase()) {
          return (
            <Tooltip title="לחצו כאן כדי לעדכן את המדדים">
              <EmptyValueClickableLabel onClick={this.handleShowPersonalStatsOfPhaseDialog(statsObject[0].Phase)}>
                <EditIcon />
              </EmptyValueClickableLabel>
            </Tooltip>
          )
        }
        return '/'
      }
    }
    return '-'
  }

  renderPersonalStatsBodyPhoto = statsObject => {
    if (statsObject && statsObject[0]) {
      if (statsObject[0].Photo) {
        const photoUrl = this.getPhotoUrl(statsObject[0].Photo)
        return (
          <a
            onClick={() => {
              window.open(photoUrl, '_blank')
            }}
          >
            <UserBodyPhoto photoUrl={photoUrl} title="לחצו להגדלה" />
          </a>
        )
      }
      // if the Phase of this value is the recent phase the user reached to
      if (statsObject[0].Phase === this.getRecentPhase()) {
        return (
          <Tooltip title="לחצו כאן כדי לעדכן את הצילום">
            <EmptyValueClickableLabel onClick={this.handleShowPersonalStatsPhotoDialog(statsObject[0].Phase)}>
              <EditIcon />
            </EmptyValueClickableLabel>
          </Tooltip>
        )
      }
    }
    return '-'
  }

  renderExistingPersonalStatsTable = () => {
    const { personalStatsList } = this.state
    const startStats = personalStatsList && personalStatsList.filter(item => item.Phase === 'start')
    const afterPhase1Stats = personalStatsList && personalStatsList.filter(item => item.Phase === 'afterPhase1')
    const afterPhase2Stats = personalStatsList && personalStatsList.filter(item => item.Phase === 'afterPhase2')
    const afterPhase3Stats = personalStatsList && personalStatsList.filter(item => item.Phase === 'afterPhase3')
    const personalStatsFieldsToDisplay = questionFields.filter(question => question.showInProgramDashboard)
    return (
      <ExistingPersonalStatsTableWrapper>
        <ActionButton>
          <StyledTuneIcon />
          <ActionButtonLabel>המדדים שלי</ActionButtonLabel>
        </ActionButton>
        <ExistingPersonalStatsTable>
          <PersonalStatHeader>
            <PersonalStatItemCell bold wider></PersonalStatItemCell>
            <PersonalStatItemCell bold>התחלתי</PersonalStatItemCell>
            <PersonalStatItemCell bold>יום 45</PersonalStatItemCell>
            <PersonalStatItemCell bold>יום 75</PersonalStatItemCell>
            <PersonalStatItemCell bold>יום 90</PersonalStatItemCell>
            <PersonalStatItemCell bold>שינוי</PersonalStatItemCell>
          </PersonalStatHeader>
          {personalStatsFieldsToDisplay.map(fieldInfo => {
            const startVal = this.getFieldValue(startStats, fieldInfo)
            const afterPhase1Val = this.getFieldValue(afterPhase1Stats, fieldInfo)
            const afterPhase2Val = this.getFieldValue(afterPhase2Stats, fieldInfo)
            const afterPhase3Val = this.getFieldValue(afterPhase3Stats, fieldInfo)

            let recentVal = null
            if (afterPhase3Val) {
              recentVal = afterPhase3Val
            } else if (afterPhase2Val) {
              recentVal = afterPhase2Val
            } else if (afterPhase1Val) {
              recentVal = afterPhase1Val
            }
            let changeInStats = startVal && recentVal ? recentVal - startVal : 0
            if (changeInStats > 0) {
              changeInStats = `+${changeInStats}`
            }

            return (
              <PersonalStatItem>
                <PersonalStatItemCell bold wider>
                  {fieldInfo.fieldLabel}
                </PersonalStatItemCell>
                <PersonalStatItemCell className={fieldInfo.fieldName !== 'weight' ? 'wizard_updateInitialPersonalStats' : null}>
                  {this.renderPersonalStatsItemValue(startStats, fieldInfo)}
                </PersonalStatItemCell>
                <PersonalStatItemCell>{this.renderPersonalStatsItemValue(afterPhase1Stats, fieldInfo)}</PersonalStatItemCell>
                <PersonalStatItemCell>{this.renderPersonalStatsItemValue(afterPhase2Stats, fieldInfo)}</PersonalStatItemCell>
                <PersonalStatItemCell>{this.renderPersonalStatsItemValue(afterPhase3Stats, fieldInfo)}</PersonalStatItemCell>
                <PersonalStatItemCell>
                  {startVal && recentVal ? (
                    <Tooltip
                      arrow
                      enterTouchDelay={0}
                      title={
                        <ChangeInStatsLabel>
                          {changeInStats}
                          <div>
                            ערך התחלתי: {startVal}
                            <br />
                            ערך נוכחי: {recentVal}
                          </div>
                        </ChangeInStatsLabel>
                      }
                    >
                      {changeInStats === 0 ? (
                        <span>-</span>
                      ) : (
                        <ChangeInStatsIconButton aria-label="שינוי במדדים">
                          {changeInStats > 0 ? <TrendingDownIcon /> : <TrendingUpIcon />}
                        </ChangeInStatsIconButton>
                      )}
                    </Tooltip>
                  ) : (
                    '-'
                  )}
                </PersonalStatItemCell>
              </PersonalStatItem>
            )
          })}
          <PersonalStatItem>
            <PersonalStatItemCell bold wider>
              צילום גוף
            </PersonalStatItemCell>
            <PersonalStatItemCell>{this.renderPersonalStatsBodyPhoto(startStats)}</PersonalStatItemCell>
            <PersonalStatItemCell>-</PersonalStatItemCell>
            <PersonalStatItemCell>{this.renderPersonalStatsBodyPhoto(afterPhase2Stats)}</PersonalStatItemCell>
            <PersonalStatItemCell>{this.renderPersonalStatsBodyPhoto(afterPhase3Stats)}</PersonalStatItemCell>
            <PersonalStatItemCell></PersonalStatItemCell>
          </PersonalStatItem>
        </ExistingPersonalStatsTable>
      </ExistingPersonalStatsTableWrapper>
    )
  }

  render() {
    const { showPersonalStatsOfPhaseDialog, showPhotoOfPhaseDialog, daysSinceStartedChallenge, isLoading, activeVideo } = this.state
    const { currentUser } = this.props
    const isTrialUserVal = isTrialUser(currentUser)

    let welcomeUserMsg = ''
    var currentHour = new Date().getHours()
    if (currentHour < 5) {
      welcomeUserMsg = 'לילה טוב'
    } else if (currentHour < 12) {
      welcomeUserMsg = 'בוקר טוב'
    } else if (currentHour < 18) {
      welcomeUserMsg = 'צוהריים טובים'
    } else {
      welcomeUserMsg = 'ערב טוב'
    }

    const personalStatsFieldsToDisplayInDialog = questionFields.filter(
      question => question.showInProgramDashboard && question.dontShowInStartPhase,
    )

    const dailyTipLabelContent = (
      <>
        <CheckIcon />
        <span>טיפ יומי:</span> {getString(`ChallengeTipsDay${daysSinceStartedChallenge + 1}`)}
      </>
    )

    return (
      <PageWrapper>
        <Breadcrumbs previousItems={[]} currentItem="חוויית החיטוב ב-90 יום" />
        {isLoading && (
          <LoadingIndicatorWrapper>
            <CircularProgress />
          </LoadingIndicatorWrapper>
        )}
        {!isLoading && (
          <MainSectionWrapper>
            {currentUser ? (
              <WelcomeUserLabelWrapper>
                <WelcomeUserLabel>
                  {welcomeUserMsg}, {currentUser.FirstName} {currentUser.LastName}
                </WelcomeUserLabel>
                <DailyTipLabel>{dailyTipLabelContent}</DailyTipLabel>
              </WelcomeUserLabelWrapper>
            ) : null}
            <MainSection>
              {this.renderExistingPersonalStatsTable()}
              <DailyTipLabelMobile>{dailyTipLabelContent}</DailyTipLabelMobile>
              <ActionButtons>
                <StyledLink to="/myDailySchedule">
                  <ActionButton>
                    <StyledCalendarIcon />
                    <ActionButtonLabel>התוכנית שלי</ActionButtonLabel>
                  </ActionButton>
                </StyledLink>
                <StyledLink to="/myWorkoutVideos">
                  <ActionButton>
                    <StyledFitnessIcon />
                    <ActionButtonLabel>האימונים שלי</ActionButtonLabel>
                  </ActionButton>
                </StyledLink>
                <StyledLink to="/myMeals">
                  <ActionButton>
                    <StyledFoodIcon />
                    <ActionButtonLabel>הארוחות שלי</ActionButtonLabel>
                  </ActionButton>
                </StyledLink>
                <StyledLink to="/myPersonalStatsProfile">
                  <ActionButton>
                    <StyledPersonIcon />
                    <ActionButtonLabel>הפרופיל שלי</ActionButtonLabel>
                  </ActionButton>
                </StyledLink>
                {/*isTrialUserVal && (
                  <StyledLink to="/challengePayment">
                    <ActionButton paymentButton>
                      <StyledCrownIcon />
                      <ActionButtonLabel paymentButton>רכישת החווייה המלאה</ActionButtonLabel>
                    </ActionButton>
                  </StyledLink>
                )*/}
                {/*<StyledLink to="/mySuccessWay">
                <ActionButton>
                  <StyledProgressIcon />
                  <ActionButtonLabel>הדרך להצלחה</ActionButtonLabel>
                </ActionButton>
          </StyledLink>*/}
              </ActionButtons>
            </MainSection>
          </MainSectionWrapper>
        )}
        {showPersonalStatsOfPhaseDialog && (
          <PersonalStatsOfPhaseDialog
            phase={showPersonalStatsOfPhaseDialog}
            personalStatsFieldsToDisplay={personalStatsFieldsToDisplayInDialog}
            handleClose={this.handlePersonalStatsOfPhaseDialogClose}
          />
        )}
        {showPhotoOfPhaseDialog && (
          <PersonalStatsPhotoDialog
            phase={showPhotoOfPhaseDialog}
            currentUser={currentUser}
            handleClose={this.handlePersonalStatsPhotoDialogClose}
          />
        )}
        {activeVideo && <WorkoutVideoDisplay activeVideo={activeVideo} handleClose={this.handleVideoCloseClick} />}
      </PageWrapper>
    )
  }
}

export default withRouter(ProgramScreen)
