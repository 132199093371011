import { connect } from 'react-redux'
import MyDailyScheduleScreen from '../components/MyDailyScheduleScreen'

export default connect(
  state => ({
    currentUser: state.currentUser.user,
    allUserMeals: state.currentUser.allUserMeals,
    allUserWorkouts: state.currentUser.allUserWorkouts,
  }),
  {},
)(MyDailyScheduleScreen)
