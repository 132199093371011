import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { Formik, Form } from 'formik'
import UserIcon from '@material-ui/icons/Person'
import CloseIcon from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import { mediaQuery, isAdminUser } from '../services/global'
import { invokeAPIcallWithFormData, invokeAPIcall } from '../services/rest'
import { colorPalette, globalStyles } from '../styles/styleVariables'
import StyledTextField from '../components/StyledTextField'

// Style
const UserSignButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  ${mediaQuery.phone`
    position: static;
    svg {
      color: ${colorPalette.primary1.main};
    }
  `}
`
const SignButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 100%;
  padding: 7px;
  background-color: ${props => (props.isActive ? colorPalette.white.main : 'transparent')};
  color: ${props => (props.isActive ? colorPalette.primary1.main : colorPalette.white.main)};
  &:hover {
    color: ${props => (props.isActive ? colorPalette.primary1.main : colorPalette.tertiary1.light)};
  }
  ${mediaQuery.phone`
    padding: 0;
  `}
`
const ButtonText = styled.span`
  ${mediaQuery.phone`
    display: none;
  `}
`
const StyledUserIcon = styled(UserIcon)`
  && {
    width: 30px;
    height: 30px;
    margin-left: 5px;
  }
`
const WelcomeUserButton = styled(SignButton)``
const SignInForm = styled.div`
  position: absolute;
  top: 44px;
  left: 0;
  background-color: ${colorPalette.white.main};
  ${globalStyles.boxRadius}
  ${globalStyles.boxShadowPopup}
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  overflow: hidden;
  min-width: 300px;
  transition: all 0.3s;
  height: ${props => (props.isActive ? '330px' : '0px')};
  z-index: 3;
  ${mediaQuery.phone`
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    transition: height 300ms ease-in-out 0s;
    height: ${props => (props.isActive ? '100vh' : '0')};
  `}
`
const WelcomeUserForm = styled(SignInForm)`
  height: ${props => (props.isActive ? '215px' : '0px')};
`
const FormInner = styled.div`
  padding: 20px;
  ${mediaQuery.phone`
    margin-top: env(safe-area-inset-top);
  `}
`
const FormTitle = styled.h4`
  color: ${colorPalette.primary1.main};
`
const StyledLink = styled(Link)`
  && {
    color: ${colorPalette.primary1.light};
    &:hover {
      color: ${colorPalette.primary1.main};
    }
  }
`
const StyledButton = styled(Button)`
  && {
    margin-top: 20px;
  }
`
const RegisterButton = styled.div`
  text-align: center;
  margin: 10px 0;
`
const WelcomeUserFormActionBtn = styled.div`
  text-align: center;
  margin: 10px 0;
  color: ${colorPalette.primary1.main};
  cursor: pointer;
`
const StyledCloseIcon = styled(CloseIcon)`
  && {
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: none;
  }
  ${mediaQuery.phone`
    && {
      display: inline-block;
    }
  `}
`

// Component
class UserSignButtons extends Component {
  state = {
    signinButtonClicked: false,
    welcomeButtonClicked: false,
    initialValues: {
      email: '',
      password: '',
    },
  }

  // Lifecycle methods
  async componentDidMount() {
    const { loginValues, setCurrentUser, setAccessToken } = this.props
    document.addEventListener('mousedown', this.handleClickOutside)

    // if this user is already logged in (meaning that we have 'currentUser.loginValues' object)
    if (loginValues && loginValues.email) {
      const currentUserData = await invokeAPIcall({ uri: 'api/users/getCurrentUser' })
      // the user's session still exists in server-side
      if (currentUserData) {
        setCurrentUser({ user: currentUserData })
      }
      // the user's session doesn't exist in server-side
      else {
        // re-login the user
        const responseData = await invokeAPIcallWithFormData({
          uri: 'token',
          body: `grant_type=password&username=${loginValues.email}&password=${loginValues.password}`,
        })

        // if success
        if (!responseData.error) {
          setAccessToken({ accessToken: responseData.access_token })

          const currentUserData = await invokeAPIcall({ uri: 'api/users/getCurrentUser' })
          setCurrentUser({ user: currentUserData })
        }
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  // Helper methods

  // UI event methods
  handleSignInClick = () => {
    const { signinButtonClicked } = this.state
    this.setState({ signinButtonClicked: !signinButtonClicked })
  }

  handleWelcomeClick = () => {
    const { welcomeButtonClicked } = this.state
    this.setState({ welcomeButtonClicked: !welcomeButtonClicked })
  }

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.buttonRef && !this.buttonRef.contains(event.target)) {
      this.setState({ signinButtonClicked: false })
    }
    if (
      this.welcomeWrapperRef &&
      !this.welcomeWrapperRef.contains(event.target) &&
      this.welcomeButtonRef &&
      !this.welcomeButtonRef.contains(event.target)
    ) {
      this.setState({ welcomeButtonClicked: false })
    }
  }

  handleLinkButtonClick = () => {
    this.setState({ signinButtonClicked: false, welcomeButtonClicked: false })
  }

  handleSignInSubmit = async (values, { setSubmitting }) => {
    const { setAccessToken, setCurrentUser, history } = this.props

    try {
      // invoke API call
      const responseData = await invokeAPIcallWithFormData({
        uri: 'token',
        body: `grant_type=password&username=${values.email}&password=${values.password}`,
      })

      // if success
      if (!responseData.error) {
        setAccessToken({ accessToken: responseData.access_token })

        const currentUserData = await invokeAPIcall({ uri: 'api/users/getCurrentUser' })
        setCurrentUser({ user: currentUserData, loginValues: values })

        this.setState({ signinButtonClicked: false })

        history.push('/program')
      }
      // if failed
      else {
        alert(responseData.error_description)
      }
    } catch (error) {
      console.log('error in login user', error)
    }

    setSubmitting(false)
  }

  handleLogoutClick = () => {
    const { removeCurrentUser, history } = this.props
    removeCurrentUser()

    this.setState({ welcomeButtonClicked: false })

    history.push('/')
  }

  setWrapperRef = node => {
    this.wrapperRef = node
  }
  setWelcomeWrapperRef = node => {
    this.welcomeWrapperRef = node
  }
  setButtonRef = node => {
    this.buttonRef = node
  }
  setWelcomeButtonRef = node => {
    this.welcomeButtonRef = node
  }

  // Render methods
  render() {
    const { currentUser } = this.props
    const { signinButtonClicked, welcomeButtonClicked, initialValues } = this.state
    const isAdmin = isAdminUser(currentUser)

    return (
      <UserSignButtonsWrapper>
        {currentUser == null && (
          <SignButton isActive={signinButtonClicked} onClick={this.handleSignInClick} ref={this.setButtonRef}>
            <StyledUserIcon />
            <ButtonText>כניסה</ButtonText>
          </SignButton>
        )}
        {currentUser != null && (
          <WelcomeUserButton isActive={welcomeButtonClicked} onClick={this.handleWelcomeClick} ref={this.setWelcomeButtonRef}>
            <StyledUserIcon />
            <ButtonText>{currentUser.FirstName}</ButtonText>
          </WelcomeUserButton>
        )}
        <SignInForm isActive={signinButtonClicked} ref={this.setWrapperRef}>
          <FormInner>
            <FormTitle>כניסה לחשבונך:</FormTitle>
            {signinButtonClicked && (
              <>
                <Formik validate={this.validate} initialValues={initialValues} onSubmit={this.handleSignInSubmit}>
                  {formikBag => {
                    const { isSubmitting, values, errors, touched, handleChange, handleBlur } = formikBag
                    return (
                      <Form>
                        <StyledTextField
                          label={'כתובת דוא"ל'}
                          margin="normal"
                          fullWidth
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <StyledTextField
                          label={'סיסמה'}
                          margin="normal"
                          fullWidth
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="password"
                          autoComplete="current-password"
                          helperText={<StyledLink to="/forgotPassword">שכחת סיסמה?</StyledLink>}
                        />
                        <StyledButton variant="contained" color="primary" fullWidth size="large" disabled={isSubmitting} type="submit">
                          התחבר
                        </StyledButton>
                      </Form>
                    )
                  }}
                </Formik>
                <RegisterButton onClick={this.handleLinkButtonClick}>
                  <Link to="/registerToChallenge/newUserViaEmail">משתמש חדש</Link>
                </RegisterButton>
              </>
            )}
          </FormInner>
          <StyledCloseIcon onClick={this.handleLinkButtonClick} />
        </SignInForm>
        <WelcomeUserForm isActive={welcomeButtonClicked} ref={this.setWelcomeWrapperRef}>
          <FormInner>
            <FormTitle>ברוך הבא {currentUser && currentUser.FirstName}</FormTitle>
            <WelcomeUserFormActionBtn onClick={this.handleLinkButtonClick}>
              <Link to="/program">לחוויית החיטוב ב-90 יום</Link>
            </WelcomeUserFormActionBtn>
            <WelcomeUserFormActionBtn onClick={this.handleLinkButtonClick}>
              <Link to="/myProfile">החשבון שלי</Link>
            </WelcomeUserFormActionBtn>
            <WelcomeUserFormActionBtn onClick={this.handleLinkButtonClick}>
              <Link to="/setNewPasswordFromExisting">החלפת סיסמה</Link>
            </WelcomeUserFormActionBtn>
            {isAdmin && (
              <WelcomeUserFormActionBtn onClick={this.handleLinkButtonClick}>
                <Link to="/adminPanel">עמודי ניהול</Link>
              </WelcomeUserFormActionBtn>
            )}
            <WelcomeUserFormActionBtn onClick={this.handleLogoutClick}>התנתק</WelcomeUserFormActionBtn>
          </FormInner>
          <StyledCloseIcon onClick={this.handleLinkButtonClick} />
        </WelcomeUserForm>
      </UserSignButtonsWrapper>
    )
  }
}

export default withRouter(UserSignButtons)
