import React, { Component } from 'react'
import styled from 'styled-components'
import { colorPalette, convertHexToRGB, globalStyles } from '../../styles/styleVariables'
import { mediaQuery } from '../../services/global'
import { getString } from '../../services/presetStringsHelper'

// Consts

// Style
const ChangingMotivationLinesWrapper = styled.div`
  position: relative;
  flex: 1;
  min-height: ${props => (props.minHeight ? props.minHeight - 20 : 100)}px;
  ${mediaQuery.phone`
    width: 100%;
  `}
`
const MotivationLine = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: ${colorPalette.white.main};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 20px;
  opacity: ${props => (props.isActive ? '1' : '0')};
  transition: all 2s;
  text-shadow: 1px 1px 6px ${colorPalette.black.main};
  ${mediaQuery.phone`
    font-size: 13px;
  `}
`
const MotivationLineTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
`
const MotivationLineSubTitle = styled.div``

// Component
class MotivationLines extends Component {
  state = {
    currentMessageIndex: 0,
    maxItemHeight: 0,
    motivationLineOptions: [],
  }

  // Lifecycle methods
  componentDidMount() {
    this.pauseInterval = false
    this.loadMotivationLines(this.props)

    window.setTimeout(() => {
      let maxItemHeight = 0
      document.querySelectorAll('.motivationLineTextItem').forEach(item => {
        if (item.scrollHeight > maxItemHeight) {
          maxItemHeight = item.scrollHeight
        }
      })
      this.setState({ maxItemHeight })
    }, 1000)
  }

  componentWillUnmount() {
    window.clearInterval(this.itemChangeInterval)
  }

  // Helper methods
  loadMotivationLines = async props => {
    const motivationLineOptions = [
      { title: getString('AppMotivationLineTitle1'), subTitle: getString('AppMotivationLineSubTitle1') },
      { title: getString('AppMotivationLineTitle2'), subTitle: getString('AppMotivationLineSubTitle2') },
      { title: getString('AppMotivationLineTitle3'), subTitle: getString('AppMotivationLineSubTitle3') },
      { title: getString('AppMotivationLineTitle4'), subTitle: getString('AppMotivationLineSubTitle4') },
    ]

    const maxIndex = motivationLineOptions.length - 1
    this.itemChangeInterval = window.setInterval(() => {
      const { currentMessageIndex } = this.state
      let newIndex = currentMessageIndex + 1
      if (currentMessageIndex === maxIndex) {
        newIndex = 0
      }
      if (this.pauseInterval === false) {
        this.setState({ currentMessageIndex: newIndex })
      }
    }, 10000)

    this.setState({ motivationLineOptions })
  }

  // Render methods
  renderItem = (item, itemIndex) => {
    const { currentMessageIndex } = this.state
    return (
      <MotivationLine isActive={currentMessageIndex === itemIndex} className="motivationLineTextItem">
        <MotivationLineTitle>{item.title}</MotivationLineTitle>
        <MotivationLineSubTitle>{item.subTitle}</MotivationLineSubTitle>
      </MotivationLine>
    )
  }

  render() {
    const { motivationLineOptions, maxItemHeight } = this.state
    return (
      <ChangingMotivationLinesWrapper minHeight={maxItemHeight}>
        {motivationLineOptions.map(this.renderItem)}
      </ChangingMotivationLinesWrapper>
    )
  }
}

export default MotivationLines
