import React, { Component } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import { withRouter } from 'react-router-dom'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloseIcon from '@material-ui/icons/Close'
import { invokeAPIcall } from '../../services/rest'
import { mediaQuery, keysToLowerCase } from '../../services/global'
import { requiredValidation } from '../../services/formValidation'
import StyledSelect from '../StyledSelect'

// Consts

// Style
const StyledDialogTitle = styled(DialogTitle)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
  }
`
const StyledButton = styled(Button)`
  && {
    margin-left: 10px;
  }
  ${mediaQuery.phone`
    && {
      margin-left: 0;
      margin-bottom: 10px;
      flex: 1;
    }
  `}
`
const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 40px;
  align-items: center;
  justify-content: center;
  ${mediaQuery.phone`
    flex-direction: column;
  `}
`

const FormWrapper = styled.div`
  padding: 20px 40px;
  padding-top: 0;
`
const FieldsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin: 10px 0;
  min-width: 460px;
`
const FieldCell = styled.div`
  flex: 0 0 48%;
  display: flex;
  justify-content: space-between;
`
const LoadingIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5px;
`
const FileUploadSubLabel = styled.div`
  font-size: 11px;
  margin-bottom: 10px;
  text-align: center;
`
const UserPhoto = styled.div`
  width: 200px;
  height: 200px;
  background-image: url('${props => props.photoUrl}');
  background-position: center;
  background-size: cover;
  margin: auto;
`
const UserPhotoButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  & > div,
  & > label {
    margin: 0 5px;
  }
`

// Component
class PersonalStatsOfPhaseDialog extends Component {
  state = {
    addedPhotoFile: null,
    isLoading: false,
  }

  // Lifecycle methods
  componentDidMount() {}

  // Helper methods

  // UI event methods
  handlePhotoFileChange = e => {
    let fileName = e.target.value
    if (fileName.includes('\\')) {
      const fileNameParts = fileName.split('\\')
      fileName = fileNameParts[fileNameParts.length - 1]
    }

    let addedPhotoFile = null
    const files = document.querySelector('input[type="file"]') && document.querySelector('input[type="file"]').files
    if (files.length > 0) {
      addedPhotoFile = files[0]
    }

    if (addedPhotoFile) {
      // more than 4MB
      if (addedPhotoFile.size > 4000000) {
        alert('משקל הקובץ גדול מדי: ' + addedPhotoFile.size)
        return false
      }
    }

    this.setState({ addedPhotoFile })
  }

  handleSubmit = async () => {
    const { phase, currentUser } = this.props
    const { addedPhotoFile } = this.state

    this.setState({ isLoading: true })

    // invoke API call
    try {
      // upload photo that user uploaded
      if (addedPhotoFile != null) {
        const uploadData = new FormData()
        uploadData.append('file', addedPhotoFile)
        uploadData.append('phase', phase)
        uploadData.append('userId', currentUser.UserID)
        await fetch('https://www.fitthybody.com/fitthybodyAPI/api/personalStats/UploadPhoto', { method: 'POST', body: uploadData })
        window.location.reload()
      }
    } catch (error) {
      console.log('error in uploading PersonalStats photo', error)
    }

    this.setState({ isLoading: false })
  }

  // Render methods
  render() {
    const { isLoading } = this.state
    const { handleClose } = this.props
    // if the user uploaded a photo
    let photoUrl = null
    const files = document.querySelector('input[type="file"]') && document.querySelector('input[type="file"]').files
    if (files && files.length > 0) {
      photoUrl = URL.createObjectURL(files[0])
    }

    return (
      <Dialog onClose={handleClose} open>
        <StyledDialogTitle id="simple-dialog-title" disableTypography>
          <Typography variant="h6">עדכון צילום הגוף שלי</Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <DialogContent>
          <FormWrapper>
            <>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="raised-button-file"
                type="file"
                onChange={e => {
                  this.handlePhotoFileChange(e)
                }}
              />
              <UserPhotoButtonsWrapper>
                <label htmlFor="raised-button-file">
                  <Button variant="contained" component="span" color="primary">
                    לחץ לבחירת תמונה
                  </Button>
                </label>
              </UserPhotoButtonsWrapper>
              <FileUploadSubLabel>משקל קובץ מקסימלי: 3MB, סוג קובץ: JPG/PNG/BMP</FileUploadSubLabel>
              {photoUrl && (
                <>
                  <UserPhoto photoUrl={photoUrl} />
                </>
              )}
              {photoUrl && (
                <ButtonsWrapper>
                  <StyledButton variant="contained" color="primary" type="submit" disabled={isLoading} onClick={this.handleSubmit}>
                    אישור
                  </StyledButton>
                </ButtonsWrapper>
              )}
            </>
            {isLoading && (
              <LoadingIndicatorWrapper>
                <CircularProgress />
              </LoadingIndicatorWrapper>
            )}
          </FormWrapper>
        </DialogContent>
      </Dialog>
    )
  }
}

export default withRouter(PersonalStatsOfPhaseDialog)
