/* global FB */
import React, { Component } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import Rating from '@material-ui/lab/Rating'
import { mediaQuery, siteName } from '../../services/global'
import { requiredValidation, emailValidation, phoneNumValidation } from '../../services/formValidation'
import { invokeAPIcall } from '../../services/rest'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import StyledTextField from '../StyledTextField'
import StyledSelect from '../StyledSelect'
import Breadcrumbs from '../Breadcrumbs'
import CountryPhoneCode from '../CountryPhoneCode'

// Style
const PageWrapper = styled.div`
  background-color: ${colorPalette.white.main};
  padding: 20px;
  flex: 1;
  ${mediaQuery.phone`
    background: none;
  `}
`
const PageContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  max-width: ${props => (props.feedbackMode ? '700px' : '900px')};
  margin: 0 auto;
  ${mediaQuery.phone`
    flex-direction: column;
    align-items: stretch;
  `}
`
const FormWrapper = styled.div`
  background-color: ${colorPalette.white.main};
  padding: 20px;
  color: ${colorPalette.primary1.main};
  ${globalStyles.boxShadow}
  ${globalStyles.boxRadius}
  flex: 1;
`
const CommunicationWaysBox = styled(FormWrapper)`
  flex: 0;
  margin-right: 20px;
  ${mediaQuery.phone`
    margin-right: 0;
    margin-top: 20px;
  `}
`
const PageTitleWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  ${mediaQuery.phone`
    flex-direction: column;
  `}
`
const PageTitle = styled.h1``
const FacebookWrapper = styled.div`
  max-width: 250px;
  max-height: 100px;
  overflow: hidden;
`
const FieldsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
`
const FieldCell = styled.div`
  flex: 0 0 100%;
  display: flex;
  justify-content: space-between;
`
const PhoneFieldCell = styled(FieldCell)`
  & > .MuiFormControl-root:first-child {
    flex: 0 0 69%;
  }
  & > .MuiFormControl-root:last-child {
    flex: 0 0 29%;
    .MuiInput-input {
      font-size: 12px;
    }
  }
`
const RatingFieldCell = styled(FieldCell)`
  display: block;
  margin-top: 20px;
`
const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  margin-top: 20px;
  ${mediaQuery.phone`
    width: 100%;
  `}
`
const StyledButton = styled(Button)`
  && {
  }
`
const CommunicationWayTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
`
const CommunicationWayContent = styled.div`
  margin-bottom: 20px;
`

// Component
class ContactUsScreen extends Component {
  state = {
    initialValues: {
      contactReason: '',
      name: '',
      phone: '',
      email: '',
      text: '',
      countryPhoneCode: 'IL',
      facebookProfilePhoto: '',
    },
    starRatingVal: 0, // we currently don't use it (it's only in UI to show the user, but doesn't sent to the API)
  }

  // Lifecycle methods
  componentDidMount() {
    this.prefillUserDataToForm()

    // Facebook init
    if (typeof FB !== 'undefined') {
      if (FB && FB.XFBML) {
        FB.XFBML.parse()
      }
    }

    window.handleFacebookLogin = user => {
      FB.api('/me?fields=id,first_name,last_name,email,picture', response => {
        const { initialValues } = this.state
        if (response.first_name && response.email) {
          this.setState({
            initialValues: {
              ...initialValues,
              name: `${response.first_name} ${response.last_name}`,
              email: response.email,
              facebookProfilePhoto: response.picture && response.picture.data.url,
            },
          })
        }
      })
    }
  }

  // Helper methods
  validate = values => {
    let errors = {}

    if (requiredValidation(values.contactReason)) {
      errors.contactReason = requiredValidation(values.contactReason)
    }
    if (requiredValidation(values.name)) {
      errors.name = requiredValidation(values.name)
    }
    if (requiredValidation(values.phone)) {
      errors.phone = requiredValidation(values.phone)
    } else if (phoneNumValidation(values.phone)) {
      errors.phone = phoneNumValidation(values.phone)
    }
    if (requiredValidation(values.email)) {
      errors.email = requiredValidation(values.email)
    } else if (emailValidation(values.email)) {
      errors.email = emailValidation(values.email)
    }
    if (requiredValidation(values.text)) {
      errors.text = requiredValidation(values.text)
    }

    return errors
  }

  prefillUserDataToForm = () => {
    const { currentUser } = this.props
    if (currentUser && currentUser.Email) {
      // pre-populate the initialValues from the logged-in user JSON
      this.setState({
        initialValues: {
          ...this.state.initialValues,
          name: `${currentUser.FirstName} ${currentUser.LastName}`,
          phone: currentUser.Phone,
          email: currentUser.Email,
        },
      })
    }
  }

  // UI event methods
  handleSubmit = async (values, { setSubmitting }) => {
    const { history, openSnackbar, feedbackMode } = this.props
    const data = {
      ...values,
      phone: `(${values.countryPhoneCode}) ${values.phone}`,
    }

    // invoke API call
    try {
      const responseData = await invokeAPIcall({
        uri: 'api/contact',
        data,
        method: 'POST',
      })

      // if API success
      if (responseData.Key === true) {
        const message = feedbackMode
          ? 'תודה על חוות דעתך, אנו משתדלים לעשות את המקסימום כדי להפוך את החווייה לטובה יותר!'
          : 'הבקשה שהזנת התקבלה בהצלחה! הצוות המקצועי שלנו ייצור עימך קשר תוך 3 שעות. תודה על הסבלנות!'
        openSnackbar({
          message,
          severity: 'success',
        })
        history.push('/')
      }
      // if failed
      else {
        openSnackbar({ message: responseData.Value, severity: 'failure' })
      }
    } catch (error) {
      console.log('error in sending email', error)
    }
    setSubmitting(false)
  }

  handleStarRatingChange = (e, newValue) => {
    this.setState({ starRatingVal: newValue })
  }

  // Render methods
  render() {
    const { initialValues, starRatingVal } = this.state
    const { feedbackMode } = this.props
    let pageTitle = 'כתבו לנו'
    if (feedbackMode) {
      pageTitle = 'חוות דעת'
    }

    return (
      <PageWrapper>
        <Helmet>
          <title>
            {siteName} - {pageTitle}
          </title>
        </Helmet>
        <Breadcrumbs previousItems={[]} currentItem={pageTitle} />
        <PageContentWrapper feedbackMode={feedbackMode}>
          <FormWrapper>
            <PageTitleWrapper>
              <PageTitle>{pageTitle}</PageTitle>
              {!window.cordova && (
                <FacebookWrapper>
                  <div
                    id="fb-login-button"
                    class="fb-login-button"
                    data-width=""
                    data-size="large"
                    data-button-type="continue_with"
                    data-auto-logout-link="false"
                    data-use-continue-as="true"
                    data-onlogin="handleFacebookLogin"
                    data-scope="email"
                  ></div>
                </FacebookWrapper>
              )}
            </PageTitleWrapper>
            <Formik validate={this.validate} initialValues={initialValues} onSubmit={this.handleSubmit} enableReinitialize={true}>
              {formikBag => {
                const { isSubmitting, values, errors, touched, handleChange, handleBlur, isValid } = formikBag
                return (
                  <Form>
                    <FieldsRow>
                      <FieldCell>
                        <StyledSelect
                          selectParams={{
                            name: 'contactReason',
                            value: values.contactReason,
                            onChange: handleChange,
                            onBlur: handleBlur,
                            margin: 'normal',
                            fullWidth: true,
                            inputProps: {
                              name: 'contactReason',
                              id: 'contactReason-simple',
                            },
                          }}
                          label={feedbackMode ? 'נושא חוות הדעת' : 'סיבת הפנייה'}
                          htmlFor="contactReason-simple"
                          helperText={errors.contactReason && touched.contactReason && errors.contactReason}
                          error={errors.contactReason && touched.contactReason}
                          inputLabelShrink={values.contactReason}
                        >
                          <MenuItem value="">--בחר--</MenuItem>
                          {feedbackMode && <MenuItem value={'productFeedback'}>חוות דעת על מוצרים</MenuItem>}
                          {feedbackMode && <MenuItem value={'serviceFeedback'}>חוות דעת על שירותים</MenuItem>}
                          {!feedbackMode && <MenuItem value={'websiteError'}>תקלה באתר</MenuItem>}
                          {!feedbackMode && <MenuItem value={'question'}>פנייה כללית</MenuItem>}
                        </StyledSelect>
                      </FieldCell>
                    </FieldsRow>
                    <FieldsRow>
                      <FieldCell>
                        <StyledTextField
                          label={'שם מלא'}
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={errors.name && touched.name && errors.name}
                          error={errors.name && touched.name}
                          margin="normal"
                          fullWidth
                        />
                      </FieldCell>
                    </FieldsRow>
                    <FieldsRow>
                      <PhoneFieldCell>
                        <StyledTextField
                          label={'טלפון'}
                          name="phone"
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={errors.phone && touched.phone && errors.phone}
                          error={errors.phone && touched.phone}
                          margin="normal"
                          fullWidth
                          helperText={feedbackMode ? 'לשימוש פנימי בלבד, לא יוצג באתר' : null}
                        />
                        <CountryPhoneCode
                          value={values.countryPhoneCode}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          errors={errors}
                          touched={touched}
                        />
                      </PhoneFieldCell>
                    </FieldsRow>
                    <FieldsRow>
                      <FieldCell>
                        <StyledTextField
                          label={'כתובת דוא״ל'}
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={errors.email && touched.email && errors.email}
                          error={errors.email && touched.email}
                          margin="normal"
                          fullWidth
                          helperText={feedbackMode ? 'לשימוש פנימי בלבד, לא יוצג באתר' : null}
                        />
                      </FieldCell>
                    </FieldsRow>
                    {feedbackMode && (
                      <FieldsRow>
                        <RatingFieldCell>
                          <div>דירוג</div>
                          <Rating name="simple-controlled" value={starRatingVal} onChange={this.handleStarRatingChange} />
                        </RatingFieldCell>
                      </FieldsRow>
                    )}
                    <FieldsRow>
                      <FieldCell>
                        <StyledTextField
                          label={'פירוט מלא'}
                          name="text"
                          value={values.text}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={errors.text && touched.text && errors.text}
                          error={errors.text && touched.text}
                          margin="normal"
                          fullWidth
                          multiline
                          rows="3"
                        />
                      </FieldCell>
                    </FieldsRow>
                    <ButtonsWrapper>
                      <StyledButton variant="contained" color="primary" fullWidth disabled={!isValid || isSubmitting} type="submit">
                        {feedbackMode ? 'שליחת חוות דעת' : 'שליחת הודעה'}
                      </StyledButton>
                    </ButtonsWrapper>
                  </Form>
                )
              }}
            </Formik>
          </FormWrapper>
          {!feedbackMode && (
            <CommunicationWaysBox>
              <CommunicationWayTitle>טלפון:</CommunicationWayTitle>
              <CommunicationWayContent>
                <div>
                  <a href="tel:0544390332">054-4390332</a> אלעד
                </div>
                <div>
                  <a href="tel:052-7773230">052-7773230</a> אושרי
                </div>
              </CommunicationWayContent>
              <CommunicationWayTitle>דוא״ל:</CommunicationWayTitle>
              <CommunicationWayContent>
                <a href="mailto:FitThyBody@gmail.com">FitThyBody@gmail.com</a>
              </CommunicationWayContent>
              <CommunicationWayTitle>הנהלה ראשית:</CommunicationWayTitle>
              <CommunicationWayContent>יהושע בן נון 91, תל אביב</CommunicationWayContent>
            </CommunicationWaysBox>
          )}
        </PageContentWrapper>
      </PageWrapper>
    )
  }
}

export default withRouter(ContactUsScreen)
