import React, { Component } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import ArrowIcon from '@material-ui/icons/ArrowDownward'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { colorPalette } from '../../styles/styleVariables'
import iosShareIconFile from '../../images/iosShareIcon.PNG'

// Consts

// Style
const StyledDialog = styled(Dialog)`
  && {
    .MuiDialog-paper {
      margin: 12px;
    }
  }
`
const StyledDialogTitle = styled(DialogTitle)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
  }
`
const StyledDialogContent = styled(DialogContent)`
  && {
    padding-bottom: 24px;
  }
`
const DeviceTitle = styled.h6`
  margin-top: 20px;
  margin-bottom: 5px;
`
const ArrowIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  svg {
    width: 40px;
    height: 40px;
  }
`
const IosShareIcon = styled.span`
  display: inline-block;
  width: 30px;
  height: 30px;
  position: relative;
  top: 10px;
  margin: 0 5px;
  background: url(${iosShareIconFile});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`

// Component
class AddToHomeScreenDialog extends Component {
  state = {}

  // Lifecycle methods
  componentDidMount() {}

  // Helper methods

  // UI event methods

  // Render methods
  render() {
    const { handleClose } = this.props
    return (
      <StyledDialog onClose={handleClose} open>
        <StyledDialogTitle id="simple-dialog-title" disableTypography>
          <Typography variant="h6">הוספה למסך הבית</Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <StyledDialogContent>
          כדי להוסיף את ״חוויית החיטוב ב-90 יום״ למסך הבית בטלפון שלך, יש לבצע את הפעולות הבאות.
          <DeviceTitle>מכשיר אייפון:</DeviceTitle>
          <div>
            1. ללחוץ על האייקון <IosShareIcon /> שמופיע בתחתית המסך.
          </div>
          <div>2. לבחור באופציה ״הוסף למסך הבית״ (Add To Home Screen).</div>
          <DeviceTitle>מכשיר אנדרואיד:</DeviceTitle>
          <div>
            1. ללחוץ על האייקון <MoreVertIcon /> שמופיע בחלק העליון של המסך.
          </div>
          <div>2. לבחור באופציה ״הוסף למסך הבית״ (Add To Home Screen).</div>
        </StyledDialogContent>
      </StyledDialog>
    )
  }
}

export default withRouter(AddToHomeScreenDialog)
