// Actions
const SHOW_LOADING = 'fullScreenLoading/show'
const HIDE_LOADING = 'fullScreenLoading/hide'

// Reducer
const initialState = {
  isLoading: false,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SHOW_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case HIDE_LOADING:
      return {
        isLoading: false,
      }
    default:
      return state
  }
}

// Action Creators
export function showFullScreenLoading() {
  return {
    type: SHOW_LOADING,
  }
}

export function hideFullScreenLoading() {
  return {
    type: HIDE_LOADING,
  }
}
