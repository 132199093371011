import React, { Component } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import { mediaQuery } from '../services/global'
import { colorPalette, globalStyles } from '../styles/styleVariables'

// Style
const SearchBarWrapper = styled.div`
  height: 100%;
  position: relative;
  margin-right: auto;
  ${mediaQuery.phone`
    display: none;
  `}
`
const SearchButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 100%;
  padding: 10px 5px;
  background-color: ${props => (props.isActive ? colorPalette.white.main : 'transparent')};
  color: ${props => (props.isActive ? colorPalette.primary1.main : colorPalette.white.main)};
  ${globalStyles.customButton}
  &:hover {
    svg {
      color: ${props => (props.isActive ? colorPalette.primary1.main : colorPalette.tertiary1.light)};
    }
  }
`
const SearchTextField = styled(TextField)`
  && {
    margin-right: auto;
    width: 180px;
    color: ${colorPalette.primary1.main};
    margin: 16px auto;
    label,
    input,
    svg {
      color: ${colorPalette.primary1.main};
    }
    input {
      height: 16px;
    }
    fieldset {
      border-color: ${colorPalette.primary1.main};
    }
    &:hover fieldset {
      border-color: ${colorPalette.tertiary1.main};
    }
  }
`
const StyledSearchIcon = styled(SearchIcon)`
  && {
    cursor: pointer;
  }
`
const OptionsMenu = styled.div`
  position: absolute;
  top: 44px;
  left: 0;
  background-color: ${colorPalette.white.main};
  ${globalStyles.boxRadius}
  ${globalStyles.boxShadowPopup}
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  overflow: hidden;
  min-width: 250px;
  transition: all 0.3s;
  height: ${props => (props.isActive ? '70px' : '0px')};
  display: flex;
`

// Component
class SearchBar extends Component {
  state = {
    searchButtonClicked: false,
    searchTerm: '',
  }

  // Lifecycle methods
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  // UI event methods
  handleSearchClick = () => {
    const { searchButtonClicked } = this.state
    this.setState({ searchButtonClicked: !searchButtonClicked })
  }

  handleTextFieldChange = e => {
    this.setState({ searchTerm: e.target.value })
  }

  handleTextFieldKeyDown = e => {
    const { history } = this.props
    const { searchTerm } = this.state
    if (e.key === 'Enter' && searchTerm.length > 2) {
      this.setState({ searchTerm: '', searchButtonClicked: false })
      history.push(`/search/${searchTerm}`)
    }
  }

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.buttonRef && !this.buttonRef.contains(event.target)) {
      this.setState({ searchButtonClicked: false })
    }
  }

  setWrapperRef = node => {
    this.wrapperRef = node
  }

  setButtonRef = node => {
    this.buttonRef = node
  }

  // Render methods
  render() {
    const { searchButtonClicked, searchTerm } = this.state
    return (
      <SearchBarWrapper>
        <SearchButton isActive={searchButtonClicked} onClick={this.handleSearchClick} ref={this.setButtonRef} title="חיפוש" type="button">
          <StyledSearchIcon />
        </SearchButton>
        <OptionsMenu isActive={searchButtonClicked} ref={this.setWrapperRef}>
          <SearchTextField
            id="outlined-search"
            type="search"
            margin="dense"
            variant="outlined"
            placeholder="חפש באתר"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={searchTerm}
            onChange={this.handleTextFieldChange}
            onKeyDown={this.handleTextFieldKeyDown}
          />
        </OptionsMenu>
      </SearchBarWrapper>
    )
  }
}

export default withRouter(SearchBar)
