import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { withRouter } from 'react-router-dom'
import FormGroup from '@material-ui/core/FormGroup'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import FoodIcon from '@material-ui/icons/Restaurant'
import LeftArrowIcon from '@material-ui/icons/KeyboardArrowLeft'
import RightArrowIcon from '@material-ui/icons/KeyboardArrowRight'
import backgroundImage from '../../images/kitchenBg.jpg'
import { mediaQuery, mobileCheck, photoUrlPrefix } from '../../services/global'
import { invokeAPIcall } from '../../services/rest'
import { dayTimeOptions, foodTypeOptions, filterMealsByFoodTypeOfUser } from '../../services/recipes'
import { isTrialUser } from '../../services/challengeProgressHelper'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import Breadcrumbs from '../Breadcrumbs'
import SendIngredientsListDialog from './SendIngredientsListDialog'
import SendRecipeEmailDialog from './SendRecipeEmailDialog'

// Consts

// Style
const PageWrapper = styled.div`
  background-color: ${colorPalette.white.main};
  background-image: url(${backgroundImage});
  background-size: 70% auto;
  background-position: top left;
  background-repeat: no-repeat;
  padding: 20px;
  padding-top: 0;
  flex: 1;
  ${mediaQuery.phone`
    background: none;
    padding: 20px;
  `}
`
const MealsScreenContent = styled.div`
  display: flex;
  margin: 0 35px;
  position: relative;
  ${mediaQuery.phone`
    width: auto;
    padding: 0 10px;
    flex-direction: column;
  `}
`
const MealsTitleSection = styled.div`
  flex: 0 0 400px;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  ${mediaQuery.phone`
    margin-left: 0;
    flex: 1;
  `}
`
const MealsTitle = styled.div`
  background-color: ${colorPalette.primary1.main};
  color: ${colorPalette.white.main};
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 4px 10px;
  display: flex;
  align-items: center;
`
const MealsTitleLabel = styled.div`
  color: ${colorPalette.white.main};
  font-size: 36px;
  font-weight: 500;
  margin-right: 20px;
  ${mediaQuery.tablet`
    font-size: 30px;
  `}
`
const iconCSS = css`
  && {
    width: 50px;
    height: 50px;
    color: ${colorPalette.white.main};
  }
`
const StyledFoodIcon = styled(FoodIcon)`
  ${iconCSS}
`
const MealItem = styled.div`
  display: flex;
  flex: 3;
  margin: 0 auto;
  padding: 10px;
  position: relative;
  color: ${colorPalette.black.main};
  margin-left: 20%;
  ${mediaQuery.phone`
    margin-left: 0;
  `}
`
const MealDetails = styled.div``
const MealName = styled.div`
  font-size: 36px;
  font-weight: 500;
`
const IngredientsText = styled.div`
  p {
    margin: 0;
  }
`
const InstructionsText = styled(IngredientsText)`
  line-height: 2;
`
const Label = styled.div`
  font-size: 18px;
  font-weight: 500;
`
const MealPhoto = styled.div`
  width: 100%;
  min-height: 350px;
  background-image: url('${props => props.photoUrl}');
  background-position: center;
  background-size: cover;
  margin: 0 auto;
  border-radius: 10px;
  ${mediaQuery.phone`
    min-height: 270px;
  `}
`
const MealPhotoZoom = styled(MealPhoto)`
  position: absolute;
  top: 0;
  right: 0;
  height: 500px;
  width: 500px;
  background-size: 200%;
  z-index: 10;
`
const IngredientsAndInstructions = styled.div`
  display: flex;
  margin-top: 20px;
  ${mediaQuery.phone`
    flex-direction: column;
  `}
`
const IngredientsWrapper = styled.div`
  min-width: 300px;
  ${mediaQuery.phone`
    min-width: 0;
  `}
`
const InstructionsWrapper = styled.div`
  ${mediaQuery.phone`
    margin-top: 20px;
  `}
`
const ExtraInfoLabels = styled.div`
  display: flex;
  & > div {
    margin-left: 25px;
  }
  ${mediaQuery.phone`
    flex-direction: column;
    & > div {
      margin-left: 0;
      margin-bottom: 5px;
    }
  `}
`
const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    margin-bottom: 10px;
    align-items: flex-start;
    span {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
`
const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  & > button {
    margin-left: 10px;
  }
  ${mediaQuery.phone`
    flex-direction: column;
    & > button {
      margin-left: 0;
      margin-bottom: 10px;
    }
  `}
`
const NextMealBtn = styled.div`
  position: absolute;
  left: -45px;
  top: 230px;
  svg {
    color: ${colorPalette.primary1.main};
    width: 45px;
    height: 45px;
    cursor: pointer;
  }
  &:hover svg {
    color: ${colorPalette.black.main};
  }
`
const PrevMealBtn = styled(NextMealBtn)`
  right: -45px;
  left: auto;
`

// Consts
const ingredientsSavedListKey = 'ingredients-saved-list'

// Component
class MealDetailsScreen extends Component {
  state = {
    mealData: null,
    showIngredientsListDialog: false,
    showSendRecipeEmailDialog: false,
    ingredientsListIncremental: 0, // just to force re-render
    zoomedBackgroundPosition: null,
    otherMealsForNavigation: [],
  }

  // Lifecycle methods
  componentDidMount() {
    this.loadMealData(this.props)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      this.loadMealData(nextProps)
    }
  }

  // Helper methods
  loadMealData = async props => {
    const { match, currentUser, allUserMeals } = props
    const { params } = match
    const isTrialUserVal = isTrialUser(currentUser)

    // for trial users - check if this meal is one of the first X meals from their DailySchedule of 90daysChallenge
    /*if (isTrialUserVal) {
      const first3MealsIDs = allUserMeals
        .filter(item => item.DayInChallenge >= 1 && item.DayInChallenge <= TRIAL_DAYS)
        .map(item => item.Recipe && item.Recipe.RecipeID)
      // if not one of the first X - block the page
      if (!first3MealsIDs.includes(Number(params.id))) {
        return
      }
    }*/

    const mealData = await invokeAPIcall({ uri: `api/recipes/${params.id}` })
    if (isTrialUserVal && mealData.ShowInChallengePhase !== 1) {
      // block trial users that want to watch recipes from later phases (after 1)
      return
    }

    this.loadOtherMealsForNavigation(mealData)

    this.setState({ mealData })
  }

  getIngredientsSavedList = () => {
    // get the current list of SavedIngredients from localStorage
    const ingredientsSavedListJSON = localStorage.getItem(ingredientsSavedListKey)
    const ingredientsSavedList = ingredientsSavedListJSON ? JSON.parse(ingredientsSavedListJSON) : []
    return ingredientsSavedList
  }

  loadOtherMealsForNavigation = async currentMealData => {
    const { personalStats } = this.props
    let otherMealsForNavigation = []
    const meals = await invokeAPIcall({ uri: 'api/recipes' })
    otherMealsForNavigation = meals.filter(
      meal => meal.DayTime == currentMealData.DayTime && meal.ShowInChallengePhase === currentMealData.ShowInChallengePhase,
    )

    // filter the meals by FoodType (based on the user's preferences)
    otherMealsForNavigation = filterMealsByFoodTypeOfUser(personalStats, otherMealsForNavigation)
    this.setState({ otherMealsForNavigation })
  }

  // UI event methods
  handleIngredientCheckbox = (RecipeIngredientID, event) => {
    let ingredientsSavedList = this.getIngredientsSavedList()
    if (event.target.checked) {
      // add this ingredient
      ingredientsSavedList = [...ingredientsSavedList, RecipeIngredientID]
    } else {
      // remove this ingredient
      ingredientsSavedList = ingredientsSavedList.filter(item => item !== RecipeIngredientID)
    }
    ingredientsSavedList = [...new Set(ingredientsSavedList)] // remove duplicates

    // save updated list in the LocalStorage
    localStorage.setItem(ingredientsSavedListKey, JSON.stringify(ingredientsSavedList))
    this.setState({ ingredientsListIncremental: this.state.ingredientsListIncremental + 1 })
  }

  handleSendIngredientsList = () => {
    this.setState({ showIngredientsListDialog: true })
  }

  handleIngredientsListDialogClose = () => {
    this.setState({ showIngredientsListDialog: false })
  }

  handleSendRecipeClick = () => {
    this.setState({ showSendRecipeEmailDialog: true })
  }

  handleSendRecipeEmailDialogClose = () => {
    this.setState({ showSendRecipeEmailDialog: false })
  }

  handleMouseMove = e => {
    if (mobileCheck()) {
      return null
    }
    const { left, top, width, height } = e.target.getBoundingClientRect()
    const x = ((e.clientX - left) / width) * 100
    const y = ((e.clientY - top) / height) * 100
    this.setState({ zoomedBackgroundPosition: `${x}% ${y}%` })
  }

  handleMouseOut = () => {
    this.setState({ zoomedBackgroundPosition: null })
  }

  handleNextMealClick = () => {
    const { mealData, otherMealsForNavigation } = this.state
    const { history } = this.props
    const nextMealId = otherMealsForNavigation.filter(meal => meal.RecipeID > mealData.RecipeID)
    if (nextMealId && nextMealId.length && nextMealId.length > 0) {
      history.push(`/myMeals/${nextMealId[0].RecipeID}`)
    } else if (otherMealsForNavigation.length > 0) {
      history.push(`/myMeals/${otherMealsForNavigation[0].RecipeID}`)
    }
  }

  handlePrevMealClick = () => {
    const { mealData, otherMealsForNavigation } = this.state
    const { history } = this.props
    const nextMealId = otherMealsForNavigation.filter(meal => meal.RecipeID < mealData.RecipeID)
    if (nextMealId && nextMealId.length && nextMealId.length > 0) {
      history.push(`/myMeals/${nextMealId[nextMealId.length - 1].RecipeID}`)
    } else if (otherMealsForNavigation.length > 0) {
      history.push(`/myMeals/${otherMealsForNavigation[otherMealsForNavigation.length - 1].RecipeID}`)
    }
  }

  // Render methods
  render() {
    const { openSnackbar } = this.props
    const { mealData, showIngredientsListDialog, showSendRecipeEmailDialog, zoomedBackgroundPosition } = this.state
    if (!mealData) {
      return null
    }
    let photoUrl = mealData.PhotoURL
    if (photoUrl) {
      if (!photoUrl.includes('http')) {
        photoUrl = `${photoUrlPrefix}/recipePhotos/${photoUrl}`
      }
    }
    const dayTimeOption = dayTimeOptions.filter(dayTimeItem => dayTimeItem.value === mealData.DayTime)
    let dayTime = dayTimeOption.length > 0 ? dayTimeOption[0].label : '-'

    let foodTypeIDsLabel = '-'
    if (mealData.FoodTypeIDs) {
      foodTypeIDsLabel = foodTypeOptions
        .filter(option => mealData.FoodTypeIDs.includes(option.value))
        .map(option => option.label)
        .join(', ')
    }

    let breadcrumbsPrevItems = [
      {
        label: 'חוויית החיטוב ב-90 יום',
        url: `/program`,
      },
      {
        label: 'הארוחות שלי',
        url: `/myMeals`,
      },
      {
        label: dayTime,
        url: `/myMeals/byDayTime/${mealData.DayTime}`,
      },
    ]
    const { match } = this.props
    if (match.path.includes('myDailySchedule')) {
      breadcrumbsPrevItems = [
        {
          label: 'חוויית החיטוב ב-90 יום',
          url: `/program`,
        },
        {
          label: 'התוכנית שלי',
          url: `/myDailySchedule`,
        },
      ]
    }

    const ingredientsSavedList = this.getIngredientsSavedList()

    return (
      <PageWrapper>
        <Breadcrumbs previousItems={breadcrumbsPrevItems} currentItem={mealData.Name} />
        <MealsScreenContent>
          <MealsTitleSection>
            <MealsTitle>
              <StyledFoodIcon />
              <MealsTitleLabel>הארוחות שלי</MealsTitleLabel>
            </MealsTitle>
            <MealPhoto photoUrl={photoUrl} /*onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseOut}*/ />
          </MealsTitleSection>
          <MealItem>
            {zoomedBackgroundPosition && <MealPhotoZoom photoUrl={photoUrl} style={{ backgroundPosition: zoomedBackgroundPosition }} />}
            <MealDetails>
              <MealName>{mealData.Name}</MealName>
              <ExtraInfoLabels>
                <div>סוג: {dayTime}</div>
                <div>כמות המנות: {mealData.AmountOfMeals || '-'}</div>
                <div>זמן עבודה (בדקות): {mealData.DurationInMins || '-'}</div>
                {mealData.CookingTimeInMins && <div>זמן בישול/אפייה (בדקות): {mealData.CookingTimeInMins || '-'}</div>}
              </ExtraInfoLabels>
              <IngredientsAndInstructions>
                <IngredientsWrapper>
                  <Label>מצרכים:</Label>
                  <IngredientsText>
                    <FormGroup>
                      {mealData.RecipeIngredients.map(item => (
                        <StyledFormControlLabel
                          control={
                            <Checkbox
                              checked={ingredientsSavedList.includes(item.RecipeIngredientID)}
                              name={item.RecipeIngredientID}
                              onChange={this.handleIngredientCheckbox.bind(this, item.RecipeIngredientID)}
                            />
                          }
                          label={item.IngredientName}
                        />
                      ))}{' '}
                    </FormGroup>
                  </IngredientsText>
                </IngredientsWrapper>
                <InstructionsWrapper>
                  <Label>הוראות הכנה:</Label>
                  <InstructionsText>
                    {mealData.InstructionsText.split('\n').map(function(item, idx) {
                      return (
                        <span key={idx}>
                          {item}
                          <br />
                        </span>
                      )
                    })}
                  </InstructionsText>
                </InstructionsWrapper>
              </IngredientsAndInstructions>
              <ButtonsWrapper>
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={ingredientsSavedList.length === 0}
                  onClick={this.handleSendIngredientsList}
                  className="wizard_sendIngredientsBtn"
                >
                  שליחת רשימת קניות ({ingredientsSavedList.length})
                </Button>
                <Button variant="outlined" color="primary" onClick={this.handleSendRecipeClick} className="wizard_sendRecipeBtn">
                  שיתוף המתכון
                </Button>
                {showIngredientsListDialog && (
                  <SendIngredientsListDialog
                    ingredientsSavedListKey={ingredientsSavedListKey}
                    getIngredientsSavedList={this.getIngredientsSavedList}
                    handleClose={this.handleIngredientsListDialogClose}
                    openSnackbar={openSnackbar}
                  />
                )}
                {showSendRecipeEmailDialog && (
                  <SendRecipeEmailDialog
                    mealData={mealData}
                    mealDayTime={dayTime}
                    handleClose={this.handleSendRecipeEmailDialogClose}
                    openSnackbar={openSnackbar}
                  />
                )}
              </ButtonsWrapper>
            </MealDetails>
          </MealItem>
          <NextMealBtn onClick={this.handleNextMealClick}>
            <LeftArrowIcon />
          </NextMealBtn>
          <PrevMealBtn onClick={this.handlePrevMealClick}>
            <RightArrowIcon />
          </PrevMealBtn>
        </MealsScreenContent>
      </PageWrapper>
    )
  }
}

export default withRouter(MealDetailsScreen)
