import React, { Component } from 'react'
import styled from 'styled-components'
import { Link, withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import EditIcon from '@material-ui/icons/Edit'
import { invokeAPIcall } from '../../services/rest'
import Breadcrumbs from '../Breadcrumbs'

// Style
const PageWrapper = styled.div`
  padding: 20px;
`
const EditIconButton = styled(IconButton)`
  && {
    padding: 4px;
  }
`

// Consts/Helpers
function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy)
}

const headCells = [
  { id: 'id', numeric: false, disablePadding: true, label: 'קוד טקסט' },
  { id: 'descKey', numeric: false, disablePadding: false, label: 'תיאור' },
  { id: 'hebrewText', numeric: false, disablePadding: false, label: 'טקסט להצגה' },
  { id: 'edit', numeric: false, disablePadding: false, label: 'עריכה' },
]

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'בחר הכל' }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel active={orderBy === headCell.id} direction={order} onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? <span>{order === 'desc' ? '' : ''}</span> : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const EnhancedTableToolbar = props => {
  const { numSelected, handleDeleteItems } = props

  return (
    <Toolbar>
      {numSelected > 0 ? (
        <Typography color="inherit" variant="subtitle1">
          {numSelected} נבחרו
        </Typography>
      ) : (
        <Typography variant="h6" id="tableTitle">
          ניהול טקסטים
        </Typography>
      )}

      {/*numSelected > 0 ? (
        <Tooltip title="מחיקה">
          <IconButton aria-label="מחיקה" onClick={handleDeleteItems}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Link to="/presetStringsAdminAdd">
          <AddButton variant="contained" color="primary">
            הוסף טקסט
          </AddButton>
        </Link>
      )*/}
    </Toolbar>
  )
}

// Component
class PresetStringsAdminScreen extends Component {
  state = {
    presetStrings: [],
    order: 'asc',
    orderBy: 'name',
    selected: [],
    page: 0,
    dense: true,
    rowsPerPage: 200,
  }

  // Lifecycle methods
  componentDidMount() {
    this.loadPresetStringList()
  }

  // Helper methods
  loadPresetStringList = async () => {
    const stringList = await invokeAPIcall({ uri: 'api/presetStrings' })
    this.setState({
      presetStrings: stringList.map(item => ({
        id: item.PresetStringID,
        descKey: item.DescKey,
        hebrewText: item.HebrewText,
      })),
    })
  }

  handleRequestSort = (event, property) => {
    const { order, orderBy } = this.state
    const isDesc = orderBy === property && order === 'desc'
    this.setState({ order: isDesc ? 'asc' : 'desc', orderBy: property })
  }

  handleSelectAllClick = event => {
    const { presetStrings } = this.state
    if (event.target.checked) {
      const newSelecteds = presetStrings.map(n => n.id)
      this.setState({ selected: newSelecteds })
      return
    }
    this.setState({ selected: [] })
  }

  handleClick = (event, name) => {
    const { selected } = this.state
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }

    this.setState({ selected: newSelected })
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage })
  }

  handleChangeRowsPerPage = event => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) })
  }

  handleDeleteItems = async () => {
    var result = window.confirm('האם אתה בטוח?')
    if (result) {
      const { selected } = this.state

      selected.forEach(async selctedId => {
        // invoke API call
        try {
          const responseData = await invokeAPIcall({ uri: `api/presetStrings/${selctedId}`, method: 'DELETE' })

          // if add success
          if (responseData.Key === true) {
            this.loadPresetStringList()
          }
          // if failed
          else {
            alert(responseData.Value || 'התרחשה שגיאה')
          }
        } catch (error) {
          console.log('error in deleting string', error)
        }
      })

      alert('המחיקה בוצעה בהצלחה')
    }
  }

  isSelected = name => {
    const { selected } = this.state
    return selected.indexOf(name) !== -1
  }

  emptyRows = () => {
    const { presetStrings, rowsPerPage, page } = this.state
    return rowsPerPage - Math.min(rowsPerPage, presetStrings.length - page * rowsPerPage)
  }

  // UI event methods

  // Render methods
  render() {
    const { presetStrings, selected, order, orderBy, page, rowsPerPage } = this.state
    return (
      <PageWrapper>
        <Breadcrumbs
          previousItems={[
            {
              label: `מסכי ניהול`,
              url: `/adminPanel`,
            },
          ]}
          currentItem={`ניהול טקסטים`}
        />
        <Paper>
          <EnhancedTableToolbar numSelected={selected.length} handleDeleteItems={this.handleDeleteItems} />
          <TableContainer>
            <Table aria-labelledby="tableTitle" size={'small'} aria-label="enhanced table">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={presetStrings.length}
              />
              <TableBody>
                {stableSort(presetStrings, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = this.isSelected(row.id)
                    const labelId = `enhanced-table-checkbox-${index}`

                    return (
                      <TableRow
                        hover
                        onClick={event => this.handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row" padding="none">
                          {row.id}
                        </TableCell>
                        <TableCell>{row.descKey}</TableCell>
                        <TableCell>
                          {row.hebrewText && row.hebrewText.length > 110 ? row.hebrewText.substring(0, 110) + '...' : row.hebrewText}
                        </TableCell>
                        <TableCell>
                          <Tooltip title="עריכה">
                            <Link to={`/presetStringsAdminAdd/${row.id}`}>
                              <EditIconButton aria-label="עריכה">
                                <EditIcon />
                              </EditIconButton>
                            </Link>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                {this.emptyRows > 0 && (
                  <TableRow style={{ height: 33 * this.emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
            component="div"
            count={presetStrings.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            labelRowsPerPage="פריטים לכל דף:"
            labelDisplayedRows={({ from, to, count }) => `${from}-${to === -1 ? count : to} מתוך ${count}`}
          />
        </Paper>
      </PageWrapper>
    )
  }
}

export default withRouter(PresetStringsAdminScreen)
