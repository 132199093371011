import React, { Component } from 'react'
import styled from 'styled-components'
import TrainersCarouselItem from '../HomeScreen/TrainersCarouselItem'
import OurTrainersCarouselItem from '../HomeScreen/OurTrainersCarouselItem'
import FeedbackCarouselItem from '../HomeScreen/FeedbackCarouselItem'

// Style
const MyTrainersWrapper = styled.div`
  z-index: 1;
  position: relative;
`
const Spacer = styled.div`
  height: 45px;
`

// Component
class MyTrainersScreen extends Component {
  // Lifecycle methods
  componentDidMount() {}

  // Render methods
  render() {
    return (
      <MyTrainersWrapper>
        <TrainersCarouselItem />
        <OurTrainersCarouselItem />
        <Spacer />
        <FeedbackCarouselItem customTop={68} />
      </MyTrainersWrapper>
    )
  }
}

export default MyTrainersScreen
