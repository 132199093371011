export function validURL(str) {
  // eslint-disable-next-line no-useless-escape
  const regex = /^(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/
  return regex.test(str)
}

/* Helper functions for redux-form field-based validation (inside <Field validate={} /> ) */
export const requiredValidation = value => (value != null && value !== '' ? undefined : 'שדה חובה')

export const maxLength = max => value => (value && value.length > max ? `Must be ${max} characters or less` : undefined)
export const maxLength15 = maxLength(15)

export const minLength = min => value => (value && value.length < min ? `Must be ${min} characters` : undefined)
export const minLength6 = minLength(6)

export const length = strictLength => value =>
  value && value.length !== strictLength ? `Must be ${strictLength} characters long` : undefined

export const numericValidation = value => (value && !/^[0-9]*$/.test(value) ? 'יש להזין ספרות בלבד' : undefined)

export const noSpacesValidation = value => (value && value.includes(' ') ? 'יש להזין ללא רווחים. אפשרי מקף תחתון' : undefined)

export const dateValidation = value => (value && value.isValid() === false ? 'תאריך לא תקין' : undefined)

export const emailValidation = value => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'דוא״ל לא תקין' : undefined)

export const zipCode = value => (value && !/(^\d{5}$)|(^\d{5}-*\d{4}$)/.test(value) ? 'Invalid ZIP code' : undefined)

export const phoneNumValidation = value => (value && !/^(0)*(\d){1,2}(-){0,1}\d{7,7}$/i.test(value) ? 'מספר טלפון שגוי' : undefined)

export const israelPhoneNumValidation = value => (value && !/^(\d){1,3}(-){0,1}\d{7,7}$/i.test(value) ? 'מספר טלפון שגוי' : undefined)
