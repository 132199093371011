import React, { Component } from 'react'
import styled from 'styled-components'
import discountStarIcon from '../images/discountStarIcon.png'
import { colorPalette } from '../styles/styleVariables'
import { mediaQuery } from '../services/global'
import { TRIAL_DAYS } from '../services/challengeProgressHelper'

// Style
const DiscountIconWrapperParent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  line-height: 1.2;
`
const DiscountIconWrapper = styled.div`
  position: relative;
  margin-top: 10px;
  display: inline-flex;
  transform: rotate(10deg);
  div {
    width: 130px;
    height: 130px;
    background: url(${discountStarIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 30px;
    bottom: 35px;
    left: 22px;
    right: 22px;
    font-size: 17px;
    color: ${colorPalette.black.main};
  }
`
const OriginalPriceLabel = styled.div`
  position: absolute;
  bottom: -18px;
  text-decoration: line-through;
  font-weight: 300;
  ${mediaQuery.phone`
    bottom: 0;
  `}
`

// Component
class ChallengePriceStar extends Component {
  state = {}

  // Lifecycle methods

  // UI event methods

  // Render methods
  render() {
    const { challengeFullPrice, challengePriceOriginal, currency } = this.props
    const currencyToDisplay = currency || 'ש״ח'
    // divide by 83 to get the price per day (as we have 90 days inside 90-days-challenge but we reduce 7 days of trial)
    return (
      <DiscountIconWrapperParent>
        <DiscountIconWrapper>
          <div></div>
          {challengeFullPrice && challengeFullPrice > 0 && (
            <span>
              רק {Math.round(challengeFullPrice / (90 - TRIAL_DAYS))} {currencyToDisplay} ליום
            </span>
          )}
        </DiscountIconWrapper>
        {challengePriceOriginal && (
          <OriginalPriceLabel>
            במקום {Math.round(challengePriceOriginal / (90 - TRIAL_DAYS))} {currencyToDisplay}
          </OriginalPriceLabel>
        )}
      </DiscountIconWrapperParent>
    )
  }
}

export default ChallengePriceStar
