import React, { Component } from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { colorPalette } from '../styles/styleVariables'
import { mediaQuery } from '../services/global'

// Style
const FullScreenWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 80;
`
const FullScreenBox = styled.div`
  position: relative;
  padding: 20px;
  padding-top: 55px;
  border-radius: 10px;
  background-color: ${colorPalette.primary1.main};
  ${mediaQuery.phone`
    height: 100%;
    width: 100%;
    margin: 0;
    overflow: auto;
    iframe {
      width: 100%;
    }
  `}
`
const ContentWrapper = styled.div`
  ${mediaQuery.phone`
    display: block;
    padding-top: env(safe-area-inset-top);
  `}
`
const CloseBtnWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 15px;
  svg {
    color: ${colorPalette.white.main};
  }
  ${mediaQuery.phone`
    margin-top: env(safe-area-inset-top);
  `}
`

// Component
class FullScreenDisplay extends Component {
  state = {}

  // Lifecycle methods

  // Helper methods

  // UI event methods

  // Render methods
  render() {
    const { children, handleClose } = this.props
    return (
      <FullScreenWrapper>
        <FullScreenBox>
          <CloseBtnWrapper onClick={handleClose}>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </CloseBtnWrapper>
          <ContentWrapper>{children}</ContentWrapper>
        </FullScreenBox>
      </FullScreenWrapper>
    )
  }
}

export default FullScreenDisplay
