import React, { Component } from 'react'
import styled from 'styled-components'
import { Link, withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import formBackgroundLeft from '../../images/formBackground_left.jpg'
import formBackgroundRight from '../../images/formBackground_right.jpg'
import { mediaQuery } from '../../services/global'
import { invokeAPIcall } from '../../services/rest'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import Breadcrumbs from '../Breadcrumbs'

// Consts

// Style
const PageWrapper = styled.div`
  background-color: #f5fcfd;
  background: url(${formBackgroundLeft}) left bottom no-repeat, url(${formBackgroundRight}) right bottom no-repeat, #f7fdfd;
  background-size: 40% auto, 35% auto;
  padding: 20px;
  flex: 1;
  ${mediaQuery.phone`
  background: none;
`}
`
const ItemList = styled.ul`
  width: 60%;
  margin: 0 auto;
`
const Item = styled.li`
  padding: 20px;
  margin-bottom: 20px;
  background-color: ${colorPalette.white.main};
  color: ${colorPalette.primary1.main};
  ${globalStyles.boxShadow}
  ${globalStyles.boxRadius}
`
const ReadMoreButton = styled(Button)`
  && {
    margin-top: 20px;
  }
`
const Title = styled.div`
  font-size: 30px;
  font-weight: 600;
`
const Desc = styled.div``
const EmptyListLabel = styled.div`
  text-align: center;
  margin-top: 40px;
  font-size: 30px;
`
const LoadingIndicatorWrapper = styled.div`
  margin: 50px auto;
  display: flex;
  justify-content: center;
`

// Component
class SearchScreen extends Component {
  state = {
    searchItems: [],
    isFetchingResults: false,
  }

  // Lifecycle methods
  componentDidMount() {
    this.loadSearchItems(this.props)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.searchTerm !== nextProps.match.params.searchTerm) {
      this.loadSearchItems(nextProps)
    }
  }

  // Helper methods
  loadSearchItems = async props => {
    const { match } = props
    const { params } = match

    this.setState({ isFetchingResults: true })
    const searchItems = await invokeAPIcall({ uri: `api/search?searchTerm=${params.searchTerm}` })
    this.setState({ searchItems, isFetchingResults: false })
  }

  // UI event methods

  // Render methods
  renderSearchItem = item => {
    return (
      <Item>
        <Link to={item.Url}>
          <Title>{item.Title}</Title>
        </Link>
        <Desc>{item.Desc}</Desc>
        <Link to={item.Url}>
          <ReadMoreButton variant="contained" color="primary">
            עבור לדף {'>'}
          </ReadMoreButton>
        </Link>
      </Item>
    )
  }

  render() {
    const { searchItems, isFetchingResults } = this.state
    const { match } = this.props
    const { params } = match
    return (
      <PageWrapper>
        <Breadcrumbs previousItems={[]} currentItem={`תוצאות חיפוש - ${params.searchTerm}`} />
        {isFetchingResults === false && searchItems.length > 0 && <ItemList>{searchItems.map(this.renderSearchItem)}</ItemList>}
        {isFetchingResults === false && searchItems.length === 0 && (
          <EmptyListLabel>לא נמצאו תוצאות חיפוש - ״{params.searchTerm}״</EmptyListLabel>
        )}
        {isFetchingResults === true && (
          <LoadingIndicatorWrapper>
            <CircularProgress />
          </LoadingIndicatorWrapper>
        )}
      </PageWrapper>
    )
  }
}

export default withRouter(SearchScreen)
