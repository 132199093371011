import React, { Component } from 'react'
import styled from 'styled-components'
import { Link, withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import formBackgroundLeft from '../../images/formBackground_left.jpg'
import formBackgroundRight from '../../images/formBackground_right.jpg'
import { mediaQuery } from '../../services/global'
import { invokeAPIcallWithFormData, invokeAPIcall } from '../../services/rest'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import Breadcrumbs from '../Breadcrumbs'

// Style
const PageWrapper = styled.div`
  background-color: #f5fcfd;
  background: url(${formBackgroundLeft}) left bottom no-repeat, url(${formBackgroundRight}) right bottom no-repeat, #f7fdfd;
  background-size: 40% auto, 35% auto;
  padding: 20px;
  flex: 1;
  ${mediaQuery.phone`
    background: none;
  `}
`
const FormWrapper = styled.div`
  background-color: ${colorPalette.white.main};
  max-width: 680px;
  margin: 0 auto;
  padding: 20px;
  color: ${colorPalette.primary1.main};
  ${globalStyles.boxShadow}
  ${globalStyles.boxRadius}
`
const PageTitle = styled.h1`
  margin-bottom: 20px;
`
const ResultMessageText = styled.h5`
  margin-bottom: 20px;
`
const LoadingIndicatorWrapper = styled.div`
  margin: 50px auto;
  display: flex;
  justify-content: center;
`
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const StyledButton = styled(Button)`
  && {
    font-size: 20px;
  }
`

// Component
class ActivateMyAccountScreen extends Component {
  state = {
    isLoading: true,
    resultMessage: null,
  }

  // Lifecycle methods
  componentDidMount() {
    this.invokeActivateApi()
  }

  // Helper methods
  invokeActivateApi = async () => {
    const { match } = this.props
    const { params } = match
    const data = {
      uniqueGuid: params.uniqueGuid,
    }

    // invoke API call
    try {
      const responseData = await invokeAPIcall({ uri: 'api/users/ActivateUser', data, method: 'PUT' })
      await this.fetchTokenAndLoginUser()

      this.setState({ resultMessage: responseData, isLoading: false })
    } catch (error) {
      console.log('error in Activate User', error)
    }
  }

  fetchTokenAndLoginUser = async () => {
    const { setAccessToken, setCurrentUser, openSnackbar, match } = this.props
    const { params } = match
    const loginValues = { email: params.uniqueGuid, password: 'uniqueGuidToken' } // send the uniqueGuidToken as the 'email' param

    try {
      // invoke API call
      const responseData = await invokeAPIcallWithFormData({
        uri: 'token',
        body: `grant_type=password&username=${loginValues.email}&password=${loginValues.password}`,
      })

      // if success
      if (!responseData.error) {
        setAccessToken({ accessToken: responseData.access_token })

        const currentUserData = await invokeAPIcall({ uri: 'api/users/getCurrentUser' })
        setCurrentUser({ user: currentUserData })
      }
      // if failed
      else {
        openSnackbar({ message: responseData.error_description, severity: 'failure' })
      }
    } catch (error) {
      console.log('error in login user', error)
    }
  }

  // Render methods
  render() {
    const { isLoading, resultMessage } = this.state
    return (
      <PageWrapper>
        <Breadcrumbs previousItems={[]} currentItem="הפעלת החשבון באתר" />
        <FormWrapper>
          <PageTitle>הפעלת החשבון באתר</PageTitle>
          {resultMessage && resultMessage.Key === true && (
            <div>
              <ResultMessageText>זכית להצטרף ל״חוויית החיטוב ב-90 יום״ של FitThyBody. חשבונך הופעל בהצלחה!</ResultMessageText>
              {/*resultMessage.UserTypeID === 1 && (
                <ButtonsWrapper>
                  <Link to="/challengePayment">
                    <StyledButton variant="contained" color="secondary">
                      לחוויית החיטוב ב-90 יום (עמוד התשלום)
                    </StyledButton>
                  </Link>
                </ButtonsWrapper>
              )*/}
              <ButtonsWrapper>
                <Link to="/personalStats">
                  <StyledButton variant="contained" color="secondary">
                    לשאלון האישי: חוויית החיטוב ב-90 יום
                  </StyledButton>
                </Link>
              </ButtonsWrapper>
            </div>
          )}
          {resultMessage && resultMessage.Key === false && <ResultMessageText>{resultMessage.Value}</ResultMessageText>}
          {isLoading && (
            <LoadingIndicatorWrapper>
              <CircularProgress />
            </LoadingIndicatorWrapper>
          )}
        </FormWrapper>
      </PageWrapper>
    )
  }
}

export default withRouter(ActivateMyAccountScreen)
