import React, { Component } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import { withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import formBackgroundLeft from '../../images/formBackground_left.jpg'
import formBackgroundRight from '../../images/formBackground_right.jpg'
import { mediaQuery, keysToLowerCase, photoUrlPrefix } from '../../services/global'
import { requiredValidation } from '../../services/formValidation'
import { invokeAPIcall } from '../../services/rest'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import StyledTextField from '../StyledTextField'
import StyledCheckbox from '../StyledCheckbox'
import Breadcrumbs from '../Breadcrumbs'

// Style
const PageWrapper = styled.div`
  background-color: #f5fcfd;
  background: url(${formBackgroundLeft}) left bottom no-repeat, url(${formBackgroundRight}) right bottom no-repeat, #f7fdfd;
  background-size: 40% auto, 35% auto;
  padding: 20px;
  flex: 1;
  ${mediaQuery.phone`
    background: none;
  `}
`
const FormWrapper = styled.div`
  background-color: ${colorPalette.white.main};
  max-width: 680px;
  margin: 0 auto;
  padding: 20px;
  color: ${colorPalette.primary1.main};
  ${globalStyles.boxShadow}
  ${globalStyles.boxRadius}
`
const PageTitle = styled.h1`
  margin-bottom: 20px;
`
const FieldsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
`
const FieldCell = styled.div`
  flex: 0 0 100%;
`
const PhotoFieldCell = styled(FieldCell)`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  ${mediaQuery.phone`
    flex-direction: column;
  `}
`
const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  margin-top: 20px;
  ${mediaQuery.phone`
    width: 100%;
  `}
`
const StyledButton = styled(Button)`
  && {
  }
`
const ItemPhoto = styled.div`
  width: 150px;
  height: 150px;
  background-image: url('${props => props.photoUrl}');
  background-position: center;
  background-size: cover;
  margin: auto;
`
const LoadingIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5px;
`

// Component
class AddBeforeAfterPhotoScreen extends Component {
  state = {
    initialValues: {
      description: '',
      feedbackText: '',
      isHighlighted: false,
    },
    addedPhotoFile: null,
  }

  // Lifecycle methods
  componentDidMount() {
    const editModeId = this.getEditModeId()
    if (editModeId != null) {
      this.loadPhotoData(editModeId)
    }
  }

  // Helper methods
  getEditModeId = () => {
    const { match } = this.props
    const { params } = match
    return params.id
  }

  loadPhotoData = async id => {
    let photoData = await invokeAPIcall({ uri: `api/beforeAfterPhotos/${id}` })
    photoData = keysToLowerCase(photoData)
    this.setState({
      initialValues: photoData,
    })
  }

  validate = values => {
    let errors = {}

    if (requiredValidation(values.description)) {
      errors.description = requiredValidation(values.description)
    }

    return errors
  }

  // UI event methods
  handleSubmit = async (values, { setSubmitting }) => {
    const editModeId = this.getEditModeId()
    const isEditMode = editModeId != null
    const { history } = this.props
    const data = {
      ...values,
    }

    // invoke API call
    try {
      const responseData = await invokeAPIcall({
        uri: isEditMode ? `api/beforeAfterPhotos/${editModeId}` : 'api/beforeAfterPhotos',
        data,
        method: isEditMode ? 'PUT' : 'POST',
      })

      // if API success
      if (responseData.Key === true) {
        // upload photo of item
        const files = document.querySelector('input[type="file"]').files
        if (files.length > 0) {
          const uploadData = new FormData()
          uploadData.append('file', files[0])
          uploadData.append('photoId', responseData.PhotoID)
          try {
            const uploadPhotoResponse = await fetch('https://www.fitthybody.com/fitthybodyAPI/api/beforeAfterPhotos/UploadPhoto', {
              method: 'POST',
              body: uploadData,
            })
            const uploadPhotoResponseJson = await uploadPhotoResponse.json()
            if (uploadPhotoResponseJson.Key === false) {
              alert(uploadPhotoResponseJson.Value)
            }
          } catch (error) {
            alert('שגיאה בהעלאת התמונה שנבחרה')
          }
        }

        history.push('/beforeAfterPhotosAdmin')
      }
      // if failed
      else {
        alert(responseData.Value || 'התרחשה שגיאה')
      }
    } catch (error) {
      console.log('error in adding/updating new photo', error)
    }
    setSubmitting(false)
  }

  handlePhotoFileChange = e => {
    if (e.target.files && e.target.files.length > 0) {
      // more than 4MB
      if (e.target.files[0].size > 4000000) {
        alert('משקל הקובץ גדול מדי: ' + e.target.files[0].size)
        return false
      }
    }
    this.setState({ addedPhotoFile: e.target.value })
  }

  // Render methods
  render() {
    const { initialValues } = this.state
    const editModeId = this.getEditModeId()
    const isEditMode = editModeId != null

    let photoUrl = null
    // for edit mode
    if (isEditMode) {
      photoUrl = initialValues.photoURL
      if (photoUrl && !photoUrl.includes('http')) {
        photoUrl = `${photoUrlPrefix}/beforeAfterPhotos/${photoUrl}`
      }
    }
    // if the user uploaded a photo
    const files = document.querySelector('input[type="file"]') && document.querySelector('input[type="file"]').files
    if (files && files.length > 0) {
      photoUrl = URL.createObjectURL(files[0])
    }
    return (
      <PageWrapper>
        <Breadcrumbs
          previousItems={[
            {
              label: `מסכי ניהול`,
              url: `/adminPanel`,
            },
          ]}
          currentItem={`ניהול תמונות לפני/אחרי`}
        />
        <FormWrapper>
          <PageTitle>{isEditMode ? 'עדכון תמונה' : 'הוספת תמונה חדשה'}</PageTitle>
          <Formik validate={this.validate} initialValues={initialValues} onSubmit={this.handleSubmit} enableReinitialize={true}>
            {formikBag => {
              const { isSubmitting, values, errors, touched, handleChange, handleBlur, isValid, setFieldValue } = formikBag
              return (
                <Form>
                  <FieldsRow>
                    <FieldCell>
                      <StyledTextField
                        label={'שם התמונה'}
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.description && touched.description && errors.description}
                        error={errors.description && touched.description}
                        margin="normal"
                        fullWidth
                      />
                    </FieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <FieldCell>
                      <StyledCheckbox
                        label={'האם יופיע בלקוחות מרוצים?'}
                        name="isHighlighted"
                        value={values.isHighlighted}
                        checked={values.isHighlighted}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.isHighlighted && touched.isHighlighted && errors.isHighlighted}
                        error={errors.isHighlighted && touched.isHighlighted}
                        margin="normal"
                        fullWidth
                      />
                    </FieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <FieldCell>
                      <StyledTextField
                        label={'חוות דעת שתופיע בראשי'}
                        name="feedbackText"
                        value={values.feedbackText}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.feedbackText && touched.feedbackText && errors.feedbackText}
                        error={errors.feedbackText && touched.feedbackText}
                        margin="normal"
                        fullWidth
                        multiline
                        rows="4"
                      />
                    </FieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <PhotoFieldCell>
                      <div>
                        <div>תמונת לפני/אחרי:</div>
                        <input
                          type="file"
                          onChange={e => {
                            setFieldValue('addedPhoto', true, false)
                            this.handlePhotoFileChange(e)
                          }}
                        />
                      </div>
                      {photoUrl && <ItemPhoto photoUrl={photoUrl} />}
                    </PhotoFieldCell>
                  </FieldsRow>
                  <ButtonsWrapper>
                    <StyledButton variant="contained" color="primary" fullWidth disabled={!isValid || isSubmitting} type="submit">
                      {isEditMode ? 'עדכן' : 'הוסף'}
                    </StyledButton>
                  </ButtonsWrapper>
                  {isSubmitting && (
                    <LoadingIndicatorWrapper>
                      <CircularProgress />
                    </LoadingIndicatorWrapper>
                  )}
                </Form>
              )
            }}
          </Formik>
        </FormWrapper>
      </PageWrapper>
    )
  }
}

export default withRouter(AddBeforeAfterPhotoScreen)
