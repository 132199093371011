export const dayTimeOptions = [
  { label: 'ארוחת בוקר', value: 1 },
  { label: 'נשנוש', value: 2 },
  { label: 'ארוחת צוהריים', value: 3 },
  { label: 'ארוחת ערב', value: 4 },
]

export const foodTypeOptions = [
  { label: 'טבעוני', value: 1 },
  { label: 'צמחוני', value: 2 },
  { label: 'פליאו', value: 3 },
  { label: 'קלסי וים-תיכוני', value: 4 },
  //next options will be multiselect (unlike the first 4 options)
  { label: 'נטול גלוטן', value: 6 },
  { label: 'נטול לקטוז', value: 7 },
  { label: 'לסוכר גבוה', value: 8 },
  { label: 'לכולסטרול גבוה', value: 9 },
  { label: 'ללחץ דם גבוה', value: 10 },
]

export const filterMealsByFoodTypeOfUser = (personalStats, meals) => {
  const startStats = personalStats && personalStats.filter(item => item.Phase === 'start')
  if (startStats && startStats[0] && startStats[0].FoodType) {
    // filter the meals by FoodType (based on the user's preferences)
    const foodTypeOfUser = startStats[0].FoodType.split(',').map(item => item.trim())
    meals = meals.filter(meal => foodTypeOfUser.every(item => meal.FoodTypeIDs.split(',').includes(item)))
  }
  return meals
}
