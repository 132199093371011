import React, { Component } from 'react'
import styled from 'styled-components'
import 'moment/locale/he'
import CircularProgress from '@material-ui/core/CircularProgress'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Clear'
import PullToRefresh from 'pulltorefreshjs'
import ReactDOMServer from 'react-dom/server'
import { BrowserRouter, HashRouter, Route, Switch, Link } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import { mediaQuery, getQuerystringVal } from '../services/global'
import { invokeAPIcall } from '../services/rest'
import { presetStringsForOffline } from '../services/presetStringsConst'
import { colorPalette, GlobalStyle, globalStyles } from '../styles/styleVariables'
import fitthybodyLogo from '../images/fitthybodyLogo.png'
import MainMenu from '../components/MainMenu'
import BottomBar from '../components/BottomBar'
import UserSignButtons from '../containers/UserSignButtonsContainer'
import ChallengeButton from '../containers/ChallengeButtonContainer'
import LangSelector from '../components/LangSelector'
import AccessibilityMenu from '../components/AccessibilityMenu'
import SocialShareMenu from '../containers/SocialShareMenuContainer'
import SnackbarMessage from '../containers/SnackbarMessageContainer'
import StoreButton from '../containers/StoreButtonContainer'
import HomeScreen from '../containers/HomeScreenContainer'
import RegisterScreen from '../containers/RegisterScreenContainer'
import MyTrainersScreen from '../components/MyTrainersScreen'
import ForgotPasswordScreen from '../containers/ForgotPasswordScreenContainer'
import SetNewPasswordScreen from '../containers/SetNewPasswordScreenContainer'
import SetNewPasswordFromExistingScreen from '../containers/SetNewPasswordFromExistingScreenContainer'
import ActivateMyAccountScreen from '../containers/ActivateMyAccountScreenContainer'
import PersonalStatsScreen from '../containers/PersonalStatsScreenContainer'
import ProgramScreen from '../containers/ProgramScreenContainer'
import ChallengePaymentScreen from '../containers/ChallengePaymentScreenContainer'
import ChallengeIntroScreen from '../containers/ChallengeIntroScreenContainer'
import MyWorkoutsScreen from '../containers/MyWorkoutsScreenContainer'
import MySuccessWayScreen from '../components/MySuccessWayScreen'
import MyMealsScreen from '../containers/MyMealsScreenContainer'
import MyDailyScheduleScreen from '../containers/MyDailyScheduleScreenContainer'
import MyPersonalStatsProfileScreen from '../containers/MyPersonalStatsProfileScreenContainer'
import StoreScreen from '../containers/StoreScreenContainer'
import ShoppingCartScreen from '../containers/ShoppingCartScreenContainer'
import CartAfterPaymentScreen from '../containers/CartAfterPaymentScreenContainer'
import ChallengeAfterPaymentScreen from '../containers/ChallengeAfterPaymentScreenContainer'
import ProductsAdminScreen from '../components/ProductsAdminScreen'
import ProductsAdminAddScreen from '../components/ProductsAdminScreen/AddScreen'
import ProductDetailsScreen from '../containers/ProductDetailsScreenContainer'
import MealDetailsScreen from '../containers/MealDetailsScreenContainer'
import ArticlesAdminScreen from '../components/ArticlesAdminScreen'
import ArticlesAdminAddScreen from '../components/ArticlesAdminScreen/AddScreen'
import ContentPagesAdminScreen from '../components/ContentPagesAdminScreen'
import ContentPagesAdminAddScreen from '../components/ContentPagesAdminScreen/AddScreen'
import CouponsAdminScreen from '../components/CouponsAdminScreen'
import CouponsAdminAddScreen from '../components/CouponsAdminScreen/AddScreen'
import GlobalSettingsAdminScreen from '../components/GlobalSettingsAdminScreen'
import MonthlyInstallmentsLevelsAdminScreen from '../components/MonthlyInstallmentsLevelsAdminScreen'
import OrdersAdminScreen from '../components/OrdersAdminScreen'
import BeforeAfterPhotosAdminScreen from '../components/BeforeAfterPhotosAdminScreen'
import BeforeAfterPhotosAdminAddScreen from '../components/BeforeAfterPhotosAdminScreen/AddScreen'
import PresetStringsAdminScreen from '../components/PresetStringsAdminScreen'
import PresetStringsAdminAddScreen from '../components/PresetStringsAdminScreen/AddScreen'
import RecipesAdminScreen from '../components/RecipesAdminScreen'
import RecipesAdminAddScreen from '../components/RecipesAdminScreen/AddScreen'
import FeedbackMessagesAdminScreen from '../components/FeedbackMessagesAdminScreen'
import FeedbackMessagesAdminAddScreen from '../components/FeedbackMessagesAdminScreen/AddScreen'
import WorkoutVideosAdminScreen from '../components/WorkoutVideosAdminScreen'
import WorkoutVideosAdminAddScreen from '../components/WorkoutVideosAdminScreen/AddScreen'
import UsersAdminScreen from '../components/UsersAdminScreen'
import ArticlesScreen from '../components/ArticlesScreen'
import ArticleViewScreen from '../components/ArticlesScreen/ArticleViewScreen'
import ContentPageScreen from '../components/ContentPageScreen'
import ContactUsScreen from '../containers/ContactUsScreenContainer'
import PhoneCallFeedbackScreen from '../containers/PhoneCallFeedbackScreenContainer'
import AdminPanelScreen from '../containers/AdminPanelScreenContainer'
import SearchScreen from '../components/SearchScreen'
import SearchBar from '../components/SearchBar'
import ChatRobotBox from '../components/ChatRobotBox'
import SlideToTopButton from '../components/SlideToTopButton'
import ProgramProgressBar from '../containers/ProgramProgressBarContainer'
import WizardDialog from '../components/WizardDialog'
import ScrollToTop from '../components/ScrollToTop'
import InAppPurchaseStore from '../containers/InAppPurchaseStoreContainer'

// Style
const TitleBar = styled.div`
  display: flex;
  align-items: flex-end;
  height: 115px;
  border-bottom-left-radius: ${globalStyles.mainBoxRadius};
  border-bottom-right-radius: ${globalStyles.mainBoxRadius};
  box-shadow: ${globalStyles.mainBoxShadow};
  padding: 0;
  padding-right: 1em;
  padding-bottom: 16px;
  background-color: ${colorPalette.white.main};
  color: ${colorPalette.white.main};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11;
  ${mediaQuery.phone`
    height: auto;
    min-height: 88px;
    padding-top: env(safe-area-inset-top);
    position: fixed;
  `}
`
const Logo = styled.div`
  background-image: url(${fitthybodyLogo});
  background-size: cover;
  background-repeat: no-repeat;
  width: 303px;
  height: 92px;
  margin-right: 0;
  margin-left: 12px;
  margin-top: 5px;
  text-align: center;

  @media (max-width: 80em) {
    width: 201px;
    height: 61px;
  }

  ${mediaQuery.phone`
    width: 201px;
    height: 61px;
  `}

  @media (max-width: 20em) {
    width: 150px;
    height: 45px;
  }
`
const MenuIconButton = styled.div`
  display: none;
  margin-right: auto;
  cursor: pointer;
  color: ${colorPalette.primary1.main};
  ${mediaQuery.phone`
    margin-left: 20px;
    display: block;
  `}
`
const Content = styled.div`
  background-color: ${colorPalette.white.main};
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 85px;
  ${mediaQuery.phone`
    margin-top: 65px;
    padding-top: env(safe-area-inset-top);
  `}
`
const StyledMenuIcon = styled(MenuIcon)`
  && {
    width: 40px;
    height: 40px;
  }
`
const DesktopMainMenuWrapper = styled.div`
  display: block;
  ${mediaQuery.phone`
    display: none;
  `}
`
const MobileMenuWrapper = styled.div`
  background-color: ${colorPalette.white.main};
  color: ${colorPalette.primary1.main};
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  overflow: hidden;
  transition: height 300ms ease-in-out 0s;
  height: ${props => (props.isOpen ? '100vh' : '0')};
`
const StyledCloseIcon = styled(CloseIcon)`
  && {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
`
const MenuColoredBar = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colorPalette.primary1.main};
  color: ${colorPalette.white.main};
  flex: 1;
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
  max-height: 44px;
  margin-bottom: 12px;
  ${mediaQuery.phone`
    background-color: ${colorPalette.white.main};
    color: ${colorPalette.primary1.main};
    flex-direction: row-reverse;
    margin-bottom: 8px;
  `}
`
const MobileProgressBarPlaceholder = styled.div`
  display: none;
  ${mediaQuery.phone`
    display: block;
    height: 40px;
  `}
`
const MobilePullDownToRefreshIndicator = styled.div`
  display: none;
  justify-content: center;
  margin-top: -30px;
  margin-bottom: -10px;
  padding-top: 4px;
  ${mediaQuery.phone`
    display: flex;
  `}
`

if (window.location.protocol !== 'https:' && !window.cordova) {
  window.location.protocol = 'https:'
}

// Component
class AppRouter extends Component {
  state = {
    isMobileMenuOpen: false,
    globalSettingsData: {},
    presetStringListLoaded: false,
  }

  // Lifecycle methods
  componentDidMount() {
    this.loadPresetStringList()

    // load couponCode from querystring (if there is one) - save in sessionStorage
    const couponCode = getQuerystringVal('couponCode')
    if (couponCode) {
      sessionStorage.setItem('couponCode', couponCode)
    }

    if (window.cordova) {
      window.open = window.cordova.InAppBrowser.open

      // init pull-down-to-refresh on mobile cordova
      PullToRefresh.init({
        mainElement: 'body',
        onRefresh() {
          window.location.reload()
        },
        iconArrow: ReactDOMServer.renderToString(<CircularProgress />),
        iconRefreshing: ReactDOMServer.renderToString(<CircularProgress />),
        shouldPullToRefresh: () => {
          return !window.scrollY && document.getElementById('videoPlayer') == null
        },
      })
    }

    this.updateUserCordovaPlatform()
    this.sendReminderEmailsToUsers()
  }

  // Helper methods
  loadPresetStringList = async () => {
    // for cordova, set the PresetStrings even before we invoke API, to be working in offline mode too
    //if (window.cordova) {
    this.setPresetStringsList(presetStringsForOffline)
    //}

    // invoke API call to get updated 'presetStrings' from the backend
    const presetStringsResult = await invokeAPIcall({
      uri: 'api/presetStrings',
    })
    if (presetStringsResult && presetStringsResult.length) {
      this.setPresetStringsList(presetStringsResult)
    }
  }

  setPresetStringsList = presetStringsResult => {
    const { setPresetStrings } = this.props
    const presetStringList = presetStringsResult.reduce((result, item) => {
      return { ...result, [item.CodeKey]: { hebrew: item.HebrewText } }
    }, {})
    setPresetStrings({ presetStringList })
    this.setState({
      presetStringListLoaded: true,
    })
  }

  updateUserCordovaPlatform = async () => {
    let cordovaPlatform = null
    if (window.cordova && window.device) {
      cordovaPlatform = window.device.platform.toLowerCase()
    }
    // invoke API call to change the user's challange progress day
    try {
      await invokeAPIcall({
        uri: `api/users/UpdateUserCordovaPlatform?cordovaPlatform=${cordovaPlatform}`,
        data: {},
        method: 'PUT',
      })
    } catch (error) { }
  }

  sendReminderEmailsToUsers = async () => {
    // invoke API call
    try {
      await invokeAPIcall({ uri: 'api/users/SendReminderEmailsToUsers', data: {}, method: 'PUT' })
    } catch (error) {
      console.log('error in SendReminderEmailsToUsers', error)
    }
  }

  // UI event methods
  handleMenuClick = () => {
    this.setState({ isMobileMenuOpen: true })
  }

  handleMenuCloseClick = () => {
    this.setState({ isMobileMenuOpen: false })
  }

  handleMenuItemClick = () => {
    this.setState({ isMobileMenuOpen: false })
  }

  handleLogoClickInCordova = e => {
    if (window.location.hash === '#/') {
      window.location.reload()
      e.stopPropagation()
      return false
    } else {
      window.location.assign('#/')
    }
  }

  render() {
    const { isMobileMenuOpen, presetStringListLoaded } = this.state
    const RouterElement = window.cordova ? HashRouter : BrowserRouter

    return (
      <>
        {presetStringListLoaded && (
          <RouterElement basename="">
            <ScrollToTop />
            {window.cordova && <InAppPurchaseStore />}
            <TitleBar>
              {window.cordova && (
                <div onClick={this.handleLogoClickInCordova}>
                  <Logo />
                </div>
              )}
              {!window.cordova && (
                <a href="/">
                  <Logo />
                </a>
              )}
              <MenuColoredBar>
                <DesktopMainMenuWrapper>
                  <MainMenu handleMenuItemClick={this.handleMenuItemClick} />
                </DesktopMainMenuWrapper>
                <ChallengeButton />
                <SearchBar />
                <StoreButton />
                <UserSignButtons />
                <LangSelector />
                <SocialShareMenu />
                <AccessibilityMenu />
              </MenuColoredBar>
              <MenuIconButton onClick={this.handleMenuClick}>
                <StyledMenuIcon />
              </MenuIconButton>
            </TitleBar>
            <Content id="mainAppContentEl">
              <Route path="/(myWorkoutVideos|myMeals|myDailySchedule|mySuccessWay|myPersonalStatsProfile|program)">
                <ProgramProgressBar />
                <MobileProgressBarPlaceholder />
                <WizardDialog />
              </Route>
              {window.cordova && (
                <MobilePullDownToRefreshIndicator>
                  <CircularProgress />
                </MobilePullDownToRefreshIndicator>
              )}
              <Switch>
                <Route path="/workoutTraining">workoutTraining</Route>
                <Route path="/articles">
                  <ArticlesScreen />
                </Route>
                <Route path="/article/:id?">
                  <ArticleViewScreen />
                </Route>
                <Route path="/contentPage/:pageKey?">
                  <ContentPageScreen />
                </Route>
                <Route path="/store/:category?">
                  <StoreScreen />
                </Route>
                <Route path="/myTrainers">
                  <MyTrainersScreen />
                </Route>
                <Route path="/program">
                  <ProgramScreen />
                </Route>
                <Route path="/challengePayment/:packageNum?">
                  <ChallengePaymentScreen />
                </Route>
                <Route path="/myWorkoutVideos/:id">
                  <MyWorkoutsScreen />
                </Route>
                <Route path="/myWorkoutVideos">
                  <MyWorkoutsScreen />
                </Route>
                <Route path="/mySuccessWay">
                  <MySuccessWayScreen />
                </Route>
                <Route path="/myPersonalStatsProfile">
                  <MyPersonalStatsProfileScreen />
                </Route>
                <Route path="/myMeals/byDayTime/:dayTime">
                  <MyMealsScreen />
                </Route>
                <Route path="/myMeals/:id">
                  <MealDetailsScreen />
                </Route>
                <Route path="/myMeals">
                  <MyMealsScreen />
                </Route>
                <Route path="/myDailySchedule/myMeals/:id">
                  <MealDetailsScreen />
                </Route>
                <Route path="/myDailySchedule">
                  <MyDailyScheduleScreen />
                </Route>
                <Route path="/registerToChallenge/:mode?">
                  <RegisterScreen />
                </Route>
                <Route path="/personalStats">
                  <PersonalStatsScreen />
                </Route>
                <Route path="/myCart">
                  <ShoppingCartScreen />
                </Route>
                <Route path="/myCartAfterPayment">
                  <CartAfterPaymentScreen />
                </Route>
                <Route path="/challengeAfterPayment">
                  <ChallengeAfterPaymentScreen />
                </Route>
                <Route path="/challengeIntro">
                  <ChallengeIntroScreen />
                </Route>
                <Route path="/myProfile">
                  <RegisterScreen editMode />
                </Route>
                <Route path="/forgotPassword">
                  <ForgotPasswordScreen />
                </Route>
                <Route path="/setNewPassword/:uniqueGuid?">
                  <SetNewPasswordScreen />
                </Route>
                <Route path="/setNewPasswordFromExisting">
                  <SetNewPasswordFromExistingScreen />
                </Route>
                <Route path="/activateMyAccount/:uniqueGuid?">
                  <ActivateMyAccountScreen />
                </Route>
                <Route path="/productsAdmin">
                  <ProductsAdminScreen />
                </Route>
                <Route path="/productsAdminAdd/:id?">
                  <ProductsAdminAddScreen />
                </Route>
                <Route path="/articlesAdmin">
                  <ArticlesAdminScreen />
                </Route>
                <Route path="/articlesAdminAdd/:id?">
                  <ArticlesAdminAddScreen />
                </Route>
                <Route path="/contentPagesAdmin">
                  <ContentPagesAdminScreen />
                </Route>
                <Route path="/contentPagesAdminAdd/:id?">
                  <ContentPagesAdminAddScreen />
                </Route>
                <Route path="/couponsAdmin">
                  <CouponsAdminScreen />
                </Route>
                <Route path="/couponsAdminAdd/:id?">
                  <CouponsAdminAddScreen />
                </Route>
                <Route path="/globalSettingsAdmin">
                  <GlobalSettingsAdminScreen />
                </Route>
                <Route path="/monthlyInstallmentsLevelsAdmin">
                  <MonthlyInstallmentsLevelsAdminScreen />
                </Route>
                <Route path="/ordersAdmin">
                  <OrdersAdminScreen />
                </Route>
                <Route path="/beforeAfterPhotosAdmin">
                  <BeforeAfterPhotosAdminScreen />
                </Route>
                <Route path="/beforeAfterPhotosAdminAdd/:id?">
                  <BeforeAfterPhotosAdminAddScreen />
                </Route>
                <Route path="/presetStringsAdmin">
                  <PresetStringsAdminScreen />
                </Route>
                <Route path="/presetStringsAdminAdd/:id?">
                  <PresetStringsAdminAddScreen />
                </Route>
                <Route path="/recipesAdmin">
                  <RecipesAdminScreen />
                </Route>
                <Route path="/recipesAdminAdd/:id?">
                  <RecipesAdminAddScreen />
                </Route>
                <Route path="/workoutVideosAdmin">
                  <WorkoutVideosAdminScreen />
                </Route>
                <Route path="/workoutVideosAdminAdd/:id?">
                  <WorkoutVideosAdminAddScreen />
                </Route>
                <Route path="/usersAdmin">
                  <UsersAdminScreen />
                </Route>
                <Route path="/feedbackMessagesAdmin">
                  <FeedbackMessagesAdminScreen />
                </Route>
                <Route path="/feedbackMessagesAdminAdd/:id?">
                  <FeedbackMessagesAdminAddScreen />
                </Route>
                <Route path="/productDetails/:urlCode?">
                  <ProductDetailsScreen />
                </Route>
                <Route path="/contactUs/feedback">
                  <ContactUsScreen feedbackMode />
                </Route>
                <Route path="/contactUs">
                  <ContactUsScreen />
                </Route>
                <Route path="/phoneCallFeedback">
                  <PhoneCallFeedbackScreen />
                </Route>
                <Route path="/search/:searchTerm">
                  <SearchScreen />
                </Route>
                <Route path="/adminPanel">
                  <AdminPanelScreen />
                </Route>
                <Route path="/">
                  <HomeScreen />
                </Route>
              </Switch>
            </Content>
            <BottomBar />
            <ChatRobotBox />
            <SlideToTopButton />
            <MobileMenuWrapper isOpen={isMobileMenuOpen}>
              <MainMenu handleMenuItemClick={this.handleMenuItemClick} />
              <StyledCloseIcon onClick={this.handleMenuCloseClick} />
            </MobileMenuWrapper>
            <SnackbarMessage />
          </RouterElement>
        )}
      </>
    )
  }
}

export default AppRouter
