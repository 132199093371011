// Actions
const SET_PRESET_STRINGS = 'presetStrings/setPresetStrings'

// Reducer
const initialState = {
  presetStringList: null,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_PRESET_STRINGS:
      return {
        ...state,
        presetStringList: action.payload.presetStringList,
      }
    default:
      return state
  }
}

// Action Creators
export function setPresetStrings({ presetStringList }) {
  return {
    type: SET_PRESET_STRINGS,
    payload: { presetStringList },
  }
}
