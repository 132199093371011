import React from 'react'
import storeObj from './store'

export function getString(stringKey) {
  // grab current state
  const state = storeObj.store.getState()

  const { presetStringList } = state.presetStrings
  if (presetStringList && presetStringList[stringKey]) {
    return presetStringList[stringKey].hebrew
  }
  return ''
}

export function multilineHtmlText(str) {
  return str.split('\n').map(function(item, idx) {
    return (
      <span key={idx}>
        {item}
        <br />
      </span>
    )
  })
}
