import React, { Component } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import { invokeAPIcall } from '../../services/rest'
import { mediaQuery } from '../../services/global'
import StyledTextField from '../StyledTextField'

// Consts

// Style
const StyledDialogTitle = styled(DialogTitle)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
    min-width: 450px;
  }
  ${mediaQuery.phone`
    && {
      min-width: 0;
    }
  `}
`
const StyledButton = styled(Button)`
  && {
    margin-left: 10px;
  }
  ${mediaQuery.phone`
    && {
      margin-left: 0;
      margin-bottom: 10px;
      flex: 1;
    }
  `}
`
const ButtonsWrapper = styled.div`
  display: flex;
  margin: 20px;
  a {
    display: flex;
  }
  ${mediaQuery.phone`
    flex-direction: column;
  `}
`

// Component
class SendRecipeEmailDialog extends Component {
  state = {
    emailMode: false,
    emailAddress: '',
  }

  // Lifecycle methods
  componentDidMount() {}

  // Helper methods

  // UI event methods
  handleSendToEmailClick = async () => {
    const { mealData, mealDayTime, handleClose, openSnackbar } = this.props
    const { emailMode, emailAddress } = this.state

    // if we're in EmailMode - send the email
    if (emailMode) {
      if (!emailAddress) {
        return
      }

      handleClose()

      let messageBody = `<u>סוג:</u> ${mealDayTime}<br />`
      messageBody += `<u>כמות המנות:</u> ${mealData.AmountOfMeals || '-'}<br />`
      messageBody += `<u>זמן עבודה (בדקות):</u> ${mealData.DurationInMins || '-'}<br /><br />`
      messageBody += '<u>מצרכים:</u><br />'
      messageBody += mealData.RecipeIngredients.map(item => `* ${item.IngredientName}`).join('<br />')
      messageBody += '<br /><br /><u>הוראות הכנה:</u><br />'
      messageBody += mealData.InstructionsText.split('\n').join('<br />')

      // invoke API call
      try {
        const data = {
          email: emailAddress,
          messageSubject: `FitThyBody - חוויית החיטוב ב-90 יום: מתכון ${mealData.Name}`,
          messageBody,
        }
        const responseData = await invokeAPIcall({
          uri: 'api/recipes/SendRecipeEmail',
          data,
          method: 'POST',
        })

        // if API success
        if (responseData.Key === true) {
          openSnackbar({ message: 'המייל נשלח בהצלחה', severity: 'success' })
        }
        // if failed
        else {
          openSnackbar({ message: responseData.Value, severity: 'failure' })
        }
      } catch (error) {
        console.log('error in sending email', error)
      }
    }
    // if it's not EmailMode - change to EmailMode now
    else {
      this.setState({ emailMode: true })
    }
  }

  handleEmailChange = e => {
    this.setState({ emailAddress: e.target.value })
  }

  // Render methods
  render() {
    const { emailMode, emailAddress } = this.state
    const { mealData, mealDayTime, handleClose } = this.props

    let whatsappText = `FitThyBody - חוויית החיטוב ב-90 יום: מתכון ${mealData.Name}%0a%0a`
    whatsappText += `סוג: ${mealDayTime}%0a`
    whatsappText += `כמות המנות: ${mealData.AmountOfMeals || '-'}%0a`
    whatsappText += `זמן עבודה (בדקות): ${mealData.DurationInMins || '-'}%0a%0a`
    whatsappText += 'מצרכים:%0a'
    whatsappText += mealData.RecipeIngredients.map(item => `* ${item.IngredientName}`).join('%0a')
    whatsappText += '%0a%0aהוראות הכנה:%0a'
    whatsappText += mealData.InstructionsText.split('\n').join('%0a')

    return (
      <Dialog onClose={handleClose} open>
        <StyledDialogTitle id="simple-dialog-title" disableTypography>
          <Typography variant="h6">שיתוף המתכון</Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <DialogContent>
          {emailMode && (
            <div>
              <StyledTextField
                label="הזן כתובת מייל"
                name="emailAddress"
                value={emailAddress}
                onChange={this.handleEmailChange}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: emailAddress !== null }}
              />
            </div>
          )}
        </DialogContent>
        <ButtonsWrapper>
          <StyledButton variant="contained" color="primary" onClick={this.handleSendToEmailClick}>
            שליחה למייל
          </StyledButton>
          {!emailMode && (
            <>
              <a
                onClick={() => {
                  window.open(`https://api.whatsapp.com/send?text=${whatsappText}`, window.cordova ? '_system' : '_blank')
                }}
              >
                <StyledButton variant="contained" color="primary">
                  שליחה לוואטסאפ
                </StyledButton>
              </a>
            </>
          )}
        </ButtonsWrapper>
      </Dialog>
    )
  }
}

export default withRouter(SendRecipeEmailDialog)
