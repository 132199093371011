import React, { Component } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import { invokeAPIcall } from '../../services/rest'
import { mediaQuery } from '../../services/global'
import StyledTextField from '../StyledTextField'

// Consts

// Style
const StyledDialogTitle = styled(DialogTitle)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
    min-width: 450px;
  }
  ${mediaQuery.phone`
    && {
      min-width: 0;
    }
  `}
`
const StyledButton = styled(Button)`
  && {
    margin-left: 10px;
  }
  ${mediaQuery.phone`
    && {
      margin-left: 0;
      margin-bottom: 10px;
      flex: 1;
    }
  `}
`
const ButtonsWrapper = styled.div`
  display: flex;
  margin: 20px;
  a {
    display: flex;
  }
  ${mediaQuery.phone`
    flex-direction: column;
  `}
`
const IngredientsList = styled.ul`
  list-style-type: disc;
  margin: 0 30px;
`

// Component
class SendIngredientsListDialog extends Component {
  state = {
    ingredientsList: [],
    emailMode: false,
    emailAddress: '',
  }

  // Lifecycle methods
  componentDidMount() {
    this.loadIngredientNames()
  }

  // Helper methods
  loadIngredientNames = async () => {
    const { getIngredientsSavedList } = this.props
    const ingredientsSavedList = getIngredientsSavedList()

    const idsParam = ingredientsSavedList.reduce((result, item, index) => {
      return `${result}ids=${item}${index < ingredientsSavedList.length - 1 ? '&' : ''}`
    }, '')
    const ingredientsList = await invokeAPIcall({ uri: `api/recipes/getIngredientsByIDs?${idsParam}` })
    this.setState({ ingredientsList })
  }

  // UI event methods
  handleEmptyList = () => {
    const { ingredientsSavedListKey, handleClose } = this.props
    localStorage.removeItem(ingredientsSavedListKey)
    handleClose()
  }

  handleSendToEmailClick = async () => {
    const { handleClose, openSnackbar } = this.props
    const { emailMode, emailAddress, ingredientsList } = this.state

    // if we're in EmailMode - send the email
    if (emailMode) {
      if (!emailAddress) {
        return
      }

      handleClose()

      // invoke API call
      try {
        const data = {
          email: emailAddress,
          messageSubject: 'FitThyBody - חוויית החיטוב ב-90 יום: רשימת קניות',
          messageBody: ingredientsList.reduce((result, item) => {
            return `${result}<br />* ${item.IngredientName}`
          }, ''),
        }
        const responseData = await invokeAPIcall({
          uri: 'api/recipes/SendRecipeEmail',
          data,
          method: 'POST',
        })

        // if API success
        if (responseData.Key === true) {
          openSnackbar({ message: 'המייל נשלח בהצלחה', severity: 'success' })
        }
        // if failed
        else {
          openSnackbar({ message: responseData.Value, severity: 'failure' })
        }
      } catch (error) {
        console.log('error in sending email', error)
      }
    }
    // if it's not EmailMode - change to EmailMode now
    else {
      this.setState({ emailMode: true })
    }
  }

  handleEmailChange = e => {
    this.setState({ emailAddress: e.target.value })
  }

  // Render methods
  render() {
    const { ingredientsList, emailMode, emailAddress } = this.state
    const ingredientListBody = ingredientsList.reduce((result, item) => {
      return `${result}%0a${item.IngredientName}`
    }, '')

    const { handleClose } = this.props
    return (
      <Dialog onClose={handleClose} open>
        <StyledDialogTitle id="simple-dialog-title" disableTypography>
          <Typography variant="h6">מצרכים ברשימת קניות</Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <DialogContent>
          {!emailMode && (
            <IngredientsList>
              {ingredientsList.map((item, index) => (
                <li>{item.IngredientName}</li>
              ))}
            </IngredientsList>
          )}
          {emailMode && (
            <div>
              <StyledTextField
                label="הזן כתובת מייל"
                name="emailAddress"
                value={emailAddress}
                onChange={this.handleEmailChange}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: emailAddress !== null }}
              />
            </div>
          )}
        </DialogContent>
        <ButtonsWrapper>
          <StyledButton variant="contained" color="primary" onClick={this.handleSendToEmailClick}>
            שליחה למייל
          </StyledButton>
          {!emailMode && (
            <>
              <a
                onClick={() => {
                  window.open(`https://api.whatsapp.com/send?text=${ingredientListBody}`, window.cordova ? '_system' : '_blank')
                }}
              >
                <StyledButton variant="contained" color="primary">
                  שליחה לוואטסאפ
                </StyledButton>
              </a>
              <StyledButton variant="contained" onClick={this.handleEmptyList}>
                איפוס הרשימה
              </StyledButton>
            </>
          )}
        </ButtonsWrapper>
      </Dialog>
    )
  }
}

export default withRouter(SendIngredientsListDialog)
