import { connect } from 'react-redux'
import RegisterScreen from '../components/RegisterScreen'
import { setAccessToken, setCurrentUser } from '../bundles/currentUser'
import { openSnackbar } from '../bundles/snackbar'

export default connect(
  state => ({
    currentUser: state.currentUser.user,
  }),
  {
    setAccessToken,
    setCurrentUser,
    openSnackbar,
  },
)(RegisterScreen)
