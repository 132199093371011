import { connect } from 'react-redux'
import SocialShareMenu from '../components/SocialShareMenu'
import { openSnackbar } from '../bundles/snackbar'

export default connect(
  state => ({}),
  {
    openSnackbar,
  },
)(SocialShareMenu)
