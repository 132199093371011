import React, { Component } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import formBackgroundLeft from '../../images/formBackground_left.jpg'
import formBackgroundRight from '../../images/formBackground_right.jpg'
import { mediaQuery, getQuerystringVal } from '../../services/global'

// Consts

// Style
const PageWrapper = styled.div`
  background-color: #f5fcfd;
  background: url(${formBackgroundLeft}) left bottom no-repeat, url(${formBackgroundRight}) right bottom no-repeat, #f7fdfd;
  background-size: 40% auto, 35% auto;
  padding: 20px;
  flex: 1;
  ${mediaQuery.phone`
    background: none;
  `}
`
const PaymentMessageTitle = styled.h1`
  text-align: center;
  margin-top: 40px;
`
const PaymentMessageBody = styled.h5`
  text-align: center;
  margin-top: 20px;
`
const PaymentMessageSmallText = styled.div`
  text-align: center;
  font-size: 12px;
`

// Component
class CartAfterPaymentScreen extends Component {
  state = {
    firstName: '',
    payerEmail: '',
    errorCode: '',
  }

  // Lifecycle methods
  componentDidMount() {
    const errorCode = getQuerystringVal('error')
    this.setState({
      firstName: getQuerystringVal('firstName'),
      payerEmail: getQuerystringVal('payerEmail'),
      errorCode,
    })

    if (errorCode == null || errorCode === '') {
      // clear the shopping cart (only after a succesful payment)
      this.props.clearCart()

      // clear the form values of shopping cart from the LocalStorage
      localStorage.removeItem('shopping-cart-form')
    }
  }

  // Helper methods

  // UI event methods

  // Render methods
  render() {
    const { firstName, payerEmail, errorCode } = this.state
    const firstNameLabel = firstName ? `${firstName}, ` : ''
    const payerEmailLabel = payerEmail ? `לכתובת: ${payerEmail}` : 'לכתובת אותה הזנת'
    return (
      <PageWrapper>
        {errorCode !== null && errorCode !== '' && (
          <>
            <PaymentMessageTitle>שגיאה בסליקת כרטיס האשראי</PaymentMessageTitle>
            <PaymentMessageBody>אנא פנה לחברת האשראי להסדר התשלום ונסה שנית.</PaymentMessageBody>
            <PaymentMessageSmallText>מספר שגיאה: {errorCode}</PaymentMessageSmallText>
          </>
        )}
        {(errorCode == null || errorCode === '') && (
          <>
            <PaymentMessageTitle>{firstNameLabel}תודה על הזמנתך!</PaymentMessageTitle>
            <PaymentMessageBody>
              התשלום בוצע בהצלחה והזמנתך התקבלה במאגר.
              <br />
              ברגעים אלו נשלח מייל אישור {payerEmailLabel}
            </PaymentMessageBody>
          </>
        )}
      </PageWrapper>
    )
  }
}

export default withRouter(CartAfterPaymentScreen)
