import React from 'react'
import styled from 'styled-components'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { colorPalette } from '../styles/styleVariables'

// Style
const StyledCheckbox = styled(Checkbox)`
  && {
  }
`

// Component
function CheckboxComponent({ ...params }) {
  return <FormControlLabel control={<StyledCheckbox {...params} />} label={params.label} />
}

export default CheckboxComponent
