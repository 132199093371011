import { connect } from 'react-redux'
import MyWorkoutsScreen from '../components/MyWorkoutsScreen'

export default connect(
  state => ({
    currentUser: state.currentUser.user,
    personalStats: state.currentUser.personalStats,
    allUserWorkouts: state.currentUser.allUserWorkouts,
  }),
  {},
)(MyWorkoutsScreen)
