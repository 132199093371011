import { createGlobalStyle, css } from 'styled-components'

// define the color palette to be used in the app
export const colorPalette = {
  primary1: {
    main: '#0e6aad',
    light: '#2289d4',
    dark: '#06406c',
  },
  primary2: {
    // Gold
    main: '#ffb547',
    light: '#ffe778',
    dark: '#c8850f',
  },
  secondary1: {},
  secondary2: {},
  tertiary1: {
    // Gray
    main: '#979797',
    light: '#ccc',
    dark: '#4a4a4a',
    background: '#f9f9f9',
  },
  white: {
    // White
    main: '#ffffff',
  },
  black: {
    // Black
    main: '#000000',
  },
  custom: {
    success: '#2daf15',
    warning: '#ff0000',
  },
}

// global styles
export const globalStyles = {
  boxShadow: css`
    box-shadow: -3px 3px 1px 5px ${colorPalette.primary1.main};
  `,
  boxShadowPopup: css`
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  `,
  boxRadius: css`
    border-radius: 10px;
  `,
  customButton: css`
    appearance: none;
    outline: 0;
    border: 0;
    &:focus {
      outline: 0;
    }
  `,
  mainBoxRadius: '30px',
  mainBoxShadow: '2px 10px 10px rgba(0, 0, 0, 0.4)',
}

// helper function to convert HEX color to RGBA (if we need to add transparency)
export const convertHexToRGB = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  if (alpha || alpha === 0) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  }

  return `rgb(${r}, ${g}, ${b})`
}

// global styles

export const GlobalStyle = createGlobalStyle`
  body {
    padding: 0;
    font-family: 'OpenSansHebrew', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    direction: rtl;
    text-align: right;
    height: 100%;
    &.invert-colors {
      -webkit-filter: invert(100%);
      filter: url(data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' ><filter id…r' slope='-1' intercept='1' /></feComponentTransfer></filter></svg>#invert);
      filter: invert(100%);
    }
    &.grayscale-colors {
      -webkit-filter: grayscale(100%);
      filter: url(data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' ><filter id…atrix in='SourceGraphic' type='saturate' values='0' /></filter></svg>#gray);
      filter: grayscale(100%);
    }
  }
  html {
    height: 100%;
    width: 100%;
  }
  * {
    box-sizing: border-box;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  a {
    text-decoration: none !important;
    color: ${colorPalette.primary1.main};
    &:hover {
      color: ${colorPalette.primary1.dark};
    }
  }
  body.underlined-links a {
    text-decoration: underline !important;
    color: ${colorPalette.custom.warning};
  }
  body.underlined-headers h1,
  body.underlined-headers h2,
  body.underlined-headers h3,
  body.underlined-headers h4,
  body.underlined-headers h5,
  body.underlined-headers h6 {
    text-decoration: underline !important;
    text-decoration-color: #222;
    font-weight: 600;
  }
  button,
  input,
  textarea {
    font-family: 'OpenSansHebrew';
  }
  #root {
    height: 100%;
  }

  /* Bootstrap overrides */
  .carousel-control-prev,
  .carousel-control-next {
    width: 5% !important;
    min-width: 55px;
    margin-top: 345px;
    height: 60px;
    align-items: flex-start;
  }

  @media (max-width: 62.5em) {
  .carousel-control-prev,
  .carousel-control-next {
    margin-top: 515px;
  }
}

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    filter: invert(1);
    background-color: rgba(0,0,0,0.3);
    border-radius: 100%;
    display: block;
    background-size: 72%;
    width: 25px;
    height: 25px;
  }

  .MuiTooltip-tooltip {
    color: ${colorPalette.primary1.main} !important;
    background-color: ${colorPalette.white.main} !important;
  }

  .MuiTooltip-arrow {
    color: ${colorPalette.white.main} !important;
  }

  @keyframes wizardSelfHighlight {
    from { opacity: 0.5; }
    to { opacity: 1; }
  }
  @keyframes wizardBorderHighlight {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  .wizardBorderHighlightAnimation {
    position: relative;
  }
  .wizardBorderHighlightAnimation::after {
    content: '';
    border: 3px solid red;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 10px;
    margin: -6px;
    animation: wizardBorderHighlight 0.3s ease-in infinite;
    animation-direction: alternate;
  }
  .wizardSelfHighlightAnimation {
    animation: wizardSelfHighlight 0.3s ease-in infinite;
    animation-direction: alternate;
  }
`
