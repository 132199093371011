import React, { Component } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import { withRouter } from 'react-router-dom'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/DoneOutline'
import { invokeAPIcall } from '../../services/rest'
import { mediaQuery, keysToLowerCase } from '../../services/global'
import { requiredValidation } from '../../services/formValidation'
import { questionFields } from '../PersonalStatsScreen'
import StyledTextField from '../StyledTextField'

// Style
const StyledDialogTitle = styled(DialogTitle)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
  }
`
const StyledButton = styled(Button)`
  && {
    margin-left: 10px;
  }
  ${mediaQuery.phone`
    && {
      margin-left: 0;
      margin-bottom: 10px;
      flex: 1;
    }
  `}
`
const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 40px;
  ${mediaQuery.phone`
    flex-direction: column;
    margin-top: 10px;
  `}
`

const FormWrapper = styled.div`
  padding: 20px 40px;
  padding-top: 0;
`
const FieldsRow = styled.div`
  margin: 20px 0;
  min-width: 460px;
  ${mediaQuery.phone`
    min-width: 0;
  `}
`
const LoadingIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5px;
`
const SelectorButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${mediaQuery.phone`
    .MuiButton-label {
      font-size: 11px;
    }
  `}
`
const SelectorButtonCheckIcon = styled(CheckIcon)`
  && {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 8px;
    right: 4px;
    opacity: ${props => (props.selected ? '1' : '0')};
  }
`
const SelectorButton = styled(Button)`
  && {
    margin-left: 5px;
    margin-bottom: 5px;
    padding-right: 20px;
  }
`
const FieldLabel = styled.h6`
  margin-bottom: 10px;
`

// Consts

// Component
class StartOfPaidChallengeDialog extends Component {
  state = {
    initialValues: {},
  }

  // Lifecycle methods
  componentDidMount() {
    // define the fields to display
    const { personalStatsFieldsToDisplay_custom, personalStats } = this.props
    const startStats = personalStats && personalStats.filter(item => item.Phase === 'start')
    this.personalStatsFieldsToDisplay = questionFields.filter(
      question => question.dontShowInStartPhase && !question.showInProgramDashboard && question.type !== 'fileUpload',
    )
    if (personalStatsFieldsToDisplay_custom) {
      this.personalStatsFieldsToDisplay = personalStatsFieldsToDisplay_custom
    }

    // define the formik's initial values
    const initialValues = {}
    this.personalStatsFieldsToDisplay.forEach(fieldInfo => {
      initialValues[fieldInfo.fieldName] = null
      if (fieldInfo.freeTextForYes) {
        initialValues[`${fieldInfo.fieldName}Text`] = null
      }

      // if there is a value in DB
      if (startStats && startStats[0]) {
        const existingPersonalStatsRecord = keysToLowerCase({ ...startStats[0] })
        if (existingPersonalStatsRecord[fieldInfo.fieldName] != null) {
          initialValues[fieldInfo.fieldName] = existingPersonalStatsRecord[fieldInfo.fieldName]
          if (fieldInfo.freeTextForYes) {
            initialValues[`${fieldInfo.fieldName}Text`] = existingPersonalStatsRecord[`${fieldInfo.fieldName}Text`]
          }
        }
      }
    })
    this.setState({ initialValues })
  }

  // Helper methods
  validate = values => {
    let errors = {}

    this.personalStatsFieldsToDisplay.forEach(fieldInfo => {
      if (fieldInfo.type === 'selector') {
        if (requiredValidation(values[fieldInfo.fieldName])) {
          errors[fieldInfo.fieldName] = requiredValidation(values[fieldInfo.fieldName])
        }
      }
    })

    return errors
  }

  // UI event methods
  handleSubmit = async (values, { setSubmitting }) => {
    const { handleClose } = this.props

    const personalStatsList = await invokeAPIcall({ uri: 'api/personalStats' })
    const personalStatsOfSpecificPhase = personalStatsList.filter(item => item.Phase === 'start')
    if (personalStatsOfSpecificPhase.length > 0) {
      const existingPersonalStatsRecord = personalStatsOfSpecificPhase[0]
      const data = {
        ...keysToLowerCase(existingPersonalStatsRecord),
        ...values,
      }

      // invoke API call
      try {
        const responseData = await invokeAPIcall({ uri: 'api/personalStats', data, method: 'POST' })

        // if API success
        if (responseData.Key === true) {
          handleClose()
          window.location.reload()
        }
        // if failed
        else {
          alert(responseData.Value || 'התרחשה שגיאה')
        }
      } catch (error) {
        console.log('error in updating PersonalStats values', error)
      }
    }
    setSubmitting(false)
  }

  // Render methods
  render() {
    const { initialValues } = this.state
    const { handleClose } = this.props
    return (
      <Dialog onClose={handleClose} open>
        <StyledDialogTitle id="simple-dialog-title" disableTypography>
          <Typography variant="h6">עדכון פרטים נוספים</Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <DialogContent>
          <Formik validate={this.validate} initialValues={initialValues} onSubmit={this.handleSubmit}>
            {formikBag => {
              const { isSubmitting, values, errors, touched, handleChange, setFieldValue, setFieldTouched } = formikBag
              return (
                <FormWrapper>
                  <Form>
                    {this.personalStatsFieldsToDisplay.map(fieldInfo => {
                      let fieldOptions = []
                      if (fieldInfo.type === 'selector') {
                        if (fieldInfo.booleanSelect) {
                          fieldOptions = [{ value: true, label: 'כן' }, { value: false, label: 'לא' }]
                        }
                      }
                      const currentQuestionValue = values[fieldInfo.fieldName]
                      return (
                        <FieldsRow>
                          <FieldLabel>{fieldInfo.fieldLabel}</FieldLabel>

                          {fieldInfo.type === 'selector' && (
                            <>
                              <SelectorButtonWrapper>
                                {fieldOptions.map(item => {
                                  const selected = currentQuestionValue === item.value
                                  return (
                                    <SelectorButton
                                      variant="outlined"
                                      color="primary"
                                      onClick={() => {
                                        setFieldValue(fieldInfo.fieldName, item.value, false)
                                        setFieldTouched(fieldInfo.fieldName, true, true)
                                      }}
                                    >
                                      {item.label}
                                      <SelectorButtonCheckIcon selected={selected} />
                                    </SelectorButton>
                                  )
                                })}
                              </SelectorButtonWrapper>
                              {errors[fieldInfo.fieldName] && touched[fieldInfo.fieldName] && (
                                <FormControl error>
                                  <FormHelperText>
                                    {errors[fieldInfo.fieldName] && touched[fieldInfo.fieldName] && errors[fieldInfo.fieldName]}
                                  </FormHelperText>
                                </FormControl>
                              )}
                              {fieldInfo.freeTextForYes && currentQuestionValue === true && (
                                <StyledTextField
                                  margin="normal"
                                  fullWidth
                                  name={`${fieldInfo.fieldName}Text`}
                                  value={values[`${fieldInfo.fieldName}Text`]}
                                  onChange={handleChange}
                                  placeholder="פירוט"
                                ></StyledTextField>
                              )}
                            </>
                          )}
                          {fieldInfo.type === 'textbox' && (
                            <>
                              <StyledTextField
                                margin="normal"
                                fullWidth
                                placeholder={fieldInfo.placeholder}
                                name={fieldInfo.fieldName}
                                value={values[fieldInfo.fieldName]}
                                onChange={handleChange}
                              ></StyledTextField>
                            </>
                          )}
                        </FieldsRow>
                      )
                    })}

                    <ButtonsWrapper>
                      <StyledButton variant="contained" color="primary" disabled={isSubmitting} type="submit">
                        אישור
                      </StyledButton>
                    </ButtonsWrapper>
                    {isSubmitting && (
                      <LoadingIndicatorWrapper>
                        <CircularProgress />
                      </LoadingIndicatorWrapper>
                    )}
                  </Form>
                </FormWrapper>
              )
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    )
  }
}

export default withRouter(StartOfPaidChallengeDialog)
