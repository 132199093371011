import { connect } from 'react-redux'
import ProgramProgressBar from '../components/ProgramProgressBar'
import { setPersonalStats, setUserMealsAndWorkouts, setCurrentUser } from '../bundles/currentUser'
import { openSnackbar } from '../bundles/snackbar'

export default connect(
  state => ({
    currentUser: state.currentUser.user,
  }),
  {
    setPersonalStats,
    setUserMealsAndWorkouts,
    setCurrentUser,
    openSnackbar,
  },
)(ProgramProgressBar)
