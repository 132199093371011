import React, { Component } from 'react'
import styled from 'styled-components'
import Snackbar from '@material-ui/core/Snackbar'
import Button from '@material-ui/core/Button'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import WarningIcon from '@material-ui/icons/Warning'
import { colorPalette } from '../styles/styleVariables'

// Style
const StyledSnackbar = styled(Snackbar)`
  && {
    .MuiSnackbarContent-root {
      background: ${props => props.bgColor};
    }
  }
`
const StyledButton = styled(Button)`
  && {
    color: ${colorPalette.white.main};
  }
`

// Component
class SnackbarMessage extends Component {
  state = {}

  // Lifecycle methods

  // UI event methods
  handleClose = () => {
    this.props.closeSnackbar()
  }

  // Render methods
  render() {
    const { message, severity, actionText, actionOnClick } = this.props
    let bgColor = 'rgb(49, 49, 49)'
    let actionEl = null
    switch (severity) {
      case 'success':
        bgColor = 'green'
        actionEl = <CheckCircleIcon />
        break
      case 'failure':
        bgColor = 'red'
        actionEl = <WarningIcon />
        break
      default:
        break
    }
    if (actionText && actionOnClick) {
      actionEl = (
        <StyledButton color="white" size="small" onClick={actionOnClick}>
          {actionText}
        </StyledButton>
      )
    }

    return (
      <StyledSnackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={message !== null}
        message={message}
        autoHideDuration={6000}
        onClose={this.handleClose}
        bgColor={bgColor}
        action={actionEl}
      />
    )
  }
}

export default SnackbarMessage
