import { connect } from 'react-redux'
import AdminPanelScreen from '../components/AdminPanelScreen'
import { addProductToCart, clearCart } from '../bundles/shoppingCart'

export default connect(
  state => ({
    currentUser: state.currentUser.user,
  }),
  {
    addProductToCart,
    clearCart,
  },
)(AdminPanelScreen)
