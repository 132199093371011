import { connect } from 'react-redux'
import CartAfterPaymentScreen from '../components/CartAfterPaymentScreen'
import { clearCart } from '../bundles/shoppingCart'

export default connect(
  state => ({}),
  {
    clearCart,
  },
)(CartAfterPaymentScreen)
