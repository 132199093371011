import React, { Component } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { colorPalette, convertHexToRGB, globalStyles } from '../../styles/styleVariables'
import { mediaQuery } from '../../services/global'

// Consts

// Style
const CountdownWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  ${mediaQuery.phone`
    justify-content: center;
  `}
`
const CountdownItem = styled.div`
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 22px;
  margin: 3px;
  padding: 8px 2px;
  padding-top: 12px;
  position: relative;
  width: 55px;
  height: 55px;
  background-color: ${colorPalette.primary1.main};
  color: ${colorPalette.white.main};
  border-radius: 5px;
  span {
    color: ${colorPalette.white.main};
    font-size: 12px;
    font-weight: 600;
  }
`
const CountdownSeparator = styled.div`
  font-size: 26px;
  font-weight: 500;
  color: ${colorPalette.primary1.main};
`

// Component
class SaleCountdown extends Component {
  state = {
    days: undefined,
    hours: undefined,
    minutes: undefined,
    seconds: undefined,
  }

  // Lifecycle methods
  componentDidMount() {
    this.interval = setInterval(() => {
      const { timeTillDate, timeFormat } = this.props

      // Get todays date and time
      const now = new Date().getTime()
      const countDownDate = new Date(timeTillDate).getTime()

      // Find the distance between now and the count down date
      var distance = countDownDate - now

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24))
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      var seconds = Math.floor((distance % (1000 * 60)) / 1000)

      if (days < 0) {
        days = 0
      }
      if (hours < 0) {
        hours = 0
      }
      if (minutes < 0) {
        minutes = 0
      }
      if (seconds < 0) {
        seconds = 0
      }

      this.setState({ days, hours, minutes, seconds })
    }, 1000)
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  // Render methods
  render() {
    const { days, hours, minutes, seconds } = this.state
    return (
      <div>
        <CountdownWrapper>
          <CountdownItem>
            {seconds || '0'}
            <span>שניות</span>
          </CountdownItem>
          <CountdownSeparator>:</CountdownSeparator>
          <CountdownItem>
            {minutes || '0'}
            <span>דקות</span>
          </CountdownItem>
          <CountdownSeparator>:</CountdownSeparator>
          <CountdownItem>
            {hours || '0'}
            <span>שעות</span>
          </CountdownItem>
          <CountdownSeparator>:</CountdownSeparator>
          <CountdownItem>
            {days || '0'}
            <span>ימים</span>
          </CountdownItem>
        </CountdownWrapper>
      </div>
    )
  }
}

export default SaleCountdown
