import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import { Link } from 'react-router-dom'
import { mediaQuery } from '../services/global'

// Style
const BreadcrumbsWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
  ${mediaQuery.phone`
    margin-top: 10px;
  `}
`

function BreadcrumbsComponent({ previousItems, currentItem }) {
  return (
    <BreadcrumbsWrapper>
      <Breadcrumbs aria-label="breadcrumb" separator={<NavigateBeforeIcon fontSize="small" />}>
        <Link to="/">דף הבית</Link>
        {previousItems.map(item => (
          <Link to={item.url}>{item.label}</Link>
        ))}
        <Typography color="textPrimary">{currentItem}</Typography>
      </Breadcrumbs>
    </BreadcrumbsWrapper>
  )
}

export default BreadcrumbsComponent
