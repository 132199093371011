// Actions
const ADD_PRODUCT_TO_CART = 'shoppingCart/addProductToCart'
const REMOVE_PRODUCT_FROM_CART = 'shoppingCart/removeProductFromCart'
const UPDATE_PRODUCT_QUANTITY_IN_CART = 'shoppingCart/updateProductQuantityInCart'
const CLEAR_CART = 'shoppingCart/clearCart'

// Reducer
const initialState = {
  productsInCart: [], // array of { productId, quantity }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ADD_PRODUCT_TO_CART:
      if (state.productsInCart.filter(item => item.productId === action.payload.productId).length > 0) {
        return state
      }
      return {
        ...state,
        productsInCart: [
          ...state.productsInCart,
          { productId: action.payload.productId, quantity: action.payload.quantity, customPrice: action.payload.customPrice },
        ],
      }
    case UPDATE_PRODUCT_QUANTITY_IN_CART:
      return {
        ...state,
        productsInCart: state.productsInCart.map(item =>
          item.productId === action.payload.productId ? { ...item, quantity: action.payload.quantity } : item,
        ),
      }
    case REMOVE_PRODUCT_FROM_CART:
      return {
        ...state,
        productsInCart: state.productsInCart.filter(item => item.productId !== action.payload.productId),
      }
    case CLEAR_CART:
      return {
        ...state,
        productsInCart: [],
      }
    default:
      return state
  }
}

// Action Creators
export function addProductToCart({ productId, quantity, customPrice }) {
  return {
    type: ADD_PRODUCT_TO_CART,
    payload: { productId, quantity, customPrice },
  }
}

export function updateProductQuantityInCart({ productId, quantity }) {
  return {
    type: UPDATE_PRODUCT_QUANTITY_IN_CART,
    payload: { productId, quantity },
  }
}

export function removeProductFromCart({ productId }) {
  return {
    type: REMOVE_PRODUCT_FROM_CART,
    payload: { productId },
  }
}

export function clearCart() {
  return {
    type: CLEAR_CART,
  }
}
