import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { withRouter } from 'react-router-dom'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import { mediaQuery } from '../../services/global'
import { invokeAPIcall } from '../../services/rest'
import { getString } from '../../services/presetStringsHelper'
import carouselBg from '../../images/productsBg.jpg'

// Style
const TrainersCarouselItemWrapper = styled.div`
  height: 550px;
  color: ${colorPalette.white.main};
  position: relative;
  display: flex;
  flex-direction: column;
  ${mediaQuery.phone`
    height: auto;
  `}
`
const MainPhoto = styled.div`
  background-color: ${colorPalette.black.main};
  background-image: url(${carouselBg});
  background-size: 50% 140%;
  background-position: center left;
  background-repeat: no-repeat;
  height: 350px;
  width: 100%;
  border-bottom-left-radius: ${globalStyles.mainBoxRadius};
  border-bottom-right-radius: ${globalStyles.mainBoxRadius};
  position: relative;
  z-index: 14;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0 20px;
  ${mediaQuery.phone`
    height: 200px;
    background-size: cover;
  `}
`
const ContentBox = styled.div`
  background-color: ${colorPalette.primary1.main};
  border-bottom-left-radius: ${globalStyles.mainBoxRadius};
  border-bottom-right-radius: ${globalStyles.mainBoxRadius};
  box-shadow: ${globalStyles.mainBoxShadow};
  position: relative;
  top: -30px;
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  ${mediaQuery.phone`
    text-align: center;
    width: auto;
    padding: 0 10px;
    padding-top: 30px;
    flex-direction: column;
  `}
`
const PromotionTitle = styled.h2`
  font-size: 37px;
  font-weight: 500;
  margin: 0;
  ${mediaQuery.phone`
    font-size: 25px;
    margin: 20px 0;
  `}
`
const PromotionTitleFirst = styled(PromotionTitle)`
  color: ${colorPalette.primary1.main};
  ${mediaQuery.phone`
    display: none;
  `}
`
const PromotionTitleFirstMobile = styled(PromotionTitle)`
  color: ${colorPalette.white.main};
  display: none;
  ${mediaQuery.phone`
    display: block;
  `}
`
const PromotionTitleSecond = styled(PromotionTitle)``
const PromotionSubTitle = styled.h4`
  font-size: 24px;
  font-weight: 400;
  margin-top: 10px;
  max-width: 65%;
  ${mediaQuery.phone`
    font-size: 1.2rem;
    text-shadow: none;
    max-width: 100%;
  `}
`
const TitlesSection = styled.div`
  padding: 0 20px;
`
const PromotionItemsList = styled.ul`
  flex: 0 0 690px;
  margin: 20px 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${mediaQuery.tablet`
    flex: 0 0 490px;
    flex-direction: column;
    flex-wrap: nowrap;
  `}
  ${mediaQuery.phone`
    margin: 20px auto;
    flex-direction: column;
    flex: 1;
  `}
`
const PromotionItem = styled.li`
  flex: 0 0 48%;
  margin: 10px 0;
  span {
    display: none;
    font-size: 14px;
  }
  &:hover span {
    display block;
  }
  ${mediaQuery.tablet`
    flex: 1;
  `}
  ${mediaQuery.phone`
    ${props => (props.hideInMobile ? 'display: none;' : '')}
  `}
  /*transition: transform 0.5s, opacity 2s;
  transition-delay: ${props => props.index * 0.1}s;
  transform: ${props => (props.isActive ? 'translateX(0%)' : 'translateX(100%)')};
  opacity: ${props => (props.isActive ? '1' : '0')};*/
`
const ItemTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  ${mediaQuery.phone`
    font-size: 16px;
  `}
`
const StyledDoneOutlineIcon = styled.div`
  && {
    background: ${colorPalette.white.main};
    width: 14px;
    height: 14px;
    margin-left: 10px;
  }
`

// Component
class HighlightProductsCarouselItem extends Component {
  state = { showPromotionItems: false }

  // Lifecycle methods
  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    if (!this.props.isActive && nextProps.isActive) {
      window.setTimeout(() => {
        this.setState({ showPromotionItems: true })
      }, 800)
    }
  }

  // UI method

  // Render methods
  render() {
    return (
      <TrainersCarouselItemWrapper>
        <MainPhoto>
          <PromotionTitleFirst>{getString('HighlightProductsCarouselItemTitle')}</PromotionTitleFirst>
        </MainPhoto>
        <ContentBox>
          <TitlesSection>
            <PromotionTitleFirstMobile>{getString('HighlightProductsCarouselItemTitle')}</PromotionTitleFirstMobile>
            <PromotionTitleSecond>{getString('HighlightProductsCarouselItemSubTitle')}</PromotionTitleSecond>
            <PromotionSubTitle>{getString('HighlightProductsCarouselItemSubTitle2')}</PromotionSubTitle>
          </TitlesSection>
          <PromotionItemsList>
            <PromotionItem index={0} isActive>
              <ItemTitle>
                <StyledDoneOutlineIcon />
                {getString('HighlightProductsCarouselItemListItem1')}
              </ItemTitle>
            </PromotionItem>
            <PromotionItem index={1} isActive>
              <ItemTitle>
                <StyledDoneOutlineIcon />
                {getString('HighlightProductsCarouselItemListItem2')}
              </ItemTitle>
            </PromotionItem>
            <PromotionItem index={2} isActive>
              <ItemTitle>
                <StyledDoneOutlineIcon />
                {getString('HighlightProductsCarouselItemListItem3')}
              </ItemTitle>
            </PromotionItem>
            <PromotionItem index={3} isActive>
              <ItemTitle>
                <StyledDoneOutlineIcon />
                {getString('HighlightProductsCarouselItemListItem4')}
              </ItemTitle>
            </PromotionItem>
            <PromotionItem hideInMobile index={4} isActive>
              <ItemTitle>
                <StyledDoneOutlineIcon />
                {getString('HighlightProductsCarouselItemListItem5')}
              </ItemTitle>
            </PromotionItem>
            <PromotionItem hideInMobile index={5} isActive>
              <ItemTitle>
                <StyledDoneOutlineIcon />
                {getString('HighlightProductsCarouselItemListItem6')}
              </ItemTitle>
            </PromotionItem>
          </PromotionItemsList>
        </ContentBox>
      </TrainersCarouselItemWrapper>
    )
  }
}

export default withRouter(HighlightProductsCarouselItem)
