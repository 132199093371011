import React, { Component } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import { withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import formBackgroundLeft from '../../images/formBackground_left.jpg'
import formBackgroundRight from '../../images/formBackground_right.jpg'
import { mediaQuery, keysToLowerCase } from '../../services/global'
import { requiredValidation } from '../../services/formValidation'
import { invokeAPIcall } from '../../services/rest'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import StyledTextField from '../StyledTextField'
import Breadcrumbs from '../Breadcrumbs'

// Style
const PageWrapper = styled.div`
  background-color: #f5fcfd;
  background: url(${formBackgroundLeft}) left bottom no-repeat, url(${formBackgroundRight}) right bottom no-repeat, #f7fdfd;
  background-size: 40% auto, 35% auto;
  padding: 20px;
  flex: 1;
  ${mediaQuery.phone`
    background: none;
  `}
`
const FormWrapper = styled.div`
  background-color: ${colorPalette.white.main};
  max-width: 680px;
  margin: 0 auto;
  padding: 20px;
  color: ${colorPalette.primary1.main};
  ${globalStyles.boxShadow}
  ${globalStyles.boxRadius}
`
const PageTitle = styled.h1`
  margin-bottom: 20px;
`
const FieldsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
`
const FieldCell = styled.div`
  flex: 0 0 100%;
`
const CodeFieldCell = styled(FieldCell)`
  flex: 0 0 49%;
`
const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  margin-top: 20px;
  ${mediaQuery.phone`
    width: 100%;
  `}
`
const StyledButton = styled(Button)`
  && {
  }
`
const LoadingIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5px;
`

// Component
class AddPresetStringScreen extends Component {
  state = {
    initialValues: {
      codeKey: '',
      descKey: '',
      hebrewText: '',
    },
  }

  // Lifecycle methods
  componentDidMount() {
    const editModeId = this.getEditModeId()
    if (editModeId != null) {
      this.loadPresetStringData(editModeId)
    }
  }

  // Helper methods
  getEditModeId = () => {
    const { match } = this.props
    const { params } = match
    return params.id
  }

  loadPresetStringData = async id => {
    let presetStringData = await invokeAPIcall({ uri: `api/presetStrings/${id}` })
    presetStringData = keysToLowerCase(presetStringData)
    this.setState({
      initialValues: presetStringData,
    })
  }

  validate = values => {
    let errors = {}

    if (requiredValidation(values.descKey)) {
      errors.descKey = requiredValidation(values.descKey)
    }
    if (requiredValidation(values.hebrewText)) {
      errors.hebrewText = requiredValidation(values.hebrewText)
    }

    return errors
  }

  // UI event methods
  handleSubmit = async (values, { setSubmitting }) => {
    const editModeId = this.getEditModeId()
    const isEditMode = editModeId != null
    const { history } = this.props
    const data = {
      ...values,
    }

    // invoke API call
    try {
      const responseData = await invokeAPIcall({
        uri: isEditMode ? `api/presetStrings/${editModeId}` : 'api/presetStrings',
        data,
        method: isEditMode ? 'PUT' : 'POST',
      })

      // if API success
      if (responseData.Key === true) {
        history.push('/presetStringsAdmin')
      }
      // if failed
      else {
        alert(responseData.Value || 'התרחשה שגיאה')
      }
    } catch (error) {
      console.log('error in adding/updating new string', error)
    }
    setSubmitting(false)
  }

  // Render methods
  render() {
    const { initialValues } = this.state
    const editModeId = this.getEditModeId()
    const isEditMode = editModeId != null

    return (
      <PageWrapper>
        <Breadcrumbs
          previousItems={[
            {
              label: `מסכי ניהול`,
              url: `/adminPanel`,
            },
          ]}
          currentItem={`ניהול טקסטים`}
        />
        <FormWrapper>
          <PageTitle>{isEditMode ? 'עדכון טקסט' : 'הוספת טקסט חדש'}</PageTitle>
          <Formik validate={this.validate} initialValues={initialValues} onSubmit={this.handleSubmit} enableReinitialize={true}>
            {formikBag => {
              const { isSubmitting, values, errors, touched, handleChange, handleBlur, isValid } = formikBag
              return (
                <Form>
                  <FieldsRow>
                    <CodeFieldCell>
                      <StyledTextField
                        label={'קוד'}
                        name="codeKey"
                        value={values.codeKey}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.codeKey && touched.codeKey && errors.codeKey}
                        error={errors.codeKey && touched.codeKey}
                        margin="normal"
                        fullWidth
                        disabled
                      />
                    </CodeFieldCell>
                    <CodeFieldCell>
                      <StyledTextField
                        label={'תיאור מיקום הטקסט'}
                        name="descKey"
                        value={values.descKey}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.descKey && touched.descKey && errors.descKey}
                        error={errors.descKey && touched.descKey}
                        margin="normal"
                        fullWidth
                      />
                    </CodeFieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <FieldCell>
                      <StyledTextField
                        label={'הטקסט שיוצג באתר'}
                        name="hebrewText"
                        value={values.hebrewText}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.hebrewText && touched.hebrewText && errors.hebrewText}
                        error={errors.hebrewText && touched.hebrewText}
                        margin="normal"
                        fullWidth
                        multiline
                        rows="4"
                      />
                    </FieldCell>
                  </FieldsRow>
                  <ButtonsWrapper>
                    <StyledButton variant="contained" color="primary" fullWidth disabled={!isValid || isSubmitting} type="submit">
                      {isEditMode ? 'עדכן' : 'הוסף'}
                    </StyledButton>
                  </ButtonsWrapper>
                  {isSubmitting && (
                    <LoadingIndicatorWrapper>
                      <CircularProgress />
                    </LoadingIndicatorWrapper>
                  )}
                </Form>
              )
            }}
          </Formik>
        </FormWrapper>
      </PageWrapper>
    )
  }
}

export default withRouter(AddPresetStringScreen)
