import React, { Component } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { withRouter } from 'react-router-dom'
import ChatBot from 'react-simple-chatbot'
import { colorPalette } from '../styles/styleVariables'
import { invokeAPIcall } from '../services/rest'
import { TRIAL_DAYS } from '../services/challengeProgressHelper'

// Style
const ChatBoxWrapper = styled.div`
  & .rsc-header {
    padding-top: env(safe-area-inset-top);
  }
`
const MessageWithLink = styled.div`
  a {
    color: ${colorPalette.white.main};
    text-decoration: underline !important;
  }
`

// Consts
const theme = {
  background: '#f5f8fb',
  headerBgColor: colorPalette.primary1.main,
  headerFontColor: '#fff',
  headerFontSize: '15px',
  botBubbleColor: colorPalette.primary1.main,
  botFontColor: '#fff',
  userBubbleColor: '#fff',
  userFontColor: '#4a4a4a',
}
const chatBotSteps = [
  {
    id: 'intro',
    message: 'FitThyBody שלום, אני הבוט לשירותך ואשמח לסייע לך. מה שמך?',
    trigger: 'name',
  },
  {
    id: 'name',
    user: true,
    trigger: 'afterNameMessage',
  },
  {
    id: 'afterNameMessage',
    message: 'היי {previousValue}! רק עוד כמה פרטים לפני שנתחיל.',
    trigger: 'afterNameSelectGender',
  },
  {
    id: 'afterNameSelectGender',
    options: [{ value: 1, label: 'אני גבר', trigger: 'afterGender' }, { value: 2, label: 'אני אישה', trigger: 'afterGender' }],
  },
  {
    id: 'afterGender',
    message: ({ previousValue, steps }) => {
      const gender = steps['afterNameSelectGender'].value
      // different message for male vs female
      return gender === 1 ? 'בן כמה אתה?' : 'בת כמה את?'
    },
    trigger: 'ageInput',
  },
  { id: 'ageInput', user: true, trigger: 'askForCity' /*'afterAge' */ },
  { id: 'askForCity', message: 'מצוין. מהי עיר מגוריך?', trigger: 'cityInput' },
  { id: 'cityInput', user: true, trigger: 'afterAge' },
  {
    id: 'afterAge',
    message: ({ previousValue, steps }) => {
      return `מעולה. איך אוכל לעזור לך?`
    },
    trigger: 'faqOptions',
  },
  /*,*/
  {
    id: 'faqOptions',
    options: [
      { value: 1, label: 'אשמח לקבל ייעוץ חינם', trigger: 'freeAdviceOptions' },
      { value: 2, label: 'אשמח לקנות מוצרים טבעיים', trigger: 'naturalProductsOptions' },
      { value: 3, label: 'אשמח להירשם לאימוני כושר', trigger: 'trainingOptions' },
      { value: 4, label: 'אחר', trigger: 'otherOptions' },
    ],
  },
  /* freeAdviceOptions
  -------------------------- */
  {
    id: 'freeAdviceOptions',
    message: 'נשמח להעניק לך ייעוץ חינם. באיזה נושא?',
    trigger: 'freeAdviceOptionsList',
  },
  {
    id: 'freeAdviceOptionsList',
    options: [
      { value: 1, label: 'אימונים', trigger: 'freeAdviceOptions_goals' },
      { value: 2, label: 'תזונה', trigger: 'freeAdviceOptions_goals' },
      { value: 3, label: 'בעיות ומחלות', trigger: 'freeAdviceOptions_goals' },
      { value: 4, label: 'רפואה טבעית', trigger: 'freeAdviceOptions_goals' },
      { value: 5, label: 'כללי', trigger: 'freeAdviceOptions_goals' },
    ],
  },
  {
    id: 'freeAdviceOptions_goals',
    message: 'ומהן המטרות שלך?',
    trigger: 'freeAdviceOptions_goalsInput',
  },
  {
    id: 'freeAdviceOptions_goalsInput',
    user: true,
    trigger: 'after_freeAdviceOptions_goalsInput_pretext',
  },
  {
    id: 'after_freeAdviceOptions_goalsInput_pretext',
    message: 'מעולה. כעת באפשרותך:',
    trigger: 'after_freeAdviceOptions_goalsInput',
  },
  {
    id: 'after_freeAdviceOptions_goalsInput',
    options: [
      {
        value: 1,
        label: 'להתנסות 7 ימים חינם ב״חוויית החיטוב ב-90 יום״ אונליין מכל מקום ובכל זמן כדי להגיע למטרות שלך במהירות וביעילות',
        trigger: 'wantChallenge_yes',
      },
      { value: 2, label: 'להשאיר לנו טלפון לחזרה', trigger: 'askForUserDetails_andPhoneQuestion' },
      { value: 3, label: 'להשאיר לנו מייל לחזרה', trigger: 'askForUserDetails_andEmailQuestion' },
    ],
  },
  /*{
    id: 'after_freeAdviceOptions_goalsInput2',
    message: ({ previousValue, steps }) => {
      const gender = steps['afterNameSelectGender'].value
      // different message for male vs female
      const genderVerb = gender === 1 ? 'תרצה' : 'תרצי'
      return `האם ${genderVerb} לנסות חינם את ״חוויית החיטוב ב-90 יום״ מכל מקום ובכל זמן למשך ${TRIAL_DAYS} ימים, שתעזור לך להגיע למטרות שלך במהירות וביעילות?`
    },
    trigger: 'freeAdviceOptions_wantChallenge',
  },
  {
    id: 'freeAdviceOptions_wantChallenge',
    options: [
      { value: 1, label: 'כן', trigger: 'wantChallenge_yes' },
      { value: 2, label: 'לא', trigger: 'askForUserDetails_andEmailQuestion' },
    ],
  },*/
  /* naturalProductsOptions
  --------------------------- */
  {
    id: 'naturalProductsOptions',
    message: ({ previousValue, steps }) => {
      const gender = steps['afterNameSelectGender'].value
      // different message for male vs female
      const genderVerb = gender === 1 ? 'תרצה' : 'תרצי'
      return `מעולה. מה ${genderVerb} לקנות?`
    },
    trigger: 'naturalProductsOptionsList',
  },
  {
    id: 'naturalProductsOptionsList',
    options: [
      { value: 1, label: 'פורמולה בהתאמה אישית', trigger: 'naturalProductsOptions_customFormula' },
      { value: 2, label: 'תמצית לניקוי רעלים ושומנים להרזיה', trigger: 'naturalProductsOptions_naturalExtract' },
    ],
  },
  {
    id: 'naturalProductsOptions_customFormula',
    message: 'פורמולה באיזה עניין?',
    trigger: 'naturalProductsOptions_customFormula_input',
  },
  {
    id: 'naturalProductsOptions_customFormula_input',
    user: true,
    trigger: 'naturalProductsOptions_customFormula_takePills',
  },
  {
    id: 'naturalProductsOptions_customFormula_takePills',
    message: ({ previousValue, steps }) => {
      const gender = steps['afterNameSelectGender'].value
      // different message for male vs female
      const genderVerb = gender === 1 ? 'אתה לוקח' : 'את לוקחת'
      return `האם ${genderVerb} תרופות/תוספים? ואם כן, אילו?`
    },
    trigger: 'naturalProductsOptions_customFormula_takePills_input',
  },
  {
    id: 'naturalProductsOptions_customFormula_takePills_input',
    user: true,
    trigger: 'askForUserDetails_andEmailQuestion', //'askForUserDetails',
  },
  {
    id: 'naturalProductsOptions_naturalExtract',
    message: 'האם יש לך מחלות רקע? אם כן, מהן?',
    trigger: 'naturalProductsOptions_naturalExtract_input',
  },
  {
    id: 'naturalProductsOptions_naturalExtract_input',
    user: true,
    trigger: 'naturalProductsOptions_customFormula_takePills',
  },
  /* trainingOptions
  --------------------------- */
  {
    id: 'trainingOptions',
    message: ({ previousValue, steps }) => {
      const gender = steps['afterNameSelectGender'].value
      // different message for male vs female
      const genderVerb = gender === 1 ? 'תרצה' : 'תרצי'
      return `מעולה. אילו אימונים ${genderVerb}?`
    },
    trigger: 'trainingOptionsList',
  },
  {
    id: 'trainingOptionsList',
    options: [
      { value: 1, label: 'תוכנית ל-90 יום של הרזיה וחיטוב הגוף', trigger: 'wantChallenge_yes' },
      { value: 2, label: 'אימונים אישיים/זוגיים/קבוצות קטנות עד הבית עם מאמן צמוד', trigger: 'trainingOptions_personalTraining' },
    ],
  },
  {
    id: 'trainingOptions_personalTraining',
    message: 'מהי כמות המשתתפים באימון של שעה?',
    trigger: 'trainingOptions_personalTraining_options',
  },
  {
    id: 'trainingOptions_personalTraining_options',
    options: [
      { value: 1, label: 'משתתף אחד', trigger: 'wantBuyTraining_yes' },
      { value: 2, label: 'שני משתתפים', trigger: 'wantBuyTraining_yes' },
      { value: 3, label: 'שלושה ויותר משתתפים', trigger: 'askForUserDetails_andEmailQuestion' /*'askForUserDetails'*/ },
    ],
  },
  /* otherOptions
  --------------------*/
  {
    id: 'otherOptions',
    message: ({ previousValue, steps }) => {
      const gender = steps['afterNameSelectGender'].value
      // different message for male vs female
      const genderVerb = gender === 1 ? 'תפרט' : 'תפרטי'
      return `אין בעיה. נשמח אם ${genderVerb}...`
    },
    trigger: 'otherOptions_input',
  },
  {
    id: 'otherOptions_input',
    user: true,
    trigger: 'askForUserDetails_andEmailQuestion', //'askForUserDetails',
  },
  /* ask for user details
  ----------------------- */
  /*{
    id: 'askForUserDetails',
    message: 'אוקיי. כדי שנוכל ליצור קשר, מהו מספר הטלפון שלך?',
    trigger: 'askForUserDetails_phone',
  },
  {
    id: 'askForUserDetails_phone',
    user: true,
    trigger: 'askForUserDetails_andEmailQuestion',
  },*/
  {
    id: 'askForUserDetails_andPhoneQuestion',
    message: ({ previousValue, steps }) => {
      const gender = steps['afterNameSelectGender'].value
      // different message for male vs female
      const genderVerb = gender === 1 ? 'רשום' : 'רשמי'
      return `תודה. ${genderVerb} את מספר הטלפון שלך כדי שנוכל לחזור אליך בהקדם`
    },
    trigger: 'askForUserDetails_andPhoneInput',
  },
  {
    id: 'askForUserDetails_andPhoneInput',
    user: true,
    trigger: 'thankYouMessage', //'askForCity',
  },
  {
    id: 'askForUserDetails_andEmailQuestion',
    message: ({ previousValue, steps }) => {
      const gender = steps['afterNameSelectGender'].value
      // different message for male vs female
      const genderVerb = gender === 1 ? 'רשום' : 'רשמי'
      return `תודה. ${genderVerb} את כתובת האימייל שלך כדי שנוכל לחזור אליך בהקדם`
    },
    trigger: 'askForUserDetails_andEmailInput',
  },
  {
    id: 'askForUserDetails_andEmailInput',
    user: true,
    trigger: 'thankYouMessage', //'askForCity',
  },
  /*------------------ */
  {
    id: 'wantChallenge_yes',
    component: (
      <MessageWithLink>
        יש ללחוץ כאן כדי להירשם לחוויית החיטוב ב-90 יום: <a href="/registerToChallenge">טופס הרשמה</a>
      </MessageWithLink>
    ),
    asMessage: true,
    end: true,
  },
  {
    id: 'wantBuyTraining_yes',
    component: (
      <MessageWithLink>
        יש ללחוץ כאן כדי להזמין אימון היכרות איתנו: <a href="/productDetails/IntroductionTraining">הרשמה לאימון היכרות</a>
      </MessageWithLink>
    ),
    asMessage: true,
    end: true,
  },
  {
    id: 'thankYouMessage',
    message: ({ previousValue, steps }) => {
      const name = steps['name'].value
      return `תודה רבה ${name}. אנחנו נחזור אליך בהקדם האפשרי`
    },
    end: true,
  },
]

class ChatRobotBox extends Component {
  state = {
    opened: false,
    chatboxKey: 1,
  }

  handleChatEnd = async ({ renderedSteps, steps, values }) => {
    const allChatAnswers = renderedSteps.reduce((result, step) => {
      const isUserMessage = step.value != null
      if (isUserMessage) {
        result = `${result}<div style='font-size: 17px; font-weight: 600;'>${step.message}</div>`
      } else {
        result = `${result}<div style='font-size: 12px;'>${step.message}</div>`
      }
      return result
    }, '')

    // if the chat includes the word 'phone' - then we need to send all this chat history via email
    if (allChatAnswers.includes('הטלפון')) {
      const data = { text: allChatAnswers }

      // invoke API call
      try {
        await invokeAPIcall({
          uri: 'api/contact/chatRobot',
          data,
          method: 'POST',
        })
      } catch (error) {
        console.log('error in sending email', error)
      }
    }
  }

  toggleFloating = ({ opened }) => {
    let { chatboxKey } = this.state
    this.setState({ opened })

    if (opened === false) {
      window.setTimeout(() => {
        // increase the 'key' by one to force a refresh of the ReactComponent, to reset all steps of the chat when closing it
        this.setState({ chatboxKey: chatboxKey + 1 })
      }, 500)
    }
  }

  render() {
    const { opened, chatboxKey } = this.state
    const { location } = this.props
    if (window.cordova && location.pathname === '/' && (location.hash === '' || location.hash === '#/')) {
      // don't render this component for cordova homepage
      return null
    }

    return (
      <ThemeProvider theme={theme}>
        <ChatBoxWrapper>
          <ChatBot
            key={chatboxKey}
            steps={chatBotSteps}
            floating
            floatingStyle={{ bottom: '60px', border: '2px solid white', left: '32px', right: 'auto', transformOrigin: 'bottom left' }}
            placeholder="הקלד הודעה..."
            headerTitle="הבוט של FitThyBody"
            handleEnd={this.handleChatEnd}
            opened={opened}
            toggleFloating={this.toggleFloating}
          />
        </ChatBoxWrapper>
      </ThemeProvider>
    )
  }
}

export default withRouter(ChatRobotBox)
