import React, { Component } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import { withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import formBackgroundLeft from '../../images/formBackground_left.jpg'
import formBackgroundRight from '../../images/formBackground_right.jpg'
import { mediaQuery, keysToLowerCase } from '../../services/global'
import { requiredValidation } from '../../services/formValidation'
import { invokeAPIcall } from '../../services/rest'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import StyledTextField from '../StyledTextField'
import KeyboardDatePicker from '../StyledDatePicker'
import Breadcrumbs from '../Breadcrumbs'

// Style
const PageWrapper = styled.div`
  background-color: #f5fcfd;
  background: url(${formBackgroundLeft}) left bottom no-repeat, url(${formBackgroundRight}) right bottom no-repeat, #f7fdfd;
  background-size: 40% auto, 35% auto;
  padding: 20px;
  flex: 1;
  ${mediaQuery.phone`
    background: none;
  `}
`
const FormWrapper = styled.div`
  background-color: ${colorPalette.white.main};
  max-width: 680px;
  margin: 0 auto;
  padding: 20px;
  color: ${colorPalette.primary1.main};
  ${globalStyles.boxShadow}
  ${globalStyles.boxRadius}
`
const PageTitle = styled.h1`
  margin-bottom: 20px;
`
const FieldsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
`
const FieldCell = styled.div`
  flex: 0 0 100%;
`
const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  margin-top: 20px;
  ${mediaQuery.phone`
    width: 100%;
  `}
`
const StyledButton = styled(Button)`
  && {
  }
`
const LoadingIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5px;
`

// Component
class GlobalSettingsScreen extends Component {
  state = {
    initialValues: {
      storeGlobalDiscountPercentage: null,
      challengePricePack1: null,
      challengePricePack1Original: null,
      challengePricePack2: null,
      challengePricePack2Original: null,
      challengePricePack3: null,
      challengePricePack3Original: null,
      challengeSaleTillDate: null,
      pageTitle: '',
      pageDescription: '',
      pageKeywords: '',
    },
  }

  // Lifecycle methods
  componentDidMount() {
    this.loadGlobalSettingsData()
  }

  // Helper methods
  loadGlobalSettingsData = async id => {
    let globalSettingsData = await invokeAPIcall({ uri: `api/globalSettings/1` })
    globalSettingsData = keysToLowerCase(globalSettingsData)
    this.setState({
      initialValues: globalSettingsData,
    })
  }

  validate = values => {
    let errors = {}

    if (requiredValidation(values.storeGlobalDiscountPercentage)) {
      errors.storeGlobalDiscountPercentage = requiredValidation(values.storeGlobalDiscountPercentage)
    }
    if (requiredValidation(values.challengePricePack1)) {
      errors.challengePricePack1 = requiredValidation(values.challengePricePack1)
    }
    if (requiredValidation(values.challengePricePack1Original)) {
      errors.challengePricePack1Original = requiredValidation(values.challengePricePack1Original)
    }
    if (requiredValidation(values.challengePricePack2)) {
      errors.challengePricePack2 = requiredValidation(values.challengePricePack2)
    }
    if (requiredValidation(values.challengePricePack2Original)) {
      errors.challengePricePack2Original = requiredValidation(values.challengePricePack2Original)
    }
    if (requiredValidation(values.challengePricePack3)) {
      errors.challengePricePack3 = requiredValidation(values.challengePricePack3)
    }
    if (requiredValidation(values.challengePricePack3Original)) {
      errors.challengePricePack3Original = requiredValidation(values.challengePricePack3Original)
    }
    if (requiredValidation(values.pageTitle)) {
      errors.pageTitle = requiredValidation(values.pageTitle)
    }
    if (requiredValidation(values.pageDescription)) {
      errors.pageDescription = requiredValidation(values.pageDescription)
    }
    if (requiredValidation(values.pageKeywords)) {
      errors.pageKeywords = requiredValidation(values.pageKeywords)
    }

    return errors
  }

  // UI event methods
  handleSubmit = async (values, { setSubmitting }) => {
    const data = {
      ...values,
    }

    // invoke API call
    try {
      const responseData = await invokeAPIcall({
        uri: `api/globalSettings/1`,
        data,
        method: 'PUT',
      })

      // if API success
      if (responseData.Key === true) {
        alert('עודכן בהצלחה')
      }
      // if failed
      else {
        alert(responseData.Value || 'התרחשה שגיאה')
      }
    } catch (error) {
      console.log('error in adding/updating new global settings', error)
    }
    setSubmitting(false)
  }

  // Render methods
  render() {
    const { initialValues } = this.state
    return (
      <PageWrapper>
        <Breadcrumbs
          previousItems={[
            {
              label: `מסכי ניהול`,
              url: `/adminPanel`,
            },
          ]}
          currentItem={`ניהול הגדרות כלליות`}
        />
        <Formik validate={this.validate} initialValues={initialValues} onSubmit={this.handleSubmit} enableReinitialize={true}>
          {formikBag => {
            const { isSubmitting, values, errors, touched, handleChange, handleBlur, isValid, setFieldValue } = formikBag
            return (
              <Form>
                <FormWrapper>
                  <PageTitle>{'עדכון הגדרות כלליות'}</PageTitle>
                  <FieldsRow>
                    <FieldCell>
                      <StyledTextField
                        label={'כותרת האתר (יופיע במנועי חיפוש)'}
                        name="pageTitle"
                        value={values.pageTitle}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.pageTitle && touched.pageTitle && errors.pageTitle}
                        error={errors.pageTitle && touched.pageTitle}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: values.pageTitle !== null }}
                      />
                    </FieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <FieldCell>
                      <StyledTextField
                        label={'תיאור האתר (יופיע במנועי חיפוש)'}
                        name="pageDescription"
                        value={values.pageDescription}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.pageDescription && touched.pageDescription && errors.pageDescription}
                        error={errors.pageDescription && touched.pageDescription}
                        margin="normal"
                        fullWidth
                        multiline
                        rows={3}
                        InputLabelProps={{ shrink: values.pageDescription !== null }}
                      />
                    </FieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <FieldCell>
                      <StyledTextField
                        label={'מילות חיפוש האתר (יופיע במנועי חיפוש)'}
                        name="pageKeywords"
                        value={values.pageKeywords}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.pageKeywords && touched.pageKeywords && errors.pageKeywords}
                        error={errors.pageKeywords && touched.pageKeywords}
                        margin="normal"
                        fullWidth
                        multiline
                        rows={3}
                        InputLabelProps={{ shrink: values.pageKeywords !== null }}
                      />
                    </FieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <FieldCell>
                      <StyledTextField
                        label={'אחוז הנחה כללי לכל המוצרים'}
                        name="storeGlobalDiscountPercentage"
                        value={values.storeGlobalDiscountPercentage}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.storeGlobalDiscountPercentage &&
                          touched.storeGlobalDiscountPercentage &&
                          errors.storeGlobalDiscountPercentage
                        }
                        error={errors.storeGlobalDiscountPercentage && touched.storeGlobalDiscountPercentage}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: values.storeGlobalDiscountPercentage !== null }}
                      />
                    </FieldCell>
                  </FieldsRow>
                </FormWrapper>
                <br />
                <br />
                <FormWrapper>
                  <PageTitle>{'הגדרות חוויית החיטוב ב-90 יום'}</PageTitle>
                  <FieldsRow>
                    <FieldCell>
                      <StyledTextField
                        label={'מחיר של חוויית החיטוב BASIC, מחיר בפועל (ש״ח)'}
                        name="challengePricePack1"
                        value={values.challengePricePack1}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.challengePricePack1 && touched.challengePricePack1 && errors.challengePricePack1}
                        error={errors.challengePricePack1 && touched.challengePricePack1}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: values.challengePricePack1 !== null }}
                      />
                    </FieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <FieldCell>
                      <StyledTextField
                        label={'מחיר של חוויית החיטוב BASIC, לפני הנחה (ש״ח)'}
                        name="challengePricePack1Original"
                        value={values.challengePricePack1Original}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.challengePricePack1Original && touched.challengePricePack1Original && errors.challengePricePack1Original
                        }
                        error={errors.challengePricePack1Original && touched.challengePricePack1Original}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: values.challengePricePack1Original !== null }}
                      />
                    </FieldCell>
                  </FieldsRow>
                  <br />
                  <br />
                  <br />
                  <FieldsRow>
                    <FieldCell>
                      <StyledTextField
                        label={'מחיר של חוויית החיטוב PREMIUM, מחיר בפועל (ש״ח)'}
                        name="challengePricePack2"
                        value={values.challengePricePack2}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.challengePricePack2 && touched.challengePricePack2 && errors.challengePricePack2}
                        error={errors.challengePricePack2 && touched.challengePricePack2}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: values.challengePricePack2 !== null }}
                      />
                    </FieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <FieldCell>
                      <StyledTextField
                        label={'מחיר של חוויית החיטוב  PREMIUM, לפני הנחה (ש״ח)'}
                        name="challengePricePack2Original"
                        value={values.challengePricePack2Original}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.challengePricePack2Original && touched.challengePricePack2Original && errors.challengePricePack2Original
                        }
                        error={errors.challengePricePack2Original && touched.challengePricePack2Original}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: values.challengePricePack2Original !== null }}
                      />
                    </FieldCell>
                  </FieldsRow>
                  <br />
                  <br />
                  <br />
                  <FieldsRow>
                    <FieldCell>
                      <StyledTextField
                        label={'מחיר של חוויית החיטוב VIP, מחיר בפועל (ש״ח)'}
                        name="challengePricePack3"
                        value={values.challengePricePack3}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.challengePricePack3 && touched.challengePricePack3 && errors.challengePricePack3}
                        error={errors.challengePricePack3 && touched.challengePricePack3}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: values.challengePricePack3 !== null }}
                      />
                    </FieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <FieldCell>
                      <StyledTextField
                        label={'מחיר של חוויית החיטוב VIP, לפני הנחה (ש״ח)'}
                        name="challengePricePack3Original"
                        value={values.challengePricePack3Original}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.challengePricePack3Original && touched.challengePricePack3Original && errors.challengePricePack3Original
                        }
                        error={errors.challengePricePack3Original && touched.challengePricePack3Original}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: values.challengePricePack3Original !== null }}
                      />
                    </FieldCell>
                  </FieldsRow>
                  <br />
                  <br />
                  <br />
                  <FieldsRow>
                    <FieldCell>
                      <KeyboardDatePicker
                        ampm={false}
                        autoOk
                        fullWidth
                        variant="inline"
                        label="מבצע חוויית החיטוב - תאריך סיום ההנחה"
                        helperText="ניתן להשאיר ריק"
                        name="challengeSaleTillDate"
                        format="DD/MM/YYYY"
                        value={values.challengeSaleTillDate}
                        onChange={(value, e) => {
                          if (value) {
                            var m = value.utcOffset(2)
                            m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                            m.toISOString()
                            m.format()
                            m.add(2, 'hours')
                            setFieldValue('challengeSaleTillDate', m, false)
                          } else {
                            setFieldValue('challengeSaleTillDate', null, false)
                          }
                        }}
                        invalidDateMessage="תאריך לא תקין"
                        minDate={new Date()}
                      />
                    </FieldCell>
                  </FieldsRow>
                  <ButtonsWrapper>
                    <StyledButton variant="contained" color="primary" fullWidth disabled={!isValid || isSubmitting} type="submit">
                      עדכן
                    </StyledButton>
                  </ButtonsWrapper>
                  {isSubmitting && (
                    <LoadingIndicatorWrapper>
                      <CircularProgress />
                    </LoadingIndicatorWrapper>
                  )}
                </FormWrapper>
              </Form>
            )
          }}
        </Formik>
      </PageWrapper>
    )
  }
}

export default withRouter(GlobalSettingsScreen)
