import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { withRouter } from 'react-router-dom'
import FitnessIcon from '@material-ui/icons/FitnessCenter'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import { mediaQuery, isScrolledIntoView, photoUrlPrefix } from '../../services/global'
import { getString } from '../../services/presetStringsHelper'
import { invokeAPIcall } from '../../services/rest'
import ProductPriceTag from '../ProductPriceTag'

// Style
const TrainersCarouselItemWrapper = styled.div`
  background-color: ${colorPalette.white.main};
  border-bottom-left-radius: ${globalStyles.mainBoxRadius};
  border-bottom-right-radius: ${globalStyles.mainBoxRadius};
  box-shadow: ${globalStyles.mainBoxShadow};
  color: ${colorPalette.black.main};
  position: relative;
  z-index: 13;
  ${mediaQuery.phone`
    height: auto;
  `}
`
const CarouselItemInner = styled.div`
  width: 100%;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mediaQuery.phone`
    height: auto;
  `}
`
const ContentBox = styled.div`
  display: flex;
  width: 100%;
  ${mediaQuery.phone`
    width: auto;
    padding: 0 10px;
    flex-direction: column;
  `}
`
const TitlesSection = styled.div`
  padding: 0 20px;
  flex: 1;
`
const PromotionSubTitle = styled.h4`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 30px;
  ${mediaQuery.phone`
    font-size: 1.2rem;
    text-shadow: none;
  `}
`
const PromotionItemsList = styled.ul`
  margin-bottom: 40px;
`
const PromotionItem = styled.li`
  span {
    display: none;
    font-size: 14px;
  }
  &:hover span {
    display block;
  }
  ${mediaQuery.phone`
    ${props => (props.hideInMobile ? 'display: none;' : '')}
  `}
  transition: transform 1s, opacity 3s;
  transition-delay: ${props => 0 + props.index * 0.1}s;
  transform: ${props => (props.isActive ? 'translateX(0%)' : 'translateX(100%)')};
  opacity: ${props => (props.isActive ? '1' : '0')};
`
const ItemTitle = styled.div`
  display: flex;
  font-size: 14px;
`
const StyledDoneOutlineIcon = styled.div`
  && {
    background: ${colorPalette.black.main};
    width: 12px;
    height: 12px;
    flex: 0 0 12px;
    margin-top: 5px;
    margin-left: 10px;
  }
`
const HighlightProducts = styled.ul`
  flex: 3;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 10px;
  ${mediaQuery.tablet`
    flex: 1;
  `}
`
const ProductItem = styled.li`
  background-color: ${colorPalette.white.main};
  flex: 0 0 22%;
  margin-bottom: 2px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  color: ${colorPalette.primary1.main};
  &:hover {
    opacity: 0.8;
  }
  ${mediaQuery.tablet`
    flex: 0 0 48%;
  `}
  ${mediaQuery.phone`
    flex: 0 0 48%;
  `}
`
const ProductNameAndPrice = styled.div`
  padding: 0 5px;
  padding-bottom: 12px;
  color: ${colorPalette.black.main};
  & > div {
    font-weight: 500;
    font-size: 14px;
  }
`
const ProductName = styled.div`
  margin-top: 2px;
  font-size: 12px !important;
  font-weight: 300 !important;
`
const ProductPhoto = styled.div`
  width: 100%;
  height: 125px;
  background-color: #eee;
  background-image: url('${props => props.photoUrl}');
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  margin: auto;
`

// Component
class OurHighlightProductsCarouselItem extends Component {
  state = { showPromotionItems: false, isVisible: false, products: [], globalSettingsData: {} }

  // Lifecycle methods
  componentDidMount() {
    window.addEventListener('scroll', () => {
      const isInView = isScrolledIntoView(document.getElementById('TrainersCarouselItemWrapper'))
      const { isVisible } = this.state
      if (this.props.isActive && !isVisible) {
        this.setState({ isVisible: isInView })
      }
    })

    if (this.props.isActive) {
      window.setTimeout(() => {
        this.setState({ showPromotionItems: true })
      }, 800)
    }

    this.loadProductList()
    this.loadGlobalSettingsData()
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.isActive && nextProps.isActive) {
      window.setTimeout(() => {
        this.setState({ showPromotionItems: true })
      }, 800)
    }
  }

  // Helper methods
  loadProductList = async () => {
    const productList = await invokeAPIcall({ uri: `api/products/getHighlighted` })
    this.setState({ products: productList })
  }

  loadGlobalSettingsData = async () => {
    let globalSettingsData = await invokeAPIcall({ uri: `api/globalSettings/1` })
    this.setState({
      globalSettingsData: globalSettingsData,
    })
  }

  // UI event methods
  handleProductClick = urlCode => {
    const { history } = this.props
    history.push(`/productDetails/${urlCode}`)
  }

  // Render methods
  renderProductItem = productItem => {
    const { globalSettingsData } = this.state
    let photoUrl = productItem.ProductPhotos[0] && productItem.ProductPhotos[0].PhotoURL
    if (photoUrl) {
      if (!photoUrl.includes('http')) {
        photoUrl = `${photoUrlPrefix}/productPhotos/${photoUrl}`
      }
    }
    return (
      <ProductItem onClick={this.handleProductClick.bind(this, productItem.UrlCode)}>
        <ProductPhoto photoUrl={photoUrl} />
        <ProductNameAndPrice>
          <ProductName>{productItem.Name}</ProductName>
          <ProductPriceTag productItem={productItem} globalSettingsData={globalSettingsData} showPricePerItem />
        </ProductNameAndPrice>
      </ProductItem>
    )
  }

  render() {
    const { showPromotionItems, isVisible, products } = this.state
    const productsToDisplay = products.length > 8 ? products.slice(0, 8) : products
    return (
      <TrainersCarouselItemWrapper id="TrainersCarouselItemWrapper">
        <CarouselItemInner isVisible={isVisible}>
          <ContentBox>
            <TitlesSection>
              <PromotionSubTitle>{getString('OurHighlightProductsCarouselItemTitle')}</PromotionSubTitle>
              <PromotionItemsList>
                <PromotionItem index={0} isActive={showPromotionItems && isVisible}>
                  <ItemTitle>
                    <StyledDoneOutlineIcon />
                    {getString('OurHighlightProductsCarouselItemListItem1')}
                  </ItemTitle>
                </PromotionItem>
                <PromotionItem index={1} isActive={showPromotionItems && isVisible}>
                  <ItemTitle>
                    <StyledDoneOutlineIcon />
                    {getString('OurHighlightProductsCarouselItemListItem2')}
                  </ItemTitle>
                </PromotionItem>
                <PromotionItem index={2} isActive={showPromotionItems && isVisible}>
                  <ItemTitle>
                    <StyledDoneOutlineIcon />
                    {getString('OurHighlightProductsCarouselItemListItem3')}
                  </ItemTitle>
                </PromotionItem>
                <PromotionItem index={3} isActive={showPromotionItems && isVisible}>
                  <ItemTitle>
                    <StyledDoneOutlineIcon />
                    {getString('OurHighlightProductsCarouselItemListItem4')}
                  </ItemTitle>
                </PromotionItem>
                <PromotionItem index={4} isActive={showPromotionItems && isVisible}>
                  <ItemTitle>
                    <StyledDoneOutlineIcon />
                    {getString('OurHighlightProductsCarouselItemListItem5')}
                  </ItemTitle>
                </PromotionItem>
              </PromotionItemsList>
            </TitlesSection>
            <HighlightProducts>{productsToDisplay.map(this.renderProductItem)}</HighlightProducts>
          </ContentBox>
        </CarouselItemInner>
      </TrainersCarouselItemWrapper>
    )
  }
}

export default withRouter(OurHighlightProductsCarouselItem)
