import React, { Component } from 'react'
import styled from 'styled-components'
import { mediaQuery } from '../../services/global'
import getOnApple from '../../images/getOnApple.png'
import getOnGoogle from '../../images/getOnGoogle.png'

// Style
const AppleGoogleButtonsWrapper = styled.div`
  display: flex;
  ${mediaQuery.phone`
    flex-direction: column;
  `}
`
const AppleButton = styled.div`
  background-image: url(${getOnApple});
  background-repeat: no-repeat;
  background-size: contain;
  width: 200px;
  height: 64px;
  border-radius: 10px;
  margin: 20px 5px;
`
const GoogleButton = styled(AppleButton)`
  background-image: url(${getOnGoogle});
`

// Component
class AppleGoogleButtons extends Component {
  // Lifecycle methods

  // Helper methods

  // Render methods
  render() {
    return (
      <AppleGoogleButtonsWrapper>
        <a href="https://apps.apple.com/il/app/fitthybody/id1576877786" target="_blank">
          <AppleButton />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.fitthybody" target="_blank">
          <GoogleButton />
        </a>
      </AppleGoogleButtonsWrapper>
    )
  }
}

export default AppleGoogleButtons
