import React, { Component } from 'react'
import styled from 'styled-components'
import { mediaQuery, photoUrlPrefix } from '../../services/global'
import { colorPalette } from '../../styles/styleVariables'

// Style
const imageDimension = 290
const mobileImageDimension = 250
const Wrapper = styled.div`
  color: ${colorPalette.primary1.main};
  text-align: center;
`
const Content = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`
const ChangingImageWrapper = styled.div`
  width: 760px;
  height: ${imageDimension}px;
  position: relative;
  ${mediaQuery.phone`
    width: ${mobileImageDimension}px;
    height: ${mobileImageDimension + 230}px;
  `}
`
const ChangingImageBox = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: ${props => (props.isActive ? '1' : '0')};
  transition: all 2s;
  border-radius: 10px;
  border: 3px solid ${colorPalette.primary1.main};
  background: ${colorPalette.primary1.main};
  overflow: hidden;
  display: flex;
  align-items: center;
  ${mediaQuery.phone`
    flex-direction: column;
  `}
`
const ChangingImage = styled.div`
  width: ${imageDimension}px;
  height: ${imageDimension}px;
  flex: 0 0 ${imageDimension}px;
  background-image: url('${props => props.photoUrl}');
  background-size: cover;
  background-position: center;
  ${mediaQuery.phone`
    width: ${mobileImageDimension}px;
    height: ${mobileImageDimension}px;
    flex: 0 0 ${mobileImageDimension}px;;
  `}
`
const ChangingImageText = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  color: ${colorPalette.white.main};
  font-size: 16px;
  flex: 1;
  text-align: right;
  ${mediaQuery.phone`
    font-size: 14px;
  `}
`
const Quote = styled.div`
  font-size: 26px;
  margin-bottom: 10px;
  font-family: 'cafe';
  ${mediaQuery.phone`
    font-size: 16px;
    margin-bottom: 2px;
  `}
`
const PromotionTitle = styled.h2`
  font-size: 37px;
  font-weight: 500;
  margin: 0 20px;
  margin-top: 20px;
  ${mediaQuery.tablet`
    font-size: 30px;
  `}
  ${mediaQuery.phone`
    font-size: 25px;
    text-align: center;
    margin-top: 0;
  `}
`

// Component
class BeforeAfterBox extends Component {
  state = {
    currentPhotoIndex: 0,
  }

  // Lifecycle methods
  componentDidMount() {
    this.loadBeforeAfterPhotos(this.props)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.beforeAfterPhotos.length !== nextProps.beforeAfterPhotos.length) {
      this.loadBeforeAfterPhotos(nextProps)
    }
  }

  componentWillUnmount() {
    window.clearInterval(this.photoChangeInterval)
  }

  // Helper methods
  loadBeforeAfterPhotos = async props => {
    const { beforeAfterPhotos } = props
    if (beforeAfterPhotos.length === 0) {
      return null
    }

    const maxIndex = beforeAfterPhotos.length - 1
    this.photoChangeInterval = window.setInterval(() => {
      const { currentPhotoIndex } = this.state
      let newIndex = currentPhotoIndex + 1
      if (currentPhotoIndex === maxIndex) {
        newIndex = 0
      }
      this.setState({ currentPhotoIndex: newIndex })
    }, 10000)
  }

  // Render methods
  renderPhotoItem = (photo, itemIndex) => {
    const { currentPhotoIndex } = this.state
    let photoUrl = photo.PhotoURL
    if (photoUrl) {
      if (!photoUrl.includes('http')) {
        photoUrl = `${photoUrlPrefix}/beforeAfterPhotos/${photoUrl}`
      }
    }

    return (
      <ChangingImageBox isActive={currentPhotoIndex === itemIndex}>
        <ChangingImage photoUrl={photoUrl} />
        <ChangingImageText>
          <Quote>{photo.FeedbackText}</Quote>
          {photo.Description}
        </ChangingImageText>
      </ChangingImageBox>
    )
  }

  render() {
    const { beforeAfterPhotos } = this.props
    return (
      <Wrapper>
        <PromotionTitle>לקוחות מרוצים</PromotionTitle>
        <Content>
          <ChangingImageWrapper>{beforeAfterPhotos.map(this.renderPhotoItem)}</ChangingImageWrapper>
        </Content>
      </Wrapper>
    )
  }
}

export default BeforeAfterBox
