import React, { Component } from 'react'
import styled from 'styled-components'
import { Link, withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import SettingsIcon from '@material-ui/icons/Settings'
import ArticleIcon from '@material-ui/icons/Assignment'
import GroupIcon from '@material-ui/icons/Group'
import FeedbackIcon from '@material-ui/icons/ThumbUp'
import PhotosIcon from '@material-ui/icons/PhotoCamera'
import ShopIcon from '@material-ui/icons/Shop'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import FoodIcon from '@material-ui/icons/LocalDining'
import TextIcon from '@material-ui/icons/TextFormat'
import MoneyIcon from '@material-ui/icons/MonetizationOn'
import GiftCardIcon from '@material-ui/icons/CardGiftcard'
import FitnessIcon from '@material-ui/icons/FitnessCenter'
import DescriptionIcon from '@material-ui/icons/Description'
import formBackgroundLeft from '../../images/formBackground_left.jpg'
import formBackgroundRight from '../../images/formBackground_right.jpg'
import { mediaQuery, isAdminUser } from '../../services/global'
import { invokeAPIcall } from '../../services/rest'
import { colorPalette } from '../../styles/styleVariables'
import Breadcrumbs from '../Breadcrumbs'
import AdminCustomPaymentDialog from './AdminCustomPaymentDialog'

// Consts

// Style
const PageWrapper = styled.div`
  background-color: #f5fcfd;
  background: url(${formBackgroundLeft}) left bottom no-repeat, url(${formBackgroundRight}) right bottom no-repeat, #f7fdfd;
  background-size: 40% auto, 35% auto;
  padding: 20px;
  flex: 1;
  ${mediaQuery.phone`
    background: none;
  `}
`
const AdminButtonsWrapper = styled.div`
  width: 650px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${mediaQuery.phone`
    width: 100%;
    flex-wrap: nowrap;
    flex-direction: column;
  `}
`
const AdminScreenBtn = styled(Button)`
  && {
    flex: 0 0 45%;
    margin-bottom: 10px;
    a {
      color: ${colorPalette.white.main};
    }
    svg {
      position: absolute;
      top: 3px;
      right: 5px;
      width: 34px;
      height: 34px;
    }
  }
  ${mediaQuery.phone`
    && {
      flex: 1;
    }
  `}
`
const StyledLink = styled(Link)`
  flex: 0 0 45%;
  ${mediaQuery.phone`
    && {
      flex: 1;
    }
  `}
`

// Component
class AdminPanelScreen extends Component {
  state = {
    showAdminCustomPaymentDialog: false,
  }

  // Lifecycle methods

  // Helper methods

  // UI event methods
  handleClearCart = () => {
    this.props.clearCart()
  }

  handleCustomAdminCart = () => {
    this.props.clearCart()

    this.setState({ showAdminCustomPaymentDialog: true })
  }

  handleAdminCustomPaymentDialogClose = adminCustomPaymentValue => {
    const { addProductToCart, history } = this.props
    this.setState({ showAdminCustomPaymentDialog: false })

    if (Number(adminCustomPaymentValue)) {
      addProductToCart({ productId: 'adminCustom', quantity: 1, customPrice: Number(adminCustomPaymentValue) })
      history.push('/myCart')
    }
  }

  handleResetChallengeDays = async () => {
    if (window.confirm('חוויית החיטוב ב-90 יום במשתמש שלך תתאפס ותחזור ליום ה-1. האם אתה מאשר?')) {
      // invoke API call to reset the user's started challenge date
      try {
        await invokeAPIcall({ uri: 'api/users/ResetUserStartedChallenge', data: {}, method: 'PUT' })
        alert('בוצע איפוס')
      } catch (error) {}
    }
  }

  // Render methods
  render() {
    const { showAdminCustomPaymentDialog } = this.state
    const { currentUser } = this.props
    const isAdmin = isAdminUser(currentUser)
    if (isAdmin !== true) {
      return null
    }
    return (
      <PageWrapper>
        <Breadcrumbs previousItems={[]} currentItem={`מסכי ניהול`} />
        <AdminButtonsWrapper>
          <StyledLink to="/globalSettingsAdmin">
            <AdminScreenBtn variant="contained" color="primary" size="large" fullWidth>
              <SettingsIcon />
              ניהול הגדרות כלליות
            </AdminScreenBtn>
          </StyledLink>
          <StyledLink to="/productsAdmin">
            <AdminScreenBtn variant="contained" color="primary" size="large" fullWidth>
              <ShopIcon />
              ניהול מוצרים
            </AdminScreenBtn>
          </StyledLink>
          <StyledLink to="/articlesAdmin">
            <AdminScreenBtn variant="contained" color="primary" size="large" fullWidth>
              <ArticleIcon />
              ניהול מאמרים
            </AdminScreenBtn>
          </StyledLink>
          <StyledLink to="/contentPagesAdmin">
            <AdminScreenBtn variant="contained" color="primary" size="large" fullWidth>
              <DescriptionIcon />
              ניהול עמודי תוכן
            </AdminScreenBtn>
          </StyledLink>
          <StyledLink to="/couponsAdmin">
            <AdminScreenBtn variant="contained" color="primary" size="large" fullWidth>
              <GiftCardIcon />
              ניהול קופונים
            </AdminScreenBtn>
          </StyledLink>
          <StyledLink to="/monthlyInstallmentsLevelsAdmin">
            <AdminScreenBtn variant="contained" color="primary" size="large" fullWidth>
              <MoneyIcon />
              ניהול מספר תשלומים חודשי
            </AdminScreenBtn>
          </StyledLink>
          <StyledLink to="/ordersAdmin">
            <AdminScreenBtn variant="contained" color="primary" size="large" fullWidth>
              <ShoppingCartIcon />
              ניהול הזמנות
            </AdminScreenBtn>
          </StyledLink>
          <StyledLink to="/presetStringsAdmin">
            <AdminScreenBtn variant="contained" color="primary" size="large" fullWidth>
              <TextIcon />
              ניהול טקסטים
            </AdminScreenBtn>
          </StyledLink>
          <StyledLink to="/beforeAfterPhotosAdmin">
            <AdminScreenBtn variant="contained" color="primary" size="large" fullWidth>
              <PhotosIcon />
              ניהול תמונות לפני/אחרי
            </AdminScreenBtn>
          </StyledLink>
          <StyledLink to="/recipesAdmin">
            <AdminScreenBtn variant="contained" color="primary" size="large" fullWidth>
              <FoodIcon />
              ניהול מתכונים
            </AdminScreenBtn>
          </StyledLink>
          <StyledLink to="/workoutVideosAdmin">
            <AdminScreenBtn variant="contained" color="primary" size="large" fullWidth>
              <FitnessIcon />
              ניהול סרטוני אימונים
            </AdminScreenBtn>
          </StyledLink>
          <StyledLink to="/usersAdmin">
            <AdminScreenBtn variant="contained" color="primary" size="large" fullWidth>
              <GroupIcon />
              ניהול משתמשים רשומים
            </AdminScreenBtn>
          </StyledLink>
          <StyledLink to="/feedbackMessagesAdmin">
            <AdminScreenBtn variant="contained" color="primary" size="large" fullWidth>
              <FeedbackIcon />
              ניהול חוות דעת
            </AdminScreenBtn>
          </StyledLink>
          <AdminScreenBtn variant="contained" color="secondary" size="large" fullWidth onClick={this.handleClearCart}>
            <ShoppingBasketIcon />
            רוקן עגלת קניות
          </AdminScreenBtn>
          <AdminScreenBtn variant="contained" color="secondary" size="large" fullWidth onClick={this.handleCustomAdminCart}>
            <ShoppingBasketIcon />
            בצע עסקה ידנית
          </AdminScreenBtn>
          {/*<AdminScreenBtn variant="contained" color="secondary" size="large" fullWidth onClick={this.handleResetChallengeDays}>
            איפוס חוויית החיטוב ב-90 יום למשתמש שלי
    </AdminScreenBtn>*/}
        </AdminButtonsWrapper>
        {showAdminCustomPaymentDialog && <AdminCustomPaymentDialog handleClose={this.handleAdminCustomPaymentDialogClose} />}
      </PageWrapper>
    )
  }
}

export default withRouter(AdminPanelScreen)
