import { connect } from 'react-redux'
import ForgotPasswordScreen from '../components/ForgotPasswordScreen'
import { openSnackbar } from '../bundles/snackbar'

export default connect(
  state => ({}),
  {
    openSnackbar,
  },
)(ForgotPasswordScreen)
