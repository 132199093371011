import { connect } from 'react-redux'
import HomeScreen from '../components/HomeScreen'
import { openSnackbar } from '../bundles/snackbar'

export default connect(
  state => ({
    currentUser: state.currentUser.user,
  }),
  {
    openSnackbar,
  },
)(HomeScreen)
