import React, { Component } from 'react'
import styled, { keyframes } from 'styled-components'
import { withRouter } from 'react-router-dom'
import CheckIcon from '@material-ui/icons/DoneOutline'
import EditIcon from '@material-ui/icons/Edit'
import RightIcon from '@material-ui/icons/ChevronRight'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import MenuItem from '@material-ui/core/MenuItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Checkbox from '@material-ui/core/Checkbox'
import formBackgroundLeft from '../../images/formBackground_left.jpg'
import formBackgroundRight from '../../images/formBackground_right.jpg'
import fitthybodyLogo from '../../images/fitthybodyLogo.png'
import { mediaQuery, isAdminUser, mobileCheck } from '../../services/global'
import { invokeAPIcall } from '../../services/rest'
import { foodTypeOptions } from '../../services/recipes'
import { getString, multilineHtmlText } from '../../services/presetStringsHelper'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import StyledTextField from '../StyledTextField'
import StyledSelect from '../StyledSelect'

// Consts
function getRandomInt(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}
export const questionFields = [
  {
    fieldName: 'height',
    fieldLabel: 'גובה',
    type: 'dropdown',
    min: 130,
    max: 230,
    initialFocusOnVal: 170,
    labelFormat: '{item} ס״מ ({feetItem})',
    randomValGenerator: () => getRandomInt(130, 230),
    showInMyPersonalStatsProfile: true,
  },
  {
    fieldName: 'weight',
    fieldLabel: 'משקל',
    type: 'dropdown',
    min: 30,
    max: 200,
    initialFocusOnVal: 70,
    labelFormat: '{item} ק״ג ({libItem} ליב׳)',
    labelShortFormat: '{item} ק״ג',
    showInFormTypes: ['afterPhase1', 'afterPhase2', 'afterPhase3'],
    showInProgramDashboard: true,
    randomValGenerator: () => getRandomInt(30, 200),
    showInMyPersonalStatsProfile: true,
  },
  {
    fieldName: 'medicalCondition',
    fieldLabel: 'מצב גופני',
    fieldSubLabel: 'ניתן לסמן מספר אפשרויות',
    type: 'selector',
    multiSelect: true,
    otherOption: true,
    options: ['בריא', 'כאבי גב', 'כאבי ברכיים', 'כאבי צוואר', 'השמנה'],
    isRequired: true,
    randomValGenerator: () => ['בריא'],
    showInMyPersonalStatsProfile: true,
  },
  {
    fieldName: 'goals',
    fieldLabel: 'מטרות',
    fieldSubLabel: 'ניתן לסמן מספר אפשרויות',
    type: 'selector',
    multiSelect: true,
    otherOption: true,
    options: ['הרזיה', 'חיטוב ועיצוב', 'הגדלת מסת שריר', 'עלייה במשקל', 'העלאת הביטחון העצמי', 'סבולת לב-ריאה'],
    isRequired: true,
    randomValGenerator: () => ['הרזיה'],
    showInMyPersonalStatsProfile: true,
  },
  {
    fieldName: 'foodType',
    fieldLabel: 'סוג התזונה המועדפת',
    type: 'selector',
    options: foodTypeOptions.filter(item => item.value <= 4).map(item => item.label),
    isRequired: true,
    randomValGenerator: () => [foodTypeOptions[0].label],
    showInMyPersonalStatsProfile: true,
  },
  {
    fieldName: 'foodTypeExtra',
    fieldLabel: 'העדפות תזונתיות',
    fieldSubLabel: 'ניתן לסמן מספר אפשרויות או לא לסמן אף אחת',
    type: 'selector',
    multiSelect: true,
    options: foodTypeOptions.filter(item => item.value > 4).map(item => item.label),
    randomValGenerator: () => [foodTypeOptions[4].label],
  },
  {
    fieldName: 'fitnessLevel',
    fieldLabel: 'מהי רמת הכושר שלך?',
    type: 'selector',
    multiSelect: false,
    options: ['לא בכושר בכלל', 'מתאמן מתחיל', 'מתאמן מדי פעם', 'מתאמן באופן קבוע', 'אתלט מנוסה'],
    isRequired: true,
    randomValGenerator: () => 'לא בכושר בכלל',
    showInMyPersonalStatsProfile: true,
  },
  /*{
    fieldName: 'timeForTraining',
    fieldLabel: 'שעות נוחות לאימון',
    type: 'selector',
    multiSelect: true,
    options: ['בוקר', 'צוהריים', 'ערב'],
    randomValGenerator: () => ['בוקר'],
  },*/
  {
    fieldName: 'armCircumference',
    fieldLabel: 'היקף זרוע',
    type: 'dropdown',
    min: 10,
    max: 100,
    labelFormat: '{item} ס״מ',
    showInFormTypes: ['afterPhase1', 'afterPhase2', 'afterPhase3'],
    showInProgramDashboard: true,
    dontShowInStartPhase: true,
    canSkip: true,
    randomValGenerator: () => getRandomInt(10, 100),
  },
  {
    fieldName: 'chestCircumference',
    fieldLabel: 'היקף חזה',
    type: 'dropdown',
    min: 40,
    max: 150,
    labelFormat: '{item} ס״מ',
    showInFormTypes: ['afterPhase1', 'afterPhase2', 'afterPhase3'],
    showInProgramDashboard: true,
    dontShowInStartPhase: true,
    canSkip: true,
    randomValGenerator: () => getRandomInt(40, 150),
  },
  {
    fieldName: 'waistCircumference',
    fieldLabel: 'היקף מותן',
    type: 'dropdown',
    min: 30,
    max: 150,
    labelFormat: '{item} ס״מ',
    showInFormTypes: ['afterPhase1', 'afterPhase2', 'afterPhase3'],
    showInProgramDashboard: true,
    dontShowInStartPhase: true,
    canSkip: true,
    randomValGenerator: () => getRandomInt(30, 150),
  },
  {
    fieldName: 'thighCircumference',
    fieldLabel: 'היקף ירך',
    type: 'dropdown',
    min: 30,
    max: 180,
    labelFormat: '{item} ס״מ',
    showInFormTypes: ['afterPhase1', 'afterPhase2', 'afterPhase3'],
    showInProgramDashboard: true,
    dontShowInStartPhase: true,
    canSkip: true,
    randomValGenerator: () => getRandomInt(30, 180),
  },
  {
    fieldName: 'useMedications',
    fieldLabel: 'האם נוטל.ת תרופות או תוספים?',
    type: 'selector',
    multiSelect: false,
    booleanSelect: true,
    freeTextForYes: true,
    randomValGenerator: () => false,
    dontShowInStartPhase: true,
    showInMyPersonalStatsProfile: true,
  },
  {
    fieldName: 'wantToUseSupplements',
    fieldLabel: 'האם תרצה.י לקבל הצעות לתוספי תזונה טבעיים המותאמים לך?',
    fieldSubLabel: 'לתוצאות מהירות יותר ולשיפור המצב הבריאותי, יישלחו הצעות למייל',
    type: 'selector',
    multiSelect: false,
    booleanSelect: true,
    randomValGenerator: () => false,
    dontShowInStartPhase: true,
  },
  {
    fieldName: 'bodyFatPerc',
    fieldLabel: 'אחוזי שומן',
    type: 'dropdown',
    dropdownUnknownVal: 'לא ידוע',
    min: 5,
    max: 50,
    labelFormat: '{item}%',
    showInFormTypes: ['afterPhase1', 'afterPhase2', 'afterPhase3'],
    showInProgramDashboard: true,
    dontShowInStartPhase: true,
    canSkip: true,
    randomValGenerator: () => getRandomInt(5, 50),
  },
  {
    fieldName: 'photo',
    fieldLabel: 'תמונת גוף בפרופיל',
    fieldSubLabel: 'בטן חשופה',
    type: 'fileUpload',
    showInFormTypes: ['afterPhase2', 'afterPhase3'],
    dontShowInStartPhase: true,
    canSkip: true,
  },
  {
    fieldName: 'notes',
    fieldLabel: 'הערות נוספות',
    type: 'textbox',
    placeholder: '(ניתן להשאיר ריק)',
    dontShowInStartPhase: true,
    showInMyPersonalStatsProfile: true,
  },
]

// Style
const PageWrapper = styled.div`
  background-color: #f5fcfd;
  background: url(${formBackgroundLeft}) left bottom no-repeat, url(${formBackgroundRight}) right bottom no-repeat, #f7fdfd;
  background-size: 40% auto, 35% auto;
  padding: 60px 20px;
  flex: 1;
  min-height: 670px;
  ${mediaQuery.phone`
    background: none;
  `}
`
const PersonalStatsFormWrapper = styled.div`
  position: relative;
  max-width: 580px;
  margin: 0 auto;
  ${mediaQuery.phone`
    padding-top: env(safe-area-inset-top);
  `}
`
const FormWrapper = styled.div`
  background-color: ${colorPalette.white.main};
  max-width: 580px;
  margin: 0 auto;
  padding: 20px;
  color: ${colorPalette.primary1.main};
  ${globalStyles.boxShadow}
  ${globalStyles.boxRadius}
  z-index: 10;
  position: relative;
  ${mediaQuery.phone`
    margin: 0 15px;
    display: ${props => (props.isPreviewMode ? 'none' : 'block')};
  `}
`
const AnswersWrapper = styled(FormWrapper)`
  position: absolute;
  top: 0;
  transition: all 0.8s;
  width: ${props => (props.isPreviewMode ? '580px' : '280px')};
  left: ${props => (props.isPreviewMode ? '0px' : '-300px')};
  ${mediaQuery.phone`
    position: relative;
    margin: 0 30px;
    margin-bottom: 10px;
    left: auto;
    width: auto;
    padding-bottom: 48px;
    display: block !important;
  `}
`
const PageTitle = styled.h1`
  margin-bottom: 20px;
`
const AnswersTitle = styled.h3`
  margin-bottom: 20px;
`
const PageDesc = styled.div``
const ObjectiveList = styled.ul`
  margin: 9px 0;
  margin-right: 10px;
`
const ObjectiveItem = styled.li`
  display: flex;
  align-items: center;
`
const StyledCheckIcon = styled(CheckIcon)`
  && {
    width: 18px;
    height: 18px;
    margin-left: 8px;
  }
`
const StyledEditIcon = styled(EditIcon)`
  && {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    cursor: pointer;
  }
`
const QuestionBoxWrapper = styled.div`
  background-color: ${colorPalette.white.main};
  max-width: 420px;
  margin: 20px auto;
  padding: 20px;
  color: ${colorPalette.primary1.main};
  text-align: center;
  ${globalStyles.boxShadow}
  ${globalStyles.boxRadius}
  transition: all 0.3s ease-in-out;
  transform: translateY(${props => (props.isQuestionBoxExiting ? '-215px' : '0')});
  ${mediaQuery.phone`
    margin: 20px 30px;
  `}
`
const QuestionLabel = styled.h4`
  margin-bottom: 10px;
`
const QuestionSubLabel = styled.h6`
  font-size: 13px;
  margin-bottom: 10px;
`
const FileUploadSubLabel = styled(QuestionSubLabel)`
  font-size: 11px;
`
const QuestionButtonWrapper = styled.div`
  margin-top: 14px;
`
const SelectorButton = styled(Button)`
  && {
    margin-left: 5px;
    margin-bottom: 5px;
    padding-right: 20px;
  }
`
const AnswerList = styled.ul`
  max-height: 300px;
  overflow: auto;
`
const AnswerItem = styled.li``
const AnswerTopRow = styled.div`
  display: flex;
  align-items: center;
`
const AnswerBottomRow = styled(AnswerTopRow)`
  display: flex;
  align-items: center;
  margin-right: 26px;
  font-size: 11px;
`
const SelectorButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  ${mediaQuery.phone`
    .MuiButton-label {
      font-size: 11px;
    }
  `}
`
const SelectorButtonCheckIcon = styled(CheckIcon)`
  && {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 8px;
    right: 4px;
    opacity: ${props => (props.selected ? '1' : '0')};
  }
`
const SubmitButton = styled(Button)`
  && {
    margin-top: 30px;
    font-size: 22px;
    line-height: 1.4;
    ${mediaQuery.phone`
      font-size: 16px;
    `}
  }
`
const PrevQuestionButton = styled.div`
  width: 40px;
  height: 40px;
  position: absolute;
  right: -60px;
  top: 40%;
  background-color: ${colorPalette.white.main};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colorPalette.tertiary1.main};
  cursor: pointer;
  ${mediaQuery.phone`
    width: 30px;
    height: 30px;
    right: -40px;
  `}
`
const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    margin-bottom: 0;
    margin-top: 20px;
  }
`

/* Loading Mode */
const fitthybodyLogoAnimation = keyframes`
  from {
    right: 100%;
  }
  to {
    right: 0%;
  }
`
const FinalLoadingWrapper = styled(PageWrapper)`
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
`
const FitthybodyLogoLoadingWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  width: 474px;
  height: 144px;
  ${mediaQuery.phone`
  width: 294px;
  height: 89px;
    `}
`
const LoadingTitle = styled.h2`
  color: ${colorPalette.primary1.main};
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: -60px;
  ${mediaQuery.phone`
  bottom: -45px;
  font-size: 24px;
    `}
`
const FitthybodyLogo = styled.div`
  background-image: url(${fitthybodyLogo});
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.2;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`
const FitthybodyLogoFilled = styled(FitthybodyLogo)`
  opacity: 1;
  right: 0%;
  animation: ${fitthybodyLogoAnimation} 6s ease-in 0s;
  animation-fill-mode: both;
`
const UserPhoto = styled.div`
  width: 200px;
  height: 200px;
  background-image: url('${props => props.photoUrl}');
  background-position: center;
  background-size: cover;
  margin: auto;
`
const LoadingIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`
const UserPhotoButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  & > div,
  & > label {
    margin: 0 5px;
  }
`
const AgreeTermsWrapper = styled.div`
  margin-top: 20px;
`
const ConfirmationSmallLabel = styled.div`
  font-size: 12px;
`

// Component
class PersonalStatsScreen extends Component {
  state = {
    initialValues: {
      firstName: '',
    },
    currentQuestionIndex: 0,
    currentQuestionValue: null,
    nextQuestionIndex: 1,
    answers: [],
    isQuestionBoxExiting: false,
    isPreviewMode: false,
    otherValue: null,
    yesDescValue: null,
    approvedStats: false,
    isFinalLoadingMode: false,
    addedPhotoFile: null,
    isSubmiting: false,
  }

  // Lifecycle methods
  componentDidMount() {
    // in mobile - prevent the option to click on browser BackButton
    if (mobileCheck()) {
      window.history.pushState(null, document.title, window.location.href)
      window.addEventListener('popstate', function(event) {
        window.history.pushState(null, document.title, window.location.href)
      })
    }
  }

  // Helper methods
  validate = values => {
    let errors = {}
    return errors
  }

  getQuestionFields = () => {
    const { showAsModalType } = this.props
    if (showAsModalType) {
      return questionFields.filter(question => question.showInFormTypes && question.showInFormTypes.includes(showAsModalType))
    }
    return questionFields.filter(question => !question.dontShowInStartPhase)
  }

  questionFieldToLabel = currentQuestionIndex => {
    return this.getQuestionFields()[currentQuestionIndex].fieldLabel
  }

  convertToFeet = n => {
    var realFeet = (n * 0.3937) / 12
    var feet = Math.floor(realFeet)
    var inches = Math.round((realFeet - feet) * 12)
    return feet + ' פיט, ' + inches + ' אינ׳'
  }

  scrollAnswersToBottom = () => {
    if (mobileCheck()) {
      return
    }
    window.setTimeout(() => {
      this.answersListEnd.scrollIntoView({ behavior: 'smooth' })
    }, 500)
  }

  // UI event methods
  handleSubmit = async () => {
    const { history, showAsModalType, setCurrentUser, handlePersonalStatsSubmit } = this.props
    const { answers, addedPhotoFile } = this.state
    const phase = showAsModalType || 'start'

    this.setState({ isSubmiting: true })

    // if it's the first phase
    if (phase === 'start') {
      // invoke API call to update the user's started challenge date
      try {
        await invokeAPIcall({
          uri: 'api/users/UpdateUserStartedChallenge',
          data: {},
          method: 'PUT',
        })
      } catch (error) {}
    }

    const currentUserData = await invokeAPIcall({
      uri: 'api/users/getCurrentUser',
    })
    if (phase === 'start') {
      setCurrentUser({ user: currentUserData })
    }

    const answersObjForApi = answers.reduce((result, item) => {
      const fieldInfo = this.getQuestionFields()[item.questionIndex]
      let { answer, yesDesc } = item
      if (Array.isArray(answer)) {
        // special logic for FoodType (we want the IDs here, not the Labels)
        if (fieldInfo.fieldName.includes('foodType')) {
          if (answer.includes('הכול')) {
            // if picked only 'all' item - use all FoodTypeOptions
            answer = foodTypeOptions.map(item => item.value)
          } else {
            answer = answer.map(a => {
              const foodTypeObj = foodTypeOptions.find(f => f.label === a)
              if (foodTypeObj) {
                return foodTypeObj.value
              }
              return a
            })
          }
        }
        answer = answer.join(', ')
      }

      // special handling for this field
      if (fieldInfo.fieldName === 'foodTypeExtra') {
        if (answer) {
          result['foodType'] = `${result['foodType']}, ${answer}`
        }
      } else {
        result[fieldInfo.fieldName] = answer
      }

      if (yesDesc) {
        result[`${fieldInfo.fieldName}Text`] = yesDesc
      }

      return result
    }, {})

    const data = { ...answersObjForApi, phase }

    // invoke API call
    try {
      const responseData = await invokeAPIcall({
        uri: 'api/personalStats',
        data,
        method: 'POST',
      })

      // if success
      if (responseData.Key === true) {
        // upload photo that user uploaded
        if (addedPhotoFile != null) {
          const uploadData = new FormData()
          uploadData.append('file', addedPhotoFile)
          uploadData.append('phase', phase)
          uploadData.append('userId', currentUserData.UserID)
          await fetch('https://www.fitthybody.com/fitthybodyAPI/api/personalStats/UploadPhoto', {
            method: 'POST',
            body: uploadData,
          })
        }

        // if it's the first phase
        if (phase === 'start') {
          // invoke API call to update the user's meals list
          try {
            await invokeAPIcall({
              uri: 'api/users/InitMealListOfUser',
              data: {},
              method: 'PUT',
            })
          } catch (error) {}

          // invoke API call to update the user's workouts list
          try {
            await invokeAPIcall({
              uri: 'api/users/InitWorkoutListOfUser',
              data: {},
              method: 'PUT',
            })
          } catch (error) {}
        }

        if (showAsModalType) {
          if (handlePersonalStatsSubmit) {
            handlePersonalStatsSubmit()
          }
        } else {
          this.setState({ isFinalLoadingMode: true })
          window.setTimeout(() => {
            history.push('/program')
          }, 6000)
        }
      }
      // if failed
      else {
        alert(responseData.Value || 'התרחשה שגיאה')
      }
      this.setState({ isSubmiting: false })
    } catch (error) {
      console.log('error in set personal stats', error)
      this.setState({ isSubmiting: false })
    }
  }

  handleCurrentQuestionValue = e => {
    const { currentQuestionIndex } = this.state
    const fieldInfo = this.getQuestionFields()[currentQuestionIndex]

    this.setState({ currentQuestionValue: e.target.value }, () => {
      if (fieldInfo.type === 'dropdown') {
        window.setTimeout(() => {
          this.handleConfirmQuestion()
        }, 200)
      }
    })
  }

  handleSelectorOptionClick = optionItem => {
    const { currentQuestionValue, currentQuestionIndex } = this.state
    const fieldInfo = this.getQuestionFields()[currentQuestionIndex]
    let newValue = null
    if (fieldInfo.booleanSelect) {
      newValue = optionItem
    } else {
      newValue = [optionItem]
    }
    if (Array.isArray(currentQuestionValue)) {
      // if this optionItem already inside the array (currentQuestionValue)
      if (currentQuestionValue.includes(optionItem)) {
        newValue = currentQuestionValue.filter(item => item !== optionItem)
      } else {
        // depends on "multiselect" - add it to existing array, or create a new array
        if (fieldInfo.multiSelect) {
          newValue = [...currentQuestionValue, optionItem]
        } else {
          newValue = [optionItem]
        }
      }
    }

    // if the user clicked on 'all'
    if (fieldInfo.allItemsOption === true && optionItem === 'all') {
      if (currentQuestionValue && Array.isArray(currentQuestionValue) && currentQuestionValue.length === fieldInfo.options.length) {
        // empty all selections
        newValue = []
      } else {
        // mark all items as selected
        newValue = fieldInfo.options
      }
    }

    this.setState({ currentQuestionValue: newValue }, () => {
      if (fieldInfo.booleanSelect && (!fieldInfo.freeTextForYes || newValue === false)) {
        window.setTimeout(() => {
          this.handleConfirmQuestion()
        }, 200)
      }
    })
  }

  handleConfirmQuestion = () => {
    const { openSnackbar } = this.props
    const { answers, currentQuestionValue, currentQuestionIndex, otherValue, yesDescValue, nextQuestionIndex } = this.state
    let answer = currentQuestionValue
    if (Array.isArray(currentQuestionValue)) {
      answer.sort((a, b) => {
        if (a === b) return 0
        if (a === 'אחר') return 1
        if (b === 'אחר') return -1

        if (a < b) return 1
        if (a > b) return -1
        return 0
      })
      answer = answer.map(a => (a === 'other' ? `אחר${otherValue ? ` (${otherValue})` : ''}` : a))
      answer = answer.map(a => (a === 'all' ? `הכול` : a))
    }
    const fieldInfo = this.getQuestionFields()[currentQuestionIndex]
    if (answer == fieldInfo.dropdownUnknownVal && fieldInfo.dropdownUnknownVal != null) {
      answer = null
    }

    if (fieldInfo.isRequired && (answer == null || answer.length === 0)) {
      openSnackbar({
        message: 'יש לסמן את אחת מהאפשרויות',
        severity: 'failure',
      })
      return
    }

    let updatedAnswers = null

    // if this answer already exist in the array (meaning that the user is editing his answer)
    if (answers.filter(a => a.questionIndex === currentQuestionIndex).length > 0) {
      updatedAnswers = answers.map(a => {
        if (a.questionIndex === currentQuestionIndex) {
          return { ...a, answer, yesDesc: yesDescValue }
        }
        return a
      })
    }
    // if this answer doesn't already exist
    else {
      updatedAnswers = [
        ...answers,
        {
          questionIndex: currentQuestionIndex,
          answer,
          yesDesc: yesDescValue,
        },
      ]
    }

    this.setState({
      answers: updatedAnswers,
      isQuestionBoxExiting: true,
      currentQuestionValue: null,
      otherValue: null,
      yesDescValue: null,
    })
    window.setTimeout(() => {
      this.setState({
        isQuestionBoxExiting: false,
        currentQuestionIndex: nextQuestionIndex,
        nextQuestionIndex: nextQuestionIndex + 1,
        isPreviewMode: updatedAnswers.length === this.getQuestionFields().length,
      })
    }, 300)

    this.scrollAnswersToBottom()
  }

  handleOtherValueChange = e => {
    this.setState({ otherValue: e.target.value })
  }

  handleYesDescValueChange = e => {
    this.setState({ yesDescValue: e.target.value })
  }

  handlePrevClick = () => {
    const { answers, currentQuestionIndex, nextQuestionIndex } = this.state
    this.setState({
      answers: answers.filter(item => item.questionIndex !== currentQuestionIndex - 1),
      isQuestionBoxExiting: true,
      currentQuestionValue: null,
      otherValue: null,
      yesDescValue: null,
      isPreviewMode: false,
    })
    window.setTimeout(() => {
      this.setState({
        isQuestionBoxExiting: false,
        currentQuestionIndex: currentQuestionIndex - 1,
        nextQuestionIndex: nextQuestionIndex - 1,
      })
    }, 300)
  }

  handleEditAnswerClick = questionIndex => {
    const { currentQuestionIndex } = this.state
    this.setState({
      isQuestionBoxExiting: true,
      currentQuestionValue: null,
      otherValue: null,
      yesDescValue: null,
      isPreviewMode: false,
    })
    window.setTimeout(() => {
      this.setState({
        isQuestionBoxExiting: false,
        currentQuestionIndex: questionIndex,
        nextQuestionIndex: currentQuestionIndex,
      })
    }, 300)
  }

  handleApproveStatsChange = e => {
    this.setState({ approvedStats: e.target.checked })
  }

  handlePhotoFileChange = e => {
    let fileName = e.target.value
    if (fileName.includes('\\')) {
      const fileNameParts = fileName.split('\\')
      fileName = fileNameParts[fileNameParts.length - 1]
    }

    let addedPhotoFile = null
    const files = document.querySelector('input[type="file"]') && document.querySelector('input[type="file"]').files
    if (files.length > 0) {
      addedPhotoFile = files[0]
    }

    if (addedPhotoFile) {
      // more than 4MB
      if (addedPhotoFile.size > 4000000) {
        alert('משקל הקובץ גדול מדי: ' + addedPhotoFile.size)
        return false
      }
    }

    this.setState({ currentQuestionValue: fileName, addedPhotoFile })
  }

  handleAutoFillMyStats = () => {
    let updatedAnswers = []
    const questionFields = this.getQuestionFields()

    for (let index = 0; index < questionFields.length; index++) {
      const question = questionFields[index]
      updatedAnswers = [
        ...updatedAnswers,
        {
          questionIndex: index,
          answer: question.randomValGenerator ? question.randomValGenerator() : '',
          yesDesc: '',
        },
      ]
    }

    this.setState({
      answers: updatedAnswers,
      isQuestionBoxExiting: false,
      currentQuestionValue: null,
      otherValue: null,
      yesDescValue: null,
      isPreviewMode: true,
    })
  }

  handleSkipToEnd = () => {
    // skip to the end of the questions
    this.setState({
      //currentQuestionIndex: questionFields.length,
      isQuestionBoxExiting: false,
      currentQuestionValue: null,
      otherValue: null,
      yesDescValue: null,
      isPreviewMode: true,
    })
  }

  // Render methods
  renderAnswer = answerItem => {
    let { answer, questionIndex, yesDesc } = answerItem
    const fieldInfo = this.getQuestionFields()[questionIndex]

    const questionLabel = this.questionFieldToLabel(questionIndex)
    let answerLabel = answer
    if (Array.isArray(answerLabel)) {
      answerLabel = answerLabel.join(', ')
    } else if (fieldInfo.type === 'dropdown') {
      answerLabel = fieldInfo.labelFormat
        .replace('{item}', answerLabel)
        .replace('{libItem}', Math.round(Number(answerLabel) * 2.204))
        .replace('{feetItem}', this.convertToFeet(answerLabel))
    } else if (fieldInfo.type === 'selector' && fieldInfo.booleanSelect) {
      answerLabel = answer === true ? 'כן' : 'לא'
    }

    if (yesDesc) {
      answerLabel = `${answerLabel} (${yesDesc})`
    }
    if (answer == null && fieldInfo.dropdownUnknownVal != null) {
      answerLabel = fieldInfo.dropdownUnknownVal
    }

    return (
      <AnswerItem>
        <AnswerTopRow>
          <StyledCheckIcon />
          {questionLabel}
        </AnswerTopRow>
        <AnswerBottomRow>
          {answerLabel}
          <StyledEditIcon onClick={this.handleEditAnswerClick.bind(this, questionIndex)} />
        </AnswerBottomRow>
      </AnswerItem>
    )
  }

  renderFieldComponent = () => {
    const { currentQuestionIndex, currentQuestionValue, otherValue, yesDescValue } = this.state
    const { showAsModalType } = this.props
    const fieldInfo = this.getQuestionFields()[currentQuestionIndex]
    const dropdownOptions = []
    if (fieldInfo.dropdownUnknownVal) {
      dropdownOptions.push({
        value: fieldInfo.dropdownUnknownVal,
        label: fieldInfo.dropdownUnknownVal,
      })
    }
    for (let i = fieldInfo.min; i <= fieldInfo.max; i++) {
      let label = fieldInfo.labelFormat
      label = label.replace('{item}', i)
      label = label.replace('{libItem}', Math.round(i * 2.204))
      label = label.replace('{feetItem}', this.convertToFeet(i))
      dropdownOptions.push({ value: i, label })
    }
    let fieldOptions = []
    if (fieldInfo.type === 'selector') {
      if (fieldInfo.booleanSelect) {
        fieldOptions = [{ value: true, label: 'כן' }, { value: false, label: 'לא' }]
      } else {
        fieldOptions = fieldInfo.options.map(option => ({
          value: option,
          label: option,
        }))
      }
      if (fieldInfo.otherOption) {
        fieldOptions = [...fieldOptions, { value: 'other', label: 'אחר' }]
      }
      if (fieldInfo.allItemsOption) {
        fieldOptions = [...fieldOptions, { value: 'all', label: 'הכול' }]
      }
    }
    // if the user uploaded a photo
    let photoUrl = null
    const files = document.querySelector('input[type="file"]') && document.querySelector('input[type="file"]').files
    if (files && files.length > 0) {
      photoUrl = URL.createObjectURL(files[0])
    }
    return (
      <>
        <QuestionLabel>{this.questionFieldToLabel(currentQuestionIndex)}</QuestionLabel>
        {fieldInfo.fieldSubLabel && <QuestionSubLabel>({fieldInfo.fieldSubLabel})</QuestionSubLabel>}
        {fieldInfo.type === 'dropdown' && (
          <>
            <StyledSelect
              selectParams={{
                name: fieldInfo.fieldName,
                value: currentQuestionValue,
                onChange: this.handleCurrentQuestionValue,
                margin: 'normal',
                fullWidth: true,
                inputProps: {
                  name: fieldInfo.fieldName,
                  id: `${fieldInfo.fieldName}-simple`,
                },
              }}
              htmlFor={`${fieldInfo.fieldName}-simple`}
            >
              {dropdownOptions.map((item, index) => {
                const autoFocusItem = fieldInfo.initialFocusOnVal
                  ? item.value == fieldInfo.initialFocusOnVal
                  : index === Math.floor(dropdownOptions.length / 2)
                return (
                  <MenuItem value={item.value} autoFocus={autoFocusItem}>
                    {item.label}
                  </MenuItem>
                )
              })}
            </StyledSelect>
            {showAsModalType && fieldInfo.canSkip && (
              <QuestionButtonWrapper>
                <Button variant="contained" color="primary" onClick={this.handleSkipToEnd}>
                  דלג
                </Button>
              </QuestionButtonWrapper>
            )}
          </>
        )}
        {fieldInfo.type === 'selector' && (
          <>
            <SelectorButtonWrapper>
              {fieldOptions.map(item => {
                let selected = false
                if (fieldInfo.booleanSelect) {
                  selected = currentQuestionValue === item.value
                } else {
                  selected = Array.isArray(currentQuestionValue) && currentQuestionValue.includes(item.value)
                }
                return (
                  <SelectorButton variant="outlined" color="primary" onClick={this.handleSelectorOptionClick.bind(this, item.value)}>
                    {item.label}
                    <SelectorButtonCheckIcon selected={selected} />
                  </SelectorButton>
                )
              })}
            </SelectorButtonWrapper>
            {Array.isArray(currentQuestionValue) && currentQuestionValue.includes('other') && (
              <StyledTextField
                margin="normal"
                fullWidth
                value={otherValue}
                onChange={this.handleOtherValueChange}
                placeholder="פירוט"
              ></StyledTextField>
            )}
            {fieldInfo.freeTextForYes && currentQuestionValue === true && (
              <StyledTextField
                margin="normal"
                fullWidth
                value={yesDescValue}
                onChange={this.handleYesDescValueChange}
                placeholder="פירוט"
              ></StyledTextField>
            )}
            {(!fieldInfo.booleanSelect || (fieldInfo.freeTextForYes && currentQuestionValue === true)) && (
              <QuestionButtonWrapper>
                <Button variant="contained" color="primary" onClick={this.handleConfirmQuestion}>
                  אישור
                </Button>
              </QuestionButtonWrapper>
            )}
          </>
        )}
        {fieldInfo.type === 'textbox' && (
          <>
            <StyledTextField
              name={fieldInfo.fieldName}
              margin="normal"
              fullWidth
              placeholder={fieldInfo.placeholder}
              value={currentQuestionValue}
              onChange={this.handleCurrentQuestionValue}
            ></StyledTextField>
            <QuestionButtonWrapper>
              <Button variant="contained" color="primary" onClick={this.handleConfirmQuestion}>
                אישור
              </Button>
            </QuestionButtonWrapper>
          </>
        )}
        {fieldInfo.type === 'fileUpload' && (
          <>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="raised-button-file"
              type="file"
              onChange={e => {
                this.handlePhotoFileChange(e)
              }}
            />
            <UserPhotoButtonsWrapper>
              <label htmlFor="raised-button-file">
                <Button variant="contained" component="span" color="primary">
                  לחץ לבחירת תמונה
                </Button>
              </label>
              {photoUrl && (
                <QuestionButtonWrapper>
                  <Button variant="contained" color="primary" onClick={this.handleConfirmQuestion}>
                    אישור
                  </Button>
                </QuestionButtonWrapper>
              )}
            </UserPhotoButtonsWrapper>
            <FileUploadSubLabel>משקל קובץ מקסימלי: 3MB, סוג קובץ: JPG/PNG/BMP</FileUploadSubLabel>
            {photoUrl && (
              <>
                <UserPhoto photoUrl={photoUrl} />
              </>
            )}
            {showAsModalType && fieldInfo.canSkip && (
              <QuestionButtonWrapper>
                <Button variant="contained" color="primary" onClick={this.handleSkipToEnd}>
                  דלג
                </Button>
              </QuestionButtonWrapper>
            )}
          </>
        )}
      </>
    )
  }

  renderPersonalStatsContent = () => {
    const { showAsModalType, currentUser } = this.props
    const { answers, isQuestionBoxExiting, currentQuestionIndex, isPreviewMode, approvedStats, isSubmiting } = this.state
    const isAdmin = isAdminUser(currentUser)

    let pageTitle = getString('PersonalStatsWindowStartTitle')
    let pageDesc = <div>{multilineHtmlText(getString('PersonalStatsWindowStartText'))}</div>

    switch (showAsModalType) {
      case 'afterPhase1':
        pageTitle = getString('PersonalStatsWindow45Title')
        pageDesc = <div>{multilineHtmlText(getString('PersonalStatsWindow45Text'))}</div>
        break
      case 'afterPhase2':
        pageTitle = getString('PersonalStatsWindow75Title')
        pageDesc = <div>{multilineHtmlText(getString('PersonalStatsWindow75Text'))}</div>
        break
      case 'afterPhase3':
        pageTitle = getString('PersonalStatsWindow90Title')
        pageDesc = <div>{multilineHtmlText(getString('PersonalStatsWindow90Text'))}</div>
        break
      default:
        break
    }

    return (
      <PersonalStatsFormWrapper>
        <FormWrapper isPreviewMode={isPreviewMode}>
          <PageTitle>{pageTitle}</PageTitle>
          <PageDesc>{pageDesc}</PageDesc>
          {isAdmin && (
            <Button variant="contained" color="secondary" onClick={this.handleAutoFillMyStats}>
              מילוי אוטומטי של הנתונים
            </Button>
          )}
        </FormWrapper>
        {currentQuestionIndex < this.getQuestionFields().length && !isPreviewMode && (
          <>
            <QuestionBoxWrapper isQuestionBoxExiting={isQuestionBoxExiting}>
              {this.renderFieldComponent()}
              {currentQuestionIndex > 0 && (
                <PrevQuestionButton onClick={this.handlePrevClick}>
                  <RightIcon />
                </PrevQuestionButton>
              )}
            </QuestionBoxWrapper>
          </>
        )}
        {answers.length > 0 && (
          <AnswersWrapper isPreviewMode={isPreviewMode}>
            <AnswersTitle>הנתונים שלך</AnswersTitle>
            <AnswerList>
              {answers.map(this.renderAnswer)}
              <div
                ref={el => {
                  this.answersListEnd = el
                }}
              ></div>
            </AnswerList>
            {isPreviewMode && (
              <>
                {!showAsModalType && (
                  <AgreeTermsWrapper>
                    <FormControl required>
                      <StyledFormControlLabel
                        control={
                          <Checkbox
                            name="agreeTerms"
                            checked={approvedStats}
                            onChange={this.handleApproveStatsChange}
                            value={approvedStats}
                            color="primary"
                          />
                        }
                        label={
                          <div>
                            לאור מצבי הגופני, הרופא שלי מאשר לי להתאמן וכל הנתונים שמילאתי נכונים ומדויקים
                            <ConfirmationSmallLabel>* מצבי הבריאותי טוב וכשיר לפעילות גופנית</ConfirmationSmallLabel>
                          </div>
                        }
                      />
                    </FormControl>
                  </AgreeTermsWrapper>
                )}
                <SubmitButton
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                  onClick={this.handleSubmit}
                  disabled={(!approvedStats && !showAsModalType) || isSubmiting}
                >
                  המשך לתוכנית
                </SubmitButton>
                {isSubmiting && (
                  <LoadingIndicatorWrapper>
                    <CircularProgress />
                  </LoadingIndicatorWrapper>
                )}
                <PrevQuestionButton onClick={this.handlePrevClick}>
                  <RightIcon />
                </PrevQuestionButton>
              </>
            )}
          </AnswersWrapper>
        )}
      </PersonalStatsFormWrapper>
    )
  }

  render() {
    const { showAsModalType } = this.props
    const { isFinalLoadingMode } = this.state
    if (showAsModalType) {
      return <div>{this.renderPersonalStatsContent()}</div>
    }

    return (
      <>
        {!isFinalLoadingMode && <PageWrapper>{this.renderPersonalStatsContent()}</PageWrapper>}
        {isFinalLoadingMode && (
          <FinalLoadingWrapper>
            <FitthybodyLogoLoadingWrapper>
              <FitthybodyLogo />
              <FitthybodyLogoFilled />
              <LoadingTitle>בונים תוכנית המתאימה לך…</LoadingTitle>
            </FitthybodyLogoLoadingWrapper>
          </FinalLoadingWrapper>
        )}
      </>
    )
  }
}

export default withRouter(PersonalStatsScreen)
