import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import { InApp_ProductId } from '../services/global'
import { invokeAPIcall } from '../services/rest'

// Style
const FullScreenWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 80;
`

class InAppPurchaseStore extends React.Component {
  componentDidMount() {
    const { hideFullScreenLoading } = this.props

    if (!window.store) {
      console.log('Store not available')
      return
    }

    hideFullScreenLoading()

    const store = window.store

    // register products in the In-App purchase store
    store.register({
      id: InApp_ProductId,
      type: store.NON_CONSUMABLE,
    })

    store.error(error => {
      console.log('ERROR ' + error.code + ': ' + error.message)
    })

    // handle events of In-App purchase store
    store.when(InApp_ProductId).updated(product => {
      var productData = {
        loaded: product.loaded,
        title: product.title,
        description: product.description,
        price: product.price,
        priceMicros: product.priceMicros,
        currency: product.currency,
        canPurchase: product.canPurchase,
        owned: product.owned,
        state: product.state,
        valid: product.valid,
        transaction: product.transaction, // = { id: 'number', appStoreReceipt: 'base64string', type: 'ios-appstore' }
      }
      localStorage.setItem('challenge90Days_InAppPurchaseData', JSON.stringify(productData))
    })

    store.when(InApp_ProductId).approved(p => {
      p.finish()

      // check in localStorage if user purchased in-app
      var challenge90Days_InAppPurchaseCompleted = localStorage.getItem('challenge90Days_InAppPurchaseCompleted')
      if (challenge90Days_InAppPurchaseCompleted !== 'true') {
        localStorage.setItem('challenge90Days_InAppPurchaseCompleted', 'true')

        this.finalizeInAppPurchaseApi(p)
      } else {
        hideFullScreenLoading()
      }
    })

    store.when(InApp_ProductId).error(p => {
      hideFullScreenLoading()
    })

    store.when(InApp_ProductId).cancelled(p => {
      hideFullScreenLoading()
    })

    store.when(InApp_ProductId).verified(p => {})

    // refresh the store
    store.refresh()
  }

  // Helpers
  finalizeInAppPurchaseApi = async product => {
    const { currentUser, hideFullScreenLoading, setCurrentUser, history } = this.props

    // invoke API call
    try {
      const data = {
        receiptData: product.transaction && product.transaction.appStoreReceipt,
        userId: currentUser.UserID,
        type: product.transaction && product.transaction.type,
        paymentFee: product.priceMicros / 1000000,
      }

      const responseData = await invokeAPIcall({
        uri: `api/inAppPurchase/FinalizeInAppPurchase`,
        data,
        method: 'PUT',
      })

      // if API success
      if (responseData.Key === true) {
        // update the 'currentUser'
        const currentUserData = await invokeAPIcall({ uri: 'api/users/getCurrentUser' })
        setCurrentUser({ user: currentUserData })

        // show success dialog (in-app purchase completed)
        alert('ברכות. הרכישה בוצעה בהצלחה! ברגעים אלו נשלח אליך מייל אישור.')
        history.push('/program')
      }
      // if failed
      else {
        alert(responseData.Value || 'התרחשה שגיאה')
      }
    } catch (error) {
      console.log('error FinalizeInAppPurchase', error)
    }

    hideFullScreenLoading()
  }

  // Render methods
  render() {
    const { isLoading } = this.props
    if (isLoading) {
      return (
        <FullScreenWrapper>
          <CircularProgress />
        </FullScreenWrapper>
      )
    }
    return null
  }
}

export default withRouter(InAppPurchaseStore)
