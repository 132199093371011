import React from 'react'
import styled from 'styled-components'
import MenuItem from '@material-ui/core/MenuItem'
import { countryPhoneCodeList } from '../services/countries'
import StyledSelect from './StyledSelect'

// Style

function CountryPhoneCode({ value, handleChange, handleBlur, errors, touched }) {
  return (
    <StyledSelect
      selectParams={{
        name: 'countryPhoneCode',
        value: value,
        onChange: handleChange,
        onBlur: handleBlur,
        margin: 'normal',
        fullWidth: true,
        inputProps: {
          name: 'countryPhoneCode',
          id: 'countryPhoneCode-simple',
        },
      }}
      htmlFor="countryPhoneCode-simple"
      helperText={errors && errors.countryPhoneCode && touched.countryPhoneCode && errors.countryPhoneCode}
      error={errors && errors.countryPhoneCode && touched.countryPhoneCode}
    >
      {countryPhoneCodeList.map(item => (
        <MenuItem value={item.code}>{`${item.code} ${item.dialCode}`}</MenuItem>
      ))}
    </StyledSelect>
  )
}

export default CountryPhoneCode
