import { connect } from 'react-redux'
import SnackbarMessage from '../components/SnackbarMessage'
import { closeSnackbar } from '../bundles/snackbar'

export default connect(
  state => ({
    message: state.snackbar.message,
    severity: state.snackbar.severity,
    actionText: state.snackbar.actionText,
    actionOnClick: state.snackbar.actionOnClick,
  }),
  {
    closeSnackbar,
  },
)(SnackbarMessage)
