import React, { Component } from 'react'
import styled from 'styled-components'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import AccessibleIcon from '@material-ui/icons/Accessible'
import { mediaQuery } from '../services/global'
import { colorPalette, globalStyles } from '../styles/styleVariables'

// Consts

// Style
const AccessibilityMenuWrapper = styled.div`
  height: 100%;
  position: relative;
  ${mediaQuery.phone`
    display: none;
  `}
`
const AccessibilityButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 100%;
  padding: 10px 8px;
  background-color: ${props => (props.isActive ? colorPalette.white.main : 'transparent')};
  color: ${props => (props.isActive ? colorPalette.primary1.main : colorPalette.white.main)};
  ${globalStyles.customButton}
  &:hover {
    svg {
      color: ${props => (props.isActive ? colorPalette.primary1.main : colorPalette.tertiary1.light)};
    }
  }
`
const OptionsMenu = styled.div`
  position: absolute;
  top: 44px;
  left: 0;
  background-color: ${colorPalette.white.main};
  ${globalStyles.boxRadius}
  ${globalStyles.boxShadowPopup}
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  overflow: hidden;
  min-width: 200px;
  transition: all 0.3s;
  height: ${props => (props.isActive ? '295px' : '0px')};
`
const StyledMenuList = styled(MenuList)`
  && {
    padding: 0;
  }
`
const StyledMenuItem = styled(MenuItem)`
  && {
    color: ${colorPalette.primary1.main};
  }
`
const SectionTitle = styled.h5`
  color: ${colorPalette.primary1.main};
  margin: 10px;
  margin-top: 18px;
`

// Component
class AccessibilityMenu extends Component {
  state = {
    accessibilityButtonClicked: false,
  }

  // Lifecycle methods
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  // UI event methods
  handleMenuButtonClick = () => {
    const { accessibilityButtonClicked } = this.state
    this.setState({ accessibilityButtonClicked: !accessibilityButtonClicked })
  }

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.buttonRef && !this.buttonRef.contains(event.target)) {
      this.setState({ accessibilityButtonClicked: false })
    }
  }

  handleRegularColorsClick = () => {
    document.body.classList.remove('invert-colors')
    document.body.classList.remove('grayscale-colors')
  }

  handleInvertClick = () => {
    document.body.classList.add('invert-colors')
    document.body.classList.remove('grayscale-colors')
  }

  handleGrayscaleClick = () => {
    document.body.classList.add('grayscale-colors')
    document.body.classList.remove('invert-colors')
  }

  handleUnderlinedLinksClick = () => {
    document.body.classList.toggle('underlined-links')
  }

  handleUnderlinedHeadersClick = () => {
    document.body.classList.toggle('underlined-headers')
  }

  setWrapperRef = node => {
    this.wrapperRef = node
  }

  setButtonRef = node => {
    this.buttonRef = node
  }

  render() {
    const { accessibilityButtonClicked } = this.state
    return (
      <AccessibilityMenuWrapper>
        <AccessibilityButton
          isActive={accessibilityButtonClicked}
          onClick={this.handleMenuButtonClick}
          ref={this.setButtonRef}
          title="נגישות"
          type="button"
        >
          <AccessibleIcon />
        </AccessibilityButton>
        <OptionsMenu isActive={accessibilityButtonClicked} ref={this.setWrapperRef}>
          <SectionTitle first>סגנונות רקע</SectionTitle>
          <StyledMenuList>
            <StyledMenuItem onClick={this.handleRegularColorsClick}>
              <span>רגיל</span>
            </StyledMenuItem>
            <StyledMenuItem onClick={this.handleInvertClick}>
              <span>ניגודיות מוגברת</span>
            </StyledMenuItem>
            <StyledMenuItem onClick={this.handleGrayscaleClick}>
              <span>גווני אפור</span>
            </StyledMenuItem>
          </StyledMenuList>
          <SectionTitle>כלים נוספים</SectionTitle>
          <StyledMenuList>
            <StyledMenuItem onClick={this.handleUnderlinedLinksClick}>
              <span>הדגשת קישורים</span>
            </StyledMenuItem>
            <StyledMenuItem onClick={this.handleUnderlinedHeadersClick}>
              <span>הדגשת כותרות</span>
            </StyledMenuItem>
          </StyledMenuList>
        </OptionsMenu>
      </AccessibilityMenuWrapper>
    )
  }
}

export default AccessibilityMenu
