export function getProductPrices(productItem, globalSettingsData) {
  // handle the mode of 'Admin Custom Product' (with a custom value)
  if (productItem.ProductID === 'adminCustom') {
    return {
      listedPrice: productItem.Price,
      actualPrice: productItem.Price,
    }
  }

  let listedPrice = null
  let actualPrice = productItem.Price
  if (productItem.ListedPrice) {
    listedPrice = productItem.ListedPrice
  } else if (globalSettingsData && globalSettingsData.StoreGlobalDiscountPercentage) {
    listedPrice = productItem.Price
    actualPrice = productItem.Price * ((100 - globalSettingsData.StoreGlobalDiscountPercentage) / 100)
  }

  return {
    listedPrice,
    actualPrice,
    displayPricePerItem: productItem.DisplayPricePerItem,
    displayPricePerItemWord: productItem.DisplayPricePerItemWord,
  }
}

// calculate how many MonthlyInstallments the user can pay here (based on the OrderTotal)
export function calcMaxNumOfMonthlyInstallmentsToAllow(orderTotalWithShipment, monthlyInstallmentsLevels) {
  let maxNumOfMonthlyInstallmentsToAllow = null
  if (monthlyInstallmentsLevels.length > 0) {
    monthlyInstallmentsLevels.forEach(level => {
      if (orderTotalWithShipment < level.UpToPrice && maxNumOfMonthlyInstallmentsToAllow == null) {
        maxNumOfMonthlyInstallmentsToAllow = level.MaxNumOfInstallments
      }
    })
    // if this is above the maximum we defined in DB
    if (maxNumOfMonthlyInstallmentsToAllow == null) {
      maxNumOfMonthlyInstallmentsToAllow = monthlyInstallmentsLevels[monthlyInstallmentsLevels.length - 1].MaxNumOfInstallments
    }
  }
  return maxNumOfMonthlyInstallmentsToAllow || 1
}
