import React, { Component } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import { withRouter } from 'react-router-dom'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Checkbox from '@material-ui/core/Checkbox'
import CloseIcon from '@material-ui/icons/Close'
import { invokeAPIcall } from '../../services/rest'
import { mediaQuery } from '../../services/global'
import { requiredValidation, emailValidation, phoneNumValidation } from '../../services/formValidation'
import StyledTextField from '../StyledTextField'

// Consts

// Style
const StyledDialogTitle = styled(DialogTitle)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
  }
`
const StyledButton = styled(Button)`
  && {
    margin-left: 10px;
  }
  ${mediaQuery.phone`
    && {
      margin-left: 0;
      margin-bottom: 10px;
      flex: 1;
    }
  `}
`
const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 40px;
  ${mediaQuery.phone`
    flex-direction: column;
  `}
`

const FormWrapper = styled.div`
  padding: 20px 40px;
  padding-top: 0;
`
const FieldsRow = styled.div`
  display: ${props => (props.isHidden ? 'none' : 'flex')};
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin: 10px 0;
`
const TextboxFieldsRow = styled(FieldsRow)`
  margin-bottom: 25px;
`
const FieldCell = styled.div`
  flex: 0 0 100%;
  display: flex;
  justify-content: space-between;
`
const DoubleFieldCell = styled(FieldCell)`
  flex: 0 0 48%;
`
const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    margin-bottom: 0;
  }
`
const StyledFormHelperText = styled(FormHelperText)`
  && {
    margin-top: 0;
  }
`

// Component
class EndOfChallengeDialog extends Component {
  state = {
    initialValues: {
      endOfChallenge_howWent: '',
      endOfChallenge_allowPhoto: false,
      endOfChallenge_wantToInvite: false,
      endOfChallenge_friendInviteEmail: '',
      endOfChallenge_friendInvitePhone: '',
    },
  }

  // Lifecycle methods
  componentDidMount() {}

  // Helper methods
  validate = values => {
    let errors = {}

    if (requiredValidation(values.endOfChallenge_howWent)) {
      errors.endOfChallenge_howWent = requiredValidation(values.endOfChallenge_howWent)
    }
    if (values.endOfChallenge_wantToInvite) {
      if (requiredValidation(values.endOfChallenge_friendInviteEmail)) {
        errors.endOfChallenge_friendInviteEmail = requiredValidation(values.endOfChallenge_friendInviteEmail)
      } else if (emailValidation(values.endOfChallenge_friendInviteEmail)) {
        errors.endOfChallenge_friendInviteEmail = emailValidation(values.endOfChallenge_friendInviteEmail)
      }
      if (requiredValidation(values.endOfChallenge_friendInvitePhone)) {
        errors.endOfChallenge_friendInvitePhone = requiredValidation(values.endOfChallenge_friendInvitePhone)
      } else if (phoneNumValidation(values.endOfChallenge_friendInvitePhone)) {
        errors.endOfChallenge_friendInvitePhone = phoneNumValidation(values.endOfChallenge_friendInvitePhone)
      }
    }

    return errors
  }

  // UI event methods
  handleEmptyList = () => {
    const { ingredientsSavedListKey, handleClose } = this.props
    localStorage.removeItem(ingredientsSavedListKey)
    handleClose()
  }

  handleSubmit = async (values, { setSubmitting }) => {
    const { handleClose } = this.props
    const data = {
      ...values,
      endOfChallenge_friendInviteEmail: values.endOfChallenge_wantToInvite ? values.endOfChallenge_friendInviteEmail : '',
      endOfChallenge_friendInvitePhone: values.endOfChallenge_wantToInvite ? values.endOfChallenge_friendInvitePhone : '',
    }

    // invoke API call
    try {
      const responseData = await invokeAPIcall({
        uri: 'api/users/UpdateEndOfChallenge',
        data,
        method: 'POST',
      })

      // if API success
      if (responseData.Key === true) {
        handleClose()
      }
      // if failed
      else {
        alert(responseData.Value || 'התרחשה שגיאה')
      }
    } catch (error) {
      console.log('error in updating end of challenge values', error)
    }
    setSubmitting(false)
  }

  // Render methods
  render() {
    const { initialValues } = this.state
    const { handleClose, personalStats } = this.props
    const personalStatsItemsWithPhoto = personalStats ? personalStats.filter(item => item.Photo !== null).length : 0
    const userHasPhotosOfBeforeAfter = personalStatsItemsWithPhoto > 1

    return (
      <Dialog onClose={handleClose} open>
        <StyledDialogTitle id="simple-dialog-title" disableTypography>
          <Typography variant="h6">שתף את תהליך ההצלחה שלך</Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <DialogContent>
          <Formik validate={this.validate} initialValues={initialValues} onSubmit={this.handleSubmit}>
            {formikBag => {
              const { isSubmitting, values, errors, touched, handleChange, handleBlur, isValid, setFieldValue } = formikBag
              return (
                <FormWrapper>
                  <Form>
                    <TextboxFieldsRow>
                      <FieldCell>
                        <StyledTextField
                          label="סיכום החווייה"
                          helperText="תאר את השינוי הגופני והרגשי לפני שהתחלת את התהליך. איך עבר לך התהליך עצמו? איך תרמה לך התוכנית? ספר לנו, איך אתה מרגיש עם סיום התהליך? איזה שינוי עברת בהרגשה ובמראה?"
                          name="endOfChallenge_howWent"
                          value={values.endOfChallenge_howWent}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.endOfChallenge_howWent && touched.endOfChallenge_howWent}
                          margin="normal"
                          fullWidth
                          multiline
                          rows="4"
                        />
                      </FieldCell>
                    </TextboxFieldsRow>
                    <FieldsRow isHidden={!userHasPhotosOfBeforeAfter}>
                      <FieldCell>
                        <FormControl required error={errors.endOfChallenge_allowPhoto && touched.endOfChallenge_allowPhoto}>
                          <StyledFormControlLabel
                            control={
                              <Checkbox
                                name="endOfChallenge_allowPhoto"
                                checked={values.endOfChallenge_allowPhoto}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.endOfChallenge_allowPhoto}
                                color="primary"
                              />
                            }
                            label="אני מאשר לפרסם את התמונות שלי (לפני/אחרי) באתר. בתמורה אשמח לקבל קופון של 50 ש״ח לקנייה באתר"
                          />
                          <StyledFormHelperText>
                            {errors.endOfChallenge_allowPhoto && touched.endOfChallenge_allowPhoto && errors.endOfChallenge_allowPhoto}
                          </StyledFormHelperText>
                        </FormControl>
                      </FieldCell>
                    </FieldsRow>
                    <FieldsRow>
                      <FieldCell>
                        <FormControl required error={errors.endOfChallenge_wantToInvite && touched.endOfChallenge_wantToInvite}>
                          <StyledFormControlLabel
                            control={
                              <Checkbox
                                name="endOfChallenge_wantToInvite"
                                checked={values.endOfChallenge_wantToInvite}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.endOfChallenge_wantToInvite}
                                color="primary"
                              />
                            }
                            label="אני מעוניין להמליץ לחבר ולקבל קופון נוסף של 50 ש״ח לקנייה באתר"
                          />
                          <StyledFormHelperText>
                            {errors.endOfChallenge_wantToInvite &&
                              touched.endOfChallenge_wantToInvite &&
                              errors.endOfChallenge_wantToInvite}
                          </StyledFormHelperText>
                        </FormControl>
                      </FieldCell>
                    </FieldsRow>
                    {values.endOfChallenge_wantToInvite && (
                      <FieldsRow>
                        <DoubleFieldCell>
                          <StyledTextField
                            label={'* המייל של החבר'}
                            helperText={
                              errors.endOfChallenge_friendInviteEmail && touched.endOfChallenge_friendInviteEmail
                                ? errors.endOfChallenge_friendInviteEmail
                                : 'הזן את המייל של החבר שתרצה להזמין'
                            }
                            name="endOfChallenge_friendInviteEmail"
                            value={values.endOfChallenge_friendInviteEmail}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.endOfChallenge_friendInviteEmail && touched.endOfChallenge_friendInviteEmail}
                            margin="normal"
                            fullWidth
                          />
                        </DoubleFieldCell>
                        <DoubleFieldCell>
                          <StyledTextField
                            label={'* הטלפון של החבר'}
                            helperText={
                              errors.endOfChallenge_friendInvitePhone && touched.endOfChallenge_friendInvitePhone
                                ? errors.endOfChallenge_friendInvitePhone
                                : 'הזן את הטלפון של החבר'
                            }
                            name="endOfChallenge_friendInvitePhone"
                            value={values.endOfChallenge_friendInvitePhone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.endOfChallenge_friendInvitePhone && touched.endOfChallenge_friendInvitePhone}
                            margin="normal"
                            fullWidth
                          />
                        </DoubleFieldCell>
                      </FieldsRow>
                    )}
                    <ButtonsWrapper>
                      <StyledButton variant="contained" color="primary" disabled={!isValid || isSubmitting} type="submit">
                        אישור
                      </StyledButton>
                    </ButtonsWrapper>
                  </Form>
                </FormWrapper>
              )
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    )
  }
}

export default withRouter(EndOfChallengeDialog)
