import { connect } from 'react-redux'
import ChallengeAfterPaymentScreen from '../components/ChallengeAfterPaymentScreen'
import { setCurrentUser } from '../bundles/currentUser'

export default connect(
  state => ({}),
  {
    setCurrentUser,
  },
)(ChallengeAfterPaymentScreen)
