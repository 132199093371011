import { connect } from 'react-redux'
import ShoppingCartScreen from '../components/ShoppingCartScreen'
import { removeProductFromCart, updateProductQuantityInCart } from '../bundles/shoppingCart'
import { openSnackbar } from '../bundles/snackbar'

export default connect(
  state => ({
    productsInCart: state.shoppingCart.productsInCart,
    currentUser: state.currentUser.user,
  }),
  {
    removeProductFromCart,
    updateProductQuantityInCart,
    openSnackbar,
  },
)(ShoppingCartScreen)
