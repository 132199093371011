import React, { Component } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import { Link, withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress'
import formBackgroundLeft from '../../images/formBackground_left.jpg'
import formBackgroundRight from '../../images/formBackground_right.jpg'
import { mediaQuery, keysToLowerCase } from '../../services/global'
import { requiredValidation, numericValidation, noSpacesValidation } from '../../services/formValidation'
import { invokeAPIcall } from '../../services/rest'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import StyledTextField from '../StyledTextField'
import StyledSelect from '../StyledSelect'
import StyledCheckbox from '../StyledCheckbox'
import Breadcrumbs from '../Breadcrumbs'

// Style
const PageWrapper = styled.div`
  background-color: #f5fcfd;
  background: url(${formBackgroundLeft}) left bottom no-repeat, url(${formBackgroundRight}) right bottom no-repeat, #f7fdfd;
  background-size: 40% auto, 35% auto;
  padding: 20px;
  flex: 1;
  ${mediaQuery.phone`
    background: none;
  `}
`
const FormWrapper = styled.div`
  background-color: ${colorPalette.white.main};
  max-width: 680px;
  margin: 0 auto;
  padding: 20px;
  color: ${colorPalette.primary1.main};
  ${globalStyles.boxShadow}
  ${globalStyles.boxRadius}
`
const PageTitle = styled.h1`
  margin-bottom: 20px;
`
const FieldsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
`
const FieldCell = styled.div`
  flex: 0 0 100%;
`
const DiscountFieldCell = styled.div`
  flex: 0 0 40%;
`
const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  margin-top: 20px;
  ${mediaQuery.phone`
    width: 100%;
  `}
`
const StyledButton = styled(Button)`
  && {
  }
`
const LoadingIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5px;
`

// Component
class AddCouponScreen extends Component {
  state = {
    initialValues: {
      couponCode: '',
      fixedDiscount: null,
      percentageDiscount: null,
      specificProductId: null,
      maxNumOfUses: null,
      specificToChallenge: false,
      notifyToEmail: '',
    },
    allProducts: [],
  }

  // Lifecycle methods
  componentDidMount() {
    const editModeId = this.getEditModeId()
    if (editModeId != null) {
      this.loadCouponData(editModeId)
    }

    this.loadAllAvailableProducts()
  }

  // Helper methods
  getEditModeId = () => {
    const { match } = this.props
    const { params } = match
    return params.id
  }

  loadCouponData = async id => {
    let couponData = await invokeAPIcall({ uri: `api/coupons/${id}` })
    couponData = keysToLowerCase(couponData)
    couponData = {
      ...couponData,
      specificProductId: couponData.specificProductID,
    }
    this.setState({
      initialValues: couponData,
    })
  }

  loadAllAvailableProducts = async () => {
    const productList = await invokeAPIcall({ uri: 'api/products' })
    this.setState({
      allProducts: productList
        .filter(product => product.ProductCategoryID === 2)
        .map(product => ({
          id: product.ProductID,
          name: product.Name,
        })),
    })
  }

  validate = values => {
    let errors = {}

    if (requiredValidation(values.couponCode)) {
      errors.couponCode = requiredValidation(values.couponCode)
    }
    if (noSpacesValidation(values.couponCode)) {
      errors.couponCode = noSpacesValidation(values.couponCode)
    }
    if (requiredValidation(values.maxNumOfUses)) {
      errors.maxNumOfUses = requiredValidation(values.maxNumOfUses)
    }
    if (numericValidation(values.maxNumOfUses)) {
      errors.maxNumOfUses = numericValidation(values.maxNumOfUses)
    }
    if (requiredValidation(values.fixedDiscount) && requiredValidation(values.percentageDiscount)) {
      errors.fixedDiscount = 'יש להזין את אחת מסוגי ההנחות'
    }
    if (numericValidation(values.fixedDiscount)) {
      errors.fixedDiscount = numericValidation(values.fixedDiscount)
    }
    if (numericValidation(values.percentageDiscount)) {
      errors.percentageDiscount = numericValidation(values.percentageDiscount)
    }
    if (values.specificToChallenge && values.specificProductId != null && values.specificProductId != '') {
      errors.specificProductId = 'יש לבחור: או רלוונטי למוצר או רלוונטי לאתגר'
    }

    return errors
  }

  // UI event methods
  handleSubmit = async (values, { setSubmitting }) => {
    const editModeId = this.getEditModeId()
    const isEditMode = editModeId != null
    const { history } = this.props
    const data = {
      ...values,
    }

    // invoke API call
    try {
      const responseData = await invokeAPIcall({
        uri: isEditMode ? `api/coupons/${editModeId}` : 'api/coupons',
        data,
        method: isEditMode ? 'PUT' : 'POST',
      })

      // if API success
      if (responseData.Key === true) {
        history.push('/couponsAdmin')
      }
      // if failed
      else {
        alert(responseData.Value || 'התרחשה שגיאה')
      }
    } catch (error) {
      console.log('error in adding/updating new coupon', error)
    }
    setSubmitting(false)
  }

  // Render methods
  render() {
    const { initialValues, allProducts } = this.state
    const editModeId = this.getEditModeId()
    const isEditMode = editModeId != null

    return (
      <PageWrapper>
        <Breadcrumbs
          previousItems={[
            {
              label: `מסכי ניהול`,
              url: `/adminPanel`,
            },
          ]}
          currentItem={`ניהול קופונים`}
        />
        <FormWrapper>
          <PageTitle>{isEditMode ? 'עדכון קופון' : 'הוספת קופון חדש'}</PageTitle>
          <Formik validate={this.validate} initialValues={initialValues} onSubmit={this.handleSubmit} enableReinitialize={true}>
            {formikBag => {
              const { isSubmitting, values, errors, touched, handleChange, handleBlur, isValid } = formikBag
              return (
                <Form>
                  <FieldsRow>
                    <FieldCell>
                      <StyledTextField
                        label={'קוד קופון'}
                        name="couponCode"
                        value={values.couponCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.couponCode && touched.couponCode ? errors.couponCode : 'זה יהיה הקוד שיזין המשתמש כדי לקבל הנחה'}
                        error={errors.couponCode && touched.couponCode}
                        margin="normal"
                        fullWidth
                      />
                    </FieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <DiscountFieldCell>
                      <StyledTextField
                        label={'הנחה באחוזים'}
                        name="percentageDiscount"
                        value={values.percentageDiscount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.percentageDiscount && touched.percentageDiscount ? errors.percentageDiscount : 'הקלד אחוזים'}
                        error={errors.percentageDiscount && touched.percentageDiscount}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: values.percentageDiscount }}
                      />
                    </DiscountFieldCell>
                    או
                    <DiscountFieldCell>
                      <StyledTextField
                        label={'הנחה בסכום קבוע'}
                        name="fixedDiscount"
                        value={values.fixedDiscount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.fixedDiscount && touched.fixedDiscount ? errors.fixedDiscount : 'הקלד סכום ב-₪'}
                        error={errors.fixedDiscount && touched.fixedDiscount}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: values.fixedDiscount }}
                      />
                    </DiscountFieldCell>
                  </FieldsRow>
                  <br />
                  <br />
                  <br />
                  <FieldsRow>
                    <DiscountFieldCell>
                      <StyledSelect
                        selectParams={{
                          name: 'specificProductId',
                          value: values.specificProductId,
                          onChange: handleChange,
                          onBlur: handleBlur,
                          margin: 'normal',
                          fullWidth: true,
                          inputProps: {
                            name: 'specificProductId',
                            id: 'specificProductId-simple',
                          },
                        }}
                        label="האם רלוונטי רק למוצר ספציפי?"
                        htmlFor="specificProductId-simple"
                        helperText={errors.specificProductId && touched.specificProductId && errors.specificProductId}
                        error={errors.specificProductId && touched.specificProductId}
                        inputLabelShrink={values.specificProductId}
                      >
                        <MenuItem value={''}>ללא</MenuItem>
                        {allProducts.map(item => (
                          <MenuItem value={item.id}>{item.name}</MenuItem>
                        ))}
                      </StyledSelect>
                    </DiscountFieldCell>
                    או
                    <DiscountFieldCell>
                      <StyledCheckbox
                        label={'האם רלוונטי רק לאתגר ה-90?'}
                        name="specificToChallenge"
                        value={values.specificToChallenge}
                        checked={values.specificToChallenge}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.specificToChallenge && touched.specificToChallenge && errors.specificToChallenge}
                        error={errors.specificToChallenge && touched.specificToChallenge}
                        margin="normal"
                        fullWidth
                      />
                    </DiscountFieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <FieldCell>
                      <StyledTextField
                        label="מספר שימושים/עסקאות מקסימלי בקופון"
                        name="maxNumOfUses"
                        value={values.maxNumOfUses}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.maxNumOfUses && touched.maxNumOfUses && errors.maxNumOfUses}
                        error={errors.maxNumOfUses && touched.maxNumOfUses}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: values.maxNumOfUses }}
                      />
                    </FieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <FieldCell>
                      <StyledTextField
                        label={'כתובת מייל המשויכת לקופון'}
                        name="notifyToEmail"
                        value={values.notifyToEmail}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.notifyToEmail && touched.notifyToEmail
                            ? errors.notifyToEmail
                            : 'תישלח הודעה למייל זה בכל עסקה בקופון (ניתן להשאיר ריק)'
                        }
                        error={errors.notifyToEmail && touched.notifyToEmail}
                        margin="normal"
                        fullWidth
                      />
                    </FieldCell>
                  </FieldsRow>
                  <ButtonsWrapper>
                    <StyledButton variant="contained" color="primary" fullWidth disabled={!isValid || isSubmitting} type="submit">
                      {isEditMode ? 'עדכן' : 'הוסף'}
                    </StyledButton>
                  </ButtonsWrapper>
                  {isSubmitting && (
                    <LoadingIndicatorWrapper>
                      <CircularProgress />
                    </LoadingIndicatorWrapper>
                  )}
                </Form>
              )
            }}
          </Formik>
        </FormWrapper>
      </PageWrapper>
    )
  }
}

export default withRouter(AddCouponScreen)
