import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { Link, withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import FoodIcon from '@material-ui/icons/Restaurant'
import LockIcon from '@material-ui/icons/Lock'
import backgroundImage from '../../images/healthyFoodBg.jpg'
import { mediaQuery, photoUrlPrefix } from '../../services/global'
import { invokeAPIcall } from '../../services/rest'
import { calcDaysSinceStartedChallenge, isTrialUser, TRIAL_DAYS } from '../../services/challengeProgressHelper'
import { dayTimeOptions, foodTypeOptions, filterMealsByFoodTypeOfUser } from '../../services/recipes'
import { colorPalette } from '../../styles/styleVariables'
import Breadcrumbs from '../Breadcrumbs'
import ChallengePhaseSelector from '../ChallengePhaseSelector'

// Consts

// Style
const PageWrapper = styled.div`
  background-color: ${colorPalette.white.main};
  background-image: url(${backgroundImage});
  background-size: 60% auto;
  background-position: top right;
  background-repeat: no-repeat;
  padding: 20px;
  padding-top: 0;
  flex: 1;
  ${mediaQuery.phone`
    background: none;
    padding: 20px;
  `}
`
const MealsScreenContent = styled.div`
  display: flex;
  ${mediaQuery.phone`
    width: auto;
    padding: 0 10px;
    flex-direction: column;
  `}
`
const MealsTitleSection = styled.div`
  flex: 1;
  margin-left: 30px;
  ${mediaQuery.phone`
    margin-bottom: 30px;
    margin-left: 0;
  `}
`
const MealsTitle = styled.div`
  background-color: ${colorPalette.primary1.main};
  color: ${colorPalette.white.main};
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 4px 10px;
  display: flex;
  align-items: center;
`
const MealsTitleLabel = styled.div`
  color: ${colorPalette.white.main};
  font-size: 36px;
  font-weight: 500;
  margin-right: 20px;
  ${mediaQuery.phone`
    font-size: 30px;
  `}
`
const iconCSS = css`
  && {
    width: 50px;
    height: 50px;
    color: ${colorPalette.white.main};
  }
`
const StyledFoodIcon = styled(FoodIcon)`
  ${iconCSS}
`
const MealThumbnailImg = styled.div`
  width: 100%;
  height: 290px;
  background-color: #eee;
  background-image: url('${props => props.photoUrl}');
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  margin: auto;
  position: relative;
`
const MealThumbnailImgOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  opacity: 0.7;
  border-radius: 10px;
  background: ${colorPalette.white.main};
`
const MealThumbnailDesc = styled.div`
  position: absolute;
  top: 0;
  bottom: 15%;
  left: 5px;
  right: 5px;
  text-align: center;
  font-weight: 500;
  color: ${colorPalette.black.main};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const MealButtons = styled.div`
  flex: 2;
  max-width: 890px;
  display: flex;
  flex-wrap: wrap;
  ${mediaQuery.tablet`
    flex: 1;
  `}
  ${mediaQuery.phone`
    flex-direction: column;
  `}
`
const MealItem = styled(Link)`
  flex: 0 0 47%;
  margin-bottom: 20px;
  margin-left: 3%;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  color: ${colorPalette.primary1.main};
  & ${MealThumbnailDesc} {
    display: none;
  }
  ${props =>
    props.disabled
      ? `
    & ${MealThumbnailImgOverlay} {
      display: block;
    }
  `
      : ''}
  &:hover {
    & ${MealThumbnailImgOverlay} {
      display: block;
    }
    & ${MealThumbnailDesc} {
      display: flex;
    }
  }
  ${mediaQuery.tablet`
  flex: 0 0 48%;
  margin-left: 2%;
  `}
  ${mediaQuery.phone`
    flex: 0 0 48%;
  `}
`
const MealInfo = styled.div`
  text-align: center;
  padding: 0 5px;
  padding-bottom: 12px;
  margin-top: 10px;
  color: ${colorPalette.black.main};
  font-size: 20px;
`
const MealsByDayTimeButtons = styled(MealButtons)``
const StyledButton = styled(Button)`
  && {
    margin-top: 20px;
  }
`
const LockIconWrapper = styled.div`
  margin-bottom: 6px;
`

// Component
class MyMealsScreen extends Component {
  state = {
    meals: [],
    selectedChallengePhase: 1,
    dataLoaded: false,
    isTrialUser: false,
    randNum: Math.random(),
  }

  // Lifecycle methods
  async componentDidMount() {
    await this.loadMeals()
    this.setSelectedChallengePhaseBasedOnDay(this.props)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.personalStats.length !== nextProps.personalStats.length) {
      this.setSelectedChallengePhaseBasedOnDay(nextProps)
    }
  }

  // Helper methods
  loadMeals = async () => {
    const meals = await invokeAPIcall({ uri: 'api/recipes' })
    this.setState({
      meals,
      dataLoaded: true,
    })
  }

  getPhotoUrl = item => {
    let photoUrl = item.PhotoURL
    if (photoUrl) {
      if (!photoUrl.includes('http')) {
        photoUrl = `${photoUrlPrefix}/recipePhotos/${photoUrl}`
      }
    }
    return photoUrl
  }

  setSelectedChallengePhaseBasedOnDay = props => {
    const { personalStats, currentUser } = props
    const daysSinceStartedChallenge = calcDaysSinceStartedChallenge(personalStats, currentUser)
    let newState = {}
    if (daysSinceStartedChallenge >= 30 && daysSinceStartedChallenge < 60) {
      newState = { selectedChallengePhase: 2 }
    } else if (daysSinceStartedChallenge >= 60) {
      newState = { selectedChallengePhase: 3 }
    }

    this.setState({
      ...newState /*isTrialUser: isTrialUser(currentUser) // TEMP DISABLE: we don't want to limit anything for trial user now */,
    })
  }

  // UI event methods
  handleChallengePhaseClick = challengePhase => {
    this.setState({ selectedChallengePhase: challengePhase })
  }

  // Render methods
  renderDayTimeItem = item => {
    const { personalStats } = this.props
    const { meals, randNum } = this.state
    let mealsInThisDayTime = meals.filter(meal => meal.DayTime === item.value)

    // filter the meals by FoodType (based on the user's preferences)
    mealsInThisDayTime = filterMealsByFoodTypeOfUser(personalStats, mealsInThisDayTime)

    let photoUrl = ''
    if (mealsInThisDayTime.length > 0) {
      const mealsInThisDayTime_onlyWithPhoto = mealsInThisDayTime.filter(m => m.PhotoURL)
      if (mealsInThisDayTime_onlyWithPhoto.length > 0) {
        const randomMealFromList = mealsInThisDayTime_onlyWithPhoto[Math.floor(randNum * mealsInThisDayTime_onlyWithPhoto.length)]
        photoUrl = this.getPhotoUrl(randomMealFromList)
      }
    }

    return (
      <MealItem to={`/myMeals/byDayTime/${item.value}`}>
        <MealThumbnailImg photoUrl={photoUrl}>
          <MealThumbnailImgOverlay />
        </MealThumbnailImg>
        <MealThumbnailDesc>({mealsInThisDayTime.length} מתכונים)</MealThumbnailDesc>
        <MealInfo>{item.label}</MealInfo>
      </MealItem>
    )
  }

  renderMealItem = (item, index) => {
    const { isTrialUser } = this.state
    const disabled = isTrialUser && index >= TRIAL_DAYS

    let photoUrl = this.getPhotoUrl(item)
    let foodTypeIDsLabel = ''
    if (item.FoodTypeIDs) {
      foodTypeIDsLabel = foodTypeOptions
        .filter(option => item.FoodTypeIDs.includes(option.value))
        .map(option => option.label)
        .join(', ')
    }
    return (
      <MealItem to={disabled ? null : `/myMeals/${item.RecipeID}`} disabled={disabled}>
        <MealThumbnailImg photoUrl={photoUrl}>
          <MealThumbnailImgOverlay />
        </MealThumbnailImg>
        <MealThumbnailDesc>
          {disabled ? (
            <>
              <LockIconWrapper title="ללקוחות פרימיום">
                <LockIcon />
              </LockIconWrapper>
              <div>מהפך ב-90 יום</div>
              <div>בריא יותר, חזק יותר, מאושר יותר</div>
              <Link to="/challengePayment">
                <StyledButton variant="contained" color="secondary" size="large">
                  לתוכנית הפרימיום
                </StyledButton>
              </Link>
            </>
          ) : (
            <>
              <div>כמות המנות: {item.AmountOfMeals || '-'}</div>
              <div>זמן עבודה (בדקות): {item.DurationInMins || '-'}</div>
              {item.CookingTimeInMins && <div>זמן בישול/אפייה (בדקות): {item.CookingTimeInMins || '-'}</div>}
            </>
          )}
        </MealThumbnailDesc>
        <MealInfo>{item.Name}</MealInfo>
      </MealItem>
    )
  }

  render() {
    const { personalStats, currentUser, match, allUserMeals } = this.props
    const { meals, selectedChallengePhase, isTrialUser } = this.state
    let mealsInThisDayTime = []

    const showByDayTime = match.params.dayTime
    let showByDayTimeLabel = '-'
    if (showByDayTime) {
      const dayTimeOption = dayTimeOptions.filter(dayTimeItem => dayTimeItem.value == showByDayTime)
      showByDayTimeLabel = dayTimeOption.length > 0 ? dayTimeOption[0].label : '-'

      mealsInThisDayTime = meals.filter(meal => meal.DayTime == showByDayTime && meal.ShowInChallengePhase === selectedChallengePhase)

      // filter the meals by FoodType (based on the user's preferences)
      mealsInThisDayTime = filterMealsByFoodTypeOfUser(personalStats, mealsInThisDayTime)

      // for trial users - show the first X meals from their DailySchedule of 90daysChallenge, as the first X here in the list
      if (isTrialUser) {
        const first3MealsIDs = allUserMeals
          .filter(item => item.DayInChallenge >= 1 && item.DayInChallenge <= TRIAL_DAYS && item.DayTime == showByDayTime)
          .map(item => item.Recipe && item.Recipe.RecipeID)
        mealsInThisDayTime = mealsInThisDayTime.reduce((acc, element) => {
          if (first3MealsIDs.includes(element.RecipeID)) {
            return [element, ...acc]
          }
          return [...acc, element]
        }, [])
      }
    }
    return (
      <PageWrapper>
        {!showByDayTime && (
          <Breadcrumbs
            previousItems={[
              {
                label: 'חוויית החיטוב ב-90 יום',
                url: `/program`,
              },
            ]}
            currentItem="הארוחות שלי"
          />
        )}
        {showByDayTime && (
          <Breadcrumbs
            previousItems={[
              {
                label: 'חוויית החיטוב ב-90 יום',
                url: `/program`,
              },
              {
                label: 'הארוחות שלי',
                url: `/myMeals`,
              },
            ]}
            currentItem={showByDayTimeLabel}
          />
        )}
        <MealsScreenContent>
          <MealsTitleSection>
            <MealsTitle>
              <StyledFoodIcon />
              <MealsTitleLabel>הארוחות שלי</MealsTitleLabel>
            </MealsTitle>
            {showByDayTime && (
              <ChallengePhaseSelector
                currentUser={currentUser}
                personalStats={personalStats}
                selectedChallengePhase={selectedChallengePhase}
                handleChallengePhaseClick={this.handleChallengePhaseClick}
              />
            )}
          </MealsTitleSection>
          {!showByDayTime && <MealsByDayTimeButtons>{dayTimeOptions.map(this.renderDayTimeItem)}</MealsByDayTimeButtons>}
          {showByDayTime && <MealButtons>{mealsInThisDayTime.map(this.renderMealItem)}</MealButtons>}
        </MealsScreenContent>
      </PageWrapper>
    )
  }
}

export default withRouter(MyMealsScreen)
