import storeObj from './store'

export async function invokeAPIcall({ uri, data, method = 'GET' }) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }

  // grab current state
  const state = storeObj.store.getState()

  // add auth access token to the headers
  const { accessToken } = state.currentUser
  if (accessToken != null && accessToken !== '') {
    headers.Authorization = `Bearer ${accessToken}`
  }

  // invoke API call
  let response = await fetch(
    `${document.URL.includes('localhost') || window.cordova ? 'https://www.fitthybody.com' : ''}/fitthybodyAPI/${uri}`,
    {
      method,
      headers,
      body: data && JSON.stringify(data),
    },
  )
  const responseData = await response.json()
  return responseData
}

export async function invokeAPIcallWithFormData({ uri, body }) {
  // invoke API call
  let response = await fetch(
    `${document.URL.includes('localhost') || window.cordova ? 'https://www.fitthybody.com' : ''}/fitthybodyAPI/${uri}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body,
    },
  )
  const responseData = await response.json()
  return responseData
}

export const wysiwygUploadCallback = upload => {
  return new Promise(async (resolve, reject) => {
    // upload photo of item
    if (upload) {
      // more than 4MB
      if (upload.size > 4000000) {
        alert('משקל הקובץ גדול מדי: ' + upload.size)
        reject(new Error('error'))
      } else {
        // if file size is fine
        const uploadData = new FormData()
        uploadData.append('file', upload)
        try {
          const response = await fetch(
            `${
              document.URL.includes('localhost') || window.cordova ? 'https://www.fitthybody.com' : ''
            }/fitthybodyAPI/api/wysiwyg/UploadPhoto`,
            {
              method: 'POST',
              body: uploadData,
            },
          )
          const responseData = await response.json()
          if (responseData.Key === true) {
            resolve({
              data: {
                link: `https://www.fitthybody.com/fitthybodyAPI/wysiwygPhotos/${responseData.Value}`,
              },
            })
          } else {
            alert(responseData.Value)
            reject(new Error(responseData.Value))
          }
        } catch (error) {
          alert('שגיאה בהעלאת התמונה')
          reject(new Error('error'))
        }
      }
    }
  })
}
