import React, { Component } from 'react'
import styled, { keyframes, css } from 'styled-components'
import { withRouter, Link } from 'react-router-dom'
import Badge from '@material-ui/core/Badge'
import Button from '@material-ui/core/Button'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import { mediaQuery } from '../services/global'
import { colorPalette } from '../styles/styleVariables'

// Style
const iconAnimation = keyframes`
  from {
    color: ${colorPalette.white.main};
  }
  to {
    color: ${colorPalette.primary2.main};
  }
`
const StoreButton = styled.div`
  margin-right: 20px;
  margin-left: 10px;
  cursor: pointer;
  svg {
    color: ${colorPalette.white.main};
    animation: ${props =>
      props.blinkIcon
        ? css`
            ${iconAnimation} 1s ease-in infinite
          `
        : ''};
  }
  &:hover {
    svg {
      color: ${colorPalette.tertiary1.light};
    }
  }
  ${mediaQuery.phone`
    margin-right: 10px;
    svg {
      color: ${colorPalette.primary1.main};
    }
  `}
`

// Component
class StoreButtonComponent extends Component {
  state = {
    blinkIcon: false,
  }

  // Lifecycle methods
  componentWillReceiveProps(nextProps) {
    const { productsInCart, openSnackbar, history } = this.props
    if (productsInCart && nextProps.productsInCart) {
      if (productsInCart.length < nextProps.productsInCart.length) {
        openSnackbar({
          message: 'המוצר נוסף לעגלת הקניות',
          severity: 'success',
          actionText: 'עבור לעגלה',
          actionOnClick: () => {
            history.push('/myCart')
          },
        })
        this.setState({ blinkIcon: true })
        window.setTimeout(() => {
          this.setState({ blinkIcon: false })
        }, 4000)
      }
    }
  }

  // UI event methods

  // Render methods
  render() {
    const { blinkIcon } = this.state
    const { productsInCart } = this.props
    const badgeCount = productsInCart.reduce((result, productItem) => {
      result = result + productItem.quantity
      return result
    }, 0)

    return (
      <StoreButton title="עגלת הקניות שלי" blinkIcon={blinkIcon}>
        <a href="/myCart">
          <Link to="/myCart">
            <Badge
              badgeContent={badgeCount}
              color="secondary"
              anchorOrigin={{
                horizontal: 'left',
                vertical: 'top',
              }}
            >
              <ShoppingCartIcon />
            </Badge>
          </Link>
        </a>
      </StoreButton>
    )
  }
}

export default withRouter(StoreButtonComponent)
