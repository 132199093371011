import React from 'react'
import styled from 'styled-components'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { colorPalette } from '../styles/styleVariables'

// Style
const StyledKeyboardDatePicker = styled(KeyboardDatePicker)`
  && {
    .MuiInput-input {
      color: ${colorPalette.primary1.main};
    }
    .MuiFormLabel-root {
      color: ${colorPalette.primary1.main};
    }
    .MuiFormLabel-root.Mui-focused {
      color: ${colorPalette.primary1.dark};
    }
    .MuiInput-root {
      &::after {
        border-bottom: 2px solid ${colorPalette.primary1.dark};
      }
      ::before {
        border-bottom: 1px solid ${colorPalette.primary1.main};
      }
      &:hover::before {
        border-bottom: 2px solid ${colorPalette.primary1.dark};
      }
    }
    .MuiSelect-icon {
      color: ${colorPalette.primary1.main};
    }
    svg {
      fill: ${colorPalette.primary1.main};
    }
  }
`

// Component
function DatePickerComponent({ ...params }) {
  return <StyledKeyboardDatePicker {...params} />
}

export default DatePickerComponent
