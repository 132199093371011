import React, { Component } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import { Link, withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import formBackgroundLeft from '../../images/formBackground_left.jpg'
import formBackgroundRight from '../../images/formBackground_right.jpg'
import { mediaQuery } from '../../services/global'
import { requiredValidation } from '../../services/formValidation'
import { invokeAPIcall } from '../../services/rest'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import StyledTextField from '../StyledTextField'
import Breadcrumbs from '../Breadcrumbs'

// Style
const PageWrapper = styled.div`
  background-color: #f5fcfd;
  background: url(${formBackgroundLeft}) left bottom no-repeat, url(${formBackgroundRight}) right bottom no-repeat, #f7fdfd;
  background-size: 40% auto, 35% auto;
  padding: 20px;
  flex: 1;
  ${mediaQuery.phone`
    background: none;
  `}
`
const FormWrapper = styled.div`
  background-color: ${colorPalette.white.main};
  max-width: 680px;
  margin: 0 auto;
  padding: 20px;
  color: ${colorPalette.primary1.main};
  ${globalStyles.boxShadow}
  ${globalStyles.boxRadius}
`
const PageTitle = styled.h1`
  margin-bottom: 20px;
`
const FieldsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
`
const FieldCell = styled.div`
  flex: 0 0 100%;
  display: flex;
  justify-content: space-between;
`
const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  margin-top: 20px;
  ${mediaQuery.phone`
    width: 100%;
  `}
`
const StyledButton = styled(Button)`
  && {
    font-size: 22px;
    line-height: 1.4;
    flex: 0 0 ${props => (props.singleButton ? '100%' : '49%')};
    ${mediaQuery.phone`
      font-size: 16px;
    `}
    & > span {
      display: block;
    }
  }
`

// Component
class SetNewPasswordScreen extends Component {
  state = {
    initialValues: {
      password: '',
      confirmPassword: '',
    },
  }

  // Lifecycle methods
  componentDidMount() {}

  // Helper methods
  validate = values => {
    let errors = {}

    if (requiredValidation(values.password)) {
      errors.password = requiredValidation(values.password)
    }
    if (requiredValidation(values.confirmPassword)) {
      errors.confirmPassword = requiredValidation(values.confirmPassword)
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'סיסמאות לא תואמות'
    }

    return errors
  }

  // UI event methods
  handleSubmit = async (values, { setSubmitting }) => {
    const { match } = this.props
    const { params } = match
    const { openSnackbar } = this.props
    const data = {
      uniqueGuid: params.uniqueGuid,
      password: values.password,
    }

    // invoke API call
    try {
      const responseData = await invokeAPIcall({ uri: 'api/users/UpdateUserPassword', data, method: 'PUT' })

      // if SetNewPassword success
      if (responseData.Key === true) {
        openSnackbar({ message: responseData.Value, severity: 'success' })
      }
      // if failed
      else {
        openSnackbar({ message: responseData.Value, severity: 'failure' })
      }
    } catch (error) {
      console.log('error in set new password', error)
    }
    setSubmitting(false)
  }

  // Render methods
  render() {
    const { initialValues } = this.state

    return (
      <PageWrapper>
        <Breadcrumbs previousItems={[]} currentItem="הגדרת סיסמה חדשה" />
        <FormWrapper>
          <PageTitle>הגדרת סיסמה חדשה</PageTitle>
          <Formik validate={this.validate} initialValues={initialValues} onSubmit={this.handleSubmit} enableReinitialize={true}>
            {formikBag => {
              const { isSubmitting, values, errors, touched, handleChange, handleBlur, handleSubmit, isValid } = formikBag
              return (
                <Form>
                  <FieldsRow>
                    <FieldCell>
                      <StyledTextField
                        label={'סיסמה חדשה'}
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.password && touched.password && errors.password}
                        error={errors.password && touched.password}
                        margin="normal"
                        fullWidth
                        type="password"
                        autoComplete="new-password"
                      />
                    </FieldCell>
                    <FieldCell>
                      <StyledTextField
                        label={'אימות סיסמה'}
                        name="confirmPassword"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.confirmPassword && touched.confirmPassword && errors.confirmPassword}
                        error={errors.confirmPassword && touched.confirmPassword}
                        margin="normal"
                        fullWidth
                        type="password"
                        autoComplete="new-password"
                      />
                    </FieldCell>
                  </FieldsRow>
                  <ButtonsWrapper>
                    <StyledButton
                      variant="contained"
                      color="primary"
                      fullWidth
                      size="large"
                      disabled={!isValid || isSubmitting}
                      onClick={e => {
                        handleSubmit(e)
                      }}
                      singleButton
                    >
                      שמירת סיסמה
                    </StyledButton>
                  </ButtonsWrapper>
                </Form>
              )
            }}
          </Formik>
        </FormWrapper>
      </PageWrapper>
    )
  }
}

export default withRouter(SetNewPasswordScreen)
