import React, { Component } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import CircularProgress from '@material-ui/core/CircularProgress'
import backgroundImage from '../../images/leavesBg.jpg'
import { mediaQuery, siteName, photoUrlPrefix } from '../../services/global'
import { invokeAPIcall } from '../../services/rest'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import Breadcrumbs from '../Breadcrumbs'
import ProductPriceTag from '../ProductPriceTag'

// Consts

// Style
const PageWrapper = styled.div`
  background-color: ${colorPalette.white.main};
  ${props =>
    props.category !== '1'
      ? `
    background-image: url(${backgroundImage});
    background-size: 34% auto;
    background-position: top left;
    background-repeat: no-repeat;
  `
      : ''}
  padding: 20px;
  flex: 1;
  ${mediaQuery.phone`
    background: none;
  `}
`
const ProductList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 75%;
  margin: auto;
  ${mediaQuery.tablet`
    flex: 1;
  `}
  ${mediaQuery.phone`
    width: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
  `}
`
const ProductItem = styled.li`
  flex: 0 0 22%;
  margin: 0 1%;
  margin-bottom: 2px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  color: ${colorPalette.primary1.main};
  &:hover {
    opacity: 0.8;
  }
  ${mediaQuery.tablet`
    flex: 0 0 48%;
  `}
  ${mediaQuery.phone`
    flex: 1;
  `}
`
const ProductNameAndPrice = styled.div`
  padding: 0 5px;
  padding-bottom: 12px;
  color: ${colorPalette.black.main};
  & > div {
    font-weight: 500;
    font-size: 18px;
  }
`
const ProductName = styled.div`
  margin-top: 2px;
`
const ProductPrice = styled.div`
  & > div {
    font-weight: 300;
    font-size: 16px;
  }
`
const ProductPhoto = styled.div`
width: 100%;
height: 185px;
background-color: #eee;
background-image: url('${props => props.photoUrl}');
background-position: center;
background-size: cover;
border-radius: 10px;
margin: auto;
`
const AddToCartBtnWrapper = styled.div`
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.5s;
  ${ProductItem}:hover & {
    opacity: 1;
  }
`
const AddToCartBtn = styled.div`
  padding: 10px;
  background-color: ${colorPalette.primary1.light};
  color: ${colorPalette.white.main};
  ${globalStyles.boxRadius}
`
const LoadingIndicatorWrapper = styled.div`
  margin: 50px auto;
  display: flex;
  justify-content: center;
`

// Component
class StoreScreen extends Component {
  state = {
    products: [],
    globalSettingsData: {},
  }

  // Lifecycle methods
  componentDidMount() {
    this.loadProductList(this.props)
    this.loadGlobalSettingsData()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.category !== nextProps.match.params.category) {
      this.setState({ products: [] })
      this.loadProductList(nextProps)
    }
  }

  // Helper methods
  loadProductList = async props => {
    const { match } = props
    const { params } = match
    let productList = await invokeAPIcall({ uri: `api/products/getByCategory?categoryId=${params.category}` })
    productList = productList.sort((a, b) => (a.DisplayPricePerItem || a.Price) - (b.DisplayPricePerItem || b.Price))
    this.setState({ products: productList })
  }

  loadGlobalSettingsData = async id => {
    let globalSettingsData = await invokeAPIcall({ uri: `api/globalSettings/1` })
    this.setState({
      globalSettingsData: globalSettingsData,
    })
  }

  // UI event methods
  handleAddToCart = (productId, e) => {
    const { addProductToCart, history } = this.props
    addProductToCart({ productId, quantity: 1 })

    window.setTimeout(() => {
      //history.push('/myCart')
    }, 500)

    e.stopPropagation()
  }

  handleProductClick = urlCode => {
    const { history } = this.props
    history.push(`/productDetails/${urlCode}`)
  }

  // Render methods
  renderProductItem = productItem => {
    const { globalSettingsData } = this.state
    let photoUrl = productItem.ProductPhotos[0] && productItem.ProductPhotos[0].PhotoURL
    if (photoUrl) {
      if (!photoUrl.includes('http')) {
        photoUrl = `${photoUrlPrefix}/productPhotos/${photoUrl}`
      }
    }
    return (
      <ProductItem onClick={this.handleProductClick.bind(this, productItem.UrlCode)}>
        <ProductPhoto photoUrl={photoUrl} />
        <ProductNameAndPrice>
          <ProductName>{productItem.Name}</ProductName>
          <ProductPrice>
            <ProductPriceTag productItem={productItem} globalSettingsData={globalSettingsData} showPricePerItem />
          </ProductPrice>
        </ProductNameAndPrice>
        <AddToCartBtnWrapper>
          <AddToCartBtn onClick={this.handleAddToCart.bind(this, productItem.ProductID)}>הוסף לעגלה</AddToCartBtn>
        </AddToCartBtnWrapper>
      </ProductItem>
    )
  }

  render() {
    const { products } = this.state
    const { match } = this.props
    const { params } = match
    const pageTitle = params.category === '1' ? 'מאמנים בשבילך' : 'מוצרים בשבילך'
    return (
      <PageWrapper category={params.category}>
        <Helmet>
          <title>
            {siteName} - {pageTitle}
          </title>
        </Helmet>
        <Breadcrumbs previousItems={[]} currentItem={pageTitle} />
        {products.length === 0 && (
          <LoadingIndicatorWrapper>
            <CircularProgress />
          </LoadingIndicatorWrapper>
        )}
        <ProductList>{products.map(this.renderProductItem)}</ProductList>
      </PageWrapper>
    )
  }
}

export default withRouter(StoreScreen)
