import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { mediaQuery, isAdminUser } from '../services/global'
import { colorPalette } from '../styles/styleVariables'

// Style
const ChallengeBtn = styled.div`
  color: ${colorPalette.primary2.main};
  font-size: 20px;
  font-weight: 600;
  margin-right: 22px;
  &:hover {
    color: ${colorPalette.tertiary1.light};
  }
  ${props =>
    props.disabled
      ? `
      color: ${colorPalette.tertiary1.light};
    `
      : ''}
  ${mediaQuery.tablet`
    font-size: 17px;
    margin-right: 12px;
  `}
  ${mediaQuery.phone`
    display: none;
  `}
`
const StyledButton = styled(Button)`
  && {
    margin-right: 20px;
    span {
      white-space: nowrap;
    }
  }
  &&.MuiButton-contained.Mui-disabled {
    background: rgba(255, 181, 71, 0.6);
  }
  ${mediaQuery.tablet`
    && {
      font-size: 12px;
      margin-right: 5px;
    }
  `}
  ${mediaQuery.phone`
    && {
      display: none;
    }
  `}
`

// Component
class ChallengeButton extends Component {
  state = {}

  // Lifecycle methods

  // UI event methods

  // Render methods
  render() {
    const { currentUser } = this.props
    const isAdmin = isAdminUser(currentUser)
    /*if (isAdmin !== true) {
      // TEMP - don't show this button to users. Only to admins
      return (
        <StyledButton disabled variant="contained" color="secondary">
          חוויית החיטוב ב-90 יום - בקרוב!
        </StyledButton>
      )
    }*/

    return (
      <Link to="/challengeIntro">
        <StyledButton variant="contained" color="secondary">
          לחוויית החיטוב ב-90 יום
        </StyledButton>
      </Link>
    )
  }
}

export default withRouter(ChallengeButton)
