import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import { mediaQuery, photoUrlPrefix } from '../../services/global'
import { invokeAPIcall } from '../../services/rest'
import { getString } from '../../services/presetStringsHelper'

// Style
const ArticlesCarouselItemWrapper = styled.div`
  background-color: ${colorPalette.primary1.main};
  border-bottom-left-radius: ${globalStyles.mainBoxRadius};
  border-bottom-right-radius: ${globalStyles.mainBoxRadius};
  color: ${colorPalette.white.main};
  box-shadow: ${globalStyles.mainBoxShadow};
  position: relative;
  margin-top: -80px;
  top: -80px;
  z-index: 6;
  padding: 30px 0;
  ${mediaQuery.phone`
    height: auto;
  `}
`
const CarouselItemInner = styled.div`
  background-color: ${colorPalette.primary1.main};
  width: 100%;
  padding: 10px 20px;
  flex-direction: column;
  align-items: center;
  ${mediaQuery.phone`
    background-size: auto 100%;
    background-position: center;
    height: auto;
  `}
`
const ContentBox = styled.div`
  font-size: 22px;
  ${mediaQuery.phone`
    position: static;
    text-align: center;
    width: auto;
    padding: 0 10px;
    flex-direction: column;
  `}
`
const PromotionTitle = styled.h2`
  font-size: 36px;
  font-weight: 500;
  margin: 20px 0;
  text-align: center;
  ${mediaQuery.tablet`
    font-size: 34px;
  `}
  ${mediaQuery.phone`
    font-size: 28px;
  `}
`
const ArticlesList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  ${mediaQuery.phone`
    flex-direction: column;
  `}
`
const ArticleItem = styled.li`
  margin: 0 30px;
  margin-bottom: 20px;
  max-width: 220px;
  overflow: hidden;
  font-size: 16px;
  background-color: ${colorPalette.white.main};
  color: ${colorPalette.black.main};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  a {
    color: ${colorPalette.black.main};
  }
  &:hover {
    color: ${colorPalette.primary1.main};
  }
  box-shadow: ${globalStyles.mainBoxShadow};
  ${globalStyles.boxRadius}
`
const ArticleItemContent = styled.div`
  padding: 20px;
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ArticlePhoto = styled.div`
  width: 220px;
  height: 165px;
  background-color: #eee;
  background-image: url('${props => props.photoUrl}');
  background-position: center;
  background-size: cover;
  border-bottom: 1px solid ${colorPalette.primary1.main};
`
const ButtonBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StyledButton = styled(Button)`
  && {
    padding: 6px 80px;
    font-size: 20px;
  }
`

// Component
class ArticlesCarouselItem extends Component {
  state = {
    articles: [],
  }

  // Lifecycle methods
  componentDidMount() {
    this.loadArticleList(this.props)
  }

  // Helper methods
  loadArticleList = async props => {
    const articleList = await invokeAPIcall({ uri: `api/articles` })
    this.setState({ articles: articleList.filter(item => item.IsHighlighted).splice(0, 3) })
  }

  // Render methods
  renderArticleItem = item => {
    let photoUrl = item.PhotoURL
    if (photoUrl) {
      if (!photoUrl.includes('http')) {
        photoUrl = `${photoUrlPrefix}/articlePhotos/${photoUrl}`
      }
    }

    return (
      <Link to={`/article/${item.ArticleID}`}>
        <ArticleItem>
          <ArticlePhoto photoUrl={photoUrl} />
          <ArticleItemContent>{item.Title}</ArticleItemContent>
        </ArticleItem>
      </Link>
    )
  }

  render() {
    const { articles } = this.state
    return (
      <>
        <ArticlesCarouselItemWrapper>
          <CarouselItemInner>
            <ContentBox>
              <PromotionTitle>{getString('ArticlesCarouselItemTitle')}</PromotionTitle>
              <ArticlesList>{articles.map(this.renderArticleItem)}</ArticlesList>
              <ButtonBar>
                <Link to="/registerToChallenge">
                  <StyledButton variant="contained" color="secondary" size="large">
                    {getString('ChallengeCountdownCarouselItemButton')}
                  </StyledButton>
                </Link>
              </ButtonBar>
            </ContentBox>
          </CarouselItemInner>
        </ArticlesCarouselItemWrapper>
      </>
    )
  }
}

export default ArticlesCarouselItem
