import React, { Component } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import { Link, withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress'
import formBackgroundLeft from '../../images/formBackground_left.jpg'
import formBackgroundRight from '../../images/formBackground_right.jpg'
import { mediaQuery, keysToLowerCase } from '../../services/global'
import { requiredValidation } from '../../services/formValidation'
import { invokeAPIcall } from '../../services/rest'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import StyledTextField from '../StyledTextField'
import StyledSelect from '../StyledSelect'
import StyledCheckbox from '../StyledCheckbox'
import Breadcrumbs from '../Breadcrumbs'

// Style
const PageWrapper = styled.div`
  background-color: #f5fcfd;
  background: url(${formBackgroundLeft}) left bottom no-repeat, url(${formBackgroundRight}) right bottom no-repeat, #f7fdfd;
  background-size: 40% auto, 35% auto;
  padding: 20px;
  flex: 1;
  ${mediaQuery.phone`
    background: none;
  `}
`
const FormWrapper = styled.div`
  background-color: ${colorPalette.white.main};
  max-width: 680px;
  margin: 0 auto;
  padding: 20px;
  color: ${colorPalette.primary1.main};
  ${globalStyles.boxShadow}
  ${globalStyles.boxRadius}
`
const PageTitle = styled.h1`
  margin-bottom: 20px;
`
const FieldsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
`
const FieldCell = styled.div`
  flex: 0 0 100%;
`
const PriceFieldCell = styled(FieldCell)`
  flex: 0 0 32%;
`
const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  margin-top: 20px;
  ${mediaQuery.phone`
    width: 100%;
  `}
`
const StyledButton = styled(Button)`
  && {
  }
`
const LoadingIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5px;
`

// Component
class AddFeedbackMessageScreen extends Component {
  state = {
    initialValues: {
      name: '',
      email: '',
      text: '',
      isShownInHomePage: false,
    },
  }

  // Lifecycle methods
  componentDidMount() {
    const editModeId = this.getEditModeId()
    if (editModeId != null) {
      this.loadFeedbackMessageData(editModeId)
    }
  }

  // Helper methods
  getEditModeId = () => {
    const { match } = this.props
    const { params } = match
    return params.id
  }

  loadFeedbackMessageData = async id => {
    let feedbackMessageData = await invokeAPIcall({ uri: `api/feedbackMessages/${id}` })
    feedbackMessageData = keysToLowerCase(feedbackMessageData)
    this.setState({
      initialValues: feedbackMessageData,
    })
  }

  validate = values => {
    let errors = {}

    if (requiredValidation(values.name)) {
      errors.name = requiredValidation(values.name)
    }
    if (requiredValidation(values.email)) {
      errors.email = requiredValidation(values.email)
    }
    if (requiredValidation(values.text)) {
      errors.text = requiredValidation(values.text)
    }

    return errors
  }

  // UI event methods
  handleSubmit = async (values, { setSubmitting }) => {
    const editModeId = this.getEditModeId()
    const isEditMode = editModeId != null
    const { history } = this.props
    const data = {
      ...values,
    }

    // invoke API call
    try {
      const responseData = await invokeAPIcall({
        uri: isEditMode ? `api/feedbackMessages/${editModeId}` : 'api/feedbackMessages',
        data,
        method: isEditMode ? 'PUT' : 'POST',
      })

      // if API success
      if (responseData.Key === true) {
        history.push('/feedbackMessagesAdmin')
      }
      // if failed
      else {
        alert(responseData.Value || 'התרחשה שגיאה')
      }
    } catch (error) {
      console.log('error in adding/updating new feedbackMessage', error)
    }
    setSubmitting(false)
  }

  // Render methods
  render() {
    const { initialValues } = this.state
    const editModeId = this.getEditModeId()
    const isEditMode = editModeId != null

    return (
      <PageWrapper>
        <Breadcrumbs
          previousItems={[
            {
              label: `מסכי ניהול`,
              url: `/adminPanel`,
            },
          ]}
          currentItem={`ניהול חוות דעת`}
        />
        <FormWrapper>
          <PageTitle>{isEditMode ? 'עדכון חוות דעת' : 'הוספת חוות דעת חדשה'}</PageTitle>
          <Formik validate={this.validate} initialValues={initialValues} onSubmit={this.handleSubmit} enableReinitialize={true}>
            {formikBag => {
              const { isSubmitting, values, errors, touched, handleChange, handleBlur, isValid, setFieldValue } = formikBag
              return (
                <Form>
                  <FieldsRow>
                    <FieldCell>
                      <StyledTextField
                        label={'שם מלא'}
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.name && touched.name && errors.name}
                        error={errors.name && touched.name}
                        margin="normal"
                        fullWidth
                      />
                    </FieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <FieldCell>
                      <StyledTextField
                        label={'דוא״ל'}
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.email && touched.email && errors.email}
                        error={errors.email && touched.email}
                        margin="normal"
                        fullWidth
                      />
                    </FieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <FieldCell>
                      <StyledTextField
                        label={'חוות דעת מלאה'}
                        name="text"
                        value={values.text}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.text && touched.text && errors.text}
                        error={errors.text && touched.text}
                        margin="normal"
                        fullWidth
                        multiline
                        rows="4"
                      />
                    </FieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <FieldCell>
                      <StyledCheckbox
                        label={'האם מוצג בעמוד הראשי?'}
                        name="isShownInHomePage"
                        value={values.isShownInHomePage}
                        checked={values.isShownInHomePage}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.isShownInHomePage && touched.isShownInHomePage && errors.isShownInHomePage}
                        error={errors.isShownInHomePage && touched.isShownInHomePage}
                        margin="normal"
                        fullWidth
                      />
                    </FieldCell>
                  </FieldsRow>
                  <ButtonsWrapper>
                    <StyledButton variant="contained" color="primary" fullWidth disabled={!isValid || isSubmitting} type="submit">
                      {isEditMode ? 'עדכן' : 'הוסף'}
                    </StyledButton>
                  </ButtonsWrapper>
                  {isSubmitting && (
                    <LoadingIndicatorWrapper>
                      <CircularProgress />
                    </LoadingIndicatorWrapper>
                  )}
                </Form>
              )
            }}
          </Formik>
        </FormWrapper>
      </PageWrapper>
    )
  }
}

export default withRouter(AddFeedbackMessageScreen)
