import React, { Component } from 'react'
import styled from 'styled-components'
import { Link, withRouter } from 'react-router-dom'
import moment from 'moment'
import Button from '@material-ui/core/Button'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import MenuItem from '@material-ui/core/MenuItem'
import DeleteIcon from '@material-ui/icons/Delete'
import { invokeAPIcall } from '../../services/rest'
import { calcDaysSinceStartedChallenge } from '../../services/challengeProgressHelper'
import { colorPalette } from '../../styles/styleVariables'
import Breadcrumbs from '../Breadcrumbs'
import StyledSelect from '../StyledSelect'

// Style
const PageWrapper = styled.div`
  padding: 20px;
`
const StyledTableRow = styled(TableRow)`
  && {
    ${props =>
      props.isActive
        ? `
    background: ${colorPalette.tertiary1.light};
    &&:hover {
      background: ${colorPalette.tertiary1.light};
    }
    `
        : ''}
  }
`
const InfoTableRow = styled(TableRow)`
  && {
    background: ${colorPalette.tertiary1.light};
  }
`
const ChallengeInfoList = styled.ul`
  display: flex;
  padding: 20px 0;
  border-bottom: 1px solid ${colorPalette.tertiary1.main};
`
const ChallengeInfoListItem = styled.li`
  flex: 1;
`
const ChallengeInfoListItemLabel = styled.div`
  font-size: 20px;
  font-weight: 500;
`
const ChallengeActionButtons = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  .MuiFormControl-root {
    margin: 0;
  }
  .MuiSelect-root {
    padding: 5px;
    padding-left: 32px;
  }
`
const StyledButton = styled(Button)`
  && {
    margin: 0 10px;
  }
`
const ShowMoreInfoLabel = styled.div`
  color: ${colorPalette.primary1.main};
  text-decoration: underline;
  cursor: pointer;
`
const UserChallengeProgressBarWrapper = styled.div`
  height: 5px;
  width: 80%;
  background: ${colorPalette.white.main};
  position: relative;
`
const UserChallengeProgressBar = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: ${props => props.percentage}%;
  background: ${colorPalette.primary1.main};
`
const PaymentDetailsLabel = styled.div`
  font-size: 10px;
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
`

// Consts/Helpers
function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy)
}

const headCells = [
  { id: 'id', numeric: false, disablePadding: true, label: 'קוד משתמש' },
  { id: 'firstName', numeric: false, disablePadding: false, label: 'שם מלא' },
  { id: 'email', numeric: false, disablePadding: false, label: 'מייל' },
  { id: 'isActivated', numeric: false, disablePadding: false, label: 'אימת מייל?' },
  { id: 'userRoles', numeric: false, disablePadding: false, label: 'סוג משתמש' },
  { id: 'startedChallengeDate', numeric: false, disablePadding: false, label: 'תאריך הרשמה לחוויית החיטוב' },
  { id: 'challengePaymentDate', numeric: false, disablePadding: false, label: 'תאריך הזנת אשראי' },
  { id: 'none', numeric: false, disablePadding: false, label: 'פרטים נוספים' },
]

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'בחר הכל' }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel active={orderBy === headCell.id} direction={order} onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? <span>{order === 'desc' ? '' : ''}</span> : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const EnhancedTableToolbar = props => {
  const { numSelected, handleDeleteItems } = props

  return (
    <Toolbar>
      {numSelected > 0 ? (
        <Typography color="inherit" variant="subtitle1">
          {numSelected} נבחרו
        </Typography>
      ) : (
        <Typography variant="h6" id="tableTitle">
          ניהול משתמשים
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="מחיקה">
          <IconButton aria-label="מחיקה" onClick={handleDeleteItems}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </Toolbar>
  )
}

// Component
class UsersAdminScreen extends Component {
  state = {
    users: [],
    order: 'asc',
    orderBy: 'name',
    selected: [],
    page: 0,
    dense: true,
    rowsPerPage: 200,
    activeRow: null,
    personalStatsList: null,
    showChallengeDaySelector: false,
  }

  // Lifecycle methods
  componentDidMount() {
    this.loadUserList()
  }

  // Helper methods
  loadUserList = async () => {
    const userList = await invokeAPIcall({ uri: 'api/users' })
    this.setState({
      users: userList.map(user => ({
        id: user.UserID,
        firstName: user.FirstName,
        lastName: user.LastName,
        email: user.Email ? user.Email.toLowerCase() : '',
        phone: user.Phone,
        gender: user.Gender,
        birthDate: user.BirthDate,
        startedChallengeDate: user.StartedChallengeDate ? moment(user.StartedChallengeDate).valueOf() : null,
        challengePaymentDate: user.ChallengePaymentDate ? moment(user.ChallengePaymentDate).valueOf() : null,
        challengePaymentDateOfBilling: user.ChallengePaymentDateOfBilling ? moment(user.ChallengePaymentDateOfBilling).valueOf() : null,
        challengePaymentType: user.ChallengePaymentType,
        challengeSelectedPackage: user.ChallengeSelectedPackage,
        challengePaymentTotalPrice: user.ChallengePaymentTotalPrice,
        challengePaymentDetails: user.ChallengePaymentDetails,
        userRoles: user.UserRoles,
        isActivated: user.IsActivated,
        endOfChallenge_howWent: user.EndOfChallenge_howWent,
        endOfChallenge_allowPhoto: user.EndOfChallenge_allowPhoto,
        endOfChallenge_friendInviteEmail: user.EndOfChallenge_friendInviteEmail,
        endOfChallenge_friendInvitePhone: user.EndOfChallenge_friendInvitePhone,
      })),
    })
  }

  handleRequestSort = (event, property) => {
    const { order, orderBy } = this.state
    const isDesc = orderBy === property && order === 'desc'
    this.setState({ order: isDesc ? 'asc' : 'desc', orderBy: property })
  }

  handleSelectAllClick = event => {
    const { users } = this.state
    if (event.target.checked) {
      const newSelecteds = users.map(n => n.id)
      this.setState({ selected: newSelecteds })
      return
    }
    this.setState({ selected: [] })
  }

  handleClick = (event, name) => {
    const { activeRow } = this.state
    this.setState({
      //selected: newSelected,
      activeRow: activeRow === name ? null : name,
      personalStatsList: null,
      showChallengeDaySelector: false,
    })

    const activeUserId = name
    this.loadPersonalStatsOfActiveUser(activeUserId)
  }

  handleCheckboxClick = (event, name) => {
    const { selected } = this.state
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }

    this.setState({
      selected: newSelected,
    })
    event.stopPropagation()
    return false
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage })
  }

  handleChangeRowsPerPage = event => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) })
  }

  handleDeleteItems = async () => {
    const promptResult = window.prompt('לצורך וידוא מחיקה, אנא הקלד את שם האתר')
    if (promptResult.toLowerCase() === 'fitthybody') {
      var result = window.confirm('כל המידע על המשתמש הזה יוסר לגמרי ממאגר הנתונים. האם אתה בטוח?')
      if (result) {
        const { selected } = this.state

        selected.forEach(async selctedId => {
          // invoke API call
          try {
            const responseData = await invokeAPIcall({ uri: `api/users/${selctedId}`, method: 'DELETE' })

            // if add success
            if (responseData.Key === true) {
              this.loadUserList()
            }
            // if failed
            else {
              alert(responseData.Value || 'התרחשה שגיאה')
            }
          } catch (error) {
            console.log('error in deleting user', error)
          }
        })

        alert('המחיקה בוצעה בהצלחה')
      }
    }
  }

  isSelected = name => {
    const { selected } = this.state
    return selected.indexOf(name) !== -1
  }

  emptyRows = () => {
    const { users, rowsPerPage, page } = this.state
    return rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage)
  }

  getStartedChallengeDateDisplayValue = row => {
    return row.startedChallengeDate ? moment(row.startedChallengeDate).format('DD/MM/YYYY') : 'לא התחיל'
  }

  getChallengePaymentDateDisplayValue = row => {
    let packageType = 'Basic'
    if (row.challengeSelectedPackage === 2) {
      packageType = 'Premium'
    } else if (row.challengeSelectedPackage === 3) {
      packageType = 'VIP'
    }

    return row.challengePaymentDate ? (
      <div>
        תאריך הזנת פרטי אשראי: {moment(row.challengePaymentDate).format('DD/MM/YYYY')}
        <div>סוג חבילה שנבחרה: {packageType || '-'}</div>
        <div>
          סכום עסקה: ₪{row.challengePaymentTotalPrice != null ? row.challengePaymentTotalPrice : '-'} (שיטת תשלום:{' '}
          {row.challengePaymentType || '-'})
        </div>
        <div>
          <strong>
            תאריך חיוב/ביצוע עסקה:{' '}
            {row.challengePaymentDateOfBilling ? moment(row.challengePaymentDateOfBilling).format('DD/MM/YYYY') : 'עוד לא חויב'}
          </strong>
        </div>
        <div>
          <br />
          <br />
          פרטי העסקה:
          <PaymentDetailsLabel>
            {row.challengePaymentDetails
              ? row.challengePaymentDetails.split(',').map(function(item, idx) {
                  return (
                    <span key={idx}>
                      {item}
                      <br />
                    </span>
                  )
                })
              : ''}
          </PaymentDetailsLabel>
        </div>
      </div>
    ) : (
      'לא הזין פרטי אשראי'
    )
  }

  loadPersonalStatsOfActiveUser = async activeUserId => {
    const personalStatsList = await invokeAPIcall({ uri: `api/personalStats/GetByUser?id=${activeUserId}` })
    this.setState({ personalStatsList })
  }

  // UI event methods
  handleInitMealListOfSpecificUser = async user => {
    if (window.confirm(`לוח הארוחות של משתמש זה ${user.email} יתאפס לגמרי כעת וכל 90 הימים ייבנו מחדש. האם אתה מאשר?`)) {
      // invoke API call to init the user's meal list
      try {
        await invokeAPIcall({ uri: `api/users/InitMealListOfSpecificUser?id=${user.id}`, data: {}, method: 'PUT' })
        alert('בנייה מחדש של לוח הארוחות הושלמה')
      } catch (error) {}
    }
  }

  handleInitWorkoutListOfSpecificUser = async user => {
    if (window.confirm(`לוח האימונים של משתמש זה ${user.email} יתאפס לגמרי כעת וכל 90 הימים ייבנו מחדש. האם אתה מאשר?`)) {
      // invoke API call to init the user's workout list
      try {
        await invokeAPIcall({ uri: `api/users/InitWorkoutListOfSpecificUser?id=${user.id}`, data: {}, method: 'PUT' })
        alert('בנייה מחדש של לוח האימונים הושלמה')
      } catch (error) {}
    }
  }

  handleShowChallengeDaySelector = () => {
    const { showChallengeDaySelector } = this.state
    this.setState({ showChallengeDaySelector: !showChallengeDaySelector })
  }

  handleChangeChallengeProgressOfUser = async (user, e) => {
    const returnToDayNumber = e.target.value
    if (window.confirm(`חוויית החיטוב של משתמש זה ${user.email} תזוז ליום ${returnToDayNumber} של האתגר. האם אתה מאשר?`)) {
      // invoke API call to change the user's challange progress day
      try {
        await invokeAPIcall({
          uri: `api/users/ChangeChallengeProgressOfSpecificUser?id=${user.id}&returnToDayNumber=${returnToDayNumber}`,
          data: {},
          method: 'PUT',
        })
        alert('המשתמש הוזז ליום שהוגדר')
        this.setState({ activeRow: null })
      } catch (error) {}
    }
  }

  handleResetChallengeOfSpecificUser = async user => {
    if (
      window.confirm(
        `האתגר של משתמש זה ${user.email} יתאפס לגמרי כעת והוא יוחזר ליום 0. כל הנתונים יימחקו והוא יצטרך למלא את כל המדדים מחדש. האם אתה מאשר?`,
      )
    ) {
      // invoke API call to reset the progress of challenge for this user
      try {
        await invokeAPIcall({ uri: `api/users/ResetUserStartedChallengeOfSpecificUser?id=${user.id}`, data: {}, method: 'PUT' })
        alert('האתגר התאפס למשתמש זה')
        this.setState({ activeRow: null })
      } catch (error) {}
    }
  }

  handleFakePurchaseOfSpecificUser = async user => {
    if (window.confirm(`המערכת תיתן למשתמש זה ${user.email} גישה מלאה לתוכנית המלאה בתשלום, מבלי שהוא רכש. האם אתה מאשר?`)) {
      // invoke API call to reset the progress of challenge for this user
      try {
        await invokeAPIcall({ uri: `api/users/FakeChallengePurchaseOfSpecificUser?id=${user.id}`, data: {}, method: 'PUT' })
        alert('משתמש זה כעת ייחשב כמי שרכש את החווייה המלאה בתשלום')
        window.location.reload()
      } catch (error) {}
    }
  }

  handleCancelFakePurchaseOfSpecificUser = async user => {
    if (
      window.confirm(`המערכת תבטל למשתמש זה ${user.email} את הגישה החינמית לתוכנית המלאה בתשלום, שקיבל בעבר מבלי שהוא רכש. האם אתה מאשר?`)
    ) {
      // invoke API call to reset the progress of challenge for this user
      try {
        await invokeAPIcall({ uri: `api/users/CancelFakeChallengePurchaseOfSpecificUser?id=${user.id}`, data: {}, method: 'PUT' })
        alert('משתמש זה כבר לא ייחשב כמי שרכש את החווייה המלאה בתשלום')
        window.location.reload()
      } catch (error) {}
    }
  }

  // Render methods
  renderInfoRow = row => {
    const { personalStatsList, showChallengeDaySelector } = this.state
    const userData = { ...row }
    userData.ChallengePaymentDate = userData.challengePaymentDate // just update to make the prop uppercase (for the next function)
    userData.ChallengePaymentDateOfBilling = userData.challengePaymentDateOfBilling // just update to make the prop uppercase (for the next function)
    const daysSinceStartedChallenge = personalStatsList ? calcDaysSinceStartedChallenge(personalStatsList, userData) + 1 : 'טוען...'

    let maxDayToChangeTo = 90
    if (daysSinceStartedChallenge <= 45) {
      maxDayToChangeTo = 45
    } else if (daysSinceStartedChallenge <= 75) {
      maxDayToChangeTo = 75
    }
    if (row.challengePaymentDate == null) {
      maxDayToChangeTo = 4 // trial user
    }
    const availableDaysToChangeTo = []
    for (var i = 1; i <= maxDayToChangeTo; i++) {
      availableDaysToChangeTo.push(i)
    }

    return (
      <InfoTableRow key={`infoRow${row.id}`}>
        <TableCell></TableCell>
        <TableCell colSpan={8}>
          <ChallengeInfoList>
            <ChallengeInfoListItem>
              <ChallengeInfoListItemLabel>פרטים אישיים נוספים:</ChallengeInfoListItemLabel>
              <div>טלפון: {row.phone || '-'}</div>
              <div>מין: {row.gender ? (row.gender === 'male' ? 'זכר' : 'נקבה') : '-'}</div>
              <div>גיל: {row.birthDate ? moment().diff(row.birthDate, 'years') : '-'}</div>
            </ChallengeInfoListItem>
          </ChallengeInfoList>
          <ChallengeInfoList>
            <ChallengeInfoListItem>
              <ChallengeInfoListItemLabel>תאריך הרשמה לחוויית החיטוב:</ChallengeInfoListItemLabel>
              {this.getStartedChallengeDateDisplayValue(row)}
            </ChallengeInfoListItem>
            <ChallengeInfoListItem>
              <ChallengeInfoListItemLabel>רכישת חוויית החיטוב המלאה בתשלום:</ChallengeInfoListItemLabel>
              {this.getChallengePaymentDateDisplayValue(row)}
              {row.challengePaymentDate == null && row.startedChallengeDate && (
                <StyledButton variant="contained" color="primary" onClick={this.handleFakePurchaseOfSpecificUser.bind(this, row)}>
                  תן לו ״חווייה מלאה בתשלום״ בחינם
                </StyledButton>
              )}
              {row.challengePaymentDate != null && row.challengePaymentTotalPrice === 0 && (
                <StyledButton variant="contained" color="primary" onClick={this.handleCancelFakePurchaseOfSpecificUser.bind(this, row)}>
                  בטל ״חוויה מלאה בתשלום״ שקיבל בחינם
                </StyledButton>
              )}
            </ChallengeInfoListItem>
            <ChallengeInfoListItem>
              <ChallengeInfoListItemLabel>יום נוכחי מתוך ה-90:</ChallengeInfoListItemLabel>
              {row.challengePaymentDate ? (
                <>
                  {daysSinceStartedChallenge}
                  <UserChallengeProgressBarWrapper>
                    <UserChallengeProgressBar percentage={Math.floor((daysSinceStartedChallenge / 90) * 100)} />
                  </UserChallengeProgressBarWrapper>
                </>
              ) : (
                'לא הזין פרטי אשראי'
              )}
            </ChallengeInfoListItem>
            <ChallengeInfoListItem>
              <ChallengeInfoListItemLabel>מידע על סיום חוויית החיטוב:</ChallengeInfoListItemLabel>
              {row.endOfChallenge_howWent ? (
                <div>
                  <div>
                    <strong>חוות דעת בסיום:</strong> {row.endOfChallenge_howWent}
                  </div>
                  <div>
                    <strong>האם מאשר שימוש בתמונתו:</strong> {row.endOfChallenge_allowPhoto ? 'כן' : 'לא'}
                  </div>
                  <div>
                    <strong>חבר שהזמין:</strong>
                    {row.endOfChallenge_friendInviteEmail
                      ? `מייל: ${row.endOfChallenge_friendInviteEmail}. טל: ${row.endOfChallenge_friendInvitePhone}`
                      : 'לא הזמין'}
                  </div>
                </div>
              ) : (
                'לא סיים'
              )}
            </ChallengeInfoListItem>
          </ChallengeInfoList>
          <ChallengeActionButtons>
            <StyledButton variant="contained" color="primary" onClick={this.handleInitMealListOfSpecificUser.bind(this, row)}>
              בנה מחדש את לוח הארוחות באתגר
            </StyledButton>
            <StyledButton variant="contained" color="primary" onClick={this.handleInitWorkoutListOfSpecificUser.bind(this, row)}>
              בנה מחדש את לוח האימונים באתגר
            </StyledButton>
            <StyledButton variant="contained" color="primary" onClick={this.handleShowChallengeDaySelector}>
              הזז אותו ליום ספציפי באתגר
            </StyledButton>
            {showChallengeDaySelector && (
              <StyledSelect
                selectParams={{
                  name: `challengeDaySelector`,
                  value: daysSinceStartedChallenge,
                  onChange: this.handleChangeChallengeProgressOfUser.bind(this, row),
                  margin: 'normal',
                  inputProps: {
                    name: `challengeDaySelector`,
                    id: `challengeDaySelector-simple`,
                  },
                }}
                htmlFor={`challengeDaySelector-simple`}
                variant="outlined"
                fullWidth={false}
              >
                {availableDaysToChangeTo.map(item => (
                  <MenuItem value={item}>יום {item}</MenuItem>
                ))}
              </StyledSelect>
            )}
            <StyledButton variant="contained" color="secondary" onClick={this.handleResetChallengeOfSpecificUser.bind(this, row)}>
              אפס אתגר וחזור ליום 0
            </StyledButton>
          </ChallengeActionButtons>
        </TableCell>
      </InfoTableRow>
    )
  }

  render() {
    const { users, selected, order, orderBy, page, rowsPerPage, activeRow } = this.state
    return (
      <PageWrapper>
        <Breadcrumbs
          previousItems={[
            {
              label: `מסכי ניהול`,
              url: `/adminPanel`,
            },
          ]}
          currentItem={`ניהול משתמשים`}
        />
        <Paper>
          <EnhancedTableToolbar numSelected={selected.length} handleDeleteItems={this.handleDeleteItems} />
          <TableContainer>
            <Table aria-labelledby="tableTitle" size={'small'} aria-label="enhanced table">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={users.length}
              />
              <TableBody>
                {stableSort(users, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = this.isSelected(row.id)
                    const labelId = `enhanced-table-checkbox-${index}`

                    return (
                      <>
                        <StyledTableRow
                          hover
                          onClick={event => this.handleClick(event, row.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                          isActive={activeRow === row.id}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              onClick={event => this.handleCheckboxClick(event, row.id)}
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </TableCell>
                          <TableCell component="th" id={labelId} scope="row" padding="none">
                            {row.id}
                          </TableCell>
                          <TableCell>{row.firstName ? row.firstName + ' ' + row.lastName : '-'}</TableCell>
                          <TableCell>{row.email}</TableCell>
                          <TableCell>{row.isActivated ? 'כן' : 'לא'}</TableCell>
                          <TableCell>{row.userRoles}</TableCell>
                          <TableCell>{this.getStartedChallengeDateDisplayValue(row)}</TableCell>
                          <TableCell>
                            {row.challengePaymentDate ? moment(row.challengePaymentDate).format('DD/MM/YYYY') : 'לא הזין אשראי'}
                          </TableCell>
                          <TableCell>
                            <ShowMoreInfoLabel>הצג פרטים נוספים על חוויית החיטוב</ShowMoreInfoLabel>
                          </TableCell>
                        </StyledTableRow>
                        {activeRow === row.id && this.renderInfoRow(row)}
                      </>
                    )
                  })}
                {this.emptyRows > 0 && (
                  <TableRow style={{ height: 33 * this.emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            labelRowsPerPage="פריטים לכל דף:"
            labelDisplayedRows={({ from, to, count }) => `${from}-${to === -1 ? count : to} מתוך ${count}`}
          />
        </Paper>
      </PageWrapper>
    )
  }
}

export default withRouter(UsersAdminScreen)
