import { connect } from 'react-redux'
import ChallengePaymentScreen from '../components/ChallengePaymentScreen'
import { openSnackbar } from '../bundles/snackbar'
import { showFullScreenLoading, hideFullScreenLoading } from '../bundles/fullScreenLoading'

export default connect(
  state => ({
    currentUser: state.currentUser.user,
  }),
  {
    openSnackbar,
    showFullScreenLoading,
    hideFullScreenLoading,
  },
)(ChallengePaymentScreen)
