import { connect } from 'react-redux'
import ProductDetailsScreen from '../components/ProductDetailsScreen'
import { addProductToCart } from '../bundles/shoppingCart'

export default connect(
  state => ({}),
  {
    addProductToCart,
  },
)(ProductDetailsScreen)
