import { combineReducers } from 'redux'
import currentUser from '../bundles/currentUser'
import shoppingCart from '../bundles/shoppingCart'
import snackbar from '../bundles/snackbar'
import presetStrings from '../bundles/presetStrings'
import fullScreenLoading from '../bundles/fullScreenLoading'

const rootReducer = combineReducers({
  currentUser,
  shoppingCart,
  snackbar,
  presetStrings,
  fullScreenLoading,
})

export default rootReducer
