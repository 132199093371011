import React, { Component } from 'react'
import styled from 'styled-components'
import { getProductPrices } from '../services/pricesHelper'
import { invokeAPIcall } from '../services/rest'

// Consts

// Style
const ProductPrice = styled.div`
  font-weight: 700;
  font-size: 20px;
`
const ListedPriceLabel = styled.span`
  text-decoration: line-through;
  margin-right: 10px;
  font-size: 12px;
`

// Component
class ProductPriceTag extends Component {
  state = {
    appliedCoupon: null,
  }

  // Lifecycle methods
  componentDidMount() {
    // load 'couponCode' from the sessionStorage (if there is one)
    const couponCode = sessionStorage.getItem('couponCode')
    if (couponCode) {
      this.loadCouponData(couponCode)
    }
  }

  // Helper methods
  loadCouponData = async couponCode => {
    let couponData = await invokeAPIcall({ uri: `api/coupons/getByCouponCode?code=${couponCode}` })
    this.setState({ appliedCoupon: couponData })
  }

  // UI event methods

  // Render methods
  renderPrice = () => {
    const { productItem, globalSettingsData, showPricePerItem } = this.props
    const { appliedCoupon } = this.state
    const { listedPrice, actualPrice, displayPricePerItem, displayPricePerItemWord } = getProductPrices(productItem, globalSettingsData)

    let actualPriceToDisplay = `₪${actualPrice.toLocaleString()}`
    if (showPricePerItem && displayPricePerItem) {
      actualPriceToDisplay = `₪${displayPricePerItem.toLocaleString()} ${displayPricePerItemWord || ''}`
    }

    /* apply coupon
    ---------------------*/
    if (appliedCoupon) {
      const { FixedDiscount, PercentageDiscount, SpecificProductID, SpecificToChallenge } = appliedCoupon
      let newPriceAfterCoupon = actualPrice
      let shouldApplyDiscount = false

      // for specific product
      if (SpecificProductID !== null) {
        if (SpecificProductID === productItem.ProductID) {
          shouldApplyDiscount = true
        }
      }
      // only specific for 90days challenge
      else if (SpecificToChallenge === true) {
      }
      // global discount
      else {
        shouldApplyDiscount = true
      }

      if (shouldApplyDiscount) {
        if (FixedDiscount) {
          newPriceAfterCoupon -= FixedDiscount
        } else if (PercentageDiscount) {
          newPriceAfterCoupon -= Math.floor(newPriceAfterCoupon * (PercentageDiscount / 100))
        }

        actualPriceToDisplay = `₪${newPriceAfterCoupon.toLocaleString()}`
      }
    }

    if (listedPrice !== null && actualPrice !== null) {
      return (
        <>
          <ListedPriceLabel>₪{listedPrice.toLocaleString()}</ListedPriceLabel>
          {actualPriceToDisplay}
        </>
      )
    } else {
      return <>{actualPriceToDisplay}</>
    }
  }

  render() {
    return <ProductPrice>{this.renderPrice()}</ProductPrice>
  }
}

export default ProductPriceTag
