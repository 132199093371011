import React from 'react'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import { colorPalette } from '../styles/styleVariables'

// Style
const StyledTextField = styled(TextField)`
  && {
    .MuiInput-input {
      color: ${colorPalette.primary1.main};
    }
    .MuiInputLabel-root {
      color: ${colorPalette.primary1.main};
    }
    .MuiInputLabel-root.Mui-focused {
      color: ${colorPalette.primary1.dark};
    }
    .MuiInputBase-input.Mui-disabled {
      color: ${colorPalette.tertiary1.main};
    }
    .MuiInput-root {
      &::after {
        border-bottom: 2px solid ${colorPalette.primary1.dark};
      }
      ::before {
        border-bottom: 1px solid ${colorPalette.primary1.main};
      }
      &:hover::before {
        border-bottom: 2px solid ${colorPalette.primary1.dark};
      }
    }
  }
`

// Component
function TextFieldComponent({ ...params }) {
  return <StyledTextField {...params} />
}

export default TextFieldComponent
