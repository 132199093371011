import React, { Component } from 'react'
import styled from 'styled-components'
import { Link, withRouter } from 'react-router-dom'
import moment from 'moment'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import InfoIcon from '@material-ui/icons/Info'
import { invokeAPIcall } from '../../services/rest'
import { colorPalette } from '../../styles/styleVariables'
import Breadcrumbs from '../Breadcrumbs'

// Style
const PageWrapper = styled.div`
  padding: 20px;
`
const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  && {
    color: ${colorPalette.custom.success};
  }
`
const StyledTableRow = styled(TableRow)`
  && {
    ${props =>
      props.isActive
        ? `
    background: ${colorPalette.tertiary1.light};
    &&:hover {
      background: ${colorPalette.tertiary1.light};
    }
    `
        : ''}
  }
`
const InfoTableRow = styled(TableRow)`
  && {
    background: ${colorPalette.tertiary1.light};
  }
`
const OrderDetailsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px;
`
const ClientInfo = styled.div`
  flex: 1;
`
const ProductsInfo = styled(ClientInfo)``
const PaymentInfo = styled(ClientInfo)`
  margin-right: 60px;
`
const PaidLabel = styled.div`
  font-size: 18px;
  color: ${colorPalette.custom.success};
`
const NotPaidLabel = styled(PaidLabel)`
  color: ${colorPalette.custom.warning};
`
const PaypalPaymentDetailsLabel = styled.div`
  font-size: 10px;
`
const ProductsTable = styled.table`
  border: 1px solid ${colorPalette.tertiary1.dark};
  th,
  td {
    border: 1px solid ${colorPalette.tertiary1.dark};
    padding: 5px;
  }
`
const ShipmentType = styled.div`
  font-size: 20px;
`
const MonthlyInstallments = styled(ShipmentType)``
const PaymentType = styled(ShipmentType)``

// Consts/Helpers
function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy)
}

const headCells = [
  { id: 'id', numeric: false, disablePadding: true, label: 'מספר הזמנה' },
  { id: 'name', numeric: false, disablePadding: false, label: 'שם הלקוח' },
  { id: 'email', numeric: false, disablePadding: false, label: 'מייל' },
  { id: 'totalPrice', numeric: false, disablePadding: false, label: 'סכום הזמנה' },
  { id: 'date', numeric: false, disablePadding: false, label: 'תאריך הזמנה' },
  { id: 'didPay', numeric: false, disablePadding: false, label: 'האם שולם' },
]

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'בחר הכל' }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel active={orderBy === headCell.id} direction={order} onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? <span>{order === 'desc' ? '' : ''}</span> : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const EnhancedTableToolbar = props => {
  const { numSelected, handleDeleteItems } = props

  return (
    <Toolbar>
      {numSelected > 0 ? (
        <Typography color="inherit" variant="subtitle1">
          {numSelected} נבחרו
        </Typography>
      ) : (
        <Typography variant="h6" id="tableTitle">
          ניהול הזמנות
        </Typography>
      )}
    </Toolbar>
  )
}

// Component
class OrdersAdminScreen extends Component {
  state = {
    orders: [],
    order: 'desc',
    orderBy: 'id',
    selected: [],
    page: 0,
    dense: true,
    rowsPerPage: 50,
    activeRow: null,
    allProducts: [],
  }

  // Lifecycle methods
  componentDidMount() {
    this.loadOrderList()
    this.loadAllProducts()
  }

  // Helper methods
  loadOrderList = async () => {
    const orderList = await invokeAPIcall({ uri: 'api/orders' })
    this.setState({
      orders: orderList.map(order => ({
        id: order.OrderID,
        name: order.Name,
        invoiceName: order.InvoiceName,
        totalPrice: order.TotalPrice,
        date: order.Date,
        email: order.Email,
        phone: order.Phone,
        address: order.Address,
        productsList: order.ProductsList,
        paypalDate: order.PaypalDate,
        paypalTotalPrice: order.PaypalTotalPrice,
        paypalPaymentDetails: order.PaypalPaymentDetails,
        paypalEmail: order.PaypalEmail,
        paymentType: order.PaymentType,
        includeShipment: order.IncludeShipment,
        shipmentType: order.ShipmentType,
        monthlyInstallments: order.MonthlyInstallments,
      })),
    })
  }

  loadAllProducts = async () => {
    const productList = await invokeAPIcall({ uri: 'api/products' })
    this.setState({ allProducts: productList.map(product => ({ id: product.ProductID, name: product.Name })) })
  }

  handleRequestSort = (event, property) => {
    const { order, orderBy } = this.state
    const isDesc = orderBy === property && order === 'desc'
    this.setState({ order: isDesc ? 'asc' : 'desc', orderBy: property })
  }

  handleSelectAllClick = event => {
    const { orders } = this.state
    if (event.target.checked) {
      const newSelecteds = orders.map(n => n.id)
      this.setState({ selected: newSelecteds })
      return
    }
    this.setState({ selected: [] })
  }

  handleClick = (event, name) => {
    const { selected, activeRow } = this.state
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }

    this.setState({ /*selected: newSelected, */ activeRow: activeRow === name ? null : name })
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage })
  }

  handleChangeRowsPerPage = event => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) })
  }

  isSelected = name => {
    const { selected } = this.state
    return selected.indexOf(name) !== -1
  }

  emptyRows = () => {
    const { orders, rowsPerPage, page } = this.state
    return rowsPerPage - Math.min(rowsPerPage, orders.length - page * rowsPerPage)
  }

  // UI event methods

  // Render methods
  renderInfoRow = row => {
    const { allProducts } = this.state
    const { productsList } = row
    let productsListData = []
    if (productsList) {
      productsListData = JSON.parse(productsList)
      productsListData = productsListData.map(product => {
        const foundProduct = allProducts.filter(p => p.id === product.productId)
        return {
          ...product,
          name: foundProduct && foundProduct[0] && foundProduct[0].name,
        }
      })
    }
    let paymentType = 'לא ידוע'
    if (row.paymentType === 'paypal') {
      paymentType = 'paypal'
    } else if (row.paymentType === 'credit') {
      paymentType = 'פלקארד'
    } else if (row.paymentType === 'in-app') {
      paymentType = 'אפליקציה'
    }
    return (
      <InfoTableRow key={`infoRow${row.id}`}>
        <TableCell></TableCell>
        <TableCell colSpan={7}>
          <OrderDetailsWrapper>
            <ClientInfo>
              <div>שם הלקוח: {row.name}</div>
              <div>שם על החשבונית: {row.invoiceName}</div>
              <div>טלפון: {row.phone}</div>
              <div>מייל: {row.email}</div>
              <div>כתובת: {row.address}</div>
              <ShipmentType>משלוח: {row.includeShipment ? 'כולל משלוח בדואר' : 'איסוף עצמי'}</ShipmentType>
              {row.includeShipment && <ShipmentType>סוג משלוח: {row.shipmentType === 'quick' ? 'משלוח מהיר' : 'משלוח רגיל'}</ShipmentType>}
            </ClientInfo>
            <ProductsInfo>
              מוצרים בהזמנה:
              <ProductsTable>
                <tr>
                  <th>מוצר</th>
                  <th>כמות</th>
                </tr>
                {productsListData.map(productData => (
                  <tr>
                    <td>{productData && productData.name}</td>
                    <td>{productData && productData.quantity}</td>
                  </tr>
                ))}
              </ProductsTable>
            </ProductsInfo>
            <PaymentInfo>
              {row.paypalDate ? <PaidLabel>העסקה הושלמה</PaidLabel> : <NotPaidLabel>עדיין לא שולם</NotPaidLabel>}
              {row.paypalDate && (
                <div>
                  <PaymentType>שולם באמצעות: {paymentType}</PaymentType>
                  <MonthlyInstallments>מספר תשלומים: {row.monthlyInstallments}</MonthlyInstallments>
                  <div>תאריך תשלום: {moment(row.paypalDate).format('DD/MM/YYYY HH:mm')}</div>
                  <div>סכום ששולם: ₪{row.paypalTotalPrice}</div>
                  <div>מייל המשלם: {row.paypalEmail}</div>
                  פרטי העסקה:
                  <PaypalPaymentDetailsLabel>
                    {row.paypalPaymentDetails
                      ? row.paypalPaymentDetails.split(',').map(function(item, idx) {
                          return (
                            <span key={idx}>
                              {item}
                              <br />
                            </span>
                          )
                        })
                      : ''}
                  </PaypalPaymentDetailsLabel>
                </div>
              )}
            </PaymentInfo>
          </OrderDetailsWrapper>
        </TableCell>
      </InfoTableRow>
    )
  }

  render() {
    const { orders, selected, order, orderBy, page, rowsPerPage, activeRow } = this.state
    return (
      <PageWrapper>
        <Breadcrumbs
          previousItems={[
            {
              label: `מסכי ניהול`,
              url: `/adminPanel`,
            },
          ]}
          currentItem={`ניהול הזמנות`}
        />
        <Paper>
          <EnhancedTableToolbar numSelected={selected.length} handleDeleteItems={this.handleDeleteItems} />
          <TableContainer>
            <Table aria-labelledby="tableTitle" size={'small'} aria-label="enhanced table">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={orders.length}
              />
              <TableBody>
                {stableSort(orders, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = this.isSelected(row.id)
                    const labelId = `enhanced-table-checkbox-${index}`

                    return (
                      <>
                        <StyledTableRow
                          hover
                          onClick={event => this.handleClick(event, row.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                          isActive={activeRow === row.id}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                          </TableCell>
                          <TableCell component="th" id={labelId} scope="row" padding="none">
                            {row.id}
                          </TableCell>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.email}</TableCell>
                          <TableCell>₪{row.totalPrice}</TableCell>
                          <TableCell>{moment(row.date).format('DD/MM/YYYY HH:mm')}</TableCell>
                          <TableCell>{row.paypalDate ? <StyledCheckCircleIcon /> : <InfoIcon />}</TableCell>
                        </StyledTableRow>
                        {activeRow === row.id && this.renderInfoRow(row)}
                      </>
                    )
                  })}
                {this.emptyRows > 0 && (
                  <TableRow style={{ height: 33 * this.emptyRows }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={orders.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            labelRowsPerPage="פריטים לכל דף:"
            labelDisplayedRows={({ from, to, count }) => `${from}-${to === -1 ? count : to} מתוך ${count}`}
          />
        </Paper>
      </PageWrapper>
    )
  }
}

export default withRouter(OrdersAdminScreen)
