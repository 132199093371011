// define the list 'PresetStrings' for offline use (when running as a cordova app and user doesn't have internet connection)
export const presetStringsForOffline = [
  {
    PresetStringID: 1,
    CodeKey: 'TrainersCarouselItemTitle',
    DescKey: 'שקופית מאמנים 1 - כותרת ראשית',
    HebrewText: 'מאמני כושר בפריסה ארצית',
    EnglishText: null,
  },
  {
    PresetStringID: 3,
    CodeKey: 'TrainersCarouselItemSubTitle',
    DescKey: 'שקופית מאמנים 1 - כותרת משנה',
    HebrewText: 'ליהנות מיחס אישי וקשוב להשגת גוף חטוב בזמינות גבוהה עד פתח ביתך.',
    EnglishText: null,
  },
  {
    PresetStringID: 4,
    CodeKey: 'TrainersCarouselItemListItem1',
    DescKey: 'שקופית מאמנים 1 - פריט 1 ברשימה',
    HebrewText: 'להגיע אל האושר דרך הכושר',
    EnglishText: null,
  },
  {
    PresetStringID: 5,
    CodeKey: 'TrainersCarouselItemListItem2',
    DescKey: 'שקופית מאמנים 1 - פריט 2 ברשימה',
    HebrewText: 'להרגיש את העוצמות ולהוציא את החיה שבך',
    EnglishText: null,
  },
  {
    PresetStringID: 6,
    CodeKey: 'TrainersCarouselItemListItem3',
    DescKey: 'שקופית מאמנים 1 - פריט 3 ברשימה',
    HebrewText: 'לכבוש את המטרות ביעילות ובמהירות',
    EnglishText: null,
  },
  {
    PresetStringID: 7,
    CodeKey: 'TrainersCarouselItemListItem4',
    DescKey: 'שקופית מאמנים 1 - פריט 4 ברשימה',
    HebrewText: 'כולל מתיחות וגמישות הגוף והמפרקים',
    EnglishText: null,
  },
  {
    PresetStringID: 8,
    CodeKey: 'TrainersCarouselItemListItem5',
    DescKey: 'שקופית מאמנים 1 - פריט 5 ברשימה',
    HebrewText: 'להפוך את החברים למעריצים שלך',
    EnglishText: null,
  },
  {
    PresetStringID: 9,
    CodeKey: 'TrainersCarouselItemListItem6',
    DescKey: 'שקופית מאמנים 1 - פריט 6 ברשימה',
    HebrewText: 'אימון היכרות במבצע של 90 ש"ח בלבד',
    EnglishText: null,
  },
  {
    PresetStringID: 10,
    CodeKey: 'OurTrainersCarouselItemTitle',
    DescKey: 'שקופית מאמנים 2 - כותרת ראשית',
    HebrewText: 'המאמנים שלנו',
    EnglishText: null,
  },
  {
    PresetStringID: 11,
    CodeKey: 'OurTrainersCarouselItemSubTitle',
    DescKey: 'שקופית מאמנים 2 - כותרת משנה',
    HebrewText:
      'מאמנות ומאמנים מן הטובים בארץ, ממגוון ענפי הספורט השונים בעלי תעודות הסמכה, ניסיון רב ואמביציה לתת לך חוויית אימון מהנה ובהתאמה אישית. ילוו אותך לאורך כל הדרך אצלך בבית\\פארק\\גינה\\חוף הים לפי בקשתך עד לתוצאות הרצויות בשילוב תזונה נכונה ואורח חיים בריא.',
    EnglishText: null,
  },
  {
    PresetStringID: 12,
    CodeKey: 'OurTrainersCarouselItemListItem1',
    DescKey: 'שקופית מאמנים 2 - פריט 1 ברשימה',
    HebrewText: 'דרבון ויחס אישי עד להגעת התוצאות המושלמות',
    EnglishText: null,
  },
  {
    PresetStringID: 13,
    CodeKey: 'OurTrainersCarouselItemListItem2',
    DescKey: 'שקופית מאמנים 2 - פריט 2 ברשימה',
    HebrewText: 'שקילות ומדידות לאורך כל תהליך השינוי',
    EnglishText: null,
  },
  {
    PresetStringID: 14,
    CodeKey: 'OurTrainersCarouselItemListItem3',
    DescKey: 'שקופית מאמנים 2 - פריט 3 ברשימה',
    HebrewText: 'אימונים באווירה כיפית במיוחד עם מוזיקה שתגרום לך ליהנות מכל רגע בתהליך',
    EnglishText: null,
  },
  {
    PresetStringID: 15,
    CodeKey: 'OurTrainersCarouselItemListItem4',
    DescKey: 'שקופית מאמנים 2 - פריט 4 ברשימה',
    HebrewText: 'המלצות תזונה הקשורות למטרות שלך',
    EnglishText: null,
  },
  {
    PresetStringID: 16,
    CodeKey: 'OurTrainersCarouselItemListItem5',
    DescKey: 'שקופית מאמנים 2 - פריט 5 ברשימה',
    HebrewText: 'בניית תוכנית אימונים אישית וממוקדת מטרה',
    EnglishText: null,
  },
  {
    PresetStringID: 17,
    CodeKey: 'OurTrainersCarouselItemListItem6',
    DescKey: 'שקופית מאמנים 2 - פריט 6 ברשימה',
    HebrewText: 'אפשרות להחלפת מאמן/סוג אימון בכל שלב בתוכנית האימונים ללא תוספת תשלום',
    EnglishText: null,
  },
  {
    PresetStringID: 18,
    CodeKey: 'HighlightProductsCarouselItemTitle',
    DescKey: 'שקופית מוצרי דגל - כותרת ראשית',
    HebrewText: 'מוצרי הדגל שלנו',
    EnglishText: null,
  },
  {
    PresetStringID: 19,
    CodeKey: 'HighlightProductsCarouselItemSubTitle',
    DescKey: 'שקופית מוצרי דגל - כותרת משנה',
    HebrewText: 'מוצרים לרכישה אונליין דרך האתר',
    EnglishText: null,
  },
  {
    PresetStringID: 20,
    CodeKey: 'ChallengeCarouselItemTitle1',
    DescKey: 'שקופית אתגר 1 - כותרת ראשית',
    HebrewText: 'מי לא מחפש להיות חטוב, \nמאושר ובריא יותר?',
    EnglishText: null,
  },
  {
    PresetStringID: 21,
    CodeKey: 'ChallengeCarouselItemSubTitle1',
    DescKey: 'שקופית אתגר 1 - כותרת משנה1',
    HebrewText: 'ליווי צמוד לעבר המטרות שלך בשיטה ייחודית ומהירה ב-90 יום משנה חיים.',
    EnglishText: null,
  },
  {
    PresetStringID: 22,
    CodeKey: 'ChallengeCarouselItemSubTitle2',
    DescKey: 'שקופית אתגר 1 - כותרת משנה2',
    HebrewText: 'רוצה להיות חלק מההצלחה הזו?',
    EnglishText: null,
  },
  {
    PresetStringID: 23,
    CodeKey: 'ChallengeCarouselItemListItem1',
    DescKey: 'שקופית אתגר 1 - פריט 1 ברשימה',
    HebrewText: 'לכבוש את המטרות שלך',
    EnglishText: null,
  },
  {
    PresetStringID: 25,
    CodeKey: 'ChallengeCarouselItemListItem1Desc',
    DescKey: 'שקופית אתגר 1 - פריט 1 ברשימה - תיאור',
    HebrewText: 'אימונים מאתגרים ומהנים למטרת חיטוב והרזיה מכל מקום ובכל זמן עם ליווי של מאמן צמוד בהתאמה אישית.',
    EnglishText: null,
  },
  {
    PresetStringID: 26,
    CodeKey: 'ChallengeCarouselItemListItem2',
    DescKey: 'שקופית אתגר 1 - פריט 2 ברשימה',
    HebrewText: 'ליהנות בקלות מטעמי הבריאות',
    EnglishText: null,
  },
  {
    PresetStringID: 27,
    CodeKey: 'ChallengeCarouselItemListItem2Desc',
    DescKey: 'שקופית אתגר 1 - פריט 2 ברשימה - תיאור',
    HebrewText: 'מאכלים לפי בחירה אישית כולל טבעוניים וצמחוניים עם מתכונים מופלאים ובריאים בהכנה פשוטה ומהירה.',
    EnglishText: null,
  },
  {
    PresetStringID: 28,
    CodeKey: 'ChallengeCarouselItemListItem3',
    DescKey: 'שקופית אתגר 1 - פריט 3 ברשימה',
    HebrewText: 'להיות גמיש להרגיש חזק לחיות בריא',
    EnglishText: null,
  },
  {
    PresetStringID: 29,
    CodeKey: 'ChallengeCarouselItemListItem3Desc',
    DescKey: 'שקופית אתגר 1 - פריט 3 ברשימה - תיאור',
    HebrewText: 'אימונים לשיפור בעיות יציבה הסובלים מכאבי גב, צוואר וברכיים כולל איזון מדדי הדם (סוכר, כולסטרול, לחץ דם ועוד).',
    EnglishText: null,
  },
  {
    PresetStringID: 30,
    CodeKey: 'ChallengeCarouselItemListItem4',
    DescKey: 'שקופית אתגר 1 - פריט 4 ברשימה',
    HebrewText: 'להתייעץ איתנו ולהרגיש בטוחים בכל שלב',
    EnglishText: null,
  },
  {
    PresetStringID: 31,
    CodeKey: 'ChallengeCarouselItemListItem4Desc',
    DescKey: 'שקופית אתגר 1 - פריט 4 ברשימה - תיאור',
    HebrewText: 'מענה אונליין לכל שאלה עם המומחים שלנו בזמינות גבוהה ובמקצועיות רבה!',
    EnglishText: null,
  },
  {
    PresetStringID: 32,
    CodeKey: 'MotivationCarouselItemListItem1',
    DescKey: 'שקופית אתגר 2 - פריט 1 ברשימה',
    HebrewText: 'לשלם מעט ולהצליח בגדול תוך זמן קצר',
    EnglishText: null,
  },
  {
    PresetStringID: 33,
    CodeKey: 'MotivationCarouselItemListItem2',
    DescKey: 'שקופית אתגר 2 - פריט 2 ברשימה',
    HebrewText: 'להתענג על מאכלים טעימים, בריאים וקלים להכנה',
    EnglishText: null,
  },
  {
    PresetStringID: 34,
    CodeKey: 'MotivationCarouselItemListItem3',
    DescKey: 'שקופית אתגר 2 - פריט 3 ברשימה',
    HebrewText: 'לכבוש את היעד עם מעקב צמוד עד להשגת המטרות',
    EnglishText: null,
  },
  {
    PresetStringID: 35,
    CodeKey: 'MotivationCarouselItemListItem4',
    DescKey: 'שקופית אתגר 2 - פריט 4 ברשימה',
    HebrewText: 'להתחטב ולהיות בכושר שיא ללא כל צורך בציוד משוכלל',
    EnglishText: null,
  },
  {
    PresetStringID: 36,
    CodeKey: 'MotivationCarouselItemListItem5',
    DescKey: 'שקופית אתגר 2 - פריט 5 ברשימה',
    HebrewText: 'להרגיש מאותגרים מכל מקום ובכל זמן כחלק ממסלול החיים',
    EnglishText: null,
  },
  {
    PresetStringID: 37,
    CodeKey: 'MotivationCarouselItemListItem6',
    DescKey: 'שקופית אתגר 2 - פריט 6 ברשימה',
    HebrewText: 'להתחזק מנטאלית בהעלאת המודעות האישית והביטחון העצמי',
    EnglishText: null,
  },
  {
    PresetStringID: 40,
    CodeKey: 'HighlightProductsCarouselItemSubTitle2',
    DescKey: 'שקופית מוצרי דגל - כותרת משנה 2',
    HebrewText: 'שקופית מוצרי דגל - כותרת משנה 2 - למלא טקסט למלא טקסט למלא טקסט',
    EnglishText: null,
  },
  {
    PresetStringID: 41,
    CodeKey: 'HighlightProductsCarouselItemListItem1',
    DescKey: 'שקופית מוצרי דגל - פריט 1 ברשימה',
    HebrewText: 'שקופית מוצרי דגל - פריט 1 ברשימה',
    EnglishText: null,
  },
  {
    PresetStringID: 42,
    CodeKey: 'HighlightProductsCarouselItemListItem2',
    DescKey: 'שקופית מוצרי דגל - פריט 2 ברשימה',
    HebrewText: 'שקופית מוצרי דגל - פריט 2 ברשימה',
    EnglishText: null,
  },
  {
    PresetStringID: 43,
    CodeKey: 'HighlightProductsCarouselItemListItem3',
    DescKey: 'שקופית מוצרי דגל - פריט 3 ברשימה',
    HebrewText: 'שקופית מוצרי דגל - פריט 3 ברשימה',
    EnglishText: null,
  },
  {
    PresetStringID: 44,
    CodeKey: 'HighlightProductsCarouselItemListItem4',
    DescKey: 'שקופית מוצרי דגל - פריט 4 ברשימה',
    HebrewText: 'שקופית מוצרי דגל - פריט 4 ברשימה',
    EnglishText: null,
  },
  {
    PresetStringID: 45,
    CodeKey: 'HighlightProductsCarouselItemListItem5',
    DescKey: 'שקופית מוצרי דגל - פריט 5 ברשימה',
    HebrewText: 'שקופית מוצרי דגל - פריט 5 ברשימה',
    EnglishText: null,
  },
  {
    PresetStringID: 46,
    CodeKey: 'HighlightProductsCarouselItemListItem6',
    DescKey: 'שקופית מוצרי דגל - פריט 6 ברשימה',
    HebrewText: 'שקופית מוצרי דגל - פריט 6 ברשימה',
    EnglishText: null,
  },
  {
    PresetStringID: 47,
    CodeKey: 'OurHighlightProductsCarouselItemTitle',
    DescKey: 'שקופית מוצרי דגל משנית - כותרת בימין',
    HebrewText: 'שקופית מוצרי דגל משנית - כותרת בימין - למלא טקסט למלא טקסט למלא טקסט',
    EnglishText: null,
  },
  {
    PresetStringID: 48,
    CodeKey: 'OurHighlightProductsCarouselItemListItem1',
    DescKey: 'שקופית מוצרי דגל משנית - פריט 1 בימין',
    HebrewText: 'שקופית מוצרי דגל משנית - פריט 1 בימין',
    EnglishText: null,
  },
  {
    PresetStringID: 49,
    CodeKey: 'OurHighlightProductsCarouselItemListItem2',
    DescKey: 'שקופית מוצרי דגל משנית - פריט 2 בימין',
    HebrewText: 'שקופית מוצרי דגל משנית - פריט 2 בימין',
    EnglishText: null,
  },
  {
    PresetStringID: 50,
    CodeKey: 'OurHighlightProductsCarouselItemListItem3',
    DescKey: 'שקופית מוצרי דגל משנית - פריט 3 בימין',
    HebrewText: 'שקופית מוצרי דגל משנית - פריט 3 בימין',
    EnglishText: null,
  },
  {
    PresetStringID: 51,
    CodeKey: 'OurHighlightProductsCarouselItemListItem4',
    DescKey: 'שקופית מוצרי דגל משנית - פריט 4 בימין',
    HebrewText: 'שקופית מוצרי דגל משנית - פריט 4 בימין',
    EnglishText: null,
  },
  {
    PresetStringID: 52,
    CodeKey: 'OurHighlightProductsCarouselItemListItem5',
    DescKey: 'שקופית מוצרי דגל משנית - פריט 5 בימין',
    HebrewText: 'שקופית מוצרי דגל משנית - פריט 5 בימין',
    EnglishText: null,
  },
  {
    PresetStringID: 53,
    CodeKey: 'ChallengeCountdownCarouselItemTitle1',
    DescKey: 'שקופית ספירה לאחור מבצע - כותרת',
    HebrewText: 'התחילו איתי את "חוויית החיטוב ב-90 יום"',
    EnglishText: null,
  },
  {
    PresetStringID: 55,
    CodeKey: 'ChallengeCountdownCarouselItemSubTitle1',
    DescKey: 'שקופית ספירה לאחור מבצע - כותרת משנה1',
    HebrewText: 'והגיעו לגרסה טובה יותר של עצמכם!',
    EnglishText: null,
  },
  {
    PresetStringID: 56,
    CodeKey: 'ChallengeCountdownCarouselItemSubTitle2',
    DescKey: 'שקופית ספירה לאחור מבצע - כותרת משנה2',
    HebrewText: 'ההרשמה במחיר המוזל תסתיים בעוד:',
    EnglishText: null,
  },
  {
    PresetStringID: 57,
    CodeKey: 'ChallengeCountdownCarouselItemButton',
    DescKey: 'שקופית ספירה לאחור מבצע - כפתור',
    HebrewText: 'הצטרפו עכשיו',
    EnglishText: null,
  },
  {
    PresetStringID: 58,
    CodeKey: 'ChallengeCountdownCarouselItemButtonLabel',
    DescKey: 'שקופית ספירה לאחור מבצע - מתחת לכפתור',
    HebrewText: '7 ימי התנסות בחינם!',
    EnglishText: null,
  },
  {
    PresetStringID: 60,
    CodeKey: 'TrainersCarouselItemSubTitle2',
    DescKey: 'שקופית מאמנים 1 - כותרת משנה2',
    HebrewText: 'להפוך את החלום שלך למציאות!',
    EnglishText: null,
  },
  {
    PresetStringID: 61,
    CodeKey: 'AboutUsCarouselItemTitle',
    DescKey: 'עמוד ראשי "מי אנחנו" כותרת',
    HebrewText: 'מי אנחנו?',
    EnglishText: null,
  },
  {
    PresetStringID: 62,
    CodeKey: 'AboutUsCarouselItemRightSideTitle',
    DescKey: 'עמוד ראשי "מי אנחנו" כותרת פריט ימין',
    HebrewText: 'אושרי-דילן אפלבאום',
    EnglishText: null,
  },
  {
    PresetStringID: 63,
    CodeKey: 'AboutUsCarouselItemLeftSideTitle',
    DescKey: 'עמוד ראשי "מי אנחנו" כותרת פריט שמאל',
    HebrewText: 'אלעד איאש',
    EnglishText: null,
  },
  {
    PresetStringID: 64,
    CodeKey: 'AboutUsCarouselItemRightSideText',
    DescKey: 'עמוד ראשי "מי אנחנו" טקסט פריט ימין',
    HebrewText:
      'מעל 14 שנות ניסיון בעולמות הכושר והתזונה - מוסמך מכון וינגייט כמאמן כושר ומכללת תילתן כנטורופת, תזונאי ומעסה מוסמך. ניסיון עשיר בריפוי אנשים עם מחלות כרוניות (סוכרת, שומנים בדם, יתר לחץ דם) ואקוטיות (דלקות, בצקות, בעיות עור, ייעוץ רפואי טבעי ואלטרנטיבי), מצבים נפשיים וקשיים בחיי היום-יום',
    EnglishText: null,
  },
  {
    PresetStringID: 65,
    CodeKey: 'AboutUsCarouselItemLeftSideText',
    DescKey: 'עמוד ראשי "מי אנחנו" טקסט פריט שמאל',
    HebrewText:
      'מעל 10 שנות ניסיון בעולם הכושר והתזונה - מוסמך מטעם מכון וינגייט כמאמן כושר המתמחה בהרזיה, חיטוב וחיזוק הגוף, העלאת מסת שריר, שיקום פציעות, אומנויות לחימה שונות וכניסה לאורח חיים בריא. \nאחראי על שינוי חייהם של אלפי לקוחות מאושרים וחטובים ברחבי הארץ.',
    EnglishText: null,
  },
  {
    PresetStringID: 67,
    CodeKey: 'AboutUsCarouselItemBottomLabel',
    DescKey: 'עמוד ראשי "מי אנחנו" טקסט תחתון',
    HebrewText: 'יחד הקמנו את FitThyBody במטרה לקדם את אורח החיים הבריא בישראל',
    EnglishText: null,
  },
  {
    PresetStringID: 68,
    CodeKey: 'ChallengeCarouselItemTopTitle1',
    DescKey: 'שקופית אתגר עליונה - כותרת ראשית',
    HebrewText: 'הזמן שלך לדאוג לבריאות שלך',
    EnglishText: null,
  },
  {
    PresetStringID: 69,
    CodeKey: 'ChallengeCarouselItemTopTextRight',
    DescKey: 'שקופית אתגר עליונה - צד ימין - טקסט',
    HebrewText:
      'מרוץ החיים מביא אותנו לעיתים לנקודות בהן אנו \nמבינים שאם לא נדאג לגוף איכות החיים שלנו תפגע. \nאנו יודעים שגוף בריא דורש השקעה הוליסטית של:\n',
    EnglishText: null,
  },
  {
    PresetStringID: 70,
    CodeKey: 'ChallengeCarouselItemTopButton',
    DescKey: 'שקופית אתגר עליונה - כפתור',
    HebrewText: 'להתנסות חינם',
    EnglishText: null,
  },
  {
    PresetStringID: 71,
    CodeKey: 'ArticlesCarouselItemTitle',
    DescKey: 'עמוד ראשי - מאמרים - כותרת',
    HebrewText: 'דברים שיכולים לעניין אותך',
    EnglishText: null,
  },
  {
    PresetStringID: 72,
    CodeKey: 'ContactUsCarouselItemTitle',
    DescKey: 'עמוד ראשי - צור קשר - כותרת',
    HebrewText: 'רוצה לשוחח איתנו לפני ההרשמה?',
    EnglishText: null,
  },
  {
    PresetStringID: 73,
    CodeKey: 'ContactUsCarouselItemSubTitle',
    DescKey: 'עמוד ראשי - צור קשר - כותרת משנה',
    HebrewText: 'נשמח ליצור איתך קשר ולהסביר יותר',
    EnglishText: null,
  },
  {
    PresetStringID: 74,
    CodeKey: 'ContactUsCarouselItemButton',
    DescKey: 'עמוד ראשי - צור קשר - כפתור',
    HebrewText: 'שלח הודעה',
    EnglishText: null,
  },
  {
    PresetStringID: 75,
    CodeKey: 'ChallengeIntroTitle',
    DescKey: 'אתגר - עמוד שכנוע - כותרת',
    HebrewText: '"חוויית החיטוב ב-90 יום" - שיפור החיים אונליין מהבית',
    EnglishText: null,
  },
  {
    PresetStringID: 76,
    CodeKey: 'ChallengeIntroSubTitle',
    DescKey: 'אתגר - עמוד שכנוע - כותרת משנה',
    HebrewText: "במרוץ החיים, בלחץ העבודה, בג'גלינג האינסופי בין חברים, משפחה, אנחנו קצת שוכחים לדאוג לעצמנו.",
    EnglishText: null,
  },
  {
    PresetStringID: 77,
    CodeKey: 'ChallengeIntroItem1Text',
    DescKey: 'אתגר - עמוד שכנוע - פריט 1 טקסט',
    HebrewText:
      ' זה הזמן לקחת את עצמך בידיים - בשביל זה אנחנו כאן! "חוויית החיטוב ב-90 יום" של FitThyBody נבנתה במיוחד עבורך ומבוססת על ניסיון רב מתחומי הבריאות השונים כדי להוביל אותך להצלחה מרבית. מטרת התוכנית היא להתאים אותה במיוחד עבורך על מנת להפיק את התוצאה              המקסימלית תוך כדי חיזוק וחיטוב הגוף, הורדת אחוזי שומן בשילוב אורח חיים בריא. התוכנית כוללת תזונה בריאה ומותאמת אישית.',
    EnglishText: null,
  },
  {
    PresetStringID: 78,
    CodeKey: 'ChallengeIntroItem2Title',
    DescKey: 'אתגר - עמוד שכנוע - פריט 2 כותרת',
    HebrewText: 'אימונים אישיים – בזמן ובמקום שמתאים לך',
    EnglishText: null,
  },
  {
    PresetStringID: 79,
    CodeKey: 'ChallengeIntroItem2Text',
    DescKey: 'אתגר - עמוד שכנוע - פריט 2 טקסט',
    HebrewText:
      'לכולנו יש את המטרות האישיות שלנו: לשפר את הכושר,  להתחטב, להקל על כאבי הגב או לפרגן לעצמנו פסק זמן - על מנת  ולתמוך בבריאותנו הגופנית והנפשית.',
    EnglishText: null,
  },
  {
    PresetStringID: 80,
    CodeKey: 'ChallengeIntroItem3Title',
    DescKey: 'אתגר - עמוד שכנוע - פריט 3 כותרת',
    HebrewText: 'תפריטים אישיים – לאכול, ליהנות ולהרגיש טוב',
    EnglishText: null,
  },
  {
    PresetStringID: 81,
    CodeKey: 'ChallengeIntroItem3Text',
    DescKey: 'אתגר - עמוד שכנוע - פריט 3 טקסט',
    HebrewText:
      'בין אם התזונה שלך היא ים-תיכונית או פליאו, טבעונית או צמחונית, "חוויית החיטוב ב-90 יום" תבנה עבורך תפריט הכולל מגוון ארוחות בוקר, צוהריים, ערב, וארוחות ביניים.',
    EnglishText: null,
  },
  {
    PresetStringID: 82,
    CodeKey: 'ChallengeIntroItem4Title',
    DescKey: 'אתגר - עמוד שכנוע - פריט 4 כותרת',
    HebrewText: 'מעקב אחר התקדמותך בתוכנית החיטוב',
    EnglishText: null,
  },
  {
    PresetStringID: 83,
    CodeKey: 'ChallengeIntroItem4Text',
    DescKey: 'אתגר - עמוד שכנוע - פריט 4 טקסט',
    HebrewText:
      ' במטרה לראות את קצב ההתקדמות ("לפני ואחרי") שלך וכדי להרגיש את השיפור במראה ולשמוע פידבקים חיוביים מהסביבה, הכנו לך טבלת מדדים ומקום לתמונת גוף. נתונים אלה יש להזין בזמן הקרוב ביותר מתחילת האימונים על מנת שיהיה לך מעקב רציף אחר הביצועים (אופציונלי).',
    EnglishText: null,
  },
  {
    PresetStringID: 84,
    CodeKey: 'ChallengeIntroItem1Text_ReadMore',
    DescKey: 'אתגר - עמוד שכנוע - פריט 1 טקסט - קרא עוד',
    HebrewText:
      'אנו              מעניקים בנוסף תמיכה רגשית המעצימה את הביטחון והמודעות העצמית בשיטת ה-NLP. התוכנית הזאת תשפר ב- 95% את המראה ואיכות החיים שלך. "חוויית החיטוב ב-90 יום" כוללת אימוני כושר המותאמים אישית בצירוף תפריט ארוחות יום-יומי בהתאם להעדפות האישיות שלך שנבנים לאחר שאלון אישי קצר שימולא על ידך. תוכנית האימונים האישית ותפריט הארוחות המותאם להעדפות שלך מתעדכנים ומתחדשים מדי שבוע.',
    EnglishText: null,
  },
  {
    PresetStringID: 85,
    CodeKey: 'ChallengeIntroItem2Text_Item1',
    DescKey: 'אתגר - עמוד שכנוע - פריט 2 טקסט -אייטם 1 ברשימה',
    HebrewText: '"חוויית החיטוב ב-90 יום" מספקת לך את המענה האידיאלי עבורך באמצעות תרגילים, אותם ניתן לעשות בכל מקום ובכל זמן.',
    EnglishText: null,
  },
  {
    PresetStringID: 86,
    CodeKey: 'ChallengeIntroItem2Text_Item2',
    DescKey: 'אתגר - עמוד שכנוע - פריט 2 טקסט -אייטם 2 ברשימה',
    HebrewText: 'לביצוע התרגילים אין צורך בציוד או עם ציוד מינימלי לבחירתך (כדור פיטבול, גומיית התנגדות פתוחה וסגורה, דלגית ומזרן).',
    EnglishText: null,
  },
  {
    PresetStringID: 87,
    CodeKey: 'ChallengeIntroItem2Text_Item3',
    DescKey: 'אתגר - עמוד שכנוע - פריט 2 טקסט -אייטם 3 ברשימה',
    HebrewText: 'האימונים מלווים בסרטוני הדרכה המסבירים כיצד לבצע את התרגילים בצורה הנכונה ביותר.',
    EnglishText: null,
  },
  {
    PresetStringID: 88,
    CodeKey: 'ChallengeIntroItem2Text_Item4',
    DescKey: 'אתגר - עמוד שכנוע - פריט 2 טקסט -אייטם 4 ברשימה',
    HebrewText: 'כל אימון נמשך כ-30 דקות ויש ביכולתך להחליט על סידור האימונים והתזונה שיועברו ליומנך האישי בהתאם לסדר היום שלך.',
    EnglishText: null,
  },
  {
    PresetStringID: 89,
    CodeKey: 'ChallengeIntroItem3Text_Item1',
    DescKey: 'אתגר - עמוד שכנוע - פריט 3 טקסט -אייטם 1 ברשימה',
    HebrewText: 'הארוחות מותאמות למטרות האישיות שלך, למצב אותו הגדרת בשאלון ולהעדפות הקולינריות שלך.',
    EnglishText: null,
  },
  {
    PresetStringID: 90,
    CodeKey: 'ChallengeIntroItem3Text_Item2',
    DescKey: 'אתגר - עמוד שכנוע - פריט 3 טקסט -אייטם 2 ברשימה',
    HebrewText: 'כל תפריט מלווה בהוראות הכנה הכוללות רשימת מצרכים אותם ניתן להוסיף כאופציה לרשימת קניות הסופר באמצעות וואטסאפ או מייל.',
    EnglishText: null,
  },
  {
    PresetStringID: 91,
    CodeKey: 'ChallengeIntroItem3Text_Item3',
    DescKey: 'אתגר - עמוד שכנוע - פריט 3 טקסט -אייטם 3 ברשימה',
    HebrewText: 'כדי ליהנות כל פעם מחדש מהארוחות, יוצע לך תפריט טעים ועדכני מדי שבוע.',
    EnglishText: null,
  },
  {
    PresetStringID: 92,
    CodeKey: 'ChallengeIntroItem4Text_ReadMore',
    DescKey: 'אתגר - עמוד שכנוע - פריט 4 טקסט - קרא עוד',
    HebrewText:
      'הוספנו מחשבונים חכמים שיובילו אותך אל המטרות האידיאליות בצורה המדויקת ביותר עבורך. בונוס: שיפור ניכר במדדים (מעבר מצבע אדום לירוק) ובהסכמתך לפרסם את הנתונים האישיים הללו בפומבי- נעניק לך 50% מתנה לקנייה ממוצרי החנות שלנו.',
    EnglishText: null,
  },
  {
    PresetStringID: 93,
    CodeKey: 'WizardProgram_Item1',
    DescKey: 'מדריך למשתמש - מסך אתגר ראשי - פריט1',
    HebrewText:
      'ברוכים הבאים למסך הראשי של ״חוויית החיטוב ב-90 יום״. כאן ניתן לצפות ולעקוב אחר המדדים שלך, ובנוסף ניתן לנווט למסכים הפנימיים.',
    EnglishText: null,
  },
  {
    PresetStringID: 94,
    CodeKey: 'WizardProgram_Item2',
    DescKey: 'מדריך למשתמש - מסך אתגר ראשי - פריט2',
    HebrewText: 'הפס שמהבהב בחלק העליון של המסך מציג את היום הנוכחי בעיגול צהוב ואת ההתקדמות שלך במהלך הימים ב״חוויית החיטוב ב-90 יום״.',
    EnglishText: null,
  },
  {
    PresetStringID: 95,
    CodeKey: 'WizardProgram_Item3',
    DescKey: 'מדריך למשתמש - מסך אתגר ראשי - פריט3',
    HebrewText:
      'על מנת שיהיה לך מעקב רציף אחר הביצועים, מומלץ מאוד להזין את מדדי הגוף בזמן הקרוב לתחילת האימונים וזאת ע״י לחיצה על הכפתור המהבהב.',
    EnglishText: null,
  },
  {
    PresetStringID: 96,
    CodeKey: 'WizardDailySchedule_Item1',
    DescKey: 'מדריך למשתמש - התוכנית שלי - פריט1',
    HebrewText:
      'במסך ״התוכנית שלי״ מוצגת התוכנית האישית שלך, הכוללת את האימונים והארוחות לכל אחד מ-90 הימים של ״חוויית החיטוב ב-90 יום״. התוכנית הותאמה במיוחד על פי הנתונים האישיים שהזנת.',
    EnglishText: null,
  },
  {
    PresetStringID: 97,
    CodeKey: 'WizardDailySchedule_Item2',
    DescKey: 'מדריך למשתמש - התוכנית שלי - פריט2',
    HebrewText: 'היום הנוכחי הוא זה שמסומן בצבע צהוב.',
    EnglishText: null,
  },
  {
    PresetStringID: 98,
    CodeKey: 'WizardDailySchedule_Item3',
    DescKey: 'מדריך למשתמש - התוכנית שלי - פריט3',
    HebrewText: 'על מנת לבצע החלפה של אימון או ארוחה, יש ללחוץ על האייקון המהבהב ליד הפריט שברצונך להחליף.',
    EnglishText: null,
  },
  {
    PresetStringID: 99,
    CodeKey: 'WizardDailySchedule_Item4',
    DescKey: 'מדריך למשתמש - התוכנית שלי - פריט4',
    HebrewText: 'קיימת אפשרות להוריד את לוח האימונים והארוחות השבועי ליומן האישי שלך בטלפון או במחשב ע״י לחיצה על הכפתור המהבהב.',
    EnglishText: null,
  },
  {
    PresetStringID: 100,
    CodeKey: 'WizardMyWorkouts_Item1',
    DescKey: 'מדריך למשתמש - האימונים שלי - פריט1',
    HebrewText: 'במסך ״האימונים שלי״ ניתן לצפות בסרטונים של מגוון האימונים שהכנו עבורך. יש ללחוץ על אחד האימונים כדי להתחיל לצפות בסרטון.',
    EnglishText: null,
  },
  {
    PresetStringID: 101,
    CodeKey: 'WizardMyWorkouts_Item3',
    DescKey: 'מדריך למשתמש - האימונים שלי - פריט3',
    HebrewText:
      'הכפתורים המהבהבים כעת יאפשרו לך לדפדף בין החודשים השונים של ״חוויית החיטוב ב-90 יום״, כאשר בכל חודש יופיעו אימונים חדשים לגמרי.',
    EnglishText: null,
  },
  {
    PresetStringID: 102,
    CodeKey: 'WizardMyWorkouts_Item4',
    DescKey: 'מדריך למשתמש - האימונים שלי - פריט4',
    HebrewText: 'ישנם אימונים (לבחירתך) המצריכים ציוד בסיסי שמומלץ לרכוש מראש: גומייה סגורה, גומייה פתוחה, דלגית, מזרן, כדור פיטבול.',
    EnglishText: null,
  },
  {
    PresetStringID: 103,
    CodeKey: 'WizardMyPersonalStats_Item1',
    DescKey: 'מדריך למשתמש - הפרופיל שלי - פריט1',
    HebrewText: 'במסך "הפרופיל שלי״ ניתן לצפות בנתונים האישיים שלך, כפי שהזנת במהלך השאלון הראשוני.',
    EnglishText: null,
  },
  {
    PresetStringID: 104,
    CodeKey: 'WizardMyPersonalStats_Item2',
    DescKey: 'מדריך למשתמש - הפרופיל שלי - פריט2',
    HebrewText:
      ' על מנת לעדכן את סוג התזונה המועדפת יש ללחוץ על הכפתור המהבהב. שינוי זה יגרום לבנייה מחדש של כל לוח הארוחות בחוויית החיטוב שלך.',
    EnglishText: null,
  },
  {
    PresetStringID: 105,
    CodeKey: 'WizardMyMeals_Item1',
    DescKey: 'מדריך למשתמש - הארוחות שלי - פריט1',
    HebrewText: 'במסך "הארוחות שלי״ יוצגו מגוון המתכונים השונים שהכנו עבורך, כולם מותאמים לך באופן אישי, בהתאם לסוג התזונה המועדפת שסימנת.',
    EnglishText: null,
  },
  {
    PresetStringID: 106,
    CodeKey: 'WizardMyMeals_Item2',
    DescKey: 'מדריך למשתמש - הארוחות שלי - פריט2',
    HebrewText: 'הארוחות מחולקות לפי זמנים: ארוחת בוקר, ארוחת צוהריים, ארוחת ערב ונשנושים בין הארוחות. בתאבון!',
    EnglishText: null,
  },
  {
    PresetStringID: 107,
    CodeKey: 'WizardMyMealsByDayTime_Item1',
    DescKey: 'מדריך למשתמש - הארוחות שלי לפי קטגוריות - פריט1',
    HebrewText: 'מסך זה מציג את רשימת הארוחות בקטגוריה שבחרת (בוקר, צוהריים, ערב או נשנושים).',
    EnglishText: null,
  },
  {
    PresetStringID: 108,
    CodeKey: 'WizardMyMealsByDayTime_Item2',
    DescKey: 'מדריך למשתמש - הארוחות שלי לפי קטגוריות - פריט2',
    HebrewText:
      'הכפתורים המהבהבים כעת יאפשרו לך לדפדף בין החודשים השונים של ״חוויית החיטוב ב-90 יום״, כאשר בכל חודש יופיעו מתכונים חדשים לגמרי. בתאבון!',
    EnglishText: null,
  },
  {
    PresetStringID: 109,
    CodeKey: 'WizardMealDetails_Item1',
    DescKey: 'מדריך למשתמש - הארוחות שלי מתכון - פריט1',
    HebrewText: 'מסך זה מציג את המתכון המלא של הארוחה, כולל מצרכים והוראות הכנה.',
    EnglishText: null,
  },
  {
    PresetStringID: 110,
    CodeKey: 'WizardMealDetails_Item2',
    DescKey: 'מדריך למשתמש - הארוחות שלי מתכון - פריט2',
    HebrewText: 'לחיצה על הכפתור המהבהב תאפשר לך לשלוח למייל או לוואטסאפ את רשימת הקניות, בהתאם למצרכים שסימנת.',
    EnglishText: null,
  },
  {
    PresetStringID: 111,
    CodeKey: 'WizardMealDetails_Item3',
    DescKey: 'מדריך למשתמש - הארוחות שלי מתכון - פריט3',
    HebrewText: 'לחיצה על הכפתור המהבהב השני תאפשר לך לשתף את כל המתכון ע״י שליחתו למייל או לוואטסאפ.',
    EnglishText: null,
  },
  {
    PresetStringID: 112,
    CodeKey: 'WizardMyWorkouts_Item2',
    DescKey: 'מדריך למשתמש - האימונים שלי - פריט2',
    HebrewText: 'בזמן צפייה בסרטון האימון, ניתן להתחיל לצפות בשלב ההסברים או לדלג ישר לאימון עצמו ע"י לחיצה על הכפתור.',
    EnglishText: null,
  },
  {
    PresetStringID: 113,
    CodeKey: 'WizardDialogTitle',
    DescKey: 'מדריך למשתמש - כותרת',
    HebrewText: 'מדריך למשתמש',
    EnglishText: null,
  },
  {
    PresetStringID: 114,
    CodeKey: 'MotivationCarouselItemTopTitle',
    DescKey: 'שקופית אתגר 2 - כותרת ראשית',
    HebrewText: 'יש מיליון תוכניות חיטוב בשוק. למה דווקא איתנו?',
    EnglishText: null,
  },
  {
    PresetStringID: 115,
    CodeKey: 'AboutUsCarouselItemBottomLink',
    DescKey: 'עמוד ראשי "מי אנחנו" - קישור לאודות',
    HebrewText: 'רוצים להכיר אותנו יותר?',
    EnglishText: null,
  },
  {
    PresetStringID: 116,
    CodeKey: 'OurTrainersCarouselItemTitle2',
    DescKey: 'שקופית מאמנים 2 - כותרת ראשית שניה',
    HebrewText: 'למה דווקא המאמנים שלנו?',
    EnglishText: null,
  },
  {
    PresetStringID: 117,
    CodeKey: 'OurTrainersCarouselItemButton',
    DescKey: 'שקופית מאמנים 2 - כפתור ראשי',
    HebrewText: 'להזמנה',
    EnglishText: null,
  },
  {
    PresetStringID: 118,
    CodeKey: 'ChallengeCarouselItemVideoOverlayText',
    DescKey: 'טקסט כותרת על סרטון יוטיוב ראשי',
    HebrewText: 'קחו את המאמן האישי להשגת המטרות יחד איתכם',
    EnglishText: null,
  },
  {
    PresetStringID: 119,
    CodeKey: 'ChallengeCarouselItemVideoOverlayButton',
    DescKey: 'טקסט כפתור על סרטון יוטיוב ראשי',
    HebrewText: 'הצטרפו לשבוע חינם אונליין',
    EnglishText: null,
  },
  {
    PresetStringID: 120,
    CodeKey: 'PersonalStatsWindowStartTitle',
    DescKey: 'הזנת נתונים אישיים - כותרת',
    HebrewText: 'בניית תוכנית מותאמת אישית',
    EnglishText: null,
  },
  {
    PresetStringID: 121,
    CodeKey: 'PersonalStatsWindowStartText',
    DescKey: 'הזנת נתונים אישיים - טקסט',
    HebrewText:
      'על מנת להפיק את התוצאה המקסימלית מתוכנית האימונים והתזונה, יש לענות על שאלון קצר באופן מדויק. כל זאת בכדי:\n\n* להשיג את התוצאה הטובה ביותר עבורך\n* ליהנות מגוף בריא וחטוב\n* להרגיש טוב יותר בשגרה היומיומית\n* לחסן ולחזק את הגוף לשיפור אורח החיים\n* להעלות את הביטחון והמודעות העצמית\n\nובכך נבנה עבורך את חוויית החיטוב הטובה ביותר שתתאים במיוחד בשבילך.\nבהמשך יש להזין את מדדי הגוף שישמשו אותך למעקב אישי "לפני ואחרי".\nוכל זה בהתבסס על הידע, הניסיון והמקצועיות שלנו עם המומחים בתחום.',
    EnglishText: null,
  },
  {
    PresetStringID: 123,
    CodeKey: 'PersonalStatsWindow45Title',
    DescKey: 'הזנת נתונים אישיים יום 45 - כותרת',
    HebrewText: 'נתונים אישיים לאחר 45 ימים',
    EnglishText: null,
  },
  {
    PresetStringID: 124,
    CodeKey: 'PersonalStatsWindow45Text',
    DescKey: 'הזנת נתונים אישיים יום 45 - טקסט',
    HebrewText:
      'לאחר שהסתיימו 45 ימים מתוך חוויית החיטוב, מומלץ להזין את המדדים המעודכנים, בהתאם להתקדמות האישית שלך בתקופה זו.\nעל מנת להתקדם לתקופה הבאה באתגר, מומלץ למלא כעת את הנתונים.',
    EnglishText: null,
  },
  {
    PresetStringID: 125,
    CodeKey: 'PersonalStatsWindow75Title',
    DescKey: 'הזנת נתונים אישיים יום 75 - כותרת',
    HebrewText: 'נתונים אישיים לאחר 75 ימים',
    EnglishText: null,
  },
  {
    PresetStringID: 126,
    CodeKey: 'PersonalStatsWindow75Text',
    DescKey: 'הזנת נתונים אישיים יום 75 - טקסט',
    HebrewText:
      'לאחר שהסתיימו 75 ימים מתוך חוויית החיטוב, מומלץ להזין את המדדים המעודכנים, בהתאם להתקדמות האישית שלך בתקופה זו.\nעל מנת להתקדם לתקופה הבאה באתגר, מומלץ למלא כעת את הנתונים.',
    EnglishText: null,
  },
  {
    PresetStringID: 127,
    CodeKey: 'PersonalStatsWindow90Title',
    DescKey: 'הזנת נתונים אישיים יום 90 - כותרת',
    HebrewText: 'נתונים אישיים לאחר 90 ימים',
    EnglishText: null,
  },
  {
    PresetStringID: 128,
    CodeKey: 'PersonalStatsWindow90Text',
    DescKey: 'הזנת נתונים אישיים יום 90 - טקסט',
    HebrewText:
      'עברת בהצלחה תהליך של 90 יום מאתגרים במיוחד!\nכדי להמשיך להניב תוצאות ולשמור על אורח חיים בריא רצוי לשמור על כושר ותזונה נכונה באחת הדרכים הבאות:',
    EnglishText: null,
  },
  {
    PresetStringID: 129,
    CodeKey: '90DaysChallengeTitle',
    DescKey: 'כותרת התוכנית',
    HebrewText: 'חוויית החיטוב ב-90 יום',
    EnglishText: null,
  },
  {
    PresetStringID: 130,
    CodeKey: 'ChallengeCarouselItemTopTextRightItem1',
    DescKey: 'שקופית אתגר עליונה - צד ימין - אייטם 1',
    HebrewText: 'כושר גופני',
    EnglishText: null,
  },
  {
    PresetStringID: 131,
    CodeKey: 'ChallengeCarouselItemTopTextRightItem2',
    DescKey: 'שקופית אתגר עליונה - צד ימין - אייטם 2',
    HebrewText: 'תזונה בריאה',
    EnglishText: null,
  },
  {
    PresetStringID: 132,
    CodeKey: 'ChallengeCarouselItemTopTextRightItem3',
    DescKey: 'שקופית אתגר עליונה - צד ימין - אייטם 3',
    HebrewText: 'נפש רגועה',
    EnglishText: null,
  },
  {
    PresetStringID: 133,
    CodeKey: 'ChallengeCarouselItemTopTextLeft',
    DescKey: 'שקופית אתגר עליונה - צד שמאל- טקסט',
    HebrewText:
      '"חוויית החיטוב ב-90 יום" מתאימה לך תוכנית בריאותית שבסופה יורגש השינוי. ומאפשרת לך בכל שלב להתייעץ איתנו בכל נושא ובכל זמן בחינם. התוכנית פותחה על ידי אנשי מקצוע מובילים מתחום:\n',
    EnglishText: null,
  },
  {
    PresetStringID: 134,
    CodeKey: 'ChallengeCarouselItemTopTextLeftItem1',
    DescKey: 'שקופית אתגר עליונה - צד שמאל- אייטם 1',
    HebrewText: 'הכושר והספורט',
    EnglishText: null,
  },
  {
    PresetStringID: 135,
    CodeKey: 'ChallengeCarouselItemTopTextLeftItem2',
    DescKey: 'שקופית אתגר עליונה - צד שמאל- אייטם 2',
    HebrewText: 'הנטורופתיה לתזונה נכונה',
    EnglishText: null,
  },
  {
    PresetStringID: 136,
    CodeKey: 'ChallengeCarouselItemTopTextLeftItem3',
    DescKey: 'שקופית אתגר עליונה - צד שמאל- אייטם 3',
    HebrewText: 'וה-NLP להגעה מהירה אל המטרות',
    EnglishText: null,
  },
  {
    PresetStringID: 137,
    CodeKey: 'ChallengeCarouselItemTopTextLeftBottom',
    DescKey: 'שקופית אתגר עליונה - צד שמאל- טקסט למטה',
    HebrewText: ' ',
    EnglishText: null,
  },
  {
    PresetStringID: 138,
    CodeKey: 'ChallengePack1Title',
    DescKey: 'חבילת basic כותרת',
    HebrewText: 'Basic',
    EnglishText: null,
  },
  {
    PresetStringID: 139,
    CodeKey: 'ChallengePack1Body',
    DescKey: 'חבילת basic תוכן',
    HebrewText: '7 ימים חינם להיכרות\nתזונה בהתאמה אישית\nתוכנית אימונים בהתאמה אישית',
    EnglishText: null,
  },
  {
    PresetStringID: 140,
    CodeKey: 'ChallengePack2Title',
    DescKey: 'חבילת premium כותרת',
    HebrewText: 'Premium',
    EnglishText: null,
  },
  {
    PresetStringID: 141,
    CodeKey: 'ChallengePack2Body',
    DescKey: 'חבילת premium תוכן',
    HebrewText:
      '7 ימים חינם להיכרות\nתזונה בהתאמה אישית\nתוכנית אימונים בהתאמה אישית\nערכת אימון: בקבוק מים + מגבת\nטיפים מקצועיים מהמומחים שלנו\nליווי מנטלי עם קואוצ׳ר',
    EnglishText: null,
  },
  {
    PresetStringID: 142,
    CodeKey: 'ChallengePack3Title',
    DescKey: 'חבילת vip כותרת',
    HebrewText: 'VIP',
    EnglishText: null,
  },
  {
    PresetStringID: 143,
    CodeKey: 'ChallengePack3Body',
    DescKey: 'חבילת vip תוכן',
    HebrewText:
      '7 ימים חינם להיכרות\nתזונה בהתאמה אישית\nתוכנית אימונים בהתאמה אישית\nערכת אימון: בקבוק מים + מגבת\nטיפים מקצועיים מהמומחים שלנו\nליווי מנטלי עם קואוצ׳ר\nייעוץ ובניית תפריט תזונתי ממוקד לפי בדיקות דם\nפורמולה מותאמת אישית לאיזון וחיזוק הגוף\nציוד כושר (מזרן, פיטבול, דלגית, גומייה פתוחה, גומייה סגורה)',
    EnglishText: null,
  },
  {
    PresetStringID: 144,
    CodeKey: 'ChallengeTipsDay1',
    DescKey: 'טיפ מתחלף באתגר יום 1',
    HebrewText: 'מומלץ לשתות בין 2 ל-3 ליטר מים ביום כדי לזרז את חילוף החומרים ואת תהליך ההרזיה ובנוסף לשמור על עור וגוף צעיר יותר.',
    EnglishText: null,
  },
  {
    PresetStringID: 145,
    CodeKey: 'ChallengeTipsDay2',
    DescKey: 'טיפ מתחלף באתגר יום 2',
    HebrewText:
      'הימנעו מכל אוכל מעובד שעבר דרך התעשייה והמפעלים שגורם להשמנה ובעיות בריאותיות וצרכו מאכלים טבעיים ללא תוספות מיותרות כמו סוכרים, שומנים מעובדים, חומרים משמרים, צבעי מאכל וריסוסים.',
    EnglishText: null,
  },
  {
    PresetStringID: 146,
    CodeKey: 'ChallengeTipsDay3',
    DescKey: 'טיפ מתחלף באתגר יום 3',
    HebrewText: 'לביצוע האימונים באופן קבוע ומיטבי- נסו להתאמן בשעה ובימים קבועים והזינו זאת ביומן עם התראה של שעה או שעתיים קודם',
    EnglishText: null,
  },
  {
    PresetStringID: 147,
    CodeKey: 'ChallengeTipsDay4',
    DescKey: 'טיפ מתחלף באתגר יום 4',
    HebrewText: 'הבריאות שלנו היא בסדר העדיפות הראשון שלנו, לפני עבודה, אנשים אחרים, כספים ודברים שונים אחרים.',
    EnglishText: null,
  },
  {
    PresetStringID: 148,
    CodeKey: 'ChallengeTipsDay5',
    DescKey: 'טיפ מתחלף באתגר יום 5',
    HebrewText:
      'החליפו כל מילה או משפט שלילי לחיובי (רצוי להוריד לחלוטין את המילים: "לא"\\"אבל"\\"למה")- יקנה לכם הרגשה טובה, רגועה יותר והגעה מהירה אל המטרות שלכם',
    EnglishText: null,
  },
  {
    PresetStringID: 149,
    CodeKey: 'ChallengeTipsDay6',
    DescKey: 'טיפ מתחלף באתגר יום 6',
    HebrewText: 'הכניסו את עצמכם לאווירת האימונים קודם כל ע"י לבישת בגדי ונעלי ספורט והתכוננו לאימון חווייתי ',
    EnglishText: null,
  },
  {
    PresetStringID: 150,
    CodeKey: 'ChallengeTipsDay7',
    DescKey: 'טיפ מתחלף באתגר יום 7',
    HebrewText: 'לאהוב את עצמנו זה הדבר הכי בסיס להצלחה בחיים ולאושר אינסופי- הרימו לעצמכם כל יום!',
    EnglishText: null,
  },
  {
    PresetStringID: 151,
    CodeKey: 'ChallengeTipsDay8',
    DescKey: 'טיפ מתחלף באתגר יום 8',
    HebrewText: 'כדאי מאוד לשתף את הסובבים אתכם על כל שינוי שאתם עוברים לטובה ולחגוג כל תוצאה הכי קטנה שאתם משיגים.',
    EnglishText: null,
  },
  {
    PresetStringID: 152,
    CodeKey: 'ChallengeTipsDay9',
    DescKey: 'טיפ מתחלף באתגר יום 9',
    HebrewText: 'הקיפו את עצמכם רק באנשים תומכים ומפרגנים.',
    EnglishText: null,
  },
  {
    PresetStringID: 153,
    CodeKey: 'ChallengeTipsDay10',
    DescKey: 'טיפ מתחלף באתגר יום 10',
    HebrewText:
      'רשמו על דף או במסך הטלפון שלכם את היעד הגופני שאליו אתם רוצים להגיע כולל משקל מדויק ככה שתהיו ממוקדי מטרה ושום דבר לא יסיט אתכם ממנה.',
    EnglishText: null,
  },
  {
    PresetStringID: 154,
    CodeKey: 'ChallengeTipsDay11',
    DescKey: 'טיפ מתחלף באתגר יום 11',
    HebrewText: 'הגיעו עד קצה היכולת שלכם - רק כך תגיעו לאושר אינסופי (הורמונים טובים משתחררים לאחר מאמץ מירבי)',
    EnglishText: null,
  },
  {
    PresetStringID: 155,
    CodeKey: 'ChallengeTipsDay12',
    DescKey: 'טיפ מתחלף באתגר יום 12',
    HebrewText: "מומלץ לשנות מיקומים שונים בכל אימון: בית, פארק, חוף ים, מקום עבודה וכו'.. על מנת לשנות אווירה מאימון לאימון.",
    EnglishText: null,
  },
  {
    PresetStringID: 156,
    CodeKey: 'ChallengeTipsDay13',
    DescKey: 'טיפ מתחלף באתגר יום 13',
    HebrewText: 'המליצו לחבר/בן/בת זוג/משפחה להצטרף אליכם לאימון היומי שלכם על מנת לדרבן את עצמכם יותר.',
    EnglishText: null,
  },
  {
    PresetStringID: 157,
    CodeKey: 'ChallengeTipsDay14',
    DescKey: 'טיפ מתחלף באתגר יום 14',
    HebrewText: 'שעתיים לפני אימון שתה מספיק מים, אכול פחמימות מזינות ושעה לפני האימון - פרי לאנרגיה מירבית',
    EnglishText: null,
  },
  {
    PresetStringID: 158,
    CodeKey: 'ChallengeTipsDay15',
    DescKey: 'טיפ מתחלף באתגר יום 15',
    HebrewText: 'צאו מאזור הנוחות- עשו דברים שנראים לכם קשים להשגה- צעד אחר צעד- מתחילים מקטן ומצליחים בגדול',
    EnglishText: null,
  },
  {
    PresetStringID: 159,
    CodeKey: 'ChallengeTipsDay16',
    DescKey: 'טיפ מתחלף באתגר יום 16',
    HebrewText: 'שתפו את התוצאות שלכם במדיה החברתית ככה שכל הסובבים אתכם יחגגו את הניצחון שלכם יחד אתכם.',
    EnglishText: null,
  },
  {
    PresetStringID: 160,
    CodeKey: 'ChallengeTipsDay17',
    DescKey: 'טיפ מתחלף באתגר יום 17',
    HebrewText: '"עבדו ותהיו חרוצים כמו הנמלה ותחיו כמו אריה".',
    EnglishText: null,
  },
  {
    PresetStringID: 161,
    CodeKey: 'ChallengeTipsDay18',
    DescKey: 'טיפ מתחלף באתגר יום 18',
    HebrewText: 'הסתכלו כל יום על עצמכם במראה ותגידו לעצמכם כמה שאתם יפים, מוצלחים וטובים.',
    EnglishText: null,
  },
  {
    PresetStringID: 162,
    CodeKey: 'ChallengeTipsDay19',
    DescKey: 'טיפ מתחלף באתגר יום 19',
    HebrewText:
      'השתמשו בציוד, מכשור, משקלים, תרגילים, מספר סטים וחזרות שונים על מנת לתת לגופכם גירויים חדשים בשביל להמשיך להגיע למטרה בקלות, יעילות ובמהירות.',
    EnglishText: null,
  },
  {
    PresetStringID: 163,
    CodeKey: 'ChallengeTipsDay20',
    DescKey: 'טיפ מתחלף באתגר יום 20',
    HebrewText: 'לא לפחד להיכשל, "הכישלון" זה סה"כ עוד מדרגה שעוברים בדרך להצלחה.',
    EnglishText: null,
  },
  {
    PresetStringID: 164,
    CodeKey: 'ChallengeTipsDay21',
    DescKey: 'טיפ מתחלף באתגר יום 21',
    HebrewText: 'כתבו מטרות ל-5 השנים הקרובות ותעדפו אותם לפי סדר ובהתאם תתחילו ליישמם.',
    EnglishText: null,
  },
  {
    PresetStringID: 165,
    CodeKey: 'ChallengeTipsDay22',
    DescKey: 'טיפ מתחלף באתגר יום 22',
    HebrewText: 'תחשבו על המטרה שלכם ותיהנו מהדרך שאתם עוברים כדי להגיע אליה והסיפוק שלכם יהיה גדול יותר.',
    EnglishText: null,
  },
  {
    PresetStringID: 166,
    CodeKey: 'ChallengeTipsDay23',
    DescKey: 'טיפ מתחלף באתגר יום 23',
    HebrewText: 'שימו מוזיקה מקפיצה באימוני הכושר שימריצו לכם את הגוף וירגשו לכם את הנפש לכיף מקסימלי',
    EnglishText: null,
  },
  {
    PresetStringID: 167,
    CodeKey: 'ChallengeTipsDay24',
    DescKey: 'טיפ מתחלף באתגר יום 24',
    HebrewText: 'תתמקדו במטרה שלכם ותנסו כל דרך אפשרית כדי להגיע אליה ואם לא השגתם את המטרה שלכם תשנו את הדרך ולא את המטרה.',
    EnglishText: null,
  },
  {
    PresetStringID: 168,
    CodeKey: 'ChallengeTipsDay25',
    DescKey: 'טיפ מתחלף באתגר יום 25',
    HebrewText: 'תהפכו לגרסה הטובה של עצמכם מבלי להשוות את עצמכם לאחרים ותהיו מאושרים יותר.',
    EnglishText: null,
  },
  {
    PresetStringID: 169,
    CodeKey: 'ChallengeTipsDay26',
    DescKey: 'טיפ מתחלף באתגר יום 26',
    HebrewText: 'חשים תחושת עצלנות ועייפות מחשבתית- זה הזמן להתאמן בכל הכוח- כנגד כל הסיכויים להתרוממות הרוח והנפש',
    EnglishText: null,
  },
  {
    PresetStringID: 170,
    CodeKey: 'ChallengeTipsDay27',
    DescKey: 'טיפ מתחלף באתגר יום 27',
    HebrewText: 'יש להימנע מסוכרים תעשייתיים ומלאכותיים.',
    EnglishText: null,
  },
  {
    PresetStringID: 171,
    CodeKey: 'ChallengeTipsDay28',
    DescKey: 'טיפ מתחלף באתגר יום 28',
    HebrewText: 'סדר את הבית, ארון הבגדים והחיים שלך הסתדרו',
    EnglishText: null,
  },
  {
    PresetStringID: 172,
    CodeKey: 'ChallengeTipsDay29',
    DescKey: 'טיפ מתחלף באתגר יום 29',
    HebrewText: 'חייך גם אם קשה',
    EnglishText: null,
  },
  {
    PresetStringID: 173,
    CodeKey: 'ChallengeTipsDay30',
    DescKey: 'טיפ מתחלף באתגר יום 30',
    HebrewText: 'עסה את עצמך לפחות פעם בחודש',
    EnglishText: null,
  },
  {
    PresetStringID: 174,
    CodeKey: 'ChallengeTipsDay31',
    DescKey: 'טיפ מתחלף באתגר יום 31',
    HebrewText:
      'צא אל הטבע והתחבר אל כל הטוב שקיבלנו מתנה- ככל שאנו מתרחקים מהטבע כך אנו נעשים יותר חולים. ככל שמתקרבים לטבע- יותר חסונים.',
    EnglishText: null,
  },
  {
    PresetStringID: 175,
    CodeKey: 'ChallengeTipsDay32',
    DescKey: 'טיפ מתחלף באתגר יום 32',
    HebrewText: 'בצע הליכה\\ריצה\\שחייה\\אופניים כל יום חצי שעה לפחות לזרימת חמצן ודם טובה יותר לרקמות',
    EnglishText: null,
  },
  {
    PresetStringID: 176,
    CodeKey: 'ChallengeTipsDay33',
    DescKey: 'טיפ מתחלף באתגר יום 33',
    HebrewText: 'בצע כל יום מתיחות\\יוגה מתיחות ',
    EnglishText: null,
  },
  {
    PresetStringID: 177,
    CodeKey: 'ChallengeTipsDay34',
    DescKey: 'טיפ מתחלף באתגר יום 34',
    HebrewText: 'התחבר לעצמך בעזרת מדיטציות וזמן פנוי לעצמך לתובנות ',
    EnglishText: null,
  },
  {
    PresetStringID: 178,
    CodeKey: 'ChallengeTipsDay35',
    DescKey: 'טיפ מתחלף באתגר יום 35',
    HebrewText: 'החלף חומרים תעשייתיים (תרופות\\אוכל\\חומרי ניקוי\\כלים) בדברים טבעיים ',
    EnglishText: null,
  },
  {
    PresetStringID: 179,
    CodeKey: 'ChallengeTipsDay36',
    DescKey: 'טיפ מתחלף באתגר יום 36',
    HebrewText: 'עשה בדיקות דם פעם בשנה לראות את מצבך ותקן אם יש צורך בצמחים ותוספים טבעיים',
    EnglishText: null,
  },
  {
    PresetStringID: 180,
    CodeKey: 'ChallengeTipsDay37',
    DescKey: 'טיפ מתחלף באתגר יום 37',
    HebrewText: 'מול מחשב- שב ארגונומי למניעת בעיות אורתופדיות. קום כל חצי שעה מהמחשב ועשה מתיחות',
    EnglishText: null,
  },
  {
    PresetStringID: 181,
    CodeKey: 'ChallengeTipsDay38',
    DescKey: 'טיפ מתחלף באתגר יום 38',
    HebrewText: 'מחל לכל הסובבים אותך (לא חובה לסלוח- כן למחול) בלי טינה ורגשות אשם',
    EnglishText: null,
  },
  {
    PresetStringID: 182,
    CodeKey: 'ChallengeTipsDay39',
    DescKey: 'טיפ מתחלף באתגר יום 39',
    HebrewText: 'השתמש בכלים רב פעמיים ולא חד פעמיים לשמירה על עצמנו ועל הסביבה- הכול חוזר אלינו',
    EnglishText: null,
  },
  {
    PresetStringID: 183,
    CodeKey: 'ChallengeTipsDay40',
    DescKey: 'טיפ מתחלף באתגר יום 40',
    HebrewText: 'למד דברים חדשים לגמרי, בעיקר שפות, מוזיקה ודברים שיכולים לעניין לשיפור הזיכרון וחיזוק המוח',
    EnglishText: null,
  },
  {
    PresetStringID: 184,
    CodeKey: 'ChallengeTipsDay41',
    DescKey: 'טיפ מתחלף באתגר יום 41',
    HebrewText: 'נשימות עמוקות מדי פעם אל הבטן כך שהיא עולה ויורדת (לא אל חזה)- נוגד חמצון להצערת הגוף והרגעת הנפש.',
    EnglishText: null,
  },
  {
    PresetStringID: 185,
    CodeKey: 'ChallengeTipsDay42',
    DescKey: 'טיפ מתחלף באתגר יום 42',
    HebrewText: 'לשינה טובה יותר - כשיש הרבה מטלות- הורד אותם על דף- מוריד מעומס הראש',
    EnglishText: null,
  },
  {
    PresetStringID: 186,
    CodeKey: 'ChallengeTipsDay43',
    DescKey: 'טיפ מתחלף באתגר יום 43',
    HebrewText: 'יש לישון כ-7 שעות רצוף בלילה (9 אידיאלי לפעילות מלאה כל היום).',
    EnglishText: null,
  },
  {
    PresetStringID: 187,
    CodeKey: 'ChallengeTipsDay44',
    DescKey: 'טיפ מתחלף באתגר יום 44',
    HebrewText: 'צבור לך חברים שימנפו אותך ויתמכו בך. ולא כאלו שמורידים ומנחיתים כל דעה ומעשה שלך.',
    EnglishText: null,
  },
  {
    PresetStringID: 188,
    CodeKey: 'ChallengeTipsDay45',
    DescKey: 'טיפ מתחלף באתגר יום 45',
    HebrewText: 'הירשם לקורסים מעשירים מדי כמה חודשים לפיתוח היכולת השכלית ומניעת שכחה',
    EnglishText: null,
  },
  {
    PresetStringID: 189,
    CodeKey: 'ChallengeTipsDay46',
    DescKey: 'טיפ מתחלף באתגר יום 46',
    HebrewText:
      'למניעת חרדות ודיכאון צפו בפחות טלוויזיה ובעיקר בחדשות, פוליטיקה ודברים שליליים שחוזרים על עצמם ועושים לנו שטיפת מוח שלילית ',
    EnglishText: null,
  },
  {
    PresetStringID: 190,
    CodeKey: 'ChallengeTipsDay47',
    DescKey: 'טיפ מתחלף באתגר יום 47',
    HebrewText: 'כשאתה חיובי ופתוח- יגיעו המון הצעות מדהימות שתוכל לקבלן ולפעול להתקדמותך.',
    EnglishText: null,
  },
  {
    PresetStringID: 191,
    CodeKey: 'ChallengeTipsDay48',
    DescKey: 'טיפ מתחלף באתגר יום 48',
    HebrewText: 'אל תייעצו לאחרים אם הם לא ביקשו ולהפך- אל תקבלו עצות מאחרים אם לא ביקשתם.',
    EnglishText: null,
  },
  {
    PresetStringID: 192,
    CodeKey: 'ChallengeTipsDay49',
    DescKey: 'טיפ מתחלף באתגר יום 49',
    HebrewText: 'הבליגו על זוטות והתמקדו בעיקר- יש שם אהבה או אין שם אהבה?!',
    EnglishText: null,
  },
  {
    PresetStringID: 193,
    CodeKey: 'ChallengeTipsDay50',
    DescKey: 'טיפ מתחלף באתגר יום 50',
    HebrewText: 'קחו סיכונים מחושבים ורק כך תתקדמו אל ההצלחה האינסופית',
    EnglishText: null,
  },
  {
    PresetStringID: 194,
    CodeKey: 'ChallengeTipsDay51',
    DescKey: 'טיפ מתחלף באתגר יום 51',
    HebrewText: 'כחטיף איכלו תפוח, פירות מתוקים, אגוזים, שקדים ותהנו מזה כל החיים- הגוף יודה לכם.',
    EnglishText: null,
  },
  {
    PresetStringID: 195,
    CodeKey: 'ChallengeTipsDay52',
    DescKey: 'טיפ מתחלף באתגר יום 52',
    HebrewText: 'אהיה זקוף, חזה פתוח, ונשום לרווחה כאילו אתה המלך- כל העת- לחיזוק הנפש',
    EnglishText: null,
  },
  {
    PresetStringID: 196,
    CodeKey: 'ChallengeTipsDay53',
    DescKey: 'טיפ מתחלף באתגר יום 53',
    HebrewText: 'עשה רק טוב לאחרים והטוב הזה יחזור אליך כפליים. התנדבות היא המפתח לאושר.',
    EnglishText: null,
  },
  {
    PresetStringID: 197,
    CodeKey: 'ChallengeTipsDay54',
    DescKey: 'טיפ מתחלף באתגר יום 54',
    HebrewText: 'חצי שעה לפני כל ארוחה שתו 2 כוסות מים.',
    EnglishText: null,
  },
  {
    PresetStringID: 198,
    CodeKey: 'ChallengeTipsDay55',
    DescKey: 'טיפ מתחלף באתגר יום 55',
    HebrewText: 'ריקדו בלי היכר- פרקו והעיפו את כל השלילי מהגוף החוצה',
    EnglishText: null,
  },
  {
    PresetStringID: 199,
    CodeKey: 'ChallengeTipsDay56',
    DescKey: 'טיפ מתחלף באתגר יום 56',
    HebrewText: 'משעה 19:00 איכלו רק פירות או הפסיקו לאכול לגמרי- לשינה רגועה וטובה יותר',
    EnglishText: null,
  },
  {
    PresetStringID: 200,
    CodeKey: 'ChallengeTipsDay57',
    DescKey: 'טיפ מתחלף באתגר יום 57',
    HebrewText: 'קפאין זה רעל- אם חייבים אז רק עד הצוהריים אחרי ארוחה ולא על בטן ריקה',
    EnglishText: null,
  },
  {
    PresetStringID: 201,
    CodeKey: 'ChallengeTipsDay58',
    DescKey: 'טיפ מתחלף באתגר יום 58',
    HebrewText: 'כבו אורות ממכשירים חשמליים בשעות הלילה או שימו אורות לשינה- להפרשת מלטונין ושינה רגועה.',
    EnglishText: null,
  },
  {
    PresetStringID: 202,
    CodeKey: 'ChallengeTipsDay59',
    DescKey: 'טיפ מתחלף באתגר יום 59',
    HebrewText: 'דברים מטרידים פתרו באותו הרגע מבלי לדחות.',
    EnglishText: null,
  },
  {
    PresetStringID: 203,
    CodeKey: 'ChallengeTipsDay60',
    DescKey: 'טיפ מתחלף באתגר יום 60',
    HebrewText: 'לכו אל הסופר כשאתם שבעים וקנו 80% פירות ירקות אגוזים שקדים. 10% קטניות. 5% דגנים מלאים. 5% כל השאר.',
    EnglishText: null,
  },
  {
    PresetStringID: 204,
    CodeKey: 'ChallengeTipsDay61',
    DescKey: 'טיפ מתחלף באתגר יום 61',
    HebrewText: 'שתו שייקים של פירות ועלים ירוקים או מיץ ירקות (לא פירות) לפיצוץ של חיים בריאים יותר וניקוי הגוף.',
    EnglishText: null,
  },
  {
    PresetStringID: 205,
    CodeKey: 'ChallengeTipsDay62',
    DescKey: 'טיפ מתחלף באתגר יום 62',
    HebrewText: 'חקרו ובדקו בעצמם כל דבר מעניין מבלי לקבל כל מידע באופן פסיבי ופעלו בהתאם.',
    EnglishText: null,
  },
  {
    PresetStringID: 206,
    CodeKey: 'ChallengeTipsDay63',
    DescKey: 'טיפ מתחלף באתגר יום 63',
    HebrewText: 'לך עם קו המחשבה החיובי שלך עד הסוף - גם אם היא סותרת רבים אחרים ואל תוותר- תגיע רחוק.',
    EnglishText: null,
  },
  {
    PresetStringID: 207,
    CodeKey: 'ChallengeTipsDay64',
    DescKey: 'טיפ מתחלף באתגר יום 64',
    HebrewText: 'הקשב לרצונות הגוף- הוא יודע מה הוא צריך כדי להתחזק. למשל אם הראש כואב- אכול שתה ותפוס תנומה קלה במקום לקחת כדורים מזיקים.',
    EnglishText: null,
  },
  {
    PresetStringID: 208,
    CodeKey: 'ChallengeTipsDay65',
    DescKey: 'טיפ מתחלף באתגר יום 65',
    HebrewText: 'מוצרי חלב מיותרים לבריאות הגוף- יוצרים ליחה, אסתמה, ובעיות נשימה- מעין מוגלה לגוף.',
    EnglishText: null,
  },
  {
    PresetStringID: 209,
    CodeKey: 'ChallengeTipsDay66',
    DescKey: 'טיפ מתחלף באתגר יום 66',
    HebrewText: 'עשה משהו תרבותי לעצמך פעם בחודש לפחות- חופש',
    EnglishText: null,
  },
  {
    PresetStringID: 210,
    CodeKey: 'ChallengeTipsDay67',
    DescKey: 'טיפ מתחלף באתגר יום 67',
    HebrewText: 'שמע מוזיקה נעימה כל יום. מצאו שקלסיקה מועילה למוח ולזיכרון. ושירו ביחד- מאזן את הגוף.',
    EnglishText: null,
  },
  {
    PresetStringID: 211,
    CodeKey: 'ChallengeTipsDay68',
    DescKey: 'טיפ מתחלף באתגר יום 68',
    HebrewText: 'פרגן כל יום לאדם אחר בסביבתך',
    EnglishText: null,
  },
  {
    PresetStringID: 212,
    CodeKey: 'ChallengeTipsDay69',
    DescKey: 'טיפ מתחלף באתגר יום 69',
    HebrewText: 'התייעץ בדברים מהותיים עם אנשי מקצוע',
    EnglishText: null,
  },
  {
    PresetStringID: 213,
    CodeKey: 'ChallengeTipsDay70',
    DescKey: 'טיפ מתחלף באתגר יום 70',
    HebrewText: 'הפחת באכילת בעלי חיים ותוצריהם.',
    EnglishText: null,
  },
  {
    PresetStringID: 214,
    CodeKey: 'ChallengeTipsDay71',
    DescKey: 'טיפ מתחלף באתגר יום 71',
    HebrewText: 'הגד תודה על 10 דברים שקורים לך בחיי היום יום',
    EnglishText: null,
  },
  {
    PresetStringID: 215,
    CodeKey: 'ChallengeTipsDay72',
    DescKey: 'טיפ מתחלף באתגר יום 72',
    HebrewText: 'בבית- נעל רק נעלי בית אורתופדיים ומנע בעיות ברכיים, רגליים, גב וצוואר',
    EnglishText: null,
  },
  {
    PresetStringID: 216,
    CodeKey: 'ChallengeTipsDay73',
    DescKey: 'טיפ מתחלף באתגר יום 73',
    HebrewText: 'אהיה גמיש מחשבתית- זה יוביל אותך לשיא ההצלחה בחיים, בזוגיות, בעבודה ובכל מקום שאליו תגיע',
    EnglishText: null,
  },
  {
    PresetStringID: 217,
    CodeKey: 'ChallengeTipsDay74',
    DescKey: 'טיפ מתחלף באתגר יום 74',
    HebrewText: 'כשכואב- טפל בכאב ואל תלך נגד הכאב. הגוף מאותת לך להוריד עומס',
    EnglishText: null,
  },
  {
    PresetStringID: 218,
    CodeKey: 'ChallengeTipsDay75',
    DescKey: 'טיפ מתחלף באתגר יום 75',
    HebrewText: 'תחליטו שאתם רוצים לעשות שינוי ועשו אותו עכשיו ואל תתנו לעצמכם "להתבשל" על זה כי אחרת זה יתפספס',
    EnglishText: null,
  },
  {
    PresetStringID: 219,
    CodeKey: 'ChallengeTipsDay76',
    DescKey: 'טיפ מתחלף באתגר יום 76',
    HebrewText: 'הימנעו משימוש רב בנייד שלכם על מנת לשמור על יציבה תקינה ובריאה יותר',
    EnglishText: null,
  },
  {
    PresetStringID: 220,
    CodeKey: 'ChallengeTipsDay77',
    DescKey: 'טיפ מתחלף באתגר יום 77',
    HebrewText: 'הקפידו על ארוחות קטנות ומסודרות כל 3 שעות',
    EnglishText: null,
  },
  {
    PresetStringID: 221,
    CodeKey: 'ChallengeTipsDay78',
    DescKey: 'טיפ מתחלף באתגר יום 78',
    HebrewText: 'הימנעו משתיית אלכוהול אשר מכילים כמות גדולה של סוכרים',
    EnglishText: null,
  },
  {
    PresetStringID: 222,
    CodeKey: 'ChallengeTipsDay79',
    DescKey: 'טיפ מתחלף באתגר יום 79',
    HebrewText: 'הקפידו לבצע בדיקות דם שגרתיות מידי שנה על מנת לשמור על איזון הגוף',
    EnglishText: null,
  },
  {
    PresetStringID: 223,
    CodeKey: 'ChallengeTipsDay80',
    DescKey: 'טיפ מתחלף באתגר יום 80',
    HebrewText:
      "כדאי לנסות לשלב כל דבר אשר נחשב לפעילות ספורטיבית בחיי היום יום שלכם בנוסף לתוכנית האימונים, כמו: עליית מדרגות, הליכה רגלית למקומות שונים וכו'",
    EnglishText: null,
  },
  {
    PresetStringID: 224,
    CodeKey: 'ChallengeTipsDay81',
    DescKey: 'טיפ מתחלף באתגר יום 81',
    HebrewText: "יש לדאוג לנעול נעלי אימון נוחות על מנת למנוע עומסים מיותרים על מפרקי ועצמות הגוף (גב, ברכיים וכו')",
    EnglishText: null,
  },
  {
    PresetStringID: 225,
    CodeKey: 'ChallengeTipsDay82',
    DescKey: 'טיפ מתחלף באתגר יום 82',
    HebrewText: 'לאחר כל אימון מומלץ לבצע מתיחות כלליות לכל הגוף בעזרת סרטון מתיחות הקיים בתוכנית',
    EnglishText: null,
  },
  {
    PresetStringID: 226,
    CodeKey: 'ChallengeTipsDay83',
    DescKey: 'טיפ מתחלף באתגר יום 83',
    HebrewText: 'גם למי שהמטרה שלו/ה שלהוריד אחוזי שומן צריך לעבוד דווקא 60% אימוני כוח ו40% אימוני אירובי',
    EnglishText: null,
  },
  {
    PresetStringID: 227,
    CodeKey: 'ChallengeTipsDay84',
    DescKey: 'טיפ מתחלף באתגר יום 84',
    HebrewText: 'מומלץ להוריד את השתייה המתוקה ובמקום זה לשתות מים או חליטות צמחים',
    EnglishText: null,
  },
  {
    PresetStringID: 228,
    CodeKey: 'ChallengeTipsDay85',
    DescKey: 'טיפ מתחלף באתגר יום 85',
    HebrewText: 'להימנע ממזונות המכילים שומן מוקשה או טראנס הגורמים למחלות קשות ומעלות את רמות השומן "הרע" בזרם הדם הכבד',
    EnglishText: null,
  },
  {
    PresetStringID: 229,
    CodeKey: 'ChallengeTipsDay86',
    DescKey: 'טיפ מתחלף באתגר יום 86',
    HebrewText: 'מומלץ להרבות בירקות מכל המינים והצבעים',
    EnglishText: null,
  },
  {
    PresetStringID: 230,
    CodeKey: 'ChallengeTipsDay87',
    DescKey: 'טיפ מתחלף באתגר יום 87',
    HebrewText: 'מומלץ לפתוח את הבוקר עם כוס מים פושרים וחצי לימון סחוט על בטן ריקה, ידוע כמנקה רעלים ועוזר בתהליך ההרזיה',
    EnglishText: null,
  },
  {
    PresetStringID: 231,
    CodeKey: 'ChallengeTipsDay88',
    DescKey: 'טיפ מתחלף באתגר יום 88',
    HebrewText: 'להימנע ממאכלים מהונדסים',
    EnglishText: null,
  },
  {
    PresetStringID: 232,
    CodeKey: 'ChallengeTipsDay89',
    DescKey: 'טיפ מתחלף באתגר יום 89',
    HebrewText:
      'לכל אחד יש גנטיקה שונה, מבנה גוף שונה, מצב בריאותי שונה, מדדי גוף שונים ואפילו סדר יום והרגלים שונים לכן אי אפשר להשוות את עצמכם לכל אדם אחר, תשלימו עם מה שקיבלתם ותהפכו לגרסה הכי טובה של עצמכם',
    EnglishText: null,
  },
  {
    PresetStringID: 233,
    CodeKey: 'ChallengeTipsDay90',
    DescKey: 'טיפ מתחלף באתגר יום 90',
    HebrewText: 'יש להימנע ממזונות המכילים גלוטן',
    EnglishText: null,
  },
  {
    PresetStringID: 234,
    CodeKey: 'AppMotivationLineTitle1',
    DescKey: 'אפליקציה ראשי-משפט מתחלף1-כותרת',
    HebrewText: 'אימונים המותאמים לך',
    EnglishText: null,
  },
  {
    PresetStringID: 235,
    CodeKey: 'AppMotivationLineSubTitle1',
    DescKey: 'אפליקציה ראשי-משפט מתחלף1-תת כותרת',
    HebrewText: 'מגוון אימונים בהתאם לרמה ולמטרות שלך',
    EnglishText: null,
  },
  {
    PresetStringID: 236,
    CodeKey: 'AppMotivationLineTitle2',
    DescKey: 'אפליקציה ראשי-משפט מתחלף2-כותרת',
    HebrewText: 'תזונה לבחירתך',
    EnglishText: null,
  },
  {
    PresetStringID: 238,
    CodeKey: 'AppMotivationLineSubTitle2',
    DescKey: 'אפליקציה ראשי-משפט מתחלף2-תת כותרת',
    HebrewText: 'תפריטים בריאים ומותאמים אישית עם מתכונים טעימים להכנה מהירה',
    EnglishText: null,
  },
  {
    PresetStringID: 239,
    CodeKey: 'AppMotivationLineTitle3',
    DescKey: 'אפליקציה ראשי-משפט מתחלף3-כותרת',
    HebrewText: 'הכול ביומן שלך',
    EnglishText: null,
  },
  {
    PresetStringID: 240,
    CodeKey: 'AppMotivationLineSubTitle3',
    DescKey: 'אפליקציה ראשי-משפט מתחלף3-תת כותרת',
    HebrewText: 'סנכרון התוכנית בחיי היום יום שלך',
    EnglishText: null,
  },
  {
    PresetStringID: 241,
    CodeKey: 'AppMotivationLineTitle4',
    DescKey: 'אפליקציה ראשי-משפט מתחלף4-כותרת',
    HebrewText: 'מעקב אחר התקדמותך',
    EnglishText: null,
  },
  {
    PresetStringID: 243,
    CodeKey: 'AppMotivationLineSubTitle4',
    DescKey: 'אפליקציה ראשי-משפט מתחלף4-תת כותרת',
    HebrewText: 'טבלת מדדי גופך בשיטת הרמזור למעקב רציף אחר התוצאות',
    EnglishText: null,
  },
]
