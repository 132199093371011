import { connect } from 'react-redux'
import MyMealsScreen from '../components/MyMealsScreen'

export default connect(
  state => ({
    currentUser: state.currentUser.user,
    personalStats: state.currentUser.personalStats,
    allUserMeals: state.currentUser.allUserMeals,
  }),
  {},
)(MyMealsScreen)
