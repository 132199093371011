import React, { Component } from 'react'
import styled from 'styled-components'
import { ChromePicker } from 'react-color'
import ColorLensIcon from '@material-ui/icons/ColorLens'
import { colorPalette } from '../styles/styleVariables'

const ColorPicWrapper = styled.div`
  position: relative;
`
const ColorPicModal = styled.div`
  position: absolute;
  right: -70px;
  top: 135%;
  z-index: 10;
`

class ColorPic extends Component {
  stopPropagation = event => {
    event.stopPropagation()
  }

  onChange = color => {
    const { onChange } = this.props
    onChange('color', color.hex)
  }

  renderModal = () => {
    let { color } = this.props.currentState
    if (!color) {
      color = colorPalette.primary1.main
    }

    return (
      <ColorPicModal onClick={this.stopPropagation}>
        <ChromePicker
          color={color}
          onChangeComplete={this.onChange}
          colors={[
            colorPalette.primary1.main,
            '#B80000',
            '#DB3E00',
            '#FCCB00',
            '#008B02',
            '#006B76',
            '#1273DE',
            '#004DCF',
            '#5300EB',
            '#EB9694',
            '#FAD0C3',
            '#FEF3BD',
            '#C1E1C5',
            '#BEDADC',
            '#C4DEF6',
            '#BED3F3',
            '#D4C4FB',
          ]}
        />
      </ColorPicModal>
    )
  }

  render() {
    const { expanded, onExpandEvent } = this.props
    return (
      <ColorPicWrapper aria-haspopup="true" aria-expanded={expanded} aria-label="rdw-color-picker">
        <div onClick={onExpandEvent}>
          <ColorLensIcon />
        </div>
        {expanded ? this.renderModal() : undefined}
      </ColorPicWrapper>
    )
  }
}

export default ColorPic
