import React, { Component } from 'react'
import styled from 'styled-components'
import { Formik, Form, FieldArray } from 'formik'
import { withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import formBackgroundLeft from '../../images/formBackground_left.jpg'
import formBackgroundRight from '../../images/formBackground_right.jpg'
import { mediaQuery } from '../../services/global'
import { requiredValidation } from '../../services/formValidation'
import { invokeAPIcall } from '../../services/rest'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import StyledTextField from '../StyledTextField'
import Breadcrumbs from '../Breadcrumbs'

// Style
const PageWrapper = styled.div`
  background-color: #f5fcfd;
  background: url(${formBackgroundLeft}) left bottom no-repeat, url(${formBackgroundRight}) right bottom no-repeat, #f7fdfd;
  background-size: 40% auto, 35% auto;
  padding: 20px;
  flex: 1;
  ${mediaQuery.phone`
    background: none;
  `}
`
const FormWrapper = styled.div`
  background-color: ${colorPalette.white.main};
  max-width: 680px;
  margin: 0 auto;
  padding: 20px;
  color: ${colorPalette.primary1.main};
  ${globalStyles.boxShadow}
  ${globalStyles.boxRadius}
`
const PageTitle = styled.h1`
  margin-bottom: 20px;
`
const FieldsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`
const FieldCell = styled.div`
  flex: 0 0 28%;
`
const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  margin-top: 20px;
  ${mediaQuery.phone`
    width: 100%;
  `}
`
const StyledButton = styled(Button)`
  && {
  }
`
const AddNewLevelButton = styled(Button)`
  && {
    margin-top: 20px;
  }
`
const LoadingIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5px;
`

// Component
class MonthlyInstallmentsLevelsAdminScreen extends Component {
  state = {
    initialValues: {
      monthlyInstallmentsLevels: [],
    },
  }

  // Lifecycle methods
  componentDidMount() {
    this.loadMonthlyInstallmentsLevels()
  }

  // Helper methods
  loadMonthlyInstallmentsLevels = async id => {
    const monthlyInstallmentsLevelList = await invokeAPIcall({ uri: 'api/monthlyInstallmentsLevels' })
    this.setState({
      initialValues: {
        monthlyInstallmentsLevels: monthlyInstallmentsLevelList.map(level => ({
          upToPrice: level.UpToPrice,
          maxNumOfInstallments: level.MaxNumOfInstallments,
        })),
      },
    })
  }

  validate = values => {
    let errors = {}

    values.monthlyInstallmentsLevels.forEach((item, index) => {
      if (requiredValidation(item.upToPrice)) {
        if (!errors[index]) {
          errors[index] = {}
        }
        errors[index].upToPrice = requiredValidation(item.upToPrice)
      }
      if (requiredValidation(item.maxNumOfInstallments)) {
        if (!errors[index]) {
          errors[index] = {}
        }
        errors[index].maxNumOfInstallments = requiredValidation(item.maxNumOfInstallments)
      }
    })

    return errors
  }

  // UI event methods
  handleSubmit = async (values, { setSubmitting }) => {
    const data = values.monthlyInstallmentsLevels

    // invoke API call
    try {
      const responseData = await invokeAPIcall({
        uri: `api/monthlyInstallmentsLevels`,
        data,
        method: 'POST',
      })

      // if API success
      if (responseData.Key === true) {
        alert(responseData.Value)
      }
      // if failed
      else {
        alert(responseData.Value || 'התרחשה שגיאה')
      }
    } catch (error) {
      console.log('error in updating monthly installments levels', error)
    }
    setSubmitting(false)
  }

  // Render methods
  render() {
    const { initialValues } = this.state
    return (
      <PageWrapper>
        <Breadcrumbs
          previousItems={[
            {
              label: `מסכי ניהול`,
              url: `/adminPanel`,
            },
          ]}
          currentItem={`ניהול מספר תשלומים`}
        />
        <FormWrapper>
          <PageTitle>{'הגדרת מספר תשלומים חודשיים אפשרי'}</PageTitle>
          <Formik validate={this.validate} initialValues={initialValues} onSubmit={this.handleSubmit} enableReinitialize={true}>
            {formikBag => {
              const { isSubmitting, values, errors, touched, handleChange, handleBlur, isValid } = formikBag
              return (
                <Form>
                  <FieldArray
                    name="monthlyInstallmentsLevels"
                    render={arrayHelpers => (
                      <div>
                        {values.monthlyInstallmentsLevels && values.monthlyInstallmentsLevels.length > 0
                          ? values.monthlyInstallmentsLevels.map((level, index) => {
                              let fromPrice = 0
                              if (index > 0) {
                                fromPrice = values.monthlyInstallmentsLevels[index - 1].upToPrice + 1
                              }
                              const upToPriceError = errors[index] && errors[index].upToPrice
                              const maxNumOfInstallmentsError = errors[index] && errors[index].maxNumOfInstallments
                              const upToPriceTouched =
                                touched.monthlyInstallmentsLevels &&
                                touched.monthlyInstallmentsLevels[index] &&
                                touched.monthlyInstallmentsLevels[index].upToPrice
                              const maxNumOfInstallmentsTouched =
                                touched.monthlyInstallmentsLevels &&
                                touched.monthlyInstallmentsLevels[index] &&
                                touched.monthlyInstallmentsLevels[index].maxNumOfInstallments
                              return (
                                <div key={index}>
                                  <FieldsRow key={index}>
                                    <FieldCell>
                                      <StyledTextField
                                        label={'מסכום'}
                                        value={fromPrice}
                                        margin="normal"
                                        fullWidth
                                        disabled
                                        InputLabelProps={{ shrink: fromPrice !== null }}
                                      />
                                    </FieldCell>
                                    <FieldCell>
                                      <StyledTextField
                                        label={'עד סכום'}
                                        name={`monthlyInstallmentsLevels.${index}.upToPrice`}
                                        value={level.upToPrice}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={upToPriceError && upToPriceTouched && upToPriceError}
                                        error={upToPriceError && upToPriceTouched}
                                        margin="normal"
                                        fullWidth
                                        InputLabelProps={{ shrink: level.upToPrice !== null }}
                                      />
                                    </FieldCell>
                                    <FieldCell>
                                      <StyledTextField
                                        label={'מספר תשלומים מקסימלי'}
                                        name={`monthlyInstallmentsLevels.${index}.maxNumOfInstallments`}
                                        value={level.maxNumOfInstallments}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={maxNumOfInstallmentsError && maxNumOfInstallmentsTouched && maxNumOfInstallmentsError}
                                        error={maxNumOfInstallmentsError && maxNumOfInstallmentsTouched}
                                        margin="normal"
                                        fullWidth
                                        InputLabelProps={{ shrink: level.maxNumOfInstallments !== null }}
                                      />
                                    </FieldCell>
                                    <Button variant="contained" color="secondary" size="small" onClick={() => arrayHelpers.remove(index)}>
                                      הסר
                                    </Button>
                                  </FieldsRow>
                                </div>
                              )
                            })
                          : null}
                        <AddNewLevelButton
                          variant="contained"
                          color="secondary"
                          size="small"
                          onClick={() => arrayHelpers.insert(values.monthlyInstallmentsLevels.length, {})}
                        >
                          הוסף שלב
                        </AddNewLevelButton>
                      </div>
                    )}
                  />

                  <ButtonsWrapper>
                    <StyledButton variant="contained" color="primary" fullWidth disabled={!isValid || isSubmitting} type="submit">
                      עדכן
                    </StyledButton>
                  </ButtonsWrapper>
                  {isSubmitting && (
                    <LoadingIndicatorWrapper>
                      <CircularProgress />
                    </LoadingIndicatorWrapper>
                  )}
                </Form>
              )
            }}
          </Formik>
        </FormWrapper>
      </PageWrapper>
    )
  }
}

export default withRouter(MonthlyInstallmentsLevelsAdminScreen)
