import React, { Component } from 'react'
import styled, { keyframes } from 'styled-components'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { colorPalette, convertHexToRGB, globalStyles } from '../../styles/styleVariables'
import { mediaQuery } from '../../services/global'
import { getString } from '../../services/presetStringsHelper'
import { invokeAPIcall } from '../../services/rest'
import pushUpGuyBg from '../../images/pushUpGuy2.jpeg'
import pushUpGuyMobileBg from '../../images/pushUpGuyMobile.jpg'
import SaleCountdown from './SaleCountdown'
import ChallengePriceStar from '../ChallengePriceStar'
import PackageItemList from '../ChallengePaymentScreen/PackageItemList'
import AppleGoogleButtons from './AppleGoogleButtons'

// Style
const ChallengeCarouselItemWrapper = styled.div`
  min-height: 582px;
  color: ${colorPalette.black.main};
  background-color: ${colorPalette.white.main};
  background-repeat: no-repeat;
  background-position: -70px 0;
  background-size: contain;
  border-bottom-left-radius: ${globalStyles.mainBoxRadius};
  border-bottom-right-radius: ${globalStyles.mainBoxRadius};
  box-shadow: ${globalStyles.mainBoxShadow};
  position: relative;
  z-index: 6;
  top: -94px;
  display: flex;
  overflow: hidden;
  ${mediaQuery.phone`
    background: ${colorPalette.white.main};
    height: auto;
    flex-direction: column;
    padding-top: 24px;
  `}
`
const PhotoPlaceholder = styled.div`
  flex: 0 0 570px;
  background-image: url(${pushUpGuyBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left top;
  ${mediaQuery.tablet`
    flex: 0;
  `}
  ${mediaQuery.phone`
    flex: 0;
  `}
`
const ContentBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding-top: 42px;
  padding-right: 42px;
  ${mediaQuery.phone`
    position: static;
    text-align: center;
    align-items: center;
    width: auto;
    padding: 0;
    margin: 0;
  `}
`
const PromotionTitle = styled.h2`
  font-size: 42px;
  font-weight: 500;
  margin: 0;
  margin-top: 10px;
  ${mediaQuery.tablet`
    font-size: 34px;
  `}
  ${mediaQuery.phone`
    font-size: 28px;
    padding: 0 10px;
  `}
`
const PromotionTitleFirst = styled(PromotionTitle)`
  color: ${colorPalette.primary1.main};
`
const PromotionTitleSecond = styled(PromotionTitle)``
const PromotionSubTitle = styled.h4`
  font-size: 30px;
  font-weight: 500;
  margin-top: 40px;
  ${mediaQuery.phone`
    font-size: 1.2rem;
    text-shadow: none;
    max-width: 100%;
    margin-top: 14px;
  `}
`
const PromotionSubTitleSecond = styled(PromotionSubTitle)`
  font-size: 20px;
  margin-top: 5px;
  font-weight: 400;
`
const MobilePhoto = styled.div`
  display: none;
  background-color: ${colorPalette.white.main};
  background-image: url(${pushUpGuyBg});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  width: 100%;
  height: 254px;
  margin-top: 20px;
  margin-bottom: 10px;
  ${mediaQuery.phone`
    display: block;
  `}
`
const ButtonBarWrapper = styled.div`
  margin: 50px;
  margin-bottom: 10px;
  position: relative;
  ${mediaQuery.phone`
    justify-content: center;
    margin: 20px 0;
  `}
`
const ButtonBar = styled.div`
  ${mediaQuery.phone`
  display: flex;
  flex-direction: column;
  align-items: center;
  `}
`
const SmallLabel = styled.span`
  display: block;
  font-size: 25px;
  margin-top: 4px;
`
const StyledButton = styled(Button)`
  && {
    font-size: 24px;
    ${mediaQuery.phone`
      font-size: 16px;
    `}
  }
`
const SaleCountdownWrapper = styled.div``
const PriceStarWrapper = styled.div`
  position: absolute;
  top: -58px;
  right: 272px;
  ${mediaQuery.phone`
    position: static;
    margin-bottom: 20px;
  `}
`
const PackageSelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${mediaQuery.phone`
    padding: 0 20px;
  `}
`

// Component
class ChallengeCountdownCarouselItem extends Component {
  state = { showPromotionItems: false, globalSettingsData: {} }

  // Lifecycle methods
  componentDidMount() {
    this.loadGlobalSettingsData()

    window.setTimeout(() => {
      this.setState({ showPromotionItems: true })
    }, 800)
  }

  // Helper methods
  loadGlobalSettingsData = async id => {
    let globalSettingsData = await invokeAPIcall({ uri: `api/globalSettings/1` })
    this.setState({
      globalSettingsData,
    })
  }

  // Render methods
  render() {
    const { globalSettingsData } = this.state
    const challengeFullPrice = globalSettingsData ? globalSettingsData.ChallengePricePack1 : 0 // we use the price of 'package1' which is 'BASIC' (not Premium)
    const challengePriceOriginal = globalSettingsData ? globalSettingsData.ChallengePricePack1Original : 0
    const challengeSaleTillDate =
      globalSettingsData && globalSettingsData.ChallengeSaleTillDate ? globalSettingsData.ChallengeSaleTillDate : null

    let countdownIsStillOngoing = true
    if (challengeSaleTillDate) {
      // Get todays date and time
      const now = new Date().getTime()
      const countDownDate = new Date(challengeSaleTillDate).getTime()

      // Find the distance between now and the count down date
      var distance = countDownDate - now
      if (distance < 0) {
        countdownIsStillOngoing = false
      }
    }

    // only for Cordova app
    if (window.cordova) {
      return (
        <PriceStarWrapper>
          <ChallengePriceStar challengeFullPrice={challengeFullPrice} challengePriceOriginal={challengePriceOriginal} />
        </PriceStarWrapper>
      )
    }

    return (
      <ChallengeCarouselItemWrapper>
        <ContentBox>
          <PromotionTitle>{getString('ChallengeCountdownCarouselItemTitle1')}</PromotionTitle>
          <MobilePhoto />
          <PromotionSubTitle>{getString('ChallengeCountdownCarouselItemSubTitle1')}</PromotionSubTitle>
          {challengeSaleTillDate && countdownIsStillOngoing && (
            <PromotionSubTitleSecond>{getString('ChallengeCountdownCarouselItemSubTitle2')}</PromotionSubTitleSecond>
          )}
          {challengeSaleTillDate && countdownIsStillOngoing && (
            <SaleCountdownWrapper>
              <SaleCountdown timeTillDate={challengeSaleTillDate} timeFormat="YYYY-MM-DDThh:mm" />
            </SaleCountdownWrapper>
          )}
          <PackageSelectionWrapper>
            <PackageItemList globalSettingsData={globalSettingsData} hideBtn />
            <ButtonBarWrapper>
              <ButtonBar>
                <Link to="/registerToChallenge">
                  <StyledButton variant="contained" color="secondary" size="large">
                    {getString('ChallengeCountdownCarouselItemButton')}
                  </StyledButton>
                </Link>
                <SmallLabel>{getString('ChallengeCountdownCarouselItemButtonLabel')}</SmallLabel>
              </ButtonBar>
              {/*<PriceStarWrapper>
                <ChallengePriceStar challengeFullPrice={challengeFullPrice} challengePriceOriginal={challengePriceOriginal} />
              </PriceStarWrapper>*/}
            </ButtonBarWrapper>
            <AppleGoogleButtons />
          </PackageSelectionWrapper>
        </ContentBox>
        <PhotoPlaceholder />
      </ChallengeCarouselItemWrapper>
    )
  }
}

export default ChallengeCountdownCarouselItem
