import React, { Component } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers'
import CloseIcon from '@material-ui/icons/Close'
import { colorPalette } from '../../styles/styleVariables'

// Consts

// Style
const StyledDialog = styled(Dialog)`
  && {
    .MuiDialog-paper {
      margin: 12px;
    }
  }
`
const StyledDialogTitle = styled(DialogTitle)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
  }
`
const FormWrapper = styled.div`
  padding: 20px 14px;
`
const StyledButton = styled(Button)`
  && {
    margin-top: 20px;
  }
`
const FieldsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin: 10px 0;
`
const FieldCell = styled.div`
  flex: 0 0 48%;
  display: flex;
  justify-content: space-between;
`
const FullFieldCell = styled.div`
  flex: 0 0 100%;
`
const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
`
const SmallLabel = styled.div`
  font-size: 13px;
  margin-top: 10px;
  color: ${colorPalette.primary1.main};
`

// Component
class DownloadCalendarDialog extends Component {
  state = {
    initialValues: {
      startInDay: new Date(),
      breakfastTime:
        moment()
          .hour(5)
          .minute(0)
          .second(0)
          .format('YYYY-MM-DDTHH:mm:ss') + '.000Z',
      snack1Time:
        moment()
          .hour(8)
          .minute(0)
          .second(0)
          .format('YYYY-MM-DDTHH:mm:ss') + '.000Z',
      lunchTime:
        moment()
          .hour(10)
          .minute(0)
          .second(0)
          .format('YYYY-MM-DDTHH:mm:ss') + '.000Z',
      snack2Time:
        moment()
          .hour(14)
          .minute(0)
          .second(0)
          .format('YYYY-MM-DDTHH:mm:ss') + '.000Z',
      dinnerTime:
        moment()
          .hour(17)
          .minute(0)
          .second(0)
          .format('YYYY-MM-DDTHH:mm:ss') + '.000Z',
      workoutTime:
        moment()
          .hour(13)
          .minute(0)
          .second(0)
          .format('YYYY-MM-DDTHH:mm:ss') + '.000Z',
    },
  }

  // Lifecycle methods
  componentDidMount() {}

  // Helper methods

  // UI event methods

  // Render methods
  render() {
    const { initialValues } = this.state
    const { handleClose, handleSubmit, mode } = this.props
    const timePickerProps = {
      ampm: false,
      autoOk: true,
      variant: 'inline',
      invalidDateMessage: 'זמן לא תקין',
    }

    return (
      <StyledDialog onClose={handleClose} open>
        <StyledDialogTitle id="simple-dialog-title" disableTypography>
          <Typography variant="h6">הורדה ליומן האישי</Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        {mode === 'successMode' && (
          <DialogContent>
            מעולה! הורדת כעת בהצלחה קובץ ics להטמעת האימונים והארוחות של השבוע הנוכחי ביומן האישי שלך.
            <br />
            <br />
            כעת יש ללחוץ פעמיים על קובץ ה-ics, לבחור ביומן הפגישות שלך והוא יעודכן עם כל האימונים והארוחות של השבוע הנוכחי.
            <ButtonsWrapper>
              <SmallLabel>תואם ליומנים: iPhone, Android, Mac</SmallLabel>
              <br />
            </ButtonsWrapper>
          </DialogContent>
        )}
        {mode === 'formMode' && (
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {formikBag => {
              const { isSubmitting, values, errors, touched, handleChange, handleBlur, isValid, setFieldValue } = formikBag
              return (
                <FormWrapper>
                  <Form>
                    <FieldsRow>
                      <FullFieldCell>
                        <KeyboardDatePicker
                          {...timePickerProps}
                          label="מלא את היומן החל מיום"
                          name="startInDay"
                          format="DD/MM/YYYY"
                          value={values.startInDay}
                          onChange={(value, e) => {
                            setFieldValue('startInDay', value, false)
                          }}
                          invalidDateMessage="תאריך לא תקין"
                          minDate={new Date()}
                          fullWidth
                        />
                      </FullFieldCell>
                    </FieldsRow>
                    <FieldsRow>
                      <FieldCell>
                        <KeyboardTimePicker
                          {...timePickerProps}
                          label="שעת ארוחת בוקר"
                          name="breakfastTime"
                          value={values.breakfastTime}
                          onChange={(value, e) => {
                            setFieldValue('breakfastTime', value, false)
                          }}
                        />
                      </FieldCell>
                      <FieldCell>
                        <KeyboardTimePicker
                          {...timePickerProps}
                          label="שעת נשנוש ראשון"
                          name="snack1Time"
                          value={values.snack1Time}
                          onChange={(value, e) => {
                            setFieldValue('snack1Time', value, false)
                          }}
                        />
                      </FieldCell>
                    </FieldsRow>
                    <FieldsRow>
                      <FieldCell>
                        <KeyboardTimePicker
                          {...timePickerProps}
                          label="שעת ארוחת צוהריים"
                          name="lunchTime"
                          value={values.lunchTime}
                          onChange={(value, e) => {
                            setFieldValue('lunchTime', value, false)
                          }}
                        />
                      </FieldCell>
                      <FieldCell>
                        <KeyboardTimePicker
                          {...timePickerProps}
                          label="שעת נשנוש שני"
                          name="snack2Time"
                          value={values.snack2Time}
                          onChange={(value, e) => {
                            setFieldValue('snack2Time', value, false)
                          }}
                        />
                      </FieldCell>
                    </FieldsRow>
                    <FieldsRow>
                      <FieldCell>
                        <KeyboardTimePicker
                          {...timePickerProps}
                          label="שעת ארוחת ערב"
                          name="dinnerTime"
                          value={values.dinnerTime}
                          onChange={(value, e) => {
                            setFieldValue('dinnerTime', value, false)
                          }}
                        />
                      </FieldCell>
                      <FieldCell>
                        <KeyboardTimePicker
                          {...timePickerProps}
                          label="שעת אימון יומי"
                          name="workoutTime"
                          value={values.workoutTime}
                          onChange={(value, e) => {
                            setFieldValue('workoutTime', value, false)
                          }}
                        />
                      </FieldCell>
                    </FieldsRow>
                    <ButtonsWrapper>
                      <StyledButton variant="contained" color="primary" type="submit">
                        הורדה ליומן האישי
                      </StyledButton>
                      <SmallLabel>תואם ליומנים: iPhone, Android, Mac</SmallLabel>
                    </ButtonsWrapper>
                  </Form>
                </FormWrapper>
              )
            }}
          </Formik>
        )}
      </StyledDialog>
    )
  }
}

export default withRouter(DownloadCalendarDialog)
