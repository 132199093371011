import React, { Component } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import { withRouter } from 'react-router-dom'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/DoneOutline'
import { invokeAPIcall } from '../../services/rest'
import { mediaQuery, keysToLowerCase } from '../../services/global'
import { requiredValidation } from '../../services/formValidation'
import { foodTypeOptions } from '../../services/recipes'
import { questionFields } from '../PersonalStatsScreen'

// Consts

// Style
const StyledDialogTitle = styled(DialogTitle)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
  }
`
const StyledButton = styled(Button)`
  && {
    margin-left: 10px;
  }
  ${mediaQuery.phone`
    && {
      margin-left: 0;
      margin-bottom: 10px;
      flex: 1;
    }
  `}
`
const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 40px;
  ${mediaQuery.phone`
    flex-direction: column;
    margin-top: 10px;
  `}
`

const FormWrapper = styled.div`
  padding: 20px 40px;
  padding-top: 0;
`
const FieldsRow = styled.div`
  margin: 20px 0;
  min-width: 460px;
  display: ${props => (props.isVisible ? 'display' : 'none')};
  ${mediaQuery.phone`
    min-width: 0;
  `}
`
const LoadingIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5px;
`
const FieldLabel = styled.h4`
  margin-bottom: 10px;
`
const SelectorButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${mediaQuery.phone`
    .MuiButton-label {
      font-size: 11px;
    }
  `}
`
const SelectorButtonCheckIcon = styled(CheckIcon)`
  && {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 8px;
    right: 4px;
    opacity: ${props => (props.selected ? '1' : '0')};
  }
`
const SelectorButton = styled(Button)`
  && {
    margin-left: 5px;
    margin-bottom: 5px;
    padding-right: 20px;
  }
`

// Consts
const personalStatsFieldsToDisplay = questionFields.filter(question => question.fieldName.includes('foodType'))

// Component
class FoodTypeChangeDialog extends Component {
  state = {
    initialValues: {},
    warningMode: false,
  }

  // Lifecycle methods
  componentDidMount() {
    const { personalStats } = this.props
    let foodTypeOfUser = []

    const startStats = personalStats && personalStats.filter(item => item.Phase === 'start')
    if (startStats && startStats[0] && startStats[0].FoodType) {
      // get the FoodType of this user
      foodTypeOfUser = startStats[0].FoodType.split(',').map(item => Number(item.trim()))
    }

    const initialValues = {
      foodType: foodTypeOfUser.filter(item => item <= 4),
      foodTypeExtra: foodTypeOfUser.filter(item => item > 4),
    }
    this.setState({ initialValues })
  }

  // Helper methods
  validate = values => {
    let errors = {}
    if (values.foodType.length === 0) {
      errors.foodType = 'שדה חובה'
    }

    return errors
  }

  // UI event methods
  handleSubmit = async (values, { setSubmitting }) => {
    const { warningMode } = this.state
    const { handleClose } = this.props

    if (warningMode === false) {
      setSubmitting(false)
      this.setState({ warningMode: true })
      return
    }

    const personalStatsList = await invokeAPIcall({ uri: 'api/personalStats' })
    const personalStatsOfSpecificPhase = personalStatsList.filter(item => item.Phase === 'start')
    if (personalStatsOfSpecificPhase.length > 0) {
      const existingPersonalStatsRecord = personalStatsOfSpecificPhase[0]
      const data = {
        ...keysToLowerCase(existingPersonalStatsRecord),
        foodType: [...values.foodType, ...values.foodTypeExtra].join(', '),
      }

      // invoke API call to update FoodType field value
      try {
        const responseData = await invokeAPIcall({ uri: 'api/personalStats', data, method: 'POST' })

        // if API success
        if (responseData.Key === true) {
          // invoke API call to update the user's meals list
          try {
            await invokeAPIcall({ uri: 'api/users/InitMealListOfUser', data: {}, method: 'PUT' })
          } catch (error) {}

          handleClose()
          window.location.reload()
        }
        // if failed
        else {
          alert(responseData.Value || 'התרחשה שגיאה')
        }
      } catch (error) {
        console.log('error in updating PersonalStats values', error)
      }
    }
    setSubmitting(false)
  }

  // Render methods
  render() {
    const { initialValues, warningMode } = this.state
    const { handleClose } = this.props

    return (
      <Dialog onClose={handleClose} open>
        <StyledDialogTitle id="simple-dialog-title" disableTypography>
          <Typography variant="h6">עדכון התזונה המועדפת</Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <DialogContent>
          <Formik validate={this.validate} initialValues={initialValues} onSubmit={this.handleSubmit}>
            {formikBag => {
              const { isSubmitting, values, errors, touched, handleChange, handleBlur, isValid, setFieldValue } = formikBag
              return (
                <FormWrapper>
                  <Form>
                    {personalStatsFieldsToDisplay.map(fieldInfo => {
                      let fieldOptions = []
                      if (fieldInfo.fieldName === 'foodType') {
                        fieldOptions = foodTypeOptions.filter(item => item.value <= 4)
                      } else if (fieldInfo.fieldName === 'foodTypeExtra') {
                        fieldOptions = foodTypeOptions.filter(item => item.value > 4)
                      }

                      return (
                        <FieldsRow isVisible={warningMode === false}>
                          <FieldLabel>{fieldInfo.fieldLabel}</FieldLabel>
                          {fieldInfo.type === 'selector' && (
                            <>
                              <SelectorButtonWrapper>
                                {fieldOptions.map(item => {
                                  const currentQuestionValue = values[fieldInfo.fieldName]
                                  const selected = Array.isArray(currentQuestionValue) && currentQuestionValue.includes(item.value)
                                  return (
                                    <SelectorButton
                                      variant="outlined"
                                      color="primary"
                                      onClick={() => {
                                        const optionItem = item.value
                                        let newValue = [optionItem]
                                        if (Array.isArray(currentQuestionValue)) {
                                          // if this optionItem already inside the array (currentQuestionValue)
                                          if (currentQuestionValue.includes(optionItem)) {
                                            newValue = currentQuestionValue.filter(item => item !== optionItem)
                                          } else {
                                            // depends on "multiselect" - add it to existing array, or create a new array
                                            if (fieldInfo.multiSelect) {
                                              newValue = [...currentQuestionValue, optionItem]
                                            } else {
                                              newValue = [optionItem]
                                            }
                                          }
                                        }
                                        setFieldValue(fieldInfo.fieldName, newValue, false)
                                      }}
                                    >
                                      {item.label}
                                      <SelectorButtonCheckIcon selected={selected} />
                                    </SelectorButton>
                                  )
                                })}
                              </SelectorButtonWrapper>
                            </>
                          )}
                        </FieldsRow>
                      )
                    })}
                    {warningMode && <div>שינוי זה יגרום לבנייה מחדש של כל לוח הארוחות בחוויית החיטוב שלך. האם יש לבצע את השינוי?</div>}
                    <ButtonsWrapper>
                      <StyledButton variant="contained" color="primary" disabled={isSubmitting} type="submit">
                        עדכון
                      </StyledButton>
                      <StyledButton color="primary" onClick={handleClose}>
                        ביטול
                      </StyledButton>
                    </ButtonsWrapper>
                    {isSubmitting && (
                      <LoadingIndicatorWrapper>
                        <CircularProgress />
                      </LoadingIndicatorWrapper>
                    )}
                  </Form>
                </FormWrapper>
              )
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    )
  }
}

export default withRouter(FoodTypeChangeDialog)
