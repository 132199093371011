import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import PersonIcon from '@material-ui/icons/Person'
import CheckIcon from '@material-ui/icons/CheckCircleOutline'
import dumbbleGuyImage from '../../images/dumbbleGuy.jpg'
import { mediaQuery, capitalizeFirstLetter } from '../../services/global'
import { invokeAPIcall } from '../../services/rest'
import { foodTypeOptions } from '../../services/recipes'
import { colorPalette } from '../../styles/styleVariables'
import Breadcrumbs from '../Breadcrumbs'
import { questionFields } from '../PersonalStatsScreen'
import StartOfPaidChallengeDialog from '../ProgramScreen/StartOfPaidChallengeDialog'
import FoodTypeChangeDialog from './FoodTypeChangeDialog'

// Consts

// Style
const PageWrapper = styled.div`
  padding: 20px;
  padding-top: 0;
  flex: 1;
  ${mediaQuery.phone`
    background: none;
    padding: 20px;
  `}
`
const MyProfileScreenContent = styled.div`
  display: flex;
  ${mediaQuery.phone`
    width: auto;
    padding: 0 10px;
    flex-direction: column;
  `}
`
const MyProfileTitleSection = styled.div`
  flex: 1.5;
  margin-left: 30px;
  ${mediaQuery.phone`
    margin-bottom: 30px;
    margin-left: 0;
  `}
`
const MyProfileTitle = styled.div`
  background-color: ${colorPalette.primary1.main};
  color: ${colorPalette.white.main};
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 4px 10px;
  display: flex;
  align-items: center;
`
const MyProfileTitleLabel = styled.div`
  color: ${colorPalette.white.main};
  font-size: 36px;
  font-weight: 500;
  margin-right: 20px;
  ${mediaQuery.phone`
    font-size: 30px;
  `}
`
const iconCSS = css`
  && {
    width: 50px;
    height: 50px;
    color: ${colorPalette.white.main};
  }
`
const StyledPersonIcon = styled(PersonIcon)`
  ${iconCSS}
`
const MyProfileTitlePhoto = styled.div`
  width: 100%;
  height: 200px;
  background-image: url(${dumbbleGuyImage});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
`
const PersonalStatsItemList = styled.ul`
  flex: 3;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`
const PersonalStatsItem = styled.li`
  flex: 0 0 49%;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${colorPalette.primary1.main};
  display: flex;
  align-items: flex-start;
  svg {
    color: ${colorPalette.primary1.main};
    width: 35px;
    height: 35px;
    margin-top: -2px;
    margin-left: 5px;
  }
  ${mediaQuery.phone`
    flex: 0 0 100%;
  `}
`
const PersonalStatsItemContent = styled.div`
  flex: 1;
`
const PersonalStatsItemLabel = styled.div`
  font-size: 18px;
  font-weight: 500;
`
const PersonalStatsItemValue = styled.div``
const PersonalStatsItemActionBtn = styled(Button)`
  && {
    margin-left: 20px;
  }
`

// Component
class MyPersonalStatsProfileScreen extends Component {
  state = {
    showFoodTypeChangeDialog: false,
    showStartOfPaidChallengeDialog: false,
    personalStatsFieldsToDisplayInDialog: null,
  }

  // Lifecycle methods
  componentDidMount() {}

  // Helper methods
  getFieldValue = (statsObject, fieldInfo) => {
    if (statsObject && statsObject[0]) {
      const fieldName = capitalizeFirstLetter(fieldInfo.fieldName)
      const val = statsObject[0][fieldName]
      let displayedValue = val

      if (fieldInfo.labelFormat && displayedValue) {
        displayedValue = fieldInfo.labelFormat
          .replace('{item}', displayedValue)
          .replace('{libItem}', Math.round(Number(displayedValue) * 2.204))
          .replace('{feetItem}', this.convertToFeet(displayedValue))
      }
      if (fieldInfo.type === 'selector' && fieldInfo.booleanSelect) {
        displayedValue = displayedValue === true ? 'כן' : displayedValue === false ? 'לא' : null
      }
      if (fieldInfo.fieldName === 'foodType') {
        const foodTypeOfUser = displayedValue.split(',').map(item => item.trim())
        displayedValue = foodTypeOptions
          .filter(item => foodTypeOfUser.includes(item.value.toString()))
          .map(item => item.label)
          .join(', ')
      }
      if (fieldInfo.fieldName === 'useMedications' && val === true) {
        if (statsObject[0]['UseMedicationsText']) {
          displayedValue = `${displayedValue} (${statsObject[0]['UseMedicationsText']})`
        }
      }

      if (displayedValue === '' || displayedValue == null) {
        displayedValue = '-'
      }

      return displayedValue
    }
    return null
  }

  convertToFeet = n => {
    var realFeet = (n * 0.3937) / 12
    var feet = Math.floor(realFeet)
    var inches = Math.round((realFeet - feet) * 12)
    return feet + ' פיט, ' + inches + ' אינ׳'
  }

  // UI event methods
  handleFoodTypeDialogClick = () => {
    this.setState({ showFoodTypeChangeDialog: true })
  }

  handleFoodTypeDialogClose = () => {
    this.setState({ showFoodTypeChangeDialog: false })
  }

  handleStartOfPaidChallengeDialogClick = fieldName => () => {
    this.setState({
      showStartOfPaidChallengeDialog: true,
      personalStatsFieldsToDisplayInDialog: questionFields.filter(question => question.fieldName === fieldName),
    })
  }

  handleStartOfPaidChallengeDialogClose = () => {
    this.setState({ showStartOfPaidChallengeDialog: false })
  }

  // Render methods
  render() {
    const { showFoodTypeChangeDialog, showStartOfPaidChallengeDialog, personalStatsFieldsToDisplayInDialog } = this.state
    const { personalStats } = this.props
    const startStats = personalStats && personalStats.filter(item => item.Phase === 'start')

    const fieldToDisplay = questionFields.filter(question => question.showInMyPersonalStatsProfile)

    return (
      <PageWrapper>
        <Breadcrumbs
          previousItems={[
            {
              label: 'חוויית החיטוב ב-90 יום',
              url: `/program`,
            },
          ]}
          currentItem="הפרופיל שלי"
        />
        <MyProfileScreenContent>
          <MyProfileTitleSection>
            <MyProfileTitle>
              <StyledPersonIcon />
              <MyProfileTitleLabel>הפרופיל שלי</MyProfileTitleLabel>
            </MyProfileTitle>
            <MyProfileTitlePhoto />
          </MyProfileTitleSection>
          <PersonalStatsItemList>
            {fieldToDisplay.map(fieldInfo => {
              const fieldName = capitalizeFirstLetter(fieldInfo.fieldName)
              const actualFieldValue = startStats[0][fieldName]
              const fieldValue = this.getFieldValue(startStats, fieldInfo)
              return (
                <PersonalStatsItem>
                  <CheckIcon />
                  <PersonalStatsItemContent>
                    <PersonalStatsItemLabel>{fieldInfo.fieldLabel}</PersonalStatsItemLabel>
                    <PersonalStatsItemValue>{fieldValue}</PersonalStatsItemValue>
                  </PersonalStatsItemContent>
                  {fieldInfo.fieldName === 'foodType' && (
                    <PersonalStatsItemActionBtn
                      variant="contained"
                      color="primary"
                      className="wizard_foodTypeChangeBtn"
                      onClick={this.handleFoodTypeDialogClick}
                    >
                      עדכון
                    </PersonalStatsItemActionBtn>
                  )}
                  {(fieldInfo.fieldName === 'useMedications' || fieldInfo.fieldName === 'notes') && actualFieldValue !== null && (
                    <PersonalStatsItemActionBtn
                      variant="contained"
                      color="primary"
                      onClick={this.handleStartOfPaidChallengeDialogClick(fieldInfo.fieldName)}
                    >
                      עדכון
                    </PersonalStatsItemActionBtn>
                  )}
                </PersonalStatsItem>
              )
            })}
          </PersonalStatsItemList>
        </MyProfileScreenContent>
        {showFoodTypeChangeDialog && (
          <FoodTypeChangeDialog personalStats={personalStats} handleClose={this.handleFoodTypeDialogClose}></FoodTypeChangeDialog>
        )}
        {showStartOfPaidChallengeDialog && (
          <StartOfPaidChallengeDialog
            personalStats={personalStats}
            personalStatsFieldsToDisplay_custom={personalStatsFieldsToDisplayInDialog}
            handleClose={this.handleStartOfPaidChallengeDialogClose}
          ></StartOfPaidChallengeDialog>
        )}
      </PageWrapper>
    )
  }
}

export default withRouter(MyPersonalStatsProfileScreen)
