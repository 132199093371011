import React, { Component } from 'react'
import styled from 'styled-components'
import { Link, withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import formBackgroundLeft from '../../images/formBackground_left.jpg'
import formBackgroundRight from '../../images/formBackground_right.jpg'
import { mediaQuery, getQuerystringVal } from '../../services/global'
import { invokeAPIcall } from '../../services/rest'

// Consts

// Style
const PageWrapper = styled.div`
  background-color: #f5fcfd;
  background: url(${formBackgroundLeft}) left bottom no-repeat, url(${formBackgroundRight}) right bottom no-repeat, #f7fdfd;
  background-size: 40% auto, 35% auto;
  padding: 20px;
  flex: 1;
  text-align: center;
  ${mediaQuery.phone`
    background: none;
  `}
`
const PaymentMessageTitle = styled.h1`
  margin-top: 40px;
`
const PaymentMessageBody = styled.h5`
  text-align: center;
  margin: 20px 0;
`
const PaymentMessageSmallText = styled.div`
  text-align: center;
  font-size: 12px;
`
const StyledButton = styled(Button)`
  && {
    font-size: 20px;
  }
`

// Component
class ChallengeAfterPaymentScreen extends Component {
  state = {
    firstName: '',
    payerEmail: '',
    errorCode: '',
  }

  // Lifecycle methods
  async componentDidMount() {
    const { setCurrentUser } = this.props
    const errorCode = getQuerystringVal('error')
    this.setState({
      firstName: getQuerystringVal('firstName'),
      payerEmail: getQuerystringVal('payerEmail'),
      errorCode,
    })

    if (errorCode == null || errorCode === '') {
      // update the 'currentUser'
      const currentUserData = await invokeAPIcall({ uri: 'api/users/getCurrentUser' })
      setCurrentUser({ user: currentUserData })
    }
  }

  // Helper methods

  // UI event methods

  // Render methods
  render() {
    const { firstName, payerEmail, errorCode } = this.state
    const firstNameLabel = firstName ? `${firstName} ` : ''
    const payerEmailLabel = payerEmail ? `לכתובת: ${payerEmail}` : 'לכתובת אותה הזנת'
    return (
      <PageWrapper>
        {errorCode !== null && errorCode !== '' && (
          <>
            <PaymentMessageTitle>שגיאה בסליקת כרטיס האשראי</PaymentMessageTitle>
            <PaymentMessageBody>אנא פנה לחברת האשראי להסדר התשלום ונסה שנית.</PaymentMessageBody>
            <PaymentMessageSmallText>מספר שגיאה: {errorCode}</PaymentMessageSmallText>
          </>
        )}
        {(errorCode == null || errorCode === '') && (
          <>
            <PaymentMessageTitle>{firstNameLabel}מזל טוב, נרשמת כעת ל"חוויית החיטוב ב-90 יום"!</PaymentMessageTitle>
            <PaymentMessageBody>
              חיוב האשראי יבוצע רק בסיום 7 ימי הניסיון.
              <br />
              ברגעים אלו נשלח מייל אישור {payerEmailLabel}
              <br />
              <br />
              צוות FitThyBody מאחל לך בהצלחה ב"חוויית החיטוב ב-90 יום"!
            </PaymentMessageBody>
            <Link to="/program">
              <StyledButton variant="contained" color="secondary" size="large">
                לעמוד "חוויית החיטוב ב-90 יום"
              </StyledButton>
            </Link>
          </>
        )}
      </PageWrapper>
    )
  }
}

export default withRouter(ChallengeAfterPaymentScreen)
