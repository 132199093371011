import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { withRouter, Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import LockIcon from '@material-ui/icons/Lock'
import { mediaQuery, photoUrlPrefix } from '../../services/global'
import { invokeAPIcall } from '../../services/rest'
import { colorPalette } from '../../styles/styleVariables'

// Consts

// Style
const StyledDialogTitle = styled(DialogTitle)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
  }
`
const StyledList = styled(List)`
  && {
    max-height: 300px;
    min-width: 450px;
    overflow: auto;
    ${mediaQuery.phone`
      min-width: 0;
      max-height: auto;
    `}
    .MuiListItem-secondaryAction {
      padding-left: 82px;
    }
  }
`
const MealThumbnailImg = styled.div`
  width: 100%;
  height: 100%;
  background-color: #eee;
  background-image: url('${props => props.photoUrl}');
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  margin: auto;
  position: relative;
`
const LoadingIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
  margin-bottom: 20px;
`
const TrialUserLayer = styled.div`
  position: absolute;
  top: 50px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 80;
  opacity: 0;
  padding: 30px;
  background: ${colorPalette.white.main};
  &:hover {
    opacity: 1;
  }
`
const LockIconWrapper = styled.div`
  margin-bottom: 6px;
`
const StyledButton = styled(Button)`
  && {
    margin-top: 20px;
  }
`
const EmptyLabel = styled.div`
  padding: 20px;
  text-align: center;
`

// Component
class SwapUserMealDialog extends Component {
  state = {
    isLoading: false,
    meals: [],
  }

  // Lifecycle methods
  componentDidMount() {
    this.loadMeals()
  }

  // Helper methods
  loadMeals = async () => {
    this.setState({ isLoading: true })

    const { userMealData } = this.props
    let meals = await invokeAPIcall({ uri: 'api/recipes' })

    // filter the meals by FoodType (based on the user's preferences)
    meals = this.filterMealsByFoodTypeOfUser(meals)

    this.setState({
      meals: meals.filter(meal => meal.DayTime == userMealData.dayTime && meal.ShowInChallengePhase == userMealData.challengePhase),
      isLoading: false,
    })
  }

  getPhotoUrl = item => {
    let photoUrl = item.PhotoURL
    if (photoUrl) {
      if (!photoUrl.includes('http')) {
        photoUrl = `${photoUrlPrefix}/recipePhotos/${photoUrl}`
      }
    }
    return photoUrl
  }

  filterMealsByFoodTypeOfUser = meals => {
    const { personalStats } = this.props
    const startStats = personalStats && personalStats.filter(item => item.Phase === 'start')
    if (startStats && startStats[0] && startStats[0].FoodType) {
      // filter the meals by FoodType (based on the user's preferences)
      const foodTypeOfUser = startStats[0].FoodType.split(',').map(item => item.trim())
      meals = meals.filter(meal => foodTypeOfUser.every(item => meal.FoodTypeIDs.split(',').includes(item)))
    }
    return meals
  }

  // UI event methods
  handleMealSelection = async newlySelectedRecipeId => {
    const { userMealData } = this.props
    // invoke API call
    try {
      const responseData = await invokeAPIcall({
        uri: `api/userMeals/${userMealData.userMealId}`,
        data: { selectedRecipeId: newlySelectedRecipeId },
        method: 'PUT',
      })

      // if API success
      if (responseData.Key === true) {
        const { handleClose } = this.props
        handleClose(true)
      }
      // if failed
      else {
        alert(responseData.Value || 'התרחשה שגיאה')
      }
    } catch (error) {
      console.log('error in adding/updating userMeal', error)
    }
  }

  // Render methods
  renderMealItem = meal => {
    const { userMealData } = this.props
    const isSelected = meal.RecipeID === userMealData.recipeId
    let photoUrl = this.getPhotoUrl(meal)
    return (
      <ListItem
        button
        key={meal.RecipeID}
        selected={isSelected}
        onClick={isSelected ? null : this.handleMealSelection.bind(this, meal.RecipeID)}
      >
        <ListItemAvatar>
          <Avatar>
            <MealThumbnailImg photoUrl={photoUrl} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={meal.Name} />
        <ListItemSecondaryAction>
          {isSelected && <CheckIcon />}
          {!isSelected && (
            <a
              onClick={() => {
                window.open(`https://www.fitthybody.com/myDailySchedule/myMeals/${meal.RecipeID}`, '_blank')
              }}
            >
              הצג מתכון
            </a>
          )}
        </ListItemSecondaryAction>
      </ListItem>
    )
  }
  render() {
    const { handleClose, isTrialUser } = this.props
    const { meals, isLoading } = this.state
    return (
      <Dialog onClose={handleClose} open>
        <StyledDialogTitle id="simple-dialog-title" disableTypography>
          <Typography variant="h6">החלף ארוחה</Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <StyledList>{meals.map(this.renderMealItem)}</StyledList>
        {meals.length === 0 && !isLoading && <EmptyLabel>אין ארוחות במאגר</EmptyLabel>}
        {isLoading && (
          <LoadingIndicatorWrapper>
            <CircularProgress />
          </LoadingIndicatorWrapper>
        )}
        {meals.length > 0 && isTrialUser && (
          <TrialUserLayer>
            <>
              <LockIconWrapper title="ללקוחות פרימיום">
                <LockIcon />
              </LockIconWrapper>
              <div>מהפך ב-90 יום</div>
              <div>בריא יותר, חזק יותר, מאושר יותר</div>
              <Link to="/challengePayment">
                <StyledButton variant="contained" color="secondary" size="large">
                  לתוכנית הפרימיום
                </StyledButton>
              </Link>
            </>
          </TrialUserLayer>
        )}
      </Dialog>
    )
  }
}

export default withRouter(SwapUserMealDialog)
