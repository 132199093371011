// Actions
const OPEN_SNACKBAR = 'snackbar/openSnackbar'
const CLOSE_SNACKBAR = 'snackbar/closeSnackbar'

// Reducer
const initialState = {
  message: null,
  severity: null,
  actionText: null,
  actionOnClick: null,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case OPEN_SNACKBAR:
      return {
        ...state,
        message: action.payload.message,
        severity: action.payload.severity,
        actionText: action.payload.actionText,
        actionOnClick: action.payload.actionOnClick,
      }
    case CLOSE_SNACKBAR:
      return {
        message: null,
        severity: null,
        actionText: null,
        actionOnClick: null,
      }
    default:
      return state
  }
}

// Action Creators
export function openSnackbar({ message, severity, actionText, actionOnClick }) {
  return {
    type: OPEN_SNACKBAR,
    payload: { message, severity, actionText, actionOnClick },
  }
}

export function closeSnackbar() {
  return {
    type: CLOSE_SNACKBAR,
  }
}
