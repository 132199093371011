import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { withRouter, Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import LockIcon from '@material-ui/icons/Lock'
import { mediaQuery } from '../../services/global'
import { invokeAPIcall } from '../../services/rest'
import { colorPalette } from '../../styles/styleVariables'

// Consts

// Style
const StyledDialogTitle = styled(DialogTitle)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
  }
`
const StyledList = styled(List)`
  && {
    max-height: 300px;
    min-width: 450px;
    overflow: auto;
    ${mediaQuery.phone`
      min-width: 0;
      max-height: auto;
    `}
    .MuiListItem-secondaryAction {
      padding-left: 82px;
    }
  }
`
const WorkoutThumbnailImg = styled.div`
  width: 100%;
  height: 100%;
  background-color: #eee;
  background-image: url('http://img.youtube.com/vi/${props => props.youtubeCode}/0.jpg');
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  margin: auto;
  position: relative;
`
const LoadingIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
  margin-bottom: 20px;
`
const TrialUserLayer = styled.div`
  position: absolute;
  top: 50px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 80;
  opacity: 0;
  padding: 30px;
  background: ${colorPalette.white.main};
  &:hover {
    opacity: 1;
  }
`
const LockIconWrapper = styled.div`
  margin-bottom: 6px;
`
const StyledButton = styled(Button)`
  && {
    margin-top: 20px;
  }
`
const EmptyLabel = styled.div`
  padding: 20px;
  text-align: center;
`

// Component
class SwapUserWorkoutDialog extends Component {
  state = {
    isLoading: false,
    workouts: [],
  }

  // Lifecycle methods
  componentDidMount() {
    this.loadWorkouts()
  }

  // Helper methods
  loadWorkouts = async () => {
    this.setState({ isLoading: true })

    const { userWorkoutData } = this.props
    const workouts = await invokeAPIcall({ uri: 'api/workoutVideos' })
    this.setState({
      workouts: workouts.filter(item => item.ShowInChallengePhase == userWorkoutData.challengePhase),
      isLoading: false,
    })
  }

  // UI event methods
  handleWorkoutSelection = async newlySelectedWorkoutId => {
    const { userWorkoutData } = this.props
    // invoke API call
    try {
      const responseData = await invokeAPIcall({
        uri: `api/userWorkouts/${userWorkoutData.userWorkoutId}`,
        data: { selectedWorkoutId: newlySelectedWorkoutId },
        method: 'PUT',
      })

      // if API success
      if (responseData.Key === true) {
        const { handleClose } = this.props
        handleClose(true)
      }
      // if failed
      else {
        alert(responseData.Value || 'התרחשה שגיאה')
      }
    } catch (error) {
      console.log('error in adding/updating userWorkout', error)
    }
  }

  // Render methods
  renderWorkoutItem = workout => {
    const { userWorkoutData } = this.props
    const isSelected = workout.VideoID === userWorkoutData.workoutVideoId
    return (
      <ListItem
        button
        key={workout.VideoID}
        selected={isSelected}
        onClick={isSelected ? null : this.handleWorkoutSelection.bind(this, workout.VideoID)}
      >
        <ListItemAvatar>
          <Avatar>
            <WorkoutThumbnailImg youtubeCode={workout.YoutubeCode} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={workout.Name} />
        <ListItemSecondaryAction>{isSelected && <CheckIcon />}</ListItemSecondaryAction>
      </ListItem>
    )
  }
  render() {
    const { handleClose, isTrialUser } = this.props
    const { workouts, isLoading } = this.state
    return (
      <Dialog onClose={handleClose} open>
        <StyledDialogTitle id="simple-dialog-title" disableTypography>
          <Typography variant="h6">החלף אימון</Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <StyledList>{workouts.map(this.renderWorkoutItem)}</StyledList>
        {workouts.length === 0 && !isLoading && <EmptyLabel>אין אימונים במאגר</EmptyLabel>}
        {isLoading && (
          <LoadingIndicatorWrapper>
            <CircularProgress />
          </LoadingIndicatorWrapper>
        )}
        {workouts.length > 0 && isTrialUser && (
          <TrialUserLayer>
            <>
              <LockIconWrapper title="ללקוחות פרימיום">
                <LockIcon />
              </LockIconWrapper>
              <div>מהפך ב-90 יום</div>
              <div>בריא יותר, חזק יותר, מאושר יותר</div>
              <Link to="/challengePayment">
                <StyledButton variant="contained" color="secondary" size="large">
                  לתוכנית הפרימיום
                </StyledButton>
              </Link>
            </>
          </TrialUserLayer>
        )}
      </Dialog>
    )
  }
}

export default withRouter(SwapUserWorkoutDialog)
