import { connect } from 'react-redux'
import ContactUsScreen from '../components/ContactUsScreen'
import { openSnackbar } from '../bundles/snackbar'

export default connect(
  state => ({
    currentUser: state.currentUser.user,
  }),
  {
    openSnackbar,
  },
)(ContactUsScreen)
