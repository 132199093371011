import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import EcoIcon from '@material-ui/icons/Eco'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import { mediaQuery } from '../../services/global'
import { getString } from '../../services/presetStringsHelper'
import carouselBg from '../../images/ourTrainers1.jpg'

// Style
const TrainersCarouselItemWrapper = styled.div`
  color: ${colorPalette.white.main};
  position: relative;
  z-index: 40;
  display: flex;
  flex-direction: column;
  ${mediaQuery.phone`
    height: auto;
  `}
`
const MainPhoto = styled.div`
  background-color: ${colorPalette.white.main};
  background-image: url(${carouselBg});
  background-size: cover;
  background-position: 0 68%;
  background-repeat: no-repeat;
  height: 550px;
  width: 100%;
  border-bottom-left-radius: ${globalStyles.mainBoxRadius};
  border-bottom-right-radius: ${globalStyles.mainBoxRadius};
  position: relative;
  z-index: 14;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0 20px;
  ${mediaQuery.phone`
    height: 260px;
    background-position: center;
  `}
`
const ContentBox = styled.div`
  background-color: ${colorPalette.primary1.main};
  border-bottom-left-radius: ${globalStyles.mainBoxRadius};
  border-bottom-right-radius: ${globalStyles.mainBoxRadius};
  box-shadow: ${globalStyles.mainBoxShadow};
  position: relative;
  top: -30px;
  display: flex;
  justify-content: space-between;
  width: auto;
  padding: 0 10px;
  padding-top: 30px;
  flex-direction: column;
`
const ContentBoxInner = styled.div`
  max-width: 790px;
  margin: 0 auto;
`
const PromotionTitle = styled.h2`
  font-size: 37px;
  font-weight: 500;
  margin: 20px 0;
  text-align: center;
  ${mediaQuery.phone`
    font-size: 25px;
  `}
`
const PromotionTitleFirst = styled(PromotionTitle)`
  color: ${colorPalette.white.main};
`
const PromotionSubTitle = styled.h4`
  font-size: 24px;
  font-weight: 400;
  margin-top: 10px;
  max-width: 100%;
  ${mediaQuery.phone`
    font-size: 1.2rem;
    text-shadow: none;
  `}
`
const PromotionSubTitleSecond = styled(PromotionSubTitle)`
  margin: 0;
`
const TitlesSection = styled.div`
  padding: 0 20px;
`
const PromotionItemsList = styled.ul`
  padding: 20px;
  padding-top: 0;
  font-size: 22px;
  ${mediaQuery.phone`
    font-size: 16px;
  `}
`
const PromotionItem = styled.li`
  span {
    display: none;
    font-size: 14px;
  }
  &:hover span {
    display block;
  }
  ${mediaQuery.phone`
    ${props => (props.hideInMobile ? 'display: none;' : '')}
  `}
  transition: transform 1s, opacity 3s;
  transition-delay: ${props => 0 + props.index * 0.1}s;
  transform: ${props => (props.isActive ? 'translateX(0%)' : 'translateX(100%)')};
  opacity: ${props => (props.isActive ? '1' : '0')};
  margin: 10px 0;
`
const ItemTitle = styled.div`
  display: flex;
  align-items: center;
  ${mediaQuery.phone`
    align-items: flex-start;
  `}
`
const StyledDoneOutlineIcon = styled(EcoIcon)`
  && {
    width: 22px;
    margin-left: 10px;
  }
`
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
`
const StyledButton = styled(Button)`
  && {
    margin-bottom: 30px;
    padding: 6px 35px;
    font-size: 20px;
  }
`

// Component
class TrainersCarouselItem extends Component {
  state = { showPromotionItems: true }

  // Lifecycle methods
  componentDidMount() {
    if (this.props.isActive) {
      window.setTimeout(() => {
        //this.setState({ showPromotionItems: true })
      }, 800)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.isActive && nextProps.isActive) {
      window.setTimeout(() => {
        //this.setState({ showPromotionItems: true })
      }, 800)
    }
  }

  // Render methods
  render() {
    const { showPromotionItems } = this.state
    return (
      <TrainersCarouselItemWrapper>
        <MainPhoto></MainPhoto>
        <ContentBox>
          <ContentBoxInner>
            <TitlesSection>
              <PromotionTitleFirst>{getString('TrainersCarouselItemTitle')}</PromotionTitleFirst>
            </TitlesSection>
            <PromotionItemsList>
              <PromotionItem index={0} isActive={showPromotionItems}>
                <ItemTitle>
                  <StyledDoneOutlineIcon />
                  {getString('OurTrainersCarouselItemListItem1')}
                </ItemTitle>
              </PromotionItem>
              <PromotionItem index={1} isActive={showPromotionItems}>
                <ItemTitle>
                  <StyledDoneOutlineIcon />
                  {getString('OurTrainersCarouselItemListItem2')}
                </ItemTitle>
              </PromotionItem>
              <PromotionItem index={2} isActive={showPromotionItems}>
                <ItemTitle>
                  <StyledDoneOutlineIcon />
                  {getString('OurTrainersCarouselItemListItem3')}
                </ItemTitle>
              </PromotionItem>
              <PromotionItem index={3} isActive={showPromotionItems}>
                <ItemTitle>
                  <StyledDoneOutlineIcon />
                  {getString('OurTrainersCarouselItemListItem4')}
                </ItemTitle>
              </PromotionItem>
              <PromotionItem index={4} isActive={showPromotionItems}>
                <ItemTitle>
                  <StyledDoneOutlineIcon />
                  {getString('OurTrainersCarouselItemListItem5')}
                </ItemTitle>
              </PromotionItem>
              <PromotionItem index={5} isActive={showPromotionItems}>
                <ItemTitle>
                  <StyledDoneOutlineIcon />
                  {getString('OurTrainersCarouselItemListItem6')}
                </ItemTitle>
              </PromotionItem>
            </PromotionItemsList>
            <ButtonsWrapper>
              <Link to="/store/1">
                <StyledButton variant="contained" color="secondary">
                  {getString('OurTrainersCarouselItemButton')}
                </StyledButton>
              </Link>
            </ButtonsWrapper>
          </ContentBoxInner>
        </ContentBox>
      </TrainersCarouselItemWrapper>
    )
  }
}

export default TrainersCarouselItem
