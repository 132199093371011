import React, { Component } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import draftToHtml from 'draftjs-to-html'
import { Helmet } from 'react-helmet'
import backgroundImage from '../../images/dumbblesBg.jpg'
import { mediaQuery, siteName } from '../../services/global'
import { invokeAPIcall } from '../../services/rest'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import Breadcrumbs from '../Breadcrumbs'

// Consts

// Style
const PageWrapper = styled.div`
  background-color: ${colorPalette.white.main};
  background-image: url(${backgroundImage});
  background-size: 60% auto;
  background-position: top right;
  background-repeat: no-repeat;
  padding: 20px;
  flex: 1;
  ${mediaQuery.phone`
    background: none;
  `}
`
const ArticleItem = styled.div`
  width: 42%;
  margin-right: auto;
  margin-left: auto;
  padding: 20px;
  background-color: ${colorPalette.white.main};
  color: ${colorPalette.black.main};
  a {
    color: ${colorPalette.black.main};
  }
  ${globalStyles.boxShadow}
  ${globalStyles.boxRadius}
  ${mediaQuery.phone`
    width: 95%;
    margin: 0;
  `}
  p, span {
    font-family: 'OpenSansHebrew', sans-serif !important;
    font-size: 16px !important;
    color: ${colorPalette.black.main} !important;
  }
`
const Title = styled.div`
  font-size: 34px;
  font-weight: 500;
  margin-bottom: 20px;
`
const Text = styled.div`
  p {
  }
  ${mediaQuery.phone`
    img {
      width: 100% !important;
    }
  `}
`

// Component
class ArticlesScreen extends Component {
  state = {
    articleData: null,
  }

  // Lifecycle methods
  async componentDidMount() {
    const { match } = this.props
    const { params } = match
    const articleData = await invokeAPIcall({ uri: `api/articles/${params.id}` })
    try {
      articleData.Text = draftToHtml(JSON.parse(articleData.Text))
      articleData.Text = articleData.Text.replaceAll('"text-align:none;"', '"text-align:center;"')
    } catch (e) {}

    this.setState({ articleData })
  }

  // Helper methods

  // UI event methods

  // Render methods
  render() {
    const { articleData } = this.state
    if (!articleData) {
      return null
    }
    return (
      <PageWrapper>
        <Helmet>
          <title>
            {siteName} - {articleData.Title}
          </title>
        </Helmet>
        <Breadcrumbs
          previousItems={[
            {
              label: 'מאמרים',
              url: `/articles`,
            },
          ]}
          currentItem={articleData.Title}
        />
        <ArticleItem>
          <Title>{articleData.Title}</Title>
          <Text dangerouslySetInnerHTML={{ __html: articleData.Text }} />
        </ArticleItem>
      </PageWrapper>
    )
  }
}

export default withRouter(ArticlesScreen)
