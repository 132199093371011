import React, { Component } from 'react'
import styled from 'styled-components'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import { invokeAPIcall } from '../../services/rest'
import FeedbackMessagesViewer from './FeedbackMessagesViewer'

// Style
const FeedbackMessagesBottomBar = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  top: -${props => props.customTop || 58}px;
  min-height: 130px;
  z-index: 12;
  background: ${colorPalette.primary1.main};
  border-bottom-left-radius: ${globalStyles.mainBoxRadius};
  border-bottom-right-radius: ${globalStyles.mainBoxRadius};
  box-shadow: ${globalStyles.mainBoxShadow};
`

// Component
class FeedbackCarouselItem extends Component {
  state = { feedbackMessages: [] }

  // Lifecycle methods
  componentDidMount() {
    this.loadFeedbackMessages()
  }

  // Helper methods
  loadFeedbackMessages = async () => {
    const feedbackMessages = await invokeAPIcall({ uri: 'api/feedbackMessages' })
    this.setState({ feedbackMessages: feedbackMessages.filter(item => item.IsShownInHomePage) })
  }

  // Render methods
  render() {
    const { customTop } = this.props
    const { feedbackMessages } = this.state
    return (
      <FeedbackMessagesBottomBar customTop={customTop}>
        {feedbackMessages && feedbackMessages.length > 0 && <FeedbackMessagesViewer feedbackMessages={feedbackMessages} />}
      </FeedbackMessagesBottomBar>
    )
  }
}

export default FeedbackCarouselItem
