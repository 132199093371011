import React, { Component } from 'react'
import styled from 'styled-components'
import { Formik, Form, FieldArray } from 'formik'
import { Link, withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress'
import formBackgroundLeft from '../../images/formBackground_left.jpg'
import formBackgroundRight from '../../images/formBackground_right.jpg'
import { mediaQuery, keysToLowerCase, photoUrlPrefix } from '../../services/global'
import { requiredValidation } from '../../services/formValidation'
import { invokeAPIcall } from '../../services/rest'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import StyledTextField from '../StyledTextField'
import StyledSelect from '../StyledSelect'
import StyledCheckbox from '../StyledCheckbox'
import Breadcrumbs from '../Breadcrumbs'

// Style
const PageWrapper = styled.div`
  background-color: #f5fcfd;
  background: url(${formBackgroundLeft}) left bottom no-repeat, url(${formBackgroundRight}) right bottom no-repeat, #f7fdfd;
  background-size: 40% auto, 35% auto;
  padding: 20px;
  flex: 1;
  ${mediaQuery.phone`
    background: none;
  `}
`
const FormWrapper = styled.div`
  background-color: ${colorPalette.white.main};
  max-width: 680px;
  margin: 0 auto;
  padding: 20px;
  color: ${colorPalette.primary1.main};
  ${globalStyles.boxShadow}
  ${globalStyles.boxRadius}
`
const PageTitle = styled.h1`
  margin-bottom: 20px;
`
const FieldsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
`
const FieldCell = styled.div`
  flex: 0 0 100%;
`
const PriceFieldCell = styled(FieldCell)`
  flex: 0 0 48%;
  ${mediaQuery.phone`
    flex: 0 0 100%;
  `}
`
const DisplayPriceFieldCell = styled(FieldCell)`
  flex: 0 0 48%;
  ${mediaQuery.phone`
    flex: 0 0 100%;
  `}
`
const PhotoFieldCell = styled(FieldCell)`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  ${mediaQuery.phone`
    flex-direction: column;
  `}
`
const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  margin-top: 20px;
  ${mediaQuery.phone`
    width: 100%;
  `}
`
const StyledButton = styled(Button)`
  && {
  }
`
const ProductPhoto = styled.div`
  width: 150px;
  height: 150px;
  background-image: url('${props => props.photoUrl}');
  background-position: center;
  background-size: cover;
  margin: auto 5px;
  ${mediaQuery.phone`
    margin: 5px;
  `}
`
const LoadingIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5px;
`
const AddNewItemButton = styled(Button)`
  && {
    margin-top: 20px;
  }
`
const PhotoItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
  border-bottom: 1px solid ${colorPalette.primary1.light};
  ${mediaQuery.phone`
    flex-direction: column;
  `}
`

// Component
class AddProductScreen extends Component {
  state = {
    initialValues: {
      name: '',
      description: '',
      keywords: '',
      price: null,
      listedPrice: null,
      displayPricePerItem: null,
      displayPricePerItemWord: null,
      shipmentFee: null,
      quickShipmentFee: null,
      productCategoryId: null,
      isHighlighted: false,
      urlCode: '',
      productPhotos: [],
    },
    addedPhotoFile: null,
  }

  // Lifecycle methods
  componentDidMount() {
    const editModeId = this.getEditModeId()
    if (editModeId != null) {
      this.loadProductData(editModeId)
    }
  }

  // Helper methods
  getEditModeId = () => {
    const { match } = this.props
    const { params } = match
    return params.id
  }

  loadProductData = async id => {
    const productDataOriginal = await invokeAPIcall({ uri: `api/products/${id}` })
    let productData = keysToLowerCase(JSON.parse(JSON.stringify(productDataOriginal)))
    productData = {
      ...productData,
      productCategoryId: productDataOriginal.ProductCategoryID,
      productPhotos: productDataOriginal.ProductPhotos,
    }
    this.setState({
      initialValues: productData,
    })
  }

  validate = values => {
    let errors = {}

    if (requiredValidation(values.name)) {
      errors.name = requiredValidation(values.name)
    }
    if (requiredValidation(values.description)) {
      errors.description = requiredValidation(values.description)
    }
    if (requiredValidation(values.price)) {
      errors.price = requiredValidation(values.price)
    }
    if (requiredValidation(values.productCategoryId)) {
      errors.productCategoryId = requiredValidation(values.productCategoryId)
    }
    if (requiredValidation(values.urlCode)) {
      errors.urlCode = requiredValidation(values.urlCode)
    }
    if (values.productPhotos.length === 0) {
      errors.productPhotos = requiredValidation(values.urlCode)
    }

    return errors
  }

  // UI event methods
  handleSubmit = async (values, { setSubmitting }) => {
    const { initialValues } = this.state
    const editModeId = this.getEditModeId()
    const isEditMode = editModeId != null
    const { history } = this.props
    const data = {
      ...values,
    }

    // invoke API call
    try {
      const responseData = await invokeAPIcall({
        uri: isEditMode ? `api/products/${editModeId}` : 'api/products',
        data,
        method: isEditMode ? 'PUT' : 'POST',
      })

      // if API success
      if (responseData.Key === true) {
        // for EditMode only - check which photos have been deleted
        if (isEditMode) {
          const photosThatWereDeleted = initialValues.productPhotos
            .filter(photo => values.productPhotos.filter(x => JSON.stringify(x) === JSON.stringify(photo)).length === 0)
            .map(photo => photo.ProductPhotoID)

          await invokeAPIcall({
            uri: 'api/products/DeleteUploadedPhotos',
            data: photosThatWereDeleted,
            method: 'POST',
          })
        }

        const uploadedPhotos = document.querySelectorAll('input[type="file"]')

        // loop through all uploaded photos
        for (const uploadedFile of uploadedPhotos) {
          // upload photo of product
          const files = uploadedFile.files
          if (files.length > 0) {
            const uploadData = new FormData()
            uploadData.append('file', files[0])
            uploadData.append('productId', responseData.ProductID)
            try {
              const uploadPhotoResponse = await fetch('https://www.fitthybody.com/fitthybodyAPI/api/products/UploadPhoto', {
                method: 'POST',
                body: uploadData,
              })
              const uploadPhotoResponseJson = await uploadPhotoResponse.json()
              if (uploadPhotoResponseJson.Key === false) {
                alert(uploadPhotoResponseJson.Value)
              }
            } catch (error) {
              alert('שגיאה בהעלאת התמונה שנבחרה')
            }
          }
        }

        history.push('/productsAdmin')
      }
      // if failed
      else {
        alert(responseData.Value || 'התרחשה שגיאה')
      }
    } catch (error) {
      console.log('error in adding/updating new product', error)
    }
    setSubmitting(false)
  }

  handlePhotoFileChange = e => {
    this.setState({ addedPhotoFile: e.target.value })
  }

  // Render methods
  render() {
    const { initialValues } = this.state
    const editModeId = this.getEditModeId()
    const isEditMode = editModeId != null

    return (
      <PageWrapper>
        <Breadcrumbs
          previousItems={[
            {
              label: `מסכי ניהול`,
              url: `/adminPanel`,
            },
          ]}
          currentItem={`ניהול מוצרים`}
        />
        <FormWrapper>
          <PageTitle>{isEditMode ? 'עדכון מוצר' : 'הוספת מוצר חדש'}</PageTitle>
          <Formik validate={this.validate} initialValues={initialValues} onSubmit={this.handleSubmit} enableReinitialize={true}>
            {formikBag => {
              const { isSubmitting, values, errors, touched, handleChange, handleBlur, isValid, setFieldValue } = formikBag
              return (
                <Form>
                  <FieldsRow>
                    <FieldCell>
                      <StyledTextField
                        label={'שם מוצר'}
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.name && touched.name && errors.name}
                        error={errors.name && touched.name}
                        margin="normal"
                        fullWidth
                      />
                    </FieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <PriceFieldCell>
                      <StyledTextField
                        label={'מחיר'}
                        name="price"
                        value={values.price}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.price && touched.price && errors.price}
                        error={errors.price && touched.price}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: values.price }}
                      />
                    </PriceFieldCell>
                    <PriceFieldCell>
                      <StyledTextField
                        label={'מחיר מחירון'}
                        name="listedPrice"
                        value={values.listedPrice}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText="(ניתן להשאיר ריק)"
                        error={errors.listedPrice && touched.listedPrice}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: values.listedPrice != null }}
                      />
                    </PriceFieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <PriceFieldCell>
                      <StyledTextField
                        label={'דמי משלוח (רגיל)'}
                        name="shipmentFee"
                        value={values.shipmentFee}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText="(ניתן להשאיר ריק)"
                        error={errors.shipmentFee && touched.shipmentFee}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: values.shipmentFee != null }}
                      />
                    </PriceFieldCell>
                    <PriceFieldCell>
                      <StyledTextField
                        label={'דמי משלוח (מהיר)'}
                        name="quickShipmentFee"
                        value={values.quickShipmentFee}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText="(ניתן להשאיר ריק)"
                        error={errors.quickShipmentFee && touched.quickShipmentFee}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: values.quickShipmentFee != null }}
                      />
                    </PriceFieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <DisplayPriceFieldCell>
                      <StyledTextField
                        label={'מחיר שיוצג לפריט'}
                        name="displayPricePerItem"
                        value={values.displayPricePerItem}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText="(ניתן להשאיר ריק)"
                        error={errors.displayPricePerItem && touched.displayPricePerItem}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: values.displayPricePerItem != null }}
                      />
                    </DisplayPriceFieldCell>
                    <DisplayPriceFieldCell>
                      <StyledTextField
                        label={'תיאור מחיר שיוצג לפריט'}
                        name="displayPricePerItemWord"
                        value={values.displayPricePerItemWord}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText="(למשל: ׳לאדם לאימון׳, ניתן להשאיר ריק)"
                        error={errors.displayPricePerItemWord && touched.displayPricePerItemWord}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: values.displayPricePerItemWord != null }}
                      />
                    </DisplayPriceFieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <FieldCell>
                      <StyledSelect
                        selectParams={{
                          name: 'productCategoryId',
                          value: values.productCategoryId,
                          onChange: handleChange,
                          onBlur: handleBlur,
                          margin: 'normal',
                          fullWidth: true,
                          inputProps: {
                            name: 'productCategoryId',
                            id: 'productCategoryId-simple',
                          },
                        }}
                        label="קטגוריה"
                        htmlFor="productCategoryId-simple"
                        helperText={errors.productCategoryId && touched.productCategoryId && errors.productCategoryId}
                        error={errors.productCategoryId && touched.productCategoryId}
                        inputLabelShrink={values.productCategoryId}
                      >
                        <MenuItem value={'1'}>מאמנים אישיים</MenuItem>
                        <MenuItem value={'2'}>מוצרים בשבילך</MenuItem>
                      </StyledSelect>
                    </FieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <FieldCell>
                      <StyledTextField
                        label={'מילות מפתח'}
                        name="keywords"
                        value={values.keywords}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.keywords && touched.keywords && errors.keywords}
                        error={errors.keywords && touched.keywords}
                        margin="normal"
                        fullWidth
                        placeholder="מופרד בפסיקים"
                      />
                    </FieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <FieldCell>
                      <StyledTextField
                        label={'טקסט שיופיע ב-URL'}
                        name="urlCode"
                        value={values.urlCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText="חובה מילה באנגלית ללא רווחים, למשל TrainingPackage"
                        error={errors.urlCode && touched.urlCode}
                        margin="normal"
                        fullWidth
                      />
                    </FieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <FieldCell>
                      <StyledCheckbox
                        label={'האם מוצר דגל?'}
                        name="isHighlighted"
                        value={values.isHighlighted}
                        checked={values.isHighlighted}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.isHighlighted && touched.isHighlighted && errors.isHighlighted}
                        error={errors.isHighlighted && touched.isHighlighted}
                        margin="normal"
                        fullWidth
                      />
                    </FieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <FieldCell>
                      <StyledTextField
                        label={'תיאור מלא'}
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.description && touched.description && errors.description}
                        error={errors.description && touched.description}
                        margin="normal"
                        fullWidth
                        multiline
                        rows="4"
                      />
                    </FieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <PhotoFieldCell>
                      <div>
                        <div>תמונת מוצר:</div>
                        <FieldArray
                          name="productPhotos"
                          render={arrayHelpers => (
                            <div>
                              {values.productPhotos && values.productPhotos.length > 0
                                ? values.productPhotos.map((photo, index) => {
                                    //const randomNum = Math.floor(Math.random() * 100)
                                    let photoUrl = null
                                    // for edit mode
                                    if (isEditMode) {
                                      photoUrl = photo.PhotoURL
                                      if (photoUrl && !photoUrl.includes('http')) {
                                        photoUrl = `${photoUrlPrefix}/productPhotos/${photoUrl}`
                                      }
                                    }
                                    // if the user uploaded a photo
                                    const elementSelector = `input[type="file"][data-index="${index}"]`
                                    const files = document.querySelector(elementSelector) && document.querySelector(elementSelector).files
                                    if (files && files.length > 0) {
                                      photoUrl = URL.createObjectURL(files[0])
                                    }

                                    return (
                                      <PhotoItemWrapper key={photo.ProductPhotoID || photo.id}>
                                        <div>
                                          <input
                                            type="file"
                                            data-index={index}
                                            onChange={e => {
                                              if (e.target.files && e.target.files.length > 0) {
                                                // more than 4MB
                                                if (e.target.files[0].size > 4000000) {
                                                  alert('משקל הקובץ גדול מדי: ' + e.target.files[0].size)
                                                  return false
                                                }
                                              }
                                              arrayHelpers.replace(index, {
                                                ...photo,
                                                uploadedPhotoFile: e.target.value,
                                              })
                                              //setFieldValue('addedPhoto', true, false)
                                            }}
                                          />
                                        </div>
                                        {photoUrl && <ProductPhoto photoUrl={photoUrl} />}
                                        <Button
                                          variant="contained"
                                          color="secondary"
                                          size="small"
                                          onClick={() => arrayHelpers.remove(index)}
                                        >
                                          הסר
                                        </Button>
                                      </PhotoItemWrapper>
                                    )
                                  })
                                : null}
                              <AddNewItemButton
                                variant="contained"
                                color="secondary"
                                size="small"
                                onClick={() =>
                                  arrayHelpers.insert(values.productPhotos.length, { id: `newFile_${values.productPhotos.length}` })
                                }
                              >
                                {values.productPhotos.length > 0 ? 'עוד תמונה...' : 'הוסף תמונה'}
                              </AddNewItemButton>
                            </div>
                          )}
                        />
                      </div>
                    </PhotoFieldCell>
                  </FieldsRow>
                  <ButtonsWrapper>
                    <StyledButton variant="contained" color="primary" fullWidth disabled={!isValid || isSubmitting} type="submit">
                      {isEditMode ? 'עדכן' : 'הוסף'}
                    </StyledButton>
                  </ButtonsWrapper>
                  {isSubmitting && (
                    <LoadingIndicatorWrapper>
                      <CircularProgress />
                    </LoadingIndicatorWrapper>
                  )}
                </Form>
              )
            }}
          </Formik>
        </FormWrapper>
      </PageWrapper>
    )
  }
}

export default withRouter(AddProductScreen)
