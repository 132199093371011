import React, { Component } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import { withRouter } from 'react-router-dom'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import formBackgroundLeft from '../../images/formBackground_left.jpg'
import formBackgroundRight from '../../images/formBackground_right.jpg'
import { mediaQuery, photoUrlPrefix } from '../../services/global'
import { invokeAPIcall } from '../../services/rest'
import { requiredValidation, emailValidation, phoneNumValidation } from '../../services/formValidation'
import { getProductPrices, calcMaxNumOfMonthlyInstallmentsToAllow } from '../../services/pricesHelper'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import StyledSelect from '../StyledSelect'
import StyledTextField from '../StyledTextField'
import Breadcrumbs from '../Breadcrumbs'
import ConfirmDialog from '../ConfirmDialog'

// Consts

// Style
const PageWrapper = styled.div`
  background-color: #f5fcfd;
  background: url(${formBackgroundLeft}) left bottom no-repeat, url(${formBackgroundRight}) right bottom no-repeat, #f7fdfd;
  background-size: 40% auto, 35% auto;
  padding: 20px;
  flex: 1;
  ${mediaQuery.phone`
    background: none;
  `}
`
const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 90%;
  margin: auto;
  ${mediaQuery.tablet`
    flex-direction: column;
  `}
`
const ProductListWrapper = styled.div`
  flex: 7;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mediaQuery.tablet`
    width: 100%;
  `}
`
const ProductList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  background-color: ${colorPalette.white.main};
  color: ${colorPalette.primary1.main};
  ${globalStyles.boxShadow}
  ${globalStyles.boxRadius}
  width: 100%;
`
const ProductItem = styled.li`
  position: relative;
  border-top: 1px solid ${colorPalette.primary1.main};
  display: flex;
  align-items: center;
  padding: 20px;
  ${mediaQuery.phone`
    flex-direction: column;
  `}
`
const ProductPhoto = styled.div`
  width: 150px;
  height: 150px;
  background-color: ${colorPalette.tertiary1.main};
  background-image: url('${props => props.photoUrl}');
  background-position: center;
  background-size: cover;
  margin: auto;
`
const ProductName = styled.div`
  flex: 1;
  margin-right: 20px;
  font-size: 20px;
  margin-left: 8px;
`
const ProductPrice = styled.div`
  flex: 0 0 90px;
  ${mediaQuery.phone`
    flex: 0 0 auto;
  `}
`
const QuantitySelector = styled.div`
  flex: 0 0 90px;
  .MuiFormControl-root {
    margin: 0;
  }
  .MuiSelect-root {
    padding: 7.5px 24px;
  }
  ${mediaQuery.phone`
    flex: 0 0 auto;
  `}
`
const ProductSum = styled.div`
  flex: 0 0 90px;
  ${mediaQuery.phone`
    margin-top: 10px;
    flex: 0 0 auto;
  `}
`
const ProductSumLabel = styled.span`
  display: none;
  ${mediaQuery.phone`
    display: inline;
  `}
`
const RemoveButton = styled.div`
  cursor: pointer;
  font-size: 14px;
  color: ${colorPalette.black.main};
  text-decoration: underline;
  display: inline-block;
`
const ProductListHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
`
const ProductNameHeader = styled.div`
  flex: 1;
`
const ProductPriceHeader = styled.div`
  flex: 0 0 90px;
  ${mediaQuery.phone`
    display: none;
  `}
`
const ProductQuantityHeader = styled.div`
  flex: 0 0 90px;
  ${mediaQuery.phone`
    display: none;
  `}
`
const ProductSumHeader = styled.div`
  flex: 0 0 90px;
  ${mediaQuery.phone`
    display: none;
  `}
`
const EmptyCartLabel = styled.div`
  text-align: center;
  margin-top: 40px;
  font-size: 30px;
`
const SummaryWrapper = styled.div`
  flex: 3;
  margin-right: 30px;
  ${mediaQuery.homeScreenChallengeCarouselItemLevel1`
    flex: 4;
  `}
  ${mediaQuery.tablet`
    margin-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  `}
`
const OrderTotalBox = styled.div`
  padding: 20px;
  background-color: ${colorPalette.white.main};
  color: ${colorPalette.primary1.main};
  ${globalStyles.boxShadow}
  ${globalStyles.boxRadius}
  ${mediaQuery.tablet`
    margin: 0 auto;
    margin-top: 20px;
    min-width: 460px;
  `}
  ${mediaQuery.phone`
    width: 100%;
    min-width: 0;
  `}
`
const OrderFormBox = styled(OrderTotalBox)`
  margin-top: 20px;
`
const GoToPaymentButton = styled(Button)`
  && {
    font-size: 22px;
    line-height: 1.4;
    margin-top: 30px;
    ${mediaQuery.phone`
      font-size: 16px;
    `}
  }
`
export const PaymentOverlayButton = styled(GoToPaymentButton)`
  && {
    margin-top: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
`
const EditCartButton = styled(Button)`
  && {
    margin-top: 30px;
    ${mediaQuery.phone`
      font-size: 16px;
    `}
  }
`
const FieldCell = styled.div`
  display: ${props => (props.hidden ? 'none' : 'block')};
`
const CouponFieldCell = styled(FieldCell)`
  flex: 4;
`
const CouponRow = styled.div`
  display: flex;
  align-items: center;
  display: ${props => (props.hidden ? 'none' : 'block')};
`
const ApplyCouponButton = styled(Button)`
  && {
    font-size: 13px;
    line-height: 1;
    flex: 1;
    margin-right: 5px;
  }
`
const LoadingIndicatorWrapper = styled.div`
  margin: 50px auto;
  display: flex;
  justify-content: center;
`
export const PayPalButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 52px;
  form {
    position: absolute;
    z-index: 2;
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    input[type='image'] {
      width: 100%;
      height: 100%;
    }
  }
`
export const CreditCardButtonWrapper = styled(PayPalButtonWrapper)``
const OrderTotalLabel = styled.h3``
export const OrderTotalDetails = styled.div`
  border-bottom: 1px solid ${colorPalette.primary1.light};
  margin-bottom: 20px;
  padding-bottom: 20px;
`
export const OrderTotalDetailsItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
`
export const OrderTotalDetailsItemLabel = styled.div``
export const OrderTotalDetailsItemValue = styled.div`
  ${props =>
    props.bold
      ? `
  font-weight: 600;
  font-size: 18px;
  `
      : ''}
`
const IncludeShipmentSelector = styled.div`
  margin-top: 30px;
  .MuiFormControl-root {
    margin: 0;
    width: 100%;
    .MuiOutlinedInput-root {
      background: ${colorPalette.primary2.main};
    }
  }
  .MuiSelect-root {
    padding: 7.5px 24px;
  }
`
export const MonthlyInstallmentsSelector = styled(IncludeShipmentSelector)`
  .MuiFormControl-root {
    .MuiOutlinedInput-root {
      background: ${colorPalette.white.main};
    }
  }
`
export const PelecardIframeWrapper = styled.div`
  position: relative;
  min-height: 100px;
  width: 100%;
  background-color: ${colorPalette.white.main};
  color: ${colorPalette.primary1.main};
  ${globalStyles.boxShadow}
  ${globalStyles.boxRadius}
  iframe {
    position: relative;
    width: 100%;
    min-height: 548px;
    margin: 8px auto;
  }
  @media (max-width: 1400px) {
    iframe {
      min-height: 645px;
    }
  }
`
export const PelecardLoadingIndicator = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 50px;
  display: flex;
  justify-content: center;
`

// Component
class ShoppingCartScreen extends Component {
  state = {
    productsInCartData: [],
    isFetchingProducts: false,
    showPaymentPersonalDetails: false,
    showPayPalButton: false,
    appliedCoupon: null,
    globalSettingsData: {},
    monthlyInstallmentsLevels: [],
    orderId: null,
    includeShipmentValue: 'quick',
    monthlyInstallmentsValue: 1,
    pelecardPaymentIframeUrl: null,
    showRemoveProductDialogForProductId: null,
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      invoiceName: '',
      street: '',
      city: '',
      postalCode: '',
    },
  }

  // Lifecycle methods
  componentDidMount() {
    this.getInfoOfProductsInCart(this.props)
    this.loadGlobalSettingsData()
    this.loadMonthlyInstallmentsLevels()

    this.prefillUserDataToForm()

    // load 'couponCode' from the sessionStorage (if there is one)
    const couponCode = sessionStorage.getItem('couponCode')
    if (couponCode) {
      this.loadCouponData(couponCode, false)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.productsInCart.length !== nextProps.productsInCart.length) {
      this.getInfoOfProductsInCart(nextProps)
    }
  }

  // Helper methods
  getInfoOfProductsInCart = async props => {
    this.setState({ isFetchingProducts: true })
    const { productsInCart } = props
    const idsParam = productsInCart.reduce((result, item, index) => {
      return `${result}ids=${item.productId}${index < productsInCart.length - 1 ? '&' : ''}`
    }, '')

    // handle the mode of 'Admin Custom Product' (with a custom value)
    if (productsInCart.length === 1) {
      const adminCustomProduct = productsInCart.filter(item => item.productId === 'adminCustom')
      if (adminCustomProduct && adminCustomProduct.length > 0) {
        const productsInCartData = [
          { Name: 'עסקה ידנית', ProductID: adminCustomProduct[0].productId, Price: adminCustomProduct[0].customPrice, ProductPhotos: [] },
        ]
        this.setState({ productsInCartData, isFetchingProducts: false })
        return
      }
    }

    const productsInCartData = await invokeAPIcall({ uri: `api/products/getByIDs?${idsParam}` })
    this.setState({ productsInCartData, isFetchingProducts: false })
  }

  validate = values => {
    let errors = {}

    if (requiredValidation(values.firstName)) {
      errors.firstName = requiredValidation(values.firstName)
    }
    if (requiredValidation(values.lastName)) {
      errors.lastName = requiredValidation(values.lastName)
    }
    if (requiredValidation(values.invoiceName)) {
      errors.invoiceName = requiredValidation(values.invoiceName)
    }
    if (requiredValidation(values.street)) {
      errors.street = requiredValidation(values.street)
    }
    if (requiredValidation(values.city)) {
      errors.city = requiredValidation(values.city)
    }
    if (requiredValidation(values.phone)) {
      errors.phone = requiredValidation(values.phone)
    } else if (phoneNumValidation(values.phone)) {
      errors.phone = phoneNumValidation(values.phone)
    }
    if (requiredValidation(values.email)) {
      errors.email = requiredValidation(values.email)
    } else if (emailValidation(values.email)) {
      errors.email = emailValidation(values.email)
    }

    return errors
  }

  prefillUserDataToForm = () => {
    const { currentUser } = this.props
    if (currentUser && currentUser.Email) {
      // pre-populate the initialValues from the logged-in user JSON
      this.setState({
        initialValues: {
          ...this.state.initialValues,
          firstName: currentUser.FirstName,
          lastName: currentUser.LastName,
          phone: currentUser.Phone,
          email: currentUser.Email,
          street: currentUser.Street,
          city: currentUser.City,
        },
      })
    }

    // if there are form values we stored in the LocalStorage (for future use) - pre-populate them too
    const initialValuesFromLocalStorageJSON = localStorage.getItem('shopping-cart-form')
    if (initialValuesFromLocalStorageJSON) {
      const initialValuesFromLocalStorage = JSON.parse(initialValuesFromLocalStorageJSON)
      if (initialValuesFromLocalStorage && initialValuesFromLocalStorage.firstName) {
        this.setState({
          initialValues: {
            ...this.state.initialValues,
            ...initialValuesFromLocalStorage,
          },
        })
      }
    }
  }

  loadCouponData = async (couponCode, showMessage) => {
    const { productsInCartData } = this.state
    const { openSnackbar } = this.props

    let couponData = await invokeAPIcall({ uri: `api/coupons/getByCouponCode?code=${couponCode}` })
    this.setState({ appliedCoupon: couponData, monthlyInstallmentsValue: 1 /* also reset the 'monthlyInstallmentsValue' */ })

    if (showMessage) {
      if (couponData !== null) {
        const { SpecificProductID, SpecificToChallenge } = couponData
        if (SpecificProductID !== null) {
          const specificProduct = productsInCartData.filter(itemInCart => itemInCart.ProductID === SpecificProductID)
          if (specificProduct.length > 0) {
            openSnackbar({ message: 'קוד קופון תקין. ההנחה בוצעה!', severity: 'success' })
          } else {
            openSnackbar({ message: 'קוד קופון תקין אך לא רלוונטי למוצרים בעגלה זו', severity: 'failure' })
          }
        } else if (SpecificToChallenge === true) {
          openSnackbar({ message: 'קוד קופון תקין אך לא רלוונטי למוצרים בעגלה זו', severity: 'failure' })
        } else {
          openSnackbar({ message: 'קוד קופון תקין. ההנחה בוצעה!', severity: 'success' })
        }
      } else {
        openSnackbar({ message: 'קוד קופון לא קיים', severity: 'failure' })
      }
    }

    // scroll to top
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  priceCalcReducer = (result, item) => {
    const { globalSettingsData } = this.state
    const { productsInCart } = this.props

    // handle the mode of 'Admin Custom Product' (with a custom value)
    if (productsInCart.length === 1) {
      const adminCustomProduct = productsInCart.filter(item => item.productId === 'adminCustom')
      if (adminCustomProduct && adminCustomProduct.length > 0) {
        return result + adminCustomProduct[0].customPrice
      }
    }

    const productDataFromCart = productsInCart.filter(itemInCart => itemInCart.productId === item.ProductID)[0]
    let quantity = 0
    if (productDataFromCart) {
      quantity = productDataFromCart.quantity
    }
    const { actualPrice } = getProductPrices(item, globalSettingsData)
    return result + actualPrice * quantity
  }

  calcOrderTotalBeforeCoupon = () => {
    const { productsInCartData } = this.state
    const orderTotal = productsInCartData.reduce(this.priceCalcReducer, 0)
    return orderTotal
  }

  calcOrderTotal = () => {
    const { productsInCartData, appliedCoupon, globalSettingsData } = this.state

    // separate calculation to 2 groups: products allow coupon, and those that don't allow
    const productsNotAllowCoupon = productsInCartData.filter(item => item.ProductCategoryID !== 2)
    const productsAllowCoupon = productsInCartData.filter(item => item.ProductCategoryID === 2)

    // calculate prices of all products
    let productsNotAllowCouponTotal = productsNotAllowCoupon.reduce(this.priceCalcReducer, 0)
    let productsAllowCouponTotal = productsAllowCoupon.reduce(this.priceCalcReducer, 0)

    // apply coupon
    if (appliedCoupon) {
      const { FixedDiscount, PercentageDiscount, SpecificProductID, SpecificToChallenge } = appliedCoupon

      // for specific product
      if (SpecificProductID !== null) {
        const specificProduct = productsInCartData.filter(itemInCart => itemInCart.ProductID === SpecificProductID)
        if (specificProduct.length > 0) {
          const { actualPrice } = getProductPrices(specificProduct[0], globalSettingsData)
          const specificProductPrice = actualPrice

          if (FixedDiscount) {
            productsAllowCouponTotal -= FixedDiscount
          } else if (PercentageDiscount) {
            productsAllowCouponTotal -= Math.floor(specificProductPrice * (PercentageDiscount / 100))
          }
        }
      }
      // only specific for 90days challenge
      else if (SpecificToChallenge === true) {
      }
      // global discount
      else {
        if (FixedDiscount) {
          productsAllowCouponTotal -= FixedDiscount
        } else if (PercentageDiscount) {
          productsAllowCouponTotal = Math.floor(productsAllowCouponTotal * ((100 - PercentageDiscount) / 100))
        }
      }

      // total can't be below 0
      if (productsAllowCouponTotal < 0) {
        productsAllowCouponTotal = 0
      }
    }

    const orderTotal = productsNotAllowCouponTotal + productsAllowCouponTotal
    return orderTotal
  }

  // check if none of the products in the cart, allows to use coupon (we allow coupon only for CategoryID=2)
  isNoProductAllowCoupon = () => {
    const { productsInCartData } = this.state
    const productsAllowCoupon = productsInCartData.filter(item => item.ProductCategoryID === 2)
    return productsAllowCoupon.length === 0
  }

  calcShipmentTotal = () => {
    const { productsInCartData } = this.state
    // calculate shipment fee of all products
    let shipmentTotal = productsInCartData.reduce((result, item) => {
      const shipmentFee = item.ShipmentFee || 0
      return result + shipmentFee
    }, 0)
    return shipmentTotal
  }

  calcShipmentTotalBasedOnType = includeShipmentValue => {
    const { productsInCartData } = this.state
    // calculate shipment fee of all products
    let shipmentTotal = productsInCartData.reduce((result, item) => {
      const shipmentFee = includeShipmentValue === 'quick' ? item.QuickShipmentFee || 0 : item.ShipmentFee || 0
      return result + shipmentFee
    }, 0)
    return shipmentTotal
  }

  getShipmentTotal = () => {
    const { includeShipmentValue } = this.state
    if (includeShipmentValue === 'none') {
      return 0
    }
    return this.calcShipmentTotalBasedOnType(includeShipmentValue)
  }

  loadGlobalSettingsData = async id => {
    let globalSettingsData = await invokeAPIcall({ uri: `api/globalSettings/1` })
    this.setState({
      globalSettingsData,
    })
  }

  loadMonthlyInstallmentsLevels = async id => {
    const monthlyInstallmentsLevels = await invokeAPIcall({ uri: 'api/monthlyInstallmentsLevels' })
    this.setState({
      monthlyInstallmentsLevels,
    })
  }

  // UI event methods
  handleRemoveProductClick = productId => {
    this.setState({ showRemoveProductDialogForProductId: productId })
  }

  handleRemoveProductDialogSubmit = () => {
    const { removeProductFromCart } = this.props
    removeProductFromCart({ productId: this.state.showRemoveProductDialogForProductId })
    this.setState({ showRemoveProductDialogForProductId: null })
  }

  handleRemoveProductDialogClose = () => {
    this.setState({ showRemoveProductDialogForProductId: null })
  }

  handleQuantityChange = (productId, e) => {
    const { updateProductQuantityInCart } = this.props
    updateProductQuantityInCart({ productId, quantity: e.target.value })
  }

  handleGoToPaymentClick = () => {
    this.setState({ showPaymentPersonalDetails: true })
  }

  handleGoBackToBeforePaymentClick = () => {
    this.setState({ showPaymentPersonalDetails: false })
  }

  handleShipmentTypeChange = e => {
    this.setState({ includeShipmentValue: e.target.value })
  }

  handleMonthlyInstallmentsChange = e => {
    this.setState({ monthlyInstallmentsValue: e.target.value })
  }

  handleGoToPayPalClick = () => {}

  handleSubmit = async (values, { setSubmitting }) => {
    const { productsInCart } = this.props
    const { includeShipmentValue, monthlyInstallmentsValue } = this.state
    this.setState({ showPayPalButton: true })
    setSubmitting(false)

    const orderTotal = this.calcOrderTotal()
    const shipmentTotal = this.getShipmentTotal()
    const orderTotalWithShipment = orderTotal + shipmentTotal
    const orderData = {
      Name: `${values.firstName} ${values.lastName}`,
      InvoiceName: values.invoiceName,
      TotalPrice: orderTotalWithShipment,
      Address: `${values.street}, ${values.city}, ${values.postalCode}`,
      Email: values.email,
      Phone: values.phone,
      ProductsList: JSON.stringify(productsInCart),
      Date: new Date(),
      IncludeShipment: includeShipmentValue !== 'none',
      ShipmentType: includeShipmentValue,
      MonthlyInstallments: monthlyInstallmentsValue,
    }
    const responseData = await invokeAPIcall({
      uri: 'api/orders',
      data: orderData,
      method: 'POST',
    })
    this.setState({ orderId: responseData.OrderID })

    // store the form values in the LocalStorage (for future use)
    localStorage.setItem('shopping-cart-form', JSON.stringify(values))
  }

  handleApplyCouponClick = couponCode => {
    this.loadCouponData(couponCode, true)
  }

  handleCreditCardPaymentClick = async () => {
    const { orderId, monthlyInstallmentsValue, appliedCoupon } = this.state
    const orderTotal = this.calcOrderTotal()
    const shipmentTotal = this.getShipmentTotal()
    const orderTotalWithShipment = orderTotal + shipmentTotal
    const couponCode = appliedCoupon && appliedCoupon.CouponCode ? appliedCoupon.CouponCode : ''
    const pelecardPaymentData = await invokeAPIcall({
      uri: `api/pelecardPayment/getPaymentUrl?orderId=${orderId}&orderTotalWithShipment=${orderTotalWithShipment}&monthlyInstallmentsNum=${monthlyInstallmentsValue}&couponCode=${couponCode}`,
    })
    this.setState({ pelecardPaymentIframeUrl: pelecardPaymentData.URL })
  }

  // Render methods
  renderProductItem = productItem => {
    const { globalSettingsData, showPaymentPersonalDetails } = this.state
    const { productsInCart } = this.props
    const productDataFromCart = productsInCart.filter(item => item.productId === productItem.ProductID)[0]
    if (!productDataFromCart) {
      return null
    }

    let photoUrl = productItem.ProductPhotos[0] && productItem.ProductPhotos[0].PhotoURL
    if (photoUrl) {
      if (!photoUrl.includes('http')) {
        photoUrl = `${photoUrlPrefix}/productPhotos/${photoUrl}`
      }
    }

    const { actualPrice } = getProductPrices(productItem, globalSettingsData)

    return (
      <ProductItem>
        <ProductPhoto photoUrl={photoUrl} />
        <ProductName>
          {productItem.Name}
          {!showPaymentPersonalDetails && (
            <div>
              <RemoveButton onClick={this.handleRemoveProductClick.bind(this, productItem.ProductID)}>הסר</RemoveButton>
            </div>
          )}
        </ProductName>
        <ProductPrice>₪{actualPrice}</ProductPrice>
        <QuantitySelector>
          <StyledSelect
            selectParams={{
              name: `${productItem.ProductID}_quantity`,
              value: productDataFromCart.quantity,
              onChange: this.handleQuantityChange.bind(this, productItem.ProductID),
              margin: 'normal',
              inputProps: {
                name: `${productItem.ProductID}_quantity`,
                id: `${productItem.ProductID}_quantity-simple`,
              },
              disabled: showPaymentPersonalDetails || productItem.ProductID === 'adminCustom',
            }}
            htmlFor={`${productItem.ProductID}_quantity-simple`}
            variant="outlined"
            fullWidth={false}
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(item => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </StyledSelect>
        </QuantitySelector>
        <ProductSum>
          <ProductSumLabel>סה״כ: </ProductSumLabel>₪{actualPrice * productDataFromCart.quantity}
        </ProductSum>
      </ProductItem>
    )
  }

  render() {
    const {
      productsInCartData,
      showPaymentPersonalDetails,
      showPayPalButton,
      initialValues,
      isFetchingProducts,
      orderId,
      includeShipmentValue,
      monthlyInstallmentsValue,
      pelecardPaymentIframeUrl,
      monthlyInstallmentsLevels,
      appliedCoupon,
      showRemoveProductDialogForProductId,
    } = this.state
    const orderTotalBeforeCoupon = this.calcOrderTotalBeforeCoupon()
    const orderTotal = this.calcOrderTotal()
    const shipmentTotal = this.getShipmentTotal()
    const orderTotalWithShipment = orderTotal + shipmentTotal
    const couponCalculatedDiscount = orderTotalBeforeCoupon - orderTotal
    const monthlyInstallmentsNum = Number(monthlyInstallmentsValue)
    const payInMonthlyInstallments = monthlyInstallmentsNum > 1
    const maxNumOfMonthlyInstallmentsToAllow = calcMaxNumOfMonthlyInstallmentsToAllow(orderTotalWithShipment, monthlyInstallmentsLevels)
    const monthlyInstallmentsToAllow = []
    for (var i = 1; i <= maxNumOfMonthlyInstallmentsToAllow; i++) {
      monthlyInstallmentsToAllow.push(i)
    }
    const couponCode = appliedCoupon && appliedCoupon.CouponCode ? appliedCoupon.CouponCode : ''

    return (
      <PageWrapper>
        <Breadcrumbs previousItems={[]} currentItem="עגלת קניות" />
        {isFetchingProducts === false && productsInCartData.length > 0 && (
          <ContentWrapper>
            <ProductListWrapper>
              {pelecardPaymentIframeUrl === null && (
                <>
                  <ProductList>
                    <ProductListHeader>
                      <ProductNameHeader>רכישות</ProductNameHeader>
                      <ProductPriceHeader>מחיר</ProductPriceHeader>
                      <ProductQuantityHeader>כמות</ProductQuantityHeader>
                      <ProductSumHeader>סכום</ProductSumHeader>
                    </ProductListHeader>
                    {productsInCartData.map(this.renderProductItem)}
                  </ProductList>
                  {showPaymentPersonalDetails && !showPayPalButton && (
                    <EditCartButton variant="contained" color="primary" size="small" onClick={this.handleGoBackToBeforePaymentClick}>
                      עריכה
                    </EditCartButton>
                  )}
                </>
              )}
              {pelecardPaymentIframeUrl !== null && (
                <PelecardIframeWrapper>
                  <PelecardLoadingIndicator>
                    <CircularProgress />
                  </PelecardLoadingIndicator>
                  <iframe width="960" height="70" src={pelecardPaymentIframeUrl} frameBorder="0" allowFullScreen allowTransparency></iframe>
                </PelecardIframeWrapper>
              )}
            </ProductListWrapper>
            <SummaryWrapper>
              <OrderTotalBox>
                <OrderTotalLabel>סיכום הזמנה</OrderTotalLabel>
                <OrderTotalDetails>
                  <OrderTotalDetailsItem>
                    <OrderTotalDetailsItemLabel>סכום רכישות:</OrderTotalDetailsItemLabel>
                    <OrderTotalDetailsItemValue>₪{orderTotalBeforeCoupon}</OrderTotalDetailsItemValue>
                  </OrderTotalDetailsItem>
                  <OrderTotalDetailsItem>
                    <OrderTotalDetailsItemLabel>משלוח:</OrderTotalDetailsItemLabel>
                    <OrderTotalDetailsItemValue>₪{shipmentTotal}</OrderTotalDetailsItemValue>
                  </OrderTotalDetailsItem>
                </OrderTotalDetails>
                {couponCalculatedDiscount > 0 && (
                  <OrderTotalDetails>
                    <OrderTotalDetailsItem>
                      <OrderTotalDetailsItemLabel>הנחת קופון:</OrderTotalDetailsItemLabel>
                      <OrderTotalDetailsItemValue>₪{couponCalculatedDiscount}-</OrderTotalDetailsItemValue>
                    </OrderTotalDetailsItem>
                  </OrderTotalDetails>
                )}
                <OrderTotalDetailsItem>
                  <OrderTotalDetailsItemLabel>סך כל ההזמנה:</OrderTotalDetailsItemLabel>
                  <OrderTotalDetailsItemValue bold>₪{orderTotalWithShipment}</OrderTotalDetailsItemValue>
                </OrderTotalDetailsItem>
                {!showPaymentPersonalDetails && (
                  <>
                    {this.calcShipmentTotal() > 0 && (
                      <IncludeShipmentSelector>
                        שיטת משלוח:
                        <StyledSelect
                          selectParams={{
                            name: `includeShipment`,
                            value: includeShipmentValue,
                            onChange: this.handleShipmentTypeChange,
                            margin: 'normal',
                            inputProps: {
                              name: `includeShipment`,
                              id: `includeShipment-simple`,
                            },
                          }}
                          htmlFor={`includeShipment-simple`}
                          variant="outlined"
                          fullWidth={false}
                        >
                          <MenuItem value="none">איסוף עצמי מתל אביב (₪0)</MenuItem>
                          <MenuItem value="regular">משלוח בדואר (₪{this.calcShipmentTotalBasedOnType('regular')})</MenuItem>
                          <MenuItem value="quick">משלוח מהיר (₪{this.calcShipmentTotalBasedOnType('quick')})</MenuItem>
                        </StyledSelect>
                      </IncludeShipmentSelector>
                    )}
                    {maxNumOfMonthlyInstallmentsToAllow > 1 && (
                      <MonthlyInstallmentsSelector>
                        כמות תשלומים:
                        <StyledSelect
                          selectParams={{
                            name: `monthlyInstallments`,
                            value: monthlyInstallmentsValue,
                            onChange: this.handleMonthlyInstallmentsChange,
                            margin: 'normal',
                            inputProps: {
                              name: `monthlyInstallments`,
                              id: `monthlyInstallments-simple`,
                            },
                          }}
                          htmlFor={`monthlyInstallments-simple`}
                          variant="outlined"
                          fullWidth={false}
                        >
                          {monthlyInstallmentsToAllow.map(item => {
                            if (item === 1) {
                              return <MenuItem value="1">תשלום אחד</MenuItem>
                            }
                            return <MenuItem value={item}>{item} תשלומים</MenuItem>
                          })}
                        </StyledSelect>
                      </MonthlyInstallmentsSelector>
                    )}
                    <GoToPaymentButton variant="contained" color="primary" fullWidth size="large" onClick={this.handleGoToPaymentClick}>
                      המשך לקופה
                    </GoToPaymentButton>
                  </>
                )}
              </OrderTotalBox>

              {showPaymentPersonalDetails && pelecardPaymentIframeUrl == null && (
                <OrderFormBox>
                  <>
                    <Formik validate={this.validate} initialValues={initialValues} onSubmit={this.handleSubmit}>
                      {formikBag => {
                        const { isSubmitting, values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue } = formikBag
                        return (
                          <Form>
                            <FieldCell hidden={showPayPalButton}>
                              <StyledTextField
                                label={'שם פרטי'}
                                name="firstName"
                                value={values.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={errors.firstName && touched.firstName && errors.firstName}
                                error={errors.firstName && touched.firstName}
                                margin="normal"
                                fullWidth
                              />
                            </FieldCell>
                            <FieldCell hidden={showPayPalButton}>
                              <StyledTextField
                                label={'שם משפחה'}
                                name="lastName"
                                value={values.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={errors.lastName && touched.lastName && errors.lastName}
                                error={errors.lastName && touched.lastName}
                                margin="normal"
                                fullWidth
                              />
                            </FieldCell>
                            <FieldCell hidden={showPayPalButton}>
                              <StyledTextField
                                label={'חשבונית על שם'}
                                name="invoiceName"
                                value={values.invoiceName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={errors.invoiceName && touched.invoiceName && errors.invoiceName}
                                error={errors.invoiceName && touched.invoiceName}
                                margin="normal"
                                fullWidth
                              />
                            </FieldCell>
                            <FieldCell hidden={showPayPalButton}>
                              <StyledTextField
                                label={'רחוב (מספר, קומה, דירה)'}
                                name="street"
                                value={values.street}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={errors.street && touched.street && errors.street}
                                error={errors.street && touched.street}
                                margin="normal"
                                fullWidth
                              />
                            </FieldCell>
                            <FieldCell hidden={showPayPalButton}>
                              <StyledTextField
                                label={'עיר'}
                                name="city"
                                value={values.city}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={errors.city && touched.city && errors.city}
                                error={errors.city && touched.city}
                                margin="normal"
                                fullWidth
                              />
                            </FieldCell>
                            <FieldCell hidden={showPayPalButton}>
                              <StyledTextField
                                label={'מיקוד'}
                                name="postalCode"
                                value={values.postalCode}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={errors.postalCode && touched.postalCode && errors.postalCode}
                                error={errors.postalCode && touched.postalCode}
                                margin="normal"
                                fullWidth
                              />
                            </FieldCell>
                            <FieldCell hidden={showPayPalButton}>
                              <StyledTextField
                                label={'טלפון'}
                                name="phone"
                                value={values.phone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={errors.phone && touched.phone && errors.phone}
                                error={errors.phone && touched.phone}
                                margin="normal"
                                fullWidth
                              />
                            </FieldCell>
                            <FieldCell hidden={showPayPalButton}>
                              <StyledTextField
                                label={'כתובת דוא"ל'}
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={errors.email && touched.email && errors.email}
                                error={errors.email && touched.email}
                                margin="normal"
                                fullWidth
                                autoComplete="email"
                              />
                            </FieldCell>
                            {!showPayPalButton && (
                              <GoToPaymentButton
                                variant="contained"
                                color="primary"
                                fullWidth
                                size="large"
                                type="submit"
                                onClick={this.handleGoToPayPalClick}
                              >
                                המשך לתשלום
                              </GoToPaymentButton>
                            )}
                            <CouponRow hidden={showPayPalButton || this.isNoProductAllowCoupon()}>
                              <CouponFieldCell>
                                <StyledTextField
                                  label={'קוד קופון'}
                                  name="coupon"
                                  value={values.coupon}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  margin="normal"
                                  fullWidth
                                />
                                {/*helperText="יש לך קופון הנחה? הקלד כאן"*/}
                              </CouponFieldCell>
                              <ApplyCouponButton
                                variant="contained"
                                color="primary"
                                fullWidth
                                size="large"
                                onClick={this.handleApplyCouponClick.bind(this, values.coupon)}
                              >
                                שימוש בקופון
                              </ApplyCouponButton>
                            </CouponRow>
                          </Form>
                        )
                      }}
                    </Formik>
                    {showPayPalButton && (
                      <>
                        <CreditCardButtonWrapper>
                          <PaymentOverlayButton
                            variant="contained"
                            color="primary"
                            fullWidth
                            size="large"
                            onClick={this.handleCreditCardPaymentClick}
                          >
                            תשלום בכרטיס אשראי
                          </PaymentOverlayButton>
                        </CreditCardButtonWrapper>
                        <PayPalButtonWrapper>
                          <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                            <input type="hidden" name="cmd" value={payInMonthlyInstallments ? '_xclick-subscriptions' : '_xclick'} />
                            <input type="hidden" name="business" value="fitthybody@gmail.com" />
                            <input type="hidden" name="lc" value="IL" />
                            <input type="hidden" name="item_name" value={`FitThyBody Store (${orderId})`} />
                            <input type="hidden" name="item_number" value={`${orderId}|${couponCode}`} />
                            <input type="hidden" name="amount" value={orderTotalWithShipment} />
                            <input type="hidden" name="currency_code" value="ILS" />
                            <input type="hidden" name="button_subtype" value="services" />
                            <input type="hidden" name="no_note" value="1" />
                            <input type="hidden" name="shipping" value="0.00" />
                            {payInMonthlyInstallments && (
                              // a3=Regular subscription price (amount of money every month)
                              <input type="hidden" name="a3" value={orderTotalWithShipment / monthlyInstallmentsNum} />
                            )}
                            {payInMonthlyInstallments && (
                              // p3=Subscription duration (X in months, days or weeks)
                              <input type="hidden" name="p3" value="1" />
                            )}
                            {payInMonthlyInstallments && (
                              // t3=Regular subscription units of duration (M=month)
                              <input type="hidden" name="t3" value="M" />
                            )}
                            {payInMonthlyInstallments && (
                              // srt=Recurring times. Number of times that subscription payments recur
                              <input type="hidden" name="srt" value={monthlyInstallmentsNum} />
                            )}
                            {payInMonthlyInstallments && (
                              // sra=Reattempt on failure (1=reattemp)
                              <input type="hidden" name="sra" value="1" />
                            )}
                            {payInMonthlyInstallments && (
                              // src=Recurring payments. Subscription payments recur unless subscribers cancel (1=recur)
                              <input type="hidden" name="src" value="1" />
                            )}
                            <input TYPE="hidden" NAME="return" value={`https://www.fitthybody.com/fitthybodyAPI/api/paypalPayment`} />
                            <input TYPE="hidden" NAME="notify_url" value={`https://www.fitthybody.com/fitthybodyAPI/api/paypalPayment`} />
                            <input TYPE="hidden" NAME="rm" value="2" />
                            <input type="hidden" name="bn" value="PP-BuyNowBF:btn_buynowCC_LG.gif:NonHostedGuest" />
                            <input
                              type="image"
                              src="https://www.paypalobjects.com/he_IL/IL/i/btn/btn_buynowCC_LG.gif"
                              border="0"
                              name="submit"
                              alt="PayPal - הדרך הקלה והבטוחה יותר לשלם באינטרנט!"
                            />
                            <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                          </form>
                          <PaymentOverlayButton variant="contained" color="primary" fullWidth size="large">
                            תשלום דרך PayPal
                          </PaymentOverlayButton>
                        </PayPalButtonWrapper>
                      </>
                    )}
                  </>
                </OrderFormBox>
              )}
            </SummaryWrapper>
          </ContentWrapper>
        )}

        {isFetchingProducts === false && productsInCartData.length === 0 && <EmptyCartLabel>עגלת הקניות ריקה</EmptyCartLabel>}
        {isFetchingProducts === true && (
          <LoadingIndicatorWrapper>
            <CircularProgress />
          </LoadingIndicatorWrapper>
        )}
        {showRemoveProductDialogForProductId && (
          <ConfirmDialog
            title="הסרה מעגלת הקניות"
            text="האם ברצונך להסיר פריט זה מעגלת הקניות שלך?"
            handleSubmit={this.handleRemoveProductDialogSubmit}
            handleClose={this.handleRemoveProductDialogClose}
          />
        )}
      </PageWrapper>
    )
  }
}

export default withRouter(ShoppingCartScreen)
