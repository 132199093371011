import React, { Component } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import Button from '@material-ui/core/Button'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import { mediaQuery } from '../../services/global'
import { invokeAPIcall } from '../../services/rest'
import { requiredValidation, phoneNumValidation } from '../../services/formValidation'
import { getString } from '../../services/presetStringsHelper'
import StyledTextField from '../StyledTextField'

// Style
const ContactUsCarouselItemWrapper = styled.div`
  color: ${colorPalette.black.main};
  background-color: ${colorPalette.white.main};
  border-bottom-left-radius: ${globalStyles.mainBoxRadius};
  border-bottom-right-radius: ${globalStyles.mainBoxRadius};
  box-shadow: ${globalStyles.mainBoxShadow};
  position: relative;
  padding-bottom: 40px;
  top: -46px;
  z-index: 11;
  ${mediaQuery.phone`
    height: auto;
  `}
`
const CarouselItemInner = styled.div`
  width: 100%;
  padding: 0 20px;
  flex-direction: column;
  align-items: center;
  ${mediaQuery.phone`
    background-size: auto 100%;
    background-position: center;
    height: auto;
  `}
`
const ContentBox = styled.div`
  font-size: 22px;
  ${mediaQuery.phone`
    position: static;
    text-align: center;
    width: auto;
    padding: 0 10px;
    flex-direction: column;
  `}
`
const PromotionTitle = styled.h2`
  font-size: 36px;
  font-weight: 500;
  margin: 20px 0;
  text-align: center;
  ${mediaQuery.tablet`
    font-size: 34px;
  `}
  ${mediaQuery.phone`
    font-size: 28px;
  `}
`
const PromotionSubTitle = styled.h4`
  font-size: 22px;
  text-align: center;
  margin-bottom: 20px;
`
const FormWrapper = styled.div`
  width: 40%;
  margin: auto;
  ${mediaQuery.phone`
    width: 100%;
  `}
`
const FieldsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  ${mediaQuery.phone`
    flex-direction: column;
    align-items: stretch;
  `}
`
const FieldCell = styled.div`
  flex: 0 0 48%;
  display: flex;
  justify-content: space-between;
  ${mediaQuery.phone`
    flex: 0 0 100%;
  `}
`
const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  margin: auto;
  margin-top: 20px;
  ${mediaQuery.phone`
    width: 100%;
  `}
`
const StyledButton = styled(Button)`
  && {
  }
`

// Component
class ContactUsCarouselItem extends Component {
  state = {
    initialValues: {
      contactReason: '',
      name: '',
      phone: '',
      email: '',
      text: 'פנייה כללית מהעמוד הראשי',
      countryPhoneCode: 'IL',
      facebookProfilePhoto: '',
    },
  }

  // Lifecycle methods
  componentDidMount() {}

  // Helper methods
  validate = values => {
    let errors = {}

    if (requiredValidation(values.name)) {
      errors.name = requiredValidation(values.name)
    }
    if (requiredValidation(values.phone)) {
      errors.phone = requiredValidation(values.phone)
    } else if (phoneNumValidation(values.phone)) {
      errors.phone = phoneNumValidation(values.phone)
    }

    return errors
  }

  // UI event methods
  handleSubmit = async (values, { setSubmitting }) => {
    const { openSnackbar } = this.props
    const data = {
      ...values,
    }

    // invoke API call
    try {
      const responseData = await invokeAPIcall({
        uri: 'api/contact',
        data,
        method: 'POST',
      })

      // if API success
      if (responseData.Key === true) {
        openSnackbar({
          message: 'הבקשה שהזנת התקבלה בהצלחה! הצוות המקצועי שלנו ייצור עימך קשר תוך 3 שעות. תודה על הסבלנות!',
          severity: 'success',
        })
      }
      // if failed
      else {
        openSnackbar({ message: responseData.Value, severity: 'failure' })
      }
    } catch (error) {
      console.log('error in sending email', error)
    }
    setSubmitting(false)
  }

  // Render methods
  render() {
    const { initialValues } = this.state
    return (
      <>
        <ContactUsCarouselItemWrapper>
          <CarouselItemInner>
            <ContentBox>
              <PromotionTitle>{getString('ContactUsCarouselItemTitle')}</PromotionTitle>
              <PromotionSubTitle>{getString('ContactUsCarouselItemSubTitle')}</PromotionSubTitle>
              <FormWrapper>
                <Formik validate={this.validate} initialValues={initialValues} onSubmit={this.handleSubmit} enableReinitialize={true}>
                  {formikBag => {
                    const { isSubmitting, values, errors, touched, handleChange, handleBlur, isValid } = formikBag
                    return (
                      <Form>
                        <FieldsRow>
                          <FieldCell>
                            <StyledTextField
                              label={'שם מלא'}
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              helperText={errors.name && touched.name && errors.name}
                              error={errors.name && touched.name}
                              margin="normal"
                              fullWidth
                            />
                          </FieldCell>
                          <FieldCell>
                            <StyledTextField
                              label={'טלפון'}
                              name="phone"
                              value={values.phone}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              helperText={errors.phone && touched.phone && errors.phone}
                              error={errors.phone && touched.phone}
                              margin="normal"
                              fullWidth
                            />
                          </FieldCell>
                        </FieldsRow>
                        <ButtonsWrapper>
                          <StyledButton variant="contained" color="primary" fullWidth disabled={!isValid || isSubmitting} type="submit">
                            {getString('ContactUsCarouselItemButton')}
                          </StyledButton>
                        </ButtonsWrapper>
                      </Form>
                    )
                  }}
                </Formik>
              </FormWrapper>
            </ContentBox>
          </CarouselItemInner>
        </ContactUsCarouselItemWrapper>
      </>
    )
  }
}

export default ContactUsCarouselItem
