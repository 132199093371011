import React, { Component } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import CheckIcon from '@material-ui/icons/Check'
import crownIcon from '../../images/crownIcon2.png'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import { getString } from '../../services/presetStringsHelper'
import { mediaQuery } from '../../services/global'
import { invokeAPIcall } from '../../services/rest'
import ChallengePriceStar from '../ChallengePriceStar'

// Consts

// Style
const PackageItemList = styled.ul`
  display: flex;
  margin-top: 20px;
  ${mediaQuery.phone`
    flex-direction: column;
  `}
`
const PackageItem = styled.li`
  background-color: ${colorPalette.white.main};
  padding: 20px;
  min-width: 200px;
  margin: 0 10px;
  color: ${colorPalette.primary1.main};
  ${globalStyles.boxShadow}
  ${globalStyles.boxRadius}
  flex: 1;
  ${props => (props.vipItem ? `color: ${colorPalette.black.main}; box-shadow: -3px 3px 1px 5px ${colorPalette.primary2.main};` : '')}
  display: flex;
  flex-direction: column;
  ${mediaQuery.phone`
    min-width: 0;
    margin: 0;
    margin-bottom: 20px;
  `}
`
const PackageItemTitle = styled.h4`
  text-align: center;
  position: relative;
  margin-bottom: 20px;
`
const PackageItemContent = styled.ul`
  margin: 10px 0;
  flex: 1;
`
const PackageItemContentPromoItem = styled.li`
  display: flex;
  margin: 5px 0;
`
const PackageItemPriceWrapper = styled.div``
const PackageItemPrice = styled.h4`
  text-align: center;
  margin: 10px 0;
  padding-top: 10px;
  border-top: 2px solid ${props => (props.vipItem ? colorPalette.black.main : colorPalette.primary1.main)};
`
const PackageItemPriceStar = styled.div`
  position: relative;
  margin-bottom: 30px;
  ${mediaQuery.phone`
    min-height: 160px;
  `}
`
const StyledButton = styled(Button)`
  && {
  }
`
const StyledCheckIcon = styled(CheckIcon)``
const CrownIconWrapper = styled.div`
  position: absolute;
  top: -5px;
  right: 15%;
  padding: 4px;
  border-radius: 8px;
  background-color: ${colorPalette.black.main};
`
const StyledCrownIcon = styled.div`
  background-image: url(${crownIcon});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
`

// Component
class PackageItemListComponent extends Component {
  state = {}

  // Lifecycle methods

  // Helper methods

  // UI event methods

  handlePackageItemClick = packageNum => async () => {
    // invoke API call to update the user's started challenge date
    try {
      await invokeAPIcall({
        uri: `api/users/UpdateUserSelectedPackage?selectedPackage=${packageNum}`,
        data: {},
        method: 'PUT',
      })
    } catch (error) {}

    const { history } = this.props
    history.push(`/challengePayment/${packageNum}`)
  }

  // Render methods
  render() {
    const { globalSettingsData, hideBtn } = this.props
    let currency = '₪'

    const items = [
      {
        packageNum: 1,
        title: getString('ChallengePack1Title'),
        promoItems: getString('ChallengePack1Body'),
        originalPrice: globalSettingsData && globalSettingsData.ChallengePricePack1Original,
        price: globalSettingsData && globalSettingsData.ChallengePricePack1,
      },
      {
        packageNum: 3,
        title: getString('ChallengePack3Title'),
        promoItems: getString('ChallengePack3Body'),
        originalPrice: globalSettingsData && globalSettingsData.ChallengePricePack3Original,
        price: globalSettingsData && globalSettingsData.ChallengePricePack3,
        vipItem: true,
      },
      {
        packageNum: 2,
        title: getString('ChallengePack2Title'),
        promoItems: getString('ChallengePack2Body'),
        originalPrice: globalSettingsData && globalSettingsData.ChallengePricePack2Original,
        price: globalSettingsData && globalSettingsData.ChallengePricePack2,
      },
    ]

    return (
      <PackageItemList>
        {items.map(item => (
          <PackageItem vipItem={item.vipItem}>
            <PackageItemContent>
              <PackageItemTitle>
                {item.title}
                {item.vipItem && (
                  <CrownIconWrapper>
                    <StyledCrownIcon />
                  </CrownIconWrapper>
                )}
              </PackageItemTitle>
              {item.promoItems.split('\n').map(function(promoItem, idx) {
                return (
                  <PackageItemContentPromoItem key={idx}>
                    <StyledCheckIcon />
                    {promoItem}
                  </PackageItemContentPromoItem>
                )
              })}
            </PackageItemContent>
            <PackageItemPriceWrapper>
              <PackageItemPriceStar>
                <ChallengePriceStar challengeFullPrice={item.price} challengePriceOriginal={item.originalPrice} currency={currency} />
              </PackageItemPriceStar>
              <PackageItemPrice vipItem={item.vipItem}>
                {currency}
                {item.price}
              </PackageItemPrice>
              {!hideBtn && (
                <StyledButton
                  variant="contained"
                  color={item.vipItem ? 'secondary' : 'primary'}
                  fullWidth
                  onClick={this.handlePackageItemClick(item.packageNum)}
                >
                  בחירה
                </StyledButton>
              )}
            </PackageItemPriceWrapper>
          </PackageItem>
        ))}
      </PackageItemList>
    )
  }
}

export default withRouter(PackageItemListComponent)
