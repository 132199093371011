import { connect } from 'react-redux'
import EndOfChallengeDialog from '../components/ProgramScreen/EndOfChallengeDialog'

export default connect(
  state => ({
    currentUser: state.currentUser.user,
    personalStats: state.currentUser.personalStats,
  }),
  {},
)(EndOfChallengeDialog)
