import React, { Component } from 'react'
import styled from 'styled-components'
import { Link, withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloseIcon from '@material-ui/icons/Clear'
import { colorPalette, globalStyles } from '../styles/styleVariables'
import { mediaQuery } from '../services/global'
import { invokeAPIcall } from '../services/rest'
import {
  calcDaysSinceStartedChallenge,
  isTrialUserExpired,
  isUserDidntEnterCreditCardInfo,
  TRIAL_DAYS,
} from '../services/challengeProgressHelper'
import PersonalStatsScreen from '../containers/PersonalStatsScreenContainer'
import EndOfChallengeDialog from '../containers/EndOfChallengeDialogContainer'
import StartOfPaidChallengeDialog from '../containers/StartOfPaidChallengeDialogContainer'

// Style
const ProgressBarWrapper = styled.div`
  position: relative;
  z-index: 1;
  ${mediaQuery.phone`
    position: fixed;
    left: 0;
    right: 0;
    top: 60px;
  `}
`
const ProgramBarNumbers = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${colorPalette.primary1.main};
  color: ${colorPalette.white.main};
  height: 64px;
  padding: 0 20px;
  padding-right: 0;
  overflow: hidden;
  border-bottom-left-radius: ${globalStyles.mainBoxRadius};
  border-bottom-right-radius: ${globalStyles.mainBoxRadius};
  box-shadow: ${globalStyles.mainBoxShadow};
`
const ProgramBarNumber = styled.div`
  font-size: 10px;
  position: relative;
  z-index: 2;
  flex: 1;
  background: ${props => (props.passed ? colorPalette.primary1.dark : 'none')};
  border-bottom-left-radius: ${props => (props.lastDayPassed ? '10px' : '0')};
  padding-right: ${props => (props.firstDay ? '20px' : '0')};
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 8px;
  ${mediaQuery.phone`
    display: ${props => (props.showInMobile ? 'flex' : 'none')};
  `}
  ${props =>
    props.importantDay
      ? `
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 6px;
  `
      : ''}
  ${props =>
    props.isSeparator
      ? `
        flex: 0.4;
        font-size: 20px;
      `
      : ''}
`
const DayLabel = styled.span`
  ${props =>
    props.isToday
      ? `
    background: ${colorPalette.primary2.main};
    color: ${colorPalette.black.main};
    position: absolute;
    bottom: 4px;
    right: ${props => (props.firstDay ? '38%' : '14%')};
    font-size: 14px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  `
      : ''}
`
const PersonalStatsWrapper = styled.div`
  position: fixed;
  z-index: 90;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 110px;
  background-color: rgba(0, 0, 0, 0.6);
  ${mediaQuery.phone`
    padding-top: 10px;
    overflow: auto;
  `}
`
const StyledCloseIcon = styled(CloseIcon)`
  && {
    display: none;
    width: 25px;
    height: 25px;
    margin-left: 6px;
    cursor: pointer;
    color: ${colorPalette.white.main};
  }
  ${mediaQuery.phone`
    && {
      position: absolute;
      top: 12px;
      right: 16px;
      z-index: 200;
      color: ${colorPalette.primary1.main};
    }
`}
`
const ChallengePaymentPopup = styled.div`
  padding: 20px;
  margin-top: 30px;
  width: 500px;
  background-color: ${colorPalette.white.main};
  color: ${colorPalette.primary1.main};
  ${globalStyles.boxShadow}
  ${globalStyles.boxRadius}
  ${mediaQuery.phone`
    min-width: 0;
    width: 95%;
  `}
`
const ChallengePaymentPopupTitle = styled.h1``
const ChallengePaymentPopupText = styled.div`
  margin-bottom: 20px;
`
const PaymentButtonWrapper = styled.div`
  margin-bottom: 10px;
`
const LoadingIndicatorWrapper = styled.div`
  margin: 50px auto;
  display: flex;
  justify-content: center;
`

class ProgramProgressBar extends Component {
  state = {
    personalStatsList: null,
    showPersonalStatsModalType: null,
    showChallengePaymentPopup: false,
    showChallengePaymentPopup_confirmState: false,
    showChallengePaymentPopup_confirmStateIsLoading: false,
    showEndOfChallengeDialog: false,
    showStartOfPaidChallengeDialog: false,
  }

  // Lifecycle methods
  async componentDidMount() {
    await this.calculateDaysSinceStartedChallengeAndShowMessage()
    await this.loadPersonalStats()
    await this.loadUserMealsAndWorkouts()
  }

  // Helper methods
  loadPersonalStats = async () => {
    const { setPersonalStats } = this.props
    const personalStatsList = await invokeAPIcall({ uri: 'api/personalStats' })

    this.setState({ personalStatsList })
    setPersonalStats({ personalStats: personalStatsList })
  }

  loadUserMealsAndWorkouts = async () => {
    const { setUserMealsAndWorkouts } = this.props
    let allUserMeals = await invokeAPIcall({ uri: 'api/userMeals' })
    let allUserWorkouts = await invokeAPIcall({ uri: 'api/userWorkouts' })

    setUserMealsAndWorkouts({ allUserMeals, allUserWorkouts })
  }

  calculateDaysSinceStartedChallengeAndShowMessage = async () => {
    const { currentUser, history } = this.props
    const personalStatsList = await invokeAPIcall({ uri: 'api/personalStats' })

    if (!personalStatsList || personalStatsList.length === 0) {
      // if user haven't enter PersonalStats yet (the array is empty) - redirect to the personalStats screen
      history.push('/personalStats')
      return
    }

    if (isUserDidntEnterCreditCardInfo(currentUser)) {
      // if user haven't completed initial creditCard info form (via Pelecard page) - redirect to the challengePayment screen
      history.push(`/challengePayment`)
      return
    }

    const daysSinceStartedChallenge = calcDaysSinceStartedChallenge(personalStatsList, currentUser)

    if (personalStatsList) {
      // if more than X days since the challenge started
      if (isTrialUserExpired(daysSinceStartedChallenge, currentUser)) {
        this.setState({ showChallengePaymentPopup: true })
        return
      }

      // if this user finished all TRIAL_DAYS, and is now starting the period as a paying user
      if (daysSinceStartedChallenge >= TRIAL_DAYS) {
        const startPersonalStats = personalStatsList.filter(item => item.Phase === 'start')
        if (startPersonalStats && startPersonalStats[0]) {
          // check if he hasn't yet entered these required fields
          if (startPersonalStats[0].UseMedications == null && startPersonalStats[0].WantToUseSupplements == null) {
            this.setState({ showStartOfPaidChallengeDialog: true })
            return
          }
        }
      }

      // if 45 days have been past since challenge start
      if (daysSinceStartedChallenge >= 45 && daysSinceStartedChallenge < 75) {
        // make sure that the user haven't already updated 'afterPhase1' stats
        if (personalStatsList.filter(item => item.Phase === 'afterPhase1').length === 0) {
          this.setState({ showPersonalStatsModalType: 'afterPhase1' })
          return
        }
      } else if (daysSinceStartedChallenge >= 75 && daysSinceStartedChallenge < 90) {
        if (personalStatsList.filter(item => item.Phase === 'afterPhase2').length === 0) {
          this.setState({ showPersonalStatsModalType: 'afterPhase2' })
          return
        }
      } else if (daysSinceStartedChallenge >= 90) {
        if (personalStatsList.filter(item => item.Phase === 'afterPhase3').length === 0) {
          this.setState({ showPersonalStatsModalType: 'afterPhase3' })
          return
        } else {
          // if he already finished 90 days (full challenge), and filled PersonalStats, but haven't filled yet the "EndOfChallenge" dialog
          if (currentUser.EndOfChallenge_howWent == null || currentUser.EndOfChallenge_howWent === '') {
            this.setState({ showEndOfChallengeDialog: true })
          }
        }
      }
    }

    this.setState({ showPersonalStatsModalType: null })
  }

  // UI event methods
  handlePersonalStatsCloseClick = () => {
    this.setState({ showPersonalStatsModalType: null })
  }

  handleEndOfChallengeDialogClose = async () => {
    const { setCurrentUser } = this.props
    this.setState({ showEndOfChallengeDialog: false })

    // update the 'currentUser'
    const currentUserData = await invokeAPIcall({ uri: 'api/users/getCurrentUser' })
    setCurrentUser({ user: currentUserData })
  }

  handleStartOfPaidChallengeDialogClose = () => {
    this.setState({ showStartOfPaidChallengeDialog: false })
  }

  handleChallengePaymentClick = () => {
    this.setState({ showChallengePaymentPopup_confirmState: true })
  }

  handleBillingCreditCardClick = async () => {
    const { openSnackbar, setCurrentUser } = this.props

    this.setState({ showChallengePaymentPopup_confirmStateIsLoading: true })

    // invoke API call to confirm payment and invoke the billing of credit card, after the X trial days passed
    try {
      const responseData = await invokeAPIcall({
        uri: `api/pelecardPayment/ConfirmChallengePaymentAndInvokeCreditCardBilling`,
        data: {},
        method: 'PUT',
      })

      // if API success
      if (responseData.Key === true) {
        this.setState({
          showChallengePaymentPopup: false,
          showChallengePaymentPopup_confirmState: false,
          showChallengePaymentPopup_confirmStateIsLoading: false,
        })
        openSnackbar({ message: responseData.Value, severity: 'success' })

        // update the 'currentUser'
        const currentUserData = await invokeAPIcall({ uri: 'api/users/getCurrentUser' })
        setCurrentUser({ user: currentUserData })

        window.setTimeout(() => {
          window.location.reload()
        }, 2000)
      }
      // if failed
      else {
        this.setState({ showChallengePaymentPopup_confirmStateIsLoading: false })
        openSnackbar({ message: responseData.Value, severity: 'failure' })
      }
    } catch (error) {
      this.setState({ showChallengePaymentPopup_confirmStateIsLoading: false })
      openSnackbar({ message: 'התרחשה שגיאה', severity: 'failure' })
    }
  }

  // Render methods
  render() {
    const {
      personalStatsList,
      showPersonalStatsModalType,
      showChallengePaymentPopup,
      showChallengePaymentPopup_confirmState,
      showChallengePaymentPopup_confirmStateIsLoading,
      showEndOfChallengeDialog,
      showStartOfPaidChallengeDialog,
    } = this.state
    const { currentUser } = this.props
    const DAY_SEPARATOR = '...'
    const daysSinceStartedChallenge = calcDaysSinceStartedChallenge(personalStatsList, currentUser) + 1

    const daysArr = []
    if (daysSinceStartedChallenge >= 0 && daysSinceStartedChallenge <= 30) {
      for (let index = 0; index < 30; index++) {
        daysArr.push(index + 1)
      }
      daysArr.push(DAY_SEPARATOR)
      daysArr.push(60)
      daysArr.push(DAY_SEPARATOR)
      daysArr.push(90)
    } else if (daysSinceStartedChallenge > 30 && daysSinceStartedChallenge <= 60) {
      daysArr.push(1)
      daysArr.push(DAY_SEPARATOR)
      daysArr.push(30)
      for (let index = 30; index < 60; index++) {
        daysArr.push(index + 1)
      }
      daysArr.push(DAY_SEPARATOR)
      daysArr.push(90)
    } else if (daysSinceStartedChallenge > 60) {
      daysArr.push(1)
      daysArr.push(DAY_SEPARATOR)
      daysArr.push(30)
      daysArr.push(DAY_SEPARATOR)
      daysArr.push(60)
      for (let index = 60; index < 90; index++) {
        daysArr.push(index + 1)
      }
    }

    return (
      <>
        <ProgressBarWrapper>
          <ProgramBarNumbers className="wizard_progressBar">
            {daysArr.map((day, index) => (
              <ProgramBarNumber
                passed={day <= daysSinceStartedChallenge || (day === DAY_SEPARATOR && daysArr[index - 1] < daysSinceStartedChallenge)}
                firstDay={day === 1}
                lastDayPassed={day === daysSinceStartedChallenge}
                importantDay={day % 30 === 0}
                isSeparator={day === DAY_SEPARATOR}
                showInMobile={day === 1 || day % 30 === 0 || day === daysSinceStartedChallenge || day === DAY_SEPARATOR}
              >
                <DayLabel firstDay={day === 1} isToday={day === daysSinceStartedChallenge}>
                  {day}
                </DayLabel>
              </ProgramBarNumber>
            ))}
          </ProgramBarNumbers>
        </ProgressBarWrapper>
        {showPersonalStatsModalType && (
          <PersonalStatsWrapper>
            <StyledCloseIcon onClick={this.handlePersonalStatsCloseClick} />
            <PersonalStatsScreen
              showAsModalType={showPersonalStatsModalType}
              handlePersonalStatsSubmit={() => {
                window.location.reload()
              }}
            />
          </PersonalStatsWrapper>
        )}
        {showChallengePaymentPopup && (
          <PersonalStatsWrapper>
            <ChallengePaymentPopup>
              <ChallengePaymentPopupTitle>
                {showChallengePaymentPopup_confirmState ? 'אישור עסקת אשראי' : `${TRIAL_DAYS} ימי הניסיון הסתיימו`}
              </ChallengePaymentPopupTitle>
              <ChallengePaymentPopupText>
                {showChallengePaymentPopup_confirmState ? (
                  <div>יש לאשר כעת את ביצוע העסקה בכרטיס האשראי שהוזן בעת ההרשמה.</div>
                ) : (
                  <div>
                    כעת תיפתח עבורך התוכנית המשודרגת שבחרת.
                    <br />
                    על מנת להמשיך ליהנות מ-90 יום משני חיים ולתוצאות יומיומיות שיביאו אותך לעבר המטרות שלך, יש לאשר את המשך התוכנית.
                  </div>
                )}
              </ChallengePaymentPopupText>
              {showChallengePaymentPopup_confirmStateIsLoading && (
                <LoadingIndicatorWrapper>
                  <CircularProgress />
                </LoadingIndicatorWrapper>
              )}
              {!showChallengePaymentPopup_confirmStateIsLoading && (
                <>
                  <PaymentButtonWrapper>
                    {showChallengePaymentPopup_confirmState ? (
                      <Button variant="contained" color="secondary" size="large" fullWidth onClick={this.handleBillingCreditCardClick}>
                        אישור עסקה
                      </Button>
                    ) : (
                      <Button variant="contained" color="secondary" size="large" fullWidth onClick={this.handleChallengePaymentClick}>
                        להמשך חוויית החיטוב המלאה
                      </Button>
                    )}
                  </PaymentButtonWrapper>
                  <Link to="/">
                    <Button variant="contained" color="primary" size="large" fullWidth>
                      לחזרה לאתר
                    </Button>
                  </Link>
                </>
              )}
            </ChallengePaymentPopup>
          </PersonalStatsWrapper>
        )}
        {showEndOfChallengeDialog && <EndOfChallengeDialog handleClose={this.handleEndOfChallengeDialogClose} />}
        {showStartOfPaidChallengeDialog && <StartOfPaidChallengeDialog handleClose={this.handleStartOfPaidChallengeDialogClose} />}
      </>
    )
  }
}

export default withRouter(ProgramProgressBar)
