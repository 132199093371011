import { connect } from 'react-redux'
import UserSignButtons from '../components/UserSignButtons'
import { setAccessToken, setCurrentUser, removeCurrentUser } from '../bundles/currentUser'
import { setPresetStrings } from '../bundles/presetStrings'

export default connect(
  state => ({
    currentUser: state.currentUser.user,
    loginValues: state.currentUser.loginValues,
  }),
  {
    setAccessToken,
    setCurrentUser,
    removeCurrentUser,
    setPresetStrings,
  },
)(UserSignButtons)
