import React, { Component } from 'react'
import styled from 'styled-components'
import { Formik, Form, FieldArray } from 'formik'
import { withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress'
import Checkbox from '@material-ui/core/Checkbox'
import formBackgroundLeft from '../../images/formBackground_left.jpg'
import formBackgroundRight from '../../images/formBackground_right.jpg'
import { mediaQuery, keysToLowerCase, photoUrlPrefix } from '../../services/global'
import { requiredValidation } from '../../services/formValidation'
import { invokeAPIcall } from '../../services/rest'
import { showInChallengePhaseOptions } from '../../services/workoutVideos'
import { dayTimeOptions, foodTypeOptions } from '../../services/recipes'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import StyledTextField from '../StyledTextField'
import StyledSelect from '../StyledSelect'
import Breadcrumbs from '../Breadcrumbs'

// Style
const PageWrapper = styled.div`
  background-color: #f5fcfd;
  background: url(${formBackgroundLeft}) left bottom no-repeat, url(${formBackgroundRight}) right bottom no-repeat, #f7fdfd;
  background-size: 40% auto, 35% auto;
  padding: 20px;
  flex: 1;
  ${mediaQuery.phone`
    background: none;
  `}
`
const FormWrapper = styled.div`
  background-color: ${colorPalette.white.main};
  max-width: 680px;
  margin: 0 auto;
  padding: 20px;
  color: ${colorPalette.primary1.main};
  ${globalStyles.boxShadow}
  ${globalStyles.boxRadius}
`
const PageTitle = styled.h1`
  margin-bottom: 20px;
`
const FieldsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
`
const FieldCell = styled.div`
  flex: 0 0 100%;
`
const PhotoFieldCell = styled(FieldCell)`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  ${mediaQuery.phone`
    flex-direction: column;
  `}
`
const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  margin-top: 20px;
  ${mediaQuery.phone`
    width: 100%;
  `}
`
const StyledButton = styled(Button)`
  && {
  }
`
const ItemPhoto = styled.div`
  width: 150px;
  height: 150px;
  background-image: url('${props => props.photoUrl}');
  background-position: center;
  background-size: cover;
  margin: auto;
`
const LoadingIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5px;
`
const RecipeIngredientsRow = styled.div`
  display: flex;
  & > div {
    margin: 0;
  }
`
const AddNewIngredientButton = styled(Button)`
  && {
    margin-top: 20px;
  }
`

// Component
class AddRecipeScreen extends Component {
  state = {
    initialValues: {
      name: '',
      instructionsText: '',
      dayTime: null,
      foodTypeIDs: [],
      amountOfMeals: '',
      durationInMins: null,
      cookingTimeInMins: null,
      recipeIngredients: [],
      showInChallengePhase: null,
    },
    addedPhotoFile: null,
  }

  // Lifecycle methods
  componentDidMount() {
    const editModeId = this.getEditModeId()
    if (editModeId != null) {
      this.loadRecipeData(editModeId)
    }
  }

  // Helper methods
  getEditModeId = () => {
    const { match } = this.props
    const { params } = match
    return params.id
  }

  loadRecipeData = async id => {
    let recipeDataOriginal = await invokeAPIcall({ uri: `api/recipes/${id}` })
    let recipeData = keysToLowerCase(JSON.parse(JSON.stringify(recipeDataOriginal)))
    if (!recipeData.foodTypeIDs || recipeData.foodTypeIDs === '') {
      recipeData.foodTypeIDs = []
    } else {
      recipeData.foodTypeIDs = recipeData.foodTypeIDs.split(',')
      recipeData.foodTypeIDs = recipeData.foodTypeIDs.map(item => Number(item))
    }
    recipeData = {
      ...recipeData,
      recipeIngredients: recipeDataOriginal.RecipeIngredients.map(item => keysToLowerCase(item)),
    }

    const state = {
      initialValues: recipeData,
    }
    this.setState(state)
  }

  validate = values => {
    let errors = {}

    if (requiredValidation(values.name)) {
      errors.name = requiredValidation(values.name)
    }
    if (requiredValidation(values.dayTime)) {
      errors.dayTime = requiredValidation(values.dayTime)
    }
    if (requiredValidation(values.instructionsText)) {
      errors.instructionsText = requiredValidation(values.instructionsText)
    }
    if (requiredValidation(values.showInChallengePhase)) {
      errors.showInChallengePhase = requiredValidation(values.showInChallengePhase)
    }

    return errors
  }

  // UI event methods
  handleSubmit = async (values, { setSubmitting }) => {
    const editModeId = this.getEditModeId()
    const isEditMode = editModeId != null
    const { history } = this.props
    const data = {
      ...values,
      foodTypeIDs: values.foodTypeIDs.join(','),
      recipeIngredients: values.recipeIngredients.map(item => {
        delete item.recipeIngredientID
        return item
      }),
    }

    // invoke API call
    try {
      const responseData = await invokeAPIcall({
        uri: isEditMode ? `api/recipes/${editModeId}` : 'api/recipes',
        data,
        method: isEditMode ? 'PUT' : 'POST',
      })

      // if API success
      if (responseData.Key === true) {
        // upload photo of item
        const files = document.querySelector('input[type="file"]').files
        if (files.length > 0) {
          const uploadData = new FormData()
          uploadData.append('file', files[0])
          uploadData.append('recipeId', responseData.RecipeID)
          try {
            const uploadPhotoResponse = await fetch('https://www.fitthybody.com/fitthybodyAPI/api/recipes/UploadPhoto', {
              method: 'POST',
              body: uploadData,
            })
            const uploadPhotoResponseJson = await uploadPhotoResponse.json()
            if (uploadPhotoResponseJson.Key === false) {
              alert(uploadPhotoResponseJson.Value)
            }
          } catch (error) {
            alert('שגיאה בהעלאת התמונה שנבחרה')
          }
        }

        history.push('/recipesAdmin')
      }
      // if failed
      else {
        alert(responseData.Value || 'התרחשה שגיאה')
      }
    } catch (error) {
      console.log('error in adding/updating new recipe', error)
    }
    setSubmitting(false)
  }

  handlePhotoFileChange = e => {
    if (e.target.files && e.target.files.length > 0) {
      // more than 4MB
      if (e.target.files[0].size > 4000000) {
        alert('משקל הקובץ גדול מדי: ' + e.target.files[0].size)
        return false
      }
    }
    this.setState({ addedPhotoFile: e.target.value })
  }

  // Render methods
  render() {
    const { initialValues } = this.state
    const editModeId = this.getEditModeId()
    const isEditMode = editModeId != null

    let photoUrl = null
    // for edit mode
    if (isEditMode) {
      photoUrl = initialValues.photoURL
      if (photoUrl && !photoUrl.includes('http')) {
        photoUrl = `${photoUrlPrefix}/recipePhotos/${photoUrl}`
      }
    }
    // if the user uploaded a photo
    const files = document.querySelector('input[type="file"]') && document.querySelector('input[type="file"]').files
    if (files && files.length > 0) {
      photoUrl = URL.createObjectURL(files[0])
    }
    return (
      <PageWrapper>
        <Breadcrumbs
          previousItems={[
            {
              label: `מסכי ניהול`,
              url: `/adminPanel`,
            },
          ]}
          currentItem={`ניהול מתכונים`}
        />
        <FormWrapper>
          <PageTitle>{isEditMode ? 'עדכון מתכון' : 'הוספת מתכון חדש'}</PageTitle>
          <Formik validate={this.validate} initialValues={initialValues} onSubmit={this.handleSubmit} enableReinitialize={true}>
            {formikBag => {
              const { isSubmitting, values, errors, touched, handleChange, handleBlur, isValid, setFieldValue } = formikBag
              return (
                <Form>
                  <FieldsRow>
                    <FieldCell>
                      <StyledTextField
                        label={'שם מתכון'}
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.name && touched.name && errors.name}
                        error={errors.name && touched.name}
                        margin="normal"
                        fullWidth
                      />
                    </FieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <FieldCell>
                      <StyledSelect
                        selectParams={{
                          name: 'foodTypeIDs',
                          value: values.foodTypeIDs,
                          onChange: handleChange,
                          onBlur: handleBlur,
                          margin: 'normal',
                          fullWidth: true,
                          inputProps: {
                            name: 'foodTypeIDs',
                            id: 'foodTypeIDs-simple',
                          },
                          multiple: true,
                          renderValue: selected =>
                            foodTypeOptions
                              .filter(item => selected.includes(item.value))
                              .map(item => item.label)
                              .join(', '),
                        }}
                        label="מתאים לדיאטה מסוג"
                        htmlFor="foodTypeIDs-simple"
                        helperText={errors.foodTypeIDs && touched.foodTypeIDs && errors.foodTypeIDs}
                        error={errors.foodTypeIDs && touched.foodTypeIDs}
                        inputLabelShrink={values.foodTypeIDs}
                      >
                        {foodTypeOptions.map(item => (
                          <MenuItem value={item.value}>
                            <Checkbox checked={values.foodTypeIDs.indexOf(item.value) > -1} />
                            {item.label}
                          </MenuItem>
                        ))}
                      </StyledSelect>
                    </FieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <FieldCell>
                      <StyledSelect
                        selectParams={{
                          name: 'dayTime',
                          value: values.dayTime,
                          onChange: handleChange,
                          onBlur: handleBlur,
                          margin: 'normal',
                          fullWidth: true,
                          inputProps: {
                            name: 'dayTime',
                            id: 'dayTime-simple',
                          },
                        }}
                        label="קטגוריה"
                        htmlFor="dayTime-simple"
                        helperText={errors.dayTime && touched.dayTime && errors.dayTime}
                        error={errors.dayTime && touched.dayTime}
                        inputLabelShrink={values.dayTime}
                      >
                        {dayTimeOptions.map(item => (
                          <MenuItem value={item.value}>{item.label}</MenuItem>
                        ))}
                      </StyledSelect>
                    </FieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <FieldCell>
                      <StyledSelect
                        selectParams={{
                          name: 'showInChallengePhase',
                          value: values.showInChallengePhase,
                          onChange: handleChange,
                          onBlur: handleBlur,
                          margin: 'normal',
                          fullWidth: true,
                          inputProps: {
                            name: 'showInChallengePhase',
                            id: 'showInChallengePhase-simple',
                          },
                        }}
                        label="תקופה באתגר"
                        htmlFor="showInChallengePhase-simple"
                        helperText={errors.showInChallengePhase && touched.showInChallengePhase && errors.showInChallengePhase}
                        error={errors.showInChallengePhase && touched.showInChallengePhase}
                        inputLabelShrink={values.showInChallengePhase}
                      >
                        {showInChallengePhaseOptions.map(item => (
                          <MenuItem value={item.value}>{item.label}</MenuItem>
                        ))}
                      </StyledSelect>
                    </FieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <PhotoFieldCell>
                      <div>
                        <div>תמונת מתכון:</div>
                        <input
                          type="file"
                          onChange={e => {
                            setFieldValue('addedPhoto', true, false)
                            this.handlePhotoFileChange(e)
                          }}
                        />
                      </div>
                      {photoUrl && <ItemPhoto photoUrl={photoUrl} />}
                    </PhotoFieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <div>
                      <br />
                      מצרכים
                    </div>
                    <FieldCell>
                      <FieldArray
                        name="recipeIngredients"
                        render={arrayHelpers => (
                          <div>
                            {values.recipeIngredients && values.recipeIngredients.length > 0
                              ? values.recipeIngredients.map((item, index) => {
                                  const ingredientNameError = errors[index] && errors[index].ingredientName
                                  const ingredientNameTouched =
                                    touched.recipeIngredients &&
                                    touched.recipeIngredients[index] &&
                                    touched.recipeIngredients[index].ingredientName
                                  return (
                                    <RecipeIngredientsRow key={index}>
                                      <StyledTextField
                                        name={`recipeIngredients.${index}.ingredientName`}
                                        value={item.ingredientName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={ingredientNameError && ingredientNameTouched && ingredientNameError}
                                        error={ingredientNameError && ingredientNameTouched}
                                        margin="normal"
                                        fullWidth
                                      />
                                      <Button variant="contained" color="secondary" size="small" onClick={() => arrayHelpers.remove(index)}>
                                        הסר
                                      </Button>
                                    </RecipeIngredientsRow>
                                  )
                                })
                              : null}
                            <AddNewIngredientButton
                              variant="contained"
                              color="secondary"
                              size="small"
                              onClick={() => arrayHelpers.insert(values.recipeIngredients.length, {})}
                            >
                              הוסף מצרכים
                            </AddNewIngredientButton>
                          </div>
                        )}
                      />
                    </FieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <FieldCell>
                      <StyledTextField
                        label={'אופן ההכנה'}
                        name="instructionsText"
                        value={values.instructionsText}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.instructionsText && touched.instructionsText && errors.instructionsText}
                        error={errors.instructionsText && touched.instructionsText}
                        margin="normal"
                        fullWidth
                        multiline
                        rows="6"
                      />
                    </FieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <FieldCell>
                      <StyledTextField
                        label={'כמות המנות'}
                        name="amountOfMeals"
                        value={values.amountOfMeals}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.amountOfMeals && touched.amountOfMeals && errors.amountOfMeals}
                        error={errors.amountOfMeals && touched.amountOfMeals}
                        margin="normal"
                        fullWidth
                      />
                    </FieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <FieldCell>
                      <StyledTextField
                        label={'זמן עבודה (בדקות)'}
                        name="durationInMins"
                        value={values.durationInMins}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.durationInMins && touched.durationInMins && errors.durationInMins}
                        error={errors.durationInMins && touched.durationInMins}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: values.durationInMins !== null }}
                      />
                    </FieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <FieldCell>
                      <StyledTextField
                        label={'זמן בישול/אפייה (בדקות)'}
                        name="cookingTimeInMins"
                        value={values.cookingTimeInMins}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.cookingTimeInMins && touched.cookingTimeInMins && errors.cookingTimeInMins}
                        error={errors.cookingTimeInMins && touched.cookingTimeInMins}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: values.cookingTimeInMins !== null }}
                      />
                    </FieldCell>
                  </FieldsRow>
                  <ButtonsWrapper>
                    <StyledButton variant="contained" color="primary" fullWidth disabled={!isValid || isSubmitting} type="submit">
                      {isEditMode ? 'עדכן' : 'הוסף'}
                    </StyledButton>
                  </ButtonsWrapper>
                  {isSubmitting && (
                    <LoadingIndicatorWrapper>
                      <CircularProgress />
                    </LoadingIndicatorWrapper>
                  )}
                </Form>
              )
            }}
          </Formik>
        </FormWrapper>
      </PageWrapper>
    )
  }
}

export default withRouter(AddRecipeScreen)
