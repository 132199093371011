import { connect } from 'react-redux'
import StoreButton from '../components/StoreButton'
import { openSnackbar } from '../bundles/snackbar'

export default connect(
  state => ({
    productsInCart: state.shoppingCart.productsInCart,
  }),
  {
    openSnackbar,
  },
)(StoreButton)
