import { connect } from 'react-redux'
import MealDetailsScreen from '../components/MyMealsScreen/MealDetailsScreen'
import { openSnackbar } from '../bundles/snackbar'

export default connect(
  state => ({
    currentUser: state.currentUser.user,
    personalStats: state.currentUser.personalStats,
    allUserMeals: state.currentUser.allUserMeals,
  }),
  {
    openSnackbar,
  },
)(MealDetailsScreen)
