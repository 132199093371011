import React, { Component } from 'react'
import styled from 'styled-components'
import ArrowIcon from '@material-ui/icons/KeyboardArrowUp'
import { colorPalette } from '../styles/styleVariables'

// Style
const SlideToTopBtn = styled.div`
  position: fixed;
  z-index: 999;
  border-radius: 100%;
  height: 46px;
  width: 46px;
  background: ${colorPalette.primary1.main};
  bottom: 60px;
  right: 32px;
  border: 2px solid ${colorPalette.white.main};
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
  display: ${props => (props.visible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    color: ${colorPalette.white.main};
    height: 34px;
    width: 34px;
  }
`

// Consts

class SlideToTopButton extends Component {
  state = {
    showButton: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    if (window.scrollY > 400) {
      this.setState({ showButton: true })
    } else {
      this.setState({ showButton: false })
    }
  }

  handleClick = () => {
    //document.body.scrollTop = document.documentElement.scrollTop = 0
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  render() {
    const { showButton } = this.state
    return (
      <SlideToTopBtn visible={showButton} onClick={this.handleClick}>
        <ArrowIcon />
      </SlideToTopBtn>
    )
  }
}

export default SlideToTopButton
