import React, { Component } from 'react'
import styled from 'styled-components'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ShareIcon from '@material-ui/icons/Share'
import FacebookIcon from '@material-ui/icons/Facebook'
import EmailIcon from '@material-ui/icons/Email'
import LinkIcon from '@material-ui/icons/Link'
import TwitterIcon from '@material-ui/icons/Twitter'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import { mediaQuery, copyToClipboard } from '../services/global'
import { colorPalette, globalStyles } from '../styles/styleVariables'

// Consts

// Style
const SocialShareMenuWrapper = styled.div`
  height: 100%;
  position: relative;
  ${mediaQuery.phone`
    display: none;
  `}
`
const ShareButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 100%;
  padding: 10px;
  background-color: ${props => (props.isActive ? colorPalette.white.main : 'transparent')};
  color: ${props => (props.isActive ? colorPalette.primary1.main : colorPalette.white.main)};
  ${globalStyles.customButton}
  &:hover {
    svg {
      color: ${props => (props.isActive ? colorPalette.primary1.main : colorPalette.tertiary1.light)};
    }
  }
`
const OptionsMenu = styled.div`
  position: absolute;
  top: 44px;
  left: 0;
  background-color: ${colorPalette.white.main};
  ${globalStyles.boxRadius}
  ${globalStyles.boxShadowPopup}
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  overflow: hidden;
  min-width: 200px;
  transition: all 0.3s;
  height: ${props => (props.isActive ? '190px' : '0px')};
`
const StyledMenuList = styled(MenuList)`
  && {
    padding: 0;
  }
`
const StyledMenuItem = styled(MenuItem)`
  && {
    color: ${colorPalette.primary1.main};
    svg {
      color: ${colorPalette.primary1.main};
    }
  }
`
const StyledListItemIcon = styled(ListItemIcon)`
  && {
    min-width: 32px;
  }
`
const SocialShareExternalButton = styled.div`
  opacity: 0;
  a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

const SHARED_FREE_TEXT = 'FitThyBody - מאמני כושר בפריסה ארצית'

// Component
class SocialShareMenu extends Component {
  state = {
    shareButtonClicked: false,
  }

  // Lifecycle methods
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  // UI event methods
  handleMenuButtonClick = () => {
    const { shareButtonClicked } = this.state
    this.setState({ shareButtonClicked: !shareButtonClicked })
  }

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.buttonRef && !this.buttonRef.contains(event.target)) {
      this.setState({ shareButtonClicked: false })
    }
  }

  handleCopyLinkClick = () => {
    const { openSnackbar } = this.props
    copyToClipboard(window.location.href)
    openSnackbar({ message: 'הלינק הועתק!', severity: 'success' })
    this.setState({ shareButtonClicked: false })
  }

  setWrapperRef = node => {
    this.wrapperRef = node
  }

  setButtonRef = node => {
    this.buttonRef = node
  }

  render() {
    const { shareButtonClicked } = this.state
    const SHARED_URL = window.location.href
    return (
      <SocialShareMenuWrapper>
        <ShareButton isActive={shareButtonClicked} onClick={this.handleMenuButtonClick} ref={this.setButtonRef} title="שיתוף" type="button">
          <ShareIcon />
        </ShareButton>
        <OptionsMenu isActive={shareButtonClicked} ref={this.setWrapperRef}>
          <StyledMenuList>
            <StyledMenuItem>
              <StyledListItemIcon>
                <WhatsAppIcon />
              </StyledListItemIcon>
              <span>שיתוף בוואטסאפ</span>
              <SocialShareExternalButton>
                <div data-href={SHARED_URL} data-layout="button_count" data-size="small">
                  <a
                    onClick={() => {
                      window.open(`https://api.whatsapp.com/send?text=${SHARED_FREE_TEXT}%20${SHARED_URL}`, '_blank')
                    }}
                  />
                </div>
              </SocialShareExternalButton>
            </StyledMenuItem>
            <StyledMenuItem>
              <StyledListItemIcon>
                <FacebookIcon />
              </StyledListItemIcon>
              <span>שיתוף בפייסבוק</span>
              <SocialShareExternalButton>
                <div data-href={SHARED_URL} data-layout="button_count" data-size="small">
                  <a
                    onClick={() => {
                      window.open(`https://www.facebook.com/sharer/sharer.php?u=${SHARED_URL}%2F&amp;src=sdkpreparse`, '_blank')
                    }}
                  />
                </div>
              </SocialShareExternalButton>
            </StyledMenuItem>
            {/*<StyledMenuItem>
              <StyledListItemIcon>
                <InstagramIcon />
              </StyledListItemIcon>
              <span>שתף באינסטגרם</span>
            </StyledMenuItem>*/}
            <StyledMenuItem>
              <StyledListItemIcon>
                <TwitterIcon />
              </StyledListItemIcon>
              <span>שיתוף בטוויטר</span>
              <SocialShareExternalButton>
                <a
                  onClick={() => {
                    window.open(
                      `https://twitter.com/intent/tweet?hashtags=FitThyBody&original_referer=${SHARED_URL}&ref_src=twsrc%5Etfw&text=${SHARED_FREE_TEXT}&tw_p=tweetbutton&url=${SHARED_URL}&via=FitThyBody`,
                      '_blank',
                    )
                  }}
                />
              </SocialShareExternalButton>
            </StyledMenuItem>
            <StyledMenuItem>
              <StyledListItemIcon>
                <EmailIcon />
              </StyledListItemIcon>
              <span>שיתוף במייל</span>
              <SocialShareExternalButton>
                <a
                  onClick={() => {
                    window.open(`mailto:?subject=${SHARED_FREE_TEXT}&body=${SHARED_FREE_TEXT} ${SHARED_URL}`, '_blank')
                  }}
                />
              </SocialShareExternalButton>
            </StyledMenuItem>
            <StyledMenuItem onClick={this.handleCopyLinkClick}>
              <StyledListItemIcon>
                <LinkIcon />
              </StyledListItemIcon>
              <span>העתקת הלינק</span>
            </StyledMenuItem>
          </StyledMenuList>
        </OptionsMenu>
      </SocialShareMenuWrapper>
    )
  }
}

export default SocialShareMenu
