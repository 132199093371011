import React, { Component } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import draftToHtml from 'draftjs-to-html'
import backgroundImage from '../../images/dumbblesBg.jpg'
import { mediaQuery, siteName } from '../../services/global'
import { invokeAPIcall } from '../../services/rest'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import Breadcrumbs from '../Breadcrumbs'

// Consts

// Style
const PageWrapper = styled.div`
  background-color: ${colorPalette.white.main};
  background-image: url(${backgroundImage});
  background-size: 60% auto;
  background-position: top right;
  background-repeat: no-repeat;
  padding: 20px;
  flex: 1;
  ${mediaQuery.phone`
    background: none;
  `}
`
const PageItem = styled.div`
  width: 72%;
  margin-right: auto;
  margin-left: 9%;
  padding: 20px;
  background-color: ${colorPalette.white.main};
  color: ${colorPalette.black.main};
  a {
    color: ${colorPalette.black.main};
  }
  ${globalStyles.boxShadow}
  ${globalStyles.boxRadius}
  ${mediaQuery.phone`
    width: 95%;
    margin: 0;
  `}
`
const Title = styled.div`
  font-size: 34px;
  font-weight: 500;
  margin-bottom: 20px;
`
const Text = styled.div`
  p {
    margin: 0;
  }
`

// Component
class ContentPageScreen extends Component {
  state = {
    contentPageData: null,
  }

  // Lifecycle methods
  async componentDidMount() {
    const { match } = this.props
    const { params } = match
    this.loadContentPageData(params)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.pageKey !== nextProps.match.params.pageKey) {
      this.loadContentPageData(nextProps.match.params)
    }
  }

  // Helper methods
  loadContentPageData = async params => {
    const contentPageData = await invokeAPIcall({ uri: `api/contentPages/getByKey?pageKey=${params.pageKey}` })
    try {
      contentPageData.Text = draftToHtml(JSON.parse(contentPageData.Text))
    } catch (e) {}

    this.setState({ contentPageData })
  }

  // UI event methods

  // Render methods
  render() {
    const { contentPageData } = this.state
    if (!contentPageData) {
      return null
    }
    return (
      <PageWrapper>
        <Helmet>
          <title>
            {siteName} - {contentPageData.Title}
          </title>
        </Helmet>
        <Breadcrumbs previousItems={[]} currentItem={contentPageData.Title} />
        <PageItem>
          <Title>{contentPageData.Title}</Title>
          <Text dangerouslySetInnerHTML={{ __html: contentPageData.Text }} />
        </PageItem>
      </PageWrapper>
    )
  }
}

export default withRouter(ContentPageScreen)
