import React, { Component } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import { withRouter } from 'react-router-dom'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import { mediaQuery } from '../../services/global'
import { requiredValidation } from '../../services/formValidation'
import StyledTextField from '../StyledTextField'

// Style
const StyledDialogTitle = styled(DialogTitle)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
  }
`
const StyledButton = styled(Button)`
  && {
    margin-left: 10px;
  }
  ${mediaQuery.phone`
    && {
      margin-left: 0;
      margin-bottom: 10px;
      flex: 1;
    }
  `}
`
const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 40px;
  ${mediaQuery.phone`
    flex-direction: column;
    margin-top: 10px;
  `}
`

const FormWrapper = styled.div`
  padding: 20px 40px;
  padding-top: 0;
`
const FieldsRow = styled.div`
  margin: 20px 0;
  min-width: 460px;
  ${mediaQuery.phone`
    min-width: 0;
  `}
`

// Consts

// Component
class AdminCustomPaymentDialog extends Component {
  state = {
    initialValues: {
      customPaymentValue: '',
    },
  }

  // Lifecycle methods
  componentDidMount() {}

  // Helper methods
  validate = values => {
    let errors = {}

    if (requiredValidation(values.customPaymentValue)) {
      errors.customPaymentValue = requiredValidation(values.customPaymentValue)
    }

    return errors
  }

  // UI event methods
  handleSubmit = async (values, { setSubmitting }) => {
    const { handleClose } = this.props
    handleClose(values.customPaymentValue)
  }

  // Render methods
  render() {
    const { initialValues } = this.state
    const { handleClose } = this.props
    return (
      <Dialog onClose={handleClose} open>
        <StyledDialogTitle id="simple-dialog-title" disableTypography>
          <Typography variant="h6">ביצוע עסקה ידנית</Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <DialogContent>
          <Formik validate={this.validate} initialValues={initialValues} onSubmit={this.handleSubmit}>
            {formikBag => {
              const { isSubmitting, values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched } = formikBag
              return (
                <FormWrapper>
                  <Form>
                    <FieldsRow>
                      <StyledTextField
                        label={'סכום העסקה ב-ש״ח'}
                        helperText="הזן את הסכום אותו תרצה לסלוק"
                        name="customPaymentValue"
                        value={values.customPaymentValue}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.customPaymentValue && touched.customPaymentValue}
                        margin="normal"
                        fullWidth
                      />
                    </FieldsRow>
                    <ButtonsWrapper>
                      <StyledButton variant="contained" color="primary" type="submit">
                        אישור
                      </StyledButton>
                    </ButtonsWrapper>
                  </Form>
                </FormWrapper>
              )
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    )
  }
}

export default withRouter(AdminCustomPaymentDialog)
