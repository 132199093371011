import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { withRouter, Link } from 'react-router-dom'
import moment from 'moment'
import Carousel from 'react-bootstrap/Carousel'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FoodIcon from '@material-ui/icons/LocalDining'
import FitnessIcon from '@material-ui/icons/FitnessCenter'
import CalendarIcon from '@material-ui/icons/DateRange'
import MoreVertIcon from '@material-ui/icons/SwapHoriz'
import LockIcon from '@material-ui/icons/Lock'
import backgroundImage from '../../images/gymPhoto.jpg'
import { mediaQuery, mobileCheck, photoUrlPrefix } from '../../services/global'
import { invokeAPIcall } from '../../services/rest'
import { dayTimeOptions, foodTypeOptions } from '../../services/recipes'
import { calcDaysSinceStartedChallenge, isTrialUser } from '../../services/challengeProgressHelper'
import { colorPalette, globalStyles, convertHexToRGB } from '../../styles/styleVariables'
import Breadcrumbs from '../Breadcrumbs'
import WorkoutVideoDisplay from '../WorkoutVideoDisplay'
import SwapUserMealDialog from './SwapUserMealDialog'
import SwapUserWorkoutDialog from './SwapUserWorkoutDialog'
import DownloadCalendarDialog from './DownloadCalendarDialog'
import AddToHomeScreenDialog from './AddToHomeScreenDialog'

// Consts

// Style
const PageWrapper = styled.div`
  background-color: ${colorPalette.white.main};
  background-image: url(${backgroundImage});
  background-size: 50% auto;
  background-position: top right;
  background-repeat: no-repeat;
  padding: 20px;
  padding-top: 0;
  flex: 1;
  ${mediaQuery.phone`
    background: none;
    padding: 20px;
  `}
`
const DailyScheduleScreenContent = styled.div`
  display: flex;
  ${mediaQuery.phone`
    width: auto;
    padding: 0 10px;
    flex-direction: column;
  `}
`
const DailyScheduleTitleSection = styled.div`
  flex: 1;
  margin-left: 30px;
  ${mediaQuery.phone`
    margin-bottom: 30px;
    margin-left: 0;
  `}
`
const DailyScheduleTitle = styled.div`
  background-color: ${colorPalette.primary1.main};
  color: ${colorPalette.white.main};
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 4px 10px;
  display: flex;
  align-items: center;
`
const DailyScheduleTitleLabel = styled.div`
  color: ${colorPalette.white.main};
  font-size: 36px;
  font-weight: 500;
  margin-right: 20px;
  ${mediaQuery.tablet`
    font-size: 28px;
  `}
`
const iconCSS = css`
  && {
    width: 50px;
    height: 50px;
    color: ${colorPalette.white.main};
  }
`
const StyledCalendarIcon = styled(CalendarIcon)`
  ${iconCSS}
`
const DaysList = styled.ul`
  flex: 3;
  display: flex;
  position: relative;
  ${mediaQuery.phone`
    display: block;
  `}
`
const DayBox = styled.li`
  margin-left: 10px;
  flex: 1;
  ${mediaQuery.phone`
    width: 60%;
    margin: 0 auto;
  `}
`
const MealsSideLabels = styled.ul`
  position: absolute;
  right: -160px;
  top: 100px;
  ${mediaQuery.phone`
    display: none;
  `}
`
const MealsSideLabel = styled.li`
  height: 134px;
  text-align: left;
  font-size: 22px;
  font-weight: 500;
  text-shadow: 1px 1px 6px ${colorPalette.white.main};
`
const DayNameTitle = styled.h2`
  text-align: center;
  font-size: 18px;
  padding: 10px 0;
  background-color: ${props => (props.isToday ? colorPalette.primary2.main : colorPalette.primary1.main)};
  color: ${props => (props.isToday ? colorPalette.black.main : colorPalette.white.main)};
  ${globalStyles.boxRadius}
  ${mediaQuery.tablet`
    font-size: 15px;
  `}
`
const DayContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${props =>
    props.disabled
      ? `
    opacity: 0.3;
  `
      : ''}
  ${mediaQuery.phone`
  `}
`
const MealButtons = styled.ul`
  margin: 0;
`
const MealItemWrapper = styled.li`
  position: relative;
  ${mediaQuery.phone`
    margin-top: 36px;
  `}
`
const MealItem = styled.div`
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: ${colorPalette.primary1.main};
  color: ${colorPalette.white.main};
  ${globalStyles.boxRadius}
  &:hover {
    background-color: ${colorPalette.primary1.light};
  }
`
const MealInfo = styled.div`
  padding: 0 8px;
  padding-left: 22px;
  padding-bottom: 5px;
`
const MealTitle = styled.div`
  margin-top: 5px;
  font-size: 12px;
  font-weight: 500;
  height: 44px;
  display: flex;
  align-items: center;
  ${mediaQuery.tablet`
    font-size: 12px;
  `}
`
const MealSubtitle = styled.div`
  font-size: 12px;
`
const MealPhoto = styled.div`
  height: 70px;
  background-color: #eee;
  background-image: url('${props => props.photoUrl}');
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`
const WorkoutButtons = styled(MealButtons)``
const WorkoutItem = styled(MealItem)``
const WorkoutInfo = styled(MealInfo)``
const WorkoutTitle = styled(MealTitle)``
const WorkoutSubtitle = styled(MealSubtitle)`
  margin-top: 10px;
`
const VideoThumbnailImg = styled(MealPhoto)`
  background-image: url('http://img.youtube.com/vi/${props => props.youtubeCode}/0.jpg');
`
const StyledFoodIcon = styled(FoodIcon)`
  && {
    width: 60px;
    height: 60px;
  }
`
const StyledFitnessIcon = styled(FitnessIcon)`
  && {
    width: 60px;
    height: 60px;
    transform: rotate(-45deg);
  }
`
const WeekDatesLabel = styled.div`
  font-size: 18px;
  font-weight: 500;
  text-shadow: 0px 0px 3px ${colorPalette.white.main};
  ${mediaQuery.tablet`
    font-size: 14px;
  `}
`
const SwapIconWrapper = styled.div`
  position: absolute;
  top: 6px;
  left: 6px;
  display: flex;
`
const StyledSwapIcon = styled(MoreVertIcon)`
  && {
    width: 18px;
    height: 18px;
    color: ${colorPalette.white.main};
  }
`
const StyledButton = styled(Button)`
  && {
    margin-top: 20px;
  }
`
const AddToHomeScreenStyledButton = styled(StyledButton)`
  && {
    margin-right: 5px;
  }
`
const LoadingIndicatorWrapper = styled.div`
  flex: 3;
  display: flex;
  justify-content: center;
  margin: 10px 0;
  margin-bottom: 20px;
`
const MobileMealCategoryTitle = styled.div`
  display: none;
  position: absolute;
  top: -24px;
  right: 0;
  ${mediaQuery.phone`
    display: block;
  `}
`
const DisabledScheduleLayer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: ${props => (props.startLayerFromDay < 2 ? 0 : ((props.startLayerFromDay - 1) / 7) * 100)}%;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 80;
  opacity: 0;
  &:hover {
    opacity: 1;
  }
  ${mediaQuery.phone`
    top: 60px;
    bottom: 0;
    left: 62px;
    right: 64px;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    opacity: 1;
    font-size: 18px;
  `}
`
const LockIconWrapper = styled.div`
  margin-bottom: 6px;
`
const StyledCarousel = styled(Carousel)`
  && {
    .carousel-control-prev,
    .carousel-control-next {
      margin-top: 8px;
    }
  }
`

// Component
class MyDailyScheduleScreen extends Component {
  state = {
    dataLoaded: false,
    userMeals: [],
    userWorkouts: [],
    activeVideo: null,
    userMealDataToChangeViaDialog: null,
    userWorkoutDataToChangeViaDialog: null,
    personalStatsList: null,
    showDownloadCalendarDialog: null, // can be null, 'formMode' or 'successMode'
    showAddToHomeScreenDialog: false,
    isTrialUser: false,

    // for mobile, we display Carousel:
    isMobile: mobileCheck(),
    carouselIndex: 5, // start at index 5, which is the current day (0=last,5=current,6=first)
  }

  // Lifecycle methods
  async componentDidMount() {
    await this.loadPersonalStats()

    await this.loadUserMeals()
    await this.loadUserWorkouts()
  }

  // Helper methods
  loadPersonalStats = async () => {
    const personalStatsList = await invokeAPIcall({ uri: 'api/personalStats' })
    this.setState({
      personalStatsList /*isTrialUser: isTrialUser(currentUser) // TEMP DISABLE: we don't want to limit anything for trial user now */,
    })
  }

  loadUserMeals = async () => {
    const { personalStatsList, carouselIndex } = this.state
    const { currentUser } = this.props
    let daysSinceStartedChallenge = calcDaysSinceStartedChallenge(personalStatsList, currentUser)
    const originalDaysSinceStartedChallenge = daysSinceStartedChallenge
    if (daysSinceStartedChallenge < 1) {
      daysSinceStartedChallenge = 1
    }
    if (daysSinceStartedChallenge > 84) {
      daysSinceStartedChallenge = 84 // stop at 84, just to show a full week in the end of the 90days
    }

    let allUserMeals = await invokeAPIcall({ uri: 'api/userMeals' })

    if (allUserMeals) {
      const userMealsOfThisWeekOnly = allUserMeals.filter(
        meal => meal.DayInChallenge >= daysSinceStartedChallenge && meal.DayInChallenge < daysSinceStartedChallenge + 7,
      )
      this.setState({ userMeals: userMealsOfThisWeekOnly })

      // update the mobile carousel accordingly
      if (carouselIndex === 5 && originalDaysSinceStartedChallenge === 0) {
        this.setState({ carouselIndex: 6 }) // start at index 6, which is the current day (0=last,5=current,6=first)
      }
      if (carouselIndex === 5 && originalDaysSinceStartedChallenge > 84) {
        this.setState({ carouselIndex: 90 - originalDaysSinceStartedChallenge - 1 })
      }
    }
  }

  loadUserWorkouts = async () => {
    const { personalStatsList } = this.state
    const { currentUser } = this.props
    const daysSinceStartedChallenge_original = calcDaysSinceStartedChallenge(personalStatsList, currentUser)
    let daysSinceStartedChallenge = daysSinceStartedChallenge_original
    if (daysSinceStartedChallenge < 1) {
      daysSinceStartedChallenge = 1
    }
    if (daysSinceStartedChallenge > 84) {
      daysSinceStartedChallenge = 84 // stop at 84, just to show a full week in the end of the 90days
    }

    let allUserWorkouts = await invokeAPIcall({ uri: 'api/userWorkouts' })

    if (allUserWorkouts) {
      const userWorkoutsOfThisWeekOnly = allUserWorkouts.filter(
        workout => workout.DayInChallenge >= daysSinceStartedChallenge && workout.DayInChallenge < daysSinceStartedChallenge + 7,
      )
      this.setState({ userWorkouts: userWorkoutsOfThisWeekOnly, dataLoaded: true })

      // after 1 second, immediately pop up the video of the workout of the current day
      window.setTimeout(() => {
        const videoToDisplayNow = userWorkoutsOfThisWeekOnly.find(item => item.DayInChallenge === daysSinceStartedChallenge_original + 1)
        if (videoToDisplayNow) {
          this.setState({ activeVideo: videoToDisplayNow.Workout })
        }
      }, 1000)
    } else {
      this.setState({ dataLoaded: true })
    }
  }

  // UI event methods
  handleVideoClick = item => {
    if (item) {
      this.setState({ activeVideo: item })
    }
  }

  handleVideoCloseClick = () => {
    this.setState({ activeVideo: null })
  }

  handleNextVideoClick = () => {
    const { userWorkouts, activeVideo } = this.state
    let currentVideoIndex = 0
    userWorkouts.forEach((item, index) => {
      if (item.Workout && item.Workout.VideoID === activeVideo.VideoID) {
        currentVideoIndex = index
      }
    })
    let nextVideoIndex = currentVideoIndex + 1
    if (nextVideoIndex >= userWorkouts.length) {
      nextVideoIndex = 0
    }
    this.setState({ activeVideo: userWorkouts[nextVideoIndex].Workout })
  }

  handlePrevVideoClick = () => {
    const { userWorkouts, activeVideo } = this.state
    const currentVideoIndex = userWorkouts.findIndex(item => item.Workout && item.Workout.VideoID === activeVideo.VideoID)
    let prevVideoIndex = currentVideoIndex - 1
    if (prevVideoIndex < 0) {
      prevVideoIndex = userWorkouts.length - 1
    }
    this.setState({ activeVideo: userWorkouts[prevVideoIndex].Workout })
  }

  handleMealClick = linkToRecipe => {
    const { history } = this.props
    if (linkToRecipe) {
      history.push(linkToRecipe)
    }
  }

  handleSwapMeal = (data, e) => {
    this.setState({ userMealDataToChangeViaDialog: data })
    e.stopPropagation()
    return false
  }

  handleMealDialogClose = shouldRefreshList => {
    this.setState({ userMealDataToChangeViaDialog: null })
    if (shouldRefreshList) {
      this.loadUserMeals()
    }
  }

  handleSwapWorkout = (data, e) => {
    this.setState({ userWorkoutDataToChangeViaDialog: data })
    e.stopPropagation()
    return false
  }

  handleWorkoutDialogClose = shouldRefreshList => {
    this.setState({ userWorkoutDataToChangeViaDialog: null })
    if (shouldRefreshList) {
      this.loadUserWorkouts()
    }
  }

  handleDownloadCalendarClick = () => {
    this.setState({ showDownloadCalendarDialog: 'formMode' })
  }

  handleCalendarDialogClose = () => {
    this.setState({ showDownloadCalendarDialog: null })
  }

  handleAddToHomeScreenClick = () => {
    this.setState({ showAddToHomeScreenDialog: true })
  }

  handleAddToHomeScreenDialogClose = () => {
    this.setState({ showAddToHomeScreenDialog: false })
  }

  handleChallengePaymentLinkClick = () => {
    window.location = '/challengePayment'
  }

  handleCalendarDialogSubmit = (values, { setSubmitting }) => {
    /*const { userMeals, userWorkouts } = this.state
    const weekStartDate = moment().startOf('week')
    const workoutStartTime = moment(values.workoutTime).format('HH:mm:ss')
    const workoutEndTime = moment(values.workoutTime)
      .add(1, 'hours')
      .format('HH:mm')

    const calendarEvents = []
    // loop through the UserWorkouts and add them as CalendarEvents
    userWorkouts.forEach((item, index) => {
      const weekStartDateStr = weekStartDate.format('YYYY-MM-DD')
      if (item.Workout) {
        calendarEvents.push({
          Summary: item.Workout.Name,
          Location: 'אימון יומי',
          StartDateTime: weekStartDateStr + 'T' + workoutStartTime + '.000Z',
          EndDateTime: weekStartDateStr + 'T' + workoutEndTime + '.000Z',
        })
      }
      weekStartDate.add(1, 'days')
    })

    const calendarEventsStrings = calendarEvents.map((eventItem, index) => {
      return `events[${index}][Summary]=${eventItem.Summary}&events[${index}][Location]=${eventItem.Location}&events[${index}][StartDateTime]=${eventItem.StartDateTime}&events[${index}][EndDateTime]=${eventItem.EndDateTime}`
    })*/

    const { currentUser } = this.props
    const { personalStatsList } = this.state
    const daysSinceStartedChallenge = calcDaysSinceStartedChallenge(personalStatsList, currentUser) + 1
    const formatTime = timeVal => {
      const momentVal = moment(timeVal)
      return momentVal.format('YYYY-MM-DD') + 'T' + momentVal.format('HH:mm:ss') + '.000'
    }
    const formatDate = dateVal => {
      const momentVal = moment(dateVal)
      return momentVal.format('YYYY-MM-DD')
    }
    const querystring = `userID=${currentUser.UserID}&daysSinceStartedChallenge=${daysSinceStartedChallenge}&startInDay=${formatDate(
      values.startInDay,
    )}&breakfastTime=${formatTime(values.breakfastTime)}&lunchTime=${formatTime(values.lunchTime)}&dinnerTime=${formatTime(
      values.dinnerTime,
    )}&snack1Time=${formatTime(values.snack1Time)}&snack2Time=${formatTime(values.snack2Time)}&workoutTime=${formatTime(
      values.workoutTime,
    )}`

    if (window.cordova) {
      if (window.confirm('האפליקציה תעביר אותך כעת למסך ההורדה. לאחר מכן ניתן לפתוח אותה מחדש.')) {
        window.open(`https://www.fitthybody.com/fitthybodyAPI/api/calendar/DownloadCalendar?${querystring}`, '_system')

        if (window.device.platform.toLowerCase() === 'android') {
          this.setState({ showDownloadCalendarDialog: 'successMode' })
        } else {
          this.setState({ showDownloadCalendarDialog: null })
        }
      }
    } else {
      this.setState({ showDownloadCalendarDialog: 'successMode' })
      window.location = `https://www.fitthybody.com/fitthybodyAPI/api/calendar/DownloadCalendar?${querystring}`
    }
  }

  handleCarouselSelect = (selectedIndex, direction) => {
    // limit going to previous, if already on the first item
    if (direction === 'prev' && this.state.carouselIndex === 0) {
      return
    }
    // limit going to next, if already on the last item
    if (direction === 'next' && this.state.carouselIndex >= 6) {
      return
    }
    this.setState({
      carouselIndex: selectedIndex,
    })
  }

  // Render methods
  renderMealItem = userMeal => {
    const dayTimeOption = dayTimeOptions.filter(dayTimeItem => dayTimeItem.value === userMeal.dayTime)
    let dayTime = dayTimeOption.length > 0 ? dayTimeOption[0].label : '-'

    let challengePhase

    let userMealId = null
    let recipeId = null
    let linkToRecipe = null
    let recipeName = 'לא הוגדרה ארוחה'
    let amountOfMeals = null
    let durationInMins = null
    let foodTypeIDsLabel = '-'
    let photoObj = (
      <MealPhoto>
        <StyledFoodIcon />
      </MealPhoto>
    )

    if (userMeal && userMeal.meal) {
      userMealId = userMeal.meal.UserMealID

      challengePhase = 1
      if (userMeal.meal.DayInChallenge > 30 && userMeal.meal.DayInChallenge <= 60) {
        challengePhase = 2
      }
      if (userMeal.meal.DayInChallenge > 60 && userMeal.meal.DayInChallenge <= 90) {
        challengePhase = 3
      }

      const recipe = userMeal.meal.Recipe
      if (recipe) {
        recipeId = recipe.RecipeID
        linkToRecipe = `/myDailySchedule/myMeals/${recipe.RecipeID}`
        recipeName = recipe.Name
        amountOfMeals = recipe.AmountOfMeals
        durationInMins = recipe.DurationInMins

        if (recipe.FoodTypeIDs) {
          foodTypeIDsLabel = foodTypeOptions
            .filter(option => recipe.FoodTypeIDs.includes(option.value))
            .map(option => option.label)
            .join(', ')
        }

        let photoUrl = recipe.PhotoURL
        if (photoUrl) {
          if (!photoUrl.includes('http')) {
            photoUrl = `${photoUrlPrefix}/recipePhotos/${photoUrl}`
          }
        }
        photoObj = <MealPhoto photoUrl={photoUrl} />
      }
    }

    return (
      <MealItemWrapper>
        <MobileMealCategoryTitle>{dayTime}</MobileMealCategoryTitle>
        <MealItem onClick={userMeal.disabled ? this.handleChallengePaymentLinkClick : this.handleMealClick.bind(this, linkToRecipe)}>
          <SwapIconWrapper title="החלף ארוחה" className={userMeal.isToday ? 'wizard_dailyScheduleSwapIcon' : null}>
            <StyledSwapIcon
              onClick={
                userMeal.disabled
                  ? null
                  : this.handleSwapMeal.bind(this, { userMealId, recipeId, challengePhase, dayTime: userMeal.dayTime })
              }
            />
          </SwapIconWrapper>
          <Tooltip
            arrow
            disableHoverListener={userMeal.disabled || !linkToRecipe}
            title={
              !userMeal.disabled && linkToRecipe ? (
                <div>
                  <div>
                    כמות המנות: {amountOfMeals || '-'}
                    <div>זמן עבודה (בדקות): {durationInMins || '-'}</div>
                  </div>
                </div>
              ) : null
            }
          >
            <MealInfo>
              <MealTitle>{recipeName}</MealTitle>
            </MealInfo>
          </Tooltip>
          {photoObj}
        </MealItem>
      </MealItemWrapper>
    )
  }

  renderWorkoutItem = item => {
    const userWorkoutId = item.UserWorkoutID

    let challengePhase = 1
    if (item.DayInChallenge > 30 && item.DayInChallenge <= 60) {
      challengePhase = 2
    }
    if (item.DayInChallenge > 60 && item.DayInChallenge <= 90) {
      challengePhase = 3
    }

    let workoutVideoId = null
    let workoutName = 'לא הוגדר אימון'
    let photoObj = (
      <MealPhoto>
        <StyledFitnessIcon />
      </MealPhoto>
    )
    if (item.Workout) {
      workoutVideoId = item.Workout.VideoID
      workoutName = item.Workout.Name
      photoObj = <VideoThumbnailImg youtubeCode={item.Workout.YoutubeCode} />
    }

    return (
      <WorkoutItem onClick={item.disabled ? this.handleChallengePaymentLinkClick : this.handleVideoClick.bind(this, item.Workout)}>
        <SwapIconWrapper title="החלף אימון" className={item.isToday ? 'wizard_dailyScheduleSwapIcon' : null}>
          <StyledSwapIcon
            onClick={
              item.disabled
                ? null
                : this.handleSwapWorkout.bind(this, {
                    userWorkoutId,
                    workoutVideoId,
                    challengePhase,
                  })
            }
          />
        </SwapIconWrapper>
        <Tooltip
          arrow
          disableHoverListener={item.disabled || !item.Workout}
          title={
            !item.disabled && item.Workout ? (
              <div>
                {item.Workout.Description.split('\n').map(function(item, idx) {
                  return (
                    <span key={idx}>
                      {item}
                      <br />
                    </span>
                  )
                })}
              </div>
            ) : null
          }
        >
          <WorkoutInfo>
            <WorkoutTitle>{workoutName}</WorkoutTitle>
          </WorkoutInfo>
        </Tooltip>
        {photoObj}
      </WorkoutItem>
    )
  }

  renderDayItem = day => {
    const { currentUser } = this.props
    const { userMeals, userWorkouts, isTrialUser, personalStatsList, isMobile } = this.state
    const mealsListForDay = userMeals ? userMeals.filter(m => m.DayInChallenge === day) : []
    const workoutsListForDay = userWorkouts ? userWorkouts.filter(m => m.DayInChallenge === day) : []
    const daysSinceStartedChallenge = calcDaysSinceStartedChallenge(personalStatsList, currentUser) + 1

    const mealsListForDaySorted = []
    const breakfastOptions = mealsListForDay.filter(m => m.DayTime === 1)
    const snackOptions = mealsListForDay.filter(m => m.DayTime === 2)
    const lunchOptions = mealsListForDay.filter(m => m.DayTime === 3)
    const dinnerOptions = mealsListForDay.filter(m => m.DayTime === 4)
    // breakfast meal
    mealsListForDaySorted.push({ dayTime: 1, meal: breakfastOptions && breakfastOptions.length > 0 && breakfastOptions[0] })
    // snack meal 1
    mealsListForDaySorted.push({ dayTime: 2, meal: snackOptions && snackOptions.length > 0 && snackOptions[0] })
    // lunch meal
    mealsListForDaySorted.push({ dayTime: 3, meal: lunchOptions && lunchOptions.length > 0 && lunchOptions[0] })
    // snack meal 1
    mealsListForDaySorted.push({ dayTime: 2, meal: snackOptions && snackOptions.length > 0 && snackOptions[1] })
    // dinner meal
    mealsListForDaySorted.push({ dayTime: 4, meal: dinnerOptions && dinnerOptions.length > 0 && dinnerOptions[0] })

    const disabled = isTrialUser && day > 3
    const isToday = day === daysSinceStartedChallenge

    return (
      <DayBox>
        <DayNameTitle isToday={isToday} className={isToday ? 'wizard_currentDayInSchedule' : null}>
          יום {day} לאתגר
        </DayNameTitle>
        <DayContent disabled={disabled}>
          <WorkoutButtons>{workoutsListForDay.map(item => ({ ...item, disabled, isToday })).map(this.renderWorkoutItem)}</WorkoutButtons>
          <MealButtons>{mealsListForDaySorted.map(item => ({ ...item, disabled, isToday })).map(this.renderMealItem)}</MealButtons>
        </DayContent>
        {disabled && isMobile && this.renderDisabledScheduleLayer(0)}
      </DayBox>
    )
  }

  renderDisabledScheduleLayer = daysSinceStartedChallenge => {
    return (
      <DisabledScheduleLayer startLayerFromDay={5 - daysSinceStartedChallenge}>
        <>
          <LockIconWrapper title="ללקוחות פרימיום">
            <LockIcon />
          </LockIconWrapper>
          <div>מהפך ב-90 יום</div>
          <div>בריא יותר, חזק יותר, מאושר יותר</div>
          <Link to="/challengePayment">
            <StyledButton variant="contained" color="secondary" size="large">
              לתוכנית הפרימיום
            </StyledButton>
          </Link>
        </>
      </DisabledScheduleLayer>
    )
  }

  render() {
    const {
      activeVideo,
      userMealDataToChangeViaDialog,
      userWorkoutDataToChangeViaDialog,
      showDownloadCalendarDialog,
      showAddToHomeScreenDialog,
      dataLoaded,
      personalStatsList,
      isMobile,
      carouselIndex,
      isTrialUser,
    } = this.state
    const { currentUser } = this.props
    let daysSinceStartedChallenge = calcDaysSinceStartedChallenge(personalStatsList, currentUser)
    if (daysSinceStartedChallenge > 84) {
      daysSinceStartedChallenge = 84 // stop at 84, just to show a full week in the end of the 90days
    }
    if (daysSinceStartedChallenge < 1) {
      daysSinceStartedChallenge = 1
    }

    const weekStartDateString = moment().format('DD/MM/YYYY')
    const weekEndDate = moment()
      .add(6, 'days')
      .format('DD/MM/YYYY')

    const days = []
    for (let index = daysSinceStartedChallenge; index < daysSinceStartedChallenge + 7; index++) {
      if (index <= 90) {
        days.push(index)
      }
    }

    return (
      <PageWrapper>
        <Breadcrumbs
          previousItems={[
            {
              label: 'חוויית החיטוב ב-90 יום',
              url: `/program`,
            },
          ]}
          currentItem="התוכנית שלי"
        />
        <DailyScheduleScreenContent>
          <DailyScheduleTitleSection>
            <DailyScheduleTitle>
              <StyledCalendarIcon />
              <DailyScheduleTitleLabel>התוכנית שלי</DailyScheduleTitleLabel>
            </DailyScheduleTitle>
            <WeekDatesLabel>
              {weekStartDateString}-{weekEndDate}
            </WeekDatesLabel>
            <Tooltip arrow title="הורדת לוח האימונים והארוחות השבועי ליומן האישי שלך בטלפון או במחשב">
              <StyledButton
                variant="contained"
                color="primary"
                onClick={this.handleDownloadCalendarClick}
                className="wizard_downloadCalendar"
              >
                הורדה ליומן האישי
              </StyledButton>
            </Tooltip>
            {isMobile && !window.cordova && (
              <AddToHomeScreenStyledButton variant="contained" color="primary" onClick={this.handleAddToHomeScreenClick}>
                הוספה למסך הבית
              </AddToHomeScreenStyledButton>
            )}
          </DailyScheduleTitleSection>
          <DaysList>
            <MealsSideLabels>
              <MealsSideLabel>אימון יומי</MealsSideLabel>
              <MealsSideLabel>ארוחת בוקר</MealsSideLabel>
              <MealsSideLabel>נשנוש</MealsSideLabel>
              <MealsSideLabel>ארוחת צוהריים</MealsSideLabel>
              <MealsSideLabel>נשנוש</MealsSideLabel>
              <MealsSideLabel>ארוחת ערב</MealsSideLabel>
            </MealsSideLabels>
            {isTrialUser && !isMobile && this.renderDisabledScheduleLayer(daysSinceStartedChallenge)}
            {/* desktop version */}
            {dataLoaded && !isMobile && days.map(this.renderDayItem)}
            {/* mobile version, with carousel */}
            {dataLoaded && isMobile && (
              <StyledCarousel activeIndex={carouselIndex} onSelect={this.handleCarouselSelect} interval={null}>
                {days
                  .slice(0)
                  .reverse()
                  .map(item => (
                    <Carousel.Item>{this.renderDayItem(item)}</Carousel.Item>
                  ))}
              </StyledCarousel>
            )}
            {!dataLoaded && (
              <LoadingIndicatorWrapper>
                <CircularProgress />
              </LoadingIndicatorWrapper>
            )}
          </DaysList>
        </DailyScheduleScreenContent>
        {userMealDataToChangeViaDialog !== null && (
          <SwapUserMealDialog
            userMealData={userMealDataToChangeViaDialog}
            handleClose={this.handleMealDialogClose}
            isTrialUser={isTrialUser}
            personalStats={personalStatsList}
          />
        )}
        {userWorkoutDataToChangeViaDialog !== null && (
          <SwapUserWorkoutDialog
            userWorkoutData={userWorkoutDataToChangeViaDialog}
            handleClose={this.handleWorkoutDialogClose}
            isTrialUser={isTrialUser}
          />
        )}
        {showDownloadCalendarDialog && (
          <DownloadCalendarDialog
            mode={showDownloadCalendarDialog}
            handleClose={this.handleCalendarDialogClose}
            handleSubmit={this.handleCalendarDialogSubmit}
          />
        )}
        {showAddToHomeScreenDialog && <AddToHomeScreenDialog handleClose={this.handleAddToHomeScreenDialogClose} />}
        {activeVideo && (
          <WorkoutVideoDisplay
            activeVideo={activeVideo}
            handleClose={this.handleVideoCloseClick}
            handleNextVideoClick={this.handleNextVideoClick}
            handlePrevVideoClick={this.handlePrevVideoClick}
          />
        )}
      </PageWrapper>
    )
  }
}

export default withRouter(MyDailyScheduleScreen)
