import React, { Component } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import { withRouter } from 'react-router-dom'
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import formBackgroundLeft from '../../images/formBackground_left.jpg'
import formBackgroundRight from '../../images/formBackground_right.jpg'
import { mediaQuery, keysToLowerCase } from '../../services/global'
import { requiredValidation } from '../../services/formValidation'
import { invokeAPIcall, wysiwygUploadCallback } from '../../services/rest'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import StyledTextField from '../StyledTextField'
import Breadcrumbs from '../Breadcrumbs'
import ColorPic from '../ColorPic'

// Style
const PageWrapper = styled.div`
  background-color: #f5fcfd;
  background: url(${formBackgroundLeft}) left bottom no-repeat, url(${formBackgroundRight}) right bottom no-repeat, #f7fdfd;
  background-size: 40% auto, 35% auto;
  padding: 20px;
  flex: 1;
  ${mediaQuery.phone`
    background: none;
  `}
`
const FormWrapper = styled.div`
  background-color: ${colorPalette.white.main};
  max-width: 680px;
  margin: 0 auto;
  padding: 20px;
  color: ${colorPalette.primary1.main};
  ${globalStyles.boxShadow}
  ${globalStyles.boxRadius}
`
const PageTitle = styled.h1`
  margin-bottom: 20px;
`
const FieldsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
`
const FieldCell = styled.div`
  flex: 0 0 100%;
`
const WYSIWYGFieldCell = styled(FieldCell)`
  direction: ltr;
  border: 1px solid #ddd;
  padding-bottom: 5px;
  .wrapper {
    height: 400px;
  }
  .editer-content {
    height: 82%;
  }
`
const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  margin-top: 20px;
  ${mediaQuery.phone`
    width: 100%;
  `}
`
const StyledButton = styled(Button)`
  && {
  }
`
const LoadingIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5px;
`

// Component
class AddContentPageScreen extends Component {
  state = {
    initialValues: {
      title: '',
      pageKey: '',
      text: '',
    },
    editorState: EditorState.createEmpty(),
  }

  // Lifecycle methods
  componentDidMount() {
    const editModeId = this.getEditModeId()
    if (editModeId != null) {
      this.loadContentPageData(editModeId)
    }
  }

  // Helper methods
  getEditModeId = () => {
    const { match } = this.props
    const { params } = match
    return params.id
  }

  loadContentPageData = async id => {
    let contentPageData = await invokeAPIcall({ uri: `api/contentPages/${id}` })
    contentPageData = keysToLowerCase(contentPageData)

    const state = {
      initialValues: contentPageData,
    }
    try {
      state.editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(contentPageData.text)))
    } catch (error) {}
    this.setState(state)
  }

  validate = values => {
    let errors = {}

    if (requiredValidation(values.title)) {
      errors.title = requiredValidation(values.title)
    }
    if (requiredValidation(values.pageKey)) {
      errors.pageKey = requiredValidation(values.pageKey)
    }
    if (requiredValidation(values.text)) {
      errors.text = requiredValidation(values.text)
    }

    return errors
  }

  // UI event methods
  handleSubmit = async (values, { setSubmitting }) => {
    const editModeId = this.getEditModeId()
    const isEditMode = editModeId != null
    const { history } = this.props
    const { editorState } = this.state
    const data = {
      ...values,
      text: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
    }

    // invoke API call
    try {
      const responseData = await invokeAPIcall({
        uri: isEditMode ? `api/contentPages/${editModeId}` : 'api/contentPages',
        data,
        method: isEditMode ? 'PUT' : 'POST',
      })

      // if API success
      if (responseData.Key === true) {
        history.push('/contentPagesAdmin')
      }
      // if failed
      else {
        alert(responseData.Value || 'התרחשה שגיאה')
      }
    } catch (error) {
      console.log('error in adding/updating new contentPage', error)
    }
    setSubmitting(false)
  }

  onEditorStateChange = editorState => {
    this.setState({
      editorState,
    })
  }

  // Render methods
  render() {
    const { initialValues, editorState } = this.state
    const editModeId = this.getEditModeId()
    const isEditMode = editModeId != null

    return (
      <PageWrapper>
        <Breadcrumbs
          previousItems={[
            {
              label: `מסכי ניהול`,
              url: `/adminPanel`,
            },
          ]}
          currentItem={`ניהול עמודי תוכן`}
        />
        <FormWrapper>
          <PageTitle>{isEditMode ? 'עדכון עמוד תוכן' : 'הוספת עמוד תוכן חדש'}</PageTitle>
          <Formik validate={this.validate} initialValues={initialValues} onSubmit={this.handleSubmit} enableReinitialize={true}>
            {formikBag => {
              const { isSubmitting, values, errors, touched, handleChange, handleBlur, isValid, setFieldValue } = formikBag
              return (
                <Form>
                  <FieldsRow>
                    <FieldCell>
                      <StyledTextField
                        label={'כותרת עמוד תוכן'}
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.title && touched.title && errors.title}
                        error={errors.title && touched.title}
                        margin="normal"
                        fullWidth
                      />
                    </FieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <FieldCell>
                      <StyledTextField
                        label={'קוד'}
                        name="pageKey"
                        value={values.pageKey}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.pageKey && touched.pageKey && errors.pageKey}
                        error={errors.pageKey && touched.pageKey}
                        margin="normal"
                        fullWidth
                      />
                    </FieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <div>
                      <br />
                      תוכן העמוד
                    </div>
                    <WYSIWYGFieldCell>
                      <Editor
                        editorState={editorState}
                        wrapperClassName="wrapper"
                        editorClassName="editer-content"
                        onEditorStateChange={e => {
                          setFieldValue('text', 'content', true)
                          this.onEditorStateChange(e)
                        }}
                        toolbar={{
                          image: {
                            uploadEnabled: true,
                            uploadCallback: wysiwygUploadCallback,
                            defaultSize: {
                              height: 'auto',
                              width: '477',
                            },
                          },
                          colorPicker: {
                            component: ColorPic,
                          },
                        }}
                      />
                    </WYSIWYGFieldCell>
                  </FieldsRow>
                  <ButtonsWrapper>
                    <StyledButton variant="contained" color="primary" fullWidth disabled={!isValid || isSubmitting} type="submit">
                      {isEditMode ? 'עדכן' : 'הוסף'}
                    </StyledButton>
                  </ButtonsWrapper>
                  {isSubmitting && (
                    <LoadingIndicatorWrapper>
                      <CircularProgress />
                    </LoadingIndicatorWrapper>
                  )}
                </Form>
              )
            }}
          </Formik>
        </FormWrapper>
      </PageWrapper>
    )
  }
}

export default withRouter(AddContentPageScreen)
