import React, { Component } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import { withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress'
import formBackgroundLeft from '../../images/formBackground_left.jpg'
import formBackgroundRight from '../../images/formBackground_right.jpg'
import { mediaQuery, keysToLowerCase } from '../../services/global'
import { requiredValidation } from '../../services/formValidation'
import { invokeAPIcall } from '../../services/rest'
import { showInChallengePhaseOptions } from '../../services/workoutVideos'
import { colorPalette, globalStyles } from '../../styles/styleVariables'
import StyledTextField from '../StyledTextField'
import StyledSelect from '../StyledSelect'
import StyledCheckbox from '../StyledCheckbox'
import Breadcrumbs from '../Breadcrumbs'

// Style
const PageWrapper = styled.div`
  background-color: #f5fcfd;
  background: url(${formBackgroundLeft}) left bottom no-repeat, url(${formBackgroundRight}) right bottom no-repeat, #f7fdfd;
  background-size: 40% auto, 35% auto;
  padding: 20px;
  flex: 1;
  ${mediaQuery.phone`
    background: none;
  `}
`
const FormWrapper = styled.div`
  background-color: ${colorPalette.white.main};
  max-width: 680px;
  margin: 0 auto;
  padding: 20px;
  color: ${colorPalette.primary1.main};
  ${globalStyles.boxShadow}
  ${globalStyles.boxRadius}
`
const PageTitle = styled.h1`
  margin-bottom: 20px;
`
const FieldsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
`
const FieldCell = styled.div`
  flex: 0 0 100%;
`
const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  margin-top: 20px;
  ${mediaQuery.phone`
    width: 100%;
  `}
`
const StyledButton = styled(Button)`
  && {
  }
`
const VideoThumbnailImg = styled.div`
  width: 150px;
  height: 150px;
  background: url('http://img.youtube.com/vi/${props => props.youtubeCode}/0.jpg');
  background-position: center;
  background-size: cover;
  margin: auto;
`
const LoadingIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5px;
`

// Component
class AddWorkoutVideoScreen extends Component {
  state = {
    initialValues: {
      name: '',
      description: '',
      youtubeCode: '',
      showInChallengePhase: null,
      workoutStartTimeSecs: '',
      noEquipment: false,
    },
  }

  // Lifecycle methods
  componentDidMount() {
    const editModeId = this.getEditModeId()
    if (editModeId != null) {
      this.loadVideoData(editModeId)
    }
  }

  // Helper methods
  getEditModeId = () => {
    const { match } = this.props
    const { params } = match
    return params.id
  }

  loadVideoData = async id => {
    let videoData = await invokeAPIcall({ uri: `api/workoutVideos/${id}` })
    videoData = keysToLowerCase(videoData)
    videoData = { ...videoData, workoutStartTimeSecs: this.convertSecondsToDisplayTime(videoData.workoutStartTimeSecs) }
    this.setState({
      initialValues: videoData,
    })
  }

  validate = values => {
    let errors = {}

    if (requiredValidation(values.name)) {
      errors.name = requiredValidation(values.name)
    }
    if (requiredValidation(values.youtubeCode)) {
      errors.youtubeCode = requiredValidation(values.youtubeCode)
    }
    if (requiredValidation(values.showInChallengePhase)) {
      errors.showInChallengePhase = requiredValidation(values.showInChallengePhase)
    }

    return errors
  }

  convertYoutubeUrlToYoutubeCode = youtubeUrl => {
    if (youtubeUrl && youtubeUrl.includes('http')) {
      // if it's a youtube full url (like: https://www.youtube.com/watch?v=qCpsLJMlMC0) - cut the youtubeCode from it (after 'v=' param)
      const youtubeQuerystringPart = youtubeUrl.split('watch?')[1]
      const vars = youtubeQuerystringPart.split('&')
      for (let i = 0; i < vars.length; i += 1) {
        const pair = vars[i].split('=')
        if (decodeURIComponent(pair[0]) === 'v') {
          return decodeURIComponent(pair[1])
        }
      }
    }
    return youtubeUrl
  }

  convertSecondsToDisplayTime = seconds => {
    return `${Math.floor(seconds / 60)}:${seconds % 60}`
  }

  convertDisplayTimeToSeconds = displayTime => {
    const parts = displayTime.split(':')
    const minutes = parts[0]
    const seconds = parts[1]
    return Number(minutes) * 60 + Number(seconds)
  }

  // UI event methods
  handleSubmit = async (values, { setSubmitting }) => {
    const editModeId = this.getEditModeId()
    const isEditMode = editModeId != null
    const { history } = this.props
    const data = {
      ...values,
      youtubeCode: this.convertYoutubeUrlToYoutubeCode(values.youtubeCode),
      workoutStartTimeSecs: this.convertDisplayTimeToSeconds(values.workoutStartTimeSecs),
    }

    // invoke API call
    try {
      const responseData = await invokeAPIcall({
        uri: isEditMode ? `api/workoutVideos/${editModeId}` : 'api/workoutVideos',
        data,
        method: isEditMode ? 'PUT' : 'POST',
      })

      // if API success
      if (responseData.Key === true) {
        history.push('/workoutVideosAdmin')
      }
      // if failed
      else {
        alert(responseData.Value || 'התרחשה שגיאה')
      }
    } catch (error) {
      console.log('error in adding/updating new video', error)
    }
    setSubmitting(false)
  }

  // Render methods
  render() {
    const { initialValues } = this.state
    const editModeId = this.getEditModeId()
    const isEditMode = editModeId != null

    return (
      <PageWrapper>
        <Breadcrumbs
          previousItems={[
            {
              label: `מסכי ניהול`,
              url: `/adminPanel`,
            },
          ]}
          currentItem={`ניהול סרטוני אימונים`}
        />
        <FormWrapper>
          <PageTitle>{isEditMode ? 'עדכון סרטון אימון' : 'הוספת סרטון אימון חדש'}</PageTitle>
          <Formik validate={this.validate} initialValues={initialValues} onSubmit={this.handleSubmit} enableReinitialize={true}>
            {formikBag => {
              const { isSubmitting, values, errors, touched, handleChange, handleBlur, isValid } = formikBag
              return (
                <Form>
                  <FieldsRow>
                    <FieldCell>
                      <StyledTextField
                        label={'כותרת אימון'}
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.name && touched.name && errors.name}
                        error={errors.name && touched.name}
                        margin="normal"
                        fullWidth
                      />
                    </FieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <FieldCell>
                      <StyledTextField
                        label={'תיאור אימון'}
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.description && touched.description && errors.description}
                        error={errors.description && touched.description}
                        margin="normal"
                        fullWidth
                        multiline
                        rows="3"
                      />
                    </FieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <FieldCell>
                      <StyledSelect
                        selectParams={{
                          name: 'showInChallengePhase',
                          value: values.showInChallengePhase,
                          onChange: handleChange,
                          onBlur: handleBlur,
                          margin: 'normal',
                          fullWidth: true,
                          inputProps: {
                            name: 'showInChallengePhase',
                            id: 'showInChallengePhase-simple',
                          },
                        }}
                        label="תקופה באתגר"
                        htmlFor="showInChallengePhase-simple"
                        helperText={errors.showInChallengePhase && touched.showInChallengePhase && errors.showInChallengePhase}
                        error={errors.showInChallengePhase && touched.showInChallengePhase}
                        inputLabelShrink={values.showInChallengePhase}
                      >
                        {showInChallengePhaseOptions.map(item => (
                          <MenuItem value={item.value}>{item.label}</MenuItem>
                        ))}
                      </StyledSelect>
                    </FieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <FieldCell>
                      <StyledTextField
                        label={'קישור לסרטון ב-YouTube'}
                        name="youtubeCode"
                        value={values.youtubeCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.youtubeCode && touched.youtubeCode
                            ? errors.youtubeCode
                            : 'כאן יש להזין קישור ליוטיוב, דוגמה: https://www.youtube.com/watch?v=qCpsLJMlMC0'
                        }
                        error={errors.youtubeCode && touched.youtubeCode}
                        margin="normal"
                        fullWidth
                      />
                      {values.youtubeCode && <VideoThumbnailImg youtubeCode={this.convertYoutubeUrlToYoutubeCode(values.youtubeCode)} />}
                    </FieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <FieldCell>
                      <StyledTextField
                        label={'זמן תחילת האימון'}
                        name="workoutStartTimeSecs"
                        value={values.workoutStartTimeSecs}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText="למשל: 6:35"
                        error={errors.workoutStartTimeSecs && touched.workoutStartTimeSecs}
                        margin="normal"
                        fullWidth
                      />
                    </FieldCell>
                  </FieldsRow>
                  <FieldsRow>
                    <FieldCell>
                      <StyledCheckbox
                        label={'האם אימון ללא ציוד? (ישובץ בשבוע ראשון)'}
                        name="noEquipment"
                        value={values.noEquipment}
                        checked={values.noEquipment}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.noEquipment && touched.noEquipment && errors.noEquipment}
                        error={errors.noEquipment && touched.noEquipment}
                        margin="normal"
                        fullWidth
                      />
                    </FieldCell>
                  </FieldsRow>
                  <ButtonsWrapper>
                    <StyledButton variant="contained" color="primary" fullWidth disabled={!isValid || isSubmitting} type="submit">
                      {isEditMode ? 'עדכן' : 'הוסף'}
                    </StyledButton>
                  </ButtonsWrapper>
                  {isSubmitting && (
                    <LoadingIndicatorWrapper>
                      <CircularProgress />
                    </LoadingIndicatorWrapper>
                  )}
                </Form>
              )
            }}
          </Formik>
        </FormWrapper>
      </PageWrapper>
    )
  }
}

export default withRouter(AddWorkoutVideoScreen)
