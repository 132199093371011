import React, { Component } from 'react'
import moment from 'moment'
import KeyboardDatePicker from '../StyledDatePicker'

// Style

// Consts

class BirthDateField extends Component {
  render() {
    const { values, errors, touched, setFieldValue, setFieldTouched } = this.props
    return (
      <KeyboardDatePicker
        ampm={false}
        autoOk
        fullWidth
        variant="inline"
        label="תאריך לידה *"
        name="birthDate"
        format="DD/MM/YYYY"
        value={values.birthDate}
        onChange={(value, e) => {
          if (value) {
            var m = value.utcOffset(2)
            m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            m.toISOString()
            m.format()
            m.add(2, 'hours')
            setFieldValue('birthDate', m, true)
          } else {
            setFieldValue('birthDate', null, true)
          }
          setFieldTouched('birthDate', true, true)
        }}
        invalidDateMessage="תאריך לא תקין"
        maxDate={moment().subtract(10, 'years')}
        minDate={moment().subtract(100, 'years')}
        initialFocusedDate={moment().subtract(30, 'years')}
        openTo="year"
        views={['year', 'month', 'date']}
        helperText={errors.birthDate && touched.birthDate && errors.birthDate}
        error={errors.birthDate && touched.birthDate}
      />
    )
  }
}

export default BirthDateField
