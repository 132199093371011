import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import FitnessIcon from '@material-ui/icons/FitnessCenter'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import naturalExtractIcon from '../../images/naturalExtractIcon.png'
import backgroundImage from '../../images/leavesBg.jpg'
import { mediaQuery, siteName, photoUrlPrefix } from '../../services/global'
import { invokeAPIcall } from '../../services/rest'
import { colorPalette } from '../../styles/styleVariables'
import StyledSelect from '../StyledSelect'
import Breadcrumbs from '../Breadcrumbs'
import ProductPriceTag from '../ProductPriceTag'

// Consts

// Style
const PageWrapper = styled.div`
  background-color: ${colorPalette.white.main};
  min-height: 640px;
  ${props =>
    props.category.toString() !== '1'
      ? `
    background-image: url(${backgroundImage});
    background-size: 34% auto;
    background-position: top left;
    background-repeat: no-repeat;
  `
      : ''}
  padding: 20px;
  ${mediaQuery.phone`
    background: none;
  `}
`
const ProductScreenContent = styled.div`
  display: flex;
  ${mediaQuery.phone`
    width: auto;
    padding: 0 10px;
    flex-direction: column;
  `}
`
const ProductTitleSection = styled.div`
  flex: 1;
  margin-left: 30px;
  ${mediaQuery.phone`
    margin-bottom: 30px;
    margin-left: 0;
  `}
`
const ProductTitle = styled.div`
  background-color: ${colorPalette.primary1.main};
  color: ${colorPalette.white.main};
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 4px 10px;
  display: flex;
  align-items: center;
`
const ProductTitleLabel = styled.div`
  color: ${colorPalette.white.main};
  font-size: 36px;
  font-weight: 500;
  margin-right: 20px;
  ${mediaQuery.phone`
    font-size: 30px;
  `}
`
const ProductItem = styled.div`
  display: flex;
  margin: 0 auto;
  position: relative;
  flex: 2;
`
const ProductDetails = styled.div`
  ${props =>
    props.category.toString() !== '1'
      ? `
      max-width: 65%;
  `
      : ''}
  ${mediaQuery.phone`
    max-width: initial;
  `}
`
const ProductName = styled.div`
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
`
const ProductPriceWrapperParent = styled.div``
const ProductPriceWrapper = styled.div`
  background-color: ${colorPalette.primary1.main};
  color: ${colorPalette.white.main};
  border-radius: 10px;
  padding: 10px 20px;
  margin-left: 20px;
  & > div {
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      margin-right: 0;
      margin-left: 10px;
      font-size: 24px;
    }
  }
  ${mediaQuery.phone`
    margin-left: 0;
  `}
`
const ProductDescription = styled.div`
  min-height: 60px;
  white-space: pre-line;
  margin-bottom: 20px;
`
const DisplayPricePerItemLabel = styled.div`
  font-size: 13px;
`
const ProductPhoto = styled.div`
  width: 100%;
  height: 420px;
  background-image: url('${props => props.photoUrl}');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 auto;
  border-radius: 10px;
  ${mediaQuery.phone`
    height: 200px;
  `}
`
const ProductPhotoZoom = styled(ProductPhoto)`
  position: absolute;
  top: 80px;
  right: 0;
  height: 500px;
  width: 500px;
  background-size: 200%;
  z-index: 10;
`
const PriceAndAddToCartWrapper = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: center;
  ${mediaQuery.phone`
    flex-direction: column;
  `}
`
const AddToCartBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  .MuiFormControl-root {
    margin: 0;
  }
  .MuiSelect-root {
    padding: 7.5px 24px;
  }
  & > div {
    display: none;
  }
  ${mediaQuery.phone`
    margin-top: 20px;
  `}
`
const AddToCartBtn = styled(Button)`
  && {
    ${mediaQuery.phone`
    width: 100%;
  `}
  }
`
const iconCSS = css`
  && {
    width: 50px;
    height: 50px;
    color: ${colorPalette.white.main};
  }
`
const StyledFitnessIcon = styled(FitnessIcon)`
  ${iconCSS}
  transform: rotate(-45deg);
`
const NaturalProductsIcon = styled.div`
  width: 40px;
  height: 40px;
  background-image: url(${naturalExtractIcon});
  background-size: contain;
  background-repeat: no-repeat;
`
const PhotoList = styled.ul`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`
const PhotoListItem = styled.li`
  width: 50px;
  height: 50px;
  margin: 0 5px;
  background-image: url('${props => props.photoUrl}');
  background-position: center;
  background-size: cover;
  cursor: pointer;
  border: 1px solid ${props => (props.isSelected ? colorPalette.primary1.light : colorPalette.white.main)};
`

// Component
class ProductDetailsScreen extends Component {
  state = {
    productData: null,
    selectedQuantity: 1,
    globalSettingsData: {},
    zoomedBackgroundPosition: null,
    selectedPhotoIndex: 0,
  }

  // Lifecycle methods
  async componentDidMount() {
    const { match } = this.props
    const { params } = match
    const productData = await invokeAPIcall({ uri: `api/products/getByUrlCode?urlCode=${params.urlCode}` })
    this.setState({ productData })

    this.loadGlobalSettingsData()
  }

  // Helper methods
  loadGlobalSettingsData = async id => {
    let globalSettingsData = await invokeAPIcall({ uri: `api/globalSettings/1` })
    this.setState({
      globalSettingsData: globalSettingsData,
    })
  }

  // UI event methods
  handleAddToCart = () => {
    const { productData, selectedQuantity } = this.state
    const { addProductToCart, history } = this.props
    addProductToCart({ productId: productData.ProductID, quantity: selectedQuantity })

    window.setTimeout(() => {
      //history.push('/myCart')
    }, 500)
  }

  handleQuantityChange = e => {
    this.setState({ selectedQuantity: e.target.value })
  }

  handleMouseMove = e => {
    const { left, top, width, height } = e.target.getBoundingClientRect()
    const x = ((e.clientX - left) / width) * 100
    const y = ((e.clientY - top) / height) * 100
    this.setState({ zoomedBackgroundPosition: `${x}% ${y}%` })
  }

  handleMouseOut = () => {
    this.setState({ zoomedBackgroundPosition: null })
  }

  handlePhotoListItemClick = selectedPhotoIndex => {
    this.setState({ selectedPhotoIndex })
  }

  // Render methods
  render() {
    const { productData, selectedQuantity, globalSettingsData, zoomedBackgroundPosition, selectedPhotoIndex } = this.state
    if (!productData) {
      return null
    }
    let photoUrl = productData.ProductPhotos[selectedPhotoIndex] && productData.ProductPhotos[selectedPhotoIndex].PhotoURL
    if (photoUrl) {
      if (!photoUrl.includes('http')) {
        photoUrl = `${photoUrlPrefix}/productPhotos/${photoUrl}`
      }
    }
    const productCategoryLabel = productData.ProductCategoryID === 1 ? 'מאמנים אישיים' : 'מוצרים בשבילך'
    return (
      <PageWrapper category={productData.ProductCategoryID}>
        <Helmet>
          <title>
            {siteName} - {productData.Name}
          </title>
          <meta name="keywords" CONTENT={`${globalSettingsData.PageKeywords}${productData.Keywords ? `,${productData.Keywords}` : ''}`} />
        </Helmet>
        <Breadcrumbs
          previousItems={[
            {
              label: productCategoryLabel,
              url: `/store/${productData.ProductCategoryID}`,
            },
          ]}
          currentItem={productData.Name}
        />
        <ProductScreenContent>
          <ProductTitleSection>
            <ProductTitle>
              {productData.ProductCategoryID === 1 ? <StyledFitnessIcon /> : <NaturalProductsIcon />}
              <ProductTitleLabel>{productCategoryLabel}</ProductTitleLabel>
            </ProductTitle>
            <ProductPhoto photoUrl={photoUrl} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseOut} />
            {productData.ProductPhotos.length > 1 && (
              <PhotoList>
                {productData.ProductPhotos.map((photo, index) => {
                  let photoUrl = photo.PhotoURL
                  if (photoUrl) {
                    if (!photoUrl.includes('http')) {
                      photoUrl = `${photoUrlPrefix}/productPhotos/${photoUrl}`
                    }
                  }
                  return (
                    <PhotoListItem
                      photoUrl={photoUrl}
                      isSelected={selectedPhotoIndex === index}
                      onClick={this.handlePhotoListItemClick.bind(this, index)}
                    />
                  )
                })}
              </PhotoList>
            )}
            <PriceAndAddToCartWrapper>
              <ProductPriceWrapperParent>
                <ProductPriceWrapper>
                  <ProductPriceTag productItem={productData} globalSettingsData={globalSettingsData} />
                </ProductPriceWrapper>
                {productData.DisplayPricePerItem != null && productData.DisplayPricePerItem != 0 && (
                  <DisplayPricePerItemLabel>{`₪${productData.DisplayPricePerItem.toLocaleString()} ${productData.DisplayPricePerItemWord ||
                    ''}`}</DisplayPricePerItemLabel>
                )}
              </ProductPriceWrapperParent>
              <AddToCartBtnWrapper>
                <StyledSelect
                  selectParams={{
                    name: `${productData.ProductID}_quantity`,
                    value: selectedQuantity,
                    onChange: this.handleQuantityChange,
                    margin: 'normal',
                    inputProps: {
                      name: `${productData.ProductID}_quantity`,
                      id: `${productData.ProductID}_quantity-simple`,
                    },
                  }}
                  htmlFor={`${productData.ProductID}_quantity-simple`}
                  variant="outlined"
                  fullWidth={false}
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(item => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </StyledSelect>
                <AddToCartBtn
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<ShoppingCartIcon />}
                  onClick={this.handleAddToCart}
                >
                  הוסף לעגלה
                </AddToCartBtn>
              </AddToCartBtnWrapper>
            </PriceAndAddToCartWrapper>
          </ProductTitleSection>
          <ProductItem>
            {zoomedBackgroundPosition && <ProductPhotoZoom photoUrl={photoUrl} style={{ backgroundPosition: zoomedBackgroundPosition }} />}
            <ProductDetails category={productData.ProductCategoryID}>
              <ProductName>{productData.Name}</ProductName>
              <ProductDescription>{productData.Description}</ProductDescription>
            </ProductDetails>
          </ProductItem>
        </ProductScreenContent>
      </PageWrapper>
    )
  }
}

export default withRouter(ProductDetailsScreen)
