// Actions
const SET_CURRENT_USER = 'currentUser/setCurrentUser'
const SET_ACCESS_TOKEN = 'currentUser/setAccessToken'
const SET_PERSONAL_STATS = 'currentUser/setPersonalStats'
const SET_USER_MEALS_AND_WORKOUTS = 'currentUser/setUserMealsAndWorkouts'
const REMOVE_CURRENT_USER = 'currentUser/removeCurrentUser'

// Reducer
const initialState = {
  user: null,
  accessToken: null,
  loginValues: {},
  personalStats: null,
  allUserMeals: [],
  allUserWorkouts: [],
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        user: action.payload.user,
        loginValues: { ...state.loginValues, ...action.payload.loginValues },
      }
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload.accessToken,
      }
    case SET_PERSONAL_STATS:
      return {
        ...state,
        personalStats: action.payload.personalStats,
      }
    case SET_USER_MEALS_AND_WORKOUTS:
      return {
        ...state,
        allUserMeals: action.payload.allUserMeals,
        allUserWorkouts: action.payload.allUserWorkouts,
      }
    case REMOVE_CURRENT_USER:
      return {
        ...state,
        user: null,
        accessToken: null,
        loginValues: {},
        personalStats: null,
        allUserMeals: [],
        allUserWorkouts: [],
      }
    default:
      return state
  }
}

// Action Creators
export function setCurrentUser({ user, loginValues }) {
  return {
    type: SET_CURRENT_USER,
    payload: { user, loginValues },
  }
}

export function setAccessToken({ accessToken }) {
  return {
    type: SET_ACCESS_TOKEN,
    payload: { accessToken },
  }
}

export function setPersonalStats({ personalStats }) {
  return {
    type: SET_PERSONAL_STATS,
    payload: { personalStats },
  }
}

export function setUserMealsAndWorkouts({ allUserMeals, allUserWorkouts }) {
  return {
    type: SET_USER_MEALS_AND_WORKOUTS,
    payload: { allUserMeals, allUserWorkouts },
  }
}

export function removeCurrentUser() {
  return {
    type: REMOVE_CURRENT_USER,
  }
}
