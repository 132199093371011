import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Button from '@material-ui/core/Button'
import StarIcon from '@material-ui/icons/StarRate'
import { mediaQuery, photoUrlPrefix } from '../../services/global'
import { colorPalette } from '../../styles/styleVariables'

// Style
const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  ${mediaQuery.phone`
    flex-direction: column;
  `}
`
const ChangingFeedbackMessageWrapper = styled.div`
  position: relative;
  flex: 1;
  min-height: ${props => (props.minHeight ? props.minHeight - 10 : 100)}px;
  ${mediaQuery.phone`
    width: 100%;
    min-height: ${props => (props.minHeight ? props.minHeight + 30 : 100)}px;
  `}
`
const FeedbackMessageText = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: ${colorPalette.white.main};
  display: flex;
  align-items: center;
  font-size: 20px;
  opacity: ${props => (props.isActive ? '1' : '0')};
  transition: all 2s;
  ${mediaQuery.phone`
    font-size: 13px;
  `}
`
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 20px;
  ${mediaQuery.phone`
    margin-right: 0;
  `}
`
const StyledButton = styled(Button)`
  && {
    text-align: center;
    font-size: 20px;
    background: ${colorPalette.white.main};
    color: ${colorPalette.primary1.main};
    ${mediaQuery.phone`
      font-size: 16px;
    `}
    &:hover {
      background: ${colorPalette.tertiary1.main};
    }
  }
`
const UserNameCircle = styled.div`
  background: ${colorPalette.primary1.light};
  color: ${colorPalette.white.main};
  border: 2px solid ${colorPalette.white.main};
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
  line-height: 38px;
  border-radius: 50%;
  margin-left: 10px;
  text-align: center;
`
const UserNameAndDate = styled.div`
  font-size: 14px;
  display: flex;
`
const ItemPhoto = styled.div`
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
  background-image: url('${props => props.photoUrl}');
  background-position: center;
  background-size: cover;
  margin-left: 10px;
  border-radius: 50%;
  border: 2px solid ${colorPalette.white.main};
`
const StarsRating = styled.div`
  margin-right: 10px;
`
const StyledStarIcon = styled(StarIcon)`
  && {
    color: ${colorPalette.primary2.main};
  }
`

// Component
class FeedbackMessagesViewer extends Component {
  state = {
    currentMessageIndex: 0,
    maxItemHeight: 0,
  }

  // Lifecycle methods
  componentDidMount() {
    this.pauseInterval = false
    this.loadFeedbackMessages(this.props)

    window.setTimeout(() => {
      let maxItemHeight = 0
      document.querySelectorAll('.feedbackMessageTextItem').forEach(item => {
        if (item.scrollHeight > maxItemHeight) {
          maxItemHeight = item.scrollHeight
        }
      })
      this.setState({ maxItemHeight })
    }, 1000)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.feedbackMessages.length !== nextProps.feedbackMessages.length) {
      this.loadFeedbackMessages(nextProps)
    }
  }

  componentWillUnmount() {
    window.clearInterval(this.itemChangeInterval)
  }

  // UI event methods
  handleMouseOver = () => {
    this.pauseInterval = true
  }

  handleMouseOut = () => {
    this.pauseInterval = false
  }

  // Helper methods
  loadFeedbackMessages = async props => {
    const { feedbackMessages } = props
    if (feedbackMessages.length === 0) {
      return null
    }

    const maxIndex = feedbackMessages.length - 1
    this.itemChangeInterval = window.setInterval(() => {
      const { currentMessageIndex } = this.state
      let newIndex = currentMessageIndex + 1
      if (currentMessageIndex === maxIndex) {
        newIndex = 0
      }
      if (this.pauseInterval === false) {
        this.setState({ currentMessageIndex: newIndex })
      }
    }, 10000)
  }

  // Render methods
  renderItem = (item, itemIndex) => {
    const { currentMessageIndex } = this.state
    let messageText = item.Text
    if (messageText && messageText.length > 60) {
      messageText = `${messageText.substring(0, 60)}...`
    }
    let dateStr = ''
    if (item.Date) {
      dateStr = `, ${moment(item.Date).format('DD/MM/YYYY')}`
    }

    let photoUrl = item.UserPhotoURL
    if (photoUrl) {
      if (!photoUrl.includes('http')) {
        photoUrl = `${photoUrlPrefix}/feedbackMessagePhotos/${photoUrl}`
      }
    }

    let randomStarsRating = 5
    if (itemIndex == 2) {
      randomStarsRating = 4
    }
    const starsCount = []
    for (let index = 0; index < randomStarsRating; index++) {
      starsCount.push(index)
    }

    return (
      <FeedbackMessageText isActive={currentMessageIndex === itemIndex} className="feedbackMessageTextItem">
        {photoUrl ? <ItemPhoto photoUrl={photoUrl} /> : <UserNameCircle>{item.Name[0]}</UserNameCircle>}
        <div>
          "{item.Text}"
          <UserNameAndDate>
            ({item.Name}
            {dateStr})
            <StarsRating>
              {starsCount.map(item => (
                <StyledStarIcon />
              ))}
            </StarsRating>
          </UserNameAndDate>
        </div>
      </FeedbackMessageText>
    )
  }

  render() {
    const { maxItemHeight } = this.state
    const { feedbackMessages } = this.props
    return (
      <Wrapper onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut}>
        <ChangingFeedbackMessageWrapper minHeight={maxItemHeight}>{feedbackMessages.map(this.renderItem)}</ChangingFeedbackMessageWrapper>
        <ButtonsWrapper>
          <Link to="/contactUs/feedback">
            <StyledButton variant="contained" color="primary" size="large">
              הוסיפו חוות דעת
            </StyledButton>
          </Link>
        </ButtonsWrapper>
      </Wrapper>
    )
  }
}

export default FeedbackMessagesViewer
