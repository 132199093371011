import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { withRouter, Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FitnessIcon from '@material-ui/icons/FitnessCenter'
import LockIcon from '@material-ui/icons/Lock'
import PlayIcon from '@material-ui/icons/PlayCircleFilled'
import InfoIcon from '@material-ui/icons/DoneOutline'
import dumbbleGuyImage from '../../images/dumbbleGuy.jpg'
import { mediaQuery } from '../../services/global'
import { invokeAPIcall } from '../../services/rest'
import { calcDaysSinceStartedChallenge, isTrialUser, TRIAL_DAYS } from '../../services/challengeProgressHelper'
import { colorPalette } from '../../styles/styleVariables'
import Breadcrumbs from '../Breadcrumbs'
import WorkoutVideoDisplay from '../WorkoutVideoDisplay'
import ChallengePhaseSelector from '../ChallengePhaseSelector'

// Consts

// Style
const PageWrapper = styled.div`
  padding: 20px;
  padding-top: 0;
  flex: 1;
  ${mediaQuery.phone`
    background: none;
    padding: 20px;
  `}
`
const WorkoutsScreenContent = styled.div`
  display: flex;
  ${mediaQuery.phone`
    width: auto;
    padding: 0 10px;
    flex-direction: column;
  `}
`
const WorkoutsTitleSection = styled.div`
  flex: 1.5;
  margin-left: 30px;
  ${mediaQuery.phone`
    margin-bottom: 30px;
    margin-left: 0;
  `}
`
const WorkoutsTitle = styled.div`
  background-color: ${colorPalette.primary1.main};
  color: ${colorPalette.white.main};
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 4px 10px;
  display: flex;
  align-items: center;
`
const WorkoutsTitleLabel = styled.div`
  color: ${colorPalette.white.main};
  font-size: 36px;
  font-weight: 500;
  margin-right: 20px;
  ${mediaQuery.phone`
    font-size: 30px;
  `}
`
const iconCSS = css`
  && {
    width: 50px;
    height: 50px;
    color: ${colorPalette.white.main};
  }
`
const StyledFitnessIcon = styled(FitnessIcon)`
  ${iconCSS}
  transform: rotate(-45deg);
`
const WorkoutsTitlePhoto = styled.div`
  width: 100%;
  height: 200px;
  background-image: url(${dumbbleGuyImage});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
`
const VideoThumbnailImg = styled.div`
  width: 100%;
  height: 175px;
  background-color: #eee;
  background-image: url('http://img.youtube.com/vi/${props => props.youtubeCode}/0.jpg');
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  margin: auto;
`
const VideoThumbnailDesc = styled.div`
  position: absolute;
  top: 0;
  bottom: 20%;
  left: 15px;
  right: 15px;
  font-size: 13px;
  font-weight: 500;
  color: ${colorPalette.black.main};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  ${mediaQuery.tablet`
    font-size: 12px;
  `}
`
const WorkoutButtons = styled.ul`
  flex: 3;
  display: flex;
  flex-wrap: wrap;
  ${mediaQuery.tablet`
    flex: 1;
  `}
`
const WorkoutButton = styled.li`
  background-color: ${colorPalette.white.main};
  flex: 0 0 30%;
  margin-bottom: 20px;
  margin-left: 3%;
  max-height: 228px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  color: ${colorPalette.primary1.main};
  & ${VideoThumbnailDesc} {
    display: none;
  }
  ${props =>
    props.disabled
      ? `
    & ${VideoThumbnailImg} {
      opacity: 0.4;
    }
  `
      : ''}
  &:hover {
    & ${VideoThumbnailImg} {
      opacity: 0.4;
    }
    & ${VideoThumbnailDesc} {
      display: flex;
    }
  }
  ${mediaQuery.tablet`
  flex: 0 0 48%;
  margin-left: 2%;
  `}
  ${mediaQuery.phone`
    flex: 0 0 48%;
  `}
`
const ButtonLabel = styled.div`
  text-align: center;
  padding: 0 5px;
  padding-bottom: 12px;
  margin-top: 10px;
  color: ${colorPalette.black.main};
  & > div {
    font-weight: 500;
    font-size: 14px;
  }
`
const LoadingIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
  width: 100%;
`
const StyledButton = styled(Button)`
  && {
    margin-top: 20px;
  }
`
const LockIconWrapper = styled.div`
  margin-bottom: 6px;
`
const StyledPlayIcon = styled(PlayIcon)`
  && {
    color: ${colorPalette.white.main};
    opacity: 0.4;
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 25%;
    right: 5%;
  }
`
const InfoLabel = styled.div`
  margin-top: 20px;
  font-size: 13px;
  background: ${colorPalette.primary1.main};
  color: ${colorPalette.white.main};
  padding: 10px;
  border-radius: 10px;
`

// Component
class MyWorkoutsScreen extends Component {
  state = {
    workoutVideos: [],
    activeVideo: null,
    selectedChallengePhase: 1,
    dataLoaded: false,
    isTrialUser: false,
  }

  // Lifecycle methods
  async componentDidMount() {
    await this.loadWorkoutVideoList()
    this.setSelectedChallengePhaseBasedOnDay(this.props)

    const { match } = this.props
    const { params } = match
    if (params.id) {
      /*const isTrialUserVal = isTrialUser(currentUser)

      // for trial users - check if this workout is one of the first X workouts from their DailySchedule of 90daysChallenge
      if (isTrialUserVal) {
        const first3WorkoutsIDs = allUserWorkouts
          .filter(item => item.DayInChallenge >= 1 && item.DayInChallenge <= TRIAL_DAYS)
          .map(item => item.Workout && item.Workout.VideoID)
        // if not one of the first X - block the page
        if (!first3WorkoutsIDs.includes(Number(params.id))) {
          return
        }
      }*/
      this.loadAndShowSpecificWorkoutVideo(params.id)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.personalStats.length !== nextProps.personalStats.length) {
      this.setSelectedChallengePhaseBasedOnDay(nextProps)
    }
  }

  // Helper methods
  loadWorkoutVideoList = async () => {
    const workoutVideos = await invokeAPIcall({ uri: 'api/workoutVideos' })
    this.setState({ workoutVideos, dataLoaded: true })
  }

  getVideoDescription = video => {
    return video.Description.split('\n').map(function(item, idx) {
      return (
        <span key={idx}>
          {item}
          <br />
        </span>
      )
    })
  }

  setSelectedChallengePhaseBasedOnDay = props => {
    const { personalStats, currentUser } = props
    const daysSinceStartedChallenge = calcDaysSinceStartedChallenge(personalStats, currentUser)
    let newState = {}
    if (daysSinceStartedChallenge >= 30 && daysSinceStartedChallenge < 60) {
      newState = { selectedChallengePhase: 2 }
    } else if (daysSinceStartedChallenge >= 60) {
      newState = { selectedChallengePhase: 3 }
    }

    this.setState({
      ...newState /*isTrialUser: isTrialUser(currentUser) // TEMP DISABLE: we don't want to limit anything for trial user now */,
    })
  }

  loadAndShowSpecificWorkoutVideo = async id => {
    const { currentUser } = this.props
    const isTrialUserVal = isTrialUser(currentUser)
    const workoutData = await invokeAPIcall({ uri: `api/workoutVideos/${id}` })
    if (isTrialUserVal && workoutData.ShowInChallengePhase !== 1) {
      // block trial users that want to watch videos from later phases (after 1)
      return
    }

    this.setState({ activeVideo: workoutData })
  }

  // UI event methods
  handleVideoClick = item => {
    this.setState({ activeVideo: item })
  }

  handleVideoCloseClick = () => {
    this.setState({ activeVideo: null })
  }

  handleChallengePhaseClick = challengePhase => {
    this.setState({ selectedChallengePhase: challengePhase })
  }

  handleNextVideoClick = () => {
    let { workoutVideos, activeVideo, selectedChallengePhase } = this.state
    workoutVideos = workoutVideos.filter(item => item.ShowInChallengePhase === selectedChallengePhase)

    const currentVideoIndex = workoutVideos.findIndex(item => item.VideoID === activeVideo.VideoID)
    let nextVideoIndex = currentVideoIndex + 1
    if (nextVideoIndex >= workoutVideos.length) {
      nextVideoIndex = 0
    }
    this.setState({ activeVideo: workoutVideos[nextVideoIndex] })
  }

  handlePrevVideoClick = () => {
    let { workoutVideos, activeVideo, selectedChallengePhase } = this.state
    workoutVideos = workoutVideos.filter(item => item.ShowInChallengePhase === selectedChallengePhase)

    const currentVideoIndex = workoutVideos.findIndex(item => item.VideoID === activeVideo.VideoID)
    let prevVideoIndex = currentVideoIndex - 1
    if (prevVideoIndex < 0) {
      prevVideoIndex = workoutVideos.length - 1
    }
    this.setState({ activeVideo: workoutVideos[prevVideoIndex] })
  }

  // Render methods
  renderWorkoutItem = (item, index) => {
    const { isTrialUser } = this.state
    const disabled = isTrialUser && index >= TRIAL_DAYS
    return (
      <WorkoutButton onClick={disabled ? null : this.handleVideoClick.bind(this, item)} disabled={disabled}>
        <VideoThumbnailImg youtubeCode={item.YoutubeCode}>{!disabled && <StyledPlayIcon />}</VideoThumbnailImg>
        <VideoThumbnailDesc>
          {disabled ? (
            <>
              <LockIconWrapper title="ללקוחות פרימיום">
                <LockIcon />
              </LockIconWrapper>
              <div>מהפך ב-90 יום</div>
              <div>בריא יותר, חזק יותר, מאושר יותר</div>
              <Link to="/challengePayment">
                <StyledButton variant="contained" color="secondary" size="large">
                  לתוכנית הפרימיום
                </StyledButton>
              </Link>
            </>
          ) : (
            this.getVideoDescription(item)
          )}
        </VideoThumbnailDesc>
        <ButtonLabel>{item.Name}</ButtonLabel>
      </WorkoutButton>
    )
  }

  render() {
    const { workoutVideos, activeVideo, selectedChallengePhase, dataLoaded, isTrialUser } = this.state
    const { personalStats, currentUser, allUserWorkouts } = this.props

    let workoutVideosToDisplay = workoutVideos.filter(item => item.ShowInChallengePhase === selectedChallengePhase)

    // for trial users - show the first X worksouts from their DailySchedule of 90daysChallenge, as the first X here in the list
    if (isTrialUser) {
      const first3WorkoutsIDs = allUserWorkouts
        .filter(item => item.DayInChallenge >= 1 && item.DayInChallenge <= TRIAL_DAYS)
        .map(item => item.Workout && item.Workout.VideoID)
      workoutVideosToDisplay = workoutVideosToDisplay.reduce((acc, element) => {
        if (first3WorkoutsIDs.includes(element.VideoID)) {
          return [element, ...acc]
        }
        return [...acc, element]
      }, [])
    }

    return (
      <PageWrapper>
        <Breadcrumbs
          previousItems={[
            {
              label: 'חוויית החיטוב ב-90 יום',
              url: `/program`,
            },
          ]}
          currentItem="האימונים שלי"
        />
        <WorkoutsScreenContent>
          <WorkoutsTitleSection>
            <WorkoutsTitle>
              <StyledFitnessIcon />
              <WorkoutsTitleLabel>האימונים שלי</WorkoutsTitleLabel>
            </WorkoutsTitle>
            <WorkoutsTitlePhoto></WorkoutsTitlePhoto>
            <ChallengePhaseSelector
              currentUser={currentUser}
              personalStats={personalStats}
              selectedChallengePhase={selectedChallengePhase}
              handleChallengePhaseClick={this.handleChallengePhaseClick}
            />
            <InfoLabel>
              * ישנם אימונים (לבחירתך) המצריכים ציוד בסיסי שמומלץ לרכוש מראש: גומייה סגורה, גומייה פתוחה, דלגית, מזרן, כדור פיטבול
            </InfoLabel>
          </WorkoutsTitleSection>
          <WorkoutButtons>
            {workoutVideosToDisplay.map(this.renderWorkoutItem)}
            {!dataLoaded && (
              <LoadingIndicatorWrapper>
                <CircularProgress />
              </LoadingIndicatorWrapper>
            )}
          </WorkoutButtons>
        </WorkoutsScreenContent>
        {activeVideo && (
          <WorkoutVideoDisplay
            activeVideo={activeVideo}
            handleClose={this.handleVideoCloseClick}
            handleNextVideoClick={this.handleNextVideoClick}
            handlePrevVideoClick={this.handlePrevVideoClick}
          />
        )}
      </PageWrapper>
    )
  }
}

export default withRouter(MyWorkoutsScreen)
