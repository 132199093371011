import React from 'react'
import styled from 'styled-components'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import { colorPalette } from '../styles/styleVariables'

// Style
const StyledFormControl = styled(FormControl)`
  && {
    margin-top: 16px;
    margin-bottom: 8px;
    .MuiInput-input {
      color: ${colorPalette.primary1.main};
      & > span {
        display: none;
      }
    }
    .MuiFormLabel-root {
      color: ${colorPalette.primary1.main};
    }
    .MuiFormLabel-root.Mui-focused {
      color: ${colorPalette.primary1.dark};
    }
    .MuiInput-root {
      &::after {
        border-bottom: 2px solid ${colorPalette.primary1.dark};
      }
      ::before {
        border-bottom: 1px solid ${colorPalette.primary1.main};
      }
      &:hover::before {
        border-bottom: 2px solid ${colorPalette.primary1.dark};
      }
    }
    .MuiSelect-icon {
      color: ${colorPalette.primary1.main};
    }
  }
`

// Component
function SelectComponent({
  selectParams,
  children,
  label,
  htmlFor,
  helperText,
  error,
  inputLabelShrink,
  variant,
  fullWidth = true,
  required = false,
}) {
  return (
    <StyledFormControl fullWidth={fullWidth} error={error} variant={variant} required={required}>
      <InputLabel htmlFor={htmlFor} shrink={inputLabelShrink}>
        {label}
      </InputLabel>
      <Select {...selectParams}>{children}</Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </StyledFormControl>
  )
}

export default SelectComponent
