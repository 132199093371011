import React, { Component } from 'react'
import styled from 'styled-components'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import MomentUtils from '@date-io/moment'
import 'moment/locale/he'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Helmet } from 'react-helmet'
import storeObj from './services/store'
import { invokeAPIcall } from './services/rest'
import { mediaQuery } from './services/global'
import { colorPalette, GlobalStyle } from './styles/styleVariables'
import { RTL } from './styles/rtlSupport'
import AppRouter from './containers/AppRouterContainer'

// Override default colors & fontFamily of Material-UI-Next
const muiNextTheme = createMuiTheme({
  palette: {
    primary: colorPalette.primary1,
    secondary: colorPalette.primary2,
  },
  typography: {
    fontFamily: '"OpenSansHebrew"',
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: 'white',
      },
    },
  },
  direction: 'rtl',
})

// Style
const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  ${mediaQuery.phone`
    min-height: ${window.outerHeight}px;
  `}
`

if (window.location.protocol !== 'https:' && !window.cordova) {
  window.location.protocol = 'https:'
}

// Component
class App extends Component {
  state = {
    globalSettingsData: {},
  }

  // Lifecycle methods
  componentDidMount() {
    this.loadGlobalSettingsData()
  }

  // Helper methods
  loadGlobalSettingsData = async id => {
    let globalSettingsData = await invokeAPIcall({ uri: `api/globalSettings/1` })
    this.setState({
      globalSettingsData,
    })
  }

  // UI event methods

  render() {
    const { globalSettingsData } = this.state

    return (
      <Provider store={storeObj.store}>
        <PersistGate loading={null} persistor={storeObj.persistor}>
          <MuiThemeProvider theme={muiNextTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale="he">
              <RTL>
                <GlobalStyle />
                <AppWrapper>
                  <Helmet>
                    <title>{globalSettingsData.PageTitle}</title>
                    <meta name="keywords" CONTENT={globalSettingsData.PageKeywords} />
                    <meta name="description" CONTENT={globalSettingsData.PageDescription} />
                  </Helmet>
                  <AppRouter />
                </AppWrapper>
              </RTL>
            </MuiPickersUtilsProvider>
          </MuiThemeProvider>
        </PersistGate>
      </Provider>
    )
  }
}

export default App
